import Drop from 'tether-drop'
import {
    DATA_TYPES,
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'

export function selectFieldType () {
    'ngInject'
    const types = [
        { id: STRING_SINGLE_LINE },
        { id: STRING_MULTI_LINE },
        { id: DATE_ONLY_VALUE },
        { id: DATE_AND_TIME },
        { id: RADIO_SELECTOR },
        { id: MULTI_SELECTOR },
        { id: USER_FIELD },
        { id: EMAIL },
        { id: NUMERIC_VALUE },
        { id: MONEY },
        { id: YES_NO },
        { id: FILES_LIST },
        { id: FORMULA, icon: 'icon-function' }
    ].map(type => ({
        ...type,
        name: `label.fieldType.${type.id}`,
        icon: type.icon || DATA_TYPES[type.id]?.icon
    }))

    return {
        restrict: 'E',
        replace: true,
        scope: {
            type: '=',
            onChange: '&?'
        },
        template: require('./select-field-type.html'),
        link: (scope, element) => {
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--field-type')[0],
                position: 'bottom left',
                openOn: 'click',
                constrainToScrollParent: false,
                constrainToWindow: false
            })

            scope.types = types
            scope.activeType = scope.types.find(t => t.id === scope.type)

            scope.setType = type => {
                if (scope.activeType.id === type.id) {
                    drop.close()
                    return
                }
                scope.onChange({ type })
                scope.activeType = scope.types.find(t => t.id === type.id)
                drop.close()
            }

            scope.$on('$destroy', () => {
                drop.destroy()
            })
        }
    }
}
