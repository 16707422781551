import config from '../../../config';

/* @ngInject */
export function AuthApiCalls($http) {
    let getCall = url => {
        return $http({
            method: 'GET',
            url: config.api + url
        }).then(res => res.data);
    };

    let postCall = (urlRest, params, settings) => {
        return $http({
            method: 'POST',
            url: config.api + urlRest,
            data: params,
            timeout: settings && settings.timeout
        }).then(res => res.data);
    };
    
    return {
        login: params => {
            return postCall('/authenticate/user', params);
        },
        loginDisabled: params => {
            return postCall('/disabled/authenticate/user', params);
        },
        // signinProvider: provider => {
        //     return postCall('/authenticate/' + provider, {});
        // },
        logout: () => {
            return getCall('/logout');
        },
        resetStart: params => {
            return postCall('/reset', params);
        },
        resetDisabledStart: params => {
            return postCall('/disabled/reset', params);
        },
        reset: (mailToken, params) => {
            return postCall('/reset/' + mailToken, params);
        },
        resetDisabled: (mailToken, params) => {
            return postCall('/disabled/reset/' + mailToken, params);
        },
        signupStart: params => {
            return postCall('/signup', params);
        },
        signup: (mailToken, params) => {
            return postCall('/signup/' + mailToken, params);
        },
        inviteSignup: (mailToken, params) => {
            return postCall('/invite/' + mailToken, params, {timeout: 60000});
        }
    };
}
