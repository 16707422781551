export const STRING_SINGLE_LINE = 'STRING_SINGLE_LINE'
export const STRING_MULTI_LINE = 'STRING_MULTI_LINE'
export const DATE_ONLY_VALUE = 'DATE_ONLY_VALUE'
export const DATE_AND_TIME = 'DATE_AND_TIME'
export const RADIO_SELECTOR = 'RADIO_SELECTOR'
export const MULTI_SELECTOR = 'MULTI_SELECTOR'
export const USER_FIELD = 'USER_FIELD'
export const EMAIL = 'EMAIL'
export const NUMERIC_VALUE = 'NUMERIC_VALUE'
export const MONEY = 'MONEY'
export const YES_NO = 'YES_NO'
export const FILES_LIST = 'FILES_LIST'
export const FORMULA = 'FORMULA'
export const AGGREGATION = 'AGGREGATION'

export const DATE_WITHOUT_TIME = 'DATE_WITHOUT_TIME'
export const STATUS = 'STATUS'

export const ALL_DATA_TYPES = [
    STRING_SINGLE_LINE,
    STRING_MULTI_LINE,
    DATE_ONLY_VALUE,
    DATE_AND_TIME,
    RADIO_SELECTOR,
    MULTI_SELECTOR,
    USER_FIELD,
    EMAIL,
    NUMERIC_VALUE,
    MONEY,
    YES_NO,
    FILES_LIST,
    FORMULA,
    AGGREGATION
]

export const DEFAULT_DATA_TYPE = STRING_SINGLE_LINE

export const OPTION_MIN_LENGTH = {
    value: 1,
    defaultValue: 1,
    label: 'label.minimumLength',
}

export const OPTION_PRECISION = {
    label: 'label.precision',
    defaultValue: 4,
    value: 2,
    hint: 'label.precisionHint'
}

export const OPTION_MAX_LENGTH = (max) => ({
    value: max,
    defaultValue: max,
    label: 'label.maximumLength'
})

export const OPTION_DEFAULT_VALUE = {
    label: 'label.defaultValue'
}

export const OPTION_ALLOWED_VALUES = {
    label: 'label.values',
    value: [],
    maxLineLength: 60,
    maxListLength: 200
}

export const YES_NO_OPTIONS = [{}, { item: 'label.yes' }, { item: 'label.no' }]

export const OPTION_MIN_DATE = {
    label: 'label.minimumDate'
}

export const OPTION_MAX_DATE = {
    label: 'label.maximumDate'
}

export const DATA_TYPES = {
    [STRING_SINGLE_LINE]: {
        icon: 'icon-task_field_text',
        settings: {
            minLength: OPTION_MIN_LENGTH,
            maxLength: OPTION_MAX_LENGTH(255)
        }
    },
    [STRING_MULTI_LINE]: {
        icon: 'icon-task_field_multiline',
        settings: {
            minLength: OPTION_MIN_LENGTH,
            maxLength: OPTION_MAX_LENGTH(2000)
        }
    },
    [EMAIL]: {
        icon: 'icon-toaster_mail',
        settings: {}
    },
    [YES_NO]: {
        icon: 'icon-task_field_yesno',
        settings: {
            defaultValue: OPTION_DEFAULT_VALUE
        }
    },
    [RADIO_SELECTOR]: {
        icon: 'icon-task_field_dropdown',
        settings: {
            allowedValues: OPTION_ALLOWED_VALUES,
            defaultValue: OPTION_DEFAULT_VALUE
        }
    },
    [MULTI_SELECTOR]: {
        icon: 'icon-task_field_multiselect',
        settings: {
            allowedValues: OPTION_ALLOWED_VALUES
        }
    },
    [DATE_ONLY_VALUE]: {
        icon: 'icon-task_due_date_add_small',
        settings: {
            minValue: OPTION_MIN_DATE,
            maxValue: OPTION_MAX_DATE
        }
    },
    [DATE_AND_TIME]: {
        icon: 'icon-task_field_datetime',
        settings: {
            minValue: OPTION_MIN_DATE,
            maxValue: OPTION_MAX_DATE
        }
    },
    [NUMERIC_VALUE]: {
        icon: 'icon-task_field_number',
        settings: {
            isInteger: {
                label: 'text.integersOnly'
            },
            precision: OPTION_PRECISION,
            minValue: {
                label: 'label.minValue'
            },
            maxValue: {
                label: 'label.maxValue'
            }
        }
    },
    [MONEY]: {
        icon: 'icon-task_field_money',
        settings: {
            minValue: {
                label: 'label.minValue'
            },
            maxValue: {
                label: 'label.maxValue'
            },
            currency: {
                default: {
                    label: 'label.currency'
                },
                available: {
                    label: 'label.additionalCurrencies'
                }
            }
        }
    },
    [FILES_LIST]: {
        icon: 'icon-task_attachment',
        settings: {
            maxFiles: {
                label: 'label.maximumFiles',
                value: 10,
                defaultValue: 10
            },
            accept: {
                label: 'label.allowedFileTypes'
            }
        }
    },
    [USER_FIELD]: {
        icon: 'icon-company_users',
        settings: {
            group: {
                label: 'label.selectFrom'
            },
            allowedUsers: [],
            allUsers: false
        }
    }
}

export const DATA_TYPE_LIST = Object.keys(DATA_TYPES).map(id => ({
    ...DATA_TYPES[id],
    id,
    name: `label.fieldType.${id}`
}))

export const DATA_TYPE_OPTIONS = DATA_TYPE_LIST.map(({ id, name, icon }) => ({
    value: id,
    label: name,
    icon: icon
}))

const SUM = 'SUM'
const AVG = 'AVG'
const MIN = 'MIN'
const MAX = 'MAX'

export const AGGREGATION_TYPES = {
    SUM,
    AVG,
    MIN,
    MAX
}

export const AGGREGATION_OPTIONS = Object.keys(AGGREGATION_TYPES).map(key => ({ item: key }))

export const FORMAT_OPTIONS = [
    { label: 'label.number', id: NUMERIC_VALUE, isDefault: true },
    { label: 'label.money', id: MONEY },
]

