import {
    ALL_TEMPLATES_OPTION,
    CUSTOM_DATE_RANGE,
    DATE_RANGE_CONFIG,
    DATE_SERVICE_FORMAT,
    DEFAULT_COLUMN_WIDTH,
    DEFAULT_COLUMN_WIDTHS,
    DEFAULT_DATE_RANGE_PRESET,
    NO_TEMPLATE_OPTION,
    PROCESS_STATUS,
    PROCESS_STATUS_FILTER_OPTIONS,
    REPORT_COLUMNS,
    REPORT_TYPE,
    TASK_STATUS,
    TASK_STATUS_FILTER_OPTIONS
} from './constants'
import moment from 'moment-timezone'
import { getService } from '../../react/utils'
import { AGGREGATION, STRING_SINGLE_LINE } from '../../../services/data-types'

export function asValueLabel ({ id, name }) {
    return { value: id, label: name }
}

export function asSelectOption ({ id, name, ...attrs }) {
    return { value: id, label: name, ...attrs }
}

export function userAsSelectOption ({ id, fullName, ...attrs }) {
    return { value: id, label: fullName, ...attrs }
}

export function getDefaultReportFilters (filters, resetStatuses = false) {
    const defaultFilters = { ...filters }
    defaultFilters.templatesIds = []
    defaultFilters.startersIds = []
    defaultFilters.assigneesIds = []

    defaultFilters.dateRange = {
        preset: DEFAULT_DATE_RANGE_PRESET,
        start: undefined,
        end: undefined
    }

    defaultFilters.statuses = resetStatuses || !defaultFilters.statuses ? [] : defaultFilters.statuses
    defaultFilters.taskStatuses = resetStatuses || !defaultFilters.taskStatuses ? [] : defaultFilters.taskStatuses

    if (defaultFilters.multipleTemplates === undefined) {
        defaultFilters.multipleTemplates = true
    }

    return defaultFilters
}

export function setDefaultReportProps (report) {
    const slug = report.type.toLowerCase().replace(/\_/g, '-')
    const filters = getDefaultReportFilters(report.filters)
    const data = { total: 0, list: [] }
    const columns = {}

    Object.keys(REPORT_COLUMNS[report.type]).forEach((key, index) => {
        const column = REPORT_COLUMNS[report.type][key]
        const { dataType = STRING_SINGLE_LINE } = column

        columns[key] = {
            label: `report.columns.${key}`,
            width: column.width || DEFAULT_COLUMN_WIDTHS[dataType] || DEFAULT_COLUMN_WIDTH,
            flexGrow: column.flexGrow || 1,
            order: index
        }
    })

    return { ...report, filters, slug, data, columns }
}

function prepareTemplatesIds (templatesIds) {
    if (templatesIds.find(id => id === ALL_TEMPLATES_OPTION.id)) {
        return [ALL_TEMPLATES_OPTION.id]
    }
    return templatesIds
}

function getDateRangeValues ({ preset, start, end }) {
    const DateHelper = getService('DateHelper')
    const timezone = DateHelper.getTZ()

    if (preset !== CUSTOM_DATE_RANGE.value) {
        const range = DATE_RANGE_CONFIG[preset]
        start = range.start(moment()).format(DATE_SERVICE_FORMAT)
        end = range.end(moment()).format(DATE_SERVICE_FORMAT)
    }

    return {
        start: moment.tz(start, DATE_SERVICE_FORMAT, timezone).unix(),
        end: moment.tz(end, DATE_SERVICE_FORMAT, timezone).unix()
    }
}

export function prepareReportParams (report) {
    const { templatesIds, statuses, dateRange, taskStatuses, startersIds, assigneesIds } = report.filters
    const { start, end } = getDateRangeValues(dateRange)

    const reportStatuses = statuses.filter(s => s !== PROCESS_STATUS.ALL && PROCESS_STATUS[s])

    const params = {
        reportType: report.type,
        templatesIds: prepareTemplatesIds(templatesIds).join(','),
        statuses: reportStatuses.join(','),
        dateFrom: start,
        dateTo: end
    }

    if (startersIds.length) {
        params.startersIds = startersIds.join(',')
    }

    if (report.type === REPORT_TYPE.TASK_PERFORMANCE) {
        params.taskStatuses = taskStatuses.filter(s => s !== TASK_STATUS.ALL).join(',')

        if (assigneesIds.length) {
            params.assigneesIds = assigneesIds.join(',')
        }
    }

    return params
}

export function prepareReportExportParams (report, fileType) {
    const { templatesIds, statuses, dateRange, taskStatuses, startersIds, assigneesIds } = report.filters
    const { start, end } = getDateRangeValues(dateRange)

    const reportStatuses = statuses.filter(s => s !== PROCESS_STATUS.ALL && PROCESS_STATUS[s])

    const params = {
        templatesIds: prepareTemplatesIds(templatesIds),
        statuses: reportStatuses,
        dateFrom: start,
        dateTo: end
    }

    if (startersIds && startersIds.length) {
        params.startersIds = startersIds
    }

    if (report.type === REPORT_TYPE.TASK_PERFORMANCE) {
        params.taskStatuses = taskStatuses.filter(s => s !== TASK_STATUS.ALL)

        if (assigneesIds && assigneesIds.length) {
            params.assigneesIds = assigneesIds
        }
    }

    return {
        reportType: report.type,
        fileType,
        params
    }
}

export function templatesWithSpecialValues (templates) {
    return [asValueLabel(ALL_TEMPLATES_OPTION), asValueLabel(NO_TEMPLATE_OPTION), ...templates]
}

export function createDateRanges () {
    return Object.keys(DATE_RANGE_CONFIG).map(key => {
        const range = DATE_RANGE_CONFIG[key]
        return {
            value: key,
            label: range.name,
            start: range.start(moment()).format(DATE_SERVICE_FORMAT),
            end: range.end(moment()).format(DATE_SERVICE_FORMAT)
        }
    })
}

export function updateStatuses (currentStatuses, selectedStatus, state) {
    const { ALL } = PROCESS_STATUS
    let newStatuses = currentStatuses.slice().filter(id => id !== selectedStatus.id)

    if (selectedStatus.id === ALL) {
        newStatuses = state ? PROCESS_STATUS_FILTER_OPTIONS.map(s => s.id) : []
    } else {
        newStatuses = state
            ? [...newStatuses, selectedStatus.id]
            : newStatuses.filter(id => id !== ALL)
    }

    const allStatusesSelected = newStatuses.filter(id => id !== ALL).length === PROCESS_STATUS_FILTER_OPTIONS.filter(s => s.id !== ALL).length
    if (allStatusesSelected && !newStatuses.find(id => id === ALL)) {
        newStatuses.push(ALL)
    }

    return newStatuses
}

export function updateTaskStatuses (currentStatuses, selectedStatus, state) {
    const { ALL } = TASK_STATUS
    let newStatuses = currentStatuses.slice().filter(id => id !== selectedStatus.id)

    if (selectedStatus.id === ALL) {
        newStatuses = state ? TASK_STATUS_FILTER_OPTIONS.map(s => s.id) : []
    } else {
        newStatuses = state
            ? [...newStatuses, selectedStatus.id]
            : newStatuses.filter(id => id !== ALL)
    }

    const allStatusesSelected = newStatuses.filter(id => id !== ALL).length === TASK_STATUS_FILTER_OPTIONS.filter(s => s.id !== ALL).length
    if (allStatusesSelected && !newStatuses.find(id => id === ALL)) {
        newStatuses.push(ALL)
    }

    return newStatuses
}

export function updateDateRangePreset (values, options) {
    let preset = options.find(opt => opt.start === values.start && opt.end === values.end) || CUSTOM_DATE_RANGE

    return { ...values, preset: preset.value }
}

export function checkIfDateRangeHasError ({ start, end }) {
    if (moment(end).diff(moment(start)) < 0) {
        return 'INVALID'
    }
    if (moment(end).diff(moment(start).add(1, 'year'), 'days', true) > 1) {
        return 'TOO_BIG'
    }
    return undefined
}

export function prepareColumns ({ columns, type, data }) {
    const newColumns = {}
    let maxOrder = 0
    Object.keys(columns).forEach((key, index) => {
        const column = REPORT_COLUMNS[type][key]
        if (column) {
            const state = columns[key]
            const order = column.order !== undefined ? column.order : index
            maxOrder = Math.max(maxOrder, order)
            newColumns[key] = { ...column, ...state, order }
        }
    })

    if (type === REPORT_TYPE.COLLECTED_DATA && data.extraColumns) {
        data.extraColumns.forEach((col, index) => {
            const getter = (d) => {
                const row = d.extraRows.find(row => row.id === col.id)
                return row ? row.value : null
            }
            const dataTypeGetter = d => {
                const row = d.extraRows.find(row => row.id === col.id)
                return row ? row.dataType : null
            }
            const state = columns[col.id] || {}
            const { dataType = STRING_SINGLE_LINE } = col
            if (dataType !== AGGREGATION) {
                const order = (newColumns[col.id] && newColumns[col.id].order !== undefined)
                    ? newColumns[col.id].order
                    : maxOrder + index
                newColumns[col.id] = {
                    ...col,
                    width: DEFAULT_COLUMN_WIDTHS[dataType],
                    flexGrow: 1,
                    getter,
                    dataTypeGetter,
                    ...state,
                    order
                }
            }
        })
    }

    return newColumns
}

export const reportHasData = (report) => !!(report && report.data && report.data.total > 0)

export const reportHasValidFilters = (report) => {
    const { templatesIds, statuses, taskStatuses = [] } = report.filters
    const validTaskStatuses = report.type !== REPORT_TYPE.TASK_PERFORMANCE || taskStatuses.length

    return templatesIds.length && statuses.length && validTaskStatuses
}

export function downloadFile (url, isMobile) {
    let el
    if (isMobile) {
        el = document.createElement('a')
        el.setAttribute('href', url)
        el.setAttribute('style', 'display:none;')
        document.body.appendChild(el)
        el.click()
    } else {
        el = document.createElement('iframe')
        el.setAttribute('src', url)
        el.setAttribute('style', 'display:none;')
        document.body.appendChild(el)
    }

    setTimeout(() => {
        document.body.removeChild(el)
    }, 5000)
}

const STOP_LOADING_OFFSET = -1

export class DataLoader {
    constructor (requestDataAction, receiveDataAction) {
        this.requestData = requestDataAction
        this.receiveData = receiveDataAction
        this.initialize()
    }

    initialize () {
        this.collection = []
        this.queue = []
        this.isProcessing = false
        this.nextOffset = null
    }

    isQueued (rowIndex) {
        return this.queue.indexOf(rowIndex) > -1
    }

    processResponse (json, props) {
        const { nextOffset, list, ...data } = json
        const lastLoadedIndex = this.collection.length + list.length - 1

        this.nextOffset = nextOffset || STOP_LOADING_OFFSET
        this.queue = this.queue.filter(i => i > lastLoadedIndex)

        this.receiveData({ ...props, data: { ...data, list } })
        this.isProcessing = false
        this.processQueue()
    }

    processQueue () {
        if (this.isProcessing || !this.queue.length || this.nextOffset === STOP_LOADING_OFFSET) {
            return
        }

        this.isProcessing = true
        this.requestData(this.nextOffset, this.processResponse.bind(this))
    }

    addToQueue (rowIndex) {
        const isQueued = this.isQueued(rowIndex)

        if (!isQueued) {
            this.queue.push(rowIndex)
        }

        this.processQueue()
    }

    getObjectAt (collection, rowIndex) {
        if (collection[rowIndex]) {
            return collection[rowIndex]
        }

        this.collection = collection.slice()
        this.addToQueue(rowIndex)

        return null
    }
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param {int} n: length of decimal
 * @param {int} x: length of whole part
 * @param {any}   s: sections delimiter
 * @param {any}   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n))

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}

export function formatNumber (number, precision) {
    if (!precision) {
        let decimalPart = String(number).split('.')[1]
        precision = decimalPart ? decimalPart.length : 0
    }
    return Number(number).format(precision, 3, ' ', '.')
}

export function columnsSorter (a, b) {
    return a.order - b.order
}

const MINUTE = 60
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

export function calculateDuration (seconds) {
    const days = Math.floor(seconds / DAY)
    seconds %= DAY
    const hours = Math.floor(seconds / HOUR)
    seconds %= HOUR
    const minutes = Math.floor(seconds / MINUTE)
    seconds %= MINUTE
    return { days, hours, minutes, seconds }
}

export function formatDuration (duration) {
    const { days, hours, minutes } = duration
    const parts = []
    if (days > 0) {
        parts.push(days + 'd')
    }
    if (hours > 0) {
        parts.push(hours + 'h')
    }
    parts.push((minutes < 10 && hours > 0 ? '0' + minutes : minutes) + 'm')
    return parts.join(' ')
}
