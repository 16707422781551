import moment from 'moment-timezone'
import { DATE_SYSTEM_FORMAT } from '../constants'
import config from './config';

export function DateHelper (ServerConfig, $auth, $translate) {
    'ngInject'

    const browserTZ = ServerConfig.browserTimeZone
    const defaultCompanyTZ = ServerConfig.defaultTimeZone
    const _workingTimeStart = ServerConfig.workingTimeStart || 0
    const { businessCalendar } = ServerConfig
    const firstDayOfWeek = businessCalendar ? businessCalendar.firstDayOfWeek : 0

    const DATE_FORMATS = () => {
        const currentLangCode = $translate.proposedLanguage()
        return config[currentLangCode]
    }

    const _getUserTZ = () => {
        if ($auth.isAuthenticated()) {
            let payload = $auth.getPayload()
            return payload.tzn
        }
    }
    const _getTZ = () => {
        return _getUserTZ() || ServerConfig.defaultTimeZone || browserTZ
    }
    const _dateToCompanyEndOfDay = (date) => {
        if (date) {
            const eodUTC = moment.unix(date).utc().endOf('day').format(DATE_SYSTEM_FORMAT)
            return moment.tz(eodUTC, DATE_SYSTEM_FORMAT, defaultCompanyTZ).unix()
        }
        return date
    }

    const _companyEndOfDayToDateInUTC = (date) => {
        if (date) {
            return moment.utc(moment.unix(date).tz(ServerConfig.defaultTimeZone).format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).unix()
        }
        return date
    }

    return {
        getTZ: () => {
            return _getTZ()
        },
        getCompanyTZ: () => {
            return ServerConfig.defaultTimeZone
        },
        getWorkingTimeStart: () => {
            return _workingTimeStart
        },
        getFirstDayOfWeek: () => firstDayOfWeek,
        getUtcFromTimestamp: (date) => {
            if (moment.isDate(date)) {
                return date.getTime()
            }
            //not a date object -> it means timestamp is passed
            return new Date(date * 1000).getTime()
        },
        dateToCompanyEndOfDay: (date) => {
            return _dateToCompanyEndOfDay(date)
        },
        companyEndOfDayToDateInUTC: (date) => {
            return _companyEndOfDayToDateInUTC(date)
        },
        DATE_FORMATS,
        timestampToDate: (ts, format) => {
            return moment.unix(ts).utc().format(format || DATE_FORMATS().DATE_INPUT)
        },
        timestampToDateTime: (ts, format = DATE_FORMATS().DATE_TIME_INPUT) => {
            const tz = _getTZ()
            return moment.unix(ts).utc().tz(tz).format(format)
        }
    }
}
