import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import 'mobx-react-lite/batchingForReactDom'
import cn from 'classnames'

import EntityIconSelector from '../../../../components/react/icon/entity-icon-selector.jsx'
import ActionsDropdown from '../../../../components/react/dropdown/actions-dropdown.jsx'
import Tooltip from '../../../react/tooltip.jsx'
import { translate } from '../../../react/utils'

import { DEFAULT_TABLE_ICON } from '../../models'

function Header ({ table, addRecordAction, openPermissions, deleteTable, ...props }) {
    const inputKeyPressHandler = event => {
        if (event.key === 'Enter') {
            event.target.blur()
        }
    }

    const preventDefaultEnterKey = event => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <header className="datatable-header" {...props}>
            <div className="datatable-icon">
                <EntityIconSelector
                    value={table.icon}
                    onChange={(icon) => table.changeIcon(icon)}
                    readOnly={!table.isEditable}
                    defaultIcon={DEFAULT_TABLE_ICON}
                />
            </div>
            <div className="datatable-title">
                <div className={cn('title', { 'empty': table.nameIsRequired, editable: table.isEditable })}>
                    {table.isEditable && (
                        <input
                            id="table-name"
                            type="text"
                            placeholder={translate('text.enterTableName')}
                            value={table.nameValue}
                            autoComplete={'off'}
                            onChange={e => table.changeName(e.target.value)}
                            onKeyPress={inputKeyPressHandler}
                        />
                    )}
                    <label htmlFor="table-name">
                        <h1>{table.nameValue || translate('text.enterTableName')}</h1>
                        {table.isEditable && (
                            <i className="icon-template_create_field_editable"/>
                        )}
                    </label>
                    {table.nameIsRequired && (
                        <span className="error">{translate('validation.required')}</span>
                    )}
                    {table.nameIsShort && (
                        <span className="error">{translate('validation.minLength', {value: 3})}</span>
                    )}
                </div>
                <div className={cn('description', { editable: table.isEditable })}>
                    {table.isEditable && (
                        <textarea
                            id="table-description"
                            rows="2"
                            cols="100"
                            value={table.description || ''}
                            onChange={e => table.changeDescription(e.target.value)}
                            onKeyDown={preventDefaultEnterKey}
                            onKeyUp={inputKeyPressHandler}
                            onBlur={() => table.changeDescription(table.description || '', true)}
                            placeholder={translate('text.enterDescription')}
                        />
                    )}
                    <label htmlFor="table-description">
                        {(table.isEditable || table.description) && (
                            <p style={{WebkitBoxOrient: 'vertical'}}>
                                {table.description || translate('text.noDescriptionProvided')}
                            </p>
                        )}
                        {table.isEditable && (
                            <i className="icon-template_create_field_editable"/>
                        )}
                    </label>
                </div>
            </div>
            <div className="actions">
                {table.isRecordEditable && (
                    <Tooltip text={translate('tooltips.addNewRecord')}>
                        <button type="button" className="btn btn-green" onClick={() => addRecordAction()}>
                            <i className="icon-template_create"/>
                            <span>{translate('label.addNewRecord')}</span>
                        </button>
                    </Tooltip>
                )}
                {table.isEditable && (
                    <ActionsDropdown>
                        <ul className="actions-list">
                            <li className="actions-list-item" onClick={openPermissions}>
                                <i className="icon-company_users"/> <span>{translate('label.permissions')}</span>
                            </li>
                            <li className="actions-list-item" onClick={deleteTable}>
                                <i className="icon-delete_roundless"/> <span>{translate('label.deleteTable')}</span>
                            </li>
                        </ul>
                    </ActionsDropdown>
                )}
            </div>
            <a href="/database" className="btn btn-back">
                <i className="icon-common_button_back"/>
            </a>
            <a href="/database" className="btn btn-close">
                <i className="icon-common_close"/>
            </a>
        </header>
    )
}

Header.propTypes = {
    table: PropTypes.object.isRequired,
    addRecordAction: PropTypes.func.isRequired,
    openPermissions: PropTypes.func.isRequired,
    deleteTable: PropTypes.func.isRequired
}

export default observer(Header)
