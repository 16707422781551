import {
    AGGREGATION,
    ALL_DATA_TYPES,
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import { flattenFormFields } from '../../processes/utils'

export const OPERATORS = {
    ALWAYS: 'ALWAYS',
    AND: 'AND',
    OR: 'OR'
}

const OPERATOR_LABELS = {
    [OPERATORS.ALWAYS]: 'taskConditions.label.rule.always',
    [OPERATORS.AND]: 'taskConditions.label.rule.and',
    [OPERATORS.OR]: 'taskConditions.label.rule.or'
}

const MAX_CONDITIONS_AMOUNT = 10

const DEFAULT_CONDITIONS = {
    rule: OPERATORS.ALWAYS,
    list: []
}

const DATE_DATA_TYPES = [DATE_ONLY_VALUE, DATE_AND_TIME]
export const NUMERIC_DATA_TYPES = [MONEY, NUMERIC_VALUE, FORMULA, AGGREGATION]
export const STRING_DATA_TYPES = [EMAIL, STRING_SINGLE_LINE, STRING_MULTI_LINE]

const getDataTypesWithout = (...types) => {
    return ALL_DATA_TYPES.filter(type => {
        return types.indexOf(type) === -1
    })
}

const AVAILABLE_OPERATIONS = [
    {
        id: 'FILLED_IN',
        label: 'taskConditions.label.operation.filledIn',
        dataTypes: ALL_DATA_TYPES
    },
    {
        id: 'NOT_FILLED_IN',
        label: 'taskConditions.label.operation.notFilledIn',
        dataTypes: ALL_DATA_TYPES
    },
    {
        id: 'EQUAL',
        label: 'taskConditions.label.operation.equalsTo',
        dataTypes: getDataTypesWithout(FILES_LIST, MULTI_SELECTOR, USER_FIELD),
        hasExpression: true
    },
    {
        id: 'NOT_EQUAL',
        label: 'taskConditions.label.operation.notEqualTo',
        dataTypes: getDataTypesWithout(FILES_LIST, MULTI_SELECTOR, USER_FIELD, YES_NO),
        hasExpression: true
    },
    {
        id: 'BEFORE',
        label: 'taskConditions.label.operation.before',
        dataTypes: DATE_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'AFTER',
        label: 'taskConditions.label.operation.after',
        dataTypes: DATE_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'LESS',
        label: 'taskConditions.label.operation.lessThan',
        dataTypes: NUMERIC_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'LESS_OR_EQUAL',
        label: 'taskConditions.label.operation.lessOrEqualTo',
        dataTypes: NUMERIC_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'GREATER',
        label: 'taskConditions.label.operation.greaterThan',
        dataTypes: NUMERIC_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'GREATER_OR_EQUAL',
        label: 'taskConditions.label.operation.greaterOrEqualThan',
        dataTypes: NUMERIC_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'STARTS_WITH',
        label: 'taskConditions.label.operation.startsWith',
        dataTypes: STRING_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'ENDS_WITH',
        label: 'taskConditions.label.operation.endsWith',
        dataTypes: STRING_DATA_TYPES,
        hasExpression: true
    },
    {
        id: 'CONTAINS',
        label: 'taskConditions.label.operation.contains',
        dataTypes: STRING_DATA_TYPES.concat(MULTI_SELECTOR),
        hasExpression: true
    },
    {
        id: 'NOT_CONTAIN',
        label: 'taskConditions.label.operation.notContain',
        dataTypes: [STRING_SINGLE_LINE, STRING_MULTI_LINE, EMAIL, MULTI_SELECTOR],
        hasExpression: true
    }
]

export function ConditionsConfig ($translate) {
    'ngInject'

    const getAvailableOperations = (dataType) => {
        const fieldOperations = AVAILABLE_OPERATIONS.filter(operation => {
            return operation.dataTypes.indexOf(dataType) > -1
        })

        return fieldOperations.map(operation => {
            const { id, label, hasExpression } = operation
            return {
                id,
                item: $translate.instant(label),
                hasExpression
            }
        })
    }

    const updateAvailableOperations = (condition) => {
        condition.field.availableOperations = []
        condition.operation = condition.operation || {}
        if (condition.field) {
            condition.field.availableOperations = getAvailableOperations(condition.field.dataType)
        }
    }

    const updateOperation = (condition) => {
        const operation = condition.field.availableOperations.find(item => condition.operation && item.id === condition.operation.id)
        condition.operation = Object.assign({}, operation || {})
        updateExpressionSupportData(condition)
    }

    const updateExpression = (condition) => {
        if (!condition.field) {
            return
        }

        let { dataType, options = {} } = condition.field

        let fieldHasAllowedValues = (dataType === MULTI_SELECTOR || dataType === RADIO_SELECTOR) && options.source && options.source.allowedValues
        let expressionStringValue = condition.expression && condition.expression.string
        let expressionRecordValue = condition.expression && condition.expression.record && condition.expression.record.recordId
        let expressionValueMissing = fieldHasAllowedValues && expressionStringValue && !options.source.allowedValues.find(value => {
            return expressionStringValue === value.item
        })

        if (expressionValueMissing && !expressionRecordValue) {
            delete condition.expression
        }
    }

    const updateExpressionSupportData = (condition) => {
        if (!condition.field) {
            return
        }

        if (condition.field.dataType === YES_NO) {
            condition.field.value = {
                yesNoValue: condition.expression.string
            }
        } else if (condition.field.dataType === MONEY) {
            condition.expression.number = condition.expression.number !== undefined
                ? condition.expression.number
                : condition.expression.amount

            // Workaround for money field, to display initial value
            condition.expression.amount = condition.expression.number
        } else if ([RADIO_SELECTOR, MULTI_SELECTOR].includes(condition.field.dataType) && condition.operation.hasExpression) {
            const { useTableAsSource, source } = condition.field.options
            if (!useTableAsSource && source.allowedValues) {
                delete condition.expression.record
                delete condition.expression.tableId
                delete condition.expression.displayFields
                condition.expression.allowedValues = source.allowedValues
            } else {
                if (condition.expression.record && (!condition.expression.tableId || condition.expression.tableId !== source.tableId)) {
                    condition.expression.record = undefined
                }
                delete condition.expression.string
                delete condition.expression.allowedValues
                condition.expression.tableId = source.tableId
                condition.expression.displayFields = source.displayFields
                condition.expression.selectRecord = (record) => {
                    const { recordId, values } = record
                    condition.expression.record = { recordId, values }
                }
            }
        }
    }

    const updateCondition = (condition, groupedFormFields) => {
        const formFields = flattenFormFields(groupedFormFields)

        let formField = formFields.find(field => field.id === condition.field.id)
        let formFieldTypeChanged = formField && condition.field.dataType !== formField.dataType
        let conditionFieldChanged = condition.field.prevId && condition.field.prevId !== condition.field.id

        if (!formField || formFieldTypeChanged || conditionFieldChanged) {
            condition.expression = {}
            condition.operation = {}
        }

        if (formField) {
            const asSelectItem = { label: formField.name.label, value: formField.id }
            condition.field = { id: formField.id, ...formField.name, asSelectItem }
            condition.field.value = {}
            condition.field.prevId = formField.id
            condition.expression = condition.expression || {}
            updateAvailableOperations(condition)
        }
    }

    const updateConditions = (item, groupedFormFields) => {
        const formFields = flattenFormFields(groupedFormFields)
        if (!item.conditions || !item.conditions.list || !item.conditions.rule) {
            item.conditions = Object.assign({}, DEFAULT_CONDITIONS)
        }

        if (!formFields.length) {
            item.conditions.rule = OPERATORS.ALWAYS
        }

        if (item.conditions.rule === OPERATORS.ALWAYS) {
            item.conditions.list = []
        } else {
            item.conditions.list = item.conditions.list.filter(condition => {
                if (condition.field.id) {
                    return formFields.find(field => field.id === condition.field.id)
                }
                return true
            })
        }

        item.conditions.list.forEach(condition => {
            updateCondition(condition, groupedFormFields)
            updateOperation(condition)
            updateExpression(condition)
        })
    }

    return {
        MAX_CONDITIONS_AMOUNT,
        DEFAULT_CONDITIONS,
        OPERATORS,
        OPERATOR_OPTIONS: Object.keys(OPERATORS).map(key => ({
            id: key, item: $translate.instant(OPERATOR_LABELS[key])
        })),
        AVAILABLE_OPERATIONS,
        getAvailableOperations: getAvailableOperations,
        updateAvailableOperations: updateAvailableOperations,
        updateConditions: updateConditions,
        updateCondition: updateCondition,
        updateOperation: updateOperation,
        updateExpressionSupportData: updateExpressionSupportData
    }
}
