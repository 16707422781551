import colors from '../libs/colors';
export function parseComment($sce, $filter, $translate) {
    'ngInject';

    let getGroupsStr = user => {
        if (user.groups && user.groups.length > 3) {
            let count = user.groups.length - 3;
            let begin = user.groups.slice(0, 3).map(group => group.name).join(', ');
            let end = $translate.instant('text.andMoreGroups', {count}, 'messageformat');
            return `<span>${begin} ${end}</span>`;
        }
        let groups = user.groups.map(group => {
            return group.name;
        }).join(', ');
        return `<span>${groups}</span>`;
    };

    return (text, mentions, noTooltip) => {
        if (!text) {
            return text;
        }
        const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp~!$;:*_}{\)\(\'\/+#-]*[\w,@?^=%&amp~!$;:*_}{\)\(\'\/+#-])?/gi;
        const emailRegex = /[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{2,6})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?/gi;

        let result = text.replace(urlRegex, url => {
            let textUrl = url.length > 100 ? url.slice(0, 100) + '...' : url;
            return '<a target="_blank" class="link" href="' + url + '">' + textUrl + '</a>'
                + '<a target="_blank" class="full-link" style="display: none" href="' + url + '">' + url + '</a>';
        });
        result = result.replace(emailRegex, email => {
            let emailText = email.length > 100 ? email.slice(0, 100) + '...' : email;
            return '<a target="_blank" href="mailto:' + email + '">' + emailText + '</a>';
        });
        if (mentions && mentions.length) {
            result = result.replace(/<@([0-9a-fA-F]{32})>/g, (str, p1) => {
                let user = mentions.find(m => m.id === p1);

                if (noTooltip) {
                    return user.fullName;
                } else {
                    let avatar;
                    let groupsStr = user.groups ? getGroupsStr(user) : '';
                    if (user.avatarUrl) {
                        avatar = `<img class="avatar" src="${user.avatarUrl}_40"/>`;
                    } else {
                        let color = colors[user.colorIndex - 1];
                        let initials = $filter('initials')(user.fullName);
                        avatar = `<div class="avatar" style="background: ${color}">${initials}</div>`;
                    }
                    return `<span class="mention">@${user.fullName.split(' ')[0]}`
                        + '<div class="user-tooltip">'
                        + '<div class="user-tooltip__container">'
                        + `${avatar}`
                        + `<div><span>${user.fullName}</span>${groupsStr}</div>`
                        + '<i class="arrow"></i>'
                        + '</div>'
                        + '</div>'
                        + '</span>';
                }
            });
        }
        return $sce.trustAsHtml(result);
    };
}
