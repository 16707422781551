import React from 'react'
import Dropdown from '../../../components/react/dropdown.jsx'
import { REPORT_EXPORT_FORMATS } from '../store/constants'
import classNames from 'classnames'

import { translate } from '../../react/utils'
import PropTypes from 'prop-types'

const ExportDropdown = ({ isOpen, isAnimated, disabled, progress, toggleDropdown, onReportExport, ...props }) => {
    const { CSV, EXCEL } = REPORT_EXPORT_FORMATS

    const label = (
        <span>
            <i className={isAnimated ? 'icon-task_field_save_ring icon-spin' : 'icon-report_export'}/>
            {' '}
            {translate('label.export')} {isAnimated ? `${progress}%` : ''}
        </span>
    )
    const itemClickHandler = (format) => {
        return () => {
            toggleDropdown(false)
            onReportExport(format)
        }
    }

    const labelClasses = classNames('btn btn-green btn-dropdown', { disabled: disabled || isAnimated })

    return (
        <Dropdown
            label={label}
            labelClasses={labelClasses}
            open={isOpen}
            onClick={toggleDropdown}
            disabled={disabled || isAnimated}
            {...props}
        >
            <ul className="export-dropdown">
                {[CSV, EXCEL].map(format => (
                    <li key={format}>
                        <a onClick={itemClickHandler(format)}>
                            <i className={`icon-File-${format}`}/>
                            {format}
                        </a>
                    </li>
                ))}
            </ul>
        </Dropdown>
    )
}

ExportDropdown.propTypes = {
    isOpen: PropTypes.bool,
    isAnimated: PropTypes.bool,
    disabled: PropTypes.bool,
    progress: PropTypes.number,
    toggleDropdown: PropTypes.func,
    onReportExport: PropTypes.func,
}

export default ExportDropdown
