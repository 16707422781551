import React from 'react';
import { components } from 'react-select';
import cn from 'classnames';
import {ALL_TEMPLATES_OPTION, NO_TEMPLATE_OPTION} from '../store/constants';

const TemplateOption = ({ children, data, ...props }) => {
    const { icon, value } = data;
    let iconComponent = (
        <i className="option-icn icon-File-Settings" style={{ backgroundColor: '#ccc' }} />
    );

    if (icon) {
        iconComponent = (
            <i className={cn('option-icn', 'icon', icon.id)} style={{ backgroundColor: icon.color }} />
        )
    }

    if (value === ALL_TEMPLATES_OPTION.id || value === NO_TEMPLATE_OPTION.id) {
        iconComponent = <i className="spacer" />;
    }

    return (
        <components.Option {...props}>
            <span className="option-label">
                {iconComponent}
                {children}
            </span>
        </components.Option>
    );
};

export default TemplateOption;
