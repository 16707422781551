export function actorsList($timeout, $window) {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            actorsList: '=',
            eventReCalc: '@'
        },
        link: ($scope, element) => {
            let arr, arrCount;

            const recalc = () => {
                arr = [].slice.call(element[0].children);
                arrCount = arr.length;
                let space = element[0].offsetWidth + 1;
                arr.forEach((el, i) => {
                    delete element[0].children[i].style.display;
                    space = space - (el.clientWidth + 8);
                    if (space < 0) {
                        element[0].children[i].style.display = 'none';
                        arrCount--;
                    }
                });

                $timeout(() => {
                    $scope.actorsList = arrCount;
                }, 0);
            };

            recalc();

            angular.element($window).on('resize', recalc);
            $scope.$on('$destroy', () => {
                angular.element($window).off('resize', recalc);
            });
            if($scope.eventReCalc) {
                $scope.$on($scope.eventReCalc, () => recalc());
            }
        }
    };
}
