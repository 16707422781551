import moment from 'moment-timezone'
import config from '../../../config'

export class AdminCalendarController {
    constructor($scope, AdminApiCalls, PageSettings, ServerConfig, $translate, $timeout, currentUser, DateHelper) {
        'ngInject';
        moment.locale(currentUser?.lang || 'en')

        let timeoutId;
        const weekdays = moment.weekdays();

        const init = (calendar) => {
            this.calendar = calendar;
            this.firstDay = weekdays[this.calendar.firstDayOfWeek];
            this.selectOptions = [1, 7].map(t => ({ item: moment().isoWeekday(t).format('dddd') }))
            this.weekdaysValues = calendar.weekDays.map(i => Object.assign(i, {item: weekdays[i.day]}));

            this.currentYear = {
                title: moment.utc().year(),
                minValue: moment.utc().startOf('year').unix(),
                maxValue: moment.utc().endOf('year').unix(),
                holidays: this.calendar.holidays
                    ? this.calendar.holidays.filter(h => {
                        return moment.unix(h.date).utc().year() === moment.utc().year();
                    })
                    : []
            };
            this.nextYear = {
                title: moment.utc().add(1, 'year').year(),
                minValue: moment.utc().add(1, 'years').startOf('year').unix(),
                maxValue: moment.utc().add(1, 'years').endOf('year').unix(),
                holidays: this.calendar.holidays
                    ? this.calendar.holidays.filter(h => moment.unix(h.date).utc().year() === moment.utc().add(1, 'year').year())
                    : []
            };
        };

        this.formatHoliday = holiday => DateHelper.timestampToDate(holiday.date, DateHelper.DATE_FORMATS().CALENDAR_DATE_SHORT)

        this.isPreloading = true;
        AdminApiCalls.getCompanyCalendar()
            .then(res => {
                if (res.success && res.result) {
                    init(res.result);
                }
            })
            .finally(() => this.isPreloading = false);

        this.updateFirstDay = () => {
            if (this.firstDay !== weekdays[this.calendar.firstDayOfWeek].item) {
                this.calendar.firstDayOfWeek = weekdays.findIndex(d => d === this.firstDay);
                this.isFDsaving = true;
                AdminApiCalls.updateFirstDayOfWeek({firstDayOfWeek: this.calendar.firstDayOfWeek})
                    .then(data => {
                        ServerConfig.businessCalendar = data.result;
                        this.weekdaysValues = data.result.weekDays.map(i => Object.assign(i, {item: weekdays[i.day]}));
                        moment.updateLocale('en', {
                            week: {dow: data.result.firstDayOfWeek}
                        });
                        this.isFDsaving = false;
                        $scope.$applyAsync(this.isFDsaved = true);
                        $timeout(() => this.isFDsaved = false, 1000);
                    })
                    .catch(err => this.onError(err))
                    .finally(() => this.isFDsaving = false);
            }
        };

        this.isDayDisabled = day => {
            return !day.nonWorking && this.weekdaysValues.filter(d => d.nonWorking).length === 6;
        };
        this.toggleNonWorkingDay = day => {
            if (this.isDayDisabled(day)) {
                return;
            }
            day.nonWorking = !day.nonWorking;
            this.isWDsaving = true;
            AdminApiCalls.updateWeekDay(day)
                .then(data => {
                    ServerConfig.businessCalendar = data.result;
                    this.weekdaysValues = data.result.weekDays.map(i => Object.assign(i, {item: weekdays[i.day]}));
                    this.isWDsaving = false;
                    $scope.$applyAsync(this.isWDsaved = true);
                    $timeout(() => this.isWDsaved = false, 1000);
                })
                .catch(err => this.onError(err))
                .finally(() => this.isWDsaving = false);
        };

        this.toggleCurrentYearForm = isOpen => this.isCurrentYearForm = isOpen;
        this.toggleNextYearForm = isOpen => this.isNextYearForm = isOpen;
        this.saveCurrentYearHoliday = data => {
            ServerConfig.businessCalendar = data.result;
            this.currentYear.holidays = data.result.holidays.filter(h => moment.unix(h.date).utc().year() === moment.utc().year());
            this.isCurrentYearForm = false;
        };
        this.saveNextYearHoliday = data => {
            ServerConfig.businessCalendar = data.result;
            this.nextYear.holidays = data.result.holidays.filter(h => moment.unix(h.date).utc().year() === moment.utc().add(1, 'year').year());
            this.isNextYearForm = false;
        };
        this.removeHoliday = (holiday, year) => {
            year.holidays = year.holidays.filter(y => y !== holiday);
            AdminApiCalls.removeHoliday(holiday.date)
                .then(data => ServerConfig.businessCalendar = data.result)
                .catch(err => this.onError(err));
        };
        this.clearAllYear = (year) => {
            let modalInstance = PageSettings.deleteConfirmation('calendar.holidays', ' ' + year.title + '?');
            modalInstance.result.then(() => {
                year.holidays = [];
                AdminApiCalls.removeAllYearHolidays(year.title)
                    .then(data => ServerConfig.businessCalendar = data.result)
                    .catch(err => this.onError(err));
            });
        };

        this.onError = errResp => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
            this.formHint = errResp.data.displayError || $translate.instant('error.commonTitle');
            timeoutId = $timeout(() => this.formHint = null, config.errorDelay);
        };
    }
}
