import Drop from 'tether-drop';

export function tmplDatePicker($rootScope, $timeout) {
    'ngInject';
    const unitsToPeriod = {
        DAY: 'calendarDays',
        BUSINESS_DAY: 'businessDays',
        WEEK: 'weeks',
        MONTH: 'months'
    };
    const periodToUnits = {
        calendarDays: 'DAY',
        businessDays: 'BUSINESS_DAY',
        weeks: 'WEEK',
        months: 'MONTH'
    };

    return {
        restrict: 'E',
        replace: true,
        scope: {
            interval: '=',
            isTemplate: '@?',
            isEdited: '@?'
        },
        template: `
            <div class="tmpl-date-picker">
                <span class="handler" ng-class="{defined: intervalTmp != null, edited: isEdited}">
                    <i class="icon-task_due_date_add"></i>
                    <i class="icon-task_due_date_add_small"></i>
                    <span ng-if="intervalTmp == null && !isEdited">
                        {{'label.notSet'  | translate}}
                    </span>
                    <span ng-if="isEdited && intervalTmp != null">
                        {{'label.optional' | translate}}
                    </span>
                    <span ng-if="intervalTmp != null">
                        {{'label.in' | translate}} {{'plural.' + activePeriod | translate:getIntervalStr():'messageformat'}} 
                        <span ng-if="intervalTmp != 0">{{'text.AFTER_PROCESS_START' | translate | lowercase}}</span>
                        <span ng-if="intervalTmp == 0">{{'text.asProcessStart' | translate}}</span>
                    </span>
                    <i ng-if="intervalTmp != null" tabindex="-1" class="icon-common_remove" touch-start="clearDate()"
                       ng-click="clearDate(); $event.stopPropagation()"></i>
                </span>
                <input ng-focus="focus()" type="text" style="position: fixed; top: -200px"/>
                <div class="tmpl-picker-content" ng-click="$event.stopPropagation()" [hidden]="!dropOpened">
                    <p>{{isTemplate ? 'text.completeThisProcessIn' : 'text.completeIn' | translate}}</p>
                    <div class="form-group">
                        <input type="tel" pattern="[0-9]{1,8}" ng-model="intervalTmp" id="tmpl-date-picker-input"
                                ng-change="recalc()" ng-keydown="keydown($event)">
                        <ul>
                            <li ng-class="{active: activePeriod == period}" ng-click="setPeriod(period)" 
                                ng-repeat="period in periods">
                                {{'label.' + period | translate}}
                            </li>
                        </ul>
                    </div>
                    <p>{{isTemplate ? 'text.afterStart' : 'text.afterProcessStart' | translate}}</p>
                    <p class="hint">{{'text.tmplDueDate' | translate}}</p>
                    <div class="arrow"></div>
                </div>
            </div>
        `,

        link: (scope, element) => {
            if (scope.isTemplate) {
                scope.indexT = 'deadline';
            } else if (scope.index) {
                scope.indexT = scope.index() || 1;
            }
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.tmpl-picker-content')[0],
                position: 'bottom left',
                openOn: 'click'
            });

            let initial;
            scope.focus = () => {
                if (!initial) {
                    drop.open();
                    initial = true;
                }
            };

            scope.keydown = event => {
                if (event && event.which === 9) {
                    drop.close();
                    $rootScope.$broadcast('tmplDatePicker:tab');
                    event.stopPropagation();
                    event.preventDefault();
                }
            };


            scope.periods = ['calendarDays', 'weeks', 'months'];
            scope.activePeriod = scope.interval ? unitsToPeriod[scope.interval.units] : 'calendarDays';
            if (scope.interval) {
                scope.intervalTmp = scope.interval.interval;
            }

            scope.getIntervalStr = () => {
                return angular.toJson({COUNT: scope.intervalTmp});
            };

            drop.on('open', () => {
                scope.dropOpened = true;
                scope.intervalTmp = scope.interval ? scope.interval.interval : 0;
                scope.recalc();
                $timeout(() => {
                    let el = document.getElementById('tmpl-date-picker-input');
                    if (el) {
                        el.focus();
                    }
                }, 50);
            });

            drop.on('close', () => {
                initial = false;
                scope.dropOpened = null;
                scope.$applyAsync();
            });

            scope.setPeriod = period => {
                scope.activePeriod = period;
                scope.recalc();
            };

            scope.recalc = () => {
                if (scope.intervalTmp != null) {
                    scope.interval = {
                        units: periodToUnits[scope.activePeriod],
                        interval: Number(scope.intervalTmp.toString())
                    };
                }
            };

            scope.$watch('intervalTmp', (newVal, oldVal) => {
                if (newVal == null) {
                    if (angular.isUndefined(newVal) && angular.isDefined(oldVal)) {
                        scope.intervalTmp = oldVal;
                    }
                    return;
                }
                scope.intervalTmp = Number(newVal.toString());
            });

            scope.clearDate = () => {
                scope.interval = null;
                scope.intervalTmp = null;
                scope.activePeriod = 'calendarDays';
                drop.close();
                scope.$applyAsync();
            };

            scope.$on('selectize:open', () => {
                scope.dropOpened = null;
                drop.close();
            });
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}