import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { getService } from '../../react/utils'

const DateTimeFormatter = ({ withTime = false, timestamp, date, ...props }) => {
    const DateHelper = getService('DateHelper')
    const timezone = DateHelper.getTZ()
    const format = withTime
        ? DateHelper.DATE_FORMATS().DATE_TIME_INPUT
        : DateHelper.DATE_FORMATS().DATE_INPUT

    if (!date && timestamp) {
        date = moment.unix(timestamp).toDate()
    }

    return date ? (
        <span {...props}>{moment.tz(date, timezone).format(format)}</span>
    ) : null
}

DateTimeFormatter.propTypes = {
    withTime: PropTypes.bool,
    timestamp: PropTypes.number,
    date: PropTypes.object
}

export default DateTimeFormatter
