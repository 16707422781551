import { PROCESS_STATUS_FILTER_OPTIONS, REPORT_TYPE, TASK_STATUS_FILTER_OPTIONS } from './constants'
import { setDefaultReportProps } from './utils'

const defaultState = {
    templates: [],
    reports: [
        {
            id: 1,
            order: 0,
            name: 'reports.allStarted.name',
            icon: 'reports_all_started',
            description: 'reports.allStarted.description',
            type: REPORT_TYPE.ALL_STARTED_PROCESSES,
            filters: {
                statuses: PROCESS_STATUS_FILTER_OPTIONS.map(opt => opt.id)
            }
        },
        {
            id: 2,
            order: 1,
            name: 'reports.collectedData.name',
            icon: 'reports_collected_data',
            description: 'reports.collectedData.description',
            type: REPORT_TYPE.COLLECTED_DATA,
            filters: {
                multipleTemplates: false,
                statuses: PROCESS_STATUS_FILTER_OPTIONS.map(opt => opt.id)
            }
        },
        {
            id: 3,
            order: 2,
            name: 'reports.taskPerformance.name',
            icon: 'reports_task_performance',
            description: 'reports.taskPerformance.description',
            type: REPORT_TYPE.TASK_PERFORMANCE,
            filters: {
                statuses: PROCESS_STATUS_FILTER_OPTIONS.map(opt => opt.id),
                taskStatuses: TASK_STATUS_FILTER_OPTIONS.map(opt => opt.id)
            }
        }
    ].map(setDefaultReportProps),
    ui: {
        columnsDropdownIsOpen: false,
        exportDropdownIsOpen: false,
        exportDropdownIsAnimated: false
    }
}

export default defaultState
