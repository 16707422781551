export function userSearch() {
    'ngInject';

    return (input, searchText = '') => {
        if (!input && !angular.isArray(input)) {
            return input;
        }

        let checkString = string => {
            return string && string.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        };

        return input.filter(item => {
            return checkString(item.name) || checkString(item.fullName) || checkString(item.email);
        });
    };
}
