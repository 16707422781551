import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import toString from 'lodash/toString'

import config from '../../config'
import { constants, DATE_SYSTEM_FORMAT, EDITABLE, HIDDEN, READ_ONLY } from '../../services/constants'
import appUtils, { escapeHtml, unescapeHtml, uuid } from '../../utils'
import { OPERATORS } from '../templates/services/conditions-config'
import {
    checkAggregationFieldType,
    checkFormulaSettingsError,
    getFieldId,
    getFieldValue,
    parseAggregationOptions,
    prepareFormulaFieldValue,
    updateValue
} from '../templates/services/data-model-utils'
import orderBy from 'lodash/orderBy'
import {
    escapeExpression,
    getStaticPartLength,
    parseExpression,
    STATIC_PART_MAX_LENGTH_FOR_TASK,
    STATIC_PART_MIN_LENGTH
} from '../../components/expression/index'

import {
    AGGREGATION,
    AGGREGATION_TYPES,
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    USER_FIELD
} from '../../services/data-types'
import { getFormattedRecordValues } from '../database/models/database-utils'
import { getService } from '../react/utils'

function prepareTemplateDataModel (dataModel) {
    let dataModelTmp = angular.copy(dataModel)
    dataModelTmp.list.forEach(s => {
        (s.section.fieldsWithValues || s.section.columns).forEach(f => {
            if ([AGGREGATION, FORMULA].includes(f.name.dataType) && f.name.options.expression[f.name.options.expression.length - 1] !== '\n') {
                f.name.options.expression = f.name.options.expression + '\n'
            }
        })

        if (s.section.isTable) {
            if (!s.section.id) {
                s.section.tempId = uuid()
            }
            s.section.columns = s.section.fieldsWithValues
            delete s.section.fieldsWithValues
        } else {
            delete s.section.columns
            s.section.fieldsWithValues = s.section.fieldsWithValues.filter(f => !checkAggregationFieldType(f))
            s.section.fieldsWithValues.forEach(f => {
                delete f.availableFields
                if (f.name.options) {
                    delete f.name.options.aggregation
                    delete f.name.options.totalFieldId
                }
            })
        }
    })
    return dataModelTmp
}

const SPECIAL_VALUES = {
    [constants.PROCESS.SPECIAL_NAME_VALUES.START_DATE]: currentMoment => currentMoment.format(getService('DateHelper').DATE_FORMATS().REPORT_START_DATE),
    [constants.PROCESS.SPECIAL_NAME_VALUES.START_DATE_TIME]: currentMoment => currentMoment.format(getService('DateHelper').DATE_FORMATS().REPORT_START_DATE_TIME),
    [constants.PROCESS.SPECIAL_NAME_VALUES.START_MONTH]: currentMoment => currentMoment.format(getService('DateHelper').DATE_FORMATS().REPORT_START_MONTH),
    [constants.PROCESS.SPECIAL_NAME_VALUES.START_YEAR]: currentMoment => currentMoment.format(getService('DateHelper').DATE_FORMATS().REPORT_START_YEAR),
    [constants.PROCESS.SPECIAL_NAME_VALUES.PROCESS_STARTER]: currentUser => currentUser.fullName,
    [constants.PROCESS.SPECIAL_NAME_VALUES.TEMPLATE_NAME]: templateName => templateName
}

const DATE_FORMAT = {
    DATE_ONLY_VALUE: (value) => {
        const format = getService('DateHelper').DATE_FORMATS().DATE_INPUT
        const utc = moment.unix(value).utc().format(format)
        return moment.utc(utc, format).format(format)
    },
    DATE_AND_TIME: (value, timezone) => {
        const format = getService('DateHelper').DATE_FORMATS().DATE_TIME_INPUT
        return moment.unix(value).utc().tz(timezone).format(format)
    }
}

function getMoneyFieldValue (field) {
    if (field.value) {
        const { options } = field.name
        const { currency, amount } = field.value.moneyValue

        if (amount) {
            const currencies = options.currency.available.concat([options.currency.default])
            const usedCurrency = currencies.find(cur => cur.id === currency)
            return usedCurrency ? `${amount} ${usedCurrency.symbol || usedCurrency.code}` : amount
        }
    }
    return null
}

function getFieldValueByType (field, timezone) {
    const { dataType } = field.name
    const value = field.value ? field.value[Object.keys(field.value)[0]] : null

    if (dataType === MONEY) {
        return getMoneyFieldValue(field)
    } else if (dataType === DATE_AND_TIME || dataType === DATE_ONLY_VALUE) {
        return value ? DATE_FORMAT[dataType](value, timezone) : null
    } else if (dataType === USER_FIELD) {
        return value && value.fullName || null
    } else if (dataType === RADIO_SELECTOR && value && value.recordId) {
        return getFormattedRecordValues(value).name
    }

    return value
}

export function getReplacementFieldValue (field, fields, timezone, currentUser, templateName) {
    if (SPECIAL_VALUES[field.value]) {
        switch (field.value) {
            case constants.PROCESS.SPECIAL_NAME_VALUES.TEMPLATE_NAME:
                return SPECIAL_VALUES[field.value](templateName)
            case constants.PROCESS.SPECIAL_NAME_VALUES.PROCESS_STARTER:
                return SPECIAL_VALUES[field.value](currentUser)
            default:
                const now = moment.utc().tz(timezone)
                return SPECIAL_VALUES[field.value](now)
        }
    }

    const formField = fields.find(f => f.name.id === field.id)

    return formField ? getFieldValueByType(formField, timezone) : null
}

const getSubstrWithMaxLength = (str, availableLength) => {
    return str.length > availableLength ? str.slice(0, availableLength) : str
}

export function prepareStaticProcessName (string) {
    return `${string} - `.slice(0, constants.PROCESS.NAME_MAX_LENGTH)
}

export function generateProcessName (templateObject, timezone, removePlaceholders, currentUser) {
    const { processStart, dataModel } = templateObject
    const dataModelList = dataModel && dataModel.list ? dataModel.list : []
    const fields = dataModelList.map(s => s.section)
        .filter(section => !section.isTable && section.fieldsWithValues)
        .reduce((list, section) => list.concat(section.fieldsWithValues), [])

    const specialReplacements = Object.keys(SPECIAL_VALUES).map(key => {
        return { value: key }
    })

    const replacements = fields.map(field => ({
        id: field.name.id,
        name: field.name.label,
        value: `<@field_${field.name.id}>`
    }))

    let totalLengthOfName = 0
    return parseExpression(processStart.titleSettings.templateString, replacements.concat(specialReplacements)).map(chunk => {
        const availableLength = constants.PROCESS.NAME_MAX_LENGTH - totalLengthOfName
        if (availableLength === 0) {
            return ''
        }
        if (chunk.type === 'field') {
            const emptyValue = removePlaceholders ? '' : `{${chunk.data.name}}`
            let value = getReplacementFieldValue(chunk.data, fields, timezone, currentUser, templateObject.name)
            if (value) {
                value = getSubstrWithMaxLength(value, availableLength)
                totalLengthOfName = totalLengthOfName + value.length
                return value
            } else {
                return emptyValue
            }
        } else {
            let value = getSubstrWithMaxLength(chunk.data, availableLength)
            totalLengthOfName = totalLengthOfName + value.length
            return value
        }
    }).join('')
}

export function setDataModelSettingsForUsedFields (dataModelSettings, usedFields) {
    return dataModelSettings.map(dms => {
        let { visibility } = dms.settings
        dms.isUsed = usedFields.some(field => field.id === dms.fieldId)

        if (dms.isUsed && visibility !== 'EDITABLE' && visibility !== 'REQUIRED') {
            dms.settings.visibility = 'REQUIRED'
        }

        return dms
    })
}

function updateDataModelSettings (dataModelSettings, dataModel, defaultFieldSettings) {
    dataModelSettings = dataModelSettings ? dataModelSettings.slice() : []

    const fields = dataModel.list.reduce((acc, cur) => acc.concat((cur.section.fieldsWithValues || cur.section.columns).map(f => f.name)), [])
    const byId = dataModelSettings.reduce((acc, f) => Object.assign(acc, { [f.fieldId]: f }), {})

    return fields.reduce((settings, field) => {
        const id = field.id || field.tempId
        const originalSettings = field.originalId && byId[field.originalId] ? byId[field.originalId].settings : {}
        const fieldSettings = {
            fieldId: id,
            settings: byId[id] ? byId[id].settings : Object.assign({}, defaultFieldSettings, originalSettings)
        }

        if (field.tempId) {
            fieldSettings.isNewEntry = true
        }

        if (field.dataType === AGGREGATION && field.options) {
            const sourceFieldSettings = settings.find(({ fieldId }) => fieldId === field.options.sourceFieldId)
            if (!sourceFieldSettings) {
                return settings
            }

            fieldSettings.settings.visibility = sourceFieldSettings.settings.visibility === HIDDEN
                ? HIDDEN
                : READ_ONLY
        }

        if (field.dataType === FORMULA) {
            fieldSettings.settings.visibility = fieldSettings.settings.visibility === HIDDEN
                ? HIDDEN
                : READ_ONLY
            fieldSettings.$isFormula = true
        }

        return settings.concat([fieldSettings])
    }, [])
}

function updateProcessStartDataModelSettings (dataModelsSettings, dataModel) {
    const DEFAULT_FIELD_SETTINGS = {
        visibility: HIDDEN
    }

    return updateDataModelSettings(dataModelsSettings, dataModel, DEFAULT_FIELD_SETTINGS)
}

function updateTaskDataModelSettings (dataModelsSettings, dataModel) {
    const DEFAULT_FIELD_SETTINGS = {
        isPrerequisite: false,
        visibility: EDITABLE
    }

    return updateDataModelSettings(dataModelsSettings, dataModel, DEFAULT_FIELD_SETTINGS)
}

export function updateFieldVisibilitySettings (dataModelSettings, fieldId, visibilityType, isFormula) {
    const fieldSettings = dataModelSettings.find(dms => dms.fieldId === fieldId)
    fieldSettings.settings.visibility = visibilityType
    fieldSettings.$isFormula = isFormula
}

function dataModelSettingsToServer (dataModelSettings) {
    return dataModelSettings.map(field => {
        const fieldCopy = angular.copy(field)
        if (fieldCopy.isNewEntry) {
            delete fieldCopy.isNewEntry
            delete fieldCopy.fieldId
        }
        delete fieldCopy.isUsed
        return fieldCopy
    })
}

function processStartToServer (processStart, dataModel) {
    let result = angular.copy(processStart)
    let { titleSettings, dataModelSettings } = processStart
    let { templateString, usedFields } = titleSettings

    delete result.titleSettings.usedFields
    delete result.titleSettings.$error

    dataModelSettings = updateProcessStartDataModelSettings(dataModelSettings, dataModel)
    dataModelSettings = setDataModelSettingsForUsedFields(dataModelSettings, usedFields)
    result.dataModelSettings = dataModelSettingsToServer(dataModelSettings)
    if (!result.dataModelSettings.length) {
        delete result.dataModelSettings
    }

    if (templateString === '') {
        delete result.titleSettings
    } else {
        result.titleSettings.templateString = escapeExpression(templateString, usedFields)
        result.titleSettings.isAutoGenerated = true
    }

    return result
}

function processStartDataFieldValue (field) {
    const { dataType } = field.name

    if (dataType === MONEY && field.value && field.value.moneyValue && field.value.moneyValue.amount) {
        let result = angular.copy(field.value)
        result.moneyValue.amount = Number(result.moneyValue.amount.toString().replace(/\s/g, ''))
        return result
    }

    if (dataType === NUMERIC_VALUE && field.value && field.value.numericValue) {
        let result = angular.copy(field.value)
        result.numericValue = Number(result.numericValue.toString().replace(/\s/g, ''))
        return result
    }

    if (dataType === FILES_LIST && field.value) {
        let { files = [] } = field.value
        if (files.length) {
            return { files: files.map(file => angular.copy(file)) }
        }
    }

    if (field.value && Object.keys(field.value).length && field.value[Object.keys(field.value)[0]]) {
        return angular.copy(field.value)
    }

    return { noValue: true }
}

export function processStartDataModelToServer (dataModel, dataModelSettings) {
    const fieldIsEditable = field => dataModelSettings.find(dms => dms.fieldId === field.name.id).settings.isEditable
    const sections = dataModel.list.map(item => item.section)
    const list = sections.map(section => {
        let newItem = { section: { id: section.id } }
        if (!section.isTable && section.fieldsWithValues) {
            newItem.section.fieldsWithValues = section.fieldsWithValues.filter(f => f.name.dataType !== FORMULA && fieldIsEditable(f)).map(field => {
                return {
                    name: { id: field.name.id },
                    value: processStartDataFieldValue(field)
                }
            })
        } else if (section.isTable && section.rows) {
            newItem.section.rows = section.rows.map(row => {
                return {
                    values: row.values.filter(c => !c.readOnly).map(col => {
                        const column = section.columns.find(c => getFieldId(c) === col.columnId)
                        return {
                            columnId: col.columnId,
                            value: processStartDataFieldValue(Object.assign({}, column, { value: col.value }))
                        }
                    })
                }
            })
        }

        return newItem
    })

    return { list }
}

export function fieldValueToString (field) {
    const MomentHelper = getService('MomentHelper')

    const { value, name } = field
    const fieldHasDataType = dataType => name.dataType === dataType

    if (!value) {
        return ''
    }

    if (fieldHasDataType(RADIO_SELECTOR)) {
        if (!value.radioButtonValue) {
            return ''
        }
        if (typeof value.radioButtonValue === 'string') {
            return value.radioButtonValue
        } else if (value.radioButtonValue.item) {
            return value.radioButtonValue.item
        } else if (value.radioButtonValue.recordId) {
            return getFormattedRecordValues(value.radioButtonValue).name
        }
        return ''
    }

    if (fieldHasDataType(MULTI_SELECTOR)) {
        return value.multiChoiceValue
            .map(item => typeof item === 'string' ? item : getFormattedRecordValues(item).name)
            .join(', ')
    }

    if (fieldHasDataType(USER_FIELD)) {
        const { fullName = '', email = '' } = value.userValue || {}
        return fullName.trim() || email || ''
    }

    if (fieldHasDataType(DATE_ONLY_VALUE)) {
        if (!value.dateValue) {
            return ''
        }
        return MomentHelper.formatInputDate(value.dateValue)
    }

    if (fieldHasDataType(DATE_AND_TIME)) {
        if (!value.dateValue) {
            return ''
        }
        return MomentHelper.formatInputDateTime(value.dateValue)
    }

    if (fieldHasDataType(FILES_LIST)) {
        return value.files.filter(f => f.id).map(f => f.id).join(',')
    }

    if (fieldHasDataType(MONEY) || (fieldHasDataType(FORMULA) && value.moneyValue)) {
        const { amount, currencyInfo } = value.moneyValue
        return amount ? `${amount.toString().replace(/\s/g, '')} ${currencyInfo.symbol || currencyInfo.code}` : ''
    }

    if (fieldHasDataType(NUMERIC_VALUE) || (fieldHasDataType(FORMULA) && value.numericValue)) {
        return (value.numericValue || '').toString().replace(/\s/g, '')
    }

    return (value[Object.keys(value)[0]] || '').toString().trim()
}

function fieldHasUnsavedFile (field) {
    return field.uploading || (field.value && field.value.files && field.value.files.find(f => !f.id))
}

function fieldHasUnsavedValue (field) {
    return field.saving || (field.$originalValue && field.$originalValue !== fieldValueToString(field))
}

export function findSavingFields (dataModel, uploadingOnly = false) {
    const { list = [] } = dataModel || {}
    const sections = list.map(item => item.section)
    const savingFields = []
    sections.forEach(section => {
        if (!section.isTable && section.fieldsWithValues) {
            section.fieldsWithValues.filter(f => (!uploadingOnly && fieldHasUnsavedValue(f)) || fieldHasUnsavedFile(f)).forEach(f => {
                savingFields.push(f)
            })
        }
    })
    return savingFields
}

export function findFieldsWithInvalidRecords (dataModel) {
    const { list = [] } = dataModel || {}
    const invalidFields = []
    list.map(item => item.section).forEach(section => {
        if (!section.isTable && section.fieldsWithValues) {
            section.fieldsWithValues.forEach(field => {
                const { name, value = {} } = field
                const radioButtonWithDeletedRecord = name.dataType === RADIO_SELECTOR && value.radioButtonValue && value.radioButtonValue.isInvalid
                const multiChoiceWithDeletedRecord = name.dataType === MULTI_SELECTOR && value.multiChoiceValue && value.multiChoiceValue.find(r => r.isInvalid)

                if (radioButtonWithDeletedRecord || multiChoiceWithDeletedRecord) {
                    invalidFields.push(field)
                }
            })
        }
    })
    return invalidFields
}

export function formFieldToSpecialRole (field, $translate) {
    return {
        name: $translate.instant('label.actor.formField') + `: ${field.name}`,
        formField: {
            id: field.id,
            name: field.name,
            dataType: 'USER_FIELD'
        }
    }
}

export function updateTaskSpecialRoles (task, $translate) {
    const formFields = (task.actors.formFields || []).map(field => Object.assign(field, formFieldToSpecialRole(field, $translate)))
    task.actors.specialRoles = [].concat(task.actors.specialRoles, formFields)
    task.actors.formFields = []
}

const conditionForEdit = (condition, formFields, isGrouped = false) => {
    const fields = isGrouped ? flattenFormFields(formFields) : formFields
    const formField = fields.find(field => condition.fieldId === field.id)
    const expression = { ...condition.expression }
    if (formField.name.options && formField.name.options.useTableAsSource && formField.name.options.source.tableId && !formField.name.options.source.isInvalid) {
        expression.tableId = formField.name.options.source.tableId
    }
    return {
        field: {
            id: condition.fieldId,
            label: condition.fieldLabel,
            dataType: formField.dataType
        },
        operation: {
            id: condition.operation
        },
        expression
    }
}

const DEFAULT_SECTION_NAME = 'Section 1'
const DEFAULT_FIELD_NAME = 'Sample form field'

export function traverseSubTreeItems (subTree, callbackFn, path = []) {
    subTree.forEach((item, index) => {
        callbackFn(item, index, path)
        if (item.group && item.group.subTree && item.group.subTree.length) {
            let localPath = path.slice()
            localPath.push(index)
            traverseSubTreeItems(item.group.subTree, callbackFn, localPath)
        }
    })
}

const formatField = field => {
    let fieldProps = Object.assign({}, field.name)
    let { dataType, options } = fieldProps

    if (dataType === 'STRING_MULTI_LINE' && (!options || !options.maxLength)) {
        // Workaround for multiline field - add max length
        fieldProps.options = Object.assign({}, options || {}, { maxLength: 2000 })
    }

    if ((dataType === 'MONEY' || dataType === 'NUMERIC_VALUE') && (!options || !options.precision)) {
        // Workaround for money field - add default precision
        fieldProps.options = Object.assign({}, options || {}, { precision: 2 })
    }

    return {
        id: fieldProps.tempId || fieldProps.id,
        value: fieldProps.tempId || fieldProps.id,
        item: fieldProps.label || '',
        label: fieldProps.label || '',
        name: fieldProps,
        dataType: dataType
    }
}

export function flattenFormFields (groupedFormFields) {
    return groupedFormFields.reduce((fields, section) => fields.concat(section.options), [])
}

const conditionsFieldsFilter = section => field => {
    const hasName = field && field.name && field.name.label
    const dataTypeIsAllowed = !section.isTable || field.name.dataType === AGGREGATION
    return hasName && dataTypeIsAllowed
}

function updateConditionFormFields (dataModel, grouped = false) {
    const groupedFormFields = dataModel.list
        .map(item => ({ ...item.section, id: item.section.id || appUtils.uid4() }))
        .map(section => {
            return {
                label: section.name,
                options: section.fieldsWithValues
                    .filter(conditionsFieldsFilter(section))
                    .map(formatField)
            }
        })

    if (grouped) {
        return groupedFormFields
    } else {
        return flattenFormFields(groupedFormFields)
    }
}

function updateDueDateFields (dataModel) {
    return dataModel.list
        .map(item => item.section)
        .filter(section => !section.isTable)
        .map(section => section.fieldsWithValues)
        .reduce((fields, section) => fields.concat(section), [])
        .filter(field => field.name.dataType === DATE_ONLY_VALUE)
        .map(field => {
            let fieldProps = Object.assign({}, field.name)
            let { dataType } = fieldProps

            return {
                id: fieldProps.tempId || fieldProps.id,
                item: fieldProps.label || 'Field name',
                name: fieldProps,
                dataType: dataType
            }
        })
}

export const DEFAULT_PROCESS_NAME = 'templateEdit.defaultProcessName'

export const prepareTableSection = section => {
    section.fieldsWithValues = [...section.columns, ...(section.fieldsWithValues || [])]

    section.fieldsWithValues.forEach(field => {
        const { dataType, options } = field.name
        if ([AGGREGATION, FORMULA].includes(dataType) && options.expression[options.expression.length - 1] !== '\n') {
            field.name.options.expression = options.expression + '\n'
        }
        if (options && options.totalFieldId) {
            const { totalFieldId } = field.name.options
            const totalField = section.fieldsWithValues.find(f => f.name.id === totalFieldId)
            if (totalField) {
                totalField.name.options.sourceFieldId = field.name.id
                field.name.options.aggregation = parseAggregationOptions(totalField)
            }
        }
    })

    return section
}

export function prepareTemplateModel (template, currentUser, actorsTree, $translate, editTemplateMode = false) {
    template = template || {}

    let defaultModel = {
        icon: {},
        name: '',
        description: '',
        shortDescription: '',
        subTree: [],
        execution: constants.PROCESS.EXECUTION.SEQUENTIAL,
        editors: { users: [], specialRoles: [], groups: [] },
        starters: { users: [], specialRoles: [], groups: [] },
        managers: { users: [], groups: [], specialRoles: [{ isProcessStarter: true, name: $translate.instant('label.processStarter') }] },
        watchers: { users: [], groups: [], specialRoles: [] },
        processStart: {
            titleSettings: {
                isAutoGenerated: false,
                templateString: '',
                usedFields: []
            },
            dataModelSettings: []
        },
        dataModel: { list: [] }
    }

    if (currentUser) {
        defaultModel.editors = { users: [currentUser], specialRoles: [], groups: [] }
    }

    if (actorsTree) {
        defaultModel.starters = {
            users: [],
            specialRoles: [actorsTree.specialRoles.find(role => role.allUsers)],
            groups: []
        }
    }

    let model = Object.assign({}, defaultModel, template, {
        processStart: Object.assign({}, defaultModel.processStart, template.processStart || {}),
        dataModel: Object.assign({}, defaultModel.dataModel, template.dataModel || {})
    })

    if (model.id && model.dueDateInterval) {
        model.dueDateInterval.calculation = 'AFTER_PROCESS_START'
    }

    if (!model.id) {
        model.dataModel.list.push(
            {
                section: {
                    name: $translate.instant(DEFAULT_SECTION_NAME),
                    isTable: false,
                    fieldsWithValues: [
                        {
                            name: {
                                tempId: uuid(),
                                label: $translate.instant(DEFAULT_FIELD_NAME),
                                dataType: 'STRING_SINGLE_LINE'
                            }
                        }
                    ]
                }
            }
        )
        model.processStart.titleSettings.isAutoGenerated = true
        model.processStart.titleSettings.templateString = $translate.instant(DEFAULT_PROCESS_NAME)
    }

    model.dataModel.list.forEach(s => {
        if (s.section.isTable) {
            s.section = prepareTableSection(s.section)
        }
    })

    let conditionFormFields = []

    if (model.dataModel.list.length) {
        model.processStart.dataModelSettings = updateProcessStartDataModelSettings(model.processStart.dataModelSettings, model.dataModel)
        conditionFormFields = updateConditionFormFields(model.dataModel, editTemplateMode)
    }

    if (model.processStart.titleSettings.isAutoGenerated) {
        model.processStart.titleSettings.templateString = unescapeHtml(model.processStart.titleSettings.templateString)
    }

    traverseSubTreeItems(model.subTree, item => {
        const KEY = Object.keys(item)[0].toLowerCase()

        if (item.task) {
            item.task.titleSettings = {
                templateString: unescapeHtml(item.task.name),
                usedFields: []
            }
            item.task.description = item.task.description || ''
            if (item.task.actors) {
                updateTaskSpecialRoles(item.task, $translate)
            }
            item.task.assignRule = item.task.assignRule || 'MANUAL'
        }

        if (item.group) {
            item.group.name = unescapeHtml(item.group.name)
        }

        if (item[KEY].conditions && item[KEY].conditions.list) {
            item[KEY].conditions.list = item[KEY].conditions.list.map(condition => conditionForEdit(condition, conditionFormFields, editTemplateMode))
        }
    })

    return model
}

let getProcessesList = data => {
    if (!data || !data.list) {
        return []
    }
    return data.list.map(item => {
        if (item.tasks) {
            item.tasks.forEach(task => {
                if (task.assignee) {
                    task.assignee = data.users.find(user => {
                        return task.assignee.id === user.id
                    })
                }
                if (task.completedBy) {
                    task.completedBy = data.users.find(user => {
                        return task.completedBy.id === user.id
                    })
                }
            })
        }
        if (item.assignees) {
            item.assignees = item.assignees.map(assignee => {
                return data.users.find(user => {
                    return assignee.id === user.id
                })
            })
        }
        item.starter = data.users.find(user => {
            return user.id === item.starter.id
        })
        return item
    })
}

let checkTemplateDataModel = data => {
    if (data && data.list && !data.list.length) {
        return true
    }
    return data && data.list && data.list.length === 1 && !data.list[0].section.name
        && data.list[0].section.fieldsWithValues.length === 1
        && !data.list[0].section.fieldsWithValues[0].name.label
        && data.list[0].section.fieldsWithValues[0].name.dataType === 'STRING_SINGLE_LINE'
        && !data.list[0].section.fieldsWithValues[0].name.options
}

let checkDefaultDataModelSettings = (tasks, data) => {
    if (!tasks || !data || !data.list || !data.list.length) {
        return true
    }
    return tasks.find(t => {
        if (t.dataModelSettings && t.dataModelSettings.length) {
            let settings = t.dataModelSettings[0].settings
            return t.dataModelSettings.length === 1 && !settings.isPrerequisite && settings.visibility === 'EDITABLE'
        }
        return true
    })
}

const itemHasConditions = item => {
    return item.conditions && item.conditions.list && item.conditions.list.length > 0
}

const taskIsDefault = task => {
    let checkActors = actors => {
        return actors && (actors.users.length || actors.groups.length || actors.specialRoles.length)
    }

    return !(task.name || task.description || task.dueDateInterval || task.dueDate || checkActors(task.actors) || itemHasConditions(task))
}

const groupIsDefault = group => {
    const groupHasOnlyDefaultTask = group.subTree.length === 1 && group.subTree[0] && group.subTree[0].task && taskIsDefault(group.subTree[0].task)
    return !(group.name || itemHasConditions(group) || !groupHasOnlyDefaultTask)
}

const itemIsDefault = item => {
    return item.group ? groupIsDefault(item.group) : taskIsDefault(item.task)
}

function prepareActorsToSave (actors) {
    if (actors) {
        let result = {
            users: [],
            groups: [],
            specialRoles: [],
            formFields: actors.formFields || []
        }
        actors.users.forEach(user => {
            let data = { id: user.id, isUser: true }
            if (user.isDeleted) {
                data.isDeleted = true
            }
            result.users.push(data)
        })
        actors.groups.filter(g => g.id !== 'all-users').forEach(group => {
            let data = { id: group.id, isUser: false }
            if (group.isDeleted) {
                data.isDeleted = true
            }
            result.groups.push(data)
        })
        actors.specialRoles.forEach(role => {
            if (role.isProcessStarter) {
                result.specialRoles.push({ isProcessStarter: true })
            }
            if (role.isProcessManager) {
                result.specialRoles.push({ isProcessManager: true })
            }
            if (role.allUsers) {
                result.specialRoles.push({ allUsers: true })
            }
            if (role.formField) {
                result.formFields.push({ id: role.formField.id, name: role.formField.name })
            }
        })
        return result
    }
}

const getExpressionValueKey = (expression) => {
    const availableExpressionKeys = ['string', 'number', 'date', 'record']
    return Object.keys(expression).find(key => availableExpressionKeys.includes(key))
}

const expressionToServer = (expression) => {
    const validKey = getExpressionValueKey(expression)
    let value = expression[validKey]

    if (validKey === 'number') {
        value = parseFloat(value.toString().replace(/\s/g, ''))
    }

    return { [validKey]: value }
}

const conditionToServer = (condition, conditionsConfig) => {
    const result = {}

    if (condition.operation && condition.operation.id) {
        const OPERATION = conditionsConfig.AVAILABLE_OPERATIONS.find(operation => operation.id === condition.operation.id)
        if (OPERATION) {
            result.operation = OPERATION.id

            if (OPERATION.hasExpression) {
                result.expression = expressionToServer(condition.expression)
            }
        }
    }

    if (condition.field && condition.field.id && result.operation) {
        result.fieldId = condition.field.id
    }

    return result
}

const conditionsToServer = (conditions, conditionsConfig) => {
    return {
        rule: conditions.rule,
        list: conditions.list
            .map(condition => conditionToServer(condition, conditionsConfig))
            .filter(condition => condition.fieldId)
    }
}

const dueDateIntervalToServer = (dueDateInterval) => {
    if (!dueDateInterval || dueDateInterval.interval === undefined) {
        return null
    }

    const calculation = dueDateInterval.calculation.split('_')
    if (calculation.length === 4) {
        const staticPart = calculation.slice(1).join('_')
        if (staticPart === 'FIELD_DATE_BEFORE' || staticPart === 'FIELD_DATE_AFTER') {
            return { ...dueDateInterval, calculation: staticPart, linkedFieldId: calculation[0] }
        }
    }

    return dueDateInterval
}

function taskToServer (task, dataModel) {
    let taskJSON = {
        actors: {
            users: [],
            groups: [],
            specialRoles: [],
            formFields: []
        },
        type: task.type,
        description: task.description || '',
        dueDate: task.dueDate || null,
        dueDateInterval: dueDateIntervalToServer(task.dueDateInterval),
        assignRule: task.assignRule
    }

    if (task.id) {
        taskJSON.id = task.id
    }

    if (task.dataModelSettings && task.dataModelSettings.length) {
        const dms = dataModel ? updateTaskDataModelSettings(task.dataModelSettings, dataModel) : task.dataModelSettings
        taskJSON.dataModelSettings = dataModelSettingsToServer(dms)
    }

    const { templateString, usedFields } = task.titleSettings
    taskJSON.name = escapeExpression(templateString, usedFields)

    if (task.actors) {
        task.actors.users.forEach(user => {
            let data = { id: user.id, isUser: true }
            if (user.isDeleted) {
                data.isDeleted = true
            }
            taskJSON.actors.users.push(data)
        })
        task.actors.groups.forEach(group => {
            let data = { id: group.id, isUser: false }
            if (group.isDeleted) {
                data.isDeleted = true
            }
            taskJSON.actors.groups.push(data)
        })
        task.actors.specialRoles.forEach(role => {
            if (role.isProcessStarter) {
                taskJSON.actors.specialRoles.push({ isProcessStarter: true })
            }
            if (role.isProcessManager) {
                taskJSON.actors.specialRoles.push({ isProcessManager: true })
            }
            if (role.isStarterDirectManager) {
                taskJSON.actors.specialRoles.push({ isStarterDirectManager: true })
            }
            if (role.allUsers) {
                taskJSON.actors.specialRoles.push({ allUsers: true })
            }
            if (role.formField) {
                taskJSON.actors.formFields.push({ id: role.formField.id, name: role.formField.label })
            }
        })
    }

    return taskJSON
}

function groupToServer (group) {
    const groupJSON = {
        name: escapeHtml(group.name),
        subTree: group.subTree,
        execution: group.execution
    }

    if (group.id) {
        groupJSON.id = group.id
    }

    return groupJSON
}

function subTreeToServer (subTree, dataModel, conditionsConfig) {
    const preparedSubTree = subTree.map(item => {
        let result
        const KEY = Object.keys(item)[0].toLowerCase()

        switch (KEY) {
            case 'task':
                result = { task: taskToServer(item.task, dataModel) }
                break
            case 'group':
                result = { group: groupToServer(item.group, dataModel) }
                break
            default:
        }

        if (result) {
            const { conditions } = item[KEY]
            if (conditions && [conditionsConfig.OPERATORS.AND, conditionsConfig.OPERATORS.OR].indexOf(conditions.rule) > -1) {
                const preparedConditions = conditionsToServer(conditions, conditionsConfig)
                if (preparedConditions.list.length) {
                    result[KEY].conditions = preparedConditions
                }
            }
        }

        if (result && result.group && result.group.subTree) {
            result.group.subTree = subTreeToServer(result.group.subTree, dataModel, conditionsConfig)
        }

        return result
    })

    return preparedSubTree.filter(item => item && item.group || (item.task && !taskIsDefault(item.task)))
}

const checkActorsIsEmpty = actors => {
    if (!actors) {
        return null
    }
    return !actors.users.length && !actors.groups.length && !actors.specialRoles.length
}
const checkLimitUsersExceed = (actors, count) => {
    if (!actors || !actors.users) {
        return false
    } else {
        return actors.users.length > count
    }
}

const compareActors = (actors1, actors2) => {
    let compareArrays = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false
        }
        return arr1.every(item1 => {
            return arr2.findIndex(item2 => {
                return item1.id === item2.id
            }) !== -1
        })
    }
    if (!actors1 && !actors2) {
        return true
    } else if (!actors1 || !actors2) {
        return false
    } else {
        return compareArrays(actors1.groups, actors2.groups) && compareArrays(actors1.users, actors2.users) && compareArrays(actors1.specialRoles, actors2.specialRoles)
    }
}

const checkInvalidActors = actors => {
    if (!actors) {
        return null
    }
    let deleted
    if (actors.users) {
        deleted = actors.users.find(user => user.isDeleted)
    }
    if (actors.groups && !deleted) {
        deleted = actors.groups.find(group => group.isDeleted)
    }
    if (actors.specialRoles && !deleted) {
        deleted = actors.specialRoles.find(role => role.isDeleted)
    }
    return deleted
}

let getActorsNames = (task, $translate, isTmpl) => {
    if (!task) {
        return
    }
    let actors = task.actors
    if (!actors) {
        return (task.hasActors && !isTmpl) ? $translate.instant('label.noUsers') : $translate.instant('task.template.actors.empty.label')
    }

    let result = []
    let notEmpty = false
    if (actors.specialRoles && actors.specialRoles.length) {
        let rolesNames = actors.specialRoles.filter(r => !r.isDeleted).map(role => {
            notEmpty = true
            return role.name
        }).join(', ')

        if (rolesNames) {
            result.push(rolesNames)
        }
    }
    if (actors.groups && actors.groups.length) {
        let groupsNames = actors.groups.filter(g => !g.isDeleted).map(group => {
            notEmpty = true
            return group.name
        }).join(', ')

        if (groupsNames) {
            result.push(groupsNames)
        }
    }
    if (actors.users && actors.users.length) {
        let usersNames = actors.users.filter(u => !u.isDeleted).map(user => {
            notEmpty = true
            return user.name || user.fullName
        }).join(', ')

        if (usersNames) {
            result.push(usersNames)
        }
    }

    if (notEmpty) {
        result = result.join(', ')
    } else {
        return (task.hasActors && !isTmpl) || checkInvalidActors(task.actors)
            ? $translate.instant('label.noUsers')
            : $translate.instant('task.template.actors.empty.label')
    }
    return result
}

let processToCompare = (process) => {
    if (!process) {
        return process
    }

    let result = angular.copy(process)
    let clearArray = (arr) => {
        arr.forEach(item => {
            delete item.color
            if (angular.isDefined(item.isActive)) {
                delete item.isActive
            }
            if (angular.isDefined(item.fullName)) {
                delete item.fullName
            }
            if (angular.isDefined(item.name)) {
                delete item.name
            }
            if (angular.isDefined(item.users)) {
                delete item.users
            }
        })
    }

    result.tasks.forEach(task => {
        if (task.actors) {
            clearArray(task.actors.users)
            clearArray(task.actors.groups)
            clearArray(task.actors.specialRoles)
        } else {
            task.actors = {
                groups: [],
                users: [],
                specialRoles: []
            }
        }
        if (task.dueDateInterval && !task.dueDate) {
            task.dueDate = moment.utc(moment().format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).add(task.dueDateInterval, 'days').unix()
        }
    })
    return result
}

let templateToCompare = (template) => {
    if (!template) {
        return template
    }

    const result = angular.copy(template)
    const clearArray = (arr) => {
        arr.forEach(item => {
            delete item.color
            if (angular.isDefined(item.isActive)) {
                delete item.isActive
            }
            if (angular.isDefined(item.fullName)) {
                delete item.fullName
            }
            if (angular.isDefined(item.name)) {
                delete item.name
            }
            if (angular.isDefined(item.users)) {
                delete item.users
            }
        })
    }

    if (result.icon && !result.icon.id && result.icon.color) {
        delete result.icon.color
    }

    if (result.dueDateInterval === null) {
        delete result.dueDateInterval
    }

    result.subTree.forEach(item => {
        if (!item.task) {
            return
        }

        if (item.task.actors) {
            clearArray(item.task.actors.users)
            clearArray(item.task.actors.groups)
            clearArray(item.task.actors.specialRoles)
        } else {
            item.task.actors = {
                groups: [],
                users: [],
                specialRoles: []
            }
        }
        item.task.dueDateInterval = item.task.dueDateInterval || null
    })

    return result
}

let checkInvalidNameValue = (val, noMin) => {
    if (noMin) {
        return !val || !val.match(config.pattern)
    }
    return !val || val.length < 3 || !val.match(config.pattern)
}

let checkInvalidUsers = obj => {
    if (!obj.tasks) {
        return
    }
    return obj.tasks.find(t => checkInvalidActors(t.actors))
}
const isInvalidDueDateInterval = obj => {
    return obj.dueDateInterval && obj.dueDateInterval.calculation && obj.dueDateInterval.interval == null
}

const isInvalidTaskTitle = (task) => {
    const { titleSettings } = task
    return titleSettings.$error !== undefined
        || !titleSettings.templateString
        || getStaticPartLength(titleSettings.templateString, titleSettings.usedFields) < STATIC_PART_MIN_LENGTH
        || getStaticPartLength(titleSettings.templateString, titleSettings.usedFields) > STATIC_PART_MAX_LENGTH_FOR_TASK
}

const isInvalidItemTitle = item => {
    if (item.task) {
        return isInvalidTaskTitle(item.task)
    }
    if (item.group) {
        return !item.group.name || item.group.name.length < 3
    }
}

const taskActorsExceedLimit = actors => {
    return checkLimitUsersExceed(actors, constants.USER_LIMITS.TASK)
}

let updateActorsWithInvalid = (actors, resp) => {
    if (!actors) {
        return
    }
    if (actors.users) {
        actors.users.forEach(u => {
            if (resp.users && resp.users.find(uT => uT === u.id)) {
                u.isDeleted = true
            }
        })
    }
    if (actors.groups) {
        actors.groups.forEach(g => {
            if (resp.groups && resp.groups.find(gT => gT === g.id)) {
                g.isDeleted = true
            }
        })
    }
}

let calcSettingsForTask = (dataModel, task) => {
    const defaults = {
        isPrerequisite: false,
        visibility: 'EDITABLE'
    }

    let allFields = []
    if (dataModel) {
        dataModel.list.forEach(s => {
            allFields = allFields.concat(s.section.fieldsWithValues)
        })
    }

    let backup
    if (task.dataModelSettings) {
        backup = task.dataModelSettings.slice()
    }
    let checkSettings = (id, tempId) => {
        if (!backup) {
            return angular.copy(defaults)
        }
        let foundField = backup.find(field => (id && field.fieldId === id) || (tempId && field.tempId === tempId))
        return foundField ? foundField.settings : angular.copy(defaults)
    }

    let result = allFields.map(item => {
        let data = {
            fieldId: item.name.id,
            settings: checkSettings(item.name.id, item.name.tempId)
        }
        if (item.name.tempId) {
            data.tempId = item.name.tempId
        }
        return data
    })
    task.dataModelSettings = result ? result.slice() : []
}

let checkPrDueDate = (process, isPast, DateHelper) => {
    if (!process || (!process.dueDate && !process.dueDateInterval)) {
        return
    }

    let workingHoursStart = DateHelper.getWorkingTimeStart()
    let tz = DateHelper.getTZ()
    let nowInTimeZone = moment().tz(tz)

    let date
    if (process.dueDate) {
        date = moment.unix(process.dueDate).tz(tz)
    } else {
        date = nowInTimeZone.clone().add(process.dueDateInterval, 'days')
    }

    if (isPast) {
        return date.isBefore(nowInTimeZone) //Target Date is Past Due
    }

    //in other cases return True if Target Date should be highlighted in RED, i.e. Date is Past Due, Today or Tomorrow
    let endOfToday = nowInTimeZone.clone().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0
    }).add(workingHoursStart, 'hours')
    let endOfTomorrow = endOfToday.clone().add(1, 'day')

    return date.isBefore(endOfTomorrow)
}

const getDueDate = (process, DateHelper, short = false) => {
    const formatT = short ? DateHelper.DATE_FORMATS().DUE_DATE_SHORT : DateHelper.DATE_FORMATS().DUE_DATE_LONG
    if (process.dueDate) {
        return moment.unix(process.dueDate).tz(DateHelper.getTZ()).format(formatT)
    } else if (process.dueDateInterval) {
        return moment().add(process.dueDateInterval, 'days').tz(DateHelper.getTZ()).format(formatT)
    }
    return null
}

let invalidSelectorField = field => {
    if (['MULTI_SELECTOR', 'RADIO_SELECTOR'].includes(field.name.dataType)) {
        if (!field.name.options || !field.name.options.source) {
            return 'emptyValue'
        }

        if (field.name.options.useTableAsSource) {
            const invalidTable = !field.name.options.source.tableId || field.name.options.source.isInvalid
            if (invalidTable) {
                return 'emptyValue'
            }
            const defaultValue = field.name.defaultValue
            const invalidDefaultValue = defaultValue && defaultValue.radioButtonValue && defaultValue.radioButtonValue.isInvalid
            if (invalidDefaultValue) {
                return 'settings'
            }
        } else {
            const emptyValue = !field.name.options.source.allowedValues || !field.name.options.source.allowedValues.length
            return emptyValue ? 'emptyValue' : false
        }
    }

    return false
}

const invalidUserFieldSettingsField = (field) => {
    if (field.name.dataType === USER_FIELD) {
        const { options, defaultValue } = field.name
        const optionsInvalid = options && options.group && options.group.isDeleted
        const defaultValueInvalid = defaultValue && defaultValue.userValue && defaultValue.userValue.isDeleted
        return optionsInvalid || defaultValueInvalid
    }
    return false
}

const invalidTemplateDataModel = (dataModel, form) => {
    const invalidDataModelField = dataModel.list.find((item, index) => {
        return form && form[`s-${index}`] && form[`s-${index}`].$invalid
            || (
                item.section.fieldsWithValues.find(f => {
                    return !f.name.label
                        || form[getFieldId(f)]?.$invalid
                        || invalidSelectorField(f)
                        || invalidUserFieldSettingsField(f)
                        || checkFormulaSettingsError(f)
                })
            )
    })

    return !!invalidDataModelField
}

const removeEmptyEntriesFromSubTree = (source) => {
    const defaultItems = source.subTree.filter(item => itemIsDefault(item))
    source.subTree = source.subTree.filter(item => !itemIsDefault(item))

    if (!source.subTree.length && defaultItems.length) {
        source.subTree[0] = defaultItems[0]
    }

    source.subTree.forEach(item => {
        if (item.group) {
            removeEmptyEntriesFromSubTree(item.group)
        }
    })
}

const expressionIsInvalid = (operation, expression) => {
    if (operation.hasExpression) {
        if (expression.tableId) {
            return !expression.record || !expression.record.recordId || expression.record.isInvalid
        }

        const validKey = getExpressionValueKey(expression)
        if (!validKey) {
            return true
        }
        return toString(expression[validKey]) === ''
    }

    return false
}

export const conditionIsInvalid = (condition) => {
    const { field = {}, operation = {}, expression = {} } = condition
    const emptyField = !field.id
    const invalidOperation = !emptyField && !operation.id
    const invalidExpression = !invalidOperation && expressionIsInvalid(operation, expression)

    if (invalidOperation) {
        return 'invalid-operation'
    }
    if (invalidExpression) {
        return 'invalid-expression'
    }

    return false
}

const conditionsAreInvalid = ({ conditions }) => {
    if (conditions && [OPERATORS.AND, OPERATORS.OR].indexOf(conditions.rule) > -1) {
        let invalidConditions = conditions.list.filter(condition => conditionIsInvalid(condition))
        return invalidConditions.length
    }
    return false
}

const taskInInvalid = task => {
    return isInvalidTaskTitle(task)
        || taskActorsExceedLimit(task.actors)
        || checkInvalidActors(task.actors)
        || checkActorsIsEmpty(task.actors)
        || isInvalidDueDateInterval(task)
        || conditionsAreInvalid(task)
}

const groupIsInvalid = group => {
    return isInvalidItemTitle({ group }) || conditionsAreInvalid(group)
}

const itemIsInvalid = item => {
    return item.group ? groupIsInvalid(item.group) : taskInInvalid(item.task)
}

const hasInvalidSubTreeItem = (subTree) => {
    return subTree.find(item => {
        const { task, group } = item
        if (task) {
            return taskInInvalid(task)
        } else if (group) {
            return groupIsInvalid(group) || hasInvalidSubTreeItem(group.subTree)
        }
        return false
    })
}

const getServerErrorForOptions = (options, $translate, isField) => {
    if (!options) {
        return
    }
    const defaultErrorMessage = $translate.instant('error.commonIncorrectFields')
    let key = isField ? 'fieldError' : 'summaryError'
    if (options.minLength && options.minLength[0]) {
        return options.minLength[0][key] || defaultErrorMessage
    }
    if (options.maxLength && options.maxLength[0]) {
        return options.maxLength[0][key] || defaultErrorMessage
    }
    if (options.minValue && options.minValue[0]) {
        return options.minValue[0][key] || defaultErrorMessage
    }
    if (options.maxValue && options.maxValue[0]) {
        return options.maxValue[0][key] || defaultErrorMessage
    }
    if (options.maxFiles && options.maxFiles[0]) {
        return options.maxFiles[0][key] || defaultErrorMessage
    }
    if (options.currency) {
        if (options.currency.available && options.currency.available[0]) {
            return options.currency.available[0][key] || defaultErrorMessage
        }
        if (options.currency.default && options.currency.default[0]) {
            return options.currency.default[0][key] || defaultErrorMessage
        }
    }
    if (options.source?.allowedValues) {
        let incorrectVal = options.source.allowedValues.find(v => v.item)
        return incorrectVal && incorrectVal.item && incorrectVal.item[0]
            ? incorrectVal.item[0][key] || defaultErrorMessage
            : defaultErrorMessage
    }
    if (options.source?.displayFields) {
        return options.source.displayFields[0][key] || defaultErrorMessage
    }

    if (options.expression) {
        return options.expression[0][key] || defaultErrorMessage
    }

    if (options.totalFieldId) {
        return options.totalFieldId[0][key] || defaultErrorMessage
    }
}

const getServerErrorForDefaultValue = (defaultValue, $translate) => {
    if (!defaultValue) {
        return
    }
    const defaultErrorMessage = $translate.instant('error.commonIncorrectFields')
    if (defaultValue.radioButtonValue && defaultValue.radioButtonValue.recordId) {
        return defaultValue.radioButtonValue.recordId[0].fieldError || defaultErrorMessage
    }
    if (defaultValue.moneyValue && defaultValue.moneyValue.currency) {
        return defaultValue.moneyValue.currency[0].fieldError || defaultErrorMessage
    }

    return defaultErrorMessage
}

let groupTasksByProcessSort = (a, b) => {
    if (!a.process && b.process) {
        return 1
    } else if (a.process && !b.process) {
        return -1
    } else if (!a.process && !b.process) {
        if (a.dueDate && !b.dueDate) {
            return -1
        } else if (!a.dueDate && b.dueDate) {
            return 1
        }

        if (a.dueDate && b.dueDate) {
            if (a.dueDate < b.dueDate) {
                return -1
            } else if (a.dueDate > b.dueDate) {
                return 1
            }
        }
        if (a.creationDate && b.creationDate) {
            if (a.creationDate > b.creationDate) {
                return -1
            } else if (a.creationDate < b.creationDate) {
                return 1
            }
        }
        return 0
    }

    if (a.process.id === b.process.id) {
        let aIndex = null
        let bIndex = null
        if (a.process.tasks) {
            a.process.tasks.forEach((task, index) => {
                if (task.id === a.id) {
                    aIndex = index
                }
            })
        }
        if (a.process.tasks) {
            a.process.tasks.forEach((task, index) => {
                if (task.id === b.id) {
                    bIndex = index
                }
            })
        }

        if (aIndex != null && bIndex != null) {
            if (aIndex > bIndex) {
                return 1
            } else if (aIndex < bIndex) {
                return -1
            }
        }
    } else if (a.process.startedDate > b.process.startedDate) {
        return -1
    } else if (a.process.startedDate < b.process.startedDate) {
        return 1
    } else {
        return a.process.id - b.process.id
    }
    return 0
}

let groupTasksByProcess = tasks => {
    let result = [{ tasks: [], process: {} }]
    tasks.sort(groupTasksByProcessSort).forEach((t, i) => {
        if (i === 0) {
            result[i].tasks.push(t)
            result[i].process = t.process || {}
            return
        }
        if (t.process) {
            if (t.process.id !== result[result.length - 1].process.id) {
                result.push({ process: t.process, tasks: [t] })
            } else {
                result[result.length - 1].tasks.push(t)
            }
        } else if (result[result.length - 1].process.id) {
            result.push({ tasks: [t], process: {} })
        } else {
            result[result.length - 1].tasks.push(t)
        }
    })
    return result
}

let groupProcessesByTemplate = processes => {
    const processesByTemplateId = processes.reduce((objectsByKey, obj) => {
        const key = obj.template ? obj.template.id : 'none'
        objectsByKey[key] = (objectsByKey[key] || []).concat(obj)
        return objectsByKey
    }, {})

    const result = Object.keys(processesByTemplateId).map(key => {
        const processes = processesByTemplateId[key]
        return { template: key !== 'none' ? processes[0].template : {}, processes }
    })

    return result.sort((a, b) => {
        if (!a.template.name) {
            return 1
        }
        if (!b.template.name) {
            return -1
        }
        return a.template.name.toLowerCase() <= b.template.name.toLowerCase() ? -1 : 1
    })
}

let customNotCompletedStrictSort = (a, b) => {
    let aIndex = null
    let bIndex = null
    if (a.process.tasks) {
        a.process.tasks.forEach((task, index) => {
            if (task.id === a.id) {
                aIndex = index
            }
        })
    }
    if (a.process.tasks) {
        a.process.tasks.forEach((task, index) => {
            if (task.id === b.id) {
                bIndex = index
            }
        })
    }

    if (aIndex != null && bIndex != null) {
        if (aIndex > bIndex) {
            return 1
        } else if (aIndex < bIndex) {
            return -1
        }
    }
    return 0
}

let customNotCompletedSort = (a, b) => {
    if (a.dueDate && !b.dueDate) {
        return -1
    } else if (!a.dueDate && b.dueDate) {
        return 1
    }

    if (a.dueDate && b.dueDate) {
        if (a.dueDate < b.dueDate) {
            return -1
        } else if (a.dueDate > b.dueDate) {
            return 1
        }
    }

    if (!a.process && b.process) {
        return -1
    } else if (a.process && !b.process) {
        return 1
    } else if (!a.process && !b.process) {
        if (a.creationDate && b.creationDate) {
            if (a.creationDate > b.creationDate) {
                return -1
            } else if (a.creationDate < b.creationDate) {
                return 1
            }
        }
        return 0
    }

    if (a.process.id === b.process.id) {
        return customNotCompletedStrictSort(a, b)
    } else if (a.process.startedDate > b.process.startedDate) {
        return -1
    } else if (a.process.startedDate < b.process.startedDate) {
        return 1
    }
    return 0
}

let customCompletedSort = (a, b) => {
    if (a.completedDate > b.completedDate) {
        return -1
    } else if (a.completedDate < b.completedDate) {
        return 1
    }
    if (a.name > b.name) {
        return 1
    } else if (a.name < b.name) {
        return -1
    }
    return 0
}

const groupTasksByDueDate = (tasks, DateHelper, $translate) => {
    const workingHoursStart = DateHelper.getWorkingTimeStart()
    const tz = DateHelper.getTZ()
    const nowInTimeZone = moment().tz(tz)

    const tsNow = nowInTimeZone.unix()
    const tsEndOfToday = nowInTimeZone.clone().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0
    }).add(workingHoursStart, 'hours').unix()
    const tsEndOfTomorrow = moment.unix(tsEndOfToday).add(1, 'day').unix()
    const tsEndOfThisWeek = nowInTimeZone.clone().endOf('week').add(workingHoursStart, 'hours').unix()
    const tsEndOfThisMonth = nowInTimeZone.clone().endOf('month').add(workingHoursStart, 'hours').unix()

    const DUE_DATE_SHORT = DateHelper.DATE_FORMATS().DUE_DATE_SHORT

    const result = [
        { tasks: [], dueDate: { title: $translate.instant('label.overdue') } },
        {
            tasks: [], dueDate: {
                title: $translate.instant('date.today'),
                date: nowInTimeZone.format(DUE_DATE_SHORT)
            }
        },
        {
            tasks: [], dueDate: {
                title: $translate.instant('date.tomorrow'),
                date: moment(nowInTimeZone).add(1, 'days').format(DUE_DATE_SHORT)
            }
        },
        {
            tasks: [], dueDate: {
                title: $translate.instant('date.thisWeek'),
                date: moment(nowInTimeZone).startOf('week').format(DUE_DATE_SHORT) + ' - ' + moment(nowInTimeZone).endOf('week').format(DUE_DATE_SHORT)
            }
        },
        {
            tasks: [], dueDate: {
                title: $translate.instant('date.thisMonth'),
                date: moment(nowInTimeZone).startOf('month').format(DUE_DATE_SHORT) + ' - ' + moment(nowInTimeZone).endOf('month').format(DUE_DATE_SHORT)
            }
        },
        { tasks: [], dueDate: { title: $translate.instant('label.later') } },
        {
            tasks: [], dueDate: {
                title: $translate.instant('label.sometime'),
                date: $translate.instant('label.noDueDate')
            }
        }
    ]
    tasks.forEach(t => {
        if (t.dueDate) {
            let tsTargetDueDate = moment.unix(t.dueDate).tz(tz).unix()

            if (tsTargetDueDate < tsNow) {
                result[0].tasks.push(t) //Overdue
            } else if (tsTargetDueDate <= tsEndOfToday) {
                result[1].tasks.push(t) //Today
            } else if (tsTargetDueDate <= tsEndOfTomorrow) {
                result[2].tasks.push(t) //Tomorrow
            } else if (tsTargetDueDate <= tsEndOfThisWeek) {
                result[3].tasks.push(t) //This week
            } else if (tsTargetDueDate <= tsEndOfThisMonth) {
                result[4].tasks.push(t) //This month
            } else {
                result[5].tasks.push(t) //Later - i.e. next month or in future
            }
        } else {
            result[6].tasks.push(t) //Sometime (NO due date set)
        }
    })
    return result.filter(n => n.tasks.length).map(n => {
        n.tasks.sort(customNotCompletedSort)
        return n
    })
}

export function precisionRound (number, precision) {
    if (isExponential(number)) {
        return number.toString()
    }

    var factor = Math.pow(10, precision)
    var n = precision < 0 ? number : 0.01 / factor + number
    return Math.round(n * factor) / factor
}

const getTotalOfColumn = (dataModelList, section, index) => {
    const field = section.fieldsWithValues[index].name
    const aggregation = field.options && field.options.aggregation

    if (!aggregation || !aggregation.expression) return null

    const totalField = section.fieldsWithValues.find(f => f.name.id === field.options.totalFieldId)
    const totalFieldValue = getFieldValue(totalField, dataModelList)

    if (totalFieldValue !== null) {
        const value = prepareFormulaFieldValue(totalField, totalFieldValue)
        return {
            total: value.moneyValue ? value.moneyValue.amount : value.numericValue,
            label: aggregation.expression === AGGREGATION_TYPES.SUM
                ? 'Total'
                : aggregation.expression.toLowerCase(),
            currency: totalField.name.options.format.dataType === MONEY && totalField.value.moneyValue
                ? { id: totalField.value.moneyValue.currency, info: totalField.value.moneyValue.currencyInfo }
                : undefined
        }
    }
    return '-'
}

export function checkShowTotal (section) {
    return section.columns.find(c => c.name.options && c.name.options.aggregation && c.name.options.aggregation.expression)
}

export function templateToServer (template, dataModel, subTree, conditionsConfig) {
    let postData = {
        name: template.name,
        execution: template.execution,
        description: template.description || '',
        shortDescription: template.shortDescription || '',
        editors: prepareActorsToSave(template.editors),
        starters: prepareActorsToSave(template.starters),
        managers: prepareActorsToSave(template.managers),
        watchers: prepareActorsToSave(template.watchers)
    }

    if (template.id) {
        postData.id = template.id
        postData.version = template.version
    }

    if (template.dueDateInterval) {
        postData.dueDateInterval = angular.copy(template.dueDateInterval)
    }

    if (template.icon && template.icon.id) {
        postData.icon = template.icon
    }

    if (checkTemplateDataModel(dataModel) && checkDefaultDataModelSettings(subTree.map(item => item.task), dataModel)) {
        delete postData.dataModel
    } else {
        postData.dataModel = prepareTemplateDataModel(dataModel)
    }

    postData.processStart = processStartToServer(template.processStart, postData.dataModel || dataModel)
    postData.subTree = subTreeToServer(subTree.slice(), postData.dataModel || dataModel, conditionsConfig)

    return postData
}

const getHierarchyPath = group => {
    let { hierarchyPath, id } = group
    if (hierarchyPath === '/') {
        hierarchyPath = ''
    }

    return `${hierarchyPath}/${id}`
}

export const mergeDataModels = (dataModel1, dataModel2) => {
    if (!dataModel1 && dataModel2) {
        // Case 1:
        // Task view: task was read-only for current user and become editable
        // Process view: not used
        return dataModel2
    } else if (dataModel1 && !dataModel2) {
        // Case 2:
        // Task  view: task was editable for current user and become read-only - stop uploading files
        // Process view: not used
        dataModel1.list.forEach(({ section }) => {
            if (!section.isTable) {
                section.fieldsWithValues.forEach(field => {
                    let { value } = field
                    if (value && value.files) {
                        value.files = value.files.filter(f => f.id)
                    }
                })
            }
        })
        return undefined
    } else if (dataModel1 && dataModel2) {
        // Case 3:
        // Task view/Process view: data form was changed by other user or in another tab
        dataModel1.list.forEach(({ section }, sectionIndex) => {
            const newSection = dataModel2.list[sectionIndex].section

            if (!section.isTable) {
                section.fieldsWithValues.forEach((field, fieldIndex) => {
                    const { dataType } = field.name
                    const newField = newSection.fieldsWithValues[fieldIndex]

                    const fieldValueAsString = fieldValueToString(field)
                    const newFieldValueAsString = fieldValueToString(newField)

                    if (!field.saving && newFieldValueAsString !== fieldValueAsString) {
                        switch (dataType) {
                            case FILES_LIST:
                                const newFiles = newField.value && newField.value.files ? newField.value.files : []
                                const uploadingFiles = field.value.files.filter((file) => !file.id)
                                field.value.files = uploadingFiles.concat(newFiles)
                                field.$originalValue = fieldValueToString(newField)
                                field.updating = true
                                break
                            default:
                                if (field.editMode && field.$originalValue !== fieldValueAsString) {
                                    field.$originalValue = fieldValueToString(newField)
                                } else {
                                    field.value = newField.value ? cloneDeep(newField.value) : {}
                                    updateValue(field)
                                    field.$originalValue = fieldValueToString(field)
                                    field.updating = true
                                }
                        }
                    }
                    field.settings = cloneDeep(newField.settings)
                })
            } else {
                section.columns.forEach((column, columnIndex) => {
                    let newColumn = newSection.columns[columnIndex]
                    if (newColumn) {
                        column.settings = newColumn.settings
                    }
                })

                section.rows = section.rows || []
                newSection.rows = newSection.rows || []

                const newRowIndexes = (newSection.rows || []).map(row => row.rowIndex)
                section.rows.forEach(row => {
                    if (row.editMode && !newRowIndexes.find(r => r === row.rowIndex)) {
                        row.isDeleted = true
                    }
                })
                section.rows = section.rows.filter(row => newRowIndexes.find(r => r === row.rowIndex))

                newSection.rows.forEach(newRow => {
                    const row = section.rows.find(r => r.rowIndex === newRow.rowIndex)
                    if (row) {
                        newRow.values.forEach(({ value: newValue }, columnIndex) => {
                            if (newValue) {
                                row.values[columnIndex].value = newValue
                            } else {
                                delete row.values[columnIndex].value
                            }
                        })
                    } else {
                        section.rows.push(newRow)
                    }
                })
            }
        })
    }
    return dataModel1
}

export const updateFieldStatuses = (field, $timeout) => {
    $timeout.cancel(field.$statusChangeTimeout)
    field.updated = false
    if (field.updating) {
        field.$statusChangeTimeout = $timeout(() => {
            field.updating = false
            field.updated = true
            field.$statusChangeTimeout = $timeout(() => {
                field.updated = false
            }, 1000)
        }, 1000)
    }
}

export const updateFieldsStatuses = (dataModel, $timeout) => {
    const { list = [] } = dataModel || {}
    list.forEach(({ section }) => {
        if (!section.isTable) {
            section.fieldsWithValues.forEach(field => updateFieldStatuses(field, $timeout))
        }
    })
}

export const cloneProcess = (prev, next) => {
    const { dataModel: oldDataModel } = prev
    const { dataModel: newDataModel, ...process } = next
    const newProcess = cloneDeep(process)

    if (newDataModel && newDataModel.list) {
        newProcess.dataModel = mergeDataModels(oldDataModel, newDataModel)
    }

    return newProcess
}

const sortActiveProcesses = (list) => {
    return orderBy(list, ['startedDate', p => p.name.toLowerCase(), 'id'], ['desc', 'asc', 'asc'])
}

const sortCompletedProcesses = (list) => {
    return orderBy(list, ['completedDate', (p) => p.name.toLowerCase(), 'id'], ['desc', 'asc', 'asc'])
}

const sortActiveGroupedProcesses = (list) => {
    return orderBy(list, [
        p => p.template ? 1 : 0,
        p => p.template ? p.template.name.toLowerCase() : '',
        'startedDate',
        p => p.name.toLowerCase(),
        'id'
    ], [
        'asc',
        'asc',
        'desc',
        'asc',
        'asc'
    ])
}

const sortCompletedGroupedProcesses = (list) => {
    return orderBy(list, [
        p => p.template ? 1 : 0,
        p => p.template ? p.template.name.toLowerCase() : '',
        'completedDate',
        p => p.name.toLowerCase(),
        'id'
    ], [
        'asc',
        'asc',
        'desc',
        'asc',
        'asc'
    ])
}

export const sortProcessesList = (list, section, isGrouped) => {
    if (isGrouped) {
        return section === 'active' ? sortActiveGroupedProcesses(list) : sortCompletedGroupedProcesses(list)
    } else {
        return section === 'active' ? sortActiveProcesses(list) : sortCompletedProcesses(list)
    }
}

export const isExponential = number => {
    if (!number) return false
    const regexp = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g
    return regexp.test(number.toString())
}

export default {
    getProcessesList: getProcessesList,
    subTreeToServer: subTreeToServer,
    getActorsNames: getActorsNames,
    processToCompare: processToCompare,
    templateToCompare: templateToCompare,
    checkInvalidActors: checkInvalidActors,
    checkInvalidUsers: checkInvalidUsers,
    isInvalidItemTitle: isInvalidItemTitle,
    isInvalidTaskInterval: isInvalidDueDateInterval,
    taskActorsExceedLimit: taskActorsExceedLimit,
    checkInvalidNameValue: checkInvalidNameValue,
    checkActorsIsEmpty: checkActorsIsEmpty,
    checkLimitUsersExceed: checkLimitUsersExceed,
    compareActors: compareActors,
    updateActorsWithInvalid: updateActorsWithInvalid,
    calcSettingsForTask: calcSettingsForTask,
    prepareActorsToSave: prepareActorsToSave,
    prepareTemplateModel: prepareTemplateModel,
    updateConditionFormFields: updateConditionFormFields,
    updateDueDateFields: updateDueDateFields,
    checkPrDueDate: checkPrDueDate,
    getDueDate: getDueDate,
    getServerErrorForOptions: getServerErrorForOptions,
    getServerErrorForDefaultValue: getServerErrorForDefaultValue,
    invalidSelectorField: invalidSelectorField,
    invalidUserFieldSettingsField: invalidUserFieldSettingsField,
    invalidTemplateDataModel,
    groupTasksByProcess: groupTasksByProcess,
    groupTasksByProcessSort: groupTasksByProcessSort,
    groupTasksByDueDate: groupTasksByDueDate,
    groupProcessesByTemplate: groupProcessesByTemplate,
    customNotCompletedStrictSort: customNotCompletedStrictSort,
    customNotCompletedSort: customNotCompletedSort,
    customCompletedSort: customCompletedSort,
    getTotalOfColumn: getTotalOfColumn,
    cleanTemplateTasks: (subTree, execution) => {
        subTree.forEach((item) => {
            let parallelExecution = execution === constants.PROCESS.EXECUTION.PARALLEL
            if (parallelExecution) {
                if (item.task && item.task.type === constants.TASK.TYPE.APPROVAL) {
                    item.task.type = constants.TASK.TYPE.SIMPLE
                }
            }
        })
    },
    mergeToArray: (arr, data, isArray) => {
        if (isArray) {
            data.forEach(item => {
                if (!arr.find(a => a.id === item.id)) {
                    arr.push(item)
                }
            })
        } else if (!arr.find(a => a.id === data.id)) {
            arr.push(data)
        }
    },
    templateToServer: templateToServer,
    updateProcessStartDataModelSettings: updateProcessStartDataModelSettings,
    updateTaskDataModelSettings: updateTaskDataModelSettings,
    removeEmptyEntriesFromSubTree: removeEmptyEntriesFromSubTree,
    hasInvalidSubTreeItem: hasInvalidSubTreeItem,
    itemIsInvalid: itemIsInvalid,
    itemIsDefault: itemIsDefault,
    taskIsDefault: taskIsDefault,
    conditionsAreInvalid: conditionsAreInvalid,
    getHierarchyPath: getHierarchyPath,
    findSavingFields: findSavingFields,
    sortProcessesList: sortProcessesList
}
