import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Tooltip from '../../../modules/react/tooltip.jsx'
import { translate } from '../../../modules/react/utils'

import './form-group.less'

function FormGroup (props) {
    const {
        label,
        children,
        className,
        controlClassName,
        error,
        helpTooltip,
        hint,
        required,
        isSaving,
        isSaved,
        isUploading,
        ...restProps
    } = props

    return (
        <div className={cn('form-group', 'form-group-v2', className, {
            'has-error': error,
            'has-hint': hint
        })} {...restProps}>
            <span className={'label'}>
                {translate(label)}
                {required && (
                    <i className="icon-template_create_field_required"/>
                )}
                {helpTooltip && (
                    <Tooltip text={helpTooltip} forceDisplay>
                        <i className="icon-common_hint tooltip-white"/>
                    </Tooltip>
                )}
            </span>
            <div className={cn('field-value', controlClassName)}>
                {children}
                {hint && (
                    <div className="hint">{translate(hint)}</div>
                )}
                {error && (
                    <div className="error">{translate(error)}</div>
                )}
            </div>
            {(isUploading || isSaving || isSaved) && (
                <div className="status">
                    {isUploading && (
                        <span className="saving">
                            <i className="icon-task_field_save_ring"/>
                            {translate('label.uploading')}
                        </span>
                    )}
                    {(!isUploading && isSaving) && (
                        <span className="saving">
                            <i className="icon-task_field_save_ring"/>
                            {translate('columnForm.label.saving')}
                        </span>
                    )}
                    {(!isUploading && isSaved) && (
                        <span className="saved">
                            <i className="icon-task_field_save_checkmark"/>
                            {translate('columnForm.label.saved')}
                        </span>
                    )}
                </div>
            )}
        </div>
    )
}

FormGroup.propTypes = {
    label: PropTypes.string,
    hint: PropTypes.string,
    helpTooltip: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.string,
    controlClassName: PropTypes.string,
    children: PropTypes.node,
    required: PropTypes.bool,
    isUploading: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSaved: PropTypes.bool
}

export default FormGroup
