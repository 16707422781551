import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import colors from '../../libs/colors.js'
import TimerFromNow from './timer-from-now.jsx'
import deviceDetector from './device-detector.js'
import utils from './utils.js'

import { translate } from './utils.js'

class ProcessTaskBar extends React.Component {
    static propTypes = {
        task: PropTypes.object,
        process: PropTypes.object,
        link: PropTypes.func,
        config: PropTypes.object,
        payload: PropTypes.object
    }

    constructor (props) {
        super(props)
        this.state = {
            showTooltip: false,
        }
    }

    changeState () {
        if (!deviceDetector.isMobile()) {
            this.setState({ showTooltip: true })
        }
    }

    render () {
        const { link, task, process, config, payload } = this.props
        const goToLink = (event) => {
            if (!deviceDetector.isMobile()) {
                link(event)
            }
        }
        const taskClassNames = cn({
            'g-default-cursor': !utils.checkPermission(task, 'view'),
            'not-assigned': true,
            'in-process': task.status === 4,
            completed: task.status === 16,
            upcoming: task.isUpcoming,
            inactive: task.status === 64 || task.status === 1024,
            approved: task.status === 128,
            rejected: task.status === 256,
            'stopped-on-reject': task.status === 512
        })
        const style = { width: 'calc(' + 100 / process.tasksCount + '% - 1px)' }
        const changeState = () => this.changeState()

        let container = null
        if (this.state.showTooltip) {
            let avatarBlock = null
            if (task.assignee) {
                if (!task.isCompleted) {
                    if (task.assignee.avatarUrl) {
                        avatarBlock =
                            <img className="avatar-small" alt="" src={task.assignee.avatarUrl + '_24'} height="24"
                                 width="24"/>
                    } else {
                        const style = { background: colors[task.assignee.colorIndex - 1] }
                        avatarBlock = (
                            <div className="avatar-small" style={style}>
                                {utils.getInitials(task.assignee.fullName)}
                            </div>
                        )
                    }
                }
            }
            if (task.completedBy) {
                if (task.completedBy.avatarUrl) {
                    avatarBlock =
                        <img className="avatar-small" alt="" src={task.completedBy.avatarUrl + '_24'} height="24"
                             width="24"/>
                } else {
                    const style = { background: colors[task.completedBy.colorIndex - 1] }
                    avatarBlock = (
                        <div className="avatar-small" style={style}>
                            {utils.getInitials(task.completedBy.fullName)}
                        </div>
                    )
                }
            } else if (!task.assignee) {
                avatarBlock = (
                    <div className="avatar-small group">
                        <i className="icon-assignee_unassigned"/>
                    </div>
                )
            }
            let label
            switch (task.status) {
                case 4:
                    label = task.assignedBy ? 'label.assigned' : 'label.autoAssigned'
                    break
                case 16:
                    label = 'label.completed'
                    break
                case 64:
                    label = 'label.inactive'
                    break
                case 128:
                    label = 'label.approved'
                    break
                case 256:
                    label = 'label.rejected'
                    break
                case 512:
                    label = 'label.cancelled'
                    break
                case 1024:
                    label = 'label.pending'
                    break
                default:
                    label = 'label.unassigned'
            }
            let completedClassName = cn({
                completed: true,
                red: task.status === 256
            })

            container = deviceDetector.isMobile() ? null : (
                <div className="process-task-tooltip">
                    <div className="process-task-tooltip__container">
                        {avatarBlock}
                        <div>
                            <span className="name">{task.name}</span>
                            {(task.status === 16 || task.status === 128 || task.status === 256) && (
                                <span className="info-line">
                                    <span className={completedClassName}>{translate(label)}&nbsp;</span>
                                    <TimerFromNow start={task.completedDate} />&nbsp;
                                    {translate('label.user.by')}&nbsp;
                                    <span className="name">{task.completedBy.fullName}</span>
                                </span>
                            )}
                            {task.status === 4 && (
                                <span className="info-line">
                                    <span>{translate(label)}</span>&nbsp;
                                    <TimerFromNow start={task.assignedDate} />&nbsp;
                                    {translate('label.to')}&nbsp;
                                    <span className="name">{task.assignee.fullName}</span>
                                    {task.isUpcoming && (
                                        <span>
                                            &nbsp;•&nbsp;
                                            <span className="red">
                                                {task.isPastDue && (
                                                    <>{translate('label.pastDue')} </>
                                                )}
                                                {task.warnDueDate}
                                            </span>
                                        </span>
                                    )}
                                </span>
                            )}
                            {task.status !== 16 && task.status !== 4 && task.status !== 128 && task.status !== 256 && (
                                <span className="info-line">
                                    {translate(label)}
                                    {task.isUpcoming && (
                                        <span>
                                            &nbsp;•&nbsp;
                                            <span className="red">
                                                {task.isPastDue && (
                                                    <>{translate('label.pastDue')} </>
                                                )}
                                                {task.warnDueDate}
                                            </span>
                                        </span>
                                    )}

                                </span>
                            )}
                        </div>
                        <i className="arrow"/>
                    </div>
                </div>
            )
        }

        return (
            <li className={taskClassNames} style={style} onClick={goToLink} onMouseEnter={changeState}>
                {container}
            </li>
        )
    }
}

export default ProcessTaskBar
