import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon'

function RolePrintView ({ role, ...props }) {
    let iconName

    if (role.isProcessStarter || role.isStarterDirectManager) {
        iconName = 'assignee_process_starter'
    } else if (role.isProcessManager) {
        iconName = 'assignee_process_manager'
    } else if (role.allUsers) {
        iconName = 'assignee_group'
    } else if (role.formField && role.formField.dataType === 'USER_FIELD') {
        iconName = 'company_users'
    }

    return (
        <span className="group" {...props}>
            {/*<span className="avatar-small">*/}
            {/*    <Icon name={iconName}/>*/}
            {/*</span>*/}
            <span className="text">{role.name}</span>
        </span>
    )
}

RolePrintView.propTypes = {
    role: PropTypes.object
}

export default RolePrintView
