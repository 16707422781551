import globalUtils from '../../utils';

const DEFAULT_SEARCH_DELAY = 500;
const IMMEDIATELY = 0;

export class ProcessesController {
    constructor($scope, $state, $location, $timeout, ProcessesSettings, Constants) {
        'ngInject';
        this.ProcessesSettings = ProcessesSettings;
        this.searchString = ProcessesSettings.searchString || '';
        if (this.searchString) {
            this.isSearchOpened = true;
        }

        this.updateGrouping = () => {
            globalUtils.localStorage.set('ProcessesListGroup', ProcessesSettings.processesGroup);
            $scope.$broadcast('grouping:changed');
        };
        this.startProcess = () => {
            $state.go('main.templates', {url: $location.path()});
        };

        this.toggleSearch = (toClose, event) => {
            if (event) {
                this.searchString = '';
                this.searchByName();
                document.getElementById('search-processes-input').focus();
            } else if (this.searchString) {
                this.isSearchOpened = true;
            } else {
                this.isSearchOpened = toClose;
            }
        };

        this.searchKey = (event) => {
            $timeout.cancel(this.searchTimeout);
            let delay = event.key === Constants.BUTTONS.ENTER ? IMMEDIATELY : DEFAULT_SEARCH_DELAY;
            this.searchTimeout = $timeout(() => this.searchByName(), delay);
        };

        this.searchByName = () => {
            ProcessesSettings.searchString = this.searchString;
            $scope.$broadcast('searchProcesses:start');
        };
    }
}
