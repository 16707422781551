import moment from 'moment-timezone'
import React from 'react'
import PropTypes from 'prop-types'
import { checkIfDateRangeHasError, updateDateRangePreset } from '../store/utils'
import DateInput from '../../../components/react/date-input.jsx'
import SelectDropdown from '../../../components/react/select-dropdown.jsx'
import { CUSTOM_DATE_RANGE, DATE_RANGE_CONFIG, DATE_SERVICE_FORMAT } from '../store/constants'
import { getService, translate } from '../../react/utils'
import clsx from 'clsx'

class DateRangeControl extends React.Component {
    static propTypes = {
        values: PropTypes.object.isRequired,
        options: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        const { firstDayOfWeek } = props.options
        this.dayPickerProps = {}

        if (firstDayOfWeek) {
            this.dayPickerProps.firstDayOfWeek = firstDayOfWeek
        }
    }

    getValues () {
        const { presets } = this.props.options
        const { values } = this.props

        const preset = presets.find(({ value }) => value === values.preset)
        const range = preset ? DATE_RANGE_CONFIG[preset.value] : undefined
        const start = preset ? range.start(moment()).format(DATE_SERVICE_FORMAT) : values.start
        const end = preset ? range.end(moment()).format(DATE_SERVICE_FORMAT) : values.end
        const endMinValue = moment(start).format(DATE_SERVICE_FORMAT)
        const endMaxValue = moment(start).add(1, 'year').format(DATE_SERVICE_FORMAT)

        return {
            preset: preset || CUSTOM_DATE_RANGE,
            start,
            end,
            endMinValue: this.dateToUnix(endMinValue),
            endMaxValue: this.dateToUnix(endMaxValue)
        }
    }

    changeDateRangePreset (preset) {
        const { start, end } = this.props.values
        this.props.onChange({
            preset: preset.value,
            start: preset.start ? preset.start : start,
            end: preset.end ? preset.end : end
        })
    }

    changeDateRangeStart (date) {
        const { presets } = this.props.options
        const start = moment(date).startOf('day').format(DATE_SERVICE_FORMAT)
        const newValues = {
            ...this.props.values,
            start,
            hasError: checkIfDateRangeHasError({ start, end: this.props.values.end })
        }
        this.props.onChange(updateDateRangePreset(newValues, presets))
    }

    changeDateRangeEnd (date) {
        const { presets } = this.props.options
        const end = moment(date).endOf('day').format(DATE_SERVICE_FORMAT)
        const newValues = {
            ...this.props.values,
            end,
            hasError: checkIfDateRangeHasError({ start: this.props.values.start, end })
        }
        this.props.onChange(updateDateRangePreset(newValues, presets))
    }

    parseInputDate (string, format) {
        const m = moment(string, format)
        if (m.isValid()) {
            return m.toDate()
        }
        return undefined
    }

    formatInputDate (date, format) {
        return moment(date).format(format)
    }

    dateToUnix = (date) => getService('MomentHelper').dateToUnix(date)

    render () {
        const { options } = this.props
        const { preset, start, end, endMinValue, endMaxValue } = this.getValues()

        const dateRangeError = this.props.values.hasError

        return (
            <div className={clsx('date-range-control', { 'has-error': dateRangeError !== undefined })}>
                <SelectDropdown
                    options={options.presets}
                    value={preset}
                    onChange={(value) => this.changeDateRangePreset(value)}
                />
                <div className="date-inputs">
                    <DateInput
                        formatDate={this.formatInputDate.bind(this)}
                        parseDate={this.parseInputDate.bind(this)}
                        placeholder={translate('label.from')}
                        value={start}
                        onDateChange={date => this.changeDateRangeStart(date)}
                        dayPickerProps={this.dayPickerProps}
                    />
                    {' — '}
                    <DateInput
                        formatDate={this.formatInputDate.bind(this)}
                        parseDate={this.parseInputDate.bind(this)}
                        placeholder={translate('label.to')}
                        value={end}
                        minValue={endMinValue}
                        maxValue={endMaxValue}
                        onDateChange={date => this.changeDateRangeEnd(date)}
                        dayPickerProps={this.dayPickerProps}
                    />
                </div>
                {dateRangeError !== undefined && (
                    <div className="error">
                        {translate(`validation.dateRange.${dateRangeError}`)}
                    </div>
                )}
            </div>
        )
    }
}

export default DateRangeControl
