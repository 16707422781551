import React from 'react';
import { components } from 'react-select';
import cn from 'classnames';
import {ALL_TEMPLATES_OPTION, NO_TEMPLATE_OPTION} from '../store/constants';

function TemplateValue({ children, data, ...props }) {
    const { icon, value } = data;
    let iconComponent = (
        <i className="value-icn icon-File-Settings" style={{backgroundColor: '#ccc'}} />
    );

    if (icon) {
        iconComponent = (
            <i className={cn('value-icn', 'icon', icon.id)} style={{backgroundColor: icon.color}} />
        )
    }

    if (value === ALL_TEMPLATES_OPTION.id || value === NO_TEMPLATE_OPTION.id) {
        iconComponent = '';
    }

    return props.selectProps.isMulti ? (
        <components.MultiValueLabel {...props}>
            {iconComponent}
            {children}
            {/* {removeAction && (
                <i tabIndex="-1" className="icon-common_close" role="button" onClick={() => removeAction(value)} />
            )} */}
        </components.MultiValueLabel>
    ) : (
        <components.SingleValue {...props}>
            {iconComponent}
            {children}
        </components.SingleValue>
    );
}

export default TemplateValue;
