import Drop from 'tether-drop';
const DropContext = Drop.createContext({classPrefix: 'select-timezones-element'});

export function selectTimezone() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '=?',
            options: '=',
            ngChange: '=?'
        },
        template: require('./select-timezone.html'),
        link: (scope, element) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--timezones')[0],
                position: 'bottom left',
                openOn: 'click'
            });

            scope.activeZone = scope.options.find(t => t.id === scope.ngModel) || scope.options[0];

            scope.setZone = zone => {
                scope.ngModel =  zone.id;
                scope.activeZone = scope.options.find(t => t.id === scope.ngModel);
                if (scope.ngChange) {
                    scope.ngChange();
                }
                drop.close();
            };

            scope.$watch('ngModel', val => {
                scope.activeZone = scope.options.find(t => t.id === val) || scope.options[0];
            });

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
