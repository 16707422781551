import Drop from 'tether-drop'

const DropdownMenuClass = Drop.createContext({ classPrefix: 'dropdown-tether' })

export function dropdownTether ($timeout, $window, $translate) {
    'ngInject'

    return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
            ngModel: '=',
            values: '=?',
            isDisabled: '=?',
            isCapitalize: '&?',
            notEmpty: '@?',
            fieldId: '=?',
            onOpen: '&?',
            onChange: '&?',
            width: '=?',
            placeholder: '@?'
        },
        template: `
            <div class="dropdown-tether">
                <button
                    class="tether-handler"
                    data-ng-disabled="(!values.length && notEmpty) || isDisabled"
                    ng-class="{capitalized: isCapitalize, muted: !activeValue}"
                    ng-attr-id="{{fieldId}}">
                    <span>{{getActiveValueLabel(activeValue)}}</span>
                    <i class="icon-common_arrow_down"></i>
                </button>
                <perfect-scrollbar class="scroll-wrapper dropdown-menu">
                    <ul ng-hide="isDisabled" ng-class="{capitalized: isCapitalize}">
                        <li class="empty-value" ng-class="{active: !activeValue}" ng-click="setValue()" ng-if="!notEmpty">
                            <i class="icon-menu_checkmark"></i>{{getEmptyValueLabel()}}
                        </li>
                        <li ng-repeat="value in values|unique"
                            ng-class="{active: getItemId(activeValue) === getItemId(value)}"
                            ng-click="setValue(value)">
                            <i class="icon-menu_checkmark"></i>{{::value.item | translate}}
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
        `,
        link: (scope, element, attrs, ctrl) => {
            let elementHandler = element.find('.tether-handler')[0]
            let elementDrop = element.find('.scroll-wrapper')[0]
            let drop = new DropdownMenuClass({
                target: elementHandler,
                content: elementDrop,
                position: 'bottom left',
                openOn: 'click'
            })

            const getItemId = item => item?.id || item?.item

            const getActiveItem = () => {
                return (scope.values?.length && scope.ngModel)
                    ? scope.values.find(t => t.id === scope.ngModel || t.item === scope.ngModel)
                    : null
            }

            if (scope.values) {
                scope.activeValue = getActiveItem()
            }

            const getDropdownWidth = targetWidth => Math.max(parseInt(targetWidth), parseInt(scope.width) || 260) + 'px'

            scope.$watch('values', () => {
                scope.activeValue = getActiveItem()
                $timeout(() => {
                    let width = $window.getComputedStyle(elementHandler).width
                    elementDrop.style.width = getDropdownWidth(width)
                })
            }, true)

            scope.$watch('ngModel', val => {
                if (val == null) {
                    scope.activeValue = null
                } else {
                    scope.activeValue = getActiveItem()
                }
                $timeout(() => {
                    let width = $window.getComputedStyle(elementHandler).width
                    elementDrop.style.width = getDropdownWidth(width)
                })
            }, true)

            scope.setValue = (value) => {
                if (scope.activeValue && getItemId(scope.activeValue) === getItemId(value || {})) {
                    drop.close()
                    return
                }
                if (!scope.activeValue && !getItemId(value || {})) {
                    drop.close()
                    return
                }
                if (scope.onChange) {
                    scope.onChange({ value })
                } else {
                    scope.ngModel = value
                        ? scope.values.find(t => getItemId(t) === getItemId(value))?.item
                        : undefined
                    ctrl.$setViewValue(scope.ngModel)
                }
                drop.close()
            }

            scope.getActiveValueLabel = activeValue => {
                return $translate.instant(activeValue?.item || scope.placeholder || ' ')
            }

            scope.getEmptyValueLabel = () => {
                return $translate.instant(scope.placeholder || 'label.emptyValue')
            }

            scope.getItemId = getItemId

            drop.on('open', () => {
                if (scope.onOpen) {
                    scope.onOpen()
                }
                elementDrop.scrollTop = 1
                $timeout(() => {
                    elementDrop.scrollTop = 0
                })
            })

            scope.$on('$destroy', () => drop.destroy())
        }
    }
}
