import utils from '../utils';

export function processTaskListDueDate(DateHelper, $timeout) {
    'ngInject';
    
    return {
        restrict: 'E',
        replace: true,
        scope: {
            dueDate: '=?',
            dueDateInterval: '=?',
            hasPermission: '=?',
            onClick: '&?'
        },
        template: `
            <span class="process-task-list-due-date" ng-click="onClickCalendar($event)">
                <div ng-show="dueDate || dueDateInterval" help-tooltip="tooltips.taskView.dueDate" tooltip-disabled="!hasPermission" class="date-string">
                    <span class="formatted-date" ng-class="{red: isWarnDue, hasPermission: hasPermission}">{{(dueDate || dueDateInterval) | dueDate}}</span>
                </div>
                <span ng-show="!dueDate && !dueDateInterval && hasPermission" class="handler date-icon" help-tooltip="tooltips.taskView.dueDate">
                    <i class="icon-task_due_date_add_small"></i>
                </span>
            </span>
        `,
        
        link: (scope, element) => {
            
            scope.onClickCalendar = (event) => {
                event.stopPropagation();
                scope.onClick({
                    $event: event,
                    targetElement: element[0],
                    dueDate: scope.dueDate,
                    dueDateInterval: scope.dueDateInterval
                });
            };
            
            function formatDate() {
                scope.isWarnDue = utils.checkPrDueDate({
                    dueDate: scope.dueDate,
                    dueDateInterval: scope.dueDateInterval
                }, false, DateHelper);
            }
    
            $timeout(() => {
                formatDate();
            });
            
            scope.$watchGroup(['dueDate', 'dueDateInterval'], () => formatDate());
        }
    };
}
