import React from 'react'
import PropTypes from 'prop-types'
import Drop from 'tether-drop'
import { BUTTONS } from '../../services/constants'

class Dropdown extends React.Component {
    static propTypes = {
        label: PropTypes.node.isRequired,
        open: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node,
        position: PropTypes.string,
        labelClasses: PropTypes.string,
        maxHeightOffset: PropTypes.number
    }

    static defaultProps = {
        open: false,
        position: 'bottom right',
        labelClasses: 'btn-switcher btn-primary-switcher btn-dropdown',
        maxHeightOffset: 20
    }

    constructor (props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount () {
        this.drop = new Drop({
            target: this.target,
            content: this.content,
            position: this.props.position,
            classes: '',
            openOn: undefined
        })

        if (this.props.open) {
            this.drop.open()
        }
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.open) {
            this.drop.open()
            document.body.addEventListener('click', this.handleClick)
            document.body.addEventListener('touchstart', this.handleClick)
            document.body.addEventListener('keyup', this.handleKeyPress)
        } else {
            this.drop.close()
            this.removeDocumentEventListeners()
        }
    }

    componentDidUpdate () {
        this.drop.position()
    }

    componentWillUnmount () {
        this.drop.destroy()
        this.removeDocumentEventListeners()
    }

    removeDocumentEventListeners () {
        document.body.removeEventListener('click', this.handleClick)
        document.body.removeEventListener('touchstart', this.handleClick)
        document.body.removeEventListener('keyup', this.handleKeyPress)
    }

    handleClick (e) {
        const clickOnContent = this.content.contains(e.target)
        const clickOnHeader = this.target.contains(e.target)

        if (!clickOnHeader && !clickOnContent) {
            this.closeDropdown()
        }
    }

    handleKeyPress (e) {
        if (e.key === BUTTONS.ESCAPE) {
            this.closeDropdown()
        }
    }

    closeDropdown () {
        const { onClick } = this.props
        onClick(false)
    }

    toggleDropdown () {
        const { onClick, open } = this.props
        onClick(!open)
    }

    _refTarget = (ref) => {
        this.target = ref
    }

    _refContent = (ref) => {
        this.content = ref
    }

    getDropdownStyles () {
        const { maxHeightOffset } = this.props
        const bodyHeight = document.body.getBoundingClientRect().height
        return {
            maxHeight: bodyHeight - maxHeightOffset,
            overflow: 'scroll'
        }
    }

    render () {
        const { label, children, open, disabled, labelClasses } = this.props

        return (
            <div ref={this._refTarget}>
                <button
                    type="button"
                    onClick={this.toggleDropdown.bind(this)}
                    className={labelClasses}
                    disabled={disabled}
                >
                    <span>
                        {label}
                        <i className="icon-common_arrow_down caret"/>
                    </span>
                </button>
                <div className="dropdown-menu-wrapper" ref={this._refContent}>
                    {open && (
                        <div style={this.getDropdownStyles()}>{children}</div>
                    )}
                </div>
            </div>
        )
    }
}

export default Dropdown
