import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import orderBy from 'lodash/orderBy'

import colors from '../../../libs/colors'
import adminUtils from '../../../modules/admin/utils'
import config from '../../../config'
import { getTextWidthForNode } from '../../../utils'
import { generateInitials } from '../../../filters/initials'
import { getService } from '../../../modules/react/utils'

const getColor = i => colors[i - 1]

const getUserName = (user) => {
    const name = user.fullName || user.name
    return name.trim() ? name : user.email
}

const getUserInitials = (user) => generateInitials(getUserName(user))

const groupNameTrim = groupName => adminUtils.groupNameTrimRestrictedChar(groupName).substr(0, 45)
const textIsEmail = (text) => !!(text && config.pattern_emails.test(text))

const emailOrGroupNameIsExisted = (searchText, { groups = [], users = [] }) => {
    let text = groupNameTrim(searchText).toLowerCase()
    const isEmail = textIsEmail(text)
    if (isEmail) {
        return users.find(user => user.email.toLowerCase() === text) !== undefined
    } else {
        return groups.find(group => group.name.toLowerCase() === text) !== undefined
    }
}

const getCreateItemLabel = (searchText) => {
    let text = groupNameTrim(searchText)
    return textIsEmail(text) ? `Invite user "${text}"` : `Create group "${text}"`
}

const getFilteredActors = ({ users, groups, specialRoles }, searchText) => {
    const checkString = string => string && string.toLowerCase().includes(searchText.toLowerCase())
    const filteredUsers = users.slice().filter(user => checkString(user.name) || checkString(user.fullName) || checkString(user.email))
    const filteredGroups = groups.slice().filter(group => checkString(group.name))
    const filteredRoles = specialRoles.slice().filter(role => checkString(role.name))

    groups.forEach(g => {
        g.users.slice()
            .filter(user => checkString(user.name) || checkString(user.fullName) || checkString(user.email))
            .forEach(user => {
                if (!filteredUsers.find(u => u.id === user.id)) {
                    filteredUsers.push(user)
                }
            })
    })

    return {
        users: orderBy(filteredUsers, getUserName),
        groups: orderBy(filteredGroups, 'name'),
        specialRoles: orderBy(filteredRoles, 'name')
    }
}

function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const getHighlightedText = (text, highlight) => {
    const escapedString = escapeRegExp(highlight)
    const parts = text.split(new RegExp(`(${escapedString})`, 'gi'))
    return (
        <>
            {parts.map((part, i) =>
                <span key={i}
                      className={clsx('text', { 'highlighted': part.toLowerCase() === highlight.toLowerCase() })}>
                    {part}
                </span>
            )}
        </>
    )
}

const asyncAction = (action, ...args) => {
    setTimeout(() => action.apply(null, args), 0)
}

function Selectize (props) {
    const {
        actors,
        value,
        hint,
        placeholder,
        adHocTask,
        onChange,
        onCreateGroup,
        onCreateGroupError,
        onInviteUser,
        isClearable = false
    } = props
    const { specialRoles = [], groups = [], users = [] } = value

    const [isOpen, setIsOpen] = useState(false)
    const [searchText, setSearchText] = useState(undefined)
    const [openedGroup, setOpenedGroup] = useState(undefined)
    const [activeEl, setActiveEl] = useState(undefined)
    const [invitationIsInProgress, setInvitationIsInProgress] = useState(false)
    const [errorMsg, setErrorMsg] = useState(undefined)
    const getErrorMsg = () => props.error || errorMsg

    const container = useRef(null)
    const searchField = useRef(null)
    const dropdown = useRef(null)

    const setFocus = () => {
        if (searchField.current && document.activeElement !== searchField.current) {
            searchField.current.focus()
        }
    }

    useEffect(() => {
        if (isOpen || (props.autofocus && !isOpen)) {
            setFocus()
        }
    })

    useEffect(() => {
        return () => {
            document.removeEventListener('click', documentClickHandler)
        }
    }, [])

    const closeDropdown = (e) => {
        if (e && container.current && container.current.contains(e.target)) {
            return
        }
        setIsOpen(false)
        setSearchText('')
        setActiveEl(undefined)

        if (searchField.current) {
            searchField.current.blur()
        }

        document.removeEventListener('click', documentClickHandler)
    }

    const documentClickHandler = (e) => {
        if (e && container.current && container.current.contains(e.target)) {
            return
        }
        if (!invitationIsInProgress) {
            closeDropdown(e)
        }
    }

    const openDropdown = () => {
        setFocus()
        if (isOpen) {
            return
        }
        document.addEventListener('click', documentClickHandler)
        setIsOpen(true)
    }

    const applyChanges = changes => {
        setSearchText('')
        onChange(changes)
    }

    const clearValue = () => applyChanges({ specialRoles: [], groups: [], users: [] })

    const addRole = role => applyChanges({
        specialRoles: [...specialRoles.filter(r => r.name !== role.name), role],
        groups,
        users
    })
    const removeRole = role => applyChanges({
        specialRoles: specialRoles.filter(r => r.name !== role.name),
        groups,
        users
    })
    const activateRole = role => setActiveEl({ role: role.name })
    const roleIsActive = role => activeEl && activeEl.role && activeEl.role === role.name

    const addGroup = group => applyChanges({
        specialRoles,
        groups: [...groups.filter(({ id }) => id !== group.id), group],
        users
    })
    const removeGroup = group => applyChanges({
        specialRoles,
        groups: groups.filter(({ id }) => id !== group.id),
        users
    })
    const activateGroup = group => setActiveEl({ group: group.id })
    const groupIsActive = group => activeEl && activeEl.group && activeEl.group === group.id

    const userGroupIsActive = (user, group) => activeEl && activeEl.user && activeEl.user === user.id && activeEl.group && activeEl.group === group.id
    const activateUserGroup = (user, group) => setActiveEl({ user: user.id, group: group.id })

    const addUser = user => applyChanges({
        specialRoles,
        groups,
        users: [...users.filter(({ id }) => id !== user.id), user]
    })
    const removeUser = user => applyChanges({ specialRoles, groups, users: users.filter(({ id }) => id !== user.id) })
    const activateUser = user => setActiveEl({ user: user.id })
    const userIsActive = user => activeEl && activeEl.user && activeEl.user === user.id

    const activateButton = () => {
        if (searchText) {
            setActiveEl({ button: true })
        }
    }
    const buttonIsActive = () => activeEl && activeEl.button === true

    const openGroup = group => setOpenedGroup(group.id)
    const closeGroup = () => setOpenedGroup(undefined)

    const openActiveGroup = () => {
        if (activeEl && activeEl.group) {
            openGroup({ id: activeEl.group })
        }
    }

    const closeActiveGroup = () => {
        if (activeEl && activeEl.group) {
            closeGroup()
        }
    }

    const placeholderText = (specialRoles.length || groups.length || users.length) ? '' : placeholder
    const searchFieldWidth = searchField && searchField.current ? getTextWidthForNode(searchField.current, searchText || placeholderText) : 0
    const filteredActors = searchText ? getFilteredActors(actors, searchText) : {}
    const currentUser = getService('currentUser')
    const AdminApiCalls = getService('AdminApiCalls')

    const activateNextItem = () => {
        const { specialRoles, groups, users } = searchText ? filteredActors : actors

        if (!activeEl) {
            if (specialRoles.length) {
                return activateRole(specialRoles[0])
            }
            if (groups.length) {
                return activateGroup(groups[0])
            }
            if (users.length) {
                return activateUser(users[0])
            }
            return activateButton()
        }
        if (activeEl.role) {
            const roleIndex = specialRoles.findIndex(r => r.name === activeEl.role)
            if (roleIndex < specialRoles.length - 1) {
                return activateRole(specialRoles[roleIndex + 1])
            } else {
                if (groups.length) {
                    return activateGroup(groups[0])
                }
                if (users.length) {
                    return activateUser(users[0])
                }
                return activateButton()
            }
        }
        if (activeEl.group && !activeEl.user) {
            const groupIndex = groups.findIndex(g => g.id === activeEl.group)
            const group = groups[groupIndex]
            if (openedGroup === activeEl.group && group.users.length) {
                return activateUserGroup(group.users[0], group)
            } else {
                if (groupIndex < groups.length - 1) {
                    return activateGroup(groups[groupIndex + 1])
                }
                if (users.length) {
                    return activateUser(users[0])
                }
                return activateButton()
            }
        }
        if (activeEl.user && !activeEl.group) {
            const userIndex = users.findIndex(u => u.id === activeEl.user)
            if (userIndex < users.length - 1) {
                return activateUser(users[userIndex + 1])
            }
            return activateButton()
        }
        if (activeEl.group && activeEl.user) {
            const groupIndex = groups.findIndex(g => g.id === activeEl.group)
            const group = groups[groupIndex]
            const userIndex = group ? group.users.findIndex(u => u.id === activeEl.user) : undefined
            if (userIndex !== undefined && userIndex < group.users.length - 1) {
                return activateUserGroup(group.users[userIndex + 1], group)
            } else {
                if (groupIndex < groups.length - 1) {
                    return activateGroup(groups[groupIndex + 1])
                }
                if (users.length) {
                    return activateUser(users[0])
                }
                return activateButton()
            }
        }
    }

    const activatePrevItem = () => {
        const { specialRoles = [], groups = [], users = [] } = searchText ? filteredActors : actors

        if (!activeEl) {
            if (specialRoles.length) {
                return activateRole(specialRoles[0])
            }
            if (groups.length) {
                return activateGroup(groups[0])
            }
            if (users.length) {
                return activateUser(users[0])
            }
            return activateButton()
        }

        if (buttonIsActive()) {
            if (users.length) {
                return activateUser(users[users.length - 1])
            }
            if (groups.length) {
                return activateGroup(groups[groups.length - 1])
            }
            if (specialRoles.length) {
                return activateRole(specialRoles[specialRoles.length - 1])
            }
        }

        if (activeEl.user && !activeEl.group) {
            const userIndex = users.findIndex(u => u.id === activeEl.user)
            if (userIndex > 0) {
                return activateUser(users[userIndex - 1])
            }
            if (groups.length) {
                const group = groups[groups.length - 1]
                if (openedGroup === group.id && group.users.length) {
                    return activateUserGroup(group.users[group.users.length - 1], group)
                }
                return activateGroup(groups[groups.length - 1])
            }
            if (specialRoles.length) {
                return activateRole(specialRoles[specialRoles.length - 1])
            }
        }

        if (activeEl.group && !activeEl.user) {
            const groupIndex = groups.findIndex(g => g.id === activeEl.group)
            if (groupIndex > 0) {
                return activateGroup(groups[groupIndex - 1])
            }
            if (specialRoles.length) {
                return activateRole(specialRoles[specialRoles.length - 1])
            }
        }

        if (activeEl.group && activeEl.user) {
            const groupIndex = groups.findIndex(g => g.id === activeEl.group)
            const group = groups[groupIndex]
            const userIndex = group ? group.users.findIndex(u => u.id === activeEl.user) : undefined
            if (userIndex !== undefined && userIndex > 0) {
                return activateUserGroup(group.users[userIndex - 1], group)
            } else {
                if (groupIndex > 0) {
                    return activateGroup(groups[groupIndex - 1])
                }
                if (specialRoles.length) {
                    return activateRole(specialRoles[specialRoles.length - 1])
                }
            }
        }

        if (activeEl.role) {
            const roleIndex = specialRoles.findIndex(r => r.name === activeEl.role)
            if (roleIndex > 0) {
                return activateRole(specialRoles[roleIndex - 1])
            }
        }
    }

    const addActiveItem = () => {
        const { specialRoles = [], groups = [], users = [] } = searchText ? filteredActors : actors

        if (!activeEl) {
            return
        }

        if (activeEl.button) {
            createNewGroup()
        }

        if (activeEl.role) {
            return addRole(specialRoles.find(role => role.name === activeEl.role))
        }

        if (activeEl.group && !activeEl.user) {
            return addGroup(groups.find(group => group.id === activeEl.group))
        }

        if (activeEl.user && !activeEl.group) {
            return addUser(users.find(user => user.id === activeEl.user))
        }

        if (activeEl.user && activeEl.group) {
            const group = groups.find(group => group.id === activeEl.group)
            return addUser(group.users.find(user => user.id === activeEl.user))
        }
    }

    const removeLastValueItem = () => {
        if (searchText) {
            return
        }

        if (users.length > 0) {
            return applyChanges({ specialRoles, groups, users: users.slice(0, users.length - 1) })
        }

        if (groups.length > 0) {
            return applyChanges({ specialRoles, groups: groups.slice(0, groups.length - 1), users })
        }

        if (specialRoles.length > 0) {
            return applyChanges({ specialRoles: specialRoles.slice(0, specialRoles.length - 1), groups, users })
        }
    }

    const keyDownHandler = (e) => {
        switch (e.key) {
            case 'Escape':
                e.stopPropagation()
                return closeDropdown()
            case 'ArrowRight':
                e.preventDefault()
                return openActiveGroup()
            case 'ArrowLeft':
                e.preventDefault()
                return closeActiveGroup()
            case 'ArrowDown':
                e.stopPropagation()
                e.preventDefault()
                activateNextItem()
                scrollToActive()
                return
            case 'ArrowUp':
                e.stopPropagation()
                e.preventDefault()
                activatePrevItem()
                scrollToActive()
                return
            case 'Enter':
                e.stopPropagation()
                e.preventDefault()
                return addActiveItem()
            case 'Delete':
            case 'Backspace':
                return removeLastValueItem()
            case 'Tab': // TODO replace with focus lost handler
                return closeDropdown()
        }
    }

    const addNewGroup = (group) => {
        onCreateGroup(group)
        addGroup(group)
        setSearchText('')
    }

    const addNewUser = (user) => {
        onInviteUser(user)
        addUser(user)
        setSearchText('')
    }

    const createNewGroup = () => {
        const groupNameTrimmed = groupNameTrim(searchText)

        if (groupNameTrimmed && textIsEmail(groupNameTrimmed) && props.withInviteUser) {
            setInvitationIsInProgress(true)
            return openInvitePopup()
        }

        if (groupNameTrimmed && props.withCreateGroup) {
            AdminApiCalls.addGroup({ name: groupNameTrimmed }, true, props.ignoreError403)
                .then(data => {
                    setErrorMsg(undefined)
                    if (data.success) {
                        addNewGroup(data.result)
                        if (!props.disableToasterPopup) {
                            getService('PageSettings').toasterData = {
                                iconClass: ' icon-company_groups',
                                text: `Group "${data.result.name}" is created`,
                                timeout: 3000
                            }
                        }
                    }
                }, errorResp => {
                    setErrorMsg(undefined)
                    if (errorResp.status === 517) {
                        addNewGroup(errorResp.data.existing)
                    }

                    let serverErrorMsg
                    if (errorResp.data) {
                        serverErrorMsg = errorResp.data.displayError || errorResp.data.error || errorResp.data.name[0].fieldError
                    }
                    if (!serverErrorMsg) {
                        serverErrorMsg = `Oops! Something went wrong. Try again or contact support team.`
                    }
                    if (onCreateGroupError) {
                        onCreateGroupError({
                            errorMsg: serverErrorMsg,
                            errorResp: { ...errorResp }
                        })
                    }
                    setErrorMsg(serverErrorMsg)
                    closeDropdown()
                })
        }
    }

    const openInvitePopup = () => {
        let modal = getService('$modal').open({
            animation: true,
            windowClass: 'invite',
            template: require('../../../templates/modals/invite.html'),
            controller: 'InviteModalController',
            backdrop: 'static',
            resolve: {
                groups: () => actors.groups.map(({ id, name, users }) => ({ id, name, usersCount: users.length })),
                preloadedState: () => [{ email: searchText }]
            }
        })
        modal.result.then(data => {
            data.result.forEach(user => {
                user.email = user.fullName
                addNewUser(user)
                // $rootScope.$broadcast('user-invited', {...user}); TODO Fix if needed
                if (!props.disableToasterPopup) {
                    getService('PageSettings').toasterData = {
                        iconClass: ' icon-company_groups',
                        text: `User "${user.fullName || user.email}" is invited`,
                        timeout: 3000
                    }
                }
            })
        }).finally(() => {
            setInvitationIsInProgress(false)
            setTimeout(closeDropdown, 0)
        })
    }

    const scrollToActive = () => {
        const padding = 10
        setTimeout(() => {
            const activeItem = dropdown.current.querySelector('div.active')
            if (!activeItem) {
                return
            }
            const scroll = (activeItem.offsetTop + activeItem.offsetHeight) - (dropdown.current.offsetHeight + dropdown.current.scrollTop)
            if (scroll > 0) {
                dropdown.current.scrollTop = dropdown.current.scrollTop + scroll + padding
            } else if (dropdown.current.scrollTop > activeItem.offsetTop) {
                dropdown.current.scrollTop = activeItem.offsetTop - padding
            }
        }, 0)
    }

    return (
        <div className="selectize-control" onClick={openDropdown} ref={container}>
            <div className={clsx('selectize-input', { active: isOpen, error: props.error })}>
                <ul className="items">
                    {specialRoles.map(role => (
                        <li className="item" key={role.name} onClick={setFocus}>
                            <div className="thumbnail">
                                <i className={clsx({
                                    'icon-assignee_process_starter': role.isProcessStarter,
                                    'icon-assignee_process_manager': role.isProcessManager,
                                    'icon-assignee_group': role.allUsers,
                                    'icon-company_users': role.formField && role.formField.dataType === 'USER_FIELD'
                                })}/>
                                {role.isDeleted && (
                                    <div className="badge-deleted">
                                        <i className="icon-assignee_badge_issued"/>
                                    </div>
                                )}
                            </div>
                            <span>{role.name}</span>
                            <i className="icon-common_close" onClick={() => asyncAction(removeRole, role)}/>
                        </li>
                    ))}

                    {groups.map(group => (
                        <li className={clsx('item', { allUsers: group.id === 'all-users' })} key={group.name}
                            onClick={setFocus}>
                            <div className="thumbnail">
                                <i className="icon-assignee_group"/>
                                {group.isDeleted && (
                                    <div className="badge-deleted">
                                        <i className="icon-assignee_badge_issued"/>
                                    </div>
                                )}
                            </div>
                            <span>{group.name}</span>
                            <i className="icon-common_close" onClick={() => asyncAction(removeGroup, group)}/>
                        </li>
                    ))}

                    {users.map(user => (
                        <li className="item" key={user.id} onClick={setFocus}>
                            {user.avatarUrl ? (
                                <div className="thumbnail">
                                    <img src={user.avatarUrl + '_24'} alt={user.fullName}/>
                                    {user.isDeleted && (
                                        <div className="badge-deleted">
                                            <i className="icon-assignee_badge_issued"/>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="thumbnail" style={{ background: getColor(user.colorIndex) }}>
                                    <span>{getUserInitials(user)}</span>
                                    {user.isDeleted && (
                                        <div className="badge-deleted">
                                            <i className="icon-assignee_badge_issued"/>
                                        </div>
                                    )}
                                </div>
                            )}

                            <span>{adHocTask && user.id === currentUser.id ? 'label.me' : getUserName(user)}</span>
                            <i className="icon-common_close" onClick={() => asyncAction(removeUser, user)}/>
                        </li>
                    ))}

                    <li>
                        <textarea
                            className="select-input-ctrl"
                            style={{ width: searchFieldWidth + 20 }}
                            value={searchText}
                            onFocus={() => openDropdown()}
                            onChange={(e) => setSearchText(e.target.value)}
                            maxLength="50"
                            placeholder={placeholderText}
                            onKeyDown={keyDownHandler}
                            rows="1"
                            tabIndex="2"
                            ref={searchField}
                        />
                    </li>
                </ul>
                {(isClearable && (specialRoles.length > 0 || groups.length > 0 || users.length > 0)) && (
                    <div className="clear-all" onClick={clearValue}>
                        <i className="icon-common_close"/>
                    </div>
                )}
            </div>

            {getErrorMsg() && (
                <div className="selectize-error">{getErrorMsg()}</div>
            )}

            {hint && (
                <div className="hint">{hint}</div>
            )}

            <div className={clsx('selectize-dropdown', { opened: isOpen })} onClick={() => setActiveEl(undefined)}>
                <div className={clsx('dropdown-list', { active: isOpen })} onClick={openDropdown} ref={dropdown}>
                    {searchText ? (
                        <ul>
                            {filteredActors.specialRoles.map((role, roleIndex) => (
                                <li className={clsx('role', { last: roleIndex === filteredActors.specialRoles.length - 1 })}
                                    key={role.name}
                                    onClick={() => addRole(role)}
                                >
                                    <div className={clsx({ active: roleIsActive(role) })}
                                         onMouseEnter={() => activateRole(role)}>
                                        <span>
                                            <i className={clsx({
                                                'icon-assignee_process_starter': role.isProcessStarter,
                                                'icon-assignee_process_manager': role.isProcessManager,
                                                'icon-assignee_group': role.allUsers,
                                                'icon-company_users': role.formField && role.formField.dataType === 'USER_FIELD'
                                            })}/>
                                        </span>
                                        <span className="text">
                                            {getHighlightedText(role.name, searchText)}
                                        </span>
                                    </div>
                                </li>
                            ))}

                            {filteredActors.groups.map(group => (
                                <li className="group" key={group.id}>
                                    <header>
                                        <div className={clsx({ active: groupIsActive(group) })}
                                             onClick={() => addGroup(group)}
                                             onMouseEnter={() => activateGroup(group)}
                                        >
                                            <span><i className="icon-assignee_group"/></span>
                                            <span className="text">
                                                {getHighlightedText(group.name, searchText)}
                                            </span>
                                        </div>
                                    </header>
                                </li>
                            ))}

                            {filteredActors.users.map(user => (
                                <li className="user" key={user.id} onClick={() => addUser(user)}>
                                    <div className={clsx({ active: userIsActive(user) })}
                                         onMouseEnter={() => activateUser(user)}>
                                        {user.avatarUrl ? (
                                            <img className="thumbnail"
                                                 src={user.avatarUrl + '_24'}
                                                 alt={getUserName(user)}
                                            />
                                        ) : (
                                            <div className="thumbnail"
                                                 style={{ background: getColor(user.colorIndex) }}>
                                                <span>{getUserInitials(user)}</span>
                                            </div>
                                        )}

                                        <span className="text user-name" title={getUserName(user)}>
                                            {getHighlightedText(getUserName(user), searchText)}
                                        </span>

                                        <span className="user-email" title={user.email}>
                                            {getHighlightedText(user.email, searchText)}
                                        </span>
                                    </div>
                                </li>
                            ))}

                            {((filteredActors.users.length > 0 || filteredActors.groups.length > 0) && (props.withCreateGroup || props.withInviteUser) && !emailOrGroupNameIsExisted(searchText, filteredActors)) && (
                                <li className="group">
                                    <header>
                                        <div className={clsx({ active: buttonIsActive() })}
                                             onClick={createNewGroup}
                                             onMouseEnter={() => activateButton()}
                                        >
                                            <span>
                                                <i className="icon-template_create"/>
                                            </span>
                                            <span className="text">{getCreateItemLabel(searchText)}</span>
                                        </div>
                                    </header>
                                </li>
                            )}

                            {(!filteredActors.users.length && !filteredActors.groups.length && !props.withCreateGroup && !props.withInviteUser) && (
                                <li className="no-result">
                                    {`We didn't find any groups or users with this name`}
                                </li>
                            )}

                            {(!filteredActors.users.length && !filteredActors.groups.length && (props.withCreateGroup || props.withInviteUser) && groupNameTrim(searchText).length) && (
                                <li className="no-result">
                                    {`We couldn't find a group with this name`}
                                    <br/>
                                    <a onClick={createNewGroup}>{getCreateItemLabel(searchText)}</a>
                                </li>
                            )}
                        </ul>
                    ) : (
                        <ul>
                            {actors.specialRoles.map((role, roleIndex) => (
                                <li className={clsx('role', 'tree', { last: roleIndex === actors.specialRoles.length - 1 })}
                                    key={role.name}
                                    onClick={() => addRole(role)}>
                                    <div className={clsx({ active: roleIsActive(role) })}
                                         onMouseEnter={() => activateRole(role)}>
                                    <span>
                                        <i className={clsx({
                                            'icon-assignee_process_starter': role.isProcessStarter,
                                            'icon-assignee_process_manager': role.isProcessManager,
                                            'icon-assignee_group': role.allUsers,
                                            'icon-company_users': role.formField && role.formField.dataType === 'USER_FIELD'
                                        })}/>
                                    </span>
                                        {role.name}
                                    </div>
                                </li>
                            ))}

                            {actors.groups.map(group => (
                                <li className={clsx('group', { allUsers: group.id === 'all-users' })} key={group.id}>
                                    <header>
                                        {group.users && group.users.length > 0 && (
                                            <i className={clsx({
                                                'icon-picker_arrow_right': group.id !== openedGroup,
                                                'icon-picker_arrow_down': group.id === openedGroup
                                            })}
                                               onClick={() => group.id === openedGroup ? closeGroup() : openGroup(group)}/>
                                        )}

                                        <div className={clsx({
                                            active: groupIsActive(group),
                                            empty: !group.users || !group.users.length
                                        })}
                                             onClick={() => addGroup(group)}
                                             onMouseEnter={() => activateGroup(group)}>
                                            <span>
                                                <i className="icon-assignee_group"/>
                                            </span>
                                            {group.name}
                                        </div>
                                    </header>
                                    {(group.users && group.users.length > 0 && group.id === openedGroup) && (
                                        <ul className="users">
                                            {group.users.map(user => (
                                                <li key={user.id} onClick={() => addUser(user)}>
                                                    <div
                                                        className={clsx('user', { active: userGroupIsActive(user, group) })}
                                                        onMouseEnter={() => activateUserGroup(user, group)}
                                                    >
                                                        {user.avatarUrl ? (
                                                            <img className="thumbnail" src={user.avatarUrl + '_24'}
                                                                 alt={getUserName(user)}/>
                                                        ) : (
                                                            <div className="thumbnail"
                                                                 style={{ background: getColor(user.colorIndex) }}>
                                                                {getUserInitials(user)}
                                                            </div>
                                                        )}

                                                        <span className="user-name" title={getUserName(user)}>
                                                            {adHocTask && user.id === currentUser.id ? 'label.me' : getUserName(user)}
                                                        </span>
                                                        <span className="user-email" title={user.email}>
                                                            {user.email}
                                                        </span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                            {actors.users.map(user => (
                                <li className="user" key={user.id} onClick={() => addUser(user)}>
                                    <div className={clsx({ active: userIsActive(user) })}
                                         onMouseEnter={() => activateUser(user)}>
                                        {user.avatarUrl ? (
                                            <img className="thumbnail" src={user.avatarUrl + '_24'}
                                                 alt={getUserName(user)}/>
                                        ) : (
                                            <div className="thumbnail"
                                                 style={{ background: getColor(user.colorIndex) }}>
                                                <span>{getUserInitials(user)}</span>
                                            </div>
                                        )}

                                        <span className="user-name" title={getUserName(user)}>
                                            {adHocTask && user.id === currentUser.id ? 'label.me' : getUserName(user)}
                                        </span>
                                        <span className="user-email" title={user.email}>{user.email}</span>
                                    </div>
                                </li>
                            ))}
                            {(!actors.groups.length && props.withCreateGroup) && (
                                <li className="no-result">
                                    {`You don't have any groups in your team. To create a new group, type a group name here`}
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

Selectize.propTypes = {
    actors: PropTypes.object,
    value: PropTypes.object,
    hint: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    withCreateGroup: PropTypes.bool,
    withInviteUser: PropTypes.bool,
    ignoreError403: PropTypes.bool,
    adHocTask: PropTypes.bool,
    autofocus: PropTypes.bool,
    disableToasterPopup: PropTypes.bool,
    onChange: PropTypes.func,
    onCreateGroup: PropTypes.func,
    onCreateGroupError: PropTypes.func,
    onInviteUser: PropTypes.func,
    isClearable: PropTypes.bool
}

export default Selectize
