import Drop from 'tether-drop';
const Context = Drop.createContext({classPrefix: 'header-user'});

export function headerUserBlock($state) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div class="header-user-block">
                <div class="user-info tooltip-right" help-tooltip="tooltips.userAccount">
                    <img class="avatar" ng-src="{{currentUser.avatarUrl + '_40'}}" ng-if="currentUser.avatarUrl" alt="photo">
                    <div class="avatar" ng-if="!currentUser.avatarUrl"
                         ng-style="{background: mainCtrl.getColorByIndex(currentUser.colorIndex)}">
                        {{currentUser.fullName | initials}}
                    </div>
                    <main>
                        <span class="name">{{currentUser.fullName}} <i class="icon-common_arrow_down"></i></span>
                        <span>{{currentUser.companyName | short:30}}</span>
                    </main>
                </div>

                <ul class="dropdown-menu--header-user">
                    <li>
                        <a ng-click="goToState('main.profile.main')">{{'label.profileSettings' | translate}}</a>
                    </li>
                    <li ng-if="currentUser.isAdmin">
                        <a ng-click="goToState('main.admin.users')">
                            {{(currentUser.companyName || 'Team') | short:30}}
                            {{'label.settings' | translate}}
                        </a>
                    </li>
                    <li>
                        <a ng-click="close()" href="http://help.workblocks.me" target="_blank">{{'label.helpCenter' | translate}}</a>
                    </li>
                    <li>
                        <a class="top-border" ng-click="mainCtrl.logout()">{{'label.signOut' | translate}}</a>
                    </li>
                </ul>
            </div>
        `,
        link: (scope, element) => {
            let drop = new Context({
                target: element.find('.user-info')[0],
                content: element.find('.dropdown-menu--header-user')[0],
                position: 'bottom right',
                openOn: 'click'
            });

            scope.goToState = state => {
                $state.go(state).finally(() => drop.close());
            };
            scope.close = () => drop.close();

            scope.$on('tether:close', scope.close);
            scope.$on('$destroy', () => drop.destroy());
        }
    };
}
