import Drop from 'tether-drop';
const DropContext = Drop.createContext({classPrefix: 'admin-select-filter'});

export function selectAdminFilterMode(AdminSettings) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        template: require('./select-filter-mode.html'),
        link: (scope, element, attrs) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--filter-mode')[0],
                position: attrs.right ? 'bottom right' : 'bottom left',
                openOn: 'click'
            });

            scope.AdminSettings = AdminSettings;
            scope.close = () => {
                drop.close();
            };

            scope.$on('tether:close', scope.close);
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
