import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import cn from 'classnames'

class Modal extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        title: PropTypes.node,
        closeAction: PropTypes.func.isRequired,
        children: PropTypes.node,
        className: PropTypes.string
    }

    constructor (props) {
        super(props)
    }

    render () {
        const { isOpen, title, closeAction, children, className } = this.props

        return (
            <ReactModal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={closeAction}
                overlayClassName="modal fade in"
                className={cn('modal-dialog', className)}
                style={{ overlay: { zIndex: 4 } }}
                parentSelector={() => document.querySelector('div[ui-view]')}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <i className="icon-common_close" onClick={closeAction}/>
                        <h3>
                            <span className="name">
                                {title}
                            </span>
                        </h3>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </ReactModal>
        )
    }
}

export default Modal
