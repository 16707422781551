import React from 'react'
import PropTypes from 'prop-types'

function UserPrintView ({ user, ...props }) {
    const name = user.name || user.fullName
    return (
        <span className="user" {...props}>
            <span className="text">{name}</span>
        </span>
    )
}

UserPrintView.propTypes = {
    user: PropTypes.object
}

export default UserPrintView
