import antlr4 from './antlr4'
import FormulaLexer from './formula/FormulaLexer'
import FormulaParser from './formula/FormulaParser'
import { escapeHtml } from '../../utils'
import { getFieldId, getFieldValue } from '../../modules/templates/services/data-model-utils'
import { AGGREGATION, AGGREGATION_OPTIONS, AGGREGATION_TYPES, FORMULA, NUMERIC_VALUE } from '../../services/data-types'
import FormulaCalculator from './formula/FormulaCalculator'
import FormulaValidator from './formula/FormulaValidator'

export const STATIC_PART_MIN_LENGTH = 3
export const STATIC_PART_MAX_LENGTH = 255
export const STATIC_PART_MAX_LENGTH_FOR_TASK = 100

export function parseExpression (string, fields) {
    let len = string.length
    let chunks = Array(len)
    let i = 0
    while (i < len) {
        let substr = string.slice(i, len)
        let chunk = { type: 'char', data: substr[0] }
        let step = 1

        if (/[\uD800-\uDBFF]/.test(substr.charAt(0)) && /[\uDC00-\uDFFF]/.test(substr.charAt(1))) {
            chunk.data = substr.slice(0, 2)
            step = 2
        }

        if (substr.slice(0, 2) === '<@') {
            fields.forEach(field => {
                if (substr.indexOf(field.value) === 0) {
                    chunk = { type: 'field', data: field }
                    step = field.value.length
                }
            })
        }

        chunks[i] = chunk
        i = i + step
    }

    return chunks
}

export function flattenReplacement (groups) {
    return groups.reduce((list, group) => {
        return list.concat(group.replacements.filter(replacement => !replacement.hidden))
    }, [])
}

export function getStaticPart (string, fields) {
    return parseExpression(string, fields).filter(chunk => chunk && chunk.type !== 'field').map(chunk => chunk.data).join('')
}

export function getStaticPartLength (string, fields) {
    return getStaticPart(string, fields).trim().length
}

export function escapeExpression (string, fields) {
    return parseExpression(string, fields).filter(chunk => chunk).map(chunk => {
        return chunk.type === 'field' ? chunk.data.value : escapeHtml(chunk.data)
    }).join('').trim()
}

export function normalizeWhiteSpaces (string) {
    return string
        .replace(/\s/g, '')
        .replace(/\*/g, ' * ')
        .replace(/\+/g, ' + ')
        .replace(/-/g, ' - ')
        .replace(/\//g, ' / ')
}

export function formatExpression (string, replacements, normalize = false) {
    if (normalize) {
        string = normalizeWhiteSpaces(string)
    }
    replacements.forEach(replacement => {
        string = string.replace(new RegExp(replacement.value, 'g'), `{${replacement.name}}`)
    })

    return string
}

export function findFieldsUsedInExpression (fields, expression) {
    return fields.filter(field => {
        return expression.indexOf(field.value) > -1
    })
}

export function formatFieldForExpression (field, hidden) {
    const format = [AGGREGATION, FORMULA].includes(field.name.dataType)
        ? field.name.options?.format?.dataType
        : field.name.dataType

    return {
        id: getFieldId(field),
        name: field.name.label,
        value: field.name.id ? `<@field_${field.name.id}>` : field.name.tempId ? `<@field_temp_${field.name.tempId}>` : '',
        format: format || NUMERIC_VALUE,
        hidden
    }
}

export const validateFormula = (input) => {
    return new Promise((resolve, reject) => {
        const chars = new antlr4.InputStream(input)
        const lexer = new FormulaLexer(chars)
        lexer.removeErrorListeners()
        lexer.addErrorListener({
            syntaxError: (recognizer, offendingSymbol, line, column, msg, err) => {
                reject({ error: msg })
            }
        })
        const tokens = new antlr4.CommonTokenStream(lexer)
        const parser = new FormulaParser(tokens)
        parser.buildParseTrees = true
        parser.removeErrorListeners()
        parser.addErrorListener({
            syntaxError: (recognizer, offendingSymbol, line, column, msg, err) => {
                reject({ error: msg })
            }
        })
        const tree = parser.resultExpression()
        antlr4.tree.ParseTreeWalker.DEFAULT.walk(new FormulaValidator(resolve), tree)
    })
}

export const calculateFormula = (field, dataModelList, currentRowIndex) => {
    const { expression } = field?.name?.options
    if (!expression) {
        return undefined
    }

    const chars = new antlr4.InputStream(expression)
    const lexer = new FormulaLexer(chars)
    const tokens = new antlr4.CommonTokenStream(lexer)
    const parser = new FormulaParser(tokens)
    parser.buildParseTrees = true
    const tree = parser.resultExpression()
    return tree.accept(new FormulaCalculator(field, dataModelList, currentRowIndex))
}

export const calculateAggregation = (field, dataModelList) => {
    const { expression, sourceFieldId } = field.name.options
    if (!expression) {
        return undefined
    }

    const fieldId = getFieldId(field)
    const section = dataModelList.map(s => s.section).find(section => section.fieldsWithValues.find((f) => f.name.id === fieldId))
    const sourceField = section.fieldsWithValues.find(f => f.name.id === sourceFieldId)
    const aggregation = AGGREGATION_OPTIONS.find(({ item }) => expression.slice(0, 3) === item)?.item
    const values = []

    section.rows?.forEach((row) => {
        const rowValue = row.values.find(({ columnId }) => columnId === sourceFieldId)
        const value = getFieldValue({ ...sourceField, value: rowValue.value }, dataModelList, row.rowIndex, true)
        if (value !== undefined) {
            values.push(value)
        }
    })

    if (aggregation === AGGREGATION_TYPES.AVG) {
        if (!values.length) {
            return {
                calculationErrors: [
                    { code: 'divisionByZero', fieldId }
                ]
            }
        }
        return values.reduce((a, b) => a + b, 0) / values.length
    }

    if (!values.length) return 0

    if (aggregation === AGGREGATION_TYPES.MIN) {
        // return values.sort((a, b) => (a < b) ? -1 : ((a > b) ? 1 : 0))[0]
        return Math.min(...values)
    }
    if (aggregation === AGGREGATION_TYPES.MAX) {
        // return values.sort((a, b) => (a > b) ? -1 : ((a < b) ? 1 : 0))[0]
        return Math.max(...values)
    }
    if (aggregation === AGGREGATION_TYPES.SUM) {
        return values.reduce((a, b) => a + b, 0)
    }
}

export const extractFieldsFromExpression = expression => {
    const regexp = /((<@field_(temp_)?)([0-9a-z]{32})>)+/g
    return Array.from(expression.matchAll(regexp), f => ({ id: f[4], value: f[1] }))
}
