import moment from 'moment-timezone'
import config from '../../../config'

export class AdminBillingController {
    constructor ($scope, $rootScope, $state, $window, $timeout, PageSettings, AdminApiCalls, AdminSettings, currentUser,
        $translate, DateHelper, $translateSanitization, $q) {
        'ngInject'
        let timeoutId, updateCard
        this.isPreloading = true
        this.canBeCancelled = false
        this.canBeReactivated = false
        this.canBeCancelledImmediately = undefined

        const init = (info) => {
            this.info = info
            if (this.info.trialEnd) {
                const tz = DateHelper.getTZ()
                const endDate = moment.unix(this.info.trialEnd).tz(tz).startOf('day')
                const today = moment.utc().tz(tz).startOf('day')
                this.daysLeft = endDate.diff(today, 'days')
                this.daysLeftCount = angular.toJson({ COUNT: this.daysLeft })
                if (this.daysLeft <= 3) {
                    this.isWarnTrial = true
                }
                this.daysLeft = this.info.trialDays - this.daysLeft
            }
            if (this.info.hasPaymentIssues && !this.isCardUpdating) {
                this.cardError = 'paymentIssues'
            }
            this.isPreloading = false
            return info
        }

        const errorHandler = (err) => {
            this.formHint = err.data.displayError || $translate.instant('error.common')
            if (timeoutId) {
                $timeout.cancel(timeoutId)
            }
            timeoutId = $timeout(() => this.formHint = null, config.errorDelay)
        }

        const updateCompanyBillingInfo = (res) => {
            let info = res.result

            this.canBeCancelledImmediately = info.canBeCancelledImmediately
            this.canBeCancelled = this.canBeCancelledImmediately !== undefined
            this.canBeReactivated = info.hasScheduledCancellation === true

            if (info.plan === 'PAID_COMMON' && !info.isTrial) {
                return AdminApiCalls.getCompanyInvoices()
                    .then(data => init(Object.assign({ payments: data.list }, info)))
                    .catch((err) => {
                        errorHandler(err)
                        return init(info)
                    })
            } else {
                return init(info)
            }
        }

        const initCompanyBillingInfo = () => {
            return AdminApiCalls.getCompanyBillingInfo()
                .then(res => {
                    return updateCompanyBillingInfo(res)
                })
        }

        const checkPaymentIssue = () => {
            const startChecking = (new Date()).getTime()
            const WaitSeconds = 30

            function check () {
                return new $q((resolve, reject) => {
                    $timeout(() => {
                        AdminApiCalls.getCompanyBillingInfo()
                            .then(response => {
                                let timeElapsed = ((new Date()).getTime() - startChecking) / 1000
                                if (!response.result.hasPaymentIssues) {
                                    return response
                                } else if (response.result.hasPaymentIssues && timeElapsed > WaitSeconds) {
                                    return response
                                } else {
                                    return check()
                                }
                            }).then((response) => {
                            resolve(response)
                        }).catch((reason) => {
                            reject(reason)
                        })
                    }, 1000)
                }).then(response => response)
            }

            return check().catch(errorHandler)
        }

        if ($state.params.id && $state.params.state === 'succeeded') {
            AdminApiCalls.getCompanyCardUpdateStatus($state.params.id)
                .then(() => {
                    return initCompanyBillingInfo().then((response) => {
                        if (!response.hasPaymentIssues) {
                            return response
                        }
                        this.cardError = null
                        this.isCardUpdating = true
                        return checkPaymentIssue().then((checkResponse) => {
                            if (checkResponse.result.hasPaymentIssues) {
                                // for prevent launch checkPaymentIssue again after refresh page
                                $state.go('main.admin.billing',
                                    { id: undefined, state: undefined },
                                    { location: 'replace' }
                                )
                            } else {
                                return updateCompanyBillingInfo(checkResponse)
                            }
                        }).finally(() => {
                            this.isCardUpdating = false
                        })
                    })
                })
                .catch(errorHandler)
        } else {
            initCompanyBillingInfo().catch(errorHandler)
        }

        this.updateCard = () => {
            const onCancel = () => {
                $scope.$applyAsync(this.isChargBeeAvailable = false)
            }
            const onSuccess = (pageId) => {
                currentUser.companyPaymentIssuesStartDate = undefined
                this.cardError = null
                AdminApiCalls.getCompanyCardUpdateStatus(pageId)
                    .then(() => {
                        this.isCardUpdating = true
                        onCancel()
                        checkPaymentIssue().then(response => {
                            Object.assign(this.info, response.result)
                            if (response.result.hasPaymentIssues) {
                                this.cardError = 'paymentIssues'
                            }
                        }).finally(() => {
                            this.isCardUpdating = false
                        })
                    })
                    .catch((err) => {
                        errorHandler(err)
                        onCancel()
                    })
            }

            if (updateCard) {
                return
            }
            updateCard = true

            if ($window.navigator.userAgent.indexOf('iPhone') === -1 && !this.info.achAllowed) {
                AdminApiCalls.getCompanyCardUpdateUrlEmbed()
                    .then(data => {
                        this.isChargBeeAvailable = true
                        AdminSettings.callChargeBee(data.result, onSuccess, onCancel)
                    })
                    .catch(errorHandler)
                    .finally(() => updateCard = false)
            } else {
                AdminApiCalls.getCompanyCardUpdateUrlRedirect()
                    .then(data => {
                        $window.location.href = data.result.url
                    })
                    .catch(errorHandler)
                    .finally(() => updateCard = false)
            }
        }

        this.hideChargeBee = () => {
            AdminSettings.hideChargeBee()
            this.isChargBeeAvailable = false
        }

        this.viewInvoice = (payment) => {
            if (payment.isViewLoading) {
                return
            }
            payment.isViewLoading = true
            let newTab = $window.open()
            AdminApiCalls.getCompanyInvoiceLink(payment.id)
                .then(data => newTab.location = data.result.url)
                .catch(errorHandler)
                .finally(() => delete payment.isViewLoading)
        }

        const cancelAccountAction = () => {
            AdminApiCalls.getCompanyInfo()
                .then(data => {
                    AdminApiCalls.cancelCompany({ version: data.version })
                        .then(res => {
                            this.canBeCancelled = false
                            this.actionButtonIsDisabled = false
                            if (res.success && this.canBeCancelledImmediately) {
                                $state.go('accountDisabled')
                            } else if (res.success) {
                                initCompanyBillingInfo().catch(errorHandler)
                            }
                        })
                        .catch(err => {
                            this.formHint = err.data.displayError
                            if (timeoutId) {
                                $timeout.cancel(timeoutId)
                            }
                            timeoutId = $timeout(() => this.formHint = null, config.errorDelay)
                        })
                })
        }

        this.actionButtonIsDisabled = false

        this.cancelAccount = () => {
            let modalScope = $scope.$new()
            modalScope.cancelAccount = true
            $translateSanitization.useStrategy(null)
            modalScope.title = this.canBeCancelledImmediately
                ? $translate.instant('billing.cancelModalImmediately.title')
                : $translate.instant('billing.cancelModal.title')
            modalScope.text = this.canBeCancelledImmediately
                ? $translate.instant('billing.cancelModalImmediately.text', { companyName: $rootScope.currentUser.companyName })
                : $translate.instant('billing.cancelModal.text', { companyName: $rootScope.currentUser.companyName })

            $translateSanitization.useStrategy(config.sanitize_strategy)

            PageSettings.openAlertModal(modalScope).then(
                () => cancelAccountAction(),
                () => {
                    this.actionButtonIsDisabled = false
                }
            )
        }

        this.reactivateAccount = () => {
            AdminApiCalls.getCompanyInfo()
                .then(data => {
                    AdminApiCalls.removeCancellation({ version: data.version })
                        .then(() => {
                            this.actionButtonIsDisabled = false
                            this.canBeReactivated = false
                            initCompanyBillingInfo().catch(errorHandler)
                        })
                })
        }

        this.toggleAccount = () => {
            if (this.actionButtonIsDisabled) {
                return
            }
            this.actionButtonIsDisabled = true

            if (this.canBeCancelled && !this.canBeReactivated) {
                this.cancelAccount()
            }

            if (!this.canBeCancelled && this.canBeReactivated) {
                this.reactivateAccount()
            }
        }

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId)
            }
        })
    }
}
