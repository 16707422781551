import UserSelect from '../react/selectize/user-select.jsx'

export function reactUserSelect(reactDirective) {
    'ngInject'
    const mapProps = [
        'value',
        'options',
        'onChange',
        'placeholder',
        'isDisabled',
        'withEmptyValue'
    ]
    return reactDirective(UserSelect, mapProps, {}, {})
}
