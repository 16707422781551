import React from 'react';
import Dropdown from '../../../components/react/dropdown.jsx';
import Button from "../../../components/react/button.jsx";
import {columnsSorter} from '../store/utils';
import { translate } from '../../react/utils'

const ColumnsDropdown = ({columns, isOpen, toggleDropdown, toggleColumnVisibility, toggleColumnLock, ...props}) => {
    const label = <i className="icon-columns" />;
    const columnKeys = Object.keys(columns).sort(columnsSorter);
    const hiddenColumnsLength = columnKeys.filter(key => columns[key].isHidden).length;
    const columnsCanBeHidden = (columnKeys.length - hiddenColumnsLength) > 1;

    return (
        <Dropdown
            label={label}
            open={isOpen}
            onClick={toggleDropdown}
            {...props}
        >
            <ul className="columns-dropdown">
                {columnKeys.map(key => (
                    <li key={key} className={columns[key].isHidden ? 'muted' : ''}>
                        <span className="column-name">{translate(columns[key].label)}</span>
                        <Button
                            style={`icon`}
                            onClick={() => toggleColumnLock(key, !columns[key].isFixed)}
                        >
                            <i className={!columns[key].isFixed ? 'icon-unlock' : 'icon-lock'} />
                        </Button>
                        <Button
                            style={`icon`}
                            onClick={() => (columns[key].isHidden || columnsCanBeHidden) && toggleColumnVisibility(key, !columns[key].isHidden)}
                        >
                            <i className={!columns[key].isHidden ? 'icon-template_create_field_read_only' : 'icon-template_create_field_hidden'} />
                        </Button>
                    </li>
                ))}
            </ul>
        </Dropdown>
    )
};

export default ColumnsDropdown;
