import colors from '../../../libs/colors';


export function userInGroup($document, $timeout, $filter, $window) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            groupId: '@',
            groupUsers: '=',
            allUsers: '=',
            onsubmit: '&',
            onclose: '&'
        },
        template: require('./user-in-group.html'),
        link: ($scope, element) => {

            function filterAvailableUsers() {
                $scope.availableUsers = angular.copy($scope.allUsers).filter(user => {
                    return $scope.groupUsers.findIndex(grUser => user.id === grUser.id) === -1;
                });
            }
            filterAvailableUsers();

            function moveUser(fromGroup, toGroup, user) {
                let index = fromGroup.findIndex(fromUser => fromUser.id === user.id);
                let isNotExist = toGroup.findIndex(toUser => toUser.id === user.id) === -1; // if before digest
                if(index !== -1 && isNotExist) {
                    toGroup.push(angular.copy(user));
                    fromGroup.splice(index, 1);
                    $scope.onsubmit({id: $scope.groupId});
                }
            }

            $scope.clearAll = () => {
                $scope.availableUsers.push(...$scope.groupUsers);
                $scope.groupUsers = [];
                $timeout(()=> {
                    $scope.onsubmit({id: $scope.groupId});
                });
            };

            $scope.removeUserFromGroup = (user) => {
                moveUser($scope.groupUsers, $scope.availableUsers, user);
            };
            $scope.addUserToGroup = (user) => {
                moveUser($scope.availableUsers, $scope.groupUsers, user);
            };

            $scope.getUserName = (user) => {
                const {fullName, email} = user;
                return fullName.trim() ? fullName : email;
            };

            let searchEl = element.find('.search-input');
            searchEl.focus();
            $scope.isSearchInFocus = true;
            searchEl.on('blur', () => {
                $scope.prevActiveIndex = $scope.activeIndex;
                $scope.activeIndex = undefined;
                $scope.isSearchInFocus = false;
                $scope.$digest();
            }).on('focus', () =>{
                $scope.activeIndex = $scope.prevActiveIndex;
                $scope.isSearchInFocus = true;
                $scope.$digest();
            });

            $scope.activeIndex = undefined;
            $scope.prevActiveIndex = undefined;

            $timeout(() => {
                $scope.availListEl = element.find('.all-users .list ul');
                let prevPos;
                $scope.availListEl.on('mousemove', (event) => {
                    if(!prevPos) { // when list of available users is scrolled by keyboard, this event launching but will be ignored
                        prevPos = {x: event.screenX, y: event.screenY};
                    } else if (prevPos.x !== event.screenX || prevPos.y !== event.screenY) {
                        prevPos.x = event.screenX;
                        prevPos.y = event.screenY;
                        $scope.isMouseHoverHighlightActive = true;
                        $scope.$digest();
                    }
                });
                $scope.availListEl.on('mouseleave', () => {
                    $scope.isMouseHoverHighlightActive = false;
                    $scope.$digest();
                });
                $scope.scrollContainerEl = element.find('.all-users .list .scroll-wrapper')[0];
            });

            let scrollToActiveLi = () => {
                let li = $scope.availListEl.find('li')[$scope.activeIndex];
                if(li) {
                    let scrollBottom = li.offsetTop - $scope.scrollContainerEl.clientHeight;
                    scrollBottom = scrollBottom + parseInt($window.getComputedStyle(li).getPropertyValue('height'));
                    scrollBottom = scrollBottom + parseInt($window.getComputedStyle(li).getPropertyValue('margin-bottom'));
                    let scrollTop = li.offsetTop - parseInt($window.getComputedStyle(li).getPropertyValue('margin-top'));
                    if(scrollBottom > 0 && $scope.scrollContainerEl.scrollTop < scrollBottom) {
                        $scope.scrollContainerEl.scrollTop = scrollBottom;
                    } else if (scrollTop >= 0 && $scope.scrollContainerEl.scrollTop > scrollTop) {
                        $scope.scrollContainerEl.scrollTop = scrollTop;
                    }
                }
            };


            $scope.onKeyPress = ($event) => {
                switch ($event.keyCode) {
                    case 40: // arrow down
                        if($scope.activeIndex !== undefined && $scope.activeIndex + 1 < $scope.filteredAvailableUsers.length) {
                            $scope.activeIndex++;
                        } else if ($scope.activeIndex === undefined) {
                            $scope.activeIndex = 0;
                        }
                        $scope.isMouseHoverHighlightActive = false;
                        $timeout(() => {
                            scrollToActiveLi();
                            $scope.$digest();
                        });
                        break;
                    case 38: // arrow up
                        if($scope.activeIndex !== undefined && $scope.activeIndex - 1 >= 0) {
                            $scope.activeIndex--;
                            $scope.isMouseHoverHighlightActive = false;
                        } else if ($scope.activeIndex === undefined) {
                            break;
                        }
                        $timeout(() => {
                            scrollToActiveLi();
                            $scope.$digest();
                        });
                        break;
                    case 27: // escape
                        $scope.activeIndex = undefined;
                        break;
                    case 13:  // enter
                        $timeout(() => {
                            let activeIndex = $scope.activeIndex;
                            if(activeIndex !== undefined && $scope.filteredAvailableUsers[activeIndex]) {
                                if(activeIndex === $scope.filteredAvailableUsers.length - 1) {
                                    $scope.activeIndex--;
                                }
                                $scope.addUserToGroup($scope.filteredAvailableUsers[activeIndex]);
                            }
                        });
                        break;
                    default:
                        $scope.activeIndex = undefined;
                }
            };

            $scope.$watch('searchAvailableUsers', function(newValue) {
                if(newValue) {
                    let availUserTemp = $filter('search')($scope.availableUsers, newValue);
                    $scope.filteredAvailableUsers = $filter('orderBy')(availUserTemp, 'fullName');
                } else {
                    $scope.filteredAvailableUsers = $filter('orderBy')($scope.availableUsers, 'fullName');
                }
            });
            $scope.$watchCollection('availableUsers', function() {
                let availUserTemp;
                if($scope.searchAvailableUsers) {
                    availUserTemp = $filter('search')($scope.availableUsers, $scope.searchAvailableUsers);
                } else {
                    availUserTemp = $scope.availableUsers;
                }
                $scope.filteredAvailableUsers = $filter('orderBy')(availUserTemp, 'fullName');
            });
            $scope.$watch('groupUsers', () => {
                filterAvailableUsers();
            });

            function onLostFocus() {
                $scope.onclose();
            }
            function onPreventLostFocus(event) {
                event.stopPropagation();
            }
            $timeout(() => {
                $document.on('click', onLostFocus);
                element.on('click', onPreventLostFocus);
            });
            $scope.$on('$destroy', () => {
                $document.off('click', onLostFocus);
                element.off('click', onPreventLostFocus);
            });

            $scope.delayHelpTooltip = 3000;

            $scope.colors = colors;
        }
    };
}
