import config from '../config.js';

export function AuthenticationConfiguration($authProvider) {
    $authProvider.httpInterceptor = true; // Add Authorization header to HTTP request
    $authProvider.loginOnSignup = true;
    $authProvider.loginRedirect = 'home'; //change location path after login
    $authProvider.logoutRedirect = 'login';
    $authProvider.signupRedirect = 'home'; //change location path after signUp
    $authProvider.loginUrl = config.api + '/authenticate/user';  //url to post login data
    $authProvider.signupUrl = config.api + '/non-existing'; //url to post sign up data
    $authProvider.loginRoute = '/login';
    $authProvider.signupRoute = '/signup';
    $authProvider.tokenName = 'token';
    $authProvider.tokenPrefix = 'satellizer'; // Local Storage name prefix
    $authProvider.authHeader = 'X-Auth-Token';
    $authProvider.authToken = undefined; //do not use token within 'X-Auth-Token'
    $authProvider.platform = 'browser';
    $authProvider.storage = 'localStorage';
}