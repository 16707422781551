import utils from '../utils';
import moment from 'moment-timezone';

export function processDatePicker(DateHelper) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dueDate: '=?',
            dueDateInterval: '=?',
            completedDate: '=?',
            startedDate: '=?',
            onClose: '&?',
            onClick: '&?',
            hasPermission: '='
        },
        template: `
            <span class="process-date-picker prc-date-picker" ng-class="{hasPermission: hasPermission}" ng-click="onClickCalendar(event)">
                <div ng-show="dueDate || dueDateInterval">
                    <span ng-if="isPastDue" class="bold red">
                        {{'label.pastDue' | translate}}
                    </span>
                    <span class="bold" ng-if="!isPastDue && dueDate" ng-class="{red: isWarnDue}">
                        {{dueDate | dueDate:true}}
                    </span>
                    <span class="bold" ng-if="!isWarnDue && dueDateInterval" ng-class="{red: isWarnDue}">
                        {{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(dueDateInterval):'messageformat'}}
                    </span>
                    <span class="date-str grey" ng-class="{red: isWarnDue}">{{formattedDueDate}}</span>
                </div>
                <round-progress max="getSvgValues().max" current="getSvgValues().current" radius="10" stroke="5"
                                color="{{isWarnDue ? '#f75f5f' : '#4abc3b'}}" bgcolor="#e8e8e8"
                                ng-if="dueDate || dueDateInterval"></round-progress>
                <span ng-hide="dueDate || dueDateInterval || !hasPermission" class="handler">
                    <i class="icon-task_due_date_add"></i>
                </span>
            </span>
        `,

        link: (scope, element) => {

            scope.onClickCalendar = (event) => {
                scope.onClick({
                    $event: event,
                    targetElement: element[0],
                    dueDate: scope.dueDate,
                    dueDateInterval: scope.dueDateInterval
                });
            };

            function formatDate() {
                let processDueDate = {
                    dueDate: scope.dueDate,
                    dueDateInterval: scope.dueDateInterval
                };
                scope.isPastDue = utils.checkPrDueDate(processDueDate, true, DateHelper);
                scope.isWarnDue = utils.checkPrDueDate(processDueDate, false, DateHelper);
                scope.formattedDueDate = utils.getDueDate(processDueDate, DateHelper);
            }

            formatDate();
            scope.getIntervalStr = (interval) => angular.toJson({COUNT: interval || 0});

            scope.$watchGroup(['dueDate', 'dueDateInterval'], () => {
                formatDate();
            });


            scope.getSvgValues = () => {
                if (!scope.dueDate && !scope.dueDateInterval) {
                    return;
                }

                let tz = DateHelper.getTZ();
                let today = moment.utc().tz(tz);
                let startDate = moment.unix(scope.startedDate).tz(tz);
                let dueDate;

                if (scope.dueDate) {
                    dueDate = moment.unix(scope.dueDate).tz(tz);
                } else {
                    dueDate = moment().add(scope.dueDateInterval, 'days').tz(tz);
                }

                let maxRange = Math.ceil(dueDate.diff(startDate, 'hours') / 24);
                let currentRange = Math.ceil(today.diff(startDate, 'hours') / 24);

                if (scope.isWarnDue || scope.isPastDue) {
                    if (today.format('DD') < dueDate.format('DD')) {
                        currentRange = currentRange <= 0 ? 1 : currentRange;
                        return {
                            current: currentRange,
                            max: currentRange + 1
                        };
                    }
                    return {
                        current: 1,
                        max: 1
                    };
                }

                return {
                    current: currentRange <= 0 ? 1 : currentRange,
                    max: maxRange <= 0 ? 1 : maxRange
                };
            };
        }
    };
}
