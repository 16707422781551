import React from 'react'
import PropTypes from 'prop-types'
import { generateInitials } from '../../../filters/initials'
import { getUserColor } from '../../../utils'

const UserAvatar = ({ user }) => {
    let icon
    if (user.avatarUrl) {
        icon = <img src={`${user.avatarUrl}_24`} alt={user.fullName} />
    } else if (user.icon) {
        icon = <span className="special-icon"><i className={user.icon} /></span>
    } else if (user.fullName) {
        icon = <span className="initials">{generateInitials(user.fullName)}</span>
    }

    return (
        <div className="thumbnail" style={{ background: getUserColor(user) }}>
            {icon}
            {user.isDeleted && (
                <div className="badge-deleted">
                    <i className="icon-assignee_badge_issued" />
                </div>
            )}
        </div>
    )
}

UserAvatar.propTypes = {
    user: PropTypes.object
}

export default UserAvatar
