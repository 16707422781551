import config from '../config'
import {
    ALLOWED_FILE_TYPES_OPTIONS,
    CREATE_NEW_TABLE,
    DEFAULT_FIELD_SETTINGS,
    LIST_SOURCE_OPTIONS,
    PROVIDE_LIST_ITEMS,
    USE_EXISTING_TABLE
} from '../services/constants'
import { DataTypedValue } from '../modules/database/models/datatypes'
import { DEFAULT_TABLE_ICON, TableForm } from '../modules/database/models'
import { ActorsValue } from '../modules/database/models/actors'
import { UserId } from '../modules/database/models/user'
import {
    AGGREGATION_OPTIONS,
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    FORMAT_OPTIONS,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../services/data-types'
import {
    checkDefaultFieldSettings,
    checkDefaultValueError,
    checkInvalidEmail,
    convertDefaultValue
} from '../modules/templates/services/data-model-utils'
import { validateFormula } from '../components/expression'

const RECORDS_PAGE_SIZE = 200
const DISPLAY_FIELDS_MAX_LENGTH = 5

export class DataModelFieldSettingsController {
    constructor ($scope, $modalInstance, $translate, companyCurrencies, allCurrencies, ApiCalls, currentUser) {
        'ngInject'
        const field = () => $scope.field.name
        const { dataType } = field()
        const dataTypeIs = (str) => dataType === str

        const defaultSource = {
            tables: [],
            tableId: undefined,
            columns: [],
            fields: [
                { protected: true }
            ],
            records: []
        }

        const setDefaults = () => {
            delete $scope.settings.defaultValue
            delete $scope.settings.invalidEmail
            $scope.description = ''

            if (dataTypeIs(FILES_LIST)) {
                $scope.settings.maxFiles = DEFAULT_FIELD_SETTINGS.maxFiles
                $scope.settings.selectedFileTypesOption = ALLOWED_FILE_TYPES_OPTIONS[0].label
            }
            if (dataTypeIs(STRING_SINGLE_LINE)) {
                $scope.settings.minLength = DEFAULT_FIELD_SETTINGS.minLength
                $scope.settings.maxLength = DEFAULT_FIELD_SETTINGS.maxLength
            }
            if (dataTypeIs(STRING_MULTI_LINE)) {
                $scope.settings.minLength = DEFAULT_FIELD_SETTINGS.minLength
                $scope.settings.maxLength = DEFAULT_FIELD_SETTINGS.maxMultilineLength
            }
            if (dataTypeIs(NUMERIC_VALUE)) {
                delete $scope.settings.minValue
                delete $scope.settings.maxValue
                delete $scope.settings.isInteger
                delete $scope.settings.showTotal
                $scope.settings.precision = DEFAULT_FIELD_SETTINGS.precision
                $scope.settings.aggregation.expression = undefined
            }
            if (dataTypeIs(MONEY)) {
                delete $scope.settings.minValue
                delete $scope.settings.maxValue
                delete $scope.settings.showTotal
                $scope.settings.currency = {
                    default: companyCurrencies.defaultCurrency,
                    available: []
                }
                $scope.resetMoneyError()
                $scope.settings.aggregation.expression = undefined
            }
            if (dataTypeIs(DATE_ONLY_VALUE) || dataTypeIs(DATE_AND_TIME)) {
                delete $scope.settings.minValue
                delete $scope.settings.maxValue
            }
            if (dataTypeIs(MULTI_SELECTOR) || dataTypeIs(RADIO_SELECTOR)) {
                delete $scope.settings.source.allowedValues
                delete $scope.settings.options
            }
            if (dataTypeIs(USER_FIELD)) {
                delete $scope.settings.group
                $scope.settings.allUsers = true
                $scope.settings.defaultValue = null
            }
            if (dataTypeIs(FORMULA)) {
                $scope.settings = {
                    ...$scope.settings,
                    expression: '',
                    format: FORMAT_OPTIONS.find(opt => opt.isDefault).label,
                    currency: Object.assign({}, companyCurrencies.defaultCurrency),
                    precision: DEFAULT_FIELD_SETTINGS.precision,
                    error: undefined,
                }
                $scope.settings.aggregation.expression = undefined
            }
            $scope.source.type = LIST_SOURCE_OPTIONS.find(opt => opt.default).id
            $scope.settings.source = { ...defaultSource }
            $scope.description = ''
        }

        $scope.pattern = config.pattern
        $scope.yesNoOptions = [{ item: 'label.yes', id: 'yes' }, { item: 'label.no', id: 'no' }]
        $scope.formatOptions = FORMAT_OPTIONS.map(opt => ({ item: opt.label }))
        $scope.dateDefaultOptions = [{ item: 'label.processStartDate', id: 'PROCESS_START_DATE' }]
        $scope.dateTimeDefaultOptions = [{ item: 'label.processStartDateAndTime', id: 'PROCESS_START_DATE_TIME' }]
        $scope.defaultValueCurrencies = []
        $scope.currencies = [companyCurrencies.defaultCurrency].concat(companyCurrencies.availableCurrencies)
        $scope.fieldNameStr = angular.toJson({ name: field().label || $translate.instant('label.thisField') })
        $scope.settings = {}
        $scope.noSourceTablesText = $translate.instant('text.sourceTableSelect.noResults')
        $scope.description = field().description || ''

        $scope.source = {
            type: LIST_SOURCE_OPTIONS.find(opt => opt.default).id,
            options: LIST_SOURCE_OPTIONS.filter(opt => !opt.adminOnly || currentUser.isAdmin).map(opt => ({
                item: opt.label,
                id: opt.id
            })),
            tables: []
        }
        $scope.newTableSource = listSource => {
            return listSource === CREATE_NEW_TABLE
        }
        $scope.existingTableSource = listSource => {
            return listSource === USE_EXISTING_TABLE
        }
        $scope.staticListSource = listSource => {
            return listSource === PROVIDE_LIST_ITEMS
        }
        $scope.isNumberFormat = format => {
            return FORMAT_OPTIONS.find(opt => opt.label === format).id === NUMERIC_VALUE
        }
        $scope.isMoneyFormat = format => {
            return FORMAT_OPTIONS.find(opt => opt.label === format).id === MONEY
        }

        const loadSourceTables = (initial = false) => {
            $scope.source.tables = []
            $scope.settings.source.tables = []
            ApiCalls.getSourceTables().then(response => {
                $scope.source.tables = response.list.map(t => ({
                    ...t,
                    icon: { ...DEFAULT_TABLE_ICON, ...t.icon }
                }))
                $scope.settings.source.tables = $scope.source.tables.slice().filter(sourceTableFilter).map(option => {
                    return {
                        ...option,
                        selected: $scope.settings.source.tableId && $scope.settings.source.tableId === option.id,
                    }
                })
                if (initial && field().options.source.tableId && field().options.source.isInvalid) {
                    $scope.settings.source.tables.push({
                        id: field().options.source.tableId,
                        name: field().options.source.tableName,
                        selected: true,
                        isInvalid: true,
                        icon: { ...DEFAULT_TABLE_ICON, ...(field().options.source.icon || {}) }
                    })
                }
            })
        }

        const loadSourceTableData = (callback) => {
            $scope.settings.source.columns = []
            $scope.settings.source.fields = []
            $scope.settings.source.records = []
            ApiCalls.getSourceTableColumns($scope.settings.source.tableId).then(response => {
                $scope.settings.source.columns = response.columns
                ApiCalls.getSourceTableRecords($scope.settings.source.tableId, {
                    count: RECORDS_PAGE_SIZE,
                    displayFields: field().options && field().options.source.displayFields ? field().options.source.displayFields.join(',') : []
                }).then(response => {
                    $scope.settings.source.fields = [{ protected: true, name: response.columns[0].name }]
                    $scope.settings.source.records = response.list.map(record => {
                        return {
                            ...record,
                            values: record.values.map(({ value }, index) => {
                                return DataTypedValue.create({ ...value, dataType: response.columns[index].dataType })
                            })
                        }
                    })
                    if (callback) {
                        callback()
                    }
                })
            })
        }

        $scope.togglePrecision = (event) => {
            if (event) {
                event.preventDefault()
                event.stopPropagation()
            }
            if ($scope.settings.isInteger) {
                $scope.settings.precision = DEFAULT_FIELD_SETTINGS.precision
            } else {
                $scope.settings.precision = null
            }
            $scope.settings.isInteger = !$scope.settings.isInteger
        }
        $scope.incorrectDates = () => {
            if (dataTypeIs(DATE_ONLY_VALUE) || dataTypeIs(DATE_AND_TIME)) {
                return $scope.settings.maxValue && $scope.settings.minValue
                    && $scope.settings.maxValue < $scope.settings.minValue
            }
        }
        $scope.incorrectMainCurrency = () => {
            if ($scope.optionsError && $scope.optionsError.currency && $scope.optionsError.currency.default) {
                return true
            }
            if (dataTypeIs(MONEY) && $scope.settings.currency) {
                let obj = $scope.settings.currency
                return obj.default && obj.available && obj.available.find(a => a.id === obj.default.id)
            }
        }
        $scope.incorrectSettings = () => {
            if (dataTypeIs(MULTI_SELECTOR) || dataTypeIs(RADIO_SELECTOR)) {
                const sourceType = $scope.source.type
                if (sourceType === PROVIDE_LIST_ITEMS) {
                    return !$scope.settings.source.allowedValues || !$scope.settings.source.allowedValues.length || $scope.settings.source.allowedValues.length > 200
                } else if (sourceType === USE_EXISTING_TABLE) {
                    return !$scope.settings.source.tableId || $scope.settings.source.isInvalid || $scope.displayFieldsAreInvalid
                }
            } else if (dataTypeIs(FORMULA)) {
                return $scope.settings.expression === undefined || $scope.settings.expression.replace(/\n/g, '').trim() === ''
            }
            return $scope.settings.invalidEmail || $scope.checkDefaultValueError()
        }
        $scope.getMainCurrencyError = () => {
            if ($scope.optionsError && $scope.optionsError.currency && $scope.optionsError.currency.default) {
                return $scope.optionsError.currency.default[0].fieldError
            }
            const curr = $scope.settings.currency
            if (dataTypeIs(MONEY) && curr && curr.default) {
                return $translate.instant('mainCurrency.error', { CODE: curr.default.code })
            }
        }
        $scope.incorrectAvailableCurrencies = () => {
            return $scope.optionsError && $scope.optionsError.currency && $scope.optionsError.currency.available
        }
        $scope.getAvailableCurrenciesError = () => {
            return $scope.optionsError.currency.available[0].fieldError
        }
        $scope.resetMoneyError = () => $scope.optionsError = null
        $scope.checkOptions = () => {
            if (!$scope.form || !$scope.form.options) {
                return
            }
            let errors = $scope.form.options.$error
            if (errors) {
                if (errors.pattern) {
                    return $translate.instant('validation.textNumSpecial')
                }
                if (errors.itemMaxlength) {
                    return $translate.instant('validation.field.options.itemMaxlength')
                }
                if (errors.empty) {
                    return $translate.instant('validation.field.options.empty')
                }
                if (errors.notUnique) {
                    return $translate.instant('validation.field.options.unique')
                }
            }
        }

        $scope.checkDefaults = () => {
            return ($scope.description === '' || !$scope.description)
                && (!$scope.settings.defaultValue || ($scope.settings.defaultValue && dataTypeIs(MONEY) && !$scope.settings.defaultValue.amount))
                && checkDefaultFieldSettings(field(), $scope.settings, { companyCurrencies, source: $scope.source })
        }

        $scope.checkDefaultValueError = () => {
            const dataTypedDefaultValue = convertDefaultValue(field(), $scope.settings.defaultValue, { currency: $scope.settings.currency })
            return checkDefaultValueError(field(), dataTypedDefaultValue, $scope.settings) || false
        }

        let savingIsInProgress
        $scope.saveSettings = () => {
            if (savingIsInProgress) {
                return
            }
            savingIsInProgress = true
            const sourceType = $scope.source.type

            if (sourceType === CREATE_NEW_TABLE) {
                const newTable = TableForm.create({
                    name: $scope.settings.source.tableName,
                    owners: ActorsValue.create({ users: [UserId.create({ id: currentUser.id })] }),
                    editors: ActorsValue.create({ users: [UserId.create({ id: currentUser.id })] }),
                    viewers: ActorsValue.create({ specialRoles: [{ allUsers: true }] }),
                })
                newTable.addNewColumn(`Title`)
                newTable.save().then(({ response, error }) => {
                    if (error) {
                        savingIsInProgress = false
                        $scope.settings.source.tableNameError = error && error.name && error.name.length ? error.name[0].fieldError : error
                        return $scope.$apply()
                    }
                    const { result } = response
                    ApiCalls.createTableRecords(result.id, {
                        columnId: result.columns[0].id,
                        values: $scope.settings.source.allowedValues.map(({ item }) => item)
                    }).then(response => {
                        savingIsInProgress = false
                        $scope.settings.source.tableId = result.id
                        $scope.settings.source.fields = []
                        if ($scope.settings.defaultValue && $scope.settings.defaultValue.item) {
                            const defaultRecord = response.list.find(r => r.values[0].value.stringValue === $scope.settings.defaultValue.item)
                            if (defaultRecord) {
                                $scope.settings.defaultValue = {
                                    recordId: defaultRecord.id,
                                    values: defaultRecord.values.map(({ value }) => ({
                                        ...value,
                                        dataType: STRING_SINGLE_LINE
                                    }))
                                }
                            }
                        }

                        $scope.ok(USE_EXISTING_TABLE)
                    })
                })
            } else {
                savingIsInProgress = false
                $scope.ok(sourceType)
            }
        }

        $scope.ok = (sourceType) => {
            if ((dataTypeIs(RADIO_SELECTOR) || dataTypeIs(MULTI_SELECTOR)) && $scope.settings.source.allowedValues && $scope.settings.source.allowedValues.length > 200) {
                return
            }
            let settings = angular.copy($scope.settings)
            if (settings.options) {
                delete settings.options
            }
            if (dataTypeIs(RADIO_SELECTOR) || dataTypeIs(MULTI_SELECTOR)) {
                if (sourceType === PROVIDE_LIST_ITEMS) {
                    settings.useTableAsSource = false
                    delete settings.useTableAsSource
                    const { allowedValues } = settings.source
                    settings.source = { allowedValues }
                }
                if (sourceType === USE_EXISTING_TABLE) {
                    settings.useTableAsSource = true
                    settings.source = {
                        tableId: $scope.settings.source.tableId,
                        displayFields: $scope.settings.source.fields.filter(f => !f.protected).map(f => f.id)
                    }
                }
            } else {
                settings.source = undefined
            }

            if (settings.isInteger) {
                settings.precision = 0
                delete settings.isInteger
            }
            if (settings.currency) {
                if (settings.currency.default) {
                    settings.currency.default = {
                        id: settings.currency.default.id,
                        numberOfUnits: settings.currency.default.numberOfUnits
                    }
                }
                if (settings.currency.available && settings.currency.available.length) {
                    settings.currency.available = settings.currency.available.map(a => ({ id: a.id }))
                }
            }
            const defaultValue = convertDefaultValue(field(), settings.defaultValue, {
                sourceType,
                currency: settings.currency
            })
            settings.defaultValue = undefined
            settings.options = undefined

            if (dataTypeIs(FILES_LIST)) {
                const selectedFileTypesOption = ALLOWED_FILE_TYPES_OPTIONS.find(({ label }) => label === settings.selectedFileTypesOption)
                if (selectedFileTypesOption && selectedFileTypesOption.accept) {
                    settings.accept = selectedFileTypesOption.accept
                } else {
                    delete settings.accept
                }
                delete settings.selectedFileTypesOption
            }

            if (dataTypeIs(FORMULA)) {
                const { expression, currency, precision, error, aggregation, totalFieldId } = settings
                const format = { dataType: FORMAT_OPTIONS.find(opt => opt.label === settings.format).id }

                if (format.dataType === NUMERIC_VALUE) {
                    format.options = { precision }
                } else if (format.dataType === MONEY) {
                    format.options = { currency }
                }

                settings = { expression: `${expression}\n`, format, error, aggregation, totalFieldId }
            }

            if ($scope.form.$valid) {
                if ($scope.resetOptionsError && !$scope.optionsError) {
                    $scope.resetOptionsError()
                }
                $modalInstance.close({
                    options: settings,
                    defaultValue,
                    description: $scope.description.slice(0, 500)
                })
            }
        }
        $scope.validateDefaultValue = () => {
            if (dataTypeIs(EMAIL)) {
                $scope.settings.invalidEmail = checkInvalidEmail($scope.settings.defaultValue)
            }
        }
        $scope.cancel = () => $modalInstance.dismiss()
        $scope.reset = () => setDefaults()

        const sourceTableFilter = table => !$scope.settings.source.query || table.name.toLowerCase().includes($scope.settings.source.query.toLowerCase())
        const filterTables = () => {
            $scope.settings.source.tables = $scope.source.tables.filter(sourceTableFilter).map(option => {
                return {
                    ...option,
                    selected: $scope.settings.source.tableId && $scope.settings.source.tableId === option.id
                }
            })
        }

        $scope.onSourceTableSearch = (query) => {
            $scope.settings.source.query = query
            filterTables()
        }

        $scope.onSelectSourceTable = table => {
            if (table && table.noResults) {
                return
            }

            let selectedTableId = table ? table.id : undefined
            delete $scope.settings.source.isInvalid
            delete $scope.settings.source.tableName

            $scope.settings.source.tableId = selectedTableId
            filterTables()
        }

        $scope.getFormattedValue = recordValue => {
            return recordValue ? recordValue.displayValue : ''
        }

        $scope.getFormattedRecordValues = record => {
            let string = ''
            if (record.values.length > 1) {
                const values = record.values.slice(1).filter($scope.getFormattedValue)
                if (values.length) {
                    string = ' (' + values.map($scope.getFormattedValue).join(', ') + ')'
                }
            }

            return $scope.getFormattedValue(record.values[0]) + string
        }

        $scope.setDefaultValue = (value) => {
            if (value && value.recordId) {
                const { recordId, name } = value
                return $scope.settings.defaultValue = { recordId, name }
            }
            $scope.settings.defaultValue = value
        }

        $scope.defaultValueIsDisabled = () => {
            if (dataTypeIs(RADIO_SELECTOR)) {
                if ($scope.selectedSourceId === USE_EXISTING_TABLE) {
                    return !$scope.settings.source.records.length && !$scope.settings.defaultValue
                }
            }
            return false
        }

        $scope.getDisplayFieldsIds = () => {
            return $scope.settings.source.fields ? $scope.settings.source.fields.filter(f => !f.protected).map(f => f.id) : []
        }

        $scope.updateSourceType = value => {
            $scope.source.type = value.id
        }

        const initialize = () => {
            if (field().options) {
                const { source = {}, ...settings } = field().options
                $scope.settings = { ...settings, source: { ...defaultSource, ...source } }
            } else {
                $scope.settings.source = { ...defaultSource }
            }

            let isLoaded = false

            if (dataTypeIs(FILES_LIST)) {
                $scope.settings.maxFiles = $scope.settings.maxFiles || DEFAULT_FIELD_SETTINGS.maxFiles
                $scope.allowedFileTypesOptions = ALLOWED_FILE_TYPES_OPTIONS.map(({ label }) => ({ item: label }))
                let selectedFileTypesOption
                if ($scope.settings.accept) {
                    selectedFileTypesOption = ALLOWED_FILE_TYPES_OPTIONS.find(({ accept }) => accept === $scope.settings.accept)
                }
                $scope.settings.selectedFileTypesOption = selectedFileTypesOption
                    ? selectedFileTypesOption.label
                    : ALLOWED_FILE_TYPES_OPTIONS[0].label
            }
            if (dataTypeIs(STRING_SINGLE_LINE) || dataTypeIs(STRING_MULTI_LINE)) {
                $scope.settings.minLength = $scope.settings.minLength || DEFAULT_FIELD_SETTINGS.minLength
                $scope.settings.maxLength = $scope.settings.maxLength || DEFAULT_FIELD_SETTINGS.maxLength

                if (field().defaultValue && field().defaultValue.stringValue) {
                    $scope.settings.defaultValue = field().defaultValue.stringValue
                }
            }
            if (dataTypeIs(STRING_MULTI_LINE)) {
                $scope.settings.maxLength = $scope.settings.maxLength || DEFAULT_FIELD_SETTINGS.maxMultilineLength
            }
            if (dataTypeIs(NUMERIC_VALUE)) {
                if ($scope.settings.precision === 0) {
                    $scope.settings.isInteger = true
                    delete $scope.settings.precision
                } else {
                    $scope.settings.precision = $scope.settings.precision || DEFAULT_FIELD_SETTINGS.precision
                }
                if (field().defaultValue && field().defaultValue.numericValue) {
                    $scope.settings.defaultValue = field().defaultValue.numericValue
                }

                $scope.onDefaultValueChange = value => {
                    $scope.settings.defaultValue = value
                }

                $scope.getPrecisionValue = () => {
                    return $scope.settings.isInteger ? 0 : $scope.settings.precision
                }
            }
            if (dataTypeIs(RADIO_SELECTOR)) {
                if (field().defaultValue && field().defaultValue.radioButtonValue) {
                    if (field().defaultValue.radioButtonValue.recordId || field().defaultValue.radioButtonValue.id) {
                        $scope.settings.defaultValue = field().defaultValue.radioButtonValue
                    } else {
                        $scope.settings.defaultValue = { item: field().defaultValue.radioButtonValue }
                    }
                } else {
                    $scope.settings.defaultValue = null
                }
            }
            if (dataTypeIs(MULTI_SELECTOR) || dataTypeIs(RADIO_SELECTOR)) {
                if ($scope.settings.source.allowedValues && $scope.settings.source.allowedValues.length) {
                    $scope.settings.options = $scope.settings.source.allowedValues.map(i => i.item).join('\n')
                }
                if ($scope.settings.useTableAsSource) {
                    $scope.source.type = USE_EXISTING_TABLE
                    $scope.settings.source.tableId = field().options.source.tableId
                    loadSourceTables(true)
                    loadSourceTableData(() => {
                        if (field().options.source.displayFields) {
                            field().options.source.displayFields.forEach(columnId => {
                                const column = $scope.settings.source.columns.find(c => c.id === columnId)
                                if (column) {
                                    $scope.settings.source.fields.push(column)
                                }
                            })
                        }
                        $scope.$applyAsync(() => isLoaded = true)
                    })
                }

                $scope.$watch(() => $scope.settings.options, value => {
                    if (value) {
                        const options = value.split('\n').map(v => ({ item: v })).filter(val => val.item.trim())
                        const originValues = [...$scope.settings.source.allowedValues]
                        $scope.settings.source.allowedValues = options.map(({ item }) => {
                            const savedValue = originValues.find(val => val.item === item)
                            return savedValue || { item }
                        })
                    } else {
                        $scope.settings.source.allowedValues = []
                    }

                    const selectedSource = $scope.source.type
                    if (dataTypeIs(RADIO_SELECTOR)
                        && selectedSource === PROVIDE_LIST_ITEMS
                        && $scope.settings.defaultValue
                        && !$scope.settings.source.allowedValues.find(val => val.item === $scope.settings.defaultValue.item)) {
                        delete $scope.settings.defaultValue
                    }
                }, true)

                $scope.$watch(() => $scope.source.type, (sourceType, prevSourceType) => {
                    $scope.selectedSourceId = sourceType

                    if (sourceType !== prevSourceType) {
                        $scope.settings.source.columns = []
                        $scope.settings.source.fields = []
                        $scope.settings.source.records = []

                        if ($scope.selectedSourceId === USE_EXISTING_TABLE) {
                            $scope.settings.source.allowedValues = []
                            $scope.settings.options = undefined
                            $scope.settings.defaultValue = undefined

                            isLoaded = false
                            loadSourceTables()
                            if ($scope.settings.source.tableId) {
                                loadSourceTableData(() => $scope.$applyAsync(() => isLoaded = true))
                            }
                        }

                        if ($scope.selectedSourceId === CREATE_NEW_TABLE) {
                            $scope.settings.source.tableName = field().label
                        }
                    }
                }, true)

                $scope.$watch(() => $scope.settings.source.tableId, (selectedTableId, prevSelectedTableId) => {
                    if (!$scope.selectedSourceId || $scope.selectedSourceId !== USE_EXISTING_TABLE) {
                        return
                    }
                    if (!selectedTableId) {
                        delete $scope.settings.defaultValue
                        $scope.settings.source.columns = []
                        $scope.settings.source.fields = []
                        $scope.settings.source.records = []
                        $scope.settings.source.allowedValues = []
                        return
                    }

                    if (selectedTableId !== prevSelectedTableId) {
                        delete $scope.settings.defaultValue
                        loadSourceTableData(() => $scope.$applyAsync(() => isLoaded = true))
                    }
                }, true)

                $scope.$watch(() => $scope.settings.source.fields.filter(f => !f.protected).map(f => f.id).join(','), (displayFieldsIds, prevDisplayFieldsIds) => {
                    $scope.displayFieldsAreInvalid = $scope.getDisplayFieldsIds().length > DISPLAY_FIELDS_MAX_LENGTH
                    if ($scope.settings.source.tableId && $scope.selectedSourceId === USE_EXISTING_TABLE && displayFieldsIds !== prevDisplayFieldsIds && isLoaded) {
                        ApiCalls.getSourceTableRecords($scope.settings.source.tableId, {
                            count: RECORDS_PAGE_SIZE,
                            displayFields: displayFieldsIds
                        }).then(response => {
                            $scope.settings.source.records = response.list.map(record => {
                                return {
                                    ...record,
                                    values: record.values.map(({ value }, index) => {
                                        return DataTypedValue.create({
                                            ...value,
                                            dataType: response.columns[index].dataType
                                        })
                                    })
                                }
                            })
                            $scope.settings.source.allowedValues = $scope.settings.source.records.map(record => ({
                                item: $scope.getFormattedRecordValues(record),
                                recordId: record.id
                            }))
                            if ($scope.settings.defaultValue && $scope.settings.defaultValue.recordId) {
                                const newDefaultRecord = $scope.settings.source.allowedValues.find(r => r.recordId === $scope.settings.defaultValue.recordId)
                                if (newDefaultRecord) {
                                    $scope.setDefaultValue({
                                        recordId: newDefaultRecord.recordId,
                                        name: newDefaultRecord.item
                                    })
                                }
                            }
                        })
                    }
                })
            }
            if (dataTypeIs(YES_NO) && field().defaultValue && field().defaultValue.yesNoValue) {
                $scope.settings.defaultValue = { id: field().defaultValue.yesNoValue }
            }
            if (dataTypeIs(MONEY)) {
                if (!$scope.settings.currency) {
                    $scope.settings.currency = {
                        default: Object.assign({}, companyCurrencies.defaultCurrency),
                        available: []
                    }
                } else {
                    let data = $scope.settings.currency
                    if (data.default) {
                        let newDefault = allCurrencies.find(a => a.id === data.default.id)
                        data.default = newDefault || Object.assign({}, companyCurrencies.defaultCurrency)
                    }
                    if (data.available && data.available.length) {
                        data.available = data.available.map(a => allCurrencies.find(c => c.id === a.id))
                    }
                }
                if (field().defaultValue && field().defaultValue.moneyValue) {
                    $scope.settings.defaultValue = field().defaultValue.moneyValue
                } else {
                    $scope.settings.defaultValue = {}
                }

                $scope.$watch(() => $scope.settings.currency, (newVal) => {
                    const mainCurrency = newVal.default
                    const available = newVal.available.filter(v => !mainCurrency || v.id !== mainCurrency.id)
                    if (available.length) {
                        $scope.settings.aggregation.expression = undefined
                    }
                    $scope.defaultValueCurrencies = [mainCurrency, ...available]
                    if ($scope.settings.defaultValue?.currency && !$scope.defaultValueCurrencies.find(({ id }) => id === $scope.settings.defaultValue.currency)) {
                        $scope.settings.defaultValue = {
                            amount: $scope.settings.defaultValue.amount,
                            currency: mainCurrency.id,
                            currencyInfo: mainCurrency
                        }
                    }
                }, true)
            }
            if (dataTypeIs(USER_FIELD)) {
                const specialUserValues = [
                    {
                        value: 'PROCESS_STARTER',
                        label: $translate.instant('label.processStarter'),
                        icon: 'icon-assignee_process_starter'
                    },
                    {
                        value: 'PROCESS_STARTER_DIRECT_MANAGER',
                        label: $translate.instant('label.starterManager'),
                        icon: 'icon-assignee_process_starter'
                    }
                ]
                const getUsers = () => {
                    const groupId = $scope.settings.group && $scope.settings.group.id ? $scope.settings.group.id : undefined
                    ApiCalls.getActorsUsers({ groupId }).then(data => {
                        $scope.$applyAsync(() => {
                            const availableUsers = data.map(user => ({ ...user, value: user.id, label: user.fullName }))

                            $scope.availableUsers = [...specialUserValues, ...availableUsers]
                            const selectedUser = $scope.settings.defaultValue && $scope.settings.defaultValue.id ? $scope.settings.defaultValue : undefined
                            if (selectedUser && !$scope.availableUsers.find(({ id }) => id === selectedUser.id)) {
                                $scope.settings.defaultValue = { ...$scope.settings.defaultValue, isDeleted: true }
                            }
                        })
                    })
                }
                $scope.onSelectUser = users => {
                    $scope.settings.defaultValue = users.length ? users[0] : undefined
                }

                $scope.availableUsers = []
                $scope.settings.defaultValue = null

                if (!$scope.settings.group && !$scope.settings.allUsers) {
                    $scope.settings.allUsers = true
                }
                const { userValue, specialValue } = field().defaultValue || {}
                if (field().defaultValue && userValue) {
                    $scope.settings.defaultValue = { ...userValue, value: userValue.id, label: userValue.fullName }
                } else if (field().defaultValue && specialValue) {
                    $scope.settings.defaultValue = specialUserValues.find(r => r.value === specialValue)
                }

                $scope.$watch(() => $scope.settings.group, () => {
                    getUsers()
                })
            }
            if ((dataTypeIs(DATE_ONLY_VALUE) || dataTypeIs(DATE_AND_TIME)) && field().defaultValue && field().defaultValue.specialValue) {
                $scope.settings.defaultValue = { id: field().defaultValue.specialValue }
            }
            if (dataTypeIs(EMAIL) && field().defaultValue && field().defaultValue.emailValue) {
                $scope.settings.defaultValue = field().defaultValue.emailValue
            }
            if (dataTypeIs(FORMULA)) {
                $scope.formulaAllowedSymbols = '+-/*0123456789'.split('')

                $scope.settings = {
                    expression: '',
                    format: FORMAT_OPTIONS.find(opt => opt.isDefault).label,
                    currency: {
                        default: Object.assign({}, companyCurrencies.defaultCurrency)
                    },
                    precision: DEFAULT_FIELD_SETTINGS.precision
                }

                if (field().options && field().options.format && field().options.format.options) {
                    const { precision, currency } = field().options.format.options
                    const availableCurrency = [companyCurrencies.defaultCurrency, ...companyCurrencies.availableCurrencies].find(cur => currency && cur.id === currency.default.id)
                    $scope.settings.expression = field().options.expression ? field().options.expression.replace('\n', '') : ''
                    $scope.settings.format = FORMAT_OPTIONS.find(opt => opt.id === field().options.format.dataType).label
                    if (field().options.format.dataType === MONEY && availableCurrency) {
                        $scope.settings.currency.default = availableCurrency
                    } else if (precision !== undefined) {
                        $scope.settings.precision = precision
                    }
                }

                let formatIsChangedByUser = false

                $scope.changeFormatByUser = () => {
                    formatIsChangedByUser = true
                }

                $scope.validateFormula = ({ expression, fieldsInUse, inputChanged }) => {
                    if (!formatIsChangedByUser && inputChanged) {
                        const SUGGESTED_FORMAT = fieldsInUse.some(f => f.format === MONEY) ? MONEY : NUMERIC_VALUE
                        $scope.settings.format = FORMAT_OPTIONS.find(opt => opt.id === SUGGESTED_FORMAT).label
                    }

                    $scope.settings.error = undefined
                    if (!expression) {
                        $scope.settings.error = $translate.instant('validation.formula.expression.isRequired')
                    } else {
                        validateFormula(expression + '\n')
                            .catch(() => {
                                $scope.settings.error = $translate.instant('validation.formula.expression.isInvalid')
                                $scope.$applyAsync()
                            })
                    }
                }
            }
            if (dataTypeIs(NUMERIC_VALUE) || dataTypeIs(MONEY) || dataTypeIs(FORMULA)) {
                $scope.settings.aggregation = {
                    options: AGGREGATION_OPTIONS
                }
                if (field().options && field().options.aggregation && field().options.aggregation.expression) {
                    $scope.settings.totalFieldId = field().options.totalFieldId
                    $scope.settings.aggregation.expression = field().options.aggregation.expression
                }
            }
        }

        initialize()
    }
}
