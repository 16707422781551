let invitedPopupDelay = 5 * 1000;

export class AdminController {
    constructor($scope, $modal, AdminSettings, $timeout, PageSettings, $stateParams, $state) {
        'ngInject';
        this.AdminSettings = AdminSettings;

        this.moreOptions = [
            {
                sref: 'main.admin.calendar',
                text: 'title.businessCalendar',
                icon: 'icon-company_calendar'
            },
            {
                sref: 'main.admin.billing',
                text: 'label.billing',
                icon: 'icon-profile_billing'
            },
            {
                sref: 'main.admin.integrations',
                text: 'label.integrations',
                icon: 'icon-profile_integration'
            }
        ];

        this.inviteUser = () => {
            if (this.isModalMode) return

            this.isModalMode = true
            $timeout(() => this.isModalMode = false, 1000)

            if ($state.includes('main.admin.users')) {
                $state.go('main.admin.users.invite')
            } else {
                let modal = PageSettings.inviteUser()
                modal.result
                    .then(data => {
                        if (data.success) {
                            $scope.$broadcast('invite', data.result.map(user => Object.assign({}, user, { isInvited: true })))
                        }
                    })
            }
        }

        this.closePopup = () => {
            if (this.invitedUsersInfo) {
                this.invitedUsersInfo = null;
            }
            if (this.popupId) {
                $timeout.cancel(this.popupId);
            }
        };
        this.addNewGroup = () => $scope.$broadcast('group-creation');

        $scope.$on('invite', (event, invitedUsers = []) => {
            this.invitedUsersInfo = invitedUsers.length === 1 ? invitedUsers[0] : { count: invitedUsers.length }
            this.popupId = $timeout(() => this.closePopup(), invitedPopupDelay)
        })
    }
}
