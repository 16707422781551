import { authRoute } from './auth.config.js'
import { AuthApiCalls } from './services/api-calls.auth.js'
import { LoginController } from './controllers/login.controller.js'
import { SignupController } from './controllers/signup.controller.js'
import { ResetController } from './controllers/reset.controller.js'

import './less/index.less'

let ngModule = angular.module('auth', [])
    .config(authRoute)
    .factory('AuthApiCalls', AuthApiCalls)
    .controller('LoginController', LoginController)
    .controller('SignupController', SignupController)
    .controller('ResetController', ResetController)

export default ngModule
