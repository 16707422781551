import { BUTTONS } from '../../../services/constants'

import './expression-input.less'
import { parseExpression } from '../index'

const MOUSE_LEFT_BUTTON = 1

const charNode = (char) => {
    let content = char !== ' ' ? char : `&nbsp;`
    return `<span class="char">${content}</span>`
}

const replacementNode = (obj) => {
    return `<span class="replacement" data-id="${obj.id}">${obj.name}</span>`
}

const adjustIndex = (nodes, current, next) => {
    if (next === nodes.length || next === 0 || nodes[next]) {
        return next
    }

    let reverse = next < current
    let nextNodeIndex

    while (nextNodeIndex === undefined) {
        next = reverse ? next - 1 : next + 1
        if (next < 0) {
            next = 0
        }
        if (next > nodes.length) {
            next = nodes.length
        }
        if (nodes[next] || next === 0 || next === nodes.length) {
            nextNodeIndex = next
        }
    }

    return nextNodeIndex
}

export function ExpressionInput ($document) {
    'ngInject'

    return {
        scope: true,
        bindToController: {
            model: '<',
            selection: '=',
            replacements: '<',
            isDisabled: '<',
            onTrigger: '&',
            onUpdate: '&',
            error: '<',
            fieldId: '<',
            placeholder: '<',
            allowedSymbols: '<',
            noFieldsTooltip: '<?'
        },
        template: `
            <div class="expression-input"
                 ng-class="{
                    focus: $ctrl.isActive,
                    error: $ctrl.error,
                    disabled: $ctrl.isDisabled,
                    'has-value': $ctrl.value
                }">
                <div class="text-view">
                    <span class="node"
                        ng-repeat="node in $ctrl.nodes track by $index"
                        ng-if="node !== null"
                        ng-class="{selected: $ctrl.isNodeSelected($index), 'has-cursor': $ctrl.isNodeHasCursor($index)}"
                        data-index="{{$index}}">
                        <span class="cursor" data-index="{{$index}}"></span>
                        <span ng-bind-html="node" data-index="{{$index}}"></span>
                    </span>
                    <span class="node last-child"
                        ng-class="{selected: $ctrl.isNodeSelected($ctrl.value.length), 'has-cursor': $ctrl.isNodeHasCursor($ctrl.value.length)}"
                        data-index="{{$ctrl.value.length}}"><span class="cursor"></span>&nbsp;</span>
                </div>
                <div class="placeholder">{{$ctrl.placeholder}}</div>
                <input type="text"
                       class="text-input"
                       value="{{$ctrl.value}}"
                       id="{{$ctrl.fieldId}}"
                       autocomplete="off"
                       ng-disabled="$ctrl.isDisabled"
                       ng-focus="$ctrl.isActive = true"
                       ng-blur="$ctrl.isActive = false"
                       placeholder="{{$ctrl.placeholder}}" />
                <button type="button" class="show-replacements-button tooltip-white"
                        ng-click="$ctrl.showReplacements($event)"
                        ng-if="!$ctrl.isDisabled && !$ctrl.noFieldsTooltip">
                    <img ng-src="/assets/icons/template_autogenerated_value_add.svg" alt="+" />
                </button>
                <button type="button" class="show-replacements-button tooltip-white"
                        ng-click="$ctrl.showReplacements($event)"
                        help-tooltip=""
                        dynamic-tooltip="$ctrl.noFieldsTooltip || ''"
                        ng-if="!$ctrl.isDisabled && $ctrl.noFieldsTooltip">
                    <img ng-src="/assets/icons/template_autogenerated_value_add.svg" alt="+" />
                </button>
            </div>
        `,
        controller: function () {
            this.parse = (string) => {
                let chunks = parseExpression(string, this.replacements)
                this.nodes = chunks.map(chunk => {
                    if (chunk === undefined) {
                        return undefined
                    }

                    if (chunk.type === 'field') {
                        return replacementNode(chunk.data)
                    } else {
                        return charNode(chunk.data)
                    }
                })
            }

            this.updateModel = (value) => {
                this.value = value
                this.parse(value)
                this.onUpdate({ value: value })
            }

            this.$onChanges = () => {
                this.replacements = this.replacements || []

                if (this.isDisabled) {
                    this.value = ''
                    this.nodes = []
                } else {
                    this.value = angular.copy(this.model) || ''
                    this.parse(this.value)
                }
            }

            this.showReplacements = (event) => {
                event.stopPropagation()
                this.onTrigger()
            }

            this.isNodeSelected = (index) => {
                let { start, end } = this.selection
                return start !== end && index >= start && index < end
            }

            this.isNodeHasCursor = (index) => {
                let { start, end } = this.selection
                return start === end && index === end
            }
        },
        controllerAs: '$ctrl',
        link: (scope, element, attrs, ctrl) => {
            const inputEl = element.find('input')[0]
            let visualSelectionMode = false
            let originalSelectionStart

            const swapIndex = (a, b) => {
                return a > b ? [b, a] : [a, b]
            }

            const setCursorAt = (start, end) => {
                end = end !== undefined ? end : start
                let position = swapIndex(start, end)

                ctrl.selection.start = adjustIndex(ctrl.nodes, ctrl.selection.start, position[0])
                ctrl.selection.end = adjustIndex(ctrl.nodes, ctrl.selection.end, position[1])

                inputEl.setSelectionRange(ctrl.selection.start, ctrl.selection.end)
            }

            const deleteLeft = (event) => {
                let start = inputEl.selectionStart
                const cursorIsAfterReplacementNode = start > 0 && !ctrl.nodes[start - 1]
                if (cursorIsAfterReplacementNode && inputEl.selectionStart === inputEl.selectionEnd) {
                    event.preventDefault()
                    let end = adjustIndex(ctrl.nodes, start, start - 1)
                    inputEl.value = inputEl.value.split('').filter((v, i) => i < end || i >= start).join('')
                    inputEl.setSelectionRange(end, end)
                }
            }

            const deleteRight = (event) => {
                let start = inputEl.selectionStart
                const cursorIsBeforeReplacementNode = start >= 0 && !ctrl.nodes[start + 1]
                if (cursorIsBeforeReplacementNode && inputEl.selectionStart === inputEl.selectionEnd) {
                    event.preventDefault()
                    let end = adjustIndex(ctrl.nodes, start, start + 1)
                    inputEl.value = inputEl.value.split('').filter((v, i) => i < start || i >= end).join('')
                    inputEl.setSelectionRange(start, start)
                }
            }

            const globalMouseUpHandler = (event) => {
                if (visualSelectionMode) {
                    event.stopPropagation()
                    visualSelectionMode = false
                    inputEl.focus()
                    $document.off('mouseup', globalMouseUpHandler)
                }
            }

            element
                .on('mousedown', '.text-view', (event) => {
                    if (event.which !== MOUSE_LEFT_BUTTON) {
                        return
                    }

                    event.preventDefault()
                    inputEl.focus()

                    visualSelectionMode = true
                    $document.on('mouseup', globalMouseUpHandler)

                    const $target = angular.element(event.target)
                    let index
                    if ($target.hasClass('text-view')) {
                        index = ctrl.value.length
                    } else {
                        let node = $target.hasClass('node') ? $target : $target.closest('.node')
                        index = Number(node[0].getAttribute('data-index'))
                    }

                    originalSelectionStart = index
                    scope.$apply(() => {
                        ctrl.isActive = true
                        setCursorAt(index)
                    })
                })
                .on('mousemove', '.node', function () {
                    if (visualSelectionMode) {
                        const index = Number(this.getAttribute('data-index'))
                        scope.$apply(() => setCursorAt(originalSelectionStart, index))
                    }
                })
                .on('change input keyup keydown', 'input', (event) => {
                    let value = event.target.value

                    if (event.type === 'keydown') {
                        if (event.key === BUTTONS.BACK_SPACE) {
                            deleteLeft(event)
                        }

                        if (event.key === BUTTONS.DELETE) {
                            deleteRight(event)
                        }

                        if (ctrl.allowedSymbols && ctrl.allowedSymbols.length) {
                            const allowedKeys = [' ', '#', '(', ')', '.', ',', BUTTONS.BACK_SPACE, BUTTONS.DELETE, BUTTONS.ARROW_UP, BUTTONS.ARROW_DOWN, BUTTONS.ARROW_RIGHT, BUTTONS.ARROW_LEFT].concat(ctrl.allowedSymbols)
                            if (!allowedKeys.includes(event.key)) {
                                event.preventDefault()
                            }
                        }
                    }

                    scope.$apply(() => {
                        if (value !== ctrl.value) {
                            ctrl.updateModel(value ? value.replace(/,/g, '.') : value)
                        }

                        if (event.type === 'input' && value[inputEl.selectionStart - 1] === '#') {
                            ctrl.showReplacements(event)
                        }

                        setCursorAt(inputEl.selectionStart, inputEl.selectionEnd)
                    })
                })

            scope.$watch(() => ctrl.value, (newValue) => {
                let { selectionStart, selectionEnd } = inputEl
                inputEl.value = newValue
                if (ctrl.isActive) {
                    setCursorAt(selectionStart, selectionEnd)
                } else {
                    setCursorAt(inputEl.selectionStart, inputEl.selectionEnd)
                }
            })
        }
    }
}
