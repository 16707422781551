import React from 'react';
import { components } from 'react-select';
import UserAvatar from './user-avatar.jsx';

const UserOption = ({ children, data, ...props }) => {
    return (
        <components.Option {...props}>
            <span className="option-label">
                <span className="option-icn">
                    <UserAvatar user={data} />
                </span>
                {children}
            </span>
        </components.Option>
    );
};

export default UserOption;
