import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Cell, Column, Table } from 'fixed-data-table-2'
import { DEFAULT_COLUMN_WIDTH, DEFAULT_HEADER_HEIGHT, DEFAULT_ROW_HEIGHT } from './index'
import { getService, translate } from '../../../modules/react/utils'

import './data-grid.less'

const additionalColumnWidth = 130

function DataGrid (props) {
    const {
        width,
        height,
        rowsCount,
        columns,
        selectedRowIndex,
        onSelectRow,
        onCreateColumn,
        columnHeaderRenderer,
        dataCellRenderer,
        onColumnReorderEndCallback,
        onColumnResizeEndCallback,
        reorderingIsActive,
        columnsState
    } = props
    const columnKeys = Object.keys(columns)
    const DevicesDetector = getService('DevicesDetector')
    const [activeRowIndex, setActiveRowIndex] = useState(null)

    const isHighlighted = rowIndex => rowIndex === selectedRowIndex || rowIndex === activeRowIndex
    const getColumnState = columnKey => columnsState[columnKey] || { width: DEFAULT_COLUMN_WIDTH }

    return (
        <Table
            className={clsx('data-grid')}
            rowHeight={DEFAULT_ROW_HEIGHT}
            headerHeight={DEFAULT_HEADER_HEIGHT}
            rowsCount={rowsCount}
            width={width}
            height={height}
            onColumnResizeEndCallback={onColumnResizeEndCallback}
            onColumnReorderEndCallback={onColumnReorderEndCallback}
            isColumnResizing={false}
            isColumnReordering={false}
            allowCellsRecycling={false}
            touchScrollEnabled={DevicesDetector.isMobile()}
            onRowClick={(event, rowIndex) => onSelectRow(rowIndex)}
            onRowMouseEnter={(event, rowIndex) => setActiveRowIndex(rowIndex)}
            onRowMouseLeave={() => setActiveRowIndex(null)}
            rowClassNameGetter={props.rowClassNameGetter}
        >
            {columnKeys.filter(key => !columns[key].isHidden).map(columnKey => (
                <Column
                    key={columnKey}
                    columnKey={columnKey}
                    isResizable={true}
                    isReorderable={reorderingIsActive && !columns[columnKey].isTitle && !getColumnState(columnKey).isFixed}
                    fixed={getColumnState(columnKey).isFixed}
                    width={getColumnState(columnKey).width}
                    flexGrow={0}
                    header={({ width, height }) => (
                        <Cell>
                            <div className="column-header-cell" style={{ width, height }}>
                                {columnHeaderRenderer(columns[columnKey], columnKey)}
                            </div>
                        </Cell>
                    )}
                    allowCellsRecycling={true}
                    cell={({
                        rowIndex,
                        width,
                        height
                    }) => dataCellRenderer(rowIndex, columnKey, width, height, isHighlighted(rowIndex))}
                />
            ))}
            {onCreateColumn && (
                <Column
                    width={additionalColumnWidth}
                    flexGrow={0}
                    header={({ height }) => (
                        <Cell>
                            <div className="column-header-cell"
                                 style={{ justifyContent: 'center', width: additionalColumnWidth - 2, height }}>
                                <button type="button" className="btn btn-icon muted" onClick={onCreateColumn}
                                        style={{ fontSize: 12 }}>
                                    <i className="icon-template_create" style={{ fontSize: 10 }}/>
                                    <span>{translate('label.addColumn')}</span>
                                </button>
                            </div>
                        </Cell>
                    )}
                    cell={({ rowIndex, width, height }) => (
                        <Cell
                            width={width}
                            height={height}
                            className={clsx('column-value-cell', { active: isHighlighted(rowIndex) })}
                        />
                    )}
                />
            )}
        </Table>
    )
}

DataGrid.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    rowsCount: PropTypes.number,
    columns: PropTypes.array,
    selectedRowIndex: PropTypes.number,
    onSelectRow: PropTypes.func,
    onCreateColumn: PropTypes.func,
    columnHeaderRenderer: PropTypes.func,
    dataCellRenderer: PropTypes.func,
    rowClassNameGetter: PropTypes.func,
    onColumnReorderEndCallback: PropTypes.func,
    onColumnResizeEndCallback: PropTypes.func,
    reorderingIsActive: PropTypes.bool,
    columnsState: PropTypes.object
}

export default DataGrid
