import 'jquery'
import 'angular'
import 'angular-animate'
import 'angular-aria'
import 'angular-cookies'
import 'angular-elastic'
import 'angular-perfect-scrollbar-2'
import 'angular-resizable'
import 'angular-sanitize'
import 'angular-svg-round-progressbar'
import 'angular-touch'
import 'messageformat'
import 'angular-translate'
import 'angular-translate-interpolation-messageformat'
import 'angular-ui-bootstrap'
import 'angular-ui-router'
import 'ng-file-upload'
import 'ng-focus-if'
import 'ng-sortable'
import 'pusher-angular'
import 'satellizer'
import './libs/angular-datepicker'
import './libs/angular-ui-mention'
import ngInfiniteScroll from 'ng-infinite-scroll'
import 'react'
import 'react-dom'
import 'ngreact'
import { loadUserflow } from 'userflow.js'

import './libs/vendor'
import utils from './utils'

// Import base styles before modules
import './less/index.less'

// Import modules
import './modules/home/home.module'
import './modules/auth/auth.module'
import './modules/admin/admin.module'
import './modules/activation/activation.module'
import './modules/profile/profile.module'
import './modules/templates/templates.module'
import './modules/processes/processes.module'
import './modules/error/error.module'
import './modules/helpers/helpers.module'
import './modules/onboarding/onboarding.module'

// Module settings
import configObj from './config'
import { config } from './index.config'
import { routerConfig } from './index.route'
import { runBlock } from './index.run'

// Custom Services
import { ApiCalls } from './services/api-calls'
import { Constants } from './services/constants'
import { ResponseObserver } from './services/response-observer'
import { LocationSettings } from './services/location-settings'
import { PageSettings } from './services/page-settings'
import { MomentHelper } from './services/moment-helper'
import { PusherHelper } from './services/pusher-helper'
import { DateHelper } from './services/date-helper'
import { TaskService } from './services/task-service'
import { DeferredAction } from './services/deferred-action'
import { TemplateCacheHelper } from './services/template-cache-helper'
import { ServerDateConverter } from './services/server-date-converter'
import { FormErrors } from './services/form-errors'
import { PrintErrors } from './services/print-errors'
import { DevicesDetector } from './services/devices-detector'
import { CurrentUserService, formatForUserflowIdentify } from './services/current-user-service'
import { CompanyCurrenciesService } from './services/company-currencies-service'

// Custom Filters
import { short } from './filters/short'
import { cardType } from './filters/cardType'
import { dueDate } from './filters/dueDate'
import { typeName } from './filters/typeName'
import { capitalize } from './filters/capitalize'
import { filesize } from './filters/filesize'
import { momentDate } from './filters/moment'
import { initials } from './filters/initials'
import { search } from './filters/search'
import { userSearch } from './filters/user-search'
import { highlight } from './filters/highlight'
import { parseComment } from './filters/parseComment'
import { escapeHtml } from './filters/escapeHtml'
import { parseLinks } from './filters/parseLinks'
import { parseSymbols } from './filters/parseSymbols'
import { paymentStatus } from './filters/paymentStatus'
import { numericFormat } from './filters/numericFormat'
import { tasksSorter } from './filters/tasksSorter'
import { without } from './filters/without'
import { withoutDeferred } from './filters/withoutDeferred'
import { truncate } from './filters/truncate'
import { allowedValues } from './filters/allowedValues'
import { invalidDataModel } from './filters/invalidDataModel'
import { groupByFilter } from './filters/groupBy'

// Custom Directives
import { autoFocus } from './directives/auto-focus'
import { menuButtonMore } from './directives/menu-button-more'
import { multilineEllipsis } from './directives/multiline-ellipsis'
import { perLineText } from './directives/per-line-text'
import { contentEditable } from './directives/content-editable'
import { compareTo } from './directives/compare-to'
import { hasError } from './directives/has-error'
import { actorsList } from './directives/actors-list'
import { taskDataModel } from './directives/task-data-model'
import { templateDataModel } from './directives/template-data-model'
import { templateViewDataModel } from './directives/template-view-data-model'
import { templateFieldsVisibility } from './directives/template-fields-visibility'
import { templateSectionTypes } from './directives/template-section-types'
import { processViewDataModel } from './directives/process-view-data-model'
import { fileDataField } from './directives/file-data-field'
import { yesnoDataField } from './directives/yesno-data-field'
import { priceDataField } from './directives/price-data-field'
import { MultiselectField } from './directives/multiselect-field'
import { multilineContent } from './directives/multiline-content'
import { dropdown } from './directives/dropdown'
import { dropdownTether } from './directives/dropdown-tether'
import { tetherDrop } from './directives/tether-drop'
import { mtDatePicker } from './directives/mt-date-picker'
import { dateAltPicker } from './directives/date-alt-picker'
import { dateTimePicker } from './directives/date-time-picker'
import { integerInput } from './directives/integer-input'
import { numericValue } from './directives/numeric-value'
import { notificationBar } from './directives/notification-bar'
import { toasterPopup } from './directives/toaster-popup'
import { attachment } from './directives/attachment'
import { tmplTaskDueDate } from './directives/tmpl-task-due-date'
import { tmplDatePicker } from './directives/tmpl-date-picker'
import { prcDatePicker } from './directives/prc-date-picker'
import { taskDatePicker } from './directives/task-date-picker'
import { userTooltip } from './directives/user-tooltip'
import { helpTooltip } from './directives/help-tooltip'
import { comments } from './directives/comments'
import { commentMention } from './directives/comment-mention'
import { mentionChecker } from './directives/mention-checker'
import { capitalizedValue } from './directives/capitalized-value'
import { selectize } from './directives/selectize'
import { trimmed } from './directives/trimmed'
import { timeInput } from './directives/time-input'
import { timerFromNow } from './directives/timer-from-now'
import { scrollShadow } from './directives/scroll-shadow'
import { markdownHint } from './directives/markdown-hint'
import { markdownContent } from './directives/markdown-content'
import { headerUserBlock } from './directives/header-user-block'
import { dataModelTableRow } from './directives/data-model-table-row'
import { widthLikePrev } from './directives/width-like-prev'
import { filesContent } from './directives/files-content'
import { selectDefaultCurrency } from './directives/select-default-currency'
import { multiSelectControl } from './directives/multi-select-control'
import { touchStart } from './directives/touch-start'
import { touchEnd } from './directives/touch-end'
import { baseDropdown } from './directives/base-dropdown'

import { SingleSelect } from './components/single-select/single-select.component'
import { UserField } from './components/user-field/user-field.component'
import { UserFieldSettings } from './components/user-field/user-field-settings.component'
import { DropdownField } from './directives/dropdown-field'

// Top-level controllers
import { MainController } from './controllers/main.controller'
import { InviteModalController } from './controllers/inviteModal.controller'
import { DataModelFieldSettingsController } from './controllers/dataModelFieldSettings.controller'
import { DataModelTableRowController } from './controllers/dataModelTableRow.controller'
import { DeleteWarningsController } from './controllers/deleteWarnings.controller'
import { PasswordModalController } from './controllers/passwordModal.controller'
import { TmplGalleryController } from './controllers/tmplGallery.controller'
import { ModalInstanceController } from './controllers/modalInstance.controller'
import { ModalAssignController } from './controllers/modalAssign.controller'
import { ModalRejectReasonController } from './controllers/modalRejectReason.controller'
import { CropImageController } from './controllers/cropImage.controller'

// Reports (React-based)
import { reportsListView } from './modules/reports/ng-directives/reports-list-view'
import { reportView } from './modules/reports/ng-directives/report-view'
import { reactCheckbox } from './components/ng-react/checkbox'
import { reactSelectDropdown } from './components/ng-react/select-dropdown'
import { reactFilePreview } from './components/ng-react/file-preview'
import { reactTableSourceField } from './components/ng-react/table-source-field'
import { reactUserSelect } from './components/ng-react/user-select'

// DataStore (React-based)
import { dataBaseView } from './modules/database/ng-directives/database-view'
import { ConfirmWithReasonController } from './controllers/confirmWithReason.controller'
import { reactNumberInput } from './components/ng-react/react-number-input'
import { unique } from './filters/unique'
import { templateScheduleDialog } from './components/ng-react/template-schedule-dialog'
import { excludeServiceFormFields } from './filters/excludeServiceFormFields'

import { ExpressionInput } from './components/expression/input/expression-input.directive'
import { ExpressionOptions } from './components/expression/dropdown/expression-options.directive'
import { ExpressionView } from './components/expression/view/expression-view.directive'
import { Expression } from './components/expression/expression.component'
import { processPrintView } from './components/ng-react/process-print-view'

angular.module(configObj.moduleName, [
    'ngAnimate',
    'ngCookies',
    'ngTouch',
    'ngSanitize',
    'ngAria',
    'ngFileUpload',
    'ui.bootstrap',
    'ui.mention',
    'ui.router',
    'pascalprecht.translate',
    'satellizer',
    'monospaced.elastic',
    'as.sortable',
    'datePicker',
    'pusher-angular',
    'angular-svg-round-progressbar',
    'angularResizable',
    'react',
    ngInfiniteScroll,
    /* Custom modules */
    'home',
    'auth',
    'admin',
    'activation',
    'profile',
    'templates',
    'processes',
    'error',
    'helpers',
    'onboarding',
    'angular-perfect-scrollbar-2',
    'focus-if'
])
    .config(config)
    .config(routerConfig)
    .run(runBlock)

    .filter('short', short)
    .filter('cardType', cardType)
    .filter('dueDate', dueDate)
    .filter('typeName', typeName)
    .filter('capitalize', capitalize)
    .filter('initials', initials)
    .filter('filesize', filesize)
    .filter('momentDate', momentDate)
    .filter('search', search)
    .filter('userSearch', userSearch)
    .filter('highlight', highlight)
    .filter('parseComment', parseComment)
    .filter('escapeHtml', escapeHtml)
    .filter('parseLinks', parseLinks)
    .filter('parseSymbols', parseSymbols)
    .filter('paymentStatus', paymentStatus)
    .filter('numericFormat', numericFormat)
    .filter('tasksSorter', tasksSorter)
    .filter('without', without)
    .filter('unique', unique)
    .filter('withoutDeferred', withoutDeferred)
    .filter('truncate', truncate)
    .filter('allowedValues', allowedValues)
    .filter('invalidDataModel', invalidDataModel)
    .filter('excludeServiceFormFields', excludeServiceFormFields)
    .filter('groupBy', groupByFilter)

    .directive('autoFocus', autoFocus)
    .directive('contentEditable', contentEditable)
    .directive('compareTo', compareTo)
    .directive('hasError', hasError)
    .directive('taskDataModel', taskDataModel)
    .directive('templateDataModel', templateDataModel)
    .directive('templateViewDataModel', templateViewDataModel)
    .directive('templateFieldsVisibility', templateFieldsVisibility)
    .directive('templateSectionTypes', templateSectionTypes)
    .directive('processViewDataModel', processViewDataModel)
    .directive('fileDataField', fileDataField)
    .directive('yesnoDataField', yesnoDataField)
    .directive('priceDataField', priceDataField)
    .directive('menuButtonMore', menuButtonMore)
    .directive('multilineContent', multilineContent)
    .directive('multilineEllipsis', multilineEllipsis)
    .directive('actorsList', actorsList)
    .directive('dropdown', dropdown)
    .directive('dropdownTether', dropdownTether)
    .directive('tetherDrop', tetherDrop)
    .directive('mtDatePicker', mtDatePicker)
    .directive('dateAltPicker', dateAltPicker)
    .directive('dateTimePicker', dateTimePicker)
    .directive('integerInput', integerInput)
    .directive('numericValue', numericValue)
    .directive('attachment', attachment)
    .directive('tmplTaskDueDate', tmplTaskDueDate)
    .directive('tmplDatePicker', tmplDatePicker)
    .directive('prcDatePicker', prcDatePicker)
    .directive('taskDatePicker', taskDatePicker)
    .directive('comments', comments)
    .directive('commentMention', commentMention)
    .directive('mentionChecker', mentionChecker)
    .directive('capitalized-value', capitalizedValue)
    .directive('timeInput', timeInput)
    .directive('selectize', selectize)
    .directive('trimmed', trimmed)
    .directive('timerFromNow', timerFromNow)
    .directive('userTooltip', userTooltip)
    .directive('helpTooltip', helpTooltip)
    .directive('perLineText', perLineText)
    .directive('scrollShadow', scrollShadow)
    .directive('markdownHint', markdownHint)
    .directive('markdownContent', markdownContent)
    .directive('notificationBar', notificationBar)
    .directive('toasterPopup', toasterPopup)
    .directive('headerUserBlock', headerUserBlock)
    .directive('dataModelTableRow', dataModelTableRow)
    .directive('widthLikePrev', widthLikePrev)
    .directive('filesContent', filesContent)
    .directive('selectDefaultCurrency', selectDefaultCurrency)
    .directive('multiSelectControl', multiSelectControl)
    .directive('touchStart', touchStart)
    .directive('touchEnd', touchEnd)
    .directive('reactCheckbox', reactCheckbox)
    .directive('reactSelectDropdown', reactSelectDropdown)
    .directive('reactFilePreview', reactFilePreview)
    .directive('reactTableSourceField', reactTableSourceField)
    .directive('reactUserSelect', reactUserSelect)
    .directive('reactNumberInput', reactNumberInput)
    .directive('templateScheduleDialog', templateScheduleDialog)
    .directive('processPrintView', processPrintView)

    .directive('databaseView', dataBaseView)

    .directive('reportsListView', reportsListView)
    .directive('reportView', reportView)
    .directive('baseDropdown', baseDropdown)
    .directive('singleSelect', SingleSelect)
    .directive('expressionInput', ExpressionInput)
    .directive('expressionOptions', ExpressionOptions)
    .directive('expressionView', ExpressionView)

    .component('dropdownField', DropdownField)
    .component('multiselectField', MultiselectField)
    .component('userField', UserField)
    .component('userFieldSettings', UserFieldSettings)
    .component('expression', Expression)

    .controller('MainController', MainController)
    .controller('InviteModalController', InviteModalController)
    .controller('DeleteWarningsController', DeleteWarningsController)
    .controller('DataModelFieldSettingsController', DataModelFieldSettingsController)
    .controller('DataModelTableRowController', DataModelTableRowController)
    .controller('PasswordModalController', PasswordModalController)
    .controller('TmplGalleryController', TmplGalleryController)
    .controller('ModalInstanceController', ModalInstanceController)
    .controller('ModalAssignController', ModalAssignController)
    .controller('ModalRejectReasonController', ModalRejectReasonController)
    .controller('ConfirmWithReasonController', ConfirmWithReasonController)
    .controller('CropImageController', CropImageController)

    .factory('ApiCalls', ApiCalls)
    .factory('Constants', Constants)
    .factory('FormErrors', FormErrors)
    .factory('PrintErrors', PrintErrors)
    .factory('ResponseObserver', ResponseObserver)
    .factory('LocationSettings', LocationSettings)
    .factory('PageSettings', PageSettings)
    .factory('DateHelper', DateHelper)
    .factory('PusherHelper', PusherHelper)
    .factory('MomentHelper', MomentHelper)
    .factory('DevicesDetector', DevicesDetector)
    .factory('TemplateCacheHelper', TemplateCacheHelper)
    .factory('TaskService', TaskService)
    .factory('DeferredAction', DeferredAction)
    .factory('CurrentUserService', CurrentUserService)
    .factory('CompanyCurrenciesService', CompanyCurrenciesService)
    .factory('ServerDateConverter', ServerDateConverter);

// App configuration settings preloading
(() => {
    utils.localStorage.remove('templateViewBackUrl')
    utils.localStorage.remove('processViewBackUrl')
    const authToken = utils.getParamByName('authToken') || utils.localStorage.get('satellizer_token')
    const authTokenHeaders = { headers: { 'X-Auth-Token': authToken } }

    const initInjector = angular.injector(['ng'])
    const $http = initInjector.get('$http')
    const $q = initInjector.get('$q')
    const $window = initInjector.get('$window')
    let configData, userData

    angular.module(configObj.moduleName).value('currentUser', {})

    // Promise for getting server config object
    let configPromise = new Promise((resolve, reject) => {
        $http.get('/api/config').then(response => {
            configData = response.data
            if ($window.analytics) {
                $window.analytics.load(configData.segmentWriteKey)
                $window.analytics.page()
            }
            angular.module(configObj.moduleName).constant('ServerConfig', configData)
            resolve()
        }, errorResponse => {
            if (errorResponse.data.canBeActivated) {
                userData = undefined
                $http.get('/api/disabled/config').then(response => {
                    configData = response.data
                    configData.canBeActivated = true
                    configData.errorStatus = errorResponse.status
                    angular.module(configObj.moduleName).constant('ServerConfig', configData)
                    if ($window.analytics) {
                        $window.analytics.load(configData.segmentWriteKey)
                        $window.analytics.page()
                    }

                    $http.get('/api/disabled/current', authTokenHeaders).then(resp => {
                        if (resp.data && resp.data.success) {
                            const { result } = resp.data
                            result.fullName = `${result.firstName || 'Loading...'} ${result.lastName || ''}`
                            userData = result
                            angular.module(configObj.moduleName).value('currentUser', userData) //eslint-disable-line
                        }
                        resolve()
                    })
                })
            } else {
                angular.module(configObj.moduleName).constant('ServerConfig', {
                    auth: { google: {} },
                    errorStatus: errorResponse.status
                })
                reject()
            }
        })
    })

    // Promise for getting current user info object
    let currentUserPromise = $http.get('/api/current', authTokenHeaders).then(response => {
        if (response.data && response.data.success && !userData) {
            const { result } = response.data
            result.fullName = `${result.firstName || 'Loading...'} ${result.lastName || ''}`
            userData = result
            angular.module(configObj.moduleName).value('currentUser', userData) //eslint-disable-line

            if (result.id) {
                $http.get('/api/templates/check_list', authTokenHeaders).then(response => {
                    $window.templatesNotAvailable = response.data.result.exists === false
                }).catch(() => {
                    $window.templatesNotAvailable = false
                })

                $http.get('/api/datatables/check_list', authTokenHeaders).then(response => {
                    $window.datatablesNotAvailable = response.data.result.exists === false
                }).catch(() => {
                    $window.datatablesNotAvailable = false
                })
            }

            loadUserflow().then(userflow => {
                $window.userflow = userflow
                // eslint-disable-next-line no-undef
                if (USERFLOW_TOKEN) {
                    // eslint-disable-next-line no-undef
                    $window.userflow.init(USERFLOW_TOKEN)
                    $window.userflow.identify(userData.id, formatForUserflowIdentify(userData))
                }
            })
        }
    })

    // Promise for getting current user info object
    let allCurrenciesPromise = $http.get('/api/currency/all', authTokenHeaders).then(response => {
        if (response.data) {
            angular.module(configObj.moduleName).constant('allCurrencies', response.data) //eslint-disable-line
        } else {
            angular.module(configObj.moduleName).constant('allCurrencies', [])
        }
    }, errorResponse => {
        if (errorResponse.status === 470 || errorResponse.status === 474) {
            angular.module(configObj.moduleName).constant('allCurrencies', [])
        }
    })

    // After resolving current user and server config promises run the app
    $q.all([configPromise, currentUserPromise, allCurrenciesPromise]).finally(() => {
        angular.element(document).ready(() => {
            angular.module(configObj.moduleName).value('companyCurrencies', {})
            angular.bootstrap(document, [configObj.moduleName], { strictDi: true })
            if (configData && userData && userData.id && $window.analytics) {
                $window.analytics.identify(userData.id, {
                    name: userData.fullName,
                    email: userData.email,
                    companyName: userData.companyName,
                    userRole: userData.isAdmin ? 'Admin' : 'User',
                    domainUrl: $window.location.protocol + '//' + $window.location.host,
                    company: {
                        id: userData.companyId
                    }
                }, {
                    Intercom: {
                        user_hash: userData.userHash
                    }
                })
            }
        })
    })
})()
