export function addHolidayForm($document, AdminApiCalls, $translate) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '=',
            cancel: '&',
            minValue: '=',
            maxValue: '=',
            onError: '=',
            save: '='
        },
        template: `
            <div class="add-holiday-form" ng-class="{noDate: clicked && !date, serverError: serverError}">
                <prc-date-picker no-btns="true" due-date="date" ad-hoc-task="true" no-left="true" is-holidays="true"
                    min-value="minValue" max-value="maxValue" autofocus="true" placeholder="label.addHolidayDate"></prc-date-picker>
                <input type="text" tabindex="2" maxlength="100" class="g-ctrl" ng-class="{error: !description || serverError}" ng-model="description"/>
                <button type="button" class="btn-switcher btn-primary-switcher with-focus"
                        ng-click="saveHoliday()" tabindex="3">
                    <i class="icon-menu_checkmark"></i>
                </button>
                <button type="button" class="btn-switcher btn-primary-switcher red with-focus"
                        ng-click="close()" tabindex="4">
                    <i class="icon-common_close"></i>
                </button>
                <span class="error" ng-if="clicked && (!description || !date)">
                    {{'text.requiredField' | translate | capitalize}}
                </span>
                <span class="error" ng-if="serverError && !(clicked && (!description || !date))">
                    {{serverError}}
                </span>
            </div>
        `,

        link: (scope) => {
            scope.activeDate = true;
            scope.description = 'Holiday';
            scope.clicked = false;

            let addClick = false;
            scope.saveHoliday = () => {
                scope.clicked = true;
                if (addClick || !scope.date || !scope.description) {
                    return;
                }
                addClick = true;
                let holiday = {date: scope.date, description: scope.description};
                AdminApiCalls.addHoliday(holiday)
                    .then(data => scope.save(data))
                    .catch(errRes => {
                        if(errRes.status === 400 || Math.floor(errRes.status / 100) === 5) {
                            scope.serverError = errRes.data.displayError  || (errRes.data.description && errRes.data.description[0].fieldError) || $translate.instant('error.common');
                            scope.clicked = false;
                        }
                        return scope.onError(errRes);
                    })
                    .finally(() => addClick = false);
            };
            scope.close = () => scope.cancel();

            scope.$watch('date', () => {
                if(scope.serverError) {
                    scope.serverError = undefined;
                }
            });

            const handleClick = (event) => {
                if (event.which === 13) {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    if (event.target && event.target.nodeName === 'BUTTON') {
                        return;
                    }
                    scope.saveHoliday();
                }
            };
            const keyDownListener = event => {
                if (event.which === 27) {
                    scope.$applyAsync(() => scope.close());
                }
            };

            $document.on('keyup', handleClick);
            $document.on('keydown', keyDownListener);

            scope.$on('$destroy', () => {
                $document.off('keyup', handleClick);
                $document.off('keydown', keyDownListener);
            });
        }
    };
}
