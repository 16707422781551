import { types } from 'mobx-state-tree'

const { string, number, array, boolean } = types

export const UserId = types.model({
    id: string
})

export const UserGroupId = types.model({
    id: string
})

export const UserGroupBase = types.model(
    'UserGroupBase',
    {
        id: types.string,
        name: types.string
    }
)

export const User = types.model({
    id: string,
    fullName: string,
    colorIndex: number,
    email: types.maybe(string),
    avatarUrl: types.maybe(string),
    isDeleted: types.maybe(boolean)
}).views(self => ({
    get asOption () {
        return {
            id: self.id,
            fullName: self.fullName,
            email: self.email,
            colorIndex: self.colorIndex,
            avatarUrl: self.avatarUrl,
            isDeleted: self.isDeleted
        }
    },
    get asSelectOption () {
        return {
            ...self.asOption,
            value: self.id,
            label: self.fullName,
        }
    },
    get asValue () {
        return { id: self.id }
    }
}))

export const UserGroup = types.model({
    id: string,
    name: string,
    users: array(User),
}).views(self => ({
    get asOption () {
        return {
            id: self.id,
            name: self.name,
            users: self.users.map(u => u.asOption)
        }
    },
    get asValue () {
        return { id: self.id }
    }
}))
