import { FORMULA } from '../services/data-types'
import { prepareDataFormFieldsForFormula } from '../modules/templates/services/data-model-utils'
import { flattenReplacement, formatExpression } from '../components/expression'

export function templateViewDataModel (companyCurrencies) {
    'ngInject'

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dataModel: '=ngModel'
        },
        template: require('../templates/template-view-data-model.html'),
        link: (scope) => {
            scope.getIcon = item => {
                return item.section && item.section.isTable
                    ? 'icon-template_create_section_table'
                    : 'icon-template_create_section_form'
            }
            scope.getDefaultCurrency = field => {
                if (field.name.options && field.name.options.currency) {
                    return field.name.options.currency.default.code
                }
                return companyCurrencies.defaultCurrency ? companyCurrencies.defaultCurrency.code : null
            }
            scope.getFieldDataSourceLabel = field => {
                if (field.name.options && field.name.options.useTableAsSource && !field.name.options.source.isInvalid) {
                    return field.name.options.source.tableName
                }
                if (field.name.options && !field.name.options.useTableAsSource) {
                    return field.name.options.source.allowedValues.length
                }
                return ''
            }

            scope.dataModel.list.forEach(({ section }) => {
                section.fieldsWithValues.forEach(field1 => {
                    if (field1.name.dataType === FORMULA && field1.name.options?.expression) {
                        const fieldGroupsForFormula = prepareDataFormFieldsForFormula(scope.dataModel.list, section, field1)
                        field1.name.$formulaHint = formatExpression(field1.name.options.expression, flattenReplacement(fieldGroupsForFormula), true)
                    }
                })
            })
        }
    }
}
