/* eslint-disable */
import XRegExp from 'xregexp';
import colors from './colors';
let _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

let Module = angular.module('ui.mention', []);

Module.directive('uiMention', function () {
    return {
        require: ['ngModel', 'uiMention'],
        controller: 'uiMention',
        controllerAs: '$mention',
        link: function link($scope, $element, $attrs, _ref) {
            var _ref2 = _slicedToArray(_ref, 2);

            var ngModel = _ref2[0];
            var uiMention = _ref2[1];

            uiMention.init(ngModel);
        }
    };
});

let escapedRegExp = (string, flags = 'g') => new RegExp(string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), flags);

Module.controller('uiMention', ["$element", "$scope", "$attrs", "$q", "$timeout", "$document",
    function ($element, $scope, $attrs, $q, $timeout, $document) {

    var _this2 = this;

    // Beginning of input or preceeded by spaces: @sometext
    this.pattern = this.pattern || XRegExp("(?:\\s+|^)@(([\\pL+\\pN+'-]*( )){0,2}[\\pL+\\pN+'-]*(?!@))$");
    // /(?:\s+|^)@([\w]*[\w ]*(?!@))$/
    this.$element = $element;
    this.choices = [];
    this.mentions = [];
    var ngModel;
        
    this.getColor = index => {
        return colors[index - 1];
    };

    /**
     * $mention.init()
     *
     */
    this.init = function (model) {
        var _this = this;

        // Leading whitespace shows up in the textarea but not the preview
        $attrs.ngTrim = 'false';
        if ($scope.comment && $scope.allUsers) {
            if (!_this.mentions || !_this.mentions.length) {
                _this.mentions = $scope.allUsers;
            }
        }

        ngModel = model;

        ngModel.$parsers.push(function (value) {
            // Removes any mentions that aren't used
            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            let res = [];
            _this.mentions.forEach(m => {
                if (m) {
                    if (~value.indexOf(_this.label(m))) {
                        const count = value.match(escapedRegExp(_this.label(m), 'g')).length;
                        let i = 0;
                        while (i < count) {
                            i++;
                            res.push(m);
                        }
                        return value = value.replace(escapedRegExp(_this.label(m), 'g'), _this.encode(m));
                    }
                }
            });
            _this.mentions = res.slice();
            _this.render(value);

            return value;
        });

        ngModel.$formatters.push(function () {
            var value = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

            // In case the value is a different primitive
            value = value.toString();

            let res = [];
            _this.mentions.forEach(m => {
                if (m) {
                    if (~value.indexOf(_this.encode(m))) {
                        const count = value.match(escapedRegExp(_this.encode(m), 'g')).length;
                        let i = 0;
                        while (i < count) {
                            i++;
                            res.push(m);
                        }
                        return value = value.replace(escapedRegExp(_this.encode(m), 'g'), _this.label(m));
                    }
                }
            });
            _this.mentions = res.slice();
            return value;
        });

        ngModel.$render = function () {
            $element.val(ngModel.$viewValue || '');
            $timeout(_this.autogrow, true);
            _this.render();
        };
    };

    /**
     * $mention.render()
     *
     */
    this.render = function () {
        var html = arguments.length <= 0 || arguments[0] === undefined ? ngModel.$modelValue : arguments[0];

        html = (html || '').toString();
        _this2.mentions.forEach(function (mention) {
            if (mention) {
                html = html.replace(_this2.encode(mention), _this2.highlight(mention));
            }
        });
        $element.next().html(html);
        return html;
    };

    /**
     * $mention.highlight()
     *
     */
    this.highlight = function (choice) {
        return '<span>' + this.label(choice) + '</span>';
    };

    /**
     * $mention.label()
     *
     * Converts a choice object to a human-readable string
     *
     */
    this.label = function (choice) {
        return choice.fullName;
    };

    /**
     * $mention.encode()
     *
     */
    this.encode = function (choice) {
        return '<@' + choice.id + '>';
    };

    /**
     * $mention.replace()
     *
     */
    this.replace = function (mention) {
        var search = arguments.length <= 1 || arguments[1] === undefined ? this.searching : arguments[1];
        var text = arguments.length <= 2 || arguments[2] === undefined ? ngModel.$viewValue : arguments[2];

        // TODO: come up with a better way to detect what to remove
        // TODO: consider alternative to using regex match
        if (this.choices && this.choices.length) {
            text = text.substr(0, search.index + search[0].indexOf('@')) + this.label(mention) + ' ' + text.substr(search.index + search[0].length);
        }
        return text;
    };

    /**
     * $mention.select()
     *
     */
    this.select = function (event, user) {
        if (user) {
            this.activeChoice = user;
        }
        var choice = this.activeChoice;

        if (choice) {
            if (event && event.preventDefault) {
                event.preventDefault();
                event.stopPropagation();
            }

            // Add the mention
            this.mentions.push(choice);

            // Replace the search with the label
            ngModel.$setViewValue(this.replace(choice));

            // Close choices panel
            this.cancel();

            // Update the textarea
            ngModel.$render();
        }
    };

    /**
     * $mention.up()
     *
     */
    this.up = function () {
        let el = document.getElementById('mentions');
        var index = this.choices.indexOf(this.activeChoice);
        if (index > 0) {
            this.activeChoice = this.choices[index - 1];
            if (el) {
                let child = el.getElementsByClassName('active');
                if (child && child[0].offsetTop < el.scrollTop + 32) {
                    el.scrollTop -= 32;
                }
            }
        } else {
            this.activeChoice = this.choices[this.choices.length - 1];
            if (el) {
                el.scrollTop = el.scrollHeight;
            }
        }
    };

    /**
     * $mention.down()
     *
     */
    this.down = function () {
        let el = document.getElementById('mentions');
        var index = this.choices.indexOf(this.activeChoice);
        if (index < this.choices.length - 1) {
            this.activeChoice = this.choices[index + 1];
            if (el) {
                let child = el.getElementsByClassName('active');
                let scroll = child[0].offsetTop - 139;
                if (scroll > 0 && el.scrollTop < scroll) {
                    el.scrollTop = scroll;
                }
            }
        } else {
            this.activeChoice = this.choices[0];
            if (el) {
                el.scrollTop = 0;
            }
        }
    };

    /**
     * $mention.search()
     *
     */
    this.search = function (match) {
        var _this3 = this;

        this.searching = match;

        return $q.when(this.findChoices(match, this.mentions)).then(function (choices) {
            _this3.choices = choices;
            _this3.activeChoice = choices[0];
            return choices;
        });
    };

    /**
     * $mention.findChoices()
     *
     */
    this.findChoices = function (match, mentions) {
        return [];
    };

    /**
     * $mention.cancel()
     *
     * Clears the choices dropdown info and stops searching
     */
    this.cancel = function () {
        this.choices = [];
        this.searching = null;
    };

    this.autogrow = function () {
        $element[0].style.height = 0; // autoshrink - need accurate scrollHeight
        var style = getComputedStyle($element[0]);
        if (style.boxSizing === 'border-box') $element[0].style.height = $element[0].scrollHeight + 'px';
    };

    // Interactions to trigger searching
    $element.on('keyup click focus', function (event) {
        // If event is fired AFTER activeChoice move is performed
        if (_this2.moved) return _this2.moved = false;
        // Don't trigger on selection
        var text = $element.val();
        // text to left of cursor ends with `@sometext`
        $timeout(() => {
            if ($element[0].selectionStart !== $element[0].selectionEnd) return;
            var match = _this2.pattern.exec(text.substr(0, $element[0].selectionEnd));
            if (match) {
                _this2.search(match);
            } else {
                _this2.cancel();
            }
            $scope.$applyAsync();
        }, 0);
    });

    const closeCommentForm = (event) => {
        if (event && event.keyCode === 27 && $scope.closeCommentForm && $scope.comment) {
            _this2.moved = true;
            $scope.closeCommentForm($scope.comment);
            return true;
        }
    };

    $element.on('keydown', function (event) {
        if (!_this2.searching) {
            closeCommentForm(event);
            return;
        } else if (!_this2.choices.length) {
            if (closeCommentForm(event)) {
                return;
            }
        }

        switch (event.keyCode) {
            case 13:
                // return
                _this2.select(event);
                break;
            case 27:
                // return
                _this2.cancel();
                event.preventDefault();
                break;
            case 38:
                // up
                _this2.up();
                event.preventDefault();
                break;
            case 40:
                // down
                _this2.down();
                event.preventDefault();
                break;
            default:
                // Exit function
                return;
        }

        _this2.moved = true;

        $scope.$applyAsync();
    });

    this.onMouseup = (function (event) {
        var _this4 = this;

        if (event.target === $element[0]) return;

        $document.off('mouseup', this.onMouseup);

        if (!this.searching) {
            return;
        }

        // Let ngClick fire first
        if (_this2.choices.length && $scope.skipClose && event.target.id !== 'attach') {
            _this2.moved = true;
            $scope.skipClose();
            $element[0].focus();
        }
        $scope.$evalAsync(function () {
            _this4.cancel();
        });
    }).bind(this);

    $element.on('focus', function (event) {
        $document.on('mouseup', _this2.onMouseup);
    });

    // Autogrow is mandatory beacuse the textarea scrolls away from highlights
    $element.on('input', this.autogrow);
    // Initialize autogrow height
    $timeout(this.autogrow, true);
}]);
/* eslint-enable */
