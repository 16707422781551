import { flattenReplacement, formatExpression } from '../index'

const processStarterIcon = `<span class="avatar-small"><i class="icon-assignee_process_starter"></i></span>`;

export function ExpressionView(ProcessesSettings) {
    'ngInject';

    return {
        bindToController: {
            value: '<',
            fields: '<',
            placeholder: '@'
        },
        template: `<span ng-bind-html="$ctrl.formatted" class="expression-view"></span>`,
        controllerAs: '$ctrl',
        controller: function() {
            this.replacements = [];

            this.$onChanges = () => {
                if (this.value) {
                    const groups = ProcessesSettings.convertDataFormFieldsToGroups(this.fields || []);
                    this.replacements = flattenReplacement(groups);
                    this.formatted = formatExpression(this.value, this.replacements);
                } else {
                    this.formatted = this.placeholder.replace('[ ]', processStarterIcon);
                }
            };
        }
    };
}
