import config from '../../../config';

export class NotificationsController {
    constructor($scope, profile, notifications, $translate, ApiCalls, $timeout) {
        'ngInject';
        let timeoutId, formSubmitTimeoutId;
        this.profile = profile;
        this.notifications = notifications.notifications;
        let length = this.notifications.length;

        if (length > 8) {
            this.firstCol = this.notifications.slice(0, Math.round(length / 2));
            this.secondCol = this.notifications.slice(Math.round(length / 2));
        } else {
            this.firstCol = this.notifications.slice();
        }

        const showError = errorMessage => {
            this.formHintError = errorMessage;
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
            timeoutId = $timeout(() => {
                this.formHintError = null;
            }, config.errorDelay);
        };

        const submitFormWithDelay = () => {
            if (formSubmitTimeoutId) {
                $timeout.cancel(formSubmitTimeoutId);
            }
            formSubmitTimeoutId = $timeout(() => this.formSubmit(), 600);
        };

        this.changeItem = item => {
            item.toSend = !item.toSend;
            submitFormWithDelay();
        };

        this.checkAll = () => {
            if (this.firstCol) {
                this.firstCol.forEach(n => n.toSend = true);
            }
            if (this.secondCol) {
                this.secondCol.forEach(n => n.toSend = true);
            }
            submitFormWithDelay();
        };

        this.uncheckAll = () => {
            if (this.firstCol) {
                this.firstCol.forEach(n => n.toSend = false);
            }
            if (this.secondCol) {
                this.secondCol.forEach(n => n.toSend = false);
            }
            submitFormWithDelay();
        };

        this.saving = false;
        this.saved = false;

        this.formSubmit = () => {
            let putData = {
                notifications: this.firstCol.concat(this.secondCol || [])
            };
            this.saving = true;
            this.saved = false;

            ApiCalls.updateNotifications(putData)
                .then(resp => {
                    if (resp.success) {
                        this.saving = false;
                        this.saved = true;
                        $timeout(() => this.saved = false, 2000);
                    }
                })
                .catch(errorResp => {
                    const errorMessage = errorResp.data
                        ? errorResp.data.displayError || errorResp.data.error
                        : $translate.instant('error.site');
                    showError(errorMessage);
                    this.saving = false;
                    this.saved = false;
                });
        };

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
        });
    }
}
