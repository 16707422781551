import { copyToClipboard } from '../../../utils'

export class AdminIntegrationsController {
    constructor ($rootScope, $scope, $translate, $sce, $modal, AdminApiCalls, PageSettings, currentUser, MomentHelper) {
        'ngInject'

        const generateKeyAction = () => {
            AdminApiCalls.generateApiKeys()
                .then((response) => this.key = response.result)
                .catch(PageSettings.errorHandlerModal)
                .finally(() => this.generateActionIsInProgress = false)
        }

        const deleteKeyAction = () => {
            AdminApiCalls.deleteApiKeys()
                .then(() => this.key = undefined)
                .catch(PageSettings.errorHandlerModal)
                .finally(() => this.deleteActionIsInProgress = false)
        }

        this.generateKey = () => {
            if (this.generateActionIsInProgress) {
                return
            }
            this.generateActionIsInProgress = true

            // Generate new key
            if (!this.key) {
                return generateKeyAction()
            }

            // Regenerate key
            let modalScope = $rootScope.$new()
            modalScope.title = $translate.instant('label.api.regenerateKey.title')
            modalScope.text = $translate.instant('label.api.regenerateKey.confirmation')
            modalScope.confirmText = $translate.instant('label.api.regenerateKey')
            modalScope.cancelText = $translate.instant('label.cancel')

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal',
                template: require('../../../templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                scope: modalScope
            })

            modalInstance.result
                .then(() => generateKeyAction())
                .catch(() => this.generateActionIsInProgress = false)
                .finally(() => modalScope.$destroy())
        }

        this.deleteKey = () => {
            if (this.deleteActionIsInProgress) {
                return
            }
            this.deleteActionIsInProgress = true

            let modalScope = $rootScope.$new()
            modalScope.title = $translate.instant('label.api.deleteKey.title')
            modalScope.text = $translate.instant('label.api.deleteKey.confirmation')
            modalScope.confirmText = $translate.instant('label.api.deleteKey')
            modalScope.cancelText = $translate.instant('label.cancel')
            modalScope.warning = true

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal',
                template: require('../../../templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                scope: modalScope
            })

            modalInstance.result
                .then(() => deleteKeyAction())
                .catch(() => this.deleteActionIsInProgress = false)
                .finally(() => modalScope.$destroy())
        }

        this.copyKey = () => {
            if (copyToClipboard(this.key.apiKey)) {
                PageSettings.toasterData = {
                    iconClass: 'icon-template_duplicate_roundless',
                    text: $translate.instant('text.api.copyKey'),
                    timeout: 3000
                }
            }
        }

        this.getRestrictedEmptyState = () => {
            return $translate.instant('label.api.notAvailable').replace('&lt;br&gt;', `<br>`)
        }

        this.getApiKeyCreationDateParams = () => {
            return { DATE: MomentHelper.calendarDate(this.key.creationDate) }
        }

        this.$onInit = () => {
            this.loaded = false
            this.apiSectionIsAvailable = currentUser.isAdmin && currentUser.companySettings.apiFeature

            if (this.apiSectionIsAvailable) {
                AdminApiCalls.getApiKeys()
                    .then(response => {
                        this.key = response.list[0]
                    })
                    .catch(PageSettings.errorHandlerModal)
                    .finally(() => this.loaded = true)
            }
        }
    }
}
