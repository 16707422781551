import Pusher from 'pusher-js';
import utils from '../utils';

export function PusherHelper($cookies, $pusher, $window, $location, $rootScope, ServerConfig) {
    'ngInject';
    let _socket, _privateChannel, _pusherData;
    let events = [
        'commentAdded',
        'commentDeleted',
        'tasksDeleted',
        'taskStatusChanged',
        'userOrGroupUpdated',
        'processUpdated',
        'taskUpdated',
        'companyStatusChanged'
    ];
    let subscriptions = {
        commentAdded: [],
        commentDeleted: [],
        tasksDeleted: [],
        taskStatusChanged: [],
        userOrGroupUpdated: [],
        processUpdated: [],
        taskUpdated: [],
        companyStatusChanged: []
    };

    return {
        firstCall: true,
        initSocket: data => {
            if (!data.pusher) {
                return;
            }
            _pusherData = data.pusher;

            let client = new Pusher(_pusherData.key, {
                //special Rest API which is called when 'subscribe' is called for private & presence channels
                //passing CSRF header & Auth token is mandatory, since server checks whether current user has access to channel
                authEndpoint: '/api/pusher/auth',
                encrypted: true,
                authTransport: 'ajax',
                auth: {
                    headers: {
                        'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN'),
                        'X-Auth-Token': data.token || $location.search().authToken || utils.localStorage.get('satellizer_token')
                    }
                }
            });
            $window.pusher = client;
            _socket = $pusher(client);
            _socket.subscribe(_pusherData.presenceChannel);
            _privateChannel = _socket.subscribe(_pusherData.privateChannel);
            _socket.connection.bind('state_change', (states) => {
                if (states.current === 'connected') {
                    $rootScope.$broadcast('socket:connected');
                }
            });
            events.forEach(eKey => {
                _privateChannel.bind(eKey, dataT => {
                    subscriptions[eKey].forEach(fn =>{
                        fn(dataT);
                        $rootScope.$digest();
                    });
                });
            });

            // SYSTEM_UPDATED EVENT
            let broadCaster = _socket.subscribe(ServerConfig.pusherBroadcastChannel);
            broadCaster.bind('SYSTEM_UPDATED', dataT => {
                if (dataT.buildVersion) {
                    let storedBuildVersion = utils.localStorage.get('meta-build-version');
                    if (storedBuildVersion && dataT.buildVersion !== storedBuildVersion) {
                        Object.assign($rootScope.notification, {
                            toDisplay: true,
                            detailsUrl: dataT.detailsUrl || null,
                            buildInfoUrl: ServerConfig.buildInfoUrl ? ServerConfig.buildInfoUrl.replace('{version}', dataT.buildVersion.replace(/\./g, '-'))
                              : '/'
                        });
                    }
                    utils.localStorage.set('meta-build-version', dataT.buildVersion);
                }
            });
        },
        getSocket: () => {
            return _socket;
        },
        getSocketId: () => {
            return $window.pusher && $window.pusher.connection ? $window.pusher.connection.socket_id : '';
        },
        getPrivateChannel: () => {
            return _privateChannel;
        },
        deleteSocket: () => {
            delete $window.pusher;
            _socket.unsubscribe(_pusherData.presenceChannel);
            _socket.unsubscribe(_pusherData.privateChannel);
            _socket.unsubscribe(ServerConfig.pusherBroadcastChannel);
            _socket = null;
            _privateChannel = null;
        },
        subscribe: (key, fn) => {
            subscriptions[key].push(fn);
        },
        unsubscribe: (key, fn) => {
            subscriptions[key] = subscriptions[key].filter(s => s !== fn);
        },
        checkIfEventShouldBeSkipped: (socketId) => {
            return $window.pusher && $window.pusher.connection && $window.pusher.connection.socket_id === socketId;
        }
    };
}
