import Drop from 'tether-drop';

export function selectFilterMode(HomeSettings) {
    'ngInject';
    return {
        restrict: 'E',
        require: '^ngModel',
        replace: true,
        scope: {
            ngModel: '='
        },
        template: require('./select-filter-mode.html'),
        link: (scope, element, attrs, ctrl) => {
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--filter-mode')[0],
                position: attrs.right ? 'bottom right' : 'bottom left',
                openOn: 'click'
            });

            if (attrs.isSub) {
                scope.labels = ['task.filter.active', 'task.filter.completed'];
                scope.tooltipText = 'tooltips.tasksSubModes';
            } else {
                scope.labels = ['title.myTasks', 'title.delegatedTasks'];
                scope.tooltipText = 'tooltips.tasksModes';
            }

            scope.HomeSettings = HomeSettings;
            scope.toggleMode = (isActive) => {
                if (scope.ngModel !== isActive) {
                    ctrl.$setViewValue(isActive);
                }
            };

            scope.close = () => {
                drop.close();
            };

            scope.$on('tether:close', scope.close);
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
