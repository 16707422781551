const STYLE = {
    EXPANDED: 'full',
    COLLAPSED: 'short',
    HIDDEN: 'invisible'
};

const DEFAULT_MAX_HEIGHT = 70;

export function multilineContent($timeout) {
    'ngInject';
    return {
        restrict: 'A',
        scope: false,
        link: (scope, element, attrs) => {
            let initialized = false;
            const initialize = (value) => {
                if (value && !initialized) {
                    if (element.innerHeight() > (attrs.maxHeight || DEFAULT_MAX_HEIGHT)) {
                        element.addClass(STYLE.COLLAPSED);
                    }

                    element.removeClass(STYLE.HIDDEN);
                    initialized = true;
                }
            };

            $timeout(() => {
                scope.$watch(() => element.is(':visible'), initialize);
            });

            const toggleElState = (expanded, event) => {
                if (event) {
                    event.stopPropagation();
                }

                element.toggleClass(STYLE.COLLAPSED, !expanded);
                element.toggleClass(STYLE.EXPANDED, expanded);
            };

            scope.showMore = (event) => {
                toggleElState(true, event);
            };
            scope.showLess = (event) => {
                toggleElState(false, event);
            };
        }
    };
}
