import { configureStore, getDefaultMiddleware } from 'redux-starter-kit'
// import logger from 'redux-logger';
import throttle from 'lodash/throttle'
import merge from 'lodash/merge'

import reducer from './reducers'
import defaultState from './default-state'
import { checkIfDateRangeHasError } from './utils'

export function loadState () {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export function saveState (state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        // Do nothing
    }
}

const middleware = [...getDefaultMiddleware()]
const savedState = loadState() || {}

if (savedState.reports?.length) {
    savedState.reports.forEach(report => {
        report.name = undefined
        report.description = undefined
        if (report.data) {
            report.data.isLoading = undefined
        }
        if (report.filters?.dateRange) {
            report.filters.dateRange.hasError = checkIfDateRangeHasError(report.filters.dateRange)
        }
    })
}

const store = configureStore({
    reducer,
    middleware,
    preloadedState: merge(defaultState, savedState)
})

store.subscribe(throttle(() => {
    saveState({
        templates: store.getState().templates,
        reports: store.getState().reports
    })
}, 1000))

export default store
