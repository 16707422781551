import React from 'react'
import PropTypes from 'prop-types'
import Select, { components, createFilter } from 'react-select'
import clsx from 'clsx'

import { translate } from '../../modules/react/utils'

import './select-dropdown.less'

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="icon-common_arrow_down"/>
        </components.DropdownIndicator>
    )
}

const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props} className={clsx({ 'empty-value': props.value === null })}>
            <i className="icon-menu_checkmark"/>
            {children}
        </components.Option>
    )
}

const selectDropdownStyles = (width, minWidth) => ({
    control: provided => ({ ...provided, width }),
    placeholder: (provided) => {
        provided.position = undefined
        provided.top = undefined
        provided.transform = undefined
        provided.maxWidth = undefined

        return { ...provided }
    },
    singleValue: (provided) => {
        provided.position = undefined
        provided.top = undefined
        provided.transform = undefined
        provided.maxWidth = undefined

        return { ...provided }
    },
    option: provided => {
        provided.background = undefined
        provided.color = undefined
    },
    menu: base => ({ ...base, zIndex: 10, minWidth }),
    groupHeading: base => ({ ...base, textTransform: undefined })
})

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true
}

class SelectDropdown extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        options: PropTypes.arrayOf(PropTypes.object),
        onChange: PropTypes.func,
        width: PropTypes.number,
        minWidth: PropTypes.number,
        disabled: PropTypes.bool,
        isSearchable: PropTypes.bool,
        isClearable: PropTypes.bool,
        placeholder: PropTypes.string
    }

    static defaultProps = {
        isSearchable: false,
        isClearable: false
    }

    constructor (props) {
        super(props)
    }

    render () {
        const { width, minWidth, disabled, value, options, ...props } = this.props

        return (
            <Select
                styles={selectDropdownStyles(width, minWidth)}
                components={{ DropdownIndicator, Option }}
                className="select-dropdown"
                classNamePrefix="select-dropdown"
                menuPosition={'absolute'}
                menuPlacement={'auto'}
                isDisabled={disabled}
                filterOption={createFilter(filterConfig)}
                value={this.translatedValue(value)}
                options={options.map(this.translatedValue)}
                {...props}
            />
        )
    }

    translatedValue = (value) => value ? ({...value, label: translate(value.label)}) : undefined
}

export default SelectDropdown
