import FilePreview from '../react/file-preview.jsx';

export function reactFilePreview(reactDirective) {
    'ngInject';
    const mapProps = [
        'file',
        'gallery',
        'isopen',
        'onclose'
    ];
    return reactDirective(FilePreview, mapProps, {restrict: 'E'}, {});
}
