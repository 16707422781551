import { HomeController } from './home.controller.js'
import { HomeSettings } from './services/home-settings.js'
import { undoDeferredAction } from './directives/undo-deferred-action'
import { selectTasksGroup } from './directives/select-tasks-group'
import { selectFilterMode } from './directives/select-filter-mode'
import { tasksMainHeader } from './directives/tasks-main-header'
import { reactMainTasksList } from './directives/react-main-tasks-list'
import { reactAvailableTasksList } from './directives/react-available-tasks-list'

import './less/index.less'

let ngModule = angular.module('home', [])
    .controller('HomeController', HomeController)
    .directive('undoDeferredAction', undoDeferredAction)
    .directive('selectTasksGroup', selectTasksGroup)
    .directive('selectFilterMode', selectFilterMode)
    .directive('tasksMainHeader', tasksMainHeader)
    .directive('reactMainTasksList', reactMainTasksList)
    .directive('reactAvailableTasksList', reactAvailableTasksList)
    .factory('HomeSettings', HomeSettings)

export default ngModule
