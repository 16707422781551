import Drop from 'tether-drop';
const TemplateSectionTypesContext = Drop.createContext({
    classPrefix: 'section-types-drop'
});

export function templateSectionTypes() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
            ngModel: '='
        },
        template: require('../templates/template-section-types.html'),
        link: (scope, element, attrs, ctrl) => {
            let drop = new TemplateSectionTypesContext({
                target: element.find('.handler')[0],
                content: element.find('.types-list')[0],
                position: 'bottom left',
                openOn: 'click'
            });

            scope.setType = isTable => {
                ctrl.$setViewValue(isTable || false);
                drop.close();
            };

            scope.getIcon = isTable => {
                return isTable
                    ? 'icon-template_create_section_table'
                    : 'icon-template_create_section_form';
            };

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
