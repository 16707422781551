import isNil from 'lodash/isNil'
import FormulaParser from './FormulaParser'
import FormulaParserVisitor from './FormulaParserVisitor'
import { getDataFormField, getFieldId, getFieldValue } from '../../../modules/templates/services/data-model-utils'

export default class FormulaCalculator extends FormulaParserVisitor {
    constructor (field, dataModelList, currentRowIndex) {
        super()
        this.field = field
        this.dataModelList = dataModelList
        this.currentRowIndex = currentRowIndex
    }

    getField (ctx) {
        const fieldId = /[0-9a-z]{32}/.exec(ctx.getText())[0]
        return getDataFormField(fieldId, this.dataModelList, this.currentRowIndex)
    }

    getFieldValue (ctx) {
        const field = this.getField(ctx)
        return getFieldValue(field, this.dataModelList, this.currentRowIndex, true)
    }

    calculate (ctx, isDivision) {
        const a = this.visit(ctx[0])
        const b = this.visit(ctx[1])

        if (a?.calculationErrors) {
            return () => a
        }

        if (b?.calculationErrors) {
            return () => b
        }

        if (isDivision && b === 0) {
            return () => ({
                calculationErrors: [
                    { code: 'divisionByZero', fieldId: getFieldId(this.field) }
                ]
            })
        }

        if (isNil(a) || isNil(b)) {
            return () => undefined
        }

        return (callback) => callback(a, b)
    }

    visitNumericExp (ctx) {
        return Number(ctx.getText())
    }

    visitFieldExp (ctx) {
        return this.getFieldValue(ctx)
    }

    visitMulDivExp (ctx) {
        if (ctx.operator.type === FormulaParser.MUL) {
            return this.calculate(ctx.expression())((a, b) => a * b)
        }
        if (ctx.operator.type === FormulaParser.DIV) {
            return this.calculate(ctx.expression(), true)((a, b) => a / b)
        }
    }

    visitAddSubExp (ctx) {
        if (ctx.operator.type === FormulaParser.ADD) {
            return this.calculate(ctx.expression())((a, b) => a + b)
        }
        if (ctx.operator.type === FormulaParser.SUB) {
            return this.calculate(ctx.expression())((a, b) => a - b)
        }
    }

    visitParenthesisExp (ctx) {
        return this.visit(ctx.expression())
    }

    visitResultExpression (ctx) {
        return this.visit(ctx.expression())
    }
}
