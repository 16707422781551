import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactModal from 'react-modal'

import './adaptive-modal.less'

class ModalDialog extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        title: PropTypes.node.isRequired,
        children: PropTypes.node,
        onCancel: PropTypes.func.isRequired,
        className: PropTypes.string
    }

    static defaultProps = {
        isOpen: false
    }

    constructor (props) {
        super(props)

        this.state = { withFixedHeader: false }

        this.overlayRef = React.createRef()
        this.contentRef = React.createRef()

        this.onCancel = this.onCancel.bind(this)
        this.scrollHandler = this.scrollHandler.bind(this)
        this.afterOpen = this.afterOpen.bind(this)
    }

    render () {
        const { withFixedHeader } = this.state
        const { isOpen, title, children, className } = this.props

        return (
            <ReactModal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={this.onCancel}
                onAfterOpen={this.afterOpen}
                overlayClassName="modal fade in"
                htmlOpenClassName="ReactModal__Html--open"
                overlayRef={node => this.overlayRef = node}
                contentRef={node => this.contentRef = node}
                className={cn('adaptive-modal', className, { 'fixed-header': withFixedHeader })}
                style={{ overlay: { zIndex: 1040 } }}
                shouldCloseOnEsc={false}
            >
                {isOpen && (
                    <div className="modal-content">
                        <div className="modal-header">
                            <i className="icon-common_close" onClick={this.onCancel}/>
                            {title}
                        </div>
                        <div className="modal-header fixed" style={{ width: this.state.fixedHeaderWidth }}>
                            <i className="icon-common_close" onClick={this.onCancel}/>
                            {title}
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                )}
            </ReactModal>
        )
    }

    afterOpen () {
        setTimeout(() => {
            this.overlayRef.addEventListener('scroll', this.scrollHandler)
        }, 100)
        this.setState({
            fixedHeaderWidth: getComputedStyle(this.contentRef.children[0]).width
        })
    }

    scrollHandler () {
        this.setState({
            withFixedHeader: this.overlayRef.scrollTop > 90
        })
    }

    onCancel () {
        this.setState({
            withFixedHeader: false
        })

        this.props.onCancel()
    }
}

export default ModalDialog
