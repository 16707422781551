import React from 'react'
import { components } from 'react-select'

export const DropdownIndicator = ({ ...props }) => {
    return props.selectProps.isMulti ? null : (
        <components.DropdownIndicator {...props}>
            <i className="icon-common_arrow_down"/>
        </components.DropdownIndicator>
    )
}

export const ClearIndicator = ({ ...props }) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon-common_close"/>
        </components.ClearIndicator>
    )
}
