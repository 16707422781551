import { activationRoute } from './activation.config'
import { BillingController } from './controllers/billing.controller'

import './less/billing.less'

let ngModule = angular.module('activation', [])
    .config(activationRoute)
    .controller('BillingController', BillingController)

export default ngModule
