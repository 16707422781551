import React from 'react'
import PropTypes from 'prop-types'
import FieldValuePrintView from './field-value-print-view'

function SimpleSectionPrintView ({ section }) {
    return (
        <div className={'data-form-section'}>
            <h3>{section.name}</h3>
            <div className={'fields'}>
                {section.fieldsWithValues.map(field => (
                    <div key={field.name.id} className={'field'}>
                        <label>{field.name.label}</label>
                        <FieldValuePrintView value={field.value} dataType={field.name.dataType} displayEmptyValue={false} />
                    </div>
                ))}
            </div>
        </div>
    )
}

SimpleSectionPrintView.propTypes = {
    section: PropTypes.object
}

export default SimpleSectionPrintView
