export function filesize() {
    'ngInject';

    return (input) => {
        if (!input) {
            return input;
        }
        return Math.round(Number(input) / 1024) + ' KB';
    };
}
