export function dataModelTableRow($timeout) {
    'ngInject';
    return {
        restrict: 'C',    
        link: (scope, el) => {
            let mainEl;
            let mouseOutListener = () => {
                let actionsWrapper  = el[0].querySelector('.actions_wrapper');
                if (actionsWrapper) {
                    actionsWrapper.classList.remove('show');
                    actionsWrapper.classList.remove('animate');
                }
            };
      
            let mouseOverListener = () => {
                let actionsWrapper  = el[0].querySelector('.actions_wrapper');
                if (actionsWrapper && mainEl) {
                    actionsWrapper.style.left = (mainEl.scrollLeft + mainEl.clientWidth) + 'px';
                    actionsWrapper.classList.add('show');
                    $timeout(() => actionsWrapper.classList.add('animate'));
                }
            };
      
            $timeout(() => {
                let actionsWrapper  = el[0].querySelector('.actions_wrapper');
                if (actionsWrapper) {
                    actionsWrapper.style.left = (parent.scrollLeft + parent.clientWidth) + 'px';
                }
                let parentEl = el.parent()[0];
                if (parentEl) {
                    mainEl = parentEl.parentNode.parentNode;
                    mainEl.addEventListener('scroll', mouseOutListener);
                }
            });
      
            el.bind('mouseenter touchstart', mouseOverListener);
            el.bind('mouseleave touchend click', mouseOutListener);
      
            scope.$on('$destroy', () => {
                el.unbind('mouseenter touchstart', mouseOverListener);
                el.unbind('mouseleave touchend click', mouseOutListener);
                if (mainEl) {
                    mainEl.removeEventListener('scroll', mouseOutListener);
                }
            });
        }
    };
}
