import { createAction } from 'redux-starter-kit'
import { downloadFile, prepareReportExportParams, prepareReportParams } from './utils'
import { getCurrentReport } from './selectors'
import { MIN_EXPORT_PROGRESS, REPORT_PAGE_SIZE } from './constants'
import { getService } from '../../react/utils'

export const selectReport = createAction('reports/selectReport')

export const openReport = (slug) => {
    return (dispatch, getState) => {
        const report = getState().reports.find(r => r.slug === slug)
        dispatch(selectReport(report.id))
    }
}

export const requestTemplates = createAction('reports/requestTemplates')
export const receiveTemplates = createAction('reports/receiveTemplates')

export const fetchTemplatesAsync = () => {
    const ApiCalls = getService('ApiCalls')

    return dispatch => {
        dispatch(requestTemplates())
        ApiCalls.getTemplatesForReports().then(json => dispatch(receiveTemplates(json)))
    }
}

export const requestUsers = createAction('reports/requestUsers')
export const receiveUsers = createAction('reports/receiveUsers')

export const fetchUsersAsync = () => {
    const ApiCalls = getService('ApiCalls')

    return dispatch => {
        dispatch(requestUsers())
        ApiCalls.getActorsUsers().then(json => dispatch(receiveUsers(json)))
    }
}

export const updateFilters = createAction('reports/updateFilters')
export const clearFilters = createAction('reports/clearFilters')

export const updateFiltersForSelectedReport = (filters) => {
    return (dispatch, getState) => {
        const report = getCurrentReport(getState())
        if (filters) {
            dispatch(updateFilters({ reportId: report.id, filters }))
        } else {
            dispatch(clearFilters({ reportId: report.id }))
        }
    }
}

export const requestReportDataSize = createAction('reports/requestReportDataSize')
export const receiveReportDataSize = createAction('reports/receiveReportDataSize')

export const requestReportData = createAction('reports/requestReportData')
export const receiveReportData = createAction('reports/receiveReportData')

export const fetchReportDataSize = () => {
    const ApiCalls = getService('ApiCalls')

    return (dispatch, getState) => {
        const report = getCurrentReport(getState())
        const params = prepareReportParams(report)
        dispatch(requestReportDataSize({ reportId: report.id }))
        ApiCalls.getReportDataSize(params)
            .then(json => dispatch(receiveReportDataSize({
                reportId: report.id,
                total: json.result.total
            })))
    }
}

export const fetchReportData = (nextOffset, callback) => {
    const ApiCalls = getService('ApiCalls')

    return (dispatch, getState) => {
        dispatch(requestReportData())
        const report = getCurrentReport(getState())
        const params = prepareReportParams(report)
        params.count = REPORT_PAGE_SIZE
        params.nextOffset = nextOffset
        ApiCalls.getReportData(params).then(json => callback(json, { reportId: report.id }))
    }
}

export const receiveConfig = createAction('reports/receiveConfig')

export const updateColumns = createAction('reports/updateColumns')

export const toggleColumnsDropdown = createAction('reports/toggleColumnsDropdown')
export const toggleColumnVisibility = createAction('reports/toggleColumnVisibility')
export const toggleColumnLock = createAction('reports/toggleColumnLock')

export const toggleExportDropdown = createAction('reports/toggleExportDropdown')

export const requestReportExport = createAction('reports/requestReportExport')
export const receiveReportExport = createAction('reports/receiveReportExport')
export const receiveReportExportProgress = createAction('reports/receiveReportExportProgress')
export const receiveCompletedReportExport = createAction('reports/receiveCompletedReportExport')

const SEC = 1000
const MIN = 60 * SEC
const INITIAL_DELAY = 0.3 * SEC
const MAX_DELAY = 3 * SEC
const MAX_POOLING_TIME = 60 * MIN

const checkReportExportStatus = (reportId, dispatch) => {
    const ApiCalls = getService('ApiCalls')
    const PageSettings = getService('PageSettings')
    const DevicesDetector = getService('DevicesDetector')

    let delay = INITIAL_DELAY
    let poolingStartedAt = Date.now()

    dispatch(receiveReportExportProgress({ progress: MIN_EXPORT_PROGRESS }))

    function checkStatusCall () {
        ApiCalls.exportReportStatus(reportId).then(json => {
            delay = Math.min(delay * 2, MAX_DELAY)
            const { result } = json
            const maxPoolingTimeIsReached = Date.now() - poolingStartedAt >= MAX_POOLING_TIME

            if (result.isFailed || maxPoolingTimeIsReached) {
                // Handle export error
                PageSettings.openErrorModal()
                dispatch(receiveCompletedReportExport())
            } else if (!result.isCompleted && !maxPoolingTimeIsReached) {
                // Request status again
                const actualProgress = (result.recordsProcessed / result.recordsCount * 100)
                const progress = Math.max(MIN_EXPORT_PROGRESS, actualProgress).toFixed(0)
                dispatch(receiveReportExportProgress({ progress }))
                setTimeout(() => checkStatusCall(delay), delay)
            } else {
                dispatch(receiveReportExportProgress({ progress: 100 }))
                setTimeout(() => dispatch(receiveCompletedReportExport()), delay)
                if (result.reportFile) {
                    downloadFile(result.reportFile, DevicesDetector.isMobile())
                }
            }
        })
    }

    setTimeout(() => checkStatusCall(), delay)
}

export const exportReport = (format) => {
    const ApiCalls = getService('ApiCalls')

    return (dispatch, getState) => {
        dispatch(requestReportExport())
        const report = getCurrentReport(getState())
        const params = prepareReportExportParams(report, format)
        ApiCalls.exportReport(params).then(json => {
            const { result } = json
            dispatch(receiveReportExport(result))
            checkReportExportStatus(result.reportId, dispatch)
        })
    }
}

export const selectRow = createAction('report/selectRow')
