/*global angular*/
export class TmplGalleryController {
    constructor ($scope, $state, $modalInstance, $timeout, PageSettings, ApiCalls) {
        'ngInject'
        let isLoading = false

        $scope.categories = {}
        $scope.buildCategories = () => {
            $scope.categories = $scope.source.reduce((categories, template) => {
                const categoryId = template.category.toLowerCase()
                if (categories[categoryId]) {
                    categories[categoryId].templates.push(template)
                } else {
                    categories[categoryId] = {
                        name: template.category,
                        id: categoryId,
                        templates: [template]
                    }
                }
                return categories
            }, {})

            if (!$scope.activeCategory) {
                $scope.setCategory($scope.categories[Object.keys($scope.categories)[0]])
            }
        }

        $timeout(() => $scope.canContinue = true, 3000)

        $scope.PageSettings = PageSettings
        $scope.templatesTree = []

        $scope.addToTemplates = () => {
            if (isLoading) {
                return
            }
            isLoading = true
            ApiCalls.copyMultipleGalleryTemplates($scope.selected)
                .then(() => $modalInstance.close(true))
                .catch(PageSettings.errorHandlerModal)
                .finally(() => isLoading = false)
        }

        $scope.setCategory = category => {
            $state.go('main.templates.gallery.category', {category: category.id})
        }

        $scope.isSelected = (tmpl) => {
            return $scope.selected.includes(tmpl.id)
        }

        $scope.toggleTmpl = tmpl => {
            const selected = $scope.selected.filter(id => id !== tmpl.id)
            if (!$scope.isSelected(tmpl)) {
                selected.push(tmpl.id)
            }
            $state.go('main.templates.gallery.category.templates', {category: $scope.activeCategory, selected: selected.join(',')})
        }

        $scope.getTasksCount = tmpl => angular.toJson({ COUNT: tmpl.tasksCount || 0 })
        $scope.getCountText = () => angular.toJson({ COUNT: $scope.selected.length || 0 })
        $scope.cancel = () => $modalInstance.dismiss()

        $scope.$watch('source', (val) => {
            if (val?.length) {
                $scope.buildCategories()
            }
        })

        $scope.$watch('activeCategory', (val) => {
            if (val) {
                $timeout(() => {
                    let el = document.getElementById('tmpl-gallery-templates-shadow') //eslint-disable-line
                    if (el) {
                        el.scrollTop = 0
                        if (el.scrollTop + el.clientHeight >= el.scrollHeight - 5) {
                            el.parentNode.classList.remove('bottom-shadow')
                        } else if (el.scrollHeight > el.clientHeight) {
                            el.parentNode.classList.add('bottom-shadow')
                        }
                    }
                })
            }
        })

        $timeout(() => {
            if ($scope.selected?.length) {
                const el = document.querySelector('#tmpl-gallery-templates-shadow')
                const child = el.querySelector('article.selected')
                if (child) {
                    child.scrollIntoView()
                }
            }
        }, 100)
    }
}
