import React from 'react'
import PropTypes from 'prop-types'
import ReportIcon from './report-icon.jsx'
import ColumnsDropdown from '../containers/columns-dropdown-container'
import ExportDropdown from '../containers/export-dropdown-container'
import Icon from '../../../components/react/icon.jsx'
import Tooltip from '../../react/tooltip.jsx'
import { translate } from '../../react/utils'

const ReportHeader = ({ report, dataIsReceived, onReportExport, isMobile = false }) => (
    <header className="report-header">

        <ReportIcon icon={report.icon}/>

        <div className="report-title">
            <div className="title">
                <h1>{translate(report.name)}</h1>
            </div>
        </div>

        <div className="report-actions">
            {dataIsReceived && (
                <ColumnsDropdown
                    reportId={report.id}
                    columns={report.columns}
                />
            )}
            {(!isMobile && dataIsReceived) && (
                <ExportDropdown
                    report={report}
                    onReportExport={onReportExport}
                />
            )}
            {(!isMobile && !dataIsReceived) && (
                <Tooltip text="tooltips.reportExport" isDisabled={true}>
                    <ExportDropdown
                        report={report}
                        onReportExport={onReportExport}
                        disabled={true}
                    />
                </Tooltip>
            )}
        </div>

        <a href="/reports" className="btn btn-close">
            <Icon name="common_close"/>
        </a>
        <a href="/reports" className="btn btn-back">
            <Icon name="common_button_back"/>
        </a>
    </header>
)

ReportHeader.propTypes = {
    report: PropTypes.object.isRequired,
    onReportExport: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    dataIsReceived: PropTypes.bool
}

export default ReportHeader
