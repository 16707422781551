/*global $*/
import appUtils, { unescapeHtml } from '../../../utils'
import utils from '../utils'
import { constants, DATE_SYSTEM_FORMAT } from '../../../services/constants'
import moment from 'moment-timezone'
import {
    formatFieldForExpression,
    STATIC_PART_MAX_LENGTH_FOR_TASK,
    STATIC_PART_MIN_LENGTH
} from '../../../components/expression/index'
import { checkFormulaSettingsError } from '../../templates/services/data-model-utils'
import { FORMULA, MULTI_SELECTOR, RADIO_SELECTOR, USER_FIELD } from '../../../services/data-types'

const unitsToPeriod = {
    DAY: 'days',
    WEEK: 'weeks',
    MONTH: 'months'
}

const getAllServerField = data => {
    if (!data || !data.list) {
        return
    }
    let res = []
    let addToRes = (f, index, indexP) => {
        if (f.name && (f.name.label || f.name.options || f.name.defaultValue)) {
            f.index = index
            f.parentIndex = indexP
            res.push(f)
        }
    }
    data.list.forEach((i, indexP) => {
        if (i.section) {
            if (i.section.fieldsWithValues) {
                i.section.fieldsWithValues.forEach((f, index) => addToRes(f, index, indexP))
            }
            if (i.section.columns) {
                i.section.columns.forEach((f, index) => addToRes(f, index, indexP))
            }
        }
    })
    return res
}

const checkServerErrorData = sE => {
    if (!sE) {
        return
    }
    if (sE.name || sE.description || sE.shortDescription || sE.dueDateInterval || sE.items || sE.processStart
        || sE.starters || sE.editors || sE.managers || sE.watchers) {
        return true
    } else if (sE.dataModel && sE.dataModel.list) {
        if (sE.dataModel.list.length === 1 && !sE.dataModel.list[0].section) {
            return true
        }
        if (sE.dataModel.list.find(i => i.section && i.section.name)) {
            return true
        }
        return !!(getAllServerField(sE.dataModel) && getAllServerField(sE.dataModel).length)
    }
}

const prepareAllCounters = (incomeList, deletedTasks, $filter, DateHelper, MomentHelper) => {
    return incomeList.map(item => {
        if (item.tasks) {
            if (deletedTasks && deletedTasks.length) {
                item.tasks = item.tasks.filter(t => deletedTasks.every(dTask => dTask.id !== t.id))
                if (item.tasks.length < item.tasksCount) {
                    item.tasksCount--
                }
            }
            item.tasks.forEach(t => {
                t.isUpcoming = (utils.checkPrDueDate(t, true, DateHelper) || utils.checkPrDueDate(t, null, DateHelper)) && t.status !== 16
                t.isPastDue = utils.checkPrDueDate(t, true, DateHelper)
                t.warnDueDate = $filter('dueDate')(t.dueDate, true)
            })
        }
        item.formattedDueDate = utils.getDueDate(item, DateHelper, true)
        item.warnFormattedDueDate = $filter('dueDate')(item.dueDate, true, true)
        item.isPastDue = utils.checkPrDueDate(item, true, DateHelper)
        item.isWarnDue = utils.checkPrDueDate(item, null, DateHelper)
        return item
    })
}

const allowedDataTypes = [
    'DATE_ONLY_VALUE',
    'DATE_AND_TIME',
    'STRING_SINGLE_LINE',
    'RADIO_SELECTOR',
    'NUMERIC_VALUE',
    'MONEY',
    'EMAIL',
    'USER_FIELD'
]

function getDefaultReplacementsGroup ($translate) {
    return {
        id: appUtils.uid4(),
        name: $translate.instant('expression.specialValues.groupName'),
        replacements: [
            {
                id: appUtils.uid4(),
                name: $translate.instant('expression.specialValues.startDate'),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.START_DATE
            },
            {
                id: appUtils.uid4(),
                name: unescapeHtml($translate.instant('expression.specialValues.startDateAndTime')),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.START_DATE_TIME
            },
            {
                id: appUtils.uid4(),
                name: $translate.instant('expression.specialValues.startMonth'),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.START_MONTH
            },
            {
                id: appUtils.uid4(),
                name: $translate.instant('expression.specialValues.startYear'),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.START_YEAR
            },
            {
                id: appUtils.uid4(),
                name: $translate.instant('expression.specialValues.processStarter'),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.PROCESS_STARTER
            },
            {
                id: appUtils.uid4(),
                name: $translate.instant('expression.specialValues.templateName'),
                value: constants.PROCESS.SPECIAL_NAME_VALUES.TEMPLATE_NAME
            }
        ]
    }
}

function getConvertDataFormFieldsToGroups ($translate) {
    return (fields) => {
        let groups = []

        fields.forEach((item, index) => {
            let group = item.section
            if (!group.isTable && group.fieldsWithValues) {
                let replacementGroup = {
                    id: group.id || appUtils.uid4(),
                    name: group.name || 'Section ' + (index + 1),
                    replacements: group.fieldsWithValues.map(field => {
                        const isHidden = !field.name.label || allowedDataTypes.indexOf(field.name.dataType) < 0
                        return formatFieldForExpression(field, isHidden)
                    })
                }

                groups.push(replacementGroup)
            }
        })

        groups.push(getDefaultReplacementsGroup($translate))

        return groups
    }
}

export const DEFAULT_PROCESS_GROUP_TYPE_ID = 1

export function ProcessesSettings ($translate, $modal, $rootScope, $window, $filter, DateHelper, PageSettings, MomentHelper) {
    'ngInject'
    let filterModes = [{
        id: 'active',
        title: $translate.instant('process.filter.active')
    }, {
        id: 'completed',
        title: $translate.instant('process.filter.completed')
    }]

    let settings = {
        showFilter: false,
        filterModes: filterModes,
        activeFilterMode: filterModes[0],
        searchString: '',
        processesGroup: appUtils.localStorage.get('ProcessesListGroup') != null && !isNaN(Number(appUtils.localStorage.get('ProcessesListGroup')))
            ? Number(appUtils.localStorage.get('ProcessesListGroup'))
            : DEFAULT_PROCESS_GROUP_TYPE_ID,

        templateSortOptions: (cb) => ({
            orderChanged: () => cb(),
            dragMove: (itemPosition, containment, eventObj) => {
                if (eventObj) {
                    let container = document.getElementById('section-tasks-list') //eslint-disable-line
                    let targetY = eventObj.pageY - $window.pageYOffset
                    if (targetY < $(container).offset().top + 30) { //eslint-disable-line
                        container.scrollTop = container.scrollTop - 50
                    } else if (targetY - 200 > container.clientHeight) {
                        container.scrollTop = container.scrollTop + 50
                    }
                }
            }
        }),
        processSortOptions: {
            dragMove: (itemPosition, containment, eventObj) => {
                if (eventObj) {
                    let container = document.getElementsByClassName('template__section')[0] //eslint-disable-line
                    let targetY = eventObj.pageY - $window.pageYOffset
                    if (targetY < 130) {
                        container.scrollTop = container.scrollTop - 50
                    } else if (targetY > container.clientHeight) {
                        container.scrollTop = container.scrollTop + 50
                    }
                }
            }
        },

        toggleFilter: () => {
            settings.showFilter = !settings.showFilter
        },
        setDefaultAll: toAll => {
            settings.defaultAll = toAll || null
        },
        toggleMode: mode => {
            if (mode.id !== settings.activeFilterMode.id) {
                settings.activeFilterMode = mode
            }
        },
        reset: () => {
            settings.activeFilterMode = filterModes[0]
            settings.defaultAll = null
            settings.searchString = ''
        },
        callExitModal: ({ title, text, confirmText, cancelText } = {}) => {
            let scope = $rootScope.$new()
            scope.title = $translate.instant(title || 'templateEdit.text.leaveThisPage')
            scope.text = $translate.instant(text || 'templateEdit.text.unsavedChanges')
            scope.confirmText = $translate.instant(confirmText || 'label.continueEditing')
            scope.cancelText = $translate.instant(cancelText || 'label.discardChanges')
            scope.expire = true
            scope.revertButtons = true

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal',
                template: require('../../../templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                scope: scope
            })
            modalInstance.result.finally(() => {
                scope.$destroy()
            })
            return modalInstance
        },
        callTaskViewExitModal: ({ title, text, confirmText } = {}) => {
            let scope = $rootScope.$new()
            scope.title = $translate.instant(title || 'taskView.text.leaveThisPage')
            scope.text = $translate.instant(text || 'taskView.text.unsavedChanges')
            scope.confirmText = $translate.instant(confirmText || 'taskView.label.stayOnPage')
            scope.expire = true
            scope.revertButtons = true

            return $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal',
                template: require('../../../templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                scope: scope
            })
        },
        callAlertTaskPageModal: errResponse => {
            if (errResponse.status === 473) {
                return PageSettings.errorHandlerModal(errResponse)
            }

            let modalScope = $rootScope.$new()
            modalScope.title = $translate.instant('error.commonTitle')
            modalScope.text = errResponse.data.displayError || errResponse.data.error
            modalScope.additionalText = $translate.instant('error.okText')
            PageSettings.openAlertModal(modalScope)
                .then(() => $window.location.reload())
                .finally(() => modalScope.$destroy())
        },
        callUserFieldAccessErrorModal: () => {
            let modalScope = $rootScope.$new()
            modalScope.title = $translate.instant('error.commonTitle')
            modalScope.text = $translate.instant('error.userField.userRestricted')
            PageSettings.openAlertModal(modalScope).finally(() => modalScope.$destroy())
        },
        getTemplateUITextErrors: (form, field, name, itemName, fieldV) => {
            if (name === 'task-title' || name === 'task-group-title') {
                let keys = ['required']
                if (!itemName || itemName.length < STATIC_PART_MIN_LENGTH) {
                    keys = ['minlength']
                } else if (itemName.length > STATIC_PART_MAX_LENGTH_FOR_TASK) {
                    keys = ['maxlength']
                }
                return $translate.instant(`validation.${name}.${keys[0]}`)
            }

            if (!form[field] || !form[field].$error) {
                return
            }
            let keys = Object.keys(form[field].$error)
            let key = keys.indexOf('pattern') !== -1 ? 'pattern' : keys[0]

            if (field === 'template-title') {
                return $translate.instant(`validation.${field}.${key}`)
            } else if (name === 'section') {
                return $translate.instant(`validation.${name}.${key}`)
            } else if (name === 'field') {
                if (!key && !fieldV.name.label) {
                    key = 'require'
                }
                if (!utils.invalidSelectorField(fieldV) && !utils.invalidUserFieldSettingsField(fieldV) && !checkFormulaSettingsError(fieldV)) {
                    return $translate.instant(`validation.${name}.${key}`)
                } else if (fieldV.name.dataType === RADIO_SELECTOR) {
                    if (fieldV.name && fieldV.name.options && fieldV.name.options.source && fieldV.name.options.source.isInvalid) {
                        key = 'source'
                    }
                    return $translate.instant(`validation.${name}.dropdown-${key}`)
                } else if (fieldV.name.dataType === MULTI_SELECTOR) {
                    if (fieldV.name && fieldV.name.options && fieldV.name.options.source && fieldV.name.options.source.isInvalid) {
                        key = 'source'
                    }
                    return $translate.instant(`validation.${name}.multiselect-${key}`)
                } else if (fieldV.name.dataType === USER_FIELD) {
                    return $translate.instant(`validation.${name}.userField.invalidSettings`)
                } else if (fieldV.name.dataType === FORMULA) {
                    const key = checkFormulaSettingsError(fieldV) === 'require' ? 'isInvalid' : 'isRequired'
                    return $translate.instant(`validation.formula.expression.${key}`)
                }
            }
        },
        getTemplateServerErrorCount: data => {
            let count = 0
            if (data.name) {
                count++
            }
            if (data.description) {
                count++
            }
            if (data.shortDescription) {
                count++
            }
            if (data.dueDateInterval) {
                count++
            }
            count = count + (data.tasks ? data.tasks.length : 0)
            if (data.dataModel) {
                if (data.dataModel.list.length) {
                    if (data.dataModel.list.length === 1 && !data.dataModel.list[0].section) {
                        count++
                    } else {
                        count = count + data.dataModel.list.filter(i => i.section && i.section.name).length
                    }
                }
                let fields = getAllServerField(data.dataModel)
                count = count + fields.length
            }
            return {
                origin: count > 4 ? 4 : count,
                val: count - 3 < 1 ? null : count - 3,
                text: angular.toJson({ COUNT: count - 3 })
            }
        },
        getAllServerField,
        checkServerErrorData: checkServerErrorData,
        prepareAllProcessListCounters: (incomeList, deletedTasks) => {
            return prepareAllCounters(incomeList, deletedTasks, $filter, DateHelper, MomentHelper)
        },
        allowedDataTypes,
        defaultReplacementsGroup: getDefaultReplacementsGroup($translate),
        convertDataFormFieldsToGroups: getConvertDataFormFieldsToGroups($translate),
        calculateDueDateFromInterval: (dueDateInterval) => {
            let tz = DateHelper.getCompanyTZ()
            let currentDateZeroTimeCompanyTZ = moment().tz(tz).startOf('day')
            if (dueDateInterval.units === 'BUSINESS_DAY') {
                MomentHelper.businessAdd(currentDateZeroTimeCompanyTZ, dueDateInterval.interval)
            } else {
                currentDateZeroTimeCompanyTZ.add(dueDateInterval.interval, unitsToPeriod[dueDateInterval.units])
            }
            //as a result return Date in same Format as we return from Calendar - Date ZERO TIME in UTC
            return moment.utc(currentDateZeroTimeCompanyTZ.format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT)
        },
        calculateDueDateForProcess: (date) => {
            let dateStr = moment(date).format(DATE_SYSTEM_FORMAT)
            let tempDate = moment.utc(dateStr, DATE_SYSTEM_FORMAT).unix()

            return tempDate > 0 ? tempDate : 0
        }
    }

    return settings
}
