import utils from '../utils';

const PROCESS_STATUS = {
    ACTIVE: 'active',
    COMPLETED: 'completed'
};

export class ProcessesListBaseController {
    constructor($scope, $state, $auth, $location, $timeout, ProcessesSettings, ServerConfig, PageSettings, ApiCalls,
                DeferredAction, $log, PusherHelper, currentUser) {
        'ngInject';
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.PageSettings = PageSettings;
        this.ProcessesSettings = ProcessesSettings;
        this.DeferredAction = DeferredAction;
        this.ServerConfig = ServerConfig;
        this.PusherHelper = PusherHelper;

        this.busyLoading = {};
        this.listStub = Array.from({length: this.PageSettings.itemsPerPage}, (v, k) => ({id: k + 1}));
        this.nextOffsetIds = {active: 0, completed: 0};

        this.api = {
            active: () => $log.error('API action should be provided'),
            completed: () => $log.error('API action should be provided'),
            getProcess: ApiCalls.getProcess
        };

        this.storage = {
            completed: [],
            active: []
        };

        this.goTo = {
            taskView: (task, event) => {
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                $state.go('main.task', {id: task.id, url: $location.path()});
            },
            process: process => {
                if (!process.name) {
                    return;
                }
                $state.go('main.processView', {id: process.id, url: $location.path()});
            },
            template: template => {
                $state.go('main.template', {id: template.id, url: $location.path()});
            }
        };

        this.processUpdatedListener = this.processUpdatedListener.bind(this);

        if (!currentUser.companySettings.limitedRTU) {
            PusherHelper.subscribe('processUpdated', this.processUpdatedListener);

            $scope.$on('$destroy', () => {
                PusherHelper.unsubscribe('processUpdated', this.processUpdatedListener);
            });
        }
    }

    updateTemplateCounter(section, templateId, change = 0) {
        this.storage[section]
            .filter(obj => obj.template && obj.template.id === templateId)
            .forEach(obj => obj.template.processesRunningCount = obj.template.processesRunningCount + change);
    }

    removeFromStorage(section, processId) {
        this.storage[section] = this.storage[section].filter(obj => obj.id !== processId);
    }

    processUpdatedListener({processId, templateId, isDeleted, oldState, newState, updateInList, socketId}) {
        if (this.PusherHelper.checkIfEventShouldBeSkipped(socketId) || !updateInList) {
            return;
        }

        const activeSection = this.getActiveSectionId();

        let templateCounterChange = 0;

        const processesListChanged = new Promise((resolve, reject) => {
            if (oldState === PROCESS_STATUS.ACTIVE && (newState === PROCESS_STATUS.COMPLETED || newState === 'none')) {
                // running process is removed/completed - decrease counter
                templateCounterChange = -1;
            } else if ((oldState === PROCESS_STATUS.COMPLETED || oldState === 'none') && newState === PROCESS_STATUS.ACTIVE) {
                // new running process or completed process is reopened - increase counter
                templateCounterChange = 1;
            }
            this.updateTemplateCounter(activeSection, templateId, templateCounterChange);

            if (activeSection === 'active' && (oldState !== newState || newState !== 'active')) {
                this.$scope.$broadcast('processList:changed');
            }

            if (oldState !== activeSection && newState !== activeSection) {
                // Process from another section
                return reject();
            }

            if (isDeleted || newState !== activeSection) {
                // Process should be removed from current section
                this.removeFromStorage(activeSection, processId);
                return resolve();
            }

            const params = {id: processId};
            if (this.ProcessesSettings.processesGroup) {
                params.count = 1;
                params.groupBy = 1;
            }

            // Process should be inside current section
            this.api[activeSection](params)
                .then(data => {
                    if (!data.list.length) {
                        // Server returned empty list, process is not belongs to current section
                        this.removeFromStorage(activeSection, processId);
                        return resolve();
                    }

                    const processData = utils.getProcessesList(data)[0];

                    if (this.storage[activeSection].find(obj => obj.id === processId)) {
                        // Process is visible, e.g. it is already loaded
                        this.storage[activeSection] = this.storage[activeSection].map(obj => {
                            if (obj.id === processId) {
                                return {...processData};
                            }
                            return obj;
                        });

                        resolve();
                    } else {
                        // Cases:
                        // 1. Process is belongs to current section but not yet loaded.
                        // 2. Process is new item in this section
                        const lastLoadedIndex = this.storage[activeSection].length - 1;
                        const copyOfCurrentSection = [...this.storage[activeSection], processData];
                        const sortedList = utils.sortProcessesList(copyOfCurrentSection, activeSection, this.ProcessesSettings.processesGroup);
                        const processPosition = sortedList.findIndex(p => p.id === processData.id);

                        if (processPosition <= lastLoadedIndex) {
                            this.storage[activeSection].splice(processPosition, 0, processData);
                            resolve();
                        } else {
                            reject();
                        }
                    }
                });
        });

        const updateList = () => {
            this.buildTree(angular.copy(this.storage[activeSection]));
            this.triggerListChange();
        };

        processesListChanged.then(() => {
            updateList();
        }, () => {
            if (templateCounterChange !== 0) {
                // Update list to apply new template counters
                updateList();
            }
        });
    }

    getActiveSectionId() {
        return this.ProcessesSettings.activeFilterMode.id;
    }

    isSectionLoading() {
        return this.busyLoading[this.getActiveSectionId()];
    }

    scrollToTop() {
        if (this.container) {
            this.container.scrollTop = 0;
        }
    }

    prepareAllCounters(incomeList) {
        return this.ProcessesSettings.prepareAllProcessListCounters(incomeList, this.DeferredAction.TaskDelete.getAllDeferred());
    }

    buildTree(incomeList) {
        if (!incomeList.length) {
            this.processesTree = [];
            return;
        }

        if (this.ProcessesSettings.processesGroup === 1) {
            this.processesTree = utils.groupProcessesByTemplate(this.prepareAllCounters(incomeList));
            return;
        }

        this.processesTree = [{processes: this.prepareAllCounters(incomeList)}];
    }

    getApiParams(section) {
        const params = {
            count: this.PageSettings.itemsPerPage,
            groupBy: this.ProcessesSettings.processesGroup || null
        };
        if (this.ProcessesSettings.searchString) {
            params.searchString = this.ProcessesSettings.searchString;
        }
        if (section && this.nextOffsetIds[section] && this.nextOffsetIds[section] !== -1) {
            params.nextOffset = this.nextOffsetIds[section];
        }

        return params;
    }

    nextPage() {
        const section = this.getActiveSectionId();
        if (this.nextOffsetIds[section] === -1 || this.isSectionLoading()) {
            return;
        }
        const params = this.getApiParams(section);
        this.busyLoading[section] = true;
        this.api[section](params).then(data => {
            this.nextOffsetIds[section] = data.nextOffset || -1;
            if (data.list && data.list.length) {
                utils.mergeToArray(this.storage[section], utils.getProcessesList(data), true);
                this.buildTree(angular.copy(this.storage[section]));
                this.triggerListChange();
            } else {
                this.processesTree = [];
            }

            this.serverError = false;
        }, () => {
            this.serverError = true;
        }).finally(() => {
            this.$timeout(() => this.busyLoading[section] = false);
        });
    }

    firstPage(showListStub = false) {
        if (showListStub) {
            this.processesTree = [{processes: this.listStub.slice()}];
        }

        const section = this.getActiveSectionId();
        this.nextOffsetIds[section] = 0;
        this.storage[section] = [];
        this.scrollToTop();
        this.nextPage();
    }

    triggerListChange() {
        this.$timeout(() => this.$scope.$emit(`list:changed`), 100);
    }
}
