import colors from '../libs/colors';

export function userTooltip($translate, PageSettings) {
    'ngInject';

    return {
        restrict: 'E',
        scope: {
            actor: '&'
        },
        replace: true,
        template: `
            <div class="user-tooltip">
                <div class="user-tooltip__container">
                    <img class="avatar" ng-src="{{actor().avatarUrl + '_40'}}" height="40" width="40"
                         ng-if="actor().avatarUrl" alt=""/>
                    <div class="avatar" ng-if="!actor().avatarUrl" ng-style="{background: color}">
                        {{actor().fullName | initials}}
                    </div>
                    <div>
                        <span>{{actor().fullName}}</span>
                        <span ng-if="actor().groups">{{groupsStr}}</span>
                    </div>
                    <i class="arrow"></i>
                </div>
            </div>
        `,

        link: (scope) => {
            if (PageSettings.smallScreensMode()) {
                return;
            }
            let getGroupsStr = user => {
                if (user.groups && user.groups.length > 3) {
                    let count = user.groups.length - 3;
                    let begin = user.groups.slice(0, 3).map(group => group.name).join(', ');
                    let end = $translate.instant('text.andMoreGroups', {count}, 'messageformat');
                    return begin + ' ' + end;
                }
                return user.groups ? user.groups.map(group => group.name).join(', ') : null;
            };

            scope.color = colors[scope.actor().colorIndex - 1];
            scope.groupsStr = getGroupsStr(scope.actor());
        }
    };
}
