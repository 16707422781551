import moment from 'moment'

export function timeInput($timeout, DateHelper) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '=',
            isActive: '=',
            onUpdate: '=?',
            onEnter: '&?'
        },
        template: `
            <div class="time-input">
                <input type="text"
                       ng-model="ngModel"
                       ng-keypress="onKeyPress($event)"
                       ng-blur="onFocusLost()"
                       ng-focus="onFocus()"
                       ng-placeholder="{{ placeholder }}" />
            </div>
        `,
        link: (scope, element) => {
            const TIME_FORMAT = DateHelper.DATE_FORMATS().TIME_INPUT
            const defaultValue = moment('12:00', TIME_FORMAT)
            scope.placeholder = defaultValue.format(TIME_FORMAT)

            scope.$watch('isActive', val => {
                if (val) {
                    $timeout(() => {
                        element.find('input')[0].focus();
                    });
                }
            })

            scope.onFocus = () => {
                element.find('input')[0].select()
            }

            scope.onFocusLost = () => {
                if (scope.ngModel) {
                    const valueFixed = scope.ngModel.toLowerCase()
                    let time = moment(valueFixed, [TIME_FORMAT, TIME_FORMAT.replace('a', ''), TIME_FORMAT.replace('a', '').replace(':', '')])
                    if (!time.isValid()) {
                        time = defaultValue
                    }
                    scope.ngModel = time.format(TIME_FORMAT)
                }

                scope.onUpdate(scope.ngModel || null)
            }

            scope.onKeyPress = event => {
                event.stopPropagation();
                if (event.key === 'Enter' || event.key === 'Escape') {
                    element.find('input')[0].blur()
                }
            }
        }
    };
}
