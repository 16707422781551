export class ProfileMainController {
    constructor($scope, $timeout) {
        'ngInject';
        $scope.$on('email:verify', (e, data) => {
            this.verificationEmail = data;
            this.popupId = $timeout(() => {
                this.closePopup();
            }, 10 * 1000);
            if (e) {
                //
            }
        });
        this.closePopup = () => {
            if (this.verificationEmail) {
                this.verificationEmail = null;
            }
            $timeout.cancel(this.popupId);
        };
    }
}
