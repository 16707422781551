import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Button = ({ style = 'default', className, disabled = false, children, onClick, ...props }) => {
    let btnClass = classNames('btn', className, {
        'disabled': disabled,
        [`btn-${style}`]: !disabled
    })

    let onClickAction = onClick && !disabled
        ? () => onClick()
        : undefined

    return (
        <button
            type="button"
            className={btnClass}
            onClick={onClickAction}
            {...props}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    style: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export default Button
