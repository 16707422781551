import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import { Cell } from 'fixed-data-table-2'

import { FILES_LIST, USER_FIELD } from '../../../../services/data-types'
import { translate } from '../../../react/utils'
import FilePreview from '../../../../components/react/file-preview.jsx'
import UserLabel from '../../../../components/react/users/user-label.jsx'

function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const getHighlightedText = (text = '', highlight = '') => {
    const escapedString = escapeRegExp(highlight)
    const parts = text ? text.split(new RegExp(`(${escapedString})`, 'gi')) : []
    return (
        <>
            {parts.map((part, i) =>
                <span key={i}
                      className={clsx('text', { 'highlighted': part.toLowerCase() === highlight.toLowerCase() })}>
                    {part}
                </span>
            )}
        </>
    )
}

function GridCell ({ record, columnId, style, isHighlighted, isRequired, searchString = '' }) {
    const [isOpen, setIsOpen] = useState(false)
    const value = columnId && !record.isLoading
        ? record.values.find(v => v.columnId === columnId)
        : undefined

    let displayValue = value
        ? <span className="static">{getHighlightedText(value.displayValue, searchString)}</span>
        : null

    if (value && value.dataType === FILES_LIST && value.files.length) {
        displayValue = (
            <>
                <FilePreview
                    file={value.files[0]}
                    gallery={value.files}
                    isopen={isOpen}
                    onclose={() => setIsOpen(false)}
                    className={clsx({ 'has-more-files': value.files.length > 1 })}
                />
                {(value.files.length > 1) && (
                    <a className="more-files" onClick={() => setIsOpen(true)}>+{value.files.length - 1}</a>
                )}
            </>
        )
    }

    if (value && value.dataType === USER_FIELD) {
        displayValue = value.userValue ? <UserLabel user={value.userValue}/> : null
    }

    if (isRequired && (value && !value.displayValue)) {
        displayValue = <span className="static required">{translate('validation.required')}</span>
    }

    if (isRequired && !value) {
        displayValue = <span className="static muted">{translate('label.loading')}</span>
    }

    return (
        <Cell
            style={style}
            className={clsx('column-value-cell', { active: isHighlighted, invalid: value ? value.isInvalid : false })}
        >
            <span style={{ width: style.width }} className="column-value-cell-content">
                {displayValue}
            </span>
        </Cell>
    )
}

GridCell.propTypes = {
    record: PropTypes.object,
    columnId: PropTypes.string,
    style: PropTypes.object,
    isHighlighted: PropTypes.bool,
    isRequired: PropTypes.bool,
    searchString: PropTypes.string
}

export default observer(GridCell)
