import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import './form.less'

import {
    DATA_TYPE_OPTIONS,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    YES_NO,
    YES_NO_OPTIONS
} from '../../../../services/data-types'
import {
    currencyAsSelectOption,
    defaultValueAsSelectOption,
    itemsAsSelectOptions
} from '../../../../services/data-types-service'

import ModalDialog from '../../../../components/react/dialog/modal.jsx'
import SelectDropdown from '../../../../components/react/select-dropdown.jsx'
import Button from '../../../../components/react/button.jsx'
import OptionFormGroup from '../../../../components/react/data-form/option-form-group.jsx'
import FormGroup from '../../../../components/react/form/form-group.jsx'
import OptionsListEditor from '../../../../components/react/form/options-list-editor.jsx'
import NumberInput from '../../../../components/react/form/number-input.jsx'
import Checkbox from '../../../../components/react/checkbox.jsx'
import DataTypeSelector from '../../../../components/react/data-form/data-type-selector.jsx'

import { translate } from '../../../react/utils'

class ColumnForm extends React.Component {
    static propTypes = {
        column: PropTypes.object,
        onCancel: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            isSaving: false
        }
    }

    render () {
        const { column } = this.props
        const isOpen = column !== undefined
        const selectedDataType = DATA_TYPE_OPTIONS.find(({ value }) => isOpen && value === column.dataType)
        const { isSaving } = this.state

        return (
            <ModalDialog
                isOpen={isOpen}
                title={translate(isOpen && column.id ? 'columnForm.title.existed' : 'columnForm.title.new')}
                onCancel={this.onCancel}
                className={'column-form'}
            >
                {isOpen && (
                    <div className="column-settings">
                        <FormGroup label={translate('columnForm.label.columnName')} required error={column.nameError}>
                            <input type="text" value={column.name} onChange={this.onChangeName}/>
                        </FormGroup>
                        <FormGroup label={translate('columnForm.label.columnType')} controlClassName={'full'}>
                            {column.id ? (
                                <span className="static-text">
                                    <span className="option-icn">
                                        <i className={clsx('icn', selectedDataType.icon)}/>
                                    </span>
                                    {translate(selectedDataType.label)}
                                </span>
                            ) : (
                                <DataTypeSelector
                                    value={selectedDataType}
                                    onChange={(data) => this.onChangeDataType(data)}
                                />
                            )}
                        </FormGroup>

                        {this.renderOptionsControls(column)}

                        <div className="actions">
                            <Button
                                style="green"
                                disabled={!!(isSaving || !column.isChanged || column.nameError || (!column.settings || !column.settings.isValid))}
                                onClick={this.saveChanges}
                            >
                                {translate(isSaving ? 'columnForm.label.saving' : 'columnForm.label.saveChanges')}
                            </Button>

                            <Button onClick={this.onCancel}>{translate('label.cancel')}</Button>
                        </div>
                    </div>
                )}
            </ModalDialog>
        )
    }

    onChangeName = (event) => {
        const { value } = event.target
        this.props.column.changeName(value)
    }

    onChangeDataType = (type) => {
        this.props.column.changeDataType(type.value)
    }

    renderOptionsControls = ({ id, dataType, settings }) => {
        if (!settings) {
            return null
        }

        if (dataType === NUMERIC_VALUE) {
            return (
                <React.Fragment>
                    <OptionFormGroup
                        label={''}
                        controlClassName={'option-field-value full'}
                    >
                        <Checkbox
                            label={settings.isInteger.label}
                            // className="field-value-checkbox"
                            checked={settings.isIntegerChecked}
                            onChange={isInteger => settings.update({ isInteger })}
                            style={{ display: 'flex', alignItems: 'center' }}
                        />
                    </OptionFormGroup>
                    <OptionFormGroup
                        label={settings.precision.label}
                        error={settings.precision.error}
                        controlClassName={'option-field-value'}
                    >
                        <NumberInput
                            unsigned
                            disabled={settings.isIntegerChecked}
                            max={settings.precision.defaultValue}
                            value={settings.isIntegerChecked ? '' : settings.precision.displayValue}
                            onChange={precision => settings.update({ precision })}
                            style={{ width: 50 }}
                        />
                    </OptionFormGroup>
                </React.Fragment>
            )
        } else if (dataType === RADIO_SELECTOR) {
            return (
                <React.Fragment>
                    <OptionFormGroup
                        label={settings.allowedValues.label}
                        error={settings.allowedValues.error}
                        controlClassName={'option-field-value full'}
                        hint={translate('text.oneValuePerLine')}
                        required
                    >
                        <OptionsListEditor
                            model={settings.allowedValues}
                            onChange={allowedValues => settings.update({ allowedValues })}
                        />
                    </OptionFormGroup>
                    <OptionFormGroup
                        label={settings.defaultValue.label}
                        error={settings.defaultValue.error}
                        controlClassName={'option-field-value'}
                    >
                        <SelectDropdown
                            options={itemsAsSelectOptions([{}, ...settings.allowedValues.value])}
                            value={defaultValueAsSelectOption(settings.defaultValue.value)}
                            onChange={({ value }) => settings.update({ defaultValue: value })}
                        />
                    </OptionFormGroup>
                </React.Fragment>
            )
        } else if (dataType === MULTI_SELECTOR) {
            return (
                <OptionFormGroup
                    label={settings.allowedValues.label}
                    error={settings.allowedValues.error}
                    controlClassName={'option-field-value full'}
                    hint={translate('text.oneValuePerLine')}
                    required
                >
                    <OptionsListEditor
                        model={settings.allowedValues}
                        onChange={allowedValues => settings.update({ allowedValues })}
                    />
                </OptionFormGroup>
            )
        } else if (dataType === YES_NO) {
            return (
                <React.Fragment>
                    <OptionFormGroup
                        label={settings.defaultValue.label}
                        error={settings.defaultValue.error}
                        controlClassName={'option-field-value'}
                    >
                        <SelectDropdown
                            options={itemsAsSelectOptions(YES_NO_OPTIONS)}
                            value={defaultValueAsSelectOption(settings.defaultValue.value)}
                            onChange={({ value }) => settings.update({ defaultValue: value })}
                        />
                    </OptionFormGroup>
                </React.Fragment>
            )
        } else if (dataType === MONEY && id !== undefined) {
            return (
                <React.Fragment>
                    <OptionFormGroup
                        label={settings.currency.default.label}
                        error={settings.currency.default.error}
                        controlClassName={'option-field-value'}
                    >
                        <SelectDropdown
                            options={settings.currencies.map(currencyAsSelectOption)}
                            value={currencyAsSelectOption(settings.currency.default.value)}
                            onChange={({ value }) => settings.update({ defaultCurrencyId: value })}
                        />
                    </OptionFormGroup>
                </React.Fragment>
            )
        }

        return null
    }

    saveChanges = () => {
        const { column } = this.props
        this.setState({ isSaving: true })
        if (!column.id) {
            column.save().then(this.onCancel)
        } else {
            column.update().then(this.onCancel)
        }
    }

    onCancel = () => {
        this.setState({ isSaving: false })
        this.props.onCancel()
    }
}

export default observer(ColumnForm)
