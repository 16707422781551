import {connect} from '../store/connect';
import ExportDropdown from '../components/export-dropdown.jsx';
import {toggleExportDropdown} from '../store/actions';

const mapStateToProps = (state) => {
    return {
        isOpen: state.ui.exportDropdownIsOpen,
        isAnimated: state.ui.exportDropdownIsAnimated,
        progress: state.ui.exportProgress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDropdown: (state) => {
            dispatch(toggleExportDropdown({state}));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportDropdown);
