export function withoutDeferred(DeferredAction) {
    'ngInject';
    return (input, action) => {
        if (!input) {
            return input;
        }
        if (DeferredAction[action]) {
            return input.filter(item => !DeferredAction[action].isDeferred(item));
        }
    };
}
