export function errorRoute($stateProvider, $urlRouterProvider) {
    'ngInject';

    $stateProvider
        .state('error', {
            url: '/error',
            template: require('./index.html'),
            controller: 'ErrorController',
            controllerAs: 'errCtrl',
            params: {
                message: null,
                reason: null
            }
        })
        .state('errorNotFound', {
            url: '/notFound',
            template: require('./notFound.html'),
            controller: 'ErrorController',
            controllerAs: 'errCtrl'
        })
        .state('accountDisabled', {
            url: '/account-disabled',
            template: require('./index.html'),
            controller: 'ErrorController',
            controllerAs: 'errCtrl',
            params: {
                blocked: true
            }
        });

    $urlRouterProvider.otherwise('/');
}
