import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../../modules/react/utils'
import Icon from '../icon'
import { decodeGroupStatus, decodeTaskStatus } from '../../../modules/reports/store/constants'
import DateTimeFormatter from '../../../modules/reports/components/date-time-formatter'
import UserPrintView from './user-print-view'
import ActorsPrintView from './actors-print-view'
import cn from 'classnames'

const taskPrintView = ({ task }) => {
    const taskStatus = decodeTaskStatus(task.status)
    const isCompleted = ['COMPLETED', 'APPROVED', 'REJECTED'].includes(taskStatus)
    const isPending = taskStatus === 'INACTIVE_REVIEW'
    const isSkipped = taskStatus === 'SKIPPED'
    const statusLabel = `task.status.` + taskStatus

    return (
        <li key={task.id} className={'item task'}>
            <span className={'item-icon'}>
                {/*<Checkbox checked={isCompleted} disabled={true} />*/}
                <input type="checkbox" checked={isCompleted} readOnly={true}/>
                {/*{isCompleted ? <Icon name={'menu_checkmark'}/> : <span className={'rectangle'}/> }*/}
            </span>
            <div className={cn('item-title', { skipped: isSkipped })}>{task.name}</div>
            {!isSkipped && (
                <>
                    {task.dueDate !== undefined && (
                        <div className={'status-field'}>
                            <label>{translate('label.dueDate')}</label>
                            <DateTimeFormatter timestamp={task.dueDate} withTime={false}/>
                        </div>
                    )}
                    <div className={'status-field'}>
                        <label>
                            {translate('label.status')}
                        </label>
                        {isCompleted && (
                            <span>
                                {translate(statusLabel)} <DateTimeFormatter timestamp={task.completedDate} withTime={true}/>
                                {' '}
                                {translate('label.user.by')} <UserPrintView user={task.completedBy}/>
                            </span>
                        )}
                        {!isCompleted && isPending && task.isAssigned && (
                            <span>
                                {translate(statusLabel)}
                                {'. '}
                                {translate('label.willBeAssignedTo')} <UserPrintView user={task.assignee}/>
                            </span>
                        )}
                        {!isCompleted && !isPending && task.isAssigned && taskStatus !== 'ASSIGNED' && (
                            <span>
                                {translate(statusLabel)}
                                {'. '}
                                {translate('label.assigned')}
                                {' '}
                                <DateTimeFormatter timestamp={task.assignedDate} withTime={true}/>
                                {' '}
                                {translate('label.to')} <UserPrintView user={task.assignee}/>
                            </span>
                        )}
                        {!isCompleted && !isPending && task.isAssigned && taskStatus === 'ASSIGNED' && (
                            <span>
                                {translate('label.assigned')}
                                {' '}
                                <DateTimeFormatter timestamp={task.assignedDate} withTime={true}/>
                                {' '}
                                {translate('label.to')} <UserPrintView user={task.assignee}/>
                            </span>
                        )}
                        {!isCompleted && !task.isAssigned && (
                            <span>
                                {translate(statusLabel)}
                                {'. '}
                                {translate('label.notAssigned.candidates')}
                                {' '}
                                <ActorsPrintView actors={task.actors}/>
                            </span>
                        )}
                    </div>
                </>
            )}
        </li>
    )
}

const groupPrintView = ({ group }) => {
    const groupStatus = decodeGroupStatus(group.status)
    const statusLabel = group.isReviewInProgress ? 'groupItem.label.inReview' : `group.status.` + groupStatus
    const groupIsSkipped = groupStatus === 'SKIPPED'

    return (
        <li key={group.id} className={'item group'}>
            <span className={'item-icon'}>
                <Icon name={'template_folder'}/>
            </span>
            <div className={cn('item-title', { skipped: groupIsSkipped })}>{group.name}</div>
            {!groupIsSkipped && (
                <div className={'status-field'}>
                    <label>{translate('label.status')}</label>
                    <span>{translate(statusLabel)}</span>
                </div>
            )}
            <ProcessSubTreePrintView subTree={group.subTree} execution={group.execution}/>
        </li>
    )
}

function ProcessSubTreePrintView ({ subTree = [], execution, ...props }) {
    const steps = subTree.map(step => step.task ? taskPrintView(step) : groupPrintView(step))

    return React.createElement(execution === 'SEQUENTIAL' ? 'ol' : 'ul', props, steps)
}

ProcessSubTreePrintView.propTypes = {
    subTree: PropTypes.arrayOf(PropTypes.object),
    execution: PropTypes.string
}

export default ProcessSubTreePrintView
