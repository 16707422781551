import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { observer } from 'mobx-react'

import EntityIcon from '../../../components/react/icon/entity-icon.jsx'
import Tooltip from '../../react/tooltip.jsx'

import { getService, translate } from '../../react/utils'
import { DEFAULT_TABLE_ICON } from '../models'

import '../less/datatables.less'

const PAGE_SIZE = 20

class TablesList extends React.Component {
    static propTypes = {
        store: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)
    }

    render () {
        const { store } = this.props
        const currentUserIsAdmin = getService('currentUser').isAdmin
        const nextPage = Math.ceil(store.tables.size / PAGE_SIZE)

        return (
            <div className="datatables">
                <div className="datatables__header">
                    <div className="datatables__header__item">
                        <h3>{translate('title.database')}</h3>
                        {currentUserIsAdmin && (
                            <Tooltip text={translate('tooltips.createTable')}>
                                <a className="btn-switcher btn-primary-switcher" onClick={this.createTableAction}>
                                    <i className="icon-company_groups_add"/>
                                    <span>{translate('label.addNewTable')}</span>
                                </a>
                            </Tooltip>
                        )}
                    </div>
                    {/*<div className="datatables__header__item">*/}
                    {/*    <small style={{ color: '#eee' }}>Search [WIP]</small>*/}
                    {/*</div>*/}
                </div>
                {(store.tables.size > 0 || store.hasTablesToLoad) ? (
                    <div className="tables-list-scroller">
                        <InfiniteScroll
                            className="tables-list"
                            pageStart={nextPage}
                            loadMore={page => store.nextPage(PAGE_SIZE, page)}
                            hasMore={store.hasTablesToLoad}
                            useWindow={false}
                            loader={
                                <div className="loading-spinner" key={0}>
                                    <i><img src="/assets/loader.gif" height="15" width="15" alt=""/></i>
                                    {translate('label.loadingMore')}
                                </div>
                            }
                        >
                            {store.tablesOrdered.map(table => (
                                <a key={table.id} className="tables-list__item" href={`/database/${table.id}`}>
                                    <div className="table-icon">
                                        <EntityIcon
                                            icon={table.icon}
                                            defaultIcon={DEFAULT_TABLE_ICON.id}
                                            defaultColor={DEFAULT_TABLE_ICON.color}
                                        />
                                    </div>
                                    <div className="table-info">
                                        <div className="table-name"
                                             style={{ WebkitBoxOrient: 'vertical' }}>{table.name}</div>
                                        <div className="table-description"
                                             style={{ WebkitBoxOrient: 'vertical' }}>{table.description}</div>
                                    </div>
                                </a>
                            ))}
                        </InfiniteScroll>
                    </div>
                ) : (
                    <div className="datatables__empty-state">
                        <i className="icon-empty_tables_bw"></i>
                        <h5>{translate('text.database.emptyState')}</h5>
                        {currentUserIsAdmin && (
                            <p>
                                <a onClick={this.createTableAction}>{translate('text.database.createFirstTable')}</a>
                            </p>
                        )}
                    </div>
                )}
            </div>
        )
    }

    createTableAction = () => this.props.store.createNewTable()
}

export default observer(TablesList)
