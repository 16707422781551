import moment from 'moment-timezone'
import { DATE_SYSTEM_FORMAT, DATE_TIME_SYSTEM_FORMAT } from './constants'

export function MomentHelper (DateHelper, $translate, ServerConfig, currentUser) {
    'ngInject'

    const updateLocale = () => {
        moment.locale(currentUser.lang || 'en', {
            week: {
                dow: ServerConfig && ServerConfig.businessCalendar ? ServerConfig.businessCalendar.firstDayOfWeek : 1
            }
        })
        moment.relativeTimeThreshold('d', 7)  // 26
        moment.relativeTimeThreshold('w', 4)
    }

    updateLocale()

    const service = {
        fromNow: date => {
            updateLocale()
            let utcDate = DateHelper.getUtcFromTimestamp(date)
            return moment(utcDate).tz(DateHelper.getTZ()).fromNow()
        },
        fromNowStarted: (date) => {
            updateLocale()
            const utcDate = DateHelper.getUtcFromTimestamp(date)
            const tz = DateHelper.getTZ()
            const a = moment().tz(tz)
            const b = moment(utcDate).tz(tz)

            let result = $translate.instant('label.started') + ' '

            /*
            let years = a.diff(b, 'years')
            let months = a.diff(b, 'months') - 12 * years

            console.log('years', years)
            console.log('months', months)

            if (years) { // If years >= 1
                result = result + translate('plural.years', { COUNT: years }, true)

                if (months) {
                    result = result + ', ' + translate('plural.months', { COUNT: months }, true)
                }
                return result + ' ' + translate('label.ago')
            } else if (months) { // If months >= 1 && year == 0
                result = result + translate('plural.months', { COUNT: months }, true)

                let newB = moment(utcDate).tz(tz).add(months, 'months')
                let weeks = a.diff(newB, 'weeks')
                if (weeks) {
                    result = result + ', ' + translate('plural.weeks', { COUNT: weeks }, true)
                }
                return result + ' ' + translate('label.ago')
            } else { // If weeks >= 1
                let weeks = a.diff(b, 'weeks')
                let days = a.diff(b, 'days') - 7 * weeks

                console.log('weeks', weeks)
                console.log('days', days)

                if (weeks) {
                    result = result + translate('plural.weeks', { COUNT: weeks }, true)
                    if (days) {
                        result = result + ', ' + translate('plural.days', { COUNT: days }, true)
                    }
                    return result + ' ' + translate('label.ago')
                } else if (days) { // If days >= 1
                    result = result + translate('plural.days', { COUNT: days }, true)
                    return result + ' ' + translate('label.ago')
                } else { // If hours >= 1
                    let hours = a.diff(b, 'hours')
                    let minutes = a.diff(b, 'minutes') - 60 * hours

                    console.log('hours', hours)
                    console.log('minutes', minutes)

                    if (hours) {
                        result = result + translate('plural.hours', { COUNT: hours }, true)

                        if (minutes) {
                            result = result + ', ' + translate('plural.minutes', { COUNT: minutes }, true)
                        }
                        return result + ' ' + translate('label.ago')
                    } else if (minutes > 10) {
                        result = result + translate('plural.minutes', { COUNT: minutes }, true)
                        return result + ' ' + translate('label.ago')
                    }
                }
            }
            */
            // return result + b.fromNow();
            return result + b.from(a)
        },
        calendarDate: (date, isShort) => {
            updateLocale()
            const utcDate = DateHelper.getUtcFromTimestamp(date)
            const tz = DateHelper.getTZ()
            const DATE_FORMATS = DateHelper.DATE_FORMATS()

            let b = moment(utcDate).tz(tz)
            let a = moment().tz(tz)

            if (a.dayOfYear() === b.dayOfYear() && a.year() === b.year()) {
                return b.format(DATE_FORMATS.CALENDAR_TIME)
            }
            if (a.dayOfYear() - b.dayOfYear() < 2 && a.year() === b.year()) {
                return b.format(DATE_FORMATS.CALENDAR_DATE_YESTERDAY)
            }

            if (isShort) {
                return b.format(DATE_FORMATS.CALENDAR_DATE_SHORT)
            }

            return b.format(DATE_FORMATS.CALENDAR_DATE)
        },
        getUTCTimestampFromString: strDate => {
            return moment(new Date(strDate)).unix()
        },
        businessAdd: (momentDate, number) => {
            let remaining = Math.abs(number)
            while (remaining) {
                momentDate.add(1, 'days')
                if (!service.isNotBusinessDay(momentDate)) {
                    remaining--
                }
            }
            return momentDate
        },
        isNotBusinessDay: momentDate => {
            if (ServerConfig.businessCalendar.holidays
                && ServerConfig.businessCalendar.holidays
                    .find(d => moment.unix(d.date).utc().format(DATE_SYSTEM_FORMAT) === momentDate.format(DATE_SYSTEM_FORMAT))) {
                return true
            }
            return ServerConfig.businessCalendar.weekDays.find(d => d.day === momentDate.day()).nonWorking
        },
        dateToUnix: (date) => {
            return moment.utc(moment(date).format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).unix()
        },
        dateTimeToUnix: (date, tz) => {
            return moment.tz(moment(date).format(DATE_TIME_SYSTEM_FORMAT), DATE_TIME_SYSTEM_FORMAT, tz || DateHelper.getTZ()).unix()
        },
        formatInputDate: (date, format) => {
            updateLocale()
            return date ? moment.unix(date).format(format) : ''
        },
        formatInputDateTime: (date, format, tz) => {
            updateLocale()
            return date ? moment.unix(date).utc().tz(tz || DateHelper.getTZ()).format(format) : ''
        }
    }

    return service
}
