import Drop from 'tether-drop';

const DropContext = Drop.createContext({classPrefix: 'price-field'});

export function priceDataField($timeout) {
    'ngInject';

    let setViewValueTimeout

    return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
            field: '=',
            ngModel: '=',
            isDisabled: '=?',
            onFocus: '&?',
            currencies: '=?'
        },
        template: require('../templates/price-data-field.html'),
        link: (scope, element, attrs, ctrl) => {
            let dropIsOpen;
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.additional-currencies')[0],
                position: 'bottom right',
                openOn: 'click'
            });

            if (drop) {
                drop.on('open', () => {
                    dropIsOpen = true;
                });
                drop.on('close', () => {
                    dropIsOpen = false;
                    scope.saveField();
                });
            }

            if (scope.field && scope.field.name.options) {
                scope.currencies = [scope.field.name.options.currency.default, ...scope.field.name.options.currency.available];
            } else {
                scope.currencies = scope.currencies || [];
            }

            scope.activate = () => {
                scope.isActive = true;
                if (scope.onFocus) {
                    scope.onFocus();
                }
            };
            scope.saveField = () => {
                if (scope.isDisabled) {
                    return
                }
                console.log('moneyField save waiting started')
                setViewValueTimeout = $timeout(() => {
                    console.log('moneyField save waiting ended')
                    if (!dropIsOpen) {
                        const amount = scope.ngModel && scope.ngModel.amount ? scope.ngModel.amount : undefined
                        ctrl.$setViewValue(Object.assign({}, scope.ngModel, {amount}));
                    }
                    scope.isActive = false;
                }, 200);
            };

            scope.fieldKeyHandler = (event) => {
                if (event && event.which === 13) {
                    event.preventDefault();
                    scope.saveField();
                }
            };

            scope.getCurrencyName = cid => {
                if (!scope.currencies.length) {
                    return 'USD';
                } else if (!cid) {
                    let cur = scope.currencies[0];
                    return scope.currencies.length > 1 ? cur.code : cur.symbol || cur.code;
                }
                let active = scope.currencies.find(c => c.id === cid);
                if (active) {
                    return scope.currencies.length > 1 ? active.code : active.symbol || active.code;
                }
            };

            scope.getNumberOfUnits = () => {
                return scope.ngModel && scope.ngModel.currencyInfo
                    ? scope.ngModel.currencyInfo.numberOfUnits
                    : scope.currencies[0].numberOfUnits;
            };

            scope.checkUnit = option => {
                if (!scope.ngModel) {
                    return option.id === scope.currencies[0].id;
                }
                return option.id === scope.ngModel.currency;
            };
            scope.toggleUnit = option => {
                if (scope.ngModel && option.id !== scope.ngModel.currency) {
                    ctrl.$setViewValue(Object.assign({}, scope.ngModel, {
                        currency: option.id,
                        currencyInfo: option
                    }));
                }

                scope.close();
            };

            scope.close = () => {
                drop.close();
            };

            scope.$on('tether:close', scope.close);
            scope.$on('$destroy', () => {
                drop.destroy()
                $timeout.cancel(setViewValueTimeout)
            });
        }
    };
}
