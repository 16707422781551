
export function titleEditable($timeout, $window) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            title: '=mtTitle',
            onFocusOut: '&',
            minLength: '=',
            maxLength: '=',
            editPermission: '='

        },
        template: require('./title-editable.html'),
        link: (scope, element) => {
            scope.txtVal = scope.title;
            scope.onEnterClose = e => {
                if (e && e.which !== 13) {
                    return;
                }
                if (e) {
                    e.preventDefault();
                }
                $timeout(() => {
                    scope.onClose();
                });
            };
            scope.onClose = () => {
                if(!scope.isEdited) {
                    return;
                }
                if (!scope.txtVal || scope.txtVal.length < scope.minLength || scope.txtVal.length > scope.maxLength) {
                    scope.txtVal = scope.title;
                } else {
                    scope.title = scope.txtVal;
                }
                scope.isEdited = false;
                $timeout(() => {
                    scope.onFocusOut({title: scope.title});
                });
            };
            scope.showInput = () => {
                if(scope.editPermission) {
                    scope.isEdited = true;
                    $timeout(() => {
                        scope.$broadcast('elastic:adjust');
                        $timeout(() => {
                            let textArea = element.find('textarea')[0];
                            let styleTextArea = $window.getComputedStyle(textArea);
                            if (styleTextArea.display !== 'none') {
                                angular.element(textArea).focus();
                                if (textArea.selectionStart === 0 && textArea.selectionEnd === 0) {
                                    textArea.selectionStart = textArea.selectionEnd = 10000;
                                }
                                return;
                            }
                            let input = element.find('input')[0];
                            let styleInput = $window.getComputedStyle(input);
                            if (styleInput.display !== 'none') {
                                angular.element(input).focus();
                                if (input.selectionStart === 0 && input.selectionEnd === 0) {
                                    input.selectionStart = input.selectionEnd = 10000;
                                }
                            }
                        }, 30);
                    });
                }
            };
            scope.hideInput = () => {
                scope.isEdited = false;
            };
            scope.minLength = scope.minLength || 0;
            scope.isEdited = false;
            scope.$watch('maxLength', (val) => {
                if(val) {
                    element.find('textarea, input[type="text"]').attr('maxlength', val);
                }
            });
            scope.$watch('isEdited', (val) => {
                if(val) {
                    $timeout(() => {
                        let textAreaEl = element.find('textarea');
                        let inputEl = element.find('input[type="text"]');
                        if(textAreaEl.length) {
                            textAreaEl[0].focus();
                        } else if (inputEl.length) {
                            inputEl[0].focus();
                        }
                    }, 0);
                }
            });
            scope.$watch('title', () => {
                scope.txtVal = scope.title;
            });
        }
    };
}
