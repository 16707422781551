import React from 'react'
import { components } from 'react-select'
import UserAvatar from '../users/user-avatar.jsx'

function UserValue ({ children, data, ...props }) {
    let iconComponent = (
        <span className="value-icn">
            <UserAvatar user={data}/>
        </span>
    )

    return props.selectProps.isMulti ? (
        <components.MultiValueLabel {...props}>
            {iconComponent}
            <span className="value-label">
                {children}
            </span>
            {/* {removeAction && (
                <i tabIndex="-1" className="icon-common_close" role="button" onClick={() => removeAction(value)} />
            )} */}
        </components.MultiValueLabel>
    ) : (
        <components.SingleValue {...props}>
            {iconComponent}
            <span className="value-label">
                {children}
            </span>
        </components.SingleValue>
    )
}

export default UserValue
