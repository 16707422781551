export function adminInfoPopup(AdminSettings) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '='
        },
        template: `
            <div class="admin-info-popup">
                <i class="icon-common_close" ng-click="close()"></i>
                <i class="icon-toaster_mail"></i>
                <div>
                    <header>
                        {{'invite.text.invitationIsSent' | translate}} 
                        <span ng-if="ngModel.fullName">{{ngModel.fullName}}</span>
                        <span ng-if="ngModel.count">{{ngModel.count}} {{'invite.text.people' | translate}}</span>
                    </header>
                </div>
            </div>
        `,

        link: (scope) => {
            scope.close = () => {
                scope.ngModel = null;
            };
            scope.closeToInvited = () => {
                AdminSettings.activeFilterMode = AdminSettings.filterModes[0];
                scope.close();
            };
        }
    };
}
