import React from 'react'
import { components } from 'react-select'
import clsx from 'clsx'
import UserAvatar from '../users/user-avatar.jsx'

const UserOption = ({ children, data, ...props }) => {
    return (
        <components.Option {...props}>
            <span className={clsx('option-label', { 'group-item': data.isGroupItem })}>
                <span className="option-icn">
                    <UserAvatar user={data}/>
                </span>
                {children}
            </span>
        </components.Option>
    )
}

export default UserOption
