import config from '../../../config'
import stringEntropy from 'fast-password-entropy'

export class ProfilePasswordController {
    constructor($scope, profile, FormErrors, ServerConfig, ApiCalls, $timeout) {
        'ngInject';
        let timeoutId;
        this.profile = profile;

        this.checkField = fieldName => {
            if (!$scope.profileForm || !$scope.profileForm[fieldName]) {
                return;
            }
            return $scope.profileForm[fieldName].$invalid && $scope.profileForm[fieldName].$dirty;
        };
        this.fieldError = fieldName => {
            return $scope.profileForm && FormErrors($scope.profileForm, fieldName);
        };

        this.passwordIsWeak = undefined
        this.handleOnChange = () => {
            this.passwordIsWeak = this.newPassword && stringEntropy(this.newPassword) < config.minimal_entropy
            if (this.formHint) {
                this.formHint = null
            }
        }

        this.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        this.clearError = () => {
            if (this.serverError || this.formHint) {
                this.serverError = null;
                this.handleOnChange();
            }
        };

        this.formSubmit = () => {
            let form = $scope.profileForm;

            if (form.$valid && !this.isUpdating) {
                this.isUpdating = true;
                let postData = {
                    password: this.newPassword
                };
                if (this.profile.hasOldPassword) {
                    postData.currentPassword = this.oldPassword;
                }

                ApiCalls.changePassword(postData)
                    .then(data => {
                        if (data.success) {
                            this.formHint = data.msg;
                        }
                    })
                    .catch(errResponse => this.serverError = errResponse.data)
                    .finally(() => {
                        this.isUpdating = false;
                        if (timeoutId) {
                            $timeout.cancel(timeoutId);
                        }
                        timeoutId = $timeout(() => {
                            this.formHint = null;
                            this.serverError = null;
                        }, config.errorDelay);
                    });
            }
        };

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
        });
    }
}
