import React from 'react'
import PropTypes from 'prop-types'
import Drop from 'tether-drop'
import { BUTTONS } from '../../../services/constants'

class BaseDropdown extends React.Component {
    static propTypes = {
        label: PropTypes.node.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onFocusLost: PropTypes.func.isRequired,
        position: PropTypes.string,
        children: PropTypes.node,
        baseClassName: PropTypes.string.isRequired
    }

    static defaultProps = {
        isOpen: false,
        position: 'bottom left',
        baseClassName: ''
    }

    render () {
        const { label, children, isOpen, baseClassName, position, onFocusLost, ...props } = this.props

        return (
            <div ref={this._refTarget} {...props}>
                {label}
                <div className="dropdown-menu-wrapper" ref={this._refContent}>
                    {isOpen && children}
                </div>
            </div>
        )
    }

    componentDidMount () {
        this.drop = new Drop({
            target: this.target,
            content: this.content,
            position: this.props.position,
            classes: this.props.baseClassName,
            constrainToScrollParent: false,
            openOn: undefined
        })

        if (this.props.isOpen) {
            this.drop.open()
        }
    }

    componentDidUpdate (prevProps) {
        const { isOpen } = this.props
        if (isOpen && !prevProps.isOpen) {
            this.drop.open()
            this.addDocumentEventListeners()
        } else if (!isOpen && prevProps.isOpen) {
            this.drop.close()
            this.removeDocumentEventListeners()
        }

        if (isOpen) {
            this.drop.position()
        }
    }

    componentWillUnmount () {
        this.drop.destroy()
        this.removeDocumentEventListeners()
    }

    addDocumentEventListeners = () => {
        document.body.addEventListener('click', this.handleClick)
        document.body.addEventListener('touchstart', this.handleClick)
        document.body.addEventListener('keyup', this.handleKeyPress)
    }

    removeDocumentEventListeners = () => {
        document.body.removeEventListener('click', this.handleClick)
        document.body.removeEventListener('touchstart', this.handleClick)
        document.body.removeEventListener('keyup', this.handleKeyPress)
    }

    handleClick = (e) => {
        const clickOnContent = this.content.contains(e.target)
        const clickOnHeader = this.target.contains(e.target)

        if (!clickOnHeader && !clickOnContent) {
            this.onFocusLost()
        }
    }

    handleKeyPress = (e) => {
        if (e.key === BUTTONS.ESCAPE) {
            this.onFocusLost()
        }
    }

    onFocusLost = () => {
        this.props.onFocusLost()
    }

    _refTarget = (ref) => {
        this.target = ref
    }

    _refContent = (ref) => {
        this.content = ref
    }
}

export default BaseDropdown
