import moment from 'moment-timezone'

export function momentDate (DateHelper) {
    'ngInject'
    return (input, formatName, withoutTZ) => {
        if (!input) {
            return input
        }

        let date = moment.unix(input)
        if (!withoutTZ) {
            let tz = DateHelper.getTZ()
            date = date.tz(tz)
        } else {
            date = date.utc()
        }

        // TODO Remove logs
        if (!formatName) {
            console.error(`Format "${formatName}" not found`)
        }

        const format = DateHelper.DATE_FORMATS()[formatName || 'DATE_SHORT']

        return date.format(format)
    }
}
