import {connect} from '../store/connect';
import ReportLoader from '../components/report-loader.jsx';
import {fetchTemplatesAsync, fetchUsersAsync, openReport} from '../store/actions';
import {getCurrentReport, getTemplates} from '../store/selectors';

const mapStateToProps = (state, ownProps) => {
    const report = getCurrentReport(state);
    const templates = getTemplates(state);
    const {slug} = ownProps.$stateParams;

    return {
        report: report && report.slug === slug ? report : null,
        templates
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadReport: () => {
            dispatch(openReport(ownProps.$stateParams.slug));
            dispatch(fetchTemplatesAsync());
            dispatch(fetchUsersAsync());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportLoader);
