import {connect} from '../store/connect';
import ReportsList from '../components/reports-list.jsx';
import {fetchTemplatesAsync} from '../store/actions';

const mapStateToProps = state => {
    return {
        reports: state.reports
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onComponentMount: () => {
            dispatch(fetchTemplatesAsync());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportsList);
