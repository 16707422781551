import config from '../../../config'
import {
    findFieldsWithInvalidRecords,
    findSavingFields,
    generateProcessName,
    prepareTemplateModel,
    processStartDataModelToServer
} from '../utils'
import { RADIO_SELECTOR, USER_FIELD } from '../../../services/data-types'
import { EDITABLE, READ_ONLY, REQUIRED } from '../../../services/constants'

export class StartProcessModalController {
    constructor($scope, $modalInstance, $translate, $timeout, $q, $filter, $document,
                ApiCalls, FormErrors, DateHelper, PageSettings, Constants, ProcessesSettings, currentUser) {
        'ngInject';
        let timeoutId;
        const timezone = DateHelper.getTZ();
        $scope.FormErrors = FormErrors;
        $scope.isHideTopShadow = true;
        $scope.isHideBottomShadow = true;
        $scope.isLoaded = false;

        $scope.processNameMaxLength = {value: Constants.PROCESS.NAME_MAX_LENGTH};

        $timeout(() => {
            let el = document.getElementById('processName'); //eslint-disable-line
            if (el) {
                el.selectionStart = el.selectionEnd = el.value.length;
            }
        }, 150);

        $scope.truncate = (input) => {
            return $filter('truncate')(input);
        };

        const updateProcessName = (removePlaceholders) => {
            if (!$scope.isLoaded) {
                return '';
            }

            if ($scope.template.processStart.titleSettings.isAutoGenerated) {
                return generateProcessName($scope.template, timezone, removePlaceholders, currentUser);
            } else {
                return '';
            }
        };

        const dataModelFormInvalidFieldSelector = '.form-group.error, .dataModelTableRow.error';
        const dataModelFormHasInvalidFields = () => {
            return $scope.prNameForm.dataModelForm && $scope.prNameForm.dataModelForm.$$element
                && $scope.prNameForm.dataModelForm.$$element.find(dataModelFormInvalidFieldSelector).length > 0;
        };

        const getStaticProcessName = () => {
            return $scope.prNameForm && $scope.prNameForm.processName ? $scope.prNameForm.processName.$modelValue : '';
        };

        const prepareSettings = (settings) => {
            switch (settings.visibility) {
                case REQUIRED:
                    settings.isEditable = true
                    settings.isRequiredToFill = true
                    settings.isHidden = false
                    break
                case EDITABLE:
                    settings.isEditable = true
                    settings.isRequiredToFill = false
                    settings.isHidden = false
                    break
                case READ_ONLY:
                    settings.isEditable = false
                    settings.isRequiredToFill = false
                    settings.isHidden = false
                    break
                default:
                    settings.isEditable = false
                    settings.isRequiredToFill = false
                    settings.isHidden = true
            }
            delete settings.visibility
            return settings
        }

        const prepareInfoForStartProcess = (template) => {
            $scope.template = prepareTemplateModel(template, undefined, undefined, $translate);

            if ($scope.template.dueDateInterval) {
                const dueDate = ProcessesSettings.calculateDueDateFromInterval($scope.template.dueDateInterval);
                $scope.template.dueDate = ProcessesSettings.calculateDueDateForProcess(dueDate);
            }

            if (template.processStart && template.processStart.dataModelSettings) {
                let dataModelSettings = template.processStart.dataModelSettings
                $scope.template.dataModel.list.map(item => item.section).forEach(section => {
                    const fields = section.isTable ? section.columns : section.fieldsWithValues
                    fields.forEach(field => {
                        let dms = dataModelSettings.find(dmsItem => dmsItem.fieldId === field.name.id)
                        field.settings = dms && dms.settings
                        prepareSettings(field.settings)
                        if (field.name.dataType === RADIO_SELECTOR
                            && field.name.defaultValue
                            && field.name.defaultValue.radioButtonValue
                            && field.name.defaultValue.radioButtonValue.isInvalid) {
                            field.name.defaultValue = undefined
                        }
                    })
                })
            }

            $scope.isLoaded = true;
            $scope.generatedProcessName = updateProcessName();

            $timeout(() => {
                $scope.$watch(() => dataModelFormHasInvalidFields, () => {
                    let phase = $scope.$root.$$phase;
                    if (phase !== '$apply' && phase !== '$digest') {
                        $scope.$apply();
                    }
                });
            });
        };

        const processInfoErrorResponseHandler = (errorResponse) => {
            const NO_PERMISSION_ERROR = 473;
            const {status, data} = errorResponse;

            $scope.serverErrorData = null;
            $scope.isClicked = true;

            if (status === NO_PERMISSION_ERROR && data && data.displayError) {
                $scope.serverError = data.displayError;
            } else {
                $scope.serverError = $translate.instant('error.common');
            }
        };

        const templatePublishId = $scope.template.id;
        ApiCalls.getInfoForStartProcess(templatePublishId)
            .then(prepareInfoForStartProcess, processInfoErrorResponseHandler);

        $scope.isReady = () => {
            return !!($scope.isLoaded && ($scope.template.description || $scope.template.dataModel.list.length));
        };

        const getActualListOfUsersForFields = () => {
            ApiCalls.getInfoForStartProcess(templatePublishId).then(
                data => {
                    try {
                        data.dataModel.list.forEach((item, sectionIndex) => {
                            const {section} = item;
                            const fieldsListKey = section.isTable ? 'columns' : 'fieldsWithValues';
                            section[fieldsListKey].forEach((field, fieldIndex) => {
                                if (field.name.dataType === USER_FIELD) {
                                    const {allowedUsers} = field.name.options;
                                    $scope.template.dataModel.list[sectionIndex].section[fieldsListKey][fieldIndex].name.options.allowedUsers = allowedUsers;
                                }
                            });
                        });
                    } catch (e) {
                        return;
                    }
                },
                processInfoErrorResponseHandler
            );
        };

        $scope.ok = ($event) => {
            $event.preventDefault();
            if ($scope.isClicked || $scope.submitIsDisabled()) {
                return;
            }
            $scope.isClicked = true;
            if ($scope.prNameForm.$invalid) {
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
                timeoutId = $timeout(() => {
                    $scope.serverError = null;
                    $scope.isClicked = false;
                }, config.errorDelay);
                return;
            }
            let prData = {
                id: $scope.template.id,
                version: $scope.template.version,
                name: getStaticProcessName()
            };
            if ($scope.template.dataModel) {
                prData.dataModel = processStartDataModelToServer($scope.template.dataModel, $scope.template.processStart.dataModelSettings);
            }
            if ($scope.template.processStart.titleSettings.isAutoGenerated) {
                prData.name = updateProcessName(true);
            }
            if ($scope.template.dueDate) {
                prData.dueDate = $scope.template.dueDate;
            }

            ApiCalls.quickStartProcess(templatePublishId, prData, !!$scope.warnings).then(data => {
                if (data.warnings) {
                    $scope.isClicked = false;
                    $scope.warnings = data.warnings;
                } else if (data.success) {
                    $modalInstance.close(true);
                }
            }, errorResponse => {
                $scope.serverError = null;
                $scope.serverErrorData = null;
                let data = errorResponse.data;
                switch (errorResponse.status) {
                    case 516:
                        if (data && data.summaryError) {
                            $scope.serverError = data.summaryError;
                        } else {
                            $scope.serverError = $translate.instant('error.common');
                        }
                        break;
                    case 515:
                        $scope.serverError = $translate.instant('startProcess.errors.515');
                        break;
                    case 472:
                        $scope.serverError = $translate.instant('startProcess.errors.472');
                        break;
                    case 400:
                        if (data.dataModel) {
                            data.dataModel.list.forEach((item, sectionIndex) => {
                                if (!item.section) {
                                    return;
                                }

                                let listOfUsersOutdated = false;

                                if (item.section.fieldsWithValues) {
                                    item.section.fieldsWithValues.forEach((field, fieldIndex) => {
                                        const {value} = field;
                                        if (value && value.userValue && value.userValue.id && value.userValue.id[0]) {
                                            const dataModelField = $scope.template.dataModel.list[sectionIndex].section.fieldsWithValues[fieldIndex];
                                            dataModelField.value = {};
                                            dataModelField.name.options.allowedUsers = [];
                                            $scope.serverError = field.value.userValue.id[0].fieldError;
                                            listOfUsersOutdated = true;
                                        } else if (value && Object.keys(value)[0]) {
                                            const key = Object.keys(value)[0]
                                            $scope.serverError = field.value[key][0].fieldError;
                                        }
                                    });
                                }
                                if (item.section.rows) {
                                    item.section.rows.forEach((row, rowIndex) => {
                                        if (row.values) {
                                            row.values.forEach((field, fieldIndex) => {
                                                const {value} = field;
                                                if (value && value.userValue && value.userValue.id && value.userValue.id[0]) {
                                                    const dataModelField = $scope.template.dataModel.list[sectionIndex].section.columns[fieldIndex];
                                                    dataModelField.name.options.allowedUsers = [];
                                                    $scope.template.dataModel.list[sectionIndex].section.rows[rowIndex].values[fieldIndex].value = {};
                                                    $scope.serverError = field.value.userValue.id[0].fieldError;
                                                    listOfUsersOutdated = true;
                                                }
                                            });
                                        }
                                    });
                                }

                                if (listOfUsersOutdated) {
                                    getActualListOfUsersForFields();
                                }
                            });
                        } else {
                            $scope.serverErrorData = data;
                        }
                        break;
                    default:
                        if (data && data.displayError) {
                            $scope.serverError = $translate.instant('error.commonWithError', {error: data.displayError});
                        } else {
                            $scope.serverError = $translate.instant('error.common');
                        }
                }
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
                timeoutId = $timeout(() => {
                    $scope.serverError = null;
                    $scope.isClicked = false;
                }, config.errorDelay);
            });
        };

        $scope.getUIError = (form) => {
            if (!form || !form.processName) {
                return;
            }
            let mid = 'validation.text.fieldIsIncomplete';
            return $translate.instant('validation.text.startProcess') + ' '
                + 1 + ' ' + $translate.instant(mid) + '. '
                + $translate.instant('validation.text.fillItOut');
        };

        $scope.checkField = (form, fieldName) => {
            if (!form || !form[fieldName]) {
                return;
            }
            return form[fieldName].$invalid && form[fieldName].$dirty;
        };

        $scope.isInvalidDeadline = () => {
            return !$scope.template.dueDate && $scope.warnings;
        };

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        $scope.formIsInvalid = () => {
            let state = !$scope.isLoaded || ($scope.prNameForm.processName && $scope.prNameForm.processName.$error.required);

            if (dataModelFormHasInvalidFields()) {
                state = true;
            }

            return state;
        };

        $scope.submitIsDisabled = () => {
            return $scope.formIsInvalid()
                || findSavingFields($scope.template.dataModel, true).length > 0
                || findFieldsWithInvalidRecords($scope.template.dataModel).length > 0
        }

        $scope.processNameHasMaxLength = () => {
            const isAutoGenerated = $scope.isLoaded && $scope.template.processStart.titleSettings.isAutoGenerated;
            const processName = isAutoGenerated ? updateProcessName(true) : getStaticProcessName();
            return processName && processName.length === Constants.PROCESS.NAME_MAX_LENGTH;
        };

        $scope.isPhoneScreenMode = PageSettings.phoneScreenMode;

        $scope.$watch('template.dataModel', () => {
            $scope.generatedProcessName = updateProcessName();
        }, true);

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
        });

        $scope.onFieldChange = () => {
            $scope.$applyAsync();
        };
    }
}
