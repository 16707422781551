import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import Selectize from './selectize.jsx'

import './actors-selector.less'

class ActorsSelector extends React.Component {
    static propTypes = {
        actors: PropTypes.object.isRequired,
        specialRoles: PropTypes.array,
        model: PropTypes.object,
        onChange: PropTypes.func.isRequired
    }

    static defaultProps = {
        specialRoles: []
    }

    render () {
        const { actors, specialRoles } = this.props

        return (
            <Selectize
                actors={actors.getOptionsModel(specialRoles)}
                value={this.getValue()}
                withCreateGroup
                withInviteUser
                ignoreError403
                onChange={this.onChangeHandler}
                onCreateGroup={group => actors.addNewGroup(group)}
                // onCreateGroupError={(error) => console.log('onCreateGroupError', error)}
                onInviteUser={user => actors.addNewUser(user)}
            />
        )
    }

    onChangeHandler = ({ specialRoles = [], groups = [], users = [] }) => {
        const { onChange } = this.props
        const modelActors = {
            users: users.map(({ id }) => ({ id })),
            groups: groups.map(({ id }) => ({ id })),
            specialRoles
        }

        onChange(modelActors)
    }

    getValue = () => {
        const { actors, model } = this.props
        const values = {}

        if (model.specialRoles) {
            values.specialRoles = []
            model.specialRoles.forEach((role) => {
                const id = Object.keys(role).filter(k => k !== 'name')[0]
                const specialRole = actors.specialRoles.find(r => r[id])
                if (specialRole) {
                    values.specialRoles.push({ name: specialRole.name, [id]: true })
                }
            })
        }

        if (model.groups) {
            values.groups = []
            model.groups.forEach(({ id }) => {
                const group = actors.groups.find(g => g.id === id)
                if (group) {
                    values.groups.push(group)
                }
            })
        }

        if (model.users) {
            values.users = []
            model.users.forEach(({ id }) => {
                const user = actors.allUsers.find(u => u.id === id)
                if (user) {
                    values.users.push(user)
                }
            })
        }

        return values
    }
}

export default observer(ActorsSelector)
