import { flow, types } from 'mobx-state-tree'
import config from '../../../config'
import moment from 'moment-timezone'
import { getService, translate } from '../../react/utils'
import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import { BaseListItem, Currency } from './datatypes'
import { User, UserGroupBase } from './user'
import { FILES_ACCEPT_OPTIONS } from '../../../services/constants'

const { model, maybe, optional, string, boolean, number, array, compose, union } = types

// Low Level Options

const BaseOption = model(
    'BaseOption',
    { label: string, error: maybe(string) }
)

const BooleanOption = compose(
    'BooleanOption',
    BaseOption,
    model({
        value: maybe(boolean),
        isValid: optional(boolean, true)
    })
).views(self => ({
    get isChecked () {
        return self.value === true
    }
}))

const NumberOption = compose(
    'NumberOption',
    BaseOption,
    model({
        value: maybe(number),
        defaultValue: maybe(number),
    })
).views(self => ({
    get isValid () {
        return self.value === undefined || !self.error
    },
    get displayValue () {
        return self.value !== undefined ? self.value.toString() : ''
    }
}))

const DataTypeListOfLinesOption = compose(
    'DataTypeListOfLinesOption',
    BaseOption,
    model({
        value: optional(array(BaseListItem), []),
        maxLineLength: number,
        maxListLength: number
    })
).views(self => ({
    get isEmpty () {
        return self.asListOfValues.join('').length < 1
    },
    get isValid () {
        return !self.isEmpty && !self.error
    },
    get asListOfValues () {
        return self.value.map(({ item }) => item)
    }
})).actions(self => ({
    validate () {
        self.error = ''

        let uniqueItems = {}
        let hasNonUniqueItems = false
        let hasWrongPatternItems = false
        let hasWrongLengthItems = false

        self.value.forEach(option => {
            option.isValid = true
            let item = option.item
                .slice()
                .replace(new RegExp('&lt;', 'g'), '<')
                .replace(new RegExp('&gt;', 'g'), '>')
                .replace(new RegExp('&nbsp;', 'g'), ' ')
                .replace(new RegExp('&amp;', 'g'), '&')

            if (!item) {
                return
            }

            if (!item.match(config.pattern)) {
                hasWrongPatternItems = true
                option.isValid = false
            }

            if (item.length > self.maxLineLength) {
                hasWrongLengthItems = true
            }

            if (!uniqueItems[option.item]) {
                uniqueItems[option.item] = 1
            } else {
                hasNonUniqueItems = true
                self.value
                    .filter(opt => opt.item === option.item)
                    .forEach(opt => opt.isValid = false)
            }
        })

        if (hasWrongPatternItems) {
            self.error = translate('validation.textNumSpecial')
            return
        }

        if (hasNonUniqueItems) {
            self.error = translate('validation.field.options.unique')
            return
        }

        if (hasWrongLengthItems) {
            self.error = translate('validation.field.options.itemMaxlength')
            return
        }

        if (self.value.length > self.maxListLength) {
            self.error = translate('validation.field.options.maxlength', {value: self.maxListLength})
        }
    }
}))

const DataTypeStringOption = compose(
    'DataTypeStringOption',
    BaseOption,
    model({
        value: maybe(string)
    })
).views(self => ({
    get isValid () {
        return !!self.value && !self.error
    }
}))

const DataTypeDateOption = compose(
    'DataTypeDateOption',
    BaseOption,
    model({
        value: maybe(number)
    })
).views(self => ({
    get isValid () {
        return !self.value || !self.error
    }
}))

const CurrencyOption = model(
    'CurrencyOption',
    {
        default: compose(
            'DefaultCurrencyOption',
            BaseOption,
            model({
                value: maybe(Currency)
            })
        ),
        available: compose(
            'AvailableCurrencyOption',
            BaseOption,
            model({
                value: optional(array(Currency), [])
            })
        )
    }
)

const UserGroupOption = model(
    'UserGroupOption',
    {
        label: string,
        value: maybe(UserGroupBase)
    }
)

// High Level Options

const BaseDataTypeOptions = model(
    'BaseDataTypeOptions',
    {
        isChanged: optional(boolean, false)
    }
).actions(self => ({
    initialize () {},
    afterCreate () {
        self.initialize()
    },
    save () {
        self.isChanged = false
    }
}))

const StringValueOptions = compose(
    'StringValueOptions',
    BaseDataTypeOptions,
    model({
        minLength: NumberOption,
        maxLength: NumberOption
    })
).views(self => ({
        get isValid () {
            return self.minLength.isValid && self.maxLength.isValid
        }
    })
).actions(self => ({
    validate () {
        const { minLength, maxLength } = self
        const minLengthIsInvalid = !minLength.value || minLength.value < minLength.defaultValue || minLength.value > maxLength.defaultValue
        const maxLengthIsInvalid = !maxLength.value || maxLength.value < minLength.defaultValue || maxLength.value > maxLength.defaultValue

        minLength.error = ''
        maxLength.error = ''

        if (!minLengthIsInvalid && !maxLengthIsInvalid && minLength.value > maxLength.value) {
            minLength.error = translate('validation.field.string-minlength-valid')
            maxLength.error = translate('validation.field.string-maxlength-valid')
        }

        if (minLengthIsInvalid) {
            minLength.error = translate('validation.field.string-minlength', {min: minLength.defaultValue, max: maxLength.defaultValue})
        }

        if (maxLengthIsInvalid) {
            maxLength.error = translate('validation.field.string-maxlength', {min: minLength.defaultValue, max: maxLength.defaultValue})
        }
    },
    update ({ minLength, maxLength }) {
        if (minLength !== undefined) {
            self.minLength.value = minLength !== null ? minLength : undefined
            self.isChanged = true
        }
        if (maxLength !== undefined) {
            self.maxLength.value = maxLength !== null ? maxLength : undefined
            self.isChanged = true
        }
        self.validate()
    },
    toServerJSON () {
        return {
            minLength: self.minLength.value,
            maxLength: self.maxLength.value
        }
    }
}))

const SingleSelectValueOptions = compose(
    'SingleSelectValueOptions',
    BaseDataTypeOptions,
    model({
        allowedValues: DataTypeListOfLinesOption,
        defaultValue: DataTypeStringOption
    })
).views(self => ({
    get isValid () {
        return self.allowedValues.isValid
    }
})).actions(self => ({
    validate () {
        self.allowedValues.validate()
    },
    update ({ allowedValues, defaultValue }) {
        if (allowedValues !== undefined) {
            self.allowedValues.value = allowedValues.map(v => ({ item: v }))
            self.isChanged = true

            if (self.defaultValue.value && !self.allowedValues.value.find(({ item }) => item === self.defaultValue.value)) {
                self.defaultValue.value = undefined
            }
        }
        if (defaultValue !== undefined) {
            self.defaultValue.value = defaultValue || undefined
            self.isChanged = true
        }
        self.validate()
    },
    toServerJSON () {
        return {
            useTableAsSource: false,
            source: {
                allowedValues: self.allowedValues.value.filter(opt => opt.item)
            },
            defaultValue: {
                radioButtonValue: self.defaultValue.value
            }
        }
    }
}))

const MultiSelectValueOptions = compose(
    'MultiSelectValueOptions',
    BaseDataTypeOptions,
    model({
        allowedValues: DataTypeListOfLinesOption
    })
).views(self => ({
    get isValid () {
        return self.allowedValues.isValid
    }
})).actions(self => ({
    validate () {
        self.allowedValues.validate()
    },
    update ({ allowedValues }) {
        if (allowedValues !== undefined) {
            self.allowedValues.value = allowedValues.map(v => ({ item: v }))
            self.isChanged = true
        }
        self.validate()
    },
    toServerJSON () {
        return {
            useTableAsSource: false,
            source: {
                allowedValues: self.allowedValues.value.filter(opt => opt.item)
            }
        }
    }
}))

const BooleanValueOptions = compose(
    'BooleanValueOptions',
    BaseDataTypeOptions,
    model({
        defaultValue: DataTypeStringOption
    })
).views(() => ({
    get isValid () {
        return true
    }
})).actions(self => ({
    update ({ defaultValue }) {
        if (defaultValue !== undefined) {
            self.defaultValue.value = defaultValue || undefined
            self.isChanged = true
        }
    },
    toServerJSON () {
        return {
            defaultValue: {
                yesNoValue: self.defaultValue.value
            }
        }
    }
}))

const DateOnlyValueOptions = compose(
    'DateOnlyValueOptions',
    BaseDataTypeOptions,
    model({
        minValue: DataTypeDateOption,
        maxValue: DataTypeDateOption
    })
).views(self => ({
    get isValid () {
        return self.minValue.isValid && self.maxValue.isValid
    }
})).actions(self => {
    const MomentHelper = getService('MomentHelper')

    return {
        validate () {
            const { minValue, maxValue } = self

            minValue.error = ''
            maxValue.error = ''

            if (minValue.value > maxValue.value) {
                minValue.error = translate('validation.field.date-minvalue')
                maxValue.error = translate('validation.field.date-maxvalue')
            }
        },
        update ({ minValue, maxValue }) {
            if (minValue !== undefined) {
                self.minValue.value = MomentHelper.dateToUnix(minValue)
                self.isChanged = true
            }

            if (maxValue !== undefined) {
                self.maxValue.value = MomentHelper.dateToUnix(maxValue)
                self.isChanged = true
            }

            self.validate()
        },
        toServerJSON () {
            return {
                minValue: self.minValue.value,
                maxValue: self.maxValue.value
            }
        },
        formatInputDate (date, format) {
            return date ? MomentHelper.formatInputDate(date, format) : ''
        },
        parseInputDate (string, format) {
            const m = moment(string, format)
            if (m.isValid()) {
                return m.toDate()
            }
            return undefined
        }
    }
})

const DateTimeValueOptions = compose(
    'DateTimeValueOptions',
    BaseDataTypeOptions,
    model({
        minValue: DataTypeDateOption,
        maxValue: DataTypeDateOption
    })
).views(self => ({
    get isValid () {
        return self.minValue.isValid && self.maxValue.isValid
    }
})).actions(self => {
    const MomentHelper = getService('MomentHelper')
    return {
        validate () {
            const { minValue, maxValue } = self

            minValue.error = ''
            maxValue.error = ''

            if (minValue.value > maxValue.value) {
                minValue.error = translate('validation.field.date-minvalue')
                maxValue.error = translate('validation.field.date-maxvalue')
            }
        },
        update ({ minValue, maxValue }) {
            if (minValue !== undefined) {
                self.minValue.value = MomentHelper.dateTimeToUnix(minValue)
                self.isChanged = true
            }

            if (maxValue !== undefined) {
                self.maxValue.value = MomentHelper.dateTimeToUnix(maxValue)
                self.isChanged = true
            }

            self.validate()
        },
        toServerJSON () {
            return {
                minValue: self.minValue.value,
                maxValue: self.maxValue.value
            }
        },
        formatInputDate (date, format) {
            return date ? MomentHelper.formatInputDate(date, format) : ''
        },
        parseInputDate (string, format) {
            const m = moment(string, format)
            if (m.isValid()) {
                return m.toDate()
            }
            return undefined
        }
    }
})

const NumericValueOptions = compose(
    'NumericValueOptions',
    BaseDataTypeOptions,
    model({
        isInteger: BooleanOption,
        precision: NumberOption,
        minValue: NumberOption,
        maxValue: NumberOption
    })
)
    .views(self => ({
        get isValid () {
            return self.minValue.isValid && self.maxValue.isValid
        },
        get isIntegerChecked () {
            return self.isInteger.isChecked || (self.isInteger.value === undefined && self.precision.value === 0)
        }
    }))
    .actions(self => ({
        validate () {
            const { precision, minValue, maxValue } = self

            precision.error = ''
            minValue.error = ''
            maxValue.error = ''

            if (precision.value !== undefined && (precision.value < 0 || precision.value > precision.defaultValue)) {
                precision.error = translate('validation.field.number-precision', {precision: precision.defaultValue})
            }

            if (minValue.value > maxValue.value) {
                minValue.error = translate('validation.field.number-minvalue')
                maxValue.error = translate('validation.field.number-maxvalue')
            }
        },
        update ({ isInteger, ...attrs }) {
            Object.keys(attrs).forEach(key => {
                if (['precision', 'minValue', 'maxValue'].includes(key)) {
                    self[key].value = attrs[key] !== null ? attrs[key] : undefined
                    self.isChanged = true
                }
            })

            if (self.precision.value === undefined) {
                self.precision.value = 0
            }

            if (isInteger !== undefined) {
                self.isInteger.value = isInteger
                self.isChanged = true

                if (self.minValue.value !== undefined && isInteger) {
                    self.minValue.value = Number(self.minValue.value.toFixed(0))
                }

                if (self.maxValue.value !== undefined && isInteger) {
                    self.maxValue.value = Number(self.maxValue.value.toFixed(0))
                }
            }

            self.validate()
        },
        toServerJSON () {
            return {
                precision: self.isInteger.value ? 0 : self.precision.value,
                minValue: self.minValue.value,
                maxValue: self.maxValue.value
            }
        }
    }))

const MoneyValueOptions = compose(
    'MoneyValueOptions',
    BaseDataTypeOptions,
    model({
        minValue: NumberOption,
        maxValue: NumberOption,
        currency: CurrencyOption,
        currencies: optional(array(Currency), [])
    })
)
    .views(self => ({
        get isValid () {
            return self.minValue.isValid && self.maxValue.isValid
        },
        get controlSettings () {
            const currency = self.currency.default.value
            const currencies = self.currency.available.value

            const min = self.minValue ? self.minValue.value : undefined
            const max = self.maxValue ? self.maxValue.value : undefined

            return {
                currency,
                currencies: [currency, ...currencies],
                min,
                max
            }
        },
        get availableCurrencies () {
            const currency = self.currency.default.value
            return self.currencies.filter(c => !currency || c.id !== currency.id)
        }
    }))
    .actions(self => ({
        validate () {
            const { minValue, maxValue } = self

            minValue.error = ''
            maxValue.error = ''

            if (minValue.value > maxValue.value) {
                minValue.error = translate('validation.field.money-minvalue')
                maxValue.error = translate('validation.field.money-maxvalue')
            }
        },
        update ({ minValue, maxValue, defaultCurrencyId, availableCurrenciesIds }) {
            if (minValue !== undefined) {
                self.minValue.value = minValue !== null ? minValue : undefined
                self.isChanged = true
            }
            if (maxValue !== undefined) {
                self.maxValue.value = maxValue !== null ? maxValue : undefined
                self.isChanged = true
            }
            if (defaultCurrencyId !== undefined) {
                self.currency.default.value = self.currencies.find(c => c.id === defaultCurrencyId).toJSON()
                self.isChanged = true
            }
            if (availableCurrenciesIds !== undefined) {
                self.currency.available.value = self.currencies.filter(c => availableCurrenciesIds.includes(c.id)).map(c => c.toJSON())
                self.isChanged = true
            }

            self.validate()
        },
        toServerJSON () {
            return {
                currency: {
                    default: {
                        id: self.currency.default.value.id
                    },
                    available: []
                }
            }
        },
        initialize () {
            const companyCurrencies = getService('companyCurrencies')
            const allCurrencies = getService('allCurrencies')
            const newDefault = self.currency.default.value ? allCurrencies.find(a => a.id === self.currency.default.value.id) : undefined

            self.currencies = [companyCurrencies.defaultCurrency, ...companyCurrencies.availableCurrencies]
            self.currency.default.value = newDefault || companyCurrencies.defaultCurrency
            if (self.currency.available.value && self.currency.available.value.length) {
                self.currency.available.value = self.currency.available.value.map(a => allCurrencies.find(c => c.id === a.id))
            }
        }
    }))

const FilesListOptions = compose(
    'FilesListOptions',
    BaseDataTypeOptions,
    model({
        maxFiles: NumberOption,
        accept: DataTypeStringOption
    })
)
    .views(self => ({
        get isValid () {
            return self.maxFiles.value && self.maxFiles.isValid
        },
        get acceptOptions () {
            return FILES_ACCEPT_OPTIONS
        },
        get acceptSelectedOption () {
            return self.accept.value
                ? FILES_ACCEPT_OPTIONS.find(opt => opt.value === self.accept.value)
                : FILES_ACCEPT_OPTIONS[0]
        }
    }))
    .actions(self => ({
        validate () {
            const { maxFiles } = self

            maxFiles.error = ''

            if (!maxFiles.value || !(maxFiles.value >= 1 && maxFiles.value <= maxFiles.defaultValue)) {
                maxFiles.error = translate('validation.field.files-maxvalue', {max: maxFiles.defaultValue})
            }
        },
        update ({ maxFiles, accept }) {
            if (maxFiles !== undefined) {
                self.maxFiles.value = maxFiles !== null ? maxFiles : undefined
                self.isChanged = true
            }

            if (accept !== undefined) {
                self.accept.value = accept !== null ? accept : undefined
                self.isChanged = true
            }

            self.validate()
        },
        toServerJSON () {
            return {
                maxFiles: self.maxFiles.value || self.maxFiles.defaultValue,
                accept: self.accept.value || undefined
            }
        }
    }))

const EmptyOptions = compose(
    'EmptyOptions',
    BaseDataTypeOptions,
    model({
        isValid: optional(boolean, true)
    })
).views(self => ({})
).actions(() => ({
    toServerJSON () {
        return {}
    }
}))

const UserFieldOptions = compose(
    'UserFieldOptions',
    BaseDataTypeOptions,
    model({
        groups: optional(array(UserGroupBase), []),
        group: UserGroupOption,
        allUsers: optional(boolean, false),
        allowedUsers: optional(array(User), [])
    })
)
    .views(self => ({
        get isValid () {
            return true
        }
    }))
    .actions(self => {
        const ApiCalls = getService('ApiCalls')
        return {
            initialize () {
                let initialValue
                if (self.group.value) {
                    initialValue = Object.assign({}, self.group.value, { selected: true })
                    self.groups = [initialValue]
                }
                // fixme add initial value into groups
                // self.fetchGroups()
            },
            update ({ group }) {
                if (group !== undefined) {
                    const selected = group ? self.groups.find(g => g.id === group.value) : undefined
                    self.group.value = selected ? { ...selected.toJSON() } : undefined
                    self.allUsers = selected === undefined
                    self.isChanged = true
                }
            },
            toServerJSON () {
                if (self.group.value) {
                    return {
                        group: self.group.value.toJSON()
                    }
                } else {
                    return {
                        allUsers: true
                    }
                }
            },
            fetchGroups: flow(function * () {
                try {
                    const data = yield ApiCalls.getActorsGroups()
                    self.groups = data.map(group => {
                        return Object.assign({}, group)
                    })
                } catch (e) {
                    console.log('Fetch groups error:', e)
                }
            })
        }
    })

function dataTypeOptionsTypeWithId (optionsType, id) {
    return compose(
        id,
        optionsType,
        model({ dataType: types.literal(id) })
    )
}

export const DataTypeOptions = union({
    dispatcher ({ dataType } = {}) {
        switch (dataType) {
            case STRING_SINGLE_LINE:
                return dataTypeOptionsTypeWithId(StringValueOptions, STRING_SINGLE_LINE)
            case STRING_MULTI_LINE:
                return dataTypeOptionsTypeWithId(StringValueOptions, STRING_MULTI_LINE)
            case EMAIL:
                return dataTypeOptionsTypeWithId(EmptyOptions, EMAIL)
            case RADIO_SELECTOR:
                return dataTypeOptionsTypeWithId(SingleSelectValueOptions, RADIO_SELECTOR)
            case MULTI_SELECTOR:
                return dataTypeOptionsTypeWithId(MultiSelectValueOptions, MULTI_SELECTOR)
            case YES_NO:
                return dataTypeOptionsTypeWithId(BooleanValueOptions, YES_NO)
            case DATE_ONLY_VALUE:
                return dataTypeOptionsTypeWithId(DateOnlyValueOptions, DATE_ONLY_VALUE)
            case DATE_AND_TIME:
                return dataTypeOptionsTypeWithId(DateTimeValueOptions, DATE_AND_TIME)
            case NUMERIC_VALUE:
                return dataTypeOptionsTypeWithId(NumericValueOptions, NUMERIC_VALUE)
            case MONEY:
                return dataTypeOptionsTypeWithId(MoneyValueOptions, MONEY)
            case FILES_LIST:
                return dataTypeOptionsTypeWithId(FilesListOptions, FILES_LIST)
            case USER_FIELD:
                return dataTypeOptionsTypeWithId(UserFieldOptions, USER_FIELD)
            default:
                return dataTypeOptionsTypeWithId(EmptyOptions, 'EMPTY')
        }
    }
})
