import moment from 'moment-timezone'
import { DATE_SERVICE_FORMAT } from '../modules/reports/store/constants'
/* eslint-disable */
var Module = angular.module('datePicker', [])

Module.constant('datePickerConfig', {
    template: 'templates/datepicker.html',
    view: 'month',
    views: ['year', 'month', 'date', 'hours', 'minutes'],
    momentNames: {
        year: 'year',
        month: 'month',
        date: 'day',
        hours: 'hours',
        minutes: 'minutes'
    },
    viewConfig: {
        year: ['years', 'isSameYear'],
        month: ['months', 'isSameMonth'],
        hours: ['hours', 'isSameHour'],
        minutes: ['minutes', 'isSameMinutes']
    },
    step: 5
})

//Moment format filter.
Module.filter('mFormat', function (currentUser) {
    'ngInject'
    moment.locale(currentUser?.lang || 'en')
    return function (m, format, tz) {
        if (!(moment.isMoment(m))) {
            return moment(m).format(format)
        }
        return tz ? moment.utc(m).tz(tz).format(format) : m.format(format)
    }
})

Module.directive('datePicker', ['datePickerConfig', 'datePickerUtils', 'DateHelper', 'ServerConfig', '$translate',
    function datePickerDirective (datePickerConfig, datePickerUtils, DateHelper, ServerConfig, $translate) {

        const SERVICE_FORMAT = 'DD MMM YYYY'

        //noinspection JSUnusedLocalSymbols
        return {
            // this is a bug ?
            require: '?ngModel',
            template: '<div ng-include="template"></div>',
            scope: {
                model: '=datePicker',
                after: '=?',
                before: '=?',
                isOpen: '=?'
            },
            link: function (scope, element, attrs, ngModel) {
                function prepareViews () {
                    scope.views = datePickerConfig.views.concat()
                    scope.view = attrs.view || datePickerConfig.view

                    scope.views = scope.views.slice(
                        scope.views.indexOf(attrs.maxView || 'year'),
                        scope.views.indexOf(attrs.minView || 'minutes') + 1
                    )

                    if (scope.views.length === 1 || scope.views.indexOf(scope.view) === -1) {
                        scope.view = scope.views[0]
                    }
                }

                let checkDate = date => {
                    if (date && date.format('DD') < 7) {
                        return date.clone().add(1, 'week')
                    }
                    return date
                }

                scope.tz = DateHelper.getTZ()
                scope.monthsList = moment.months()
                let yearMax = 2100
                scope.yearsList = new Array(attrs.isDue ? 121 : 201)
                for (let i = 0; i < scope.yearsList.length; i++) {
                    scope.yearsList[i] = yearMax - i
                }

                datePickerUtils.setParams(scope.tz)
                var arrowClick = false,
                    tz = scope.tz,
                    createMoment = datePickerUtils.createMoment,
                    step = parseInt(attrs.step || datePickerConfig.step, 10),
                    partial = !!attrs.partial,
                    minDate = scope.after ? (attrs.withTz ? moment.unix(scope.after).utc().tz(tz) : moment.unix(scope.after).utc()) : null,
                    maxDate = scope.before ? (attrs.withTz ? moment.unix(scope.before).utc().tz(tz) : moment.unix(scope.before).utc()) : null,
                    now = scope.now = createMoment(),
                    selected = scope.date = createMoment(scope.model
                    || !inValidRange(now) ? checkDate(minDate) || checkDate(maxDate) : now),
                    autoclose = attrs.autoClose === 'true'

                if (!scope.model) {
                    selected.minute(Math.ceil(selected.minute() / step) * step).second(0)
                }
                setTimeout(() => {
                    element[0].querySelector('.months').scrollTop
                        = (Number(selected.format('MM')) - 2) * 26
                    element[0].querySelector('.months').scrollTop
                        = (scope.yearsList.indexOf(Number(selected.format('YYYY'))) - 2) * 26
                })

                scope.template = attrs.template || datePickerConfig.template

                scope.watchDirectChanges = attrs.watchDirectChanges !== undefined
                scope.callbackOnSetDate = attrs.dateChange ? datePickerUtils.findFunction(scope, attrs.dateChange) : undefined

                prepareViews()

                scope.setView = function (nextView) {
                    if (scope.views.indexOf(nextView) !== -1) {
                        scope.view = nextView
                    }
                }

                scope.selectDate = function (date) {
                    if (attrs.disabled || !inValidRange(date)) {
                        return false
                    }
                    if (isSame(scope.date, date)) {
                        date = scope.date
                    }
                    date = clipDate(date)
                    if (!date) {
                        return false
                    }
                    scope.date = date

                    var nextView = scope.views[scope.views.indexOf(scope.view) + 1]
                    if ((!nextView || partial) || scope.model) {
                        setDate(date)
                    }

                    if (nextView) {
                        scope.setView(nextView)
                    } else if (autoclose) {
                        element.addClass('hidden')
                        scope.$emit('hidePicker')
                    } else {
                        prepareViewData()
                    }
                }

                scope.updateMYTemps = () => {
                    scope.activeMonthT = scope.date.month()
                    scope.activeYearT = scope.date.year()
                    scope.yearMonthIsActive = !scope.yearMonthIsActive
                    setTimeout(() => {
                        element[0].querySelector('.months').scrollTop
                            = (Number(scope.date.format('MM')) - 4) * 27
                        element[0].querySelector('.years').scrollTop
                            = (scope.yearsList.indexOf(Number(scope.date.format('YYYY'))) - 3) * 27
                    })
                }

                scope.setActiveYearT = year => {
                    scope.activeYearT = year
                }

                scope.setActiveMonthT = m => {
                    scope.activeMonthT = m
                }

                scope.updateDateWithMYT = () => {
                    let date = moment(scope.date)
                    date.month(scope.activeMonthT)
                    date.year(scope.activeYearT)
                    scope.selectDate(date)
                    scope.yearMonthIsActive = false
                }

                scope.$on('reset:datepicker', () => {
                    scope.yearMonthIsActive = false
                })

                function setDate (date) {
                    if (date) {
                        scope.model = date
                        if (ngModel) {
                            ngModel.$setViewValue(date)
                        }
                    }
                    scope.$emit('setDate', scope.model, scope.view)

                    //This is duplicated in the new functionality.
                    if (scope.callbackOnSetDate) {
                        scope.callbackOnSetDate(attrs.datePicker, scope.date)
                    }
                }

                function update () {
                    var view = scope.view
                    datePickerUtils.setParams(tz)

                    if (scope.model && !arrowClick) {
                        scope.date = createMoment(scope.model)
                        arrowClick = false
                    }

                    var date = scope.date

                    switch (view) {
                        case 'year':
                            scope.years = datePickerUtils.getVisibleYears(date)
                            break
                        case 'month':
                            scope.months = datePickerUtils.getVisibleMonths(date)
                            break
                        case 'date':
                            scope.weekdays = scope.weekdays || datePickerUtils.getDaysOfWeek()
                            scope.weeks = datePickerUtils.getVisibleWeeks(date)
                            break
                        case 'hours':
                            scope.hours = datePickerUtils.getVisibleHours(date)
                            break
                        case 'minutes':
                            scope.minutes = datePickerUtils.getVisibleMinutes(date, step)
                            break
                    }

                    prepareViewData()
                }

                function watch () {
                    if (scope.view !== 'date') {
                        return scope.view
                    }
                    return scope.date ? scope.date.month() : null
                }

                scope.$watch(watch, update)

                if (scope.watchDirectChanges) {
                    scope.$watch('model', function (newV, oldV) {
                        if (oldV && !newV) {
                            scope.date = createMoment(!inValidRange(now) ? checkDate(minDate) || checkDate(maxDate) : now)
                        }
                        arrowClick = false
                        update()
                    })
                }

                function prepareViewData () {
                    var view = scope.view,
                        date = scope.date,
                        classes = [], classList = '',
                        i, j

                    datePickerUtils.setParams(tz)

                    if (view === 'date') {
                        var weeks = scope.weeks, week
                        for (i = 0; i < weeks.length; i++) {
                            week = weeks[i]
                            classes.push([])
                            for (j = 0; j < week.length; j++) {
                                classList = ''
                                if (scope.model && datePickerUtils.isSameDay(scope.model, week[j])) {
                                    classList += 'active'
                                }
                                if (isNow(week[j], view)) {
                                    classList += ' now'
                                }
                                if (isHoliday(week[j], view)) {
                                    classList += ' holiday'
                                }
                                //if (week[j].month() !== date.month()) classList += ' disabled';
                                if (week[j].month() !== date.month()) {
                                    classList += ' disabled'
                                }
                                if (!inValidRange(week[j])) {
                                    if (!inValidMaxRange(week[j])) {
                                        classList += ' disabled-max'
                                    }
                                    if (!inValidMinRange(week[j])) {
                                        classList += ' disabled-min'
                                    }
                                }
                                classes[i].push(classList)
                            }
                        }
                    } else {
                        var params = datePickerConfig.viewConfig[view],
                            dates = scope[params[0]],
                            compareFunc = params[1]

                        for (i = 0; i < dates.length; i++) {
                            classList = ''
                            if (datePickerUtils[compareFunc](date, dates[i])) {
                                classList += 'active'
                            }
                            if (isNow(dates[i], view)) {
                                classList += ' now'
                            }
                            if (isHoliday(week[j], view)) {
                                classList += ' holiday'
                            }
                            if (!inValidRange(dates[i])) {
                                classList += ' disabled'
                            }
                            classes.push(classList)
                        }
                    }
                    scope.classes = classes
                }

                scope.getHint = cs => {
                    if (cs.indexOf('disabled-min') !== -1 && minDate) {
                        return $translate.instant(
                            'validation.text.datesLater',
                            { date: minDate.clone().subtract(1, 'days').format(DateHelper.DATE_FORMATS().DATE_INPUT) }
                        )
                    }
                    if (cs.indexOf('disabled-max') !== -1 && maxDate) {
                        return $translate.instant(
                            'validation.text.datesLess',
                            { date: maxDate.clone().add(1, 'days').format(DateHelper.DATE_FORMATS().DATE_INPUT) }
                        )
                    }
                    return null
                }

                scope.next = function (delta) {
                    var date = moment(scope.date)
                    delta = delta || 1
                    switch (scope.view) {
                        case 'year':
                        /*falls through*/
                        case 'month':
                            date.year(date.year() + delta)
                            break
                        case 'date':
                            date.month(date.month() + delta)
                            break
                        case 'hours':
                        /*falls through*/
                        case 'minutes':
                            date.hours(date.hours() + delta)
                            break
                    }
                    // date = clipDate(date);
                    if (date) {
                        scope.date = date
                        arrowClick = true
                        update()
                    }
                }

                function inValidMinRange (date) {
                    let valid = true
                    const newDate = moment.utc(date.format(SERVICE_FORMAT), SERVICE_FORMAT)
                    const minDateForCompare = minDate ? moment.utc(minDate.format(SERVICE_FORMAT), SERVICE_FORMAT) : minDate
                    if (minDateForCompare && minDateForCompare.isAfter(newDate)) {
                        valid = isSame(minDateForCompare, newDate)
                    }
                    return valid
                }

                function inValidMaxRange (date) {
                    let valid = true
                    const newDate = moment.utc(date.format(SERVICE_FORMAT), SERVICE_FORMAT)
                    const maxDateForCompare = maxDate ? moment.utc(maxDate.format(SERVICE_FORMAT), SERVICE_FORMAT) : null
                    if (maxDateForCompare && maxDateForCompare.isBefore(newDate)) {
                        valid &= isSame(maxDateForCompare, newDate)
                    }
                    return valid
                }

                function inValidRange (date) {
                    let valid = true
                    const newDate = moment.utc(date.format(SERVICE_FORMAT), SERVICE_FORMAT)
                    const minDateForCompare = minDate ? moment.utc(minDate.format(SERVICE_FORMAT), SERVICE_FORMAT) : null
                    const maxDateForCompare = maxDate ? moment.utc(maxDate.format(SERVICE_FORMAT), SERVICE_FORMAT) : null
                    if (minDateForCompare && minDateForCompare.isAfter(newDate)) {
                        valid = isSame(minDateForCompare, newDate)
                    }
                    if (maxDateForCompare && maxDateForCompare.isBefore(newDate)) {
                        valid &= isSame(maxDateForCompare, newDate)
                    }
                    return valid
                }

                function isSame (date1, date2) {
                    return !!date1.isSame(date2, datePickerConfig.momentNames[scope.view])
                }

                function clipDate (date) {
                    if (minDate && minDate.isAfter(date)) {
                        return minDate
                    } else if (maxDate && maxDate.isBefore(date)) {
                        return maxDate
                    } else {
                        return date
                    }
                }

                function isNow (date, view) {
                    var is = true

                    switch (view) {
                        case 'minutes':
                            is &= ~~(now.minutes() / step) === ~~(date.minutes() / step)
                        /* falls through */
                        case 'hours':
                            is &= now.hours() === date.hours()
                        /* falls through */
                        case 'date':
                            is &= now.date() === date.date()
                        /* falls through */
                        case 'month':
                            is &= now.month() === date.month()
                        /* falls through */
                        case 'year':
                            is &= now.year() === date.year()
                    }
                    return is
                }

                function isHoliday (date, view) {
                    if (view === 'date') {
                        if (ServerConfig.businessCalendar.holidays && date
                            && ServerConfig.businessCalendar.holidays
                                .find(d => moment.unix(d.date).utc().format(SERVICE_FORMAT) === date.format(SERVICE_FORMAT))) {
                            return true
                        }
                        return ServerConfig.businessCalendar.weekDays.find(d => d.day === date.day()).nonWorking
                    }
                }

                scope.prev = function (delta) {
                    return scope.next(-delta || -1)
                }
            }
        }
    }])

Module.factory('datePickerUtils', ['DateHelper', (DateHelper) => {
    var tz = DateHelper.getTZ()
    var createNewDate = function (year, month, day, hour, minute) {
        var utc = Date.UTC(year | 0, month | 0, day | 0, hour | 0, minute | 0)
        return moment(utc).utc().tz(tz)
    }

    return {
        getVisibleMinutes: function (m, step) {
            var year = m.year(),
                month = m.month(),
                day = m.date(),
                hour = m.hours(), pushedDate,
                offset = m.utcOffset() / 60,
                minutes = [], minute

            for (minute = 0; minute < 60; minute += step) {
                pushedDate = createNewDate(year, month, day, hour - offset, minute)
                minutes.push(pushedDate)
            }
            return minutes
        },
        getVisibleWeeks: function (m) {
            m = moment(m)
            var startYear = m.year(),
                startMonth = m.month()

            //Set date to the first day of the month
            m.date(1)

            //Grab day of the week
            var day = m.day()

            if (day === 0) {
                //If the first day of the month is a sunday, go back one week.
                m.date(-6)
            } else {
                //Otherwise, go back the required number of days to arrive at the previous sunday
                m.date(1 - day)
            }

            var weeks = []

            while (weeks.length < 6) {
                if (m.year() === startYear && m.month() > startMonth) {
                    break
                }
                weeks.push(this.getDaysOfWeek(m))
                m.add(7, 'd')
            }
            return weeks
        },
        getVisibleYears: function (d) {
            var m = moment(d),
                year = m.year()

            m.year(year - (year % 10))
            year = m.year()

            var offset = m.utcOffset() / 60,
                years = [],
                pushedDate,
                actualOffset

            for (var i = 0; i < 12; i++) {
                pushedDate = createNewDate(year, 0, 1, 0 - offset)
                actualOffset = pushedDate.utcOffset() / 60
                if (actualOffset !== offset) {
                    pushedDate = createNewDate(year, 0, 1, 0 - actualOffset)
                    offset = actualOffset
                }
                years.push(pushedDate)
                year++
            }
            return years
        },
        getDaysOfWeek: function (m) {
            m = m ? m : (tz ? moment.utc().tz(tz).day(0) : moment().day(0))

            var year = m.year(),
                month = m.month(),
                day = m.date(),
                days = [],
                pushedDate,
                utcOffset = m.utcOffset() / 60,
                tzOffset

            for (var i = 0; i < 7; i++) {
                pushedDate = createNewDate(year, month, day, 0 - utcOffset, 0, false)
                tzOffset = pushedDate.utcOffset() / 60
                if (tzOffset < utcOffset) {
                    pushedDate = createNewDate(year, month, day, 0 - tzOffset, 0, false)
                }
                days.push(pushedDate)
                day++
            }
            return days
        },
        getVisibleMonths: function (m) {
            var year = m.year(),
                offset = m.utcOffset() / 60,
                months = [],
                pushedDate,
                actualOffset

            for (var month = 0; month < 12; month++) {
                pushedDate = createNewDate(year, month, 1, 0 - offset, 0, false)
                actualOffset = pushedDate.utcOffset() / 60
                if (actualOffset !== offset) {
                    pushedDate = createNewDate(year, month, 1, 0 - actualOffset, 0, false)
                }
                months.push(pushedDate)
            }
            return months
        },
        getVisibleHours: function (m) {
            var year = m.year(),
                month = m.month(),
                day = m.date(),
                hours = [],
                hour, pushedDate, actualOffset,
                offset = m.utcOffset() / 60

            for (hour = 0; hour < 24; hour++) {
                pushedDate = createNewDate(year, month, day, hour - offset, 0, false)
                actualOffset = pushedDate.utcOffset() / 60
                if (actualOffset !== offset) {
                    pushedDate = createNewDate(year, month, day, hour - actualOffset, 0, false)
                }
                hours.push(pushedDate)
            }

            return hours
        },
        isAfter: function (model, date) {
            return model && model.unix() >= date.unix()
        },
        isBefore: function (model, date) {
            return model.unix() <= date.unix()
        },
        isSameYear: function (model, date) {
            return model && model.year() === date.year()
        },
        isSameMonth: function (model, date) {
            return this.isSameYear(model, date) && model.month() === date.month()
        },
        isSameDay: function (model, date) {
            return this.isSameMonth(model, date) && model.date() === date.date()
        },
        isSameHour: function (model, date) {
            return this.isSameDay(model, date) && model.hours() === date.hours()
        },
        isSameMinutes: function (model, date) {
            return this.isSameHour(model, date) && model.minutes() === date.minutes()
        },
        setParams: function (zone) {
            tz = zone
        },
        findFunction: function (scope, name) {
            //Search scope ancestors for a matching function.
            //Can probably combine this and the below function
            //into a single search function and two comparison functions
            //Need to add support for lodash style selectors (eg, 'objectA.objectB.function')
            var parentScope = scope
            do {
                parentScope = parentScope.$parent
                if (angular.isFunction(parentScope[name])) {
                    return parentScope[name]
                }
            } while (parentScope.$parent)

            return false
        },
        findParam: function (scope, name) {
            //Search scope ancestors for a matching parameter.
            var parentScope = scope
            do {
                parentScope = parentScope.$parent
                if (parentScope[name]) {
                    return parentScope[name]
                }
            } while (parentScope.$parent)

            return false
        },
        createMoment: (m) => {
            if (!(moment.isMoment(m))) {
                m = moment(m)
            }
            // return moment.utc(m).tz(tz)
            return moment.tz(m.format(DATE_SERVICE_FORMAT), DATE_SERVICE_FORMAT, tz)
        },
        getDate: function (scope, attrs, name) {
            var result = false
            if (attrs[name]) {
                result = this.createMoment(attrs[name])
                if (!result.isValid()) {
                    result = this.findParam(scope, attrs[name])
                    if (result) {
                        result = this.createMoment(result)
                    }
                }
            }

            return result
        }
    }
}])

Module.run(['$templateCache', function ($templateCache) {
    $templateCache.put('templates/datepicker.html',
        `
            <div ng-switch="view">
                <div ng-switch-when="date">
                    <table>
                        <thead>
                            <tr>
                                <th ng-click="prev()">&nbsp;</th>
                                <th colspan="5" class="switch" ng-click="updateMYTemps()">
                                    {{date | mFormat:'MMMM YYYY':tz}}
                                    <i class="icon-common_arrow_down"></i>
                                </th>
                                <th ng-click="next()">&nbsp;</th>
                            </tr>
                            <tr>
                                <th ng-repeat="day in weekdays" style="overflow: hidden"
                                    ng-bind="day|mFormat:'ddd':tz"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-repeat="week in weeks" ng-init="$index2 = $index">
                                <td ng-repeat="day in week" ng-class="{left: $first || (classes[$index2][$index - 1].indexOf('disabled-max') == -1 && classes[$index2][$index].indexOf('disabled-max') != -1), right: $last || (classes[$index2][$index + 1].indexOf('disabled-min') == -1 && classes[$index2][$index].indexOf('disabled-min') != -1)}">
                                    <span ng-class="classes[$index2][$index]"
                                          ng-click="selectDate(day)" ng-bind="day|mFormat:'DD':tz"></span>
                                    <div class="tooltip">{{getHint(classes[$index2][$index])}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="year-month-selection dropdown-menu" ng-show="yearMonthIsActive">
                        <div class="lists">
                            <ul class="months">
                                <li ng-repeat="monthT in monthsList" ng-class="{active: activeMonthT === $index}"
                                    ng-click="setActiveMonthT($index)">
                                    <i class="icon-menu_checkmark"></i>{{monthT}}
                                </li>
                            </ul>
                            <ul class="years">
                                <li ng-repeat="yearT in yearsList" ng-class="{active: activeYearT === yearT}"
                                    ng-click="setActiveYearT(yearT)">
                                    <i class="icon-menu_checkmark"></i>{{yearT}}
                                </li>
                            </ul>
                        </div>
                        <footer>
                            <button class="btn btn-green" ng-click="updateDateWithMYT()">
                                {{'label.set' | translate}} {{monthsList[activeMonthT]}} {{activeYearT}}
                            </button>
                        </footer>
                        <div class="arrow"></div>
                    </div>
                </div>
            </div>
        `
    )
}])

export default Module
/* eslint-enable */
