import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import deviceDetector from './device-detector.js'
import { getService, translate } from './utils'

class Tooltip extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        isDisabled: PropTypes.bool,
        forceDisplay: PropTypes.bool
    }

    static defaultProps = {
        isDisabled: false,
        forceDisplay: false
    }

    constructor (props) {
        super(props)

        this.currentUser = getService('currentUser')
    }

    getTranslatedText () {
        const { text, isDisabled } = this.props
        const textForDisabled = `${text}Disabled`
        const label = isDisabled ? textForDisabled : text
        return translate(label)
    }

    componentDidMount () {
        if (deviceDetector.isMobile()) {
            return
        }
        const { isDisabled, forceDisplay } = this.props
        this.componentEl = ReactDOM.findDOMNode(this)
        this.tooltipEl = document.createElement('div')

        this.tooltipArrowEl = document.createElement('div')
        this.tooltipArrowEl.className = 'arrow'
        let tooltipContentEl = document.createElement('span')
        tooltipContentEl.innerText = this.getTranslatedText()

        this.tooltipEl.appendChild(this.tooltipArrowEl)
        this.tooltipEl.appendChild(tooltipContentEl)
        this.tooltipEl.className = clsx('help-tooltip', { 'white': forceDisplay, 'disabled': isDisabled })

        this.componentEl.addEventListener('mousemove', this.handleMouseMove.bind(this))
        this.componentEl.addEventListener('mouseleave', this.handleMouseOut.bind(this))
    }

    componentDidUpdate () {
        if (this.tooltipEl && !deviceDetector.isMobile()) {
            const { isDisabled, forceDisplay } = this.props
            this.recalcPosition()
            this.tooltipEl.className = clsx('help-tooltip', { 'white': forceDisplay, 'disabled': isDisabled })
            this.tooltipEl.childNodes[1].innerText = this.getTranslatedText()
        }
    }

    mountTooltip () {
        document.body.appendChild(this.tooltipEl)
        this.resetTooltip()
        this.isTooltipMounted = true
    }

    recalcPosition () {
        const { isDisabled, forceDisplay } = this.props
        let componentRect = this.componentEl.getBoundingClientRect()
        let center = {
            x: componentRect.left + this.componentEl.clientWidth / 2,
            y: componentRect.top + this.componentEl.clientHeight
        }

        let tooltipLeft = center.x - this.tooltipEl.clientWidth / 2
        let tooltipBottom = center.y + this.tooltipEl.clientHeight + 12
        if (tooltipLeft < 4) {
            this.tooltipEl.style.left = '4px'
            this.tooltipEl.style.right = 'auto'
        } else if (center.x + this.tooltipEl.clientWidth > window.innerWidth) {
            this.tooltipEl.style.left = 'auto'
            this.tooltipEl.style.right = '4px'
        } else {
            this.tooltipEl.style.left = Math.ceil(tooltipLeft) + 'px'
            this.tooltipEl.style.right = 'auto'
        }
        if (tooltipBottom > window.innerHeight) {
            let offsetTop = Math.ceil(componentRect.top - this.tooltipEl.clientHeight - 12)
            this.tooltipEl.style.top = offsetTop + 'px'
            this.tooltipArrowEl.style.top = Math.ceil(offsetTop + this.tooltipEl.clientHeight - 1) + 'px'
            if (isDisabled || forceDisplay) {
                this.tooltipArrowEl.style.transform = 'rotate(225deg) translate(5px, 6px)'
            } else {
                this.tooltipArrowEl.style.transform = 'rotate(180deg)'
            }
        } else {
            this.tooltipEl.style.top = Math.ceil(center.y + 12) + 'px'
            this.tooltipArrowEl.style.top = Math.ceil(center.y + 2) + 'px'
            if (isDisabled || forceDisplay) {
                this.tooltipArrowEl.style.transform = 'rotate(45deg) translate(4px)'
            } else {
                this.tooltipArrowEl.style.transform = 'rotate(0deg)'
            }
        }
        this.tooltipArrowEl.style.left = Math.ceil(center.x - 11) + 'px'
    }

    resetTooltip () {
        this.tooltipEl.classList.remove('visible')
    }

    isVisible () {
        return this.props.isDisabled || this.props.forceDisplay || !this.currentUser.skipHelpTooltips
    }

    handleMouseMove () {
        const { text } = this.props
        if (text === '' || !this.isVisible()) {
            return
        }
        if (!this.isTooltipMounted) {
            this.mountTooltip()
        }
        this.recalcPosition()
        this.tooltipEl.classList.add('visible')
    }

    handleMouseOut () {
        this.resetTooltip()
    }

    componentWillUnmount () {
        if (deviceDetector.isMobile()) {
            return
        }
        this.componentEl.removeEventListener('mousemove', this.handleMouseMove.bind(this))
        this.componentEl.removeEventListener('mouseleave', this.handleMouseOut.bind(this))
        if (this.tooltipEl.parentNode === document.body) {
            document.body.removeChild(this.tooltipEl)
            this.isTooltipMounted = false
        }
    }

    render () {
        return this.props.children
    }
}

export default Tooltip
