import React from 'react'
import clsx from 'clsx'

export function getHighlightedText (text, highlight) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return (
        <>
            {parts.map((part, i) =>
                <span key={i}
                      className={clsx({ 'highlighted': part.toLowerCase() === highlight.toLowerCase() })}>
                    {part}
                </span>
            )}
        </>
    )
}
