import './expression-options.less'
import { BUTTONS, SELECT_BUTTONS } from '../../../services/constants'

export function ExpressionOptions ($document) {
    'ngInject'

    return {
        scope: true,
        bindToController: {
            isOpened: '<',
            groups: '<',
            onSelect: '&',
            onFocusLost: '&'
        },
        template: `
            <div class="expression-options" ng-class="{active: $ctrl.isOpened}">
                <ul class="dropdown-list">
                    <li class="group"
                        ng-repeat="group in $ctrl.groups track by group.id"
                        ng-class="{active: $ctrl.isGroupActive($index)}"
                        group-idx="{{$index}}">
                        <div class="group-label"
                             ng-focus="$ctrl.activateItem($index)">
                            <i class="icon-picker_arrow_right"></i>
                            <span>{{group.name}}</span>
                        </div>
                        <ul class="group-items" ng-init="groupIndex=$index">
                            <li class="group-item"
                                item-idx="{{$index}}"
                                ng-repeat="replacement in group.replacements track by replacement.id"
                                ng-class="{active: $ctrl.isReplacementActive(groupIndex, $index)}">
                                <span class="group-item-label"
                                      ng-click="$ctrl.selectReplacement(groupIndex, $index)"
                                      ng-mousemove="$ctrl.activateItem(groupIndex, $index)"
                                      ng-focus="$ctrl.activateItem(groupIndex, $index)">{{replacement.name}}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        `,
        controller: function ($scope) {
            'ngInject'

            this.isGroupActive = (groupIndex) => groupIndex === this.activeGroupIndex
            this.isReplacementActive = (groupIndex, replacementIndex) => {
                return this.isGroupActive(groupIndex) && replacementIndex === this.activeReplacementIndex
            }
            this.selectReplacement = (groupIndex, replacementIndex) => {
                if (groupIndex !== undefined && replacementIndex !== undefined) {
                    let replacement = this.groups[groupIndex] ? this.groups[groupIndex].replacements[replacementIndex] : null
                    if (replacement) {
                        this.onSelect({ id: replacement.id })
                    }
                }
            }
            this.selectActiveReplacement = () => {
                this.selectReplacement(this.activeGroupIndex, this.activeReplacementIndex)
            }
            this.activateItem = (groupIndex, replacementIndex) => {
                this.activeGroupIndex = groupIndex
                this.activeReplacementIndex = replacementIndex !== undefined ? replacementIndex : 0
            }
            this.activatePrevItem = () => {
                let nextReplacementIndex = this.activeReplacementIndex - 1
                if (nextReplacementIndex > -1) {
                    this.activeReplacementIndex = nextReplacementIndex
                    return
                }

                let nextGroupIndex = this.activeGroupIndex - 1
                if (nextGroupIndex > -1) {
                    this.activeGroupIndex = nextGroupIndex
                    let group = this.groups[nextGroupIndex]
                    this.activeReplacementIndex = group.replacements.length - 1
                }
            }
            this.activateNextItem = () => {
                let group = this.groups[this.activeGroupIndex]
                let nextReplacementIndex = this.activeReplacementIndex + 1
                if (nextReplacementIndex < group.replacements.length) {
                    this.activeReplacementIndex = nextReplacementIndex
                    return
                }

                let nextGroupIndex = this.activeGroupIndex + 1
                if (nextGroupIndex < this.groups.length) {
                    this.activeGroupIndex = nextGroupIndex
                    this.activeReplacementIndex = 0
                }
            }
            this.handleKeypress = (event) => {
                if (SELECT_BUTTONS.indexOf(event.key) > -1) {
                    event.stopPropagation()

                    $scope.$apply(() => {
                        switch (event.key) {
                            case BUTTONS.ESCAPE:
                                this.close()
                                break
                            case BUTTONS.ARROW_UP:
                                this.activatePrevItem()
                                break
                            case BUTTONS.ARROW_DOWN:
                                this.activateNextItem()
                                break
                            case BUTTONS.ENTER:
                                this.selectActiveReplacement()
                                break
                            default:
                        }
                    })

                    return false
                }
            }

            let eventBindingState = false
            this.toggleEventBinding = (state) => {
                if (state === eventBindingState) {
                    return
                }

                if (state) {
                    $document.on('keydown', this.handleKeypress)
                } else {
                    $document.off('keydown', this.handleKeypress)
                }
                eventBindingState = state
            }

            this.close = () => {
                this.onFocusLost()
            }

            this.$onChanges = () => {
                this.toggleEventBinding(this.isOpened)
                this.activeGroupIndex = 0
                this.activeReplacementIndex = 0
            }

            this.$onDestroy = () => {
                this.toggleEventBinding(false)
            }
        },
        controllerAs: '$ctrl',
        link: (scope, element, attrs, ctrl) => {
            const dropdownListEl = element.find('.dropdown-list')

            scope.$watch(() => [ctrl.activeGroupIndex, ctrl.activeReplacementIndex].join('-'), () => {
                const selector = `[group-idx='${ctrl.activeGroupIndex}']  [item-idx='${ctrl.activeReplacementIndex}']`
                const activeEl = element.find(selector)

                if (activeEl.length) {
                    const position = activeEl.position().top
                    const actualScrollTop = dropdownListEl.scrollTop()
                    let newScrollTop
                    if (position < 0) {
                        newScrollTop = actualScrollTop + position
                    }
                    if (position > dropdownListEl.height()) {
                        newScrollTop = actualScrollTop + (position - dropdownListEl.height()) + activeEl.height()
                    }
                    if (newScrollTop) {
                        dropdownListEl.animate({ scrollTop: newScrollTop }, 200)
                    }
                }
            })
        }
    }
}
