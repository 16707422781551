import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { translate } from '../../react/utils'
import ReportIcon from './report-icon.jsx'

const ReportsListItem = ({ report }) => (
    <a href={`/reports/${report.slug}`} className={cn('reports-list-item', report.slug)}>
        <ReportIcon icon={report.icon}/>
        <div className="report-info">
            <div className="report-name">{translate(report.name)}</div>
            <div className="report-description">{translate(report.description)}</div>
            <div className="report-action">
                <span className="btn btn-green">{translate('label.report.run')}</span>
            </div>
        </div>
    </a>
)

ReportsListItem.propTypes = {
    report: PropTypes.object
}

export default ReportsListItem
