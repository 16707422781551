export class BillingController {
    constructor ($auth, $scope, $state, $translate, $timeout, $window, currentUser, AdminApiCalls, AdminSettings, ServerConfig, $rootScope, $modal) {
        'ngInject'

        if (!$auth.isAuthenticated()) {
            $state.go('login-disabled')
        }

        // Private props
        let updatingCard

        // Private methods
        const errorHandler = (err) => {
            this.errorMessage = err.data.displayError || $translate.instant('error.common')
        }
        const onCancel = () => {
            $scope.$applyAsync(this.isCardFormVisible = false)
        }
        const onSuccess = (pageId) => {
            AdminApiCalls.getDisabledCompanyCardUpdateStatus(pageId)
                .then(() => {
                    AdminApiCalls.reactivateDisabledCompany()
                        .then(() => {
                            onCancel()
                            $auth.logout()
                            ServerConfig.errorStatus = undefined
                            this.accountActivated = true
                        })
                        .catch((err) => {
                            onCancel()
                            const modalScope = $rootScope.$new()
                            modalScope.title = $translate.instant('error.commonTitle')
                            modalScope.text = err.data.displayError || $translate.instant('error.common')
                            modalScope.confirmText = $translate.instant('label.ok')
                            modalScope.expire = true
                            modalScope.noClose = true

                            const modalInstance = $modal.open({
                                animation: true,
                                windowClass: 'confirm-alt-modal expiration',
                                template: require('../../../templates/modals/confirm-alt.html'),
                                controller: 'ModalInstanceController',
                                backdrop: 'static',
                                keyboard: false,
                                scope: modalScope
                            })
                            modalInstance.result.finally(() => {modalScope.$destroy()})
                        })
                })
                .catch((err) => {
                    errorHandler(err)
                    onCancel()
                })
        }

        // Public props
        this.accountActivated = false
        this.isAdmin = currentUser.isAdmin
        this.currentDomain = $window.location.host.split('.').slice(1).join('.')
        this.adminSummary = $translate.instant('activation.text.adminSummary').replace('&lt;br&gt;', `<br>`)
        this.nonAdminSummary = $translate.instant('activation.text.nonAdminSummary').replace('&lt;br&gt;', `<br>`)
        this.isCardFormVisible = false

        // Public methods
        this.updateCard = () => {
            if (updatingCard) {
                return
            }

            updatingCard = true
            this.errorMessage = null

            AdminApiCalls.getDisabledCompanyCardUpdateUrlEmbed()
                .then(data => {
                    this.isCardFormVisible = true
                    AdminSettings.callChargeBee(data.result, onSuccess, onCancel)
                })
                .catch((err) => {
                    errorHandler(err)
                    onCancel()
                })
                .finally(() => updatingCard = false)
        }
    }
}
