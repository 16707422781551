export function toasterPopup(PageSettings, $state, $timeout) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {},
        template: `
            <div class="toaster-popup" ng-show="PageSettings.toasterData">
                <i class="icon-common_close" ng-click="close()"></i>
                <i class="custom" ng-class="PageSettings.toasterData.iconClass"></i>
                <div>
                    <header ng-bind-html="PageSettings.toasterData.text"></header>
                    <a ng-click="goToLink()" ng-show="PageSettings.toasterData.linkText && PageSettings.toasterData.route.name">
                        {{PageSettings.toasterData.linkText}}
                    </a>
                </div>
            </div>
        `,

        link: (scope) => {
            let timeoutId;
            scope.PageSettings = PageSettings;
            scope.goToLink = () => {
                let route = PageSettings.toasterData.route;
                if (route) {
                    $state.go(route.name, route.data);
                }
                scope.close();
            };
            scope.close = () => {
                PageSettings.toasterData = null;
            };

            scope.$watch('PageSettings.toasterData', (val) => {
                if (val) {
                    if (timeoutId) {
                        $timeout.cancel(timeoutId);
                    }
                    timeoutId = $timeout(() => scope.close(), PageSettings.toasterData.timeout || 10000);
                }
            });
            scope.$on('$destroy', () => {
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
            });
        }
    };
}
