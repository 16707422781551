import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    DATE_WITHOUT_TIME,
    EMAIL,
    FILES_LIST,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STATUS,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import { constants } from '../../../services/constants'

export const REPORT_TYPE = {
    ALL_STARTED_PROCESSES: 'ALL_STARTED_PROCESSES',
    COLLECTED_DATA: 'COLLECTED_DATA',
    TASK_PERFORMANCE: 'TASK_PERFORMANCE'
}

export const PROCESS_STATUS = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    STOPPED: 'STOPPED',
    ALL: 'ALL'
}

export const decodeProcessStatus = (statusCode, isRejected) => {
    if (statusCode === 8) {
        return isRejected ? PROCESS_STATUS.REJECTED : PROCESS_STATUS.COMPLETED
    } else if (statusCode === 16) {
        return PROCESS_STATUS.STOPPED
    }
    // default case for 2 (STARTED) and 4 (IN_PROGRESS)
    return PROCESS_STATUS.ACTIVE
}

export const TASK_STATUS = {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    ASSIGNED: 'ASSIGNED',
    COMPLETED: 'COMPLETED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    INACTIVE_REVIEW: 'INACTIVE_REVIEW',
    ALL: 'ALL'
}

export const decodeTaskStatus = statusCode => {
    const status = Object.keys(constants.TASK.STATUS).find(key => constants.TASK.STATUS[key] === statusCode)
    if (['STOPPED_ON_REJECT', 'STOPPED_ON_CANCEL'].includes(status)) {
        return 'CANCELLED'
    }
    return status
}

export const decodeGroupStatus = statusCode => {
    const status = Object.keys(constants.GROUP.STATUS).find(key => constants.GROUP.STATUS[key] === statusCode)
    // if (['STOPPED_ON_REJECT', 'STOPPED_ON_CANCEL'].includes(status)) {
    //     return 'CANCELLED'
    // }
    return status
}

export const PROCESS_STATUS_FILTER_OPTIONS = Object.keys(PROCESS_STATUS).map(id => {
    return {
        id,
        name: `report.filters.field.processStatus.${PROCESS_STATUS[id]}`
    }
})

export const TASK_STATUS_FILTER_OPTIONS = Object.keys(TASK_STATUS).map(id => {
    return {
        id,
        name: `report.filters.field.taskStatus.${TASK_STATUS[id]}`
    }
})

export const NO_TEMPLATE_OPTION = {
    id: 'NO_TEMPLATE',
    name: 'report.filters.specialOption.noTemplate'
}

export const ALL_TEMPLATES_OPTION = {
    id: 'ALL',
    name: 'report.filters.specialOption.allTemplates'
}

export const CUSTOM_DATE_RANGE = {
    label: 'report.filters.dateRange.custom',
    value: 'CUSTOM'
}

export const DATE_RANGE_CONFIG = {
    TODAY: {
        name: 'report.filters.dateRange.today',
        start: (momentInstance) => momentInstance.startOf('day'),
        end: (momentInstance) => momentInstance.endOf('day')
    },
    YESTERDAY: {
        name: 'report.filters.dateRange.yesterday',
        start: (momentInstance) => momentInstance.subtract(1, 'd').startOf('day'),
        end: (momentInstance) => momentInstance.subtract(1, 'd').endOf('day')
    },
    THIS_WEEK: {
        name: 'report.filters.dateRange.thisWeek',
        start: (momentInstance) => momentInstance.startOf('week'),
        end: (momentInstance) => momentInstance.endOf('day')
    },
    LAST_WEEK: {
        name: 'report.filters.dateRange.lastWeek',
        start: (momentInstance) => momentInstance.subtract(1, 'w').startOf('week'),
        end: (momentInstance) => momentInstance.subtract(1, 'w').endOf('week')
    },
    LAST_TWO_WEEKS: {
        name: 'report.filters.dateRange.lastTwoWeeks',
        start: (momentInstance) => momentInstance.subtract(2, 'w').startOf('week'),
        end: (momentInstance) => momentInstance.subtract(1, 'w').endOf('week')
    },
    THIS_MONTH: {
        name: 'report.filters.dateRange.thisMonth',
        start: (momentInstance) => momentInstance.startOf('month'),
        end: (momentInstance) => momentInstance.endOf('day')
    },
    LAST_MONTH: {
        name: 'report.filters.dateRange.lastMonth',
        start: (momenmomentInstance) => momenmomentInstance.subtract(1, 'M').startOf('month'),
        end: (momentInstance) => momentInstance.subtract(1, 'M').endOf('month')
    },
    THIS_YEAR: {
        name: 'report.filters.dateRange.thisYear',
        start: (momentInstance) => momentInstance.startOf('year'),
        end: (momentInstance) => momentInstance.endOf('day')
    },
    LAST_YEAR: {
        name: 'report.filters.dateRange.lastYear',
        start: (momentInstance) => momentInstance.subtract(1, 'y').startOf('year'),
        end: (momentInstance) => momentInstance.subtract(1, 'y').endOf('year')
    }
}

export const DEFAULT_DATE_RANGE_PRESET = 'THIS_WEEK'

export const DATE_SERVICE_FORMAT = 'YYYY-MM-DD HH:mm'

export const DURATION = 'DURATION'

export const REPORT_COLUMNS = {
    [REPORT_TYPE.ALL_STARTED_PROCESSES]: {
        TEMPLATE_TITLE: {
            getter: d => d.templateName,
            flexGrow: 2
        },
        PROCESS_TITLE: {
            getter: d => d.name,
            flexGrow: 2
        },
        PROCESS_STARTER: {
            width: 150,
            getter: d => d.starter.fullName
        },
        START_DATE: {
            getter: d => d.startedDate,
            dataType: DATE_AND_TIME
        },
        END_DATE: {
            hideIfEmpty: true,
            getter: d => d.completedDate,
            dataType: DATE_AND_TIME
        },
        PROCESS_DEADLINE: {
            getter: d => d.dueDate,
            dataType: DATE_WITHOUT_TIME
        },
        STATUS: {
            getter: d => ({ status: d.status, isProcess: true }),
            dataType: STATUS
        }
    },
    [REPORT_TYPE.COLLECTED_DATA]: {
        TEMPLATE_TITLE: {
            getter: d => d.templateName,
            flexGrow: 2
        },
        PROCESS_TITLE: {
            getter: d => d.name,
            flexGrow: 2
        },
        PROCESS_STARTER: {
            width: 150,
            getter: d => d.starter.fullName
        },
        PROCESS_STARTED_DATE: {
            getter: d => d.startedDate,
            dataType: DATE_AND_TIME
        },
        PROCESS_COMPLETED_DATE: {
            getter: d => d.completedDate,
            dataType: DATE_AND_TIME
        },
        PROCESS_DEADLINE: {
            getter: d => d.dueDate,
            dataType: DATE_WITHOUT_TIME
        },
        STATUS: {
            getter: d => ({ status: d.status, isProcess: true }),
            dataType: STATUS
        }
    },
    [REPORT_TYPE.TASK_PERFORMANCE]: {
        TEMPLATE_TITLE: {
            getter: d => d.process.templateName,
            flexGrow: 2
        },
        PROCESS_TITLE: {
            getter: d => d.process.name,
            flexGrow: 2
        },
        PROCESS_STARTER: {
            width: 150,
            getter: d => d.process.starter.fullName
        },
        PROCESS_STARTED_DATE: {
            getter: d => d.process.startedDate,
            dataType: DATE_AND_TIME
        },
        PROCESS_COMPLETED_DATE: {
            getter: d => d.process.completedDate,
            dataType: DATE_AND_TIME
        },
        PROCESS_DEADLINE: {
            getter: d => d.process.dueDate,
            dataType: DATE_WITHOUT_TIME
        },
        PROCESS_STATUS: {
            getter: d => ({ status: d.process.status, isProcess: true }),
            dataType: STATUS
        },
        TASK_TITLE: {
            getter: d => d.name,
            flexGrow: 2
        },
        TASK_ASSIGNEE: {
            width: 150,
            getter: d => d.assignee ? d.assignee.fullName : ''
        },
        TASK_CREATION_DATE: {
            getter: d => d.activationDate,
            dataType: DATE_AND_TIME
        },
        TASK_DUE_DATE: {
            getter: d => d.dueDate,
            dataType: DATE_WITHOUT_TIME
        },
        TASK_ASSIGNED_DATE: {
            getter: d => d.assignedDate,
            dataType: DATE_AND_TIME
        },
        TASK_COMPLETED_DATE: {
            getter: d => d.completedDate,
            dataType: DATE_AND_TIME
        },
        TASK_STATUS: {
            getter: d => ({ status: d.status, isProcess: false }),
            dataType: STATUS
        },
        TASK_REJECTION_REASON: {
            getter: d => d.rejectReason
        },
        TASK_TIME_TO_ASSIGN: {
            getter: d => d.timeToAssign,
            dataType: DURATION
        },
        TASK_TIME_FROM_ASSIGN_TO_COMPLETE: {
            getter: d => d.timeFromAssignToComplete,
            dataType: DURATION
        },
        TASK_TIME_TO_COMPLETE: {
            getter: d => d.timeToComplete,
            dataType: DURATION
        },
        TASK_TIME_OVERDUE: {
            getter: d => d.timeOverdue,
            dataType: DURATION
        }
    }
}

export const DEFAULT_COLUMN_WIDTH = 120
export const DEFAULT_ROW_HEIGHT = 40
export const DEFAULT_HEADER_HEIGHT = 40

export const DEFAULT_TIME = '12:00'

export const REPORT_EXPORT_FORMATS = {
    CSV: 'CSV',
    EXCEL: 'Excel'
}

export const REPORT_PAGE_SIZE = 50
export const MIN_EXPORT_PROGRESS = 1

export const DEFAULT_COLUMN_WIDTHS = {
    [STRING_SINGLE_LINE]: 200,
    [STRING_MULTI_LINE]: 200,
    [DATE_ONLY_VALUE]: 120,
    [DATE_WITHOUT_TIME]: 120,
    [DATE_AND_TIME]: 150,
    [EMAIL]: 200,
    [FILES_LIST]: 150,
    [MULTI_SELECTOR]: 150,
    [RADIO_SELECTOR]: 150,
    [MONEY]: 120,
    [NUMERIC_VALUE]: 120,
    [YES_NO]: 90,
    [USER_FIELD]: 120,
    [STATUS]: 100
}
