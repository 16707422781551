import Drop from 'tether-drop';

export function selectPrFilterMode($state, $translate) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        template: require('./select-filter-mode.html'),
        link: (scope, element, attrs) => {
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--filter-mode')[0],
                position: attrs.right ? 'bottom right' : 'bottom left',
                openOn: 'click'
            });

            scope.getTitle = () => {
                return $translate.instant(scope.checkMode('my') ? 'title.myProcesses' : 'title.allProcesses');
            };
            scope.setMode = (mode) => {
                $state.go('main.processes.' + mode).finally(() => {
                    drop.close();
                });
            };
            scope.checkMode = (mode) => {
                return $state.current.name === 'main.processes.' + mode;
            };

            scope.$on('tether:close', scope.close);
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
