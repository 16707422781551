import Drop from 'tether-drop'

const autoCloseDelay = 1500

export function baseDropdown ($document, $timeout) {
    'ngInject'

    return {
        restrict: 'E',
        transclude: true,
        template: '<div ng-transclude=""></div>',
        scope: {
            position: '@?',
            onTargetClick: '&?',
            onOpen: '&?',
            onClose: '&?',
            autoClose: '<?'
        },
        link: (scope, element, attrs) => {
            scope.keepOpened = attrs.keepOpened !== undefined

            let drop = new Drop({
                target: element.find('.dropdown-toggle')[0],
                content: element.find('.dropdown-menu')[0],
                position: scope.position || 'bottom left',
                openOn: false,
                remove: true
            })

            let closeOnEscape,
                autoCloseTimeout

            function closeAction () {
                drop.content.removeEventListener('mouseleave', closeAction)
                drop.content.removeEventListener('mouseleave', startAutoCloseTimeout)
                drop.content.removeEventListener('mouseenter', stopAutoCloseTimeout)
                drop.close()

                if (scope.onClose) {
                    scope.onClose()
                }

                $timeout.cancel(autoCloseTimeout)
                $document.off('click', clickHandler)
                $document.off('keydown', closeOnEscape)
            }

            function startAutoCloseTimeout () {
                autoCloseTimeout = $timeout(closeAction, autoCloseDelay)
                drop.content.addEventListener('mouseenter', stopAutoCloseTimeout)
            }

            function stopAutoCloseTimeout () {
                $timeout.cancel(autoCloseTimeout)
                drop.content.removeEventListener('mouseenter', stopAutoCloseTimeout)
                drop.content.addEventListener('mouseleave', startAutoCloseTimeout)
            }

            function clickHandler (event) {
                if (scope.keepOpened && drop.content.contains(event.target)) {
                    closeOnLeaveAfterClick()
                } else {
                    closeAction()
                }
            }

            function closeOnLeaveAfterClick () {
                drop.content.addEventListener('mouseleave', closeAction)
            }

            function openAction () {
                drop.open()
                if (scope.onOpen) {
                    scope.onOpen()
                }

                if (scope.autoClose) {
                    startAutoCloseTimeout()
                }

                $timeout(() => {
                    $document.on('click', clickHandler)
                    $document.on('keydown', closeOnEscape)
                }, 50)
            }

            closeOnEscape = (event) => {
                if (event.key === 'Escape') {
                    closeAction()
                }
            }

            element.on('click', '.dropdown-toggle', (e) => {
                if (scope.onTargetClick) {
                    scope.onTargetClick(e)
                }
                if (drop.isOpened()) {
                    closeAction()
                } else {
                    openAction()
                }
            })

            scope.$on('$destroy', () => {
                $document.off('click', clickHandler)
                $document.off('keydown', closeOnEscape)
                $timeout.cancel(autoCloseTimeout)
                drop.destroy()
            })
        }
    }
}
