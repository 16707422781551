/**
 * Базовая директива для dropdown например:
 *
 *   <div class="dropdown">
 *      <span class="dropdown-toggle">При клике сюда показать меню</span>
 *      <div class="dropdown-menu">
 *         ...
 *      </div>
 *   </div>
 *
 * При клике на dropdown-toggle у элемента dropdown добавится класс dropdown-opened
 */

export function dropdown($document, $rootScope) {
    'ngInject';

    return {
        restrict: 'AC',
        link: (scope, element) => {
            let isOpened = false;

            let closeMenu = function() {
                isOpened = false;
                element.removeClass('dropdown-opened');
                $document.off('click touchend', closeMenu);
            };

            let openMenu = function() {
                isOpened = true;
                $rootScope.$broadcast('tether:close');
                element.addClass('dropdown-opened');
                $document.on('click touchend', closeMenu);
            };
            
            scope.$on('dropdown:close', () => {
                closeMenu();
            });

            element.on('click touchend', '.dropdown-toggle', function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (isOpened) {
                    closeMenu();
                } else {
                    openMenu();
                }
            });
        }
    };
}
