export function truncate() {
    'ngInject';

    return function(input, allowed) {
        if (!input) {
            return input;
        }
        
        // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        allowed = (
            String(allowed || '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
        ).join('');

        let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        let commentsAndPhpTags = /<!--[\s\S]*?-->|<!--|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '')
            .replace(tags, ($0, $1) => allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '');
    };
}