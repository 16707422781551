import Drop from 'tether-drop';
const DropContext = Drop.createContext({classPrefix: 'review-actions-drop'});

export function taskReviewActions() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            callback: '&'
        },
        template: `
            <div class="review-actions" ng-click="$event.stopPropagation()">
                <div class="handler">
                    <i class="icon-common_arrow_down"></i>
                </div>
                
                <ul class="dropdown-menu--review-actions">
                    <li>
                        <a ng-click="callback(); close()" help-tooltip="tooltips.rejectTask">
                            {{'taskView.label.finalReject' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        `,
        link: (scope, element) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--review-actions')[0],
                position: 'bottom left',
                openOn: 'click'
            });
            scope.close = () => {
                drop.close();
            };
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
