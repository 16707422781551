import moment from 'moment-timezone';
import Drop from 'tether-drop';
import { DATE_SYSTEM_FORMAT } from '../services/constants'

const DateAltPickerContext = Drop.createContext({
    classPrefix: 'date-alt-picker-drop'
});

export function dateAltPicker(DateHelper) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dateModel: '=?date',
            onOpen: '&?',
            onClose: '&?',
            field: '=?',
            isDisabled: '=?'
        },
        template: `
            <span class="prc-date-picker date-time-picker">
                <span class="handler" ng-class="{readOnly: isDisabled}" ng-attr-id="{{field.name.id}}" tabindex="-1">
                    <i class="icon-task_due_date_add_small"></i>
                    <div ng-if="dateValue">
                        {{dateValue}}
                    </div>
                    <i ng-if="dateValue && !isDisabled" class="icon-common_remove" touch-start="clearDate()"
                       tabindex="-1" ng-click="clearDate($event)"></i>
                </span>
                <div class="prc-picker-content date-time-picker-content" ng-class="{empty: !dateModel}"
                     ng-click="$event.stopPropagation()" ng-show="!isDisabled">
                    <div ng-click="pickerClick($event)" date-picker="pickerValue"
                         watch-direct-changes="true" min-view="date" max-view="date"
                         after="(field.name || field).options.minValue" before="(field.name || field).options.maxValue"></div>
                </div>
            </span>
        `,

        link: (scope, element) => {
            let drop = new DateAltPickerContext({
                target: element.find('.handler')[0],
                content: element.find('.prc-picker-content')[0],
                position: 'bottom center',
                remove: false,
                openOn: 'click',
                tetherOptions: {
                    constraints: [
                        {
                            to: 'window',
                            attachment: 'together',
                            pin: true
                        }
                    ]
                }
            });
            let dump;

            const setPickerValue = () => {
                if (scope.dateModel) {
                    scope.pickerValue = moment.unix(scope.dateModel).utc()
                } else {
                    scope.pickerValue = undefined
                    scope.dateValue = undefined
                }
            }

            scope.$watch('pickerValue', newValue => {
                if (!newValue) {
                    return
                }

                const value = moment.utc(moment(newValue).format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT)
                scope.dateModel = value.unix()
                scope.dateValue = value.format(DateHelper.DATE_FORMATS().DATE_INPUT)
            })

            scope.$watch('dateModel', ()=> {
                setPickerValue();
            });

            scope.pickerClick = (event) => {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                if (event.target.nodeName === 'SPAN') {
                    drop.close();
                }
            };

            scope.clearDate = (event) => {
                if (event) {
                    event.stopPropagation();
                }
                scope.dateValue = null;
                scope.dateModel = null;
                if (scope.pickerValue) {
                    delete scope.pickerValue;
                }
                if (scope.field) {
                    scope.field.value = scope.field.value || {};
                    scope.field.value.dateValue = scope.dateModel;
                }
                if (scope.onClose && !scope.dateModel) {
                    dump = scope.dateModel;
                    scope.onClose();
                }
                scope.$applyAsync();
            };

            drop.on('open', () => {
                dump = scope.dateModel;
                if (scope.onOpen) {
                    scope.onOpen();
                }
            });

            drop.on('close', () => {
                scope.$broadcast('reset:datepicker');
                if (scope.onClose && dump !== scope.dateModel) {
                    scope.onClose();
                }
            });

            scope.$on('selectize:open', () => {
                drop.close();
            });

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
