import Drop from 'tether-drop';


export function menuButtonMore() {
    'ngInject';
    
    return {
        restrict: 'E',
        replace: true,
        scope: {
            mtOptions: '='
        },
        template: `
            <div >
                <a ng-class="{active: isActive}" class="btn-switcher btn-primary-switcher tether-handler menu-button-more">
                    <i class="icon-task_more"></i>
                    <span>More</span>
                    <i class="bottom-triangle"></i>
                </a>
                <ul class="dropdown-menu menu-button-more-options" ng-hide="readOnly">
                    <li ng-repeat="option in mtOptions" ui-sref="{{option.sref}}" ui-sref-active="active">
                        <i class="icon-profile_billing" ng-class="[option.icon]"></i>
                        <span>{{option.text | translate}}</span>
                    </li>
                </ul>
            </div>
        `,
        
        link: (scope, element) => {
            const DropdownMenuClass = Drop.createContext({
                classPrefix: 'dropdown-tether'
            });
            let drop = new DropdownMenuClass({
                target: element.find('.tether-handler')[0],
                content: element.find('.dropdown-menu')[0],
                position: 'bottom left',
                openOn: 'click'
            });
    
            scope.$on('$destroy', () => {
                drop.destroy();
            });
    
        }
    };
}
