import React from 'react'
import PropTypes from 'prop-types'
import EntityIcon from './entity-icon.jsx'
import BaseDropdown from '../dropdown/base.jsx'

import icons from '../../../libs/icons'
import colors from '../../../libs/colors.js'
import clsx from 'clsx'
import { translate } from '../../../modules/react/utils'

class EntityIconSelector extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        defaultIcon: PropTypes.object.isRequired,
        readOnly: PropTypes.bool
    }

    constructor (props) {
        super(props)

        this.state = {
            isOpen: false,
            selectedColor: colors[0]
        }
    }

    render () {
        const { value, readOnly, defaultIcon } = this.props
        const { isOpen, selectedColor } = this.state
        const label = (
            <EntityIcon
                icon={value}
                onClick={!readOnly ? this.toggleMenu : undefined}
                defaultIcon={defaultIcon.id}
                defaultColor={defaultIcon.color}
            />
        )

        return (
            <BaseDropdown
                label={label}
                isOpen={isOpen}
                onFocusLost={this.closeMenu}
                baseClassName={clsx('entity-icon-dropdown', { 'readonly': readOnly })}
            >
                <div className="icons-selector">
                    <span className="arrow"/>
                    <div className="icons-selector-icons">
                        <h3>{translate('label.selectIcon')}</h3>
                        <ul>
                            {icons.map(({ id }) => (
                                <li key={id} onClick={() => this.selectIcon(id)}>
                                    <EntityIcon icon={{ id, color: selectedColor }}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="icons-selector-colors">
                        <h3>{translate('label.selectBgColor')}</h3>
                        <ul>
                            {colors.map(color => (
                                <li
                                    key={color}
                                    style={{ backgroundColor: color, borderColor: color }}
                                    onClick={() => this.selectColor(color)}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </BaseDropdown>
        )
    }

    toggleMenu = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    closeMenu = () => {
        this.setState({ isOpen: false })
    }

    selectColor = selectedColor => {
        const { value, defaultIcon } = this.props
        this.setState({ selectedColor })
        const selectedIcon = { id: value ? value.id : defaultIcon.id, color: selectedColor }
        this.props.onChange(selectedIcon)
    }

    selectIcon = id => {
        const selectedIcon = { id, color: this.state.selectedColor }
        this.closeMenu()
        this.props.onChange(selectedIcon)
    }

}

export default EntityIconSelector
