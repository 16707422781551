let validationErrors = {
    email: 'It is not Email',
    duplicateEmail: 'Duplicate email address',
    url: 'It is not URL',
    required: 'It should be not empty',
    pattern: 'Not suitable input',
    minlength: 'Model is too short',
    maxlength: 'Model is too long',
    compareTo: 'Password does not match'
};
export default validationErrors;
