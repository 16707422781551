import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { translate } from '../../modules/react/utils'

import './search-field.less'

function SearchField ({ value, onChange, ...props }) {
    const hasValue = !!value
    return (
        <div className="search-field" {...props}>
            <i className="icon-header_search search-icon"/>
            <input
                type="text"
                placeholder={translate('text.search')}
                value={value}
                onChange={e => onChange(e.target.value)}
                className={clsx({ filled: hasValue })}
                autoComplete={'off'}
            />
            {hasValue && (
                <i className="icon-common_remove clear-button" onClick={() => onChange('')}/>
            )}
        </div>
    )
}

SearchField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}

export default SearchField
