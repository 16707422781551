import { errorRoute } from './error.config.js'
import { ErrorController } from './error.controller.js'

import './index.less'

let ngModule = angular.module('error', [])
    .config(errorRoute)
    .controller('ErrorController', ErrorController)

export default ngModule
