export function cardType() {
    'ngInject';

    return (input) => {
        if (!input) {
            return input;
        }
        let res = input.replace(/_/g, ' ').split(' ');
        return res.map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ');
    };
}
