import config from '../config';

export function TemplateCacheHelper($templateCache) {
    'ngInject';
    let _htmlTemplatesKeys = [];
    let urlsToStore = config.templates_cache_dir;

    let _addKey = key => {
        let urlMatched = urlsToStore.filter(urlPart => {
            return key.indexOf(urlPart) === 0;
        });
        if (urlMatched && urlMatched.length > 0 && _htmlTemplatesKeys.indexOf(key) < 0) {
            _htmlTemplatesKeys.push(key);
        }
    };
    //clearAll will remove ONLY Templates fetched based on URLs filters
    let _clearAll = () => {
        _htmlTemplatesKeys.forEach(keyStored => {
            $templateCache.remove(keyStored);
        });
        _htmlTemplatesKeys = [];
    };

    return {
        size: () => {
            return _htmlTemplatesKeys.length;
        },
        storeKey: key => {
            return _addKey(key);
        },
        clearAll: () => {
            return _clearAll();
        }
    };
}
