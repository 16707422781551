import React from 'react'
import PropTypes from 'prop-types'
import config from '../../../config'
import isNil from 'lodash/isNil'

const MIN_INTEGER = -999999999999999
const MAX_INTEGER = 999999999999999
const MAX_INTEGER_LENGTH = 15

class NumberInput extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        precision: PropTypes.number,
        unsigned: PropTypes.bool,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onFocusChange: PropTypes.func
    }

    static defaultProps = {
        unsigned: false
    }

    constructor (props) {
        super(props)

        this.state = {
            withDecimalDot: false,
            withNegativeSign: false
        }
    }

    componentDidUpdate (prevProps) {
        const { unsigned, precision } = this.props
        if (unsigned !== prevProps.unsigned || precision !== prevProps.precision) {
            this.applyChanges(this.props.value)
        }
    }

    render () {
        const { value, unsigned, precision, onFocusChange, disabled, ...props } = this.props
        return (
            <input
                {...props}
                type={'text'}
                className="number-input"
                value={this.getViewValue()}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onFocus={() => {
                    this.onFocusChange(true)
                }}
                onBlur={() => {
                    this.toggleDecimal(false)
                    this.toggleNegative(false)
                    this.onFocusChange(false)
                }}
                disabled={disabled}
            />
        )
    }

    handleChange = e => {
        let { value } = e.target

        if (value.endsWith('.') || value.endsWith(',')) {
            value = value.slice(0, value.length - 1)
            this.toggleDecimal(true)
        } else {
            this.toggleDecimal(false)
        }

        if (value === '-') {
            this.toggleNegative(true)
            value = ''
        } else {
            this.toggleNegative(false)
        }

        if (value === '' || value.match(config.pattern_number)) {
            this.applyChanges(value)
        }
    }

    handleKeyDown = e => {
        const { precision } = this.props

        if (precision === 0 && (e.key === '.' || e.key === ',')) {
            e.preventDefault()
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault()
            this.increment()
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault()
            this.decrement()
        }
    }

    applyChanges = value => {
        if (value && ((value[0] !== '-' && value.length > MAX_INTEGER_LENGTH) || value.length > MAX_INTEGER_LENGTH + 1)) {
            return
        }

        const { unsigned, precision, onChange } = this.props
        let fixedValue = value !== '' ? Number(value) : null
        if (unsigned && fixedValue !== null) {
            fixedValue = Math.abs(fixedValue)
        }
        if (!isNil(precision) && fixedValue !== null) {
            fixedValue = this.getFixedValue(fixedValue, precision)
        }
        onChange(fixedValue !== null ? this.getValidValue(fixedValue) : fixedValue)
    }

    toggleDecimal = state => {
        this.setState({
            withDecimalDot: state
        })
    }

    toggleNegative = state => {
        this.setState({
            withNegativeSign: state
        })
    }

    increment = () => {
        const { max, step = 1 } = this.props
        const newValue = (this.getInitialValue() * 1000 + Number(step) * 1000) / 1000
        const result = this.normalizeCalculation(newValue)
        this.applyChanges(Math.min(result, max || result))
    }

    decrement = () => {
        const { min, step = 1 } = this.props
        const newValue = (this.getInitialValue() * 1000 - Number(step) * 1000) / 1000
        const result = this.normalizeCalculation(newValue)
        this.applyChanges(Math.max(result, min || result))
    }

    getViewValue = () => {
        const { withDecimalDot, withNegativeSign } = this.state
        const value = this.props.value ? this.props.value.toString() : ''
        const sign = withNegativeSign && (value === '' || value[0] !== '-') ? '-' : ''
        const dot = withDecimalDot && (value === '' || !value.includes('.')) ? '.' : ''

        return `${sign}${value}${dot}`
    }

    getInitialValue = () => {
        const { value, min, max } = this.props

        if (value !== undefined && value !== '') {
            return Number(value)
        }
        if (min !== undefined && min > 0) {
            return min
        }
        if (max !== undefined && max < 0) {
            return max
        }
        return 0
    }

    getValidValue = value => {
        return Math.max(MIN_INTEGER, Math.min(MAX_INTEGER, value))
    }

    getFixedValue = (value, precision) => {
        if (value === undefined || value === null) {
            return null
        }

        let [integer, fraction] = value.toString().split('.')

        if (isNil(precision) || !fraction) {
            return Number(integer)
        }

        if (!isNil(precision) && precision >= 0 && fraction.length > precision) {
            fraction = fraction.slice(0, precision)
        }

        return Number(`${integer}.${fraction}`)
    }

    normalizeCalculation = (value) => {
        const { step = 1, precision = 0 } = this.props
        const stepDecimalPart = step.toString().split('.')[1]
        const stepPrecision = stepDecimalPart ? stepDecimalPart.length : 0
        const actualPrecision = Math.max(precision, stepPrecision)

        return this.getFixedValue(value, actualPrecision)
    }

    onFocusChange = focusState => {
        if (this.props.onFocusChange) {
            this.props.onFocusChange(focusState)
        }
    }
}

export default NumberInput
