
export function TaskService(ApiCalls, PageSettings, ProcessesSettings, $q, DateHelper) {
    'ngInject';

    let updatingTaskDueDateCount = {};


    return {
        updateDueDate: (dueDate, taskCurrent, taskSaved) => {
            if (taskSaved.dueDate === DateHelper.dateToCompanyEndOfDay(dueDate)) {
                return $q.resolve();
            }
            let putData = {field: 'dueDate'};
            if (dueDate) {
                putData.dueDate = dueDate;
            }
            if(updatingTaskDueDateCount[taskCurrent.id] === undefined) {
                updatingTaskDueDateCount = 1;
            } else {
                updatingTaskDueDateCount[taskCurrent.id]++;
            }
            return ApiCalls.modifyTask(taskCurrent.id, putData)
                .then(data => {
                    updatingTaskDueDateCount--;
                    if (data.success) {
                        taskSaved.dueDate = data.result.dueDate;
                        taskSaved.version = data.result.version;
                        if(updatingTaskDueDateCount <= 0) {
                            taskCurrent.dueDate = data.result.dueDate;
                            taskCurrent.version = data.result.version;
                        }
                    } else if (updatingTaskDueDateCount <= 0) {
                        taskCurrent.dueDate = taskSaved.dueDate;
                        taskCurrent.version = taskSaved.version;
                    }
                })
                .catch(response => {
                    updatingTaskDueDateCount--;
                    if(updatingTaskDueDateCount <= 0) {
                        taskCurrent.dueDate = taskSaved.dueDate;
                        taskCurrent.version = taskSaved.version;
                    }
                    if(response.status === 473) {
                        return PageSettings.errorHandlerModal(response);
                    } else {
                        return ProcessesSettings.callAlertTaskPageModal(response);
                    }
                });
        }
    };
}
