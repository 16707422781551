import config from '../../../config'
import utils, { formFieldToSpecialRole, mergeDataModels, prepareTableSection, updateFieldsStatuses } from '../utils'
import dataModelUtils from '../data-model-utils'
import { updateFormulaFieldsWithServerData } from '../../templates/services/data-model-utils'

export class TaskController {
    constructor (actors, allActiveUsers, Constants, task, $scope, $location, ApiCalls, $state, $stateParams, $window, $q,
        $timeout, $modal, DateHelper, PageSettings, ProcessesSettings, PusherHelper, $rootScope, DeferredAction, $translate) {
        'ngInject'

        $rootScope.isHideIntercom = true

        this.PageSettings = PageSettings
        this.task = task || {}
        this.siblingTasks = []
        this.actors = actors
        this.allActiveUsers = allActiveUsers
        this.url = $stateParams.url
        this.patternName = config.pattern
        this.isActionProcessing = false
        this.modifyTaskAvailabeToCount = 0
        this.eventReCalc = 'actors-list:re-calc'
        this.isSavingDataModel = false

        const timezone = DateHelper.getTZ()

        $scope.STATUS = Constants.TASK.STATUS
        $scope.TYPE = Constants.TASK.TYPE

        const compareActors = (a, b) => {
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            } else {
                return 0
            }
        }

        const makeRemainsActors = (actorsT, count) => {
            if (!actorsT) {
                return
            }
            let result = []
            let originCounter = 0
            if (!actorsT) {
                return result
            }

            if (actorsT.groups) {
                originCounter = originCounter + actorsT.groups.length
                if (actorsT.groups.length > count) {
                    result = result.concat(actorsT.groups.slice(count).map(g => {
                        g.isGroup = true
                        return g
                    }).sort(compareActors))
                }
            }
            count = (count - actorsT.groups.length) > 0 ? count - actorsT.groups.length : 0

            if (actorsT.specialRoles) {
                originCounter = originCounter + actorsT.specialRoles.length
                if (actorsT.specialRoles.length > count) {
                    result = result.concat(actorsT.specialRoles.slice(count).map(r => {
                        r.isRole = true
                        return r
                    }).sort(compareActors))
                }
            }
            count = (count - actorsT.specialRoles.length) > 0 ? (count - actorsT.specialRoles.length) : 0

            if (actorsT.users) {
                originCounter = originCounter + actorsT.users.length
                if (actorsT.users.length > count) {
                    result = result.concat(actorsT.users.slice(count).map(u => {
                        u.isUser = true
                        return u
                    }).sort(compareActors))
                }
            }
            if (result && originCounter === result.length) {
                result.shift()
            }
            return result
        }

        const updateActorsForEdit = actorsForEdit => {
            this.actorsForEdit = {
                users: actorsForEdit && actorsForEdit.users ? actorsForEdit.users.slice() : [],
                groups: actorsForEdit && actorsForEdit.groups ? actorsForEdit.groups.slice() : [],
                specialRoles: actorsForEdit && actorsForEdit.specialRoles ? actorsForEdit.specialRoles.slice() : []
            }

            if (actorsForEdit && actorsForEdit.formFields) {
                actorsForEdit.formFields.forEach(formField => {
                    this.actorsForEdit.specialRoles.push(formFieldToSpecialRole(formField, $translate))
                })
            }
        }

        const INACTIVE_TASK_STATUSES = [Constants.TASK.STATUS.INACTIVE, Constants.TASK.STATUS.SKIPPED]
        const taskIsActive = (taskObj) => INACTIVE_TASK_STATUSES.indexOf(taskObj.status) === -1

        const updateSiblingTasks = () => {
            this.siblingTasks = []
            if ($stateParams.tasks) {
                this.siblingTasks = $stateParams.tasks
            } else if (this.task.process) {
                ApiCalls.getProcessActiveTasks(this.task.process.id).then(data => {
                    this.siblingTasks = [...data]
                    $scope.$applyAsync()
                })
            }
        }

        const updateTaskProps = () => {
            this.task.dueDateInUTC = DateHelper.companyEndOfDayToDateInUTC(this.task.dueDate)
            if (this.task.actors) {
                Object.keys(this.task.actors).forEach(key => {
                    this.task.actors[key] = this.task.actors[key].sort(compareActors)
                })
                $timeout(() => {
                    $scope.$broadcast(this.eventReCalc)
                    if (angular.isDefined(this.actorsToShow)) {
                        this.popupActors = makeRemainsActors(this.task.actors, this.actorsToShow)
                    }
                }, 0)
            }
            if (this.task.actorsForEdit) {
                updateActorsForEdit(this.task.actorsForEdit)
            }
            if (this.task.dataModel) {
                this.task.dataModel.list.forEach(s => {
                    if (s.section.isTable) {
                        s.section = prepareTableSection(s.section)
                    }
                })
            }
            updateSiblingTasks()
        }

        updateTaskProps()

        let taskCopy = angular.copy(this.task)
        let changed = false
        let tasksLoaded = false

        if (actors.formFields) {
            actors.formFields.forEach(formField => {
                this.actors.specialRoles.push(formFieldToSpecialRole(formField, $translate))
            })
        }

        this.process = angular.copy(this.task.process)
        this.assignee = this.task.assigneeName

        this.toggleCommentsOpen = () => {
            this.isCommentsOpened = !this.isCommentsOpened
        }

        const getCurrentTaskIndex = () => this.siblingTasks.findIndex(({ id }) => id === this.task.id)

        this.nextId = () => {
            let currentIndex = getCurrentTaskIndex()

            if (currentIndex > -1) {
                let nextTask = this.siblingTasks.find((t, i) => {
                    return i > currentIndex && taskIsActive(t)
                })

                if (nextTask) {
                    return nextTask.id
                } else if (!tasksLoaded && $stateParams.fromList) {
                    tasksLoaded = true
                    let pageNumber = Math.floor(this.siblingTasks.length / PageSettings.homeItemsPerPage)
                    ApiCalls[`get${$stateParams.fromList}Tasks`]({
                        index: pageNumber,
                        count: PageSettings.homeItemsPerPage,
                        groupBy: $stateParams.tasksGroup
                    }).then(data => {
                        if (data.list && data.list.length) {
                            utils.mergeToArray(this.siblingTasks, data.list, true)
                        }
                    })
                }
            }
            return false
        }

        this.prevId = () => {
            let reversedTasks = this.siblingTasks.slice().reverse()
            let currentIndex = reversedTasks.findIndex(({ id }) => id === this.task.id)
            let prevTask = reversedTasks.find((t, i) => {
                return i > currentIndex && taskIsActive(t)
            })

            if (prevTask) {
                return prevTask.id
            }

            return false
        }

        this.goToAnother = (id) => {
            if (!id) {
                return
            }
            let currentIndex = getCurrentTaskIndex()
            if (changed) {
                this.siblingTasks.splice(currentIndex, 1)
            }
            $state.go('main.task', {
                id: id,
                tasks: $stateParams.tasks ? this.siblingTasks : null,
                url: this.url,
                fromList: $stateParams.fromList,
                tasksGroup: $stateParams.tasksGroup,
                processState: $stateParams.processState
            })
        }

        this.goToProcess = () => {
            $state.go('main.processView', { id: this.process.id, url: $location.path() })
        }

        this.close = () => {
            if ($stateParams.processState) {
                $state.go('main.processView', {
                    id: this.process.id,
                    openedGroups: $stateParams.processState.openedGroups
                })
            } else {
                $location.path(this.url)
            }
            $scope.$applyAsync()
        }

        const updateFieldStatusLabels = () => {
            updateFieldsStatuses(this.task.dataModel, $timeout)
        }

        const updateTaskData = (dueDateOnly = false, formulaChanged = false) => {
            return ApiCalls.getTask(this.task.id)
                .then(taskUpdated => {
                    if (dueDateOnly) {
                        this.task.dueDate = taskUpdated.dueDate
                        this.task.dueDateInUTC = DateHelper.companyEndOfDayToDateInUTC(this.task.dueDate)
                        return
                    }
                    if (formulaChanged) {
                        updateFormulaFieldsWithServerData(this.task.dataModel, taskUpdated.dataModel)
                        updateFieldStatusLabels()
                        return
                    }
                    let dataModel = mergeDataModels(this.task.dataModel, taskUpdated.dataModel)
                    this.task = Object.assign({}, taskUpdated, { dataModel })
                    updateTaskProps()
                    updateFieldStatusLabels()
                    this.process = angular.copy(this.task.process)
                })
                .catch((errResponse) => {
                    if (errResponse.status === 473) {
                        $window.location.reload()
                    } else {
                        ProcessesSettings.callAlertTaskPageModal(errResponse)
                    }
                })
        }

        const changeTaskErrorHandler = (errResponse) => {
            if (errResponse.status === 473) {
                updateTaskData()
            } else {
                ProcessesSettings.callAlertTaskPageModal(errResponse)
                return $q.resolve()
            }
        }

        this.saveFieldErrorHandler = (response) => {
            return changeTaskErrorHandler(response)
        }

        this.unAssign = () => {
            if (!this.checkPermission(this.task, 'unAssign') || this.isActionProcessing) {
                return
            }
            this.blockActorsList = this.isActionProcessing = true
            ApiCalls.unassignTask(this.task)
                .then(data => {
                    if (data.result) {
                        let dataModel = mergeDataModels(this.task.dataModel, data.result.dataModel)
                        this.task = Object.assign({}, data.result, { dataModel })
                        updateTaskProps()
                        this.process = angular.copy(this.task.process)
                        $timeout(() => {
                            this.blockActorsList = false
                            $scope.$broadcast(this.eventReCalc)
                        }, 300)
                    }
                    this.isActionProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isActionProcessing = false)
                })
            changed = true
        }

        this.selfAssign = () => {
            if (!this.checkPermission(this.task, 'selfAssign') || this.isActionProcessing) {
                return
            }
            this.isActionProcessing = true
            ApiCalls.assignTask(this.task)
                .then(data => {
                    if (data.result) {
                        let dataModel = mergeDataModels(this.task.dataModel, data.result.dataModel)
                        this.task = Object.assign({}, data.result, { dataModel })
                        updateTaskProps()
                        this.actorsToShow = null
                        this.process = angular.copy(this.task.process)
                    }
                    this.isActionProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isActionProcessing = false)
                })
            changed = true
        }

        let isAssignClick = false
        this.assign = (isReassign) => {
            if (isAssignClick || !this.checkPermission(this.task, isReassign ? 'reAssign' : 'assignToOther')) {
                return
            }
            let modalScope = $scope.$new()
            modalScope.isReassign = isReassign
            modalScope.taskId = this.task.id
            isAssignClick = true

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'assign-modal',
                backdrop: 'static',
                template: require('../../../templates/modals/assign.html'),
                controller: 'ModalAssignController',
                scope: modalScope,
                resolve: {
                    users: () => ApiCalls.getTaskActorsForAssign(this.task.id)
                }
            })

            modalInstance.result.then(response => {
                if (response.success) {
                    let dataModel = mergeDataModels(this.task.dataModel, response.result.dataModel)
                    this.task = Object.assign({}, response.result, { dataModel })
                    updateTaskProps()
                } else {
                    changeTaskErrorHandler(response)
                }
            }).finally(() => {
                isAssignClick = false
                modalScope.$destroy()
            })
        }

        const doActionAfterSavingFields = (action) => {
            $timeout(() => {
                if (!this.checkSavingFields()) {
                    action()
                } else {
                    doActionAfterSavingFields(action)
                }
            }, 100)
        }

        const completeTaskAction = () => {
            ApiCalls.completeTask(this.task)
                .then(data => {
                    if (data.result) {
                        this.task = data.result
                        updateTaskProps()
                        this.process = angular.copy(this.task.process)
                    }
                    this.isCompleteProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isCompleteProcessing = false)
                })
            changed = true
        }

        this.completeTask = () => {
            console.log('completeAction waiting started')
            $timeout(() => {
                console.log('completeAction waiting ended')
                if (this.task.isCompleted || this.isCompleteDisabled() || this.isCompleteProcessing) {
                    return
                }

                this.isCompleteProcessing = true
                doActionAfterSavingFields(completeTaskAction)
            }, 300)
        }

        this.completeOnBehalf = () => {
            if (!this.isCompleteOnBehalfEnabled() || this.isCompleteProcessing) {
                return
            }
            this.isCompleteProcessing = true
            ApiCalls.completeOnBehalfTask(this.task)
                .then(data => {
                    if (data.result) {
                        this.task = data.result
                        updateTaskProps()
                        this.process = angular.copy(this.task.process)
                    }
                    this.isCompleteProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isCompleteProcessing = false)
                })
        }

        const approveAction = (isForceApprove = false) => {
            const apiAction = isForceApprove ? ApiCalls.forceApproveTask : ApiCalls.approveTask
            apiAction(this.task)
                .then(data => {
                    if (data.result) {
                        this.task = data.result
                        updateTaskProps()
                        this.process = angular.copy(this.task.process)
                    }
                    this.isApproveProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isApproveProcessing = false)
                })
        }

        this.approveTask = () => {
            $timeout(() => {
                if (this.task.status === $scope.STATUS.APPROVED
                    || this.task.status === $scope.STATUS.REJECTED
                    || this.isApproveDisabled()
                    || this.isApproveProcessing) {
                    return
                }

                this.isApproveProcessing = true
                doActionAfterSavingFields(approveAction)
            }, 50)
        }

        const rejectAction = (isForceReject = false) => {
            let modalScope = $scope.$new()
            modalScope.task = angular.copy(this.task)
            modalScope.isForceReject = isForceReject

            let updatingTaskDataInProgress = false

            $modal.open({
                windowClass: 'reject-reason-modal',
                template: require('../../../templates/modals/reject-reason.html'),
                controller: 'ModalRejectReasonController',
                scope: modalScope
            }).result.then(response => {
                if (response.success) {
                    this.task = response.result
                    updateTaskProps()
                    $scope.$digest()
                    this.isRejectProcessing = false
                } else {
                    updatingTaskDataInProgress = true
                    changeTaskErrorHandler(response).finally(() => {
                        updatingTaskDataInProgress = false
                        this.isRejectProcessing = false
                    })
                }
                modalScope.$destroy()
            }).finally(() => {
                if (!updatingTaskDataInProgress) {
                    this.isRejectProcessing = false
                }
            })
        }

        this.rejectTask = () => {
            $timeout(() => {
                if (this.task.status === $scope.STATUS.APPROVED
                    || this.task.status === $scope.STATUS.REJECTED
                    || this.isApproveDisabled()
                    || this.isRejectProcessing) {
                    return
                }

                this.isRejectProcessing = true
                doActionAfterSavingFields(rejectAction)
            }, 50)
        }

        this.reopenTask = () => {
            if (this.isReopenDisabled() || this.isActionProcessing) {
                return
            }

            this.isActionProcessing = true
            ApiCalls.uncompleteTask(this.task)
                .then(data => {
                    if (data.result) {
                        this.task = data.result
                        updateTaskProps()
                        this.process = angular.copy(this.task.process)
                    }
                    this.isActionProcessing = false
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => this.isActionProcessing = false)
                })
            changed = true
        }

        const deleteCompleteAction = () => $location.path(this.url)
        const deleteAction = () => {
            if (!this.checkPermission(this.task, 'delete')) {
                return
            }
            if (DeferredAction.TaskDelete.isDeferred(this.task)) {
                DeferredAction.TaskDelete.immediately(this.task)
                    .then(deleteCompleteAction)
                    .catch((res) => PageSettings.errorHandlerModal(res, deleteCompleteAction))
            } else {
                DeferredAction.TaskDelete.addToDeferred(this.task)
                this.close()
            }
        }

        this.deleteTask = () => {
            doActionAfterSavingFields(deleteAction)
        }

        this.getCompleteReopenTooltip = () => {
            if (this.task.isCompleted) {
                return 'tooltips.uncompleteTask'
            } else if (this.task.status === $scope.STATUS.INACTIVE_REVIEW) {
                return { text: 'tooltips.taskIsPending', isWhite: true }
            } else if (!this.checkPermission(this.task, 'complete')) {
                return 'tooltips.completeNotAssignedTask'
            } else {
                return 'tooltips.completeTask'
            }
        }

        this.getSimpleCheckboxTooltip = () => {
            if (this.task.status === $scope.STATUS.ASSIGNED && this.checkPermission(this.task, 'complete')) {
                return 'tooltips.completeTask'
            } else if (this.task.status === $scope.STATUS.INACTIVE) {
                return { text: 'tooltips.completeInvalidTask', isWhite: true }
            } else if (this.task.status === $scope.STATUS.STOPPED_ON_REJECT) {
                return { text: 'tooltips.taskCancelled', isWhite: true }
            } else if (this.task.status === $scope.STATUS.INACTIVE_REVIEW) {
                return { text: 'tooltips.taskIsPending', isWhite: true }
            } else {
                return { text: 'tooltips.completeNotAssignedTaskDisabled', isWhite: true }
            }
        }

        this.getApprovalTooltip = (isRejectAction) => {
            switch (this.task.status) {
                case $scope.STATUS.ASSIGNED:
                    if (this.checkPermission(this.task, 'approve')) {
                        return isRejectAction ? 'tooltips.rejectTask' : 'tooltips.approveTask'
                    }
                    return { text: 'tooltips.completeNotAssignedTaskDisabled', isWhite: true }
                case $scope.STATUS.INACTIVE:
                    return { text: 'tooltips.completeInvalidTask', isWhite: true }
                case $scope.STATUS.STOPPED_ON_REJECT:
                    return { text: 'tooltips.taskCancelled', isWhite: true }
                case $scope.STATUS.INACTIVE_REVIEW:
                    return { text: 'tooltips.taskIsPending', isWhite: true }
                case $scope.STATUS.APPROVED:
                    if (!this.checkPermission(this.task, 'reopen')) {
                        return { text: 'tooltips.reopenRestrictedForApproved', isWhite: true }
                    }
                    return { text: 'tooltips.reopenApproval' }
                case $scope.STATUS.REJECTED:
                    if (!this.checkPermission(this.task, 'reopen')) {
                        return { text: 'tooltips.reopenRestrictedForRejected', isWhite: true }
                    }
                    return { text: 'tooltips.reopenApproval' }
                default:
                    return { text: 'tooltips.completeNotAssignedTaskDisabled', isWhite: true }
            }
        }

        this.getReviewTooltip = () => {
            return 'tooltips.requestChanges'
        }

        this.isCompleteDisabled = () => {
            return !this.checkPermission(this.task, 'complete') || this.checkDataModel() || this.checkUploadingFiles()
        }

        this.isReopenDisabled = () => {
            return !this.checkPermission(this.task, 'reopen')
        }

        this.isApproveDisabled = () => {
            return !this.checkPermission(this.task, 'approve') || this.checkDataModel() || this.checkUploadingFiles()
        }

        this.isCompleteOnBehalfVisible = () => {
            return !this.task.isCompleted && this.checkPermission(this.task, 'completeOnBehalf')
        }

        this.isCompleteOnBehalfEnabled = () => {
            return this.isCompleteOnBehalfVisible()
                && !this.checkDataModel()
                && !this.checkSavingFields()
        }

        this.isReviewDisabled = () => {
            return !(this.task.type === Constants.TASK.TYPE.REVIEW
                && this.task.status === $scope.STATUS.ASSIGNED
                && this.checkPermission(this.task, 'review'))
        }

        this.isForceApproveDisabled = () => {
            return this.task.type !== Constants.TASK.TYPE.REVIEW
                || this.task.status !== $scope.STATUS.INACTIVE_REVIEW
                || !this.checkPermission(this.task, 'forceApprove')
        }

        this.requestChangesIsAllowed = () => {
            return this.task.type === Constants.TASK.TYPE.REVIEW
                && this.task.status === $scope.STATUS.ASSIGNED
                && this.checkPermission(this.task, 'approve')
        }

        this.checkDueDate = (isPast) => {
            return utils.checkPrDueDate(this.task, isPast, DateHelper)
        }

        this.getDueDate = () => {
            return utils.getDueDate(this.task, DateHelper)
        }

        this.getIntervalStr = () => {
            return angular.toJson({ COUNT: this.task.dueDateInterval || 0 })
        }

        const reviewAction = () => {
            let modalScope = $scope.$new()
            modalScope.task = angular.copy(this.task)
            modalScope.isReviewAction = true

            let updatingTaskDataInProgress = false

            $modal.open({
                windowClass: 'reject-reason-modal',
                template: require('../../../templates/modals/reject-reason.html'),
                controller: 'ModalRejectReasonController',
                scope: modalScope
            }).result.then(response => {
                if (response.success) {
                    this.task = response.result
                    updateTaskProps()
                    $scope.$digest()
                    this.isRejectProcessing = false
                } else {
                    updatingTaskDataInProgress = true
                    changeTaskErrorHandler(response).finally(() => {
                        updatingTaskDataInProgress = false
                        this.isRejectProcessing = false
                    })
                }
                modalScope.$destroy()
            }).finally(() => {
                if (!updatingTaskDataInProgress) {
                    this.isRejectProcessing = false
                }
            })
        }

        this.reviewTask = () => {
            if (this.isReviewDisabled() || this.isRejectProcessing) {
                return
            }

            this.isRejectProcessing = true
            doActionAfterSavingFields(reviewAction)
        }

        this.forceApproveTask = () => {
            if (this.isForceApproveDisabled() || this.isApproveProcessing) {
                return
            }

            this.isApproveProcessing = true
            doActionAfterSavingFields(() => approveAction(true))
        }

        this.forceRejectTask = () => {
            if (this.isForceApproveDisabled() || this.isRejectProcessing) {
                return
            }

            this.isRejectProcessing = true
            doActionAfterSavingFields(() => rejectAction(true))
        }

        const checkRowErrors = (section, row) => {
            let sectionT = angular.copy(section)
            sectionT.columns.forEach(c => {
                let valueT = row.values.find(v => v.columnId === c.name.id)
                if (valueT && valueT.value && !valueT.value.noValue) {
                    c.value = valueT.value
                } else {
                    c.value = {}
                }
            })
            return !!sectionT.columns.find(f => dataModelUtils.checkField(f) === 'required')
        }

        this.checkDataModel = () => {
            if (!this.task.dataModel) {
                return
            }
            return this.task.dataModel.list.find(item => {
                let result
                if (item.section) {
                    if (item.section.isTable) {
                        if ((!item.section.rows || !item.section.rows.length) && item.section.isTableRequired) {
                            result = true
                        } else {
                            result = item.section.rows ? item.section.rows.find(r => checkRowErrors(item.section, r)) : null
                        }
                    } else {
                        item.section.fieldsWithValues.forEach(field => {
                            let fieldResult = dataModelUtils.checkField(field)
                            if (fieldResult) {
                                result = fieldResult
                            }
                        })
                    }
                }
                if (!result && item.field) {
                    result = dataModelUtils.checkField(item.field)
                }
                return !!result
            })
        }

        this.checkUploadingFiles = () => {
            return this.task.dataModel && utils.findSavingFields(this.task.dataModel, true).length > 0
        }

        this.checkUnsavedFields = () => {
            return this.task.dataModel && utils.findSavingFields(this.task.dataModel).length > 0
        }

        this.checkSavingFields = () => {
            return this.isSavingDataModel || (this.task.dataModel && utils.findSavingFields(this.task.dataModel).length > 0)
        }

        this.getRequiredFields = () => {
            let res = []
            if (this.task && this.task.dataModel) {
                this.task.dataModel.list.forEach(item => {
                    if (item.section) {
                        if (item.section.isTable) {
                            if (item.section.rows) {
                                item.section.rows.forEach(r => {
                                    if (checkRowErrors(item.section, r)) {
                                        res.push({ section: item.section, row: r })
                                    }
                                })
                            }
                        } else {
                            item.section.fieldsWithValues.forEach(field => {
                                if (dataModelUtils.checkField(field)) {
                                    res.push({ field: field, section: item.section })
                                }
                            })
                        }
                    }
                })
            }
            return res
        }

        this.findRequiredField = () => {
            let error = this.getRequiredFields()[0]
            if (error) {
                error.section.isClosed = false
                const elId = error.row ? error.section.id + error.row.rowIndex : error.field.name.id
                $timeout(() => PageSettings.scrollToElement(elId, '.task-section__content > article > main'), 200)
            }
        }

        this.openSelectActors = () => {
            if (this.checkPermission(this.task, 'editActors')) {
                this.selectActors = true
            }
        }

        this.closeSelectActors = () => {
            this.actorsToShow = null
            $timeout(() => $scope.$broadcast(this.eventReCalc), 100)
            if (angular.equals(utils.prepareActorsToSave(this.actorsForEdit), utils.prepareActorsToSave(this.task.actorsForEdit))) {
                return this.selectActors = false
            }
            if (utils.checkInvalidActors(this.actorsForEdit) || utils.checkActorsIsEmpty(this.actorsForEdit)) {
                updateActorsForEdit(this.task.actorsForEdit)
                this.selectActors = false
                return
            }

            if (this.actorsForEdit && (this.actorsForEdit.users.length || this.actorsForEdit.groups.length)) {
                Object.keys(this.actorsForEdit).forEach(key => {
                    this.actorsForEdit[key] = this.actorsForEdit[key].sort(compareActors)
                })
            } else if (this.task.actors) {
                delete this.task.actors
            }

            let putData = { field: 'actors' }
            if (this.actorsForEdit) {
                putData.actors = utils.prepareActorsToSave(this.actorsForEdit)
            }
            this.modifyTaskAvailabeToCount++
            ApiCalls.modifyTask(this.task.id, putData)
                .then(data => {
                    if (data.success) {
                        this.task = data.result
                        updateTaskProps()
                        this.modifyTaskAvailabeToCount--
                        if (!this.modifyTaskAvailabeToCount) {
                            this.selectActors = false
                            updateActorsForEdit(this.task.actorsForEdit)
                            $timeout(() => $scope.$broadcast(this.eventReCalc))
                        }
                    }
                })
                .catch(changeTaskErrorHandler)
        }

        this.onCreateGroupError = (errorMsg, errorResp) => {
            PageSettings.createGroupHandlerModal(errorMsg, errorResp)
        }

        this.updateTitle = () => {
            if (!this.task.name || taskCopy.name === this.task.name) {
                return
            } else {
                this.task.version++
            }
            ApiCalls.modifyTask(this.task.id, { field: 'name', name: this.task.name.trim() })
                .then(data => {
                    if (data.success) {
                        taskCopy.name = data.result.name
                        this.task.version = data.result.version
                    }
                })
                .catch(changeTaskErrorHandler)
        }
        this.editDescr = ($event) => {
            if (this.task.template || !this.checkPermission(this.task, 'edit')
                || ($event && $event.target && $event.target.nodeName === 'A')) {
                return
            }
            if ($event) {
                $event.stopPropagation()
            }
            this.editDescription = true
        }

        this.saveDescr = ($event) => {
            if ($event && $event.relatedTarget && $event.relatedTarget.classList.contains('mdh-item')) {
                return
            }
            let descrEl = document.getElementById('task-description') //eslint-disable-line
            this.task.description = descrEl.value
            if (taskCopy.description === this.task.description) {
                this.editDescription = false
                return
            } else {
                this.task.version++
            }
            ApiCalls.modifyTask(this.task.id, { field: 'description', description: this.task.description })
                .then(data => {
                    if (data.success) {
                        this.editDescription = false
                        taskCopy.description = data.result.description
                        this.task.version = data.result.version
                        descrEl.blur()
                    }
                })
                .catch(errResponse => {
                    changeTaskErrorHandler(errResponse).finally(() => {
                        this.editDescription = false
                        descrEl.blur()
                    })
                })
        }

        this.updateDate = (newDueDate) => {
            let dueDateInCompanyTimeZone = DateHelper.dateToCompanyEndOfDay(newDueDate)

            this.task.dueDate = dueDateInCompanyTimeZone
            this.task.dueDateInUTC = DateHelper.companyEndOfDayToDateInUTC(dueDateInCompanyTimeZone)

            if (taskCopy.dueDate === dueDateInCompanyTimeZone) {
                return
            }
            $scope.$broadcast(this.eventReCalc)

            let putData = { field: 'dueDate' }
            if (newDueDate) {
                putData.dueDate = newDueDate
            }
            ApiCalls.modifyTask(this.task.id, putData)
                .then(data => {
                    if (data.success) {
                        taskCopy.dueDate = data.result.dueDate
                        this.task.version = data.result.version
                    }
                })
                .catch(changeTaskErrorHandler)
        }

        this.checkPermission = (obj, permission) => {
            return obj && obj.permissions ? obj.permissions.indexOf(permission) !== -1 : null
        }

        this.keyPressedDescr = e => {
            if (e && e.ctrlKey && (e.which === 10 || e.which === 13)) {
                e.preventDefault()
                this.saveDescr()
            }
        }

        $scope.$watch(() => this.actorsToShow, () => {
            $scope.$applyAsync()
            if (angular.isDefined(this.actorsToShow)) {
                this.popupActors = makeRemainsActors(this.task.actors, this.actorsToShow)
            }
        }, true)

        $scope.$watch(() => this.selectActors, (val) => {
            if (val === false) {
                if (angular.isDefined(this.actorsToShow)) {
                    this.popupActors = makeRemainsActors(this.task.actors, this.actorsToShow)
                }
            }
        }, true)

        let customExit = (event, nextState, nextStateParams) => {
            if (this.checkSavingFields() && nextState.name !== 'login') {
                event.preventDefault()
                PageSettings.openSavingIndicator('task')
                setTimeout(() => {
                    $state.go(nextState.name, nextStateParams)
                }, 1000)
            } else {
                PageSettings.closeSavingIndicator('task')
            }
        }

        let defaultExit = () => {
            if (this.checkSavingFields()) {
                $window.event.returnValue = $translate.instant('taskView.text.unsavedChanges')
            }
        }

        let destroyCallback = $scope.$on('$stateChangeStart', customExit)
        $window.addEventListener('beforeunload', defaultExit)

        const taskUpdatedListener = ({
            taskId,
            isDeleted,
            socketId,
            dueDateChanged = false,
            formulaChanged = false
        }) => {
            const eventIsTriggeredByUser = PusherHelper.checkIfEventShouldBeSkipped(socketId)
            if (eventIsTriggeredByUser && !dueDateChanged && !formulaChanged || taskId !== this.task.id) {
                return
            }

            if (isDeleted) {
                return deleteCompleteAction()
            }

            updateTaskData(eventIsTriggeredByUser && dueDateChanged, formulaChanged)
        }

        const commentDeletedListener = (data) => {
            if (data.task.id === this.task.id) {
                updateTaskData()
            }
        }

        PusherHelper.subscribe('taskUpdated', taskUpdatedListener)
        PusherHelper.subscribe('commentDeleted', commentDeletedListener)

        $scope.$on('$destroy', () => {
            destroyCallback()
            $window.removeEventListener('beforeunload', defaultExit)
            PusherHelper.unsubscribe('taskUpdated', taskUpdatedListener)
            PusherHelper.unsubscribe('commentDeleted', commentDeletedListener)
            $rootScope.isHideIntercom = false
        })

        let highlightTimer
        this.highlightAssignButton = () => {
            $timeout.cancel(highlightTimer)
            if (!this.task.isAssigned && this.checkPermission(this.task, 'selfAssign')) {
                this.assignIsHighlighted = true
                highlightTimer = $timeout(() => {
                    this.assignIsHighlighted = null
                }, 5000)
            }
        }
    }
}
