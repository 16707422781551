export function yesnoDataField() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
            field: '=',
            isDisabled: '=?',
            onChange: '&?',
            checkFieldError: '&?'
        },
        template: require('../templates/yesno-data-field.html'),
        link: (scope, el, attrs, ctrl) => {
            if (!scope.field) {
                return;
            }
            scope.toggleValue = value => {
                if (scope.isDisabled) {
                    return;
                }
                if (scope.checkValue(value)) {
                    ctrl.$setViewValue(null);
                } else {
                    ctrl.$setViewValue(value);
                }
                if (scope.onChange) {
                    scope.onChange();
                }
            };
            scope.checkValue = value => scope.field.value && scope.field.value.yesNoValue === value;
            scope.isRequired = () => {
                if (scope.checkFieldError) {
                    return scope.checkFieldError();
                }
                return (!scope.field.value || !scope.field.value.yesNoValue) && scope.field.settings && scope.field.settings.isRequiredToFill;
            };
        }
    };
}
