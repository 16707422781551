import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../icon'

function ActorGroupPrintView ({name, ...props}) {
    return (
        <span className="group" {...props}>
            {/*<span className="avatar-small">*/}
            {/*    <Icon name={'assignee_group'}/>*/}
            {/*</span>*/}
            <span className="text">{name}</span>
        </span>
    )
}

ActorGroupPrintView.propTypes = {
    name: PropTypes.string
}

export default ActorGroupPrintView
