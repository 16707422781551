import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import TimerFromNow from './timer-from-now.jsx'
import ProcessLink from './process-link.jsx'
import TasksListTile from './tasks-list-tile.jsx'
import { tmplColors } from '../../libs/colors.js'

import { translate } from './utils'

class MainTasksList extends React.Component {
    static propTypes = {
        tree: PropTypes.arrayOf(Object),
        currentUser: PropTypes.object,
        tasksGroup: PropTypes.number,
        activeMode: PropTypes.string,
        functions: PropTypes.object,
        config: PropTypes.object,
        payload: PropTypes.object
    }

    render () {
        const { tree, activeMode, tasksGroup, functions, config, payload, currentUser } = this.props
        if (!tree) {
            return null
        }
        let nodes = tree.map((node, index) => {
            let header = null
            if (activeMode === 'My' && node.tasks.length) {
                if (node.dueDate) {
                    const classNames = cn('task-duedate-section-header', 'bg-gray', {
                        'bg-red': node.dueDate.title === translate('label.overdue')
                    })
                    header = (
                        <header className={classNames}>
                            {node.dueDate.title}&nbsp;
                            {node.dueDate.date && <span>&nbsp;• &nbsp;{node.dueDate.date}</span>}
                        </header>
                    )
                } else if (node.process) {
                    const processView = (event) => functions.goToProcess(node, event)
                    if (node.process.id) {
                        let headerIcon = null
                        let hStyle = null
                        if (node.process.icon) {
                            let iconStyle = { background: node.process.icon.color }
                            hStyle = { backgroundColor: tmplColors[node.process.icon.color] }
                            let iconClasses = cn({ icon: true }, node.process.icon.id)
                            headerIcon = (
                                <div className="avatar-small" style={iconStyle}>
                                    <i className={iconClasses}/>
                                </div>
                            )
                        } else {
                            headerIcon = (
                                <div className="avatar-small default">
                                    <i className="icon-File-Settings"/>
                                </div>
                            )
                        }
                        header = (
                            <header className="task-process-section-header bg-gray" style={hStyle}>
                                <div className="first-row">
                                    {headerIcon}
                                    {node.process && (
                                        <ProcessLink task={node} process={node.process} config={config}
                                                     payload={payload} link={processView}/>
                                    )}
                                </div>
                                <div className="second-row">
                                    <div className="top">
                                        <TimerFromNow start={node.process.startedDate} isStarted={true}/>&nbsp;
                                        {translate('label.user.by')}&nbsp;
                                        <span className="owner-name">{node.process.starter.fullName}</span>
                                    </div>
                                    {node.process.dueDate && (
                                        <div className="dueDate">
                                            {node.process.isPastDue && (
                                                <span className="red">
                                                    {translate('label.pastDue')} {node.process.formattedDueDate}
                                                </span>
                                            )}
                                            {!node.process.isPastDue && (
                                                <span className={node.process.isWarnDue ? 'red' : ''}>
                                                    {node.process.warnFormattedDueDate}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </header>
                        )
                    } else {
                        header = (
                            <header className="task-process-section-header--empty bg-gray">
                                {translate('tasks-group.noProcess')}
                            </header>
                        )
                    }
                }
            }
            let content = !!node.tasks && !!node.tasks.length && node.tasks.map((task, taskIndex) => {
                return (
                    <TasksListTile
                        key={taskIndex}
                        index={taskIndex}
                        functions={functions}
                        currentUser={currentUser}
                        config={config}
                        payload={payload}
                        task={task}
                        tasksGroup={tasksGroup}
                        activeMode={activeMode}
                        node={node}
                        isLast={node.tasks.length === 1}
                    />
                )
            })
            return (
                <li key={index}>
                    {header}
                    {content}
                </li>
            )
        })
        return <ul>{nodes}</ul>
    }
}

export default MainTasksList
