import colors from '../libs/colors';
import uid from 'uid';
import utils from '../modules/processes/utils';
import adminUtils from '../modules/admin/utils';
import config from '../config';
import {getTextWidth} from '../utils';

const BUTTONS = {
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    ESCAPE: 27,
    ENTER: 13,
    DELETE: 46,
    BACK_SPACE: 8,
    TAB: 9
};

export function selectize($timeout, $filter, $translate, $rootScope, $document, PageSettings, $translateSanitization, $modal) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            actorsModel: '=ngModel',
            users: '=',
            withoutRoles: '&?',
            withoutStarterDirectManagerRole: '&?',
            placeholder: '@',
            event: '@?',
            hint: '@',
            autofocus: '@',
            adHocTask: '@',
            onsubmit: '&',
            isCreateGroup: '=?',
            isCreateGroupWithoutExistUsers: '=?',
            onCreateGroupError: '&?',
            onClearServerError: '&?',
            onErrorMsgChanged: '&?',
            outerErrorMsg: '=?',
            userLimit: '=?',
            userLimitErrorMsg: '=?',
            emptyErrorMsg: '=?',
            isAllowedEmpty: '=?',
            isHideErrors: '=?',
            isHideInitialEmptyErrorMsg: '=?',
            isDisablePopupForError403: '=?',
            isDisableToasterPopup: '=?',
            dirty: '=?',
            dirtyEvent: '@?'
        },
        template: require('../templates/selectize.html'),

        controller: ($scope, $element, currentUser, AdminApiCalls) => {
            'ngInject';
            // Check element by id in array
            let checkInArray = (array, model) => {
                return array.find(item => item.id === model.id);
            };

            $scope.createItemButton = {isActive: false};
            $scope.currentUser = currentUser;

            // Get all users in tree
            let getAllUsers = () => {
                let allUsers = [];
                if ($scope.users && $scope.users.groups) {
                    $scope.users.groups.forEach(group => {
                        if (group && group.users) {
                            group.users.forEach(user => {
                                if (!checkInArray(allUsers, user)) {
                                    allUsers.push(angular.copy(user));
                                }
                            });
                        }
                    });
                }

                if ($scope.users && $scope.users.users) {
                    $scope.users.users.forEach(user => {
                        if (!checkInArray(allUsers, user)) {
                            allUsers.push(angular.copy(user));
                        }
                    });
                }
                return allUsers;
            };

            let initActors = () => {
                $scope.actors = $scope.users ? angular.copy($scope.users) : {};
                $scope.actors.users = $filter('orderBy')($scope.actors.users, $scope.getUserName);

                $scope.actors.groups.forEach(group => {
                    group.users = $filter('orderBy')(group.users, $scope.getUserName);
                });

                if ($scope.withoutRoles && $scope.withoutRoles() && $scope.actors.specialRoles) {
                    $scope.actors.specialRoles = [$scope.actors.specialRoles.find(role => role.allUsers)];
                }

                if ($scope.withoutStarterDirectManagerRole && $scope.withoutStarterDirectManagerRole() && $scope.actors.specialRoles) {
                    $scope.actors.specialRoles = $scope.actors.specialRoles.filter(role => !role.isStarterDirectManager)
                }

                $scope.actors.allUsers = getAllUsers();
            };

            // Invite new user
            const openInvitePopup = () => {
                let modal = $modal.open({
                    animation: true,
                    windowClass: 'invite',
                    template: require('../templates/modals/invite.html'),
                    controller: 'InviteModalController',
                    backdrop: 'static',
                    resolve: {
                        groups: (ApiCalls) => {
                            'ngInject';
                            return ApiCalls.getActorsGroups();
                        },
                        preloadedState: () => {
                            return [{email: $scope.searchText}];
                        }
                    }
                });
                modal.result.then(data => {
                    data.result.forEach(user => {
                        user.email = user.fullName;
                        $scope.addUser(user);
                        $scope.actors.users.push(user);
                        $scope.users.users.push(user);
                        $rootScope.$broadcast('user-invited', {...user});

                        $timeout(() => {
                            $scope.closeSelectize();
                            $scope.inactiveAll($scope.actors);
                        });

                        if(!$scope.isDisableToasterPopup) {
                            $translateSanitization.useStrategy(null);
                            PageSettings.toasterData = {
                                iconClass: ' icon-company_groups',
                                text: $translate.instant('label.userInvited', {name: user.fullName || user.email}),
                                timeout: 3000
                            };
                            $translateSanitization.useStrategy(config.sanitize_strategy);
                        }
                    });
                }).finally(() => {
                    $timeout(() => {
                        $scope.waitUserInvitation = false;
                    });
                });
            };

            // Initialization
            $scope.placeholderModel = $scope.placeholder;
            initActors();
            $scope.searchText = '';
            $scope.inputWidth = 0;

            $scope.getColor = (index) => {
                return colors[index - 1];
            };

            $scope.emailOrGroupNameIsExisted = () => {
                let text = $scope.groupNameTrim($scope.searchText).toLowerCase();
                const isEmail = $scope.searchTextIsEmail(text);
                if (isEmail) {
                    return $scope.globalActors.users.findIndex(user => user.email.toLowerCase() === text) !== -1;
                } else {
                    return $scope.globalActors.groups.findIndex(group => group.name.toLowerCase() === text) !== -1;
                }
            };

            $scope.searchTextIsEmail = (text) => {
                return !!(text && config.pattern_emails.test(text));
            };

            $scope.getCreateItemLabel = () => {
                let text = $scope.groupNameTrim($scope.searchText);
                if ($scope.searchTextIsEmail(text)) {
                    return `${$translate.instant('label.inviteUser')} "${text}"`;
                } else {
                    return `${$translate.instant('label.createGroup')} "${text}"`;
                }
            };

            $scope.getUserName = (user) => {
                const name = user.fullName || user.name;
                return name.trim() ? name : user.email;
            };

            // performance
            const invalidMsg = $translate.instant('validation.text.invalidActors');
            const requiredMsg = $translate.instant('validation.required');
            const userLimitDefaultMsg = $translate.instant('validation.task-title.actors.limitUsers');

            // Show/hide placeholder
            $scope.$watch('actorsModel', model => {
                $scope.actorsEmptyMsg = !$scope.isAllowedEmpty && utils.checkActorsIsEmpty(model) ? ($scope.emptyErrorMsg || requiredMsg) : '';
                $scope.invalidActorsMsg = utils.checkInvalidActors(model) ? invalidMsg : '';

                if (angular.isUndefined(model)) {
                    return;
                }
                if (!model.specialRoles.length && !model.groups.length && !model.users.length) {
                    $scope.placeholderModel = $scope.placeholder;
                    return;
                }
                if ($scope.adHocTask && model.users.length === 1 && model.users[0].id === currentUser.id) {
                    $scope.placeholderModel = $scope.placeholder;
                    return;
                }

                if ((model.specialRoles && model.specialRoles.length) || (model.groups && model.groups.length) || (model.users && model.users.length)) {
                    $scope.placeholderModel = '';
                } else {
                    $scope.placeholderModel = $scope.placeholder;
                }
            }, true);
            $scope.$watch('actorsModel', model => {
                $timeout(() => {
                    $scope.handleInputChange();
                });

                if (angular.isUndefined(model)) {
                    return;
                }
                if (!model.specialRoles.length && !model.groups.length && !model.users.length) {
                    $scope.placeholderModel = $scope.placeholder;
                    return;
                }
                if ($scope.adHocTask && model.users.length === 1 && model.users[0].id === currentUser.id) {
                    $scope.placeholderModel = $scope.placeholder;
                    return;
                }

                if ((model.specialRoles && model.specialRoles.length) || (model.groups && model.groups.length) || (model.users && model.users.length)) {
                    $scope.placeholderModel = '';
                } else {
                    $scope.placeholderModel = $scope.placeholder;
                }
            }, true);

            $scope.$watch('serverErrorMsg || ((dirty || !isHideInitialEmptyErrorMsg) && actorsEmptyMsg) || localUserLimitErrorMsg || invalidActorsMsg || outerErrorMsg', (errorMsg) => {
                if(!$scope.isHideErrors) {
                    $scope.errorMsg = errorMsg;
                }
                if($scope.onErrorMsgChanged) {
                    $scope.onErrorMsgChanged({errorMsg: errorMsg});
                }
            });

            $scope.$watch('actorsModel.users.length', (countUsers) => {
                if(countUsers && $scope.userLimit && countUsers > $scope.userLimit) {
                    $scope.localUserLimitErrorMsg = $scope.userLimitErrorMsg || userLimitDefaultMsg;
                } else {
                    $scope.localUserLimitErrorMsg = undefined;
                }
            });

            // Add/remove user in tree view
            $scope.addUser = user => {
                if (!checkInArray($scope.actorsModel.users, user)) {
                    $scope.actorsModel.users.push(angular.copy(user));
                    $scope.searchText = '';
                    $scope.dirty = true;
                }
            };
            $scope.removeUser = (user)=> {
                $scope.clearErrors();
                $scope.actorsModel.users = $scope.actorsModel.users.filter(userLoc => {
                    return userLoc.id !== user.id;
                });
                $scope.dirty = true;
            };

            // Add/remove group in tree view
            $scope.addGroup = (group) => {
                if (!checkInArray($scope.actorsModel.groups, group)) {
                    $scope.actorsModel.groups.push(group);
                    $scope.searchText = '';
                    $scope.dirty = true;
                }
            };
            $scope.removeGroup = (group) => {
                $scope.clearErrors();
                $scope.actorsModel.groups = $scope.actorsModel.groups.filter(groupLoc => {
                    return groupLoc.id !== group.id;
                });
                $scope.dirty = true;
            };

            const rolesAreEqual = (roleA, roleB) => {
                if (roleA.formField && roleB.formField) {
                    return roleA.formField.id === roleB.formField.id;
                }
                return roleA.name === roleB.name;
            };

            // Add/remove role in tree view
            $scope.addRole = (role) => {
                if (!$scope.actorsModel.specialRoles.find(r => rolesAreEqual(r, role))) {
                    $scope.actorsModel.specialRoles.push(role);
                    $scope.searchText = '';
                }
                $scope.dirty = true;
            };
            $scope.removeRole = (role) => {
                $scope.clearErrors();
                $scope.actorsModel.specialRoles = $scope.actorsModel.specialRoles.filter(r => {
                    return !rolesAreEqual(r, role);
                });
                $scope.dirty = true;
            };

            // Clear ALL btn
            $scope.clearActorsModel = () => {
                $scope.actorsModel.groups = [];
                $scope.actorsModel.users = [];
                $scope.actorsModel.specialRoles = [];
                $scope.dirty = true;
            };


            //
            // Keydown events...
            //
            $scope.clickMap = {
                parent: -1,
                child: -1,
                role: -1
            };

            // Inactive all
            $scope.inactiveAll = (actors) => {
                if (!actors) {
                    return;
                }

                actors.groups.forEach(group => {
                    group.isActive = false;
                    if (group.users) {
                        group.users.forEach(user => {
                            user.isActive = false;
                        });
                    }
                });
                actors.users.forEach(user => {
                    user.isActive = false;
                });
                if (actors.specialRoles) {
                    actors.specialRoles.forEach(role => {
                        role.isActive = false;
                    });
                }
                $scope.clickMap.parent = -1; // -1 = not clicked, -2 = after group
                $scope.clickMap.child = -1; // -1 = not clicked
                $scope.clickMap.role = -1; // -1 = not clicked

                $scope.createItemButton.isActive = false;
            };

            // add to input
            $scope.addToInput = actors => {
                if ($scope.clickMap.role >= 0 && $scope.clickMap.parent === -1
                    || $scope.clickMap.role >= 0 && $scope.clickMap.parent === -2
                    && $scope.clickMap.child === -1) {

                    $scope.addRole(actors.specialRoles[$scope.clickMap.role]);
                    return;
                }

                if ($scope.clickMap.child >= 0) {
                    if ($scope.clickMap.parent >= 0) {
                        $scope.addUser(actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child]);
                    } else {
                        $scope.addUser(actors.users[$scope.clickMap.child]);
                    }
                } else if ($scope.clickMap.parent >= 0) {
                    $scope.addGroup(actors.groups[$scope.clickMap.parent]);
                }
            };

            // remove from input
            $scope.rmFromInput = () => {
                if ($scope.searchText) {
                    return;
                }

                if ($scope.actorsModel.users && $scope.actorsModel.users.length) {
                    $scope.actorsModel.users.pop();
                    return;
                }
                if ($scope.actorsModel.groups && $scope.actorsModel.groups.length) {
                    $scope.actorsModel.groups.pop();
                    return;
                }
                if ($scope.actorsModel.specialRoles && $scope.actorsModel.specialRoles.length) {
                    $scope.actorsModel.specialRoles.pop();
                }
            };

            // toggle Group
            $scope.openGroup = () => {
                if ($scope.clickMap.parent > -1 && $scope.clickMap.child === -1 && $scope.actors.groups[$scope.clickMap.parent].users) {
                    $scope.actors.groups[$scope.clickMap.parent].isOpen = true;
                }
            };
            $scope.closeGroup = () => {
                if ($scope.clickMap.parent > -1 && $scope.clickMap.child === -1) {
                    $scope.actors.groups[$scope.clickMap.parent].isOpen = false;
                }
            };

            $scope.groupNameTrim = (groupName) => {
                return adminUtils.groupNameTrimRestrictedChar(groupName).substr(0, 45);
            };

            const inputStyle = window.getComputedStyle($element.find('.select-input-ctrl')[0]);
            const inputFontStyle = inputStyle.font || inputStyle['font-size'] + ' ' + inputStyle['font-family'];
            const paddingRight = 20;

            $scope.handleInputChange = () => {
                $scope.searchText = $scope.groupNameTrim($scope.searchText);
                const inputValue = $scope.searchText || $scope.placeholderModel || '';
                $scope.inputWidth = getTextWidth(inputValue, inputFontStyle) + paddingRight;
            };

            $scope.handleInputChange();

            // Add new group
            $scope.createNewGroup = () => {
                let groupNameTrimmed = $scope.groupNameTrim($scope.searchText);

                if ($scope.searchTextIsEmail(groupNameTrimmed)) {
                    $scope.waitUserInvitation = true;
                    return openInvitePopup();
                }

                if (groupNameTrimmed) {
                    let createGroup = (resultGroup) => {
                        if($scope.isCreateGroupWithoutExistUsers && resultGroup.users) {
                            resultGroup.usersCount = resultGroup.users.length;
                            delete resultGroup.users;
                        }
                        $rootScope.$broadcast('group-creation', Object.assign({}, resultGroup));
                        $scope.users.groups.push(angular.copy(resultGroup));
                        $scope.actors.groups.push(resultGroup);
                        $scope.addGroup(resultGroup);
                        $scope.searchText = '';
                    };
                    AdminApiCalls.addGroup({name: groupNameTrimmed}, true, $scope.isDisablePopupForError403).then(data => {
                        $scope.serverErrorMsg = null;
                        if (data.success) {
                            createGroup(data.result);
                            if(!$scope.isDisableToasterPopup) {
                                $translateSanitization.useStrategy(null);
                                let text = $translate.instant('label.groupCreated', {groupName: data.result.name});
                                $translateSanitization.useStrategy(config.sanitize_strategy);
                                PageSettings.toasterData = {
                                    iconClass: ' icon-company_groups',
                                    text: text,
                                    timeout: 3000
                                };
                            }
                        }
                    }, errorResp => {
                        if(errorResp.status === 517) {
                            $scope.serverErrorMsg = null;
                            createGroup(errorResp.data.existing);
                        }
                        $scope.serverErrorMsg = null;
                        if (errorResp.data) {
                            $scope.serverErrorMsg = errorResp.data.displayError || errorResp.data.error || errorResp.data.name[0].fieldError;
                        }
                        if(!$scope.serverErrorMsg) {
                            $scope.serverErrorMsg = $translate.instant('error.common');
                        }
                        if($scope.onCreateGroupError) {
                            $scope.onCreateGroupError({
                                errorMsg: $scope.serverErrorMsg,
                                errorResp: angular.copy(errorResp)
                            });
                        }
                        $timeout(() => $scope.closeSelectize(true));
                    });
                }
            };
            $scope.getMembersCount = count => {
                return angular.toJson({COUNT: count || 0});
            };

            $scope.hoverAction = (obj, name, group) => {
                $scope.inactiveAll($scope.actors);
                $scope.inactiveAll($scope.globalActors);
                obj.isActive = true;
                let gRows = $scope.actors.specialRoles;

                switch (name) {
                    case 'user':
                        $scope.clickMap.role = gRows && gRows.length ? gRows.length - 1 : -1;
                        if (group) {
                            $scope.clickMap.parent = $scope.actors.groups.indexOf(group);
                            $scope.clickMap.child = group.users.indexOf(obj);
                        } else {
                            $scope.clickMap.parent = -2;
                            $scope.clickMap.child = $scope.actors.users.indexOf(obj);
                        }
                        break;
                    case 'group':
                        $scope.clickMap.role = gRows && gRows.length ? gRows.length - 1 : -1;
                        $scope.clickMap.child = -1;
                        $scope.clickMap.parent = $scope.actors.groups.indexOf(obj);
                        break;
                    case 'role':
                        $scope.clickMap.role = $scope.actors.specialRoles ? $scope.actors.specialRoles.indexOf(obj) : -1;
                        $scope.clickMap.parent = -1;
                        $scope.clickMap.child = -1;
                        break;
                    default:
                        $scope.clickMap.role = -1;
                        $scope.clickMap.parent = -1;
                        $scope.clickMap.child = -1;
                }
            };

            // to prev list item
            $scope.prevActive = actors => {
                let el = $scope.parentEl.getElementsByClassName('active');
                if (el[0] && el[0].offsetTop < $scope.parentEl.scrollTop + 32) {
                    $scope.parentEl.scrollTop = $scope.parentEl.scrollTop - 32;
                }

                if ($scope.clickMap.child < 0) {
                    if ($scope.clickMap.parent === 0) {
                        actors.groups[$scope.clickMap.parent].isActive = false;
                        $scope.clickMap.parent = -1;
                        if ($scope.clickMap.role >= 0) {
                            actors.specialRoles[$scope.clickMap.role].isActive = true;
                        }
                        return;
                    } else if ($scope.clickMap.parent < 0 && actors.specialRoles && $scope.clickMap.role > 0) {
                        actors.specialRoles[$scope.clickMap.role].isActive = false;
                        $scope.clickMap.role--;
                        actors.specialRoles[$scope.clickMap.role].isActive = true;
                        return;
                    }
                }

                switch ($scope.clickMap.parent) {
                    case -1:
                        break;
                    case -2: // After groups
                        if ($scope.createItemButton.isActive) {
                            $scope.createItemButton.isActive = false;
                            $scope.clickMap.child = actors.users.length - 1;
                            actors.users[$scope.clickMap.child].isActive = true;
                        } else if ($scope.clickMap.child > 0) {
                            actors.users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.child--;
                            actors.users[$scope.clickMap.child].isActive = true;
                        } else if (actors.groups && actors.groups.length) {
                            actors.users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.parent = actors.groups.length - 1;
                            if (actors.groups[$scope.clickMap.parent].isOpen) {
                                $scope.clickMap.child = actors.groups[$scope.clickMap.parent].users.length - 1;
                                actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = true;
                            } else {
                                $scope.clickMap.child = -1;
                                actors.groups[$scope.clickMap.parent].isActive = true;
                            }
                        } else if (actors.specialRoles && actors.specialRoles.length) {
                            if ($scope.clickMap.child === 0) {
                                actors.users[$scope.clickMap.child].isActive = false;
                                $scope.clickMap.role = actors.specialRoles.length - 1;
                                $scope.clickMap.child = -1;
                                actors.specialRoles[$scope.clickMap.role].isActive = true;
                            }
                        }
                        break;
                    default:
                        if ($scope.clickMap.child < 0) {
                            if ($scope.clickMap.parent > 0) {
                                actors.groups[$scope.clickMap.parent].isActive = false;
                                $scope.clickMap.parent--;
                                if (actors.groups[$scope.clickMap.parent].isOpen && !$scope.searchText) {
                                    $scope.clickMap.child = actors.groups[$scope.clickMap.parent].users.length - 1;
                                    actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = true;
                                } else {
                                    actors.groups[$scope.clickMap.parent].isActive = true;
                                }
                            }
                        } else if ($scope.clickMap.child === 0) {
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.child = -1;
                            actors.groups[$scope.clickMap.parent].isActive = true;
                        } else {
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.child--;
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = true;
                        }
                }
            };

            // to next list item
            $scope.nextActive = actors => {
                let el = $scope.parentEl.getElementsByClassName('active');
                if (el[0]) {
                    let scroll = el[0].offsetTop - 139;
                    if (scroll > 0 && $scope.parentEl.scrollTop < scroll) {
                        $scope.parentEl.scrollTop = scroll;
                    }
                }

                if ($scope.clickMap.role < 0) {
                    if (actors.specialRoles && actors.specialRoles.length) {
                        $scope.clickMap.role = 0;
                        actors.specialRoles[$scope.clickMap.role].isActive = true;
                        return;
                    }
                } else {
                    if ((actors.groups.length || actors.groups.users || actors.users.length) && actors.specialRoles[$scope.clickMap.role]) {
                        actors.specialRoles[$scope.clickMap.role].isActive = false;
                    }
                    if ($scope.clickMap.role + 1 < actors.specialRoles.length) {
                        $scope.clickMap.role++;
                        actors.specialRoles[$scope.clickMap.role].isActive = true;
                        return;
                    }
                }

                switch ($scope.clickMap.parent) {
                    case -1: // Not clicked
                        if (actors.groups && actors.groups.length) {
                            $scope.clickMap.parent = 0;
                            actors.groups[$scope.clickMap.parent].isActive = true;
                        } else if (actors.users && actors.users.length) {
                            $scope.clickMap.parent = -2;
                            $scope.clickMap.child = 0;
                            actors.users[$scope.clickMap.child].isActive = true;
                        }
                        break;

                    case -2: // After groups
                        if ($scope.clickMap.child + 1 < actors.users.length) {
                            if ($scope.clickMap.child >= 0) {
                                actors.users[$scope.clickMap.child].isActive = false;
                            }
                            actors.users[++$scope.clickMap.child].isActive = true;
                        } else if (!$scope.createItemButton.isActive && $scope.searchText && ($scope.globalActors.users.length || $scope.globalActors.groups.length) && $scope.isCreateGroup && !$scope.emailOrGroupNameIsExisted()) {
                            actors.users[$scope.clickMap.child].isActive = false;
                            $scope.createItemButton.isActive = true;
                        }
                        break;

                    default:
                        if ($scope.clickMap.child < 0) { // Not witin group
                            if (actors.groups[$scope.clickMap.parent].isOpen && !$scope.searchText) {
                                $scope.clickMap.child = 0;
                                actors.groups[$scope.clickMap.parent].isActive = false;
                                actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = true;
                            } else if ($scope.clickMap.parent + 1 < actors.groups.length) {
                                actors.groups[$scope.clickMap.parent].isActive = false;
                                $scope.clickMap.parent++;
                                actors.groups[$scope.clickMap.parent].isActive = true;
                            } else if (actors.users && actors.users.length) {
                                actors.groups[$scope.clickMap.parent].isActive = false;
                                $scope.clickMap.parent = -2;
                                $scope.clickMap.child = 0;
                                actors.users[$scope.clickMap.child].isActive = true;
                            }
                        // Within group
                        } else if ($scope.clickMap.child + 1 < actors.groups[$scope.clickMap.parent].users.length) {
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.child++;
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = true;
                        } else if ($scope.clickMap.parent + 1 < actors.groups.length) {
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.child = -1;
                            $scope.clickMap.parent++;
                            actors.groups[$scope.clickMap.parent].isActive = true;
                        } else {
                            actors.groups[$scope.clickMap.parent].users[$scope.clickMap.child].isActive = false;
                            $scope.clickMap.parent = -2;
                            $scope.clickMap.child = 0;
                            actors.users[$scope.clickMap.child].isActive = true;
                        }
                } // END: switch
            };

            $scope.clearErrors = () => {
                if ($scope.serverErrorMsg) {
                    $scope.serverErrorMsg = null;
                    if($scope.onClearServerError) {
                        $scope.onClearServerError();
                    }
                }
            };

            $scope.$watch('searchText', (newValue, oldValue) => {
                $scope.clickMap = {
                    parent: -1,
                    child: -1,
                    role: -1
                };
                $scope.clearErrors();
                $scope.handleInputChange();

                if (!oldValue) {
                    $scope.inactiveAll($scope.actors);
                }

                newValue = $scope.groupNameTrim(newValue);
                if (newValue) {
                    $scope.globalActors = {};
                    let usersTemp = $filter('userSearch')($scope.actors.allUsers, newValue);
                    $scope.globalActors.users = $filter('orderBy')(usersTemp, $scope.getUserName);
                    let groupsTemp = $filter('search')($scope.actors.groups, newValue);
                    $scope.globalActors.groups = $filter('orderBy')(groupsTemp, 'name');

                    if ($scope.actors.specialRoles) {
                        let rolesTemp = $filter('search')($scope.actors.specialRoles, newValue);
                        $scope.globalActors.specialRoles = $filter('orderBy')(rolesTemp, 'name');
                    }
                } else {
                    $scope.inactiveAll($scope.globalActors);
                }
                $scope.createItemButton.isActive = false;
            });

            $scope.$watch('users.groups.length', () => {
                initActors();
            });

            $scope.$watch('users.users.length', () => {
                initActors();
            });
        },

        link: (scope, element) => {
            const selectUID = uid(6);
            if (angular.isUndefined(scope.actorsModel)) {
                scope.actorsModel = {
                    groups: [],
                    users: [],
                    specialRoles: []
                };
            }
            let backup = angular.copy(scope.actorsModel);

            let docClickListener = () => {
                if (!scope.waitUserInvitation) {
                    scope.closeSelectize();
                    scope.inactiveAll(scope.actors);
                }
            };

            scope.setFocus = () => {
                element.find('.select-input-ctrl')[0].focus();
            };

            scope.revertChanges = () => {
                scope.actorsModel = angular.copy(backup);
            };
            let isOpen = false;
            scope.openSelectize = (event, isFocused) => {
                if (event) {
                    event.stopPropagation();
                }
                if (!scope.activeMode) {
                    $rootScope.$broadcast('selectize:open', selectUID);
                }
                if (!isFocused) {
                    $timeout(() => {
                        scope.setFocus();
                    });
                }
                scope.activeMode = true;
                if(!isOpen) {
                    $timeout(() => {
                        $document.on('click', docClickListener);
                    });
                    isOpen = true;
                }
                $timeout(() => {
                    scope.parentEl = element[0].querySelector('.dropdown-list');
                });
            };

            if (scope.autofocus) {
                $timeout(() => scope.openSelectize(), 50);
            }

            if (scope.event) {
                scope.$on(scope.event, () => scope.openSelectize());
            }
            if(scope.dirtyEvent) {
                scope.$on(scope.dirtyEvent, (event, dirty) => scope.dirty = !!dirty);
            }

            scope.closeSelectize = (noClearSearch) => {
                scope.activeMode = false;
                if (scope.onsubmit) {
                    scope.onsubmit();
                }
                $timeout(() => {
                    if (!noClearSearch) {
                        scope.searchText = '';
                    }
                    element.find('.select-input-ctrl')[0].blur();
                    if(isOpen) {
                        $document.off('click', docClickListener);
                        isOpen = false;
                    }
                });
            };

            let enterClicked;
            scope.keypress = ($event) => {
                let actors;
                if (scope.searchText) {
                    actors = scope.globalActors;
                } else {
                    actors = scope.actors;
                }

                switch ($event.keyCode) {
                    case BUTTONS.ARROW_DOWN:
                        scope.nextActive(actors);
                        break;
                    case BUTTONS.ARROW_RIGHT:
                        scope.openGroup();
                        break;
                    case BUTTONS.ARROW_UP:
                        scope.prevActive(actors);
                        break;
                    case BUTTONS.ARROW_LEFT:
                        scope.closeGroup();
                        break;
                    case BUTTONS.ESCAPE:
                        scope.closeSelectize();
                        break;
                    case BUTTONS.ENTER:
                        $event.preventDefault();
                        const searchText = scope.groupNameTrim(scope.searchText);
                        const roleNotFound = searchText && !scope.globalActors.specialRoles.length;
                        const usersNotFound = searchText && !scope.globalActors.users.length;
                        const groupsNotFound = searchText && !scope.globalActors.groups.length;
                        if (scope.createItemButton.isActive || (roleNotFound && usersNotFound && groupsNotFound && scope.isCreateGroup)) {
                            enterClicked = true;
                            scope.createNewGroup();
                        } else if (!angular.equals(scope.clickMap, {parent: -1, child: -1, role: -1})) {
                            enterClicked = true;
                            scope.addToInput(actors);
                            scope.inactiveAll(scope.actors);
                        }
                        break;
                    case BUTTONS.DELETE:
                    case BUTTONS.BACK_SPACE:
                        scope.rmFromInput();
                        break;
                    case BUTTONS.TAB:
                        scope.closeSelectize();
                        break;
                    default:
                }
            };

            scope.keyup = event => {
                if (event && event.which === BUTTONS.ENTER) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (angular.equals(scope.clickMap, {parent: -1, child: -1, role: -1}) && !enterClicked) {
                        scope.closeSelectize();
                    }
                    enterClicked = false;
                }
            };

            scope.$on('tmplDatePicker:tab', () => {
                $timeout(() => {
                    scope.setFocus();
                });
            });
            scope.$on('selectize:open', (event, data) => {
                if (data && selectUID !== data) {
                    scope.closeSelectize();
                }
            });
            scope.$on('$destroy', () => {
                $document.off('click', docClickListener);
            });
        }
    };
}
