import colors from '../../libs/colors';
import utils from '../processes/utils';

let updateUserOrGroupCb = (userT, isGroup) => {
    let updateLocUser = u => Object.assign(u, userT, {avatarUrl: userT.avatarUrl || null});
    return {
        available: task => {
            if (task.process && task.process.starter && task.process.starter.id === userT.id) {
                updateLocUser(task.process.starter);
            }
        },
        My: task => {
            if (task.process && task.process.starter && task.process.starter.id === userT.id) {
                updateLocUser(task.process.starter);
            }
        },
        Delegated: task => {
            if (isGroup) {
                if (task.actors && task.actors.groups) {
                    task.actors.groups.forEach(g => {
                        if (g.id === userT.id) {
                            Object.assign(g, userT);
                        }
                    });
                }
            } else {
                if (task.assignee && task.assignee.id === userT.id) {
                    updateLocUser(task.assignee);
                }
                if (task.actors && task.actors.users) {
                    task.actors.users.forEach(u => {
                        if (u.id === userT.id) {
                            Object.assign(u, userT, {avatarUrl: userT.avatarUrl || null, name: userT.fullName});
                        }
                    });
                }
            }
        },
        Completed: task => {
            if (task.completedBy && task.completedBy.id === userT.id) {
                updateLocUser(task.completedBy);
            }
        },
        DelegatedCompleted: task => {
            if (task.completedBy && task.completedBy.id === userT.id) {
                updateLocUser(task.completedBy);
            }
        }
    };
};

let getActorsNames = (task, $translate) => {
    let actorsStr =  utils.getActorsNames(task, $translate);
    let actorsArr = actorsStr.split(', ');
    if (actorsArr.length > 3) {
        let count = actorsArr.length - 3;
        let begin = actorsArr.slice(0, 3).join(', ') + ' ';
        let end = $translate.instant('text.andMore', {count: count});
        return begin + end;
    }
    return actorsStr;
};
let prepareList = (list, deletedTasks, DateHelper, $translate, $filter) => {
    let resList = deletedTasks.length ? list.filter(t => deletedTasks.every(dTask => dTask.id !== t.id)) : list;
    return resList.map(taskT => {
        let newTask = angular.copy(taskT);
        if (taskT.process) {
            newTask.process.formattedDueDate = utils.getDueDate(taskT.process, DateHelper, true);
            newTask.process.isWarnDue = utils.checkPrDueDate(taskT.process, null, DateHelper);
            newTask.process.isPastDue = utils.checkPrDueDate(taskT.process, true, DateHelper);
            if (newTask.process.dueDate) {
                newTask.process.warnFormattedDueDate = $filter('dueDate')(newTask.process.dueDate, true, true);
            }
            if (newTask.process.tasks) {
                newTask.process.tasks.forEach(t => {
                    t.isUpcoming = (utils.checkPrDueDate(t, true, DateHelper) || utils.checkPrDueDate(t, null, DateHelper))
                        && !t.isCompleted;
                });
                if (deletedTasks.length) {
                    deletedTasks.forEach(dTask => {
                        if(dTask.process && dTask.process.id === taskT.process.id) {
                            newTask.process.tasks = taskT.process.tasks.filter(t => deletedTasks.every(dpTask => {
                                return dpTask.id !== t.id;
                            }));
                        }
                    });
                }
            }
        }
        if (newTask.dueDate) {
            newTask.formattedDueDate = $filter('dueDate')(newTask.dueDate) || null;
        }
        newTask.isWarnDue = utils.checkPrDueDate(taskT, null, DateHelper) || null;
        newTask.actorsNames = getActorsNames(taskT, $translate);
        newTask.assigneeColor = taskT.assignee ? colors[taskT.assignee.colorIndex - 1] : null;
        return newTask;
    });
};

export default {
    updateUserOrGroupCb: updateUserOrGroupCb,
    prepareList: prepareList
};
