import ReportLoaderContainer from '../containers/report-loader-container';

export function reportView(reactDirective, $stateParams, $filter, $sce, DevicesDetector) {
    'ngInject';
    const textParser = (value) => {
        const html = $filter('parseLinks')(value);
        return $sce.getTrustedHtml(html);
    };
    return reactDirective(ReportLoaderContainer, undefined, {}, {$stateParams, DevicesDetector, textParser});
}
