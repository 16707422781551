import { getDataTypeSettings } from '../../../services/data-types-service'
import { MULTI_SELECTOR, RADIO_SELECTOR } from '../../../services/data-types'
import { DataTypedValue } from './datatypes'

export const prepareColumnSnapshot = (json) => {
    const {id, name, order, dataType, isTitle, options, defaultValue} = json
    const savedSettings = dataType === RADIO_SELECTOR || dataType === MULTI_SELECTOR
        ? { defaultValue, ...options.source}
        : { defaultValue, ...options}

    const settings = getDataTypeSettings(dataType, savedSettings)

    return {
        id,
        name,
        order,
        dataType,
        isTitle,
        settings
    }
}

function isString (value) {
    return typeof value === 'string' || value instanceof String;
}

function isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

export const flattenFieldError = (fieldError) => {
    if (isString(fieldError)) {
        return fieldError
    }

    if (Array.isArray(fieldError) && fieldError.length) {
        return flattenFieldError(fieldError[0])
    }

    if (isObject(fieldError) && Object.keys(fieldError).length) {
        return flattenFieldError(fieldError[Object.keys(fieldError)[0]])
    }

    return ''
}

export function orderColumns (columns) {
    return columns.slice().sort((a, b) => {
        if (a.order === b.order) {
            return 0
        }
        return a.order > b.order ? 1 : -1
    })
}

function getFormattedValue (recordValue) {
    return recordValue ? DataTypedValue.create(recordValue).displayValue : ''
}

export function getFormattedRecordValues (record) {
    const { name, values = [], isInvalid } = record
    const nameParts = values.map(v => {
        if (v.dataValue && v.columnType) {
            return {
                ...v.dataValue,
                dataType: v.columnType
            }
        } else if (v.columnType && !v.dataValue) {
            return undefined
        }
        return v
    })

    let string = ''
    if (nameParts.length > 1) {
        const additionalFieldsValues = nameParts.slice(1).filter(getFormattedValue)
        if (additionalFieldsValues.length) {
            string = ' (' + additionalFieldsValues.map(getFormattedValue).join(', ') + ')'
        }
    }

    return {
        id: record.id || record.recordId,
        recordId: record.id || record.recordId,
        name: name ? name : getFormattedValue(nameParts[0]) + string,
        values: record.values,
        isInvalid
    }
}

export function getFormattedRadioButtonValue (radioButtonValue) {
    return radioButtonValue.recordId ? getFormattedRecordValues(radioButtonValue).name : radioButtonValue
}

export function getFormattedMultiChoiceValue (multiChoiceValue) {
    return multiChoiceValue.map(r => r.recordId ? getFormattedRecordValues(r).name : r)
}
