import { BUTTONS } from '../services/constants'

const EVENT_TYPE = 'keydown'

export class ConfirmWithReasonController {
    constructor ($scope, $modalInstance, $document) {
        'ngInject'
        $scope.ok = () => {
            if (!$scope.isEmpty()) {
                $modalInstance.close({ userInput: $scope.userInput })
            }
        }

        $scope.cancel = () => {
            $modalInstance.dismiss()
        }

        const handleKeypress = (event) => {
            const { key } = event

            if (key === BUTTONS.ENTER && !$scope.isEmpty()) {
                event.preventDefault()
                return $scope.ok()
            }

            if (key === BUTTONS.ESCAPE) {
                event.preventDefault()
                return $scope.cancel()
            }
        }

        $document.on(EVENT_TYPE, handleKeypress)

        $scope.$on('$destroy', () => {
            $document.off(EVENT_TYPE, handleKeypress)
        })

        $scope.isEmpty = () => {
            return !$scope.userInput
        }
    }
}
