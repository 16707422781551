export function search() {
    'ngInject';

    return (input, searchText) => {
        if (!input && !angular.isArray(input)) {
            return input;
        }

        let checkString = string => {
            return string.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        };

        return input.filter(item => {
            if (item.fullName) {
                return checkString(item.fullName);
            }
            return checkString(item.name);
        });
    };
}
