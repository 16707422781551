import React from 'react'
import PropTypes from 'prop-types'

import { translate } from '../../../modules/react/utils'
import ActorGroupPrintView from './actor-group-print-view'
import RolePrintView from './role-print-view'
import UserPrintView from './user-print-view'

function ActorsPrintView ({ actors = {} }) {
    const specialRoles = (actors.specialRoles || []).filter(r => !r.isDeleted)
    const groups = (actors.groups || []).filter(g => !g.isDeleted)
    const users = (actors.users || []).filter(u => !u.isDeleted)

    return (<span className={'actors'}>
        {(!specialRoles.length && !groups.length && !users.length) && (
            <span className="group muted">
                <span className="text">{translate('label.noUsers')}</span>
            </span>
        )}
        {specialRoles.map(role => (
            <RolePrintView role={role} key={role.name}/>
        ))}
        {groups.map(group => (
            <ActorGroupPrintView name={group.name} key={group.id}/>
        ))}
        {users.map(user => (
            <UserPrintView user={user} key={user.id}/>
        ))}
    </span>)
}

ActorsPrintView.propTypes = {
    actors: PropTypes.object
}

export default ActorsPrintView
