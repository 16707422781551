import React from 'react'
import PropTypes from 'prop-types'
import Drop from 'tether-drop'
import moment from 'moment'
import clsx from 'clsx'

import DatePicker from './date/date-picker.jsx'
import { BUTTONS } from '../../services/constants'

import './date-input.less'
import { getService } from '../../modules/react/utils'

class DateInput extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        minValue: PropTypes.any,
        maxValue: PropTypes.any,
        placeholder: PropTypes.string,
        dayPickerProps: PropTypes.object,
        position: PropTypes.string,
        withTimeInput: PropTypes.bool,
        withIcon: PropTypes.bool,
        isClearable: PropTypes.bool,
        disabled: PropTypes.bool,
        formatDate: PropTypes.func,
        onDateChange: PropTypes.func
    }

    static defaultProps = {
        withIcon: false,
        isClearable: false,
        position: 'bottom left',
    }

    constructor (props) {
        super(props)

        this.state = {
            open: false
        }
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.open === prevState.open) {
            return
        }

        if (this.state.open) {
            this.drop.open()
            document.addEventListener('mouseup', this.handleClick)
            document.addEventListener('keyup', this.handleKeyPress)
        } else {
            this.drop.close()
            document.removeEventListener('mouseup', this.handleClick)
            document.removeEventListener('keyup', this.handleKeyPress)
        }
    }

    componentDidMount () {
        this.drop = new Drop({
            target: this.target,
            content: this.content,
            position: this.props.position,
            classes: '',
            openOn: undefined,
        })

        moment.locale(getService('currentUser').lang)
    }

    componentWillUnmount () {
        this.drop.destroy()
        document.removeEventListener('mouseup', this.handleClick)
        document.removeEventListener('keyup', this.handleKeyPress)
    }

    openDatePicker = () => {
        document.body.click()
        this.setState({ open: true })
    }

    closeDatePicker = () => {
        this.setState({ open: false })
        this.input.blur()
    }

    handleClick = (e) => {
        if (this.target.contains(e.target) || this.content.contains(e.target)) {
            return
        }

        this.closeDatePicker()
    }

    handleKeyPress = (e) => {
        if (e.key === BUTTONS.ESCAPE) {
            this.closeDatePicker()
        }
    }

    handleDateInput = (string) => {
        // const { format, parseDate } = this.props
        // const date = parseDate(string, format)
        // this.handleDayClick(date);
    }

    handleDateChange = (date, close = true) => {
        if (close) {
            this.closeDatePicker()
        }
        this.props.onDateChange(date ? date.toDate() : null)
    }

    _refTarget = (ref) => {
        this.target = ref
    }

    _refContent = (ref) => {
        this.content = ref
    }

    _refInput = (ref) => {
        this.input = ref
    }

    render () {
        const DateHelper = getService('DateHelper')
        const {
            value,
            placeholder,
            formatDate,
            dayPickerProps,
            withTimeInput,
            withIcon,
            isClearable,
            disabled
        } = this.props
        const format = withTimeInput
            ? DateHelper.DATE_FORMATS().DATE_TIME_INPUT
            : DateHelper.DATE_FORMATS().DATE_INPUT

        const formattedValue = formatDate(value, format)
        const shiftedDate = formattedValue ? moment(formattedValue, format) : undefined

        return (
            <div className={clsx('date-picker-input', { 'datetime-picker-input': withTimeInput })}
                 ref={this._refTarget}>
                <span>
                {withIcon && (
                    <i className="icon-task_due_date_add_small"/>
                )}
                    <input
                        ref={this._refInput}
                        type="text"
                        placeholder={placeholder}
                        value={formattedValue}
                        onChange={(e) => this.handleDateInput(e.target.value)}
                        onFocus={this.openDatePicker}
                        disabled={disabled}
                    />
                    {(isClearable && formattedValue && !disabled) && (
                        <i className="icon-common_remove" onClick={() => this.handleDateChange(undefined, true)}/>
                    )}
                </span>
                <div className="date-picker-container" ref={this._refContent}>
                    {(this.state.open && !disabled) && (
                        <DatePicker
                            value={shiftedDate}
                            onChange={this.handleDateChange}
                            modifiers={{ daysOfWeek: [0, 6], disabled: this.disabledDateModifier }}
                            {...dayPickerProps}
                            withTimeInput={withTimeInput}
                        />
                    )}
                </div>
            </div>
        )
    }

    disabledDateModifier = (date) => {
        const { minValue, maxValue, withTimeInput } = this.props

        const MomentHelper = getService('MomentHelper')

        const timestamp = withTimeInput
            ? MomentHelper.dateTimeToUnix(date)
            : MomentHelper.dateToUnix(date)

        return (maxValue && timestamp > maxValue) || (minValue && timestamp < minValue)
    }
}

export default DateInput
