import moment from 'moment-timezone';
import stringEntropy from 'fast-password-entropy'

import config from '../../../config';
import utils from '../../../utils';

export class SignupController {
    constructor($scope, $state, userData, $translate, $auth, $stateParams, FormErrors, ServerConfig,
                CurrentUserService, AuthApiCalls, PageSettings, $location) {
        'ngInject';
        $scope.user = userData.result || {};
        $scope.patternPassword = config.pattern_passwords;
        $scope.emailPattern = () => config.pattern_emails;

        const authTypeIsEnabled = type => ServerConfig.auth.supported && ServerConfig.auth.supported.includes(type)
        $scope.userLoginIsEnabled = authTypeIsEnabled('user')
        $scope.googleLoginIsEnabled = authTypeIsEnabled('google')
        $scope.microsoftLoginIsEnabled = authTypeIsEnabled('microsoft')
        $scope.samlLoginIsEnabled = authTypeIsEnabled('saml')

        $scope.passwordIsWeak = undefined
        $scope.validatePasswordStrength = () => {
            $scope.passwordIsWeak = $scope.user?.password && stringEntropy($scope.user.password) < config.minimal_entropy
        }

        if ($stateParams.error) {
            $scope.formHint = $translate.instant('login.error.' + $stateParams.error);
        }

        $scope.checkField = fieldName => {
            if (!$scope.signupForm || !$scope.signupForm[fieldName]) {
                return;
            }
            if ($scope.signupForm[fieldName].$invalid && $scope.signupForm[fieldName].$dirty) {
                return $scope.signupForm[fieldName].$error;
            }
        };
        $scope.fieldError = fieldName => {
            return FormErrors($scope.signupForm, fieldName);
        };

        $scope.pattern = (fieldName) => {
            let str = $scope.user[fieldName] || '';
            $scope.user[fieldName] = str.match(config.pattern_names).join('');

            $scope.signupForm[fieldName].delSymbols = (str !== $scope.user[fieldName]);
        };

        $scope.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        $scope.sendMail = () => {
            let form = $scope.signupForm;
            let signupData = angular.toJson($scope.user);
            if (form.$valid) {
                AuthApiCalls.signupStart(signupData)
                    .then(data => {
                        if (data.success) {
                            $state.go('login', {message: data.msg || 'Check your email for continue sign up'});
                        }
                    })
                    .catch((response) => {
                        let data = response.data;
                        if (data && data.error) {
                            $scope.formHint = data.error;
                        } else {
                            $scope.formHint = $translate.instant('error.common');
                        }
                    });
            }
        };

        $scope.signinProvider = provider => {
            if(!$scope.isCompletingRegistration) {
                $scope.isCompletingRegistration = true;
                utils.queryAPI(`/api/authenticate/${provider}?onError=${encodeURIComponent($location.path())}`);
            }
        };

        $scope.completeRegistration = () => {
            let form = $scope.signupForm;
            let postData = angular.copy($scope.user);
            if ($state.current.name === 'invite') {
                postData.timeZoneId = moment.tz.guess();
            }
            let signupData = angular.toJson(postData);
            form.firstName.$dirty = true;
            form.lastName.$dirty = true;
            form['new-password'].$dirty = true;
            form['password-confirm'].$dirty = true;

            if (form.$valid) {
                $scope.isCompletingRegistration = true;
                let promise;
                if ($state.current.name === 'invite') {
                    promise = AuthApiCalls.inviteSignup($stateParams.mailToken, signupData);
                } else {
                    promise = AuthApiCalls.signup($stateParams.mailToken, signupData);
                }
                promise
                    .then(data => {
                        if (data.result) {
                            $auth.setToken(data.result.token);
                            CurrentUserService.reload(data.result.token).finally(() => {
                                $state.go('main.home');
                            });
                        }
                    })
                    .catch((response) => {
                        $scope.isCompletingRegistration = false;
                        let data = response.data;
                        $scope.serverError = data;
                        if (data && (data.error || data.displayError)) {
                            $scope.formHint = data.error || data.displayError;
                        } else {
                            $scope.formHint = $translate.instant('error.common');
                        }
                    });
            }
        };
    }
}
