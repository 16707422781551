
export function taskDatePicker() {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dueDate: '=?',
            dueDateInterval: '=?',
            onClose: '='
        },
        template: `
            <span class="prc-date-picker">
                <span class="handler" help-tooltip="tooltips.taskView.dueDate">
                    <i class="icon-task_due_date_add"></i>
                </span>
                <mt-date-picker date="dueDate" date-interval="dueDateInterval" on-close="onClose(date)"
                            target-element="targetElement"></mt-date-picker>
            </span>
        `,

        link: (scope, element) => {
            scope.targetElement = element.find('.handler')[0];
        }
    };
}
