import config from '../config';
import colors from '../libs/colors';
import {validateAttachedFiles} from '../utils';

export function comments(ApiCalls, MomentHelper, Upload, $timeout, $translate, $state, DeferredAction,
                         PageSettings, ProcessesSettings, Constants) {
    'ngInject';
    const unhighlightDelay = 500;
    return {
        restrict: 'E',
        replace: true,
        scope: {
            task: '=',
            allUsers: '=',
            tree: '=',
            isOpened: '='
        },
        template: require('../templates/comments.html'),
        controller: ($scope, ServerConfig, PrintErrors, currentUser, PusherHelper, $window) => {
            'ngInject';
            if (!$scope.task) {
                return;
            }
            $scope.PageSettings = PageSettings;
            $scope.printErrors = PrintErrors.printFilesErrors;

            $scope.getMaxSize = () => {
                return ServerConfig.fileMaxSize;
            };

            $scope.getTaskComments = () => {
                let count = $scope.task.commentsCount || 0;
                return angular.toJson({COUNT: count});
            };

            $scope.getMatchesCount = (matches) => {
                let count = matches ? matches.length || 0 : 0;
                return angular.toJson({COUNT: count});
            };

            $scope.newMessages = [];
            $scope.getNewMessages = () => {
                let count = $scope.newMessages.length;
                return angular.toJson({COUNT: count});
            };

            $scope.getMsgActors = () => {
                if (!$scope.newMessages || !$scope.newMessages.length) {
                    return [];
                }
                let result = [];
                let ids = [];
                $scope.newMessages.forEach(msg => {
                    if (ids.length >= 4) {
                        return;
                    }
                    if (ids.indexOf(msg.creator.id) === -1) {
                        result.push(msg);
                        ids.push(msg.creator.id);
                    }
                });
                return result;
            };

            $scope.clearNewMessages = () => {
                $scope.newMessages = [];
                $scope.$applyAsync();
            };
            $scope.hideNotification = () => {
                $scope.notificationIsDisplayed = false;
            };
            $scope.showNotification = () => {
                $scope.notificationIsDisplayed = true;
            };

            $scope.getTaskAttachments = () => {
                let count = $scope.task.attachmentsCount || 0;
                return angular.toJson({COUNT: count});
            };

            $scope.isActiveMode = mode => $scope.activeMode === mode;

            // Displaying errors
            $scope.getErrUploads = () => {
                let names = $scope.notUploaded.join(', ');
                return {fileNames: names};
            };

            // Confirmations
            let checkUploads = () => {
                let changes = false;
                if (angular.isArray($scope.files)) {
                    $scope.files.forEach(file => {
                        if (!angular.isArray(file.result)) {
                            changes = true;
                        }
                    });
                }
                return changes;
            };

            let checkUnsavedComment = () => {
                return !!($scope.newComment.text || ($scope.files && $scope.files.length));
            };

            let customExit = (event, nextState, nextStateParams) => {
                if (nextState.name === 'login' || DeferredAction.TaskDelete.isDeferred($scope.task)) {
                    return;
                }
                if (checkUploads()) {
                    event.preventDefault();
                    ProcessesSettings.callTaskViewExitModal({
                        title: 'uploadingFiles.text.leaveThisPage',
                        text: 'uploadingFiles.text.unsavedChanges'
                    });
                } else if (checkUnsavedComment()) {
                    event.preventDefault();
                    let modalInstance = ProcessesSettings.callExitModal({
                        title: 'comments.text.leaveThisPage',
                        text: 'comments.text.unsavedChanges',
                        confirmText: 'comments.label.continueEditing',
                        cancelText: 'comments.label.discardChanges'
                    });
                    modalInstance.result.then(() => {
                        $scope.newComment = {attachments: []};
                        $scope.files = [];
                        $state.go(nextState.name, nextStateParams);
                    }).catch(() => {
                        $scope.activate();
                    });
                }
            };

            let defaultExit = () => {
                if (!DeferredAction.TaskDelete.isDeferred($scope.task) && (checkUnsavedComment() || checkUploads())) {
                    $window.event.returnValue = $translate.instant('comments.text.unsavedChanges');
                }
            };

            let destroyCallback = $scope.$on('$stateChangeStart', customExit);
            $window.addEventListener('beforeunload', defaultExit);

            // PUSHER EVENT HANDLERS
            //bind to connection state_change to be able to determine when connection is lost
            $scope.$on('socket:connected', () => {
                if (!PusherHelper.firstCall && $scope.checkPermission('viewComments')) {
                    ApiCalls.getTaskComments($scope.task.id).then(data => {
                        $scope.comments = data.list || [];
                        $scope.task.version = data.taskVersion;
                        $scope.task.commentsCount = $scope.comments.length;
                        $scope.task.attachmentsCount = $scope.comments.reduce((pr, nx) => {
                            return pr + nx.attachmentsCount;
                        }, 0);
                        $timeout($scope.scrollBottom);
                    });
                } else {
                    PusherHelper.firstCall = false;
                }
            });

            let addComment = comment => {
                let el = $scope.getElement();
                comment.isNew = true;
                $scope.comments.unshift(comment);
                $scope.task.commentsCount++;
                $scope.$broadcast('comment:new');

                if (comment.attachmentsCount) {
                    $scope.task.attachmentsCount = $scope.task.attachmentsCount + comment.attachmentsCount;
                    $scope.attachments = $scope.attachments || [];
                    comment.attachments.forEach(a => {
                        a.linkId = comment.id;
                        a.isNew = true;
                        a.creator = angular.copy(comment.creator);
                        $scope.attachments.unshift(a);

                        $timeout(() => {
                            delete a.isNew;
                        }, unhighlightDelay);
                    });

                    if ($scope.activeMode === 'Attachments') {
                        $timeout(() => {
                            $('.attachments > main').scrollTop(0); //eslint-disable-line
                        }, 100);
                    }
                }

                if (el[0].scrollTop + 90 >= el[0].scrollHeight - el[0].offsetHeight || comment.creator.id === currentUser.id) {
                    $scope.scrollBottom();

                    $timeout(() => {
                        delete comment.isNew;
                    }, unhighlightDelay);
                } else {
                    $scope.newMessages.push(comment);
                }
            };

            let commentAddListener = (data) => {
                if (!$scope.comments) {
                    $scope.comments = [];
                }
                if (data.task && data.task.id === $scope.task.id && data.comment
                    && !$scope.comments.find(c => c.id === data.comment.id)) {
                    if (data.missingData) {
                        ApiCalls.getTaskComment(data.task.id, data.comment.id).then(comment => {
                            addComment(comment);
                        });
                    } else {
                        addComment(data.comment);
                    }
                    $scope.task.version = data.task.version;
                }
            };

            let commentDelListener = (data) => {
                if (data.task && $scope.task && data.task.id === $scope.task.id && data.comment) {
                    $scope.comments = $scope.comments.filter(c => {
                        if (c.id === data.comment.id) {
                            c.isDeleted = true;
                            $scope.task.commentsCount--;
                            $scope.task.attachmentsCount = $scope.task.attachmentsCount - c.attachmentsCount;
                            if ($scope.attachments) {
                                $scope.attachments = $scope.attachments.filter(a => a.linkId !== c.id);
                            }
                            return false;
                        }
                        return true;
                    });
                    $scope.newMessages = $scope.newMessages.filter(m => m.id !== data.comment.id);
                    $scope.task.version = data.task.version;
                }
            };

            let updateUserOrGroup = (data) => {
                let updateLocUser = (u, origin) => Object.assign(u, origin, {avatarUrl: origin.avatarUrl || null});
                if (data.user) {
                    if ($scope.comments) {
                        $scope.comments.forEach(c => {
                            if (c.creator && c.creator.id === data.user.id) {
                                updateLocUser(c.creator, data.user);
                            }
                            if (c.mentions) {
                                let locUser = c.mentions.find(m => m.id === data.user.id);
                                if (locUser) {
                                    updateLocUser(locUser, data.user);
                                }
                            }
                        });
                    }
                    let user = $scope.allUsers.find(u => u.id === data.user.id);
                    if (user) {
                        updateLocUser(user, data.user);
                    }
                    if ($scope.attachments) {
                        $scope.attachments.forEach(a => {
                            if (a.creator && a.creator.id === data.user.id) {
                                updateLocUser(a.creator, data.user);
                            }
                        });
                    }
                }
            };

            $scope.fetchTaskHistory = () => {
                const promise = ApiCalls.getTaskHistory($scope.task.id);

                promise
                    .then(data => $scope.history = data.list || [])
                    .catch(PageSettings.errorHandlerModal);

                return promise;
            };

            const taskUpdatedListener = ({taskId, historyChanged}) => {
                if (taskId === $scope.task.id && historyChanged) {
                    $scope.fetchTaskHistory();
                }
            };

            PusherHelper.subscribe('commentAdded', commentAddListener);
            PusherHelper.subscribe('commentDeleted', commentDelListener);
            PusherHelper.subscribe('userOrGroupUpdated', updateUserOrGroup);
            PusherHelper.subscribe('taskUpdated', taskUpdatedListener);

            $scope.$on('$destroy', () => {
                destroyCallback()
                $window.removeEventListener('beforeunload', defaultExit);
                PusherHelper.unsubscribe('commentAdded', commentAddListener);
                PusherHelper.unsubscribe('commentDeleted', commentDelListener);
                PusherHelper.unsubscribe('userOrGroupUpdated', updateUserOrGroup);
                PusherHelper.unsubscribe('taskUpdated', taskUpdatedListener);
            })

            $scope.getUserName = ({fullName, email}) => {
                return fullName.trim() ? fullName : email;
            };
        },

        link: (scope, element) => {
            if (!scope.task) {
                return;
            }
            scope.scrollBottom = () => {
                let mainEl = $(element.find('.comments > main')); //eslint-disable-line
                mainEl.animate({scrollTop: mainEl[0].scrollHeight}, 300);
            };
            scope.getElement = () => {
                return $(element.find('main')); //eslint-disable-line
            };

            let el = $(element.find('main')); //eslint-disable-line
            let scrollListener = () => {
                scope.newMessages = scope.newMessages.filter(msg => {
                    if (msg.isNew && document.getElementById('msg-' + msg.id).offsetTop + 60 //eslint-disable-line
                                        < el[0].clientHeight + el[0].scrollTop) {
                        scope.notificationIsDisplayed = false;
                        scope.$applyAsync();
                        $timeout(() => delete msg.isNew, unhighlightDelay);
                        return false;
                    }
                    return true;
                });
            };
            el.on('scroll', scrollListener);
            scope.$on('$destroy', () => {
                el.off('scroll', scrollListener);
            });

            scope.maxHeight = () => {
                if (element.context.parentNode) {
                    let maxHeight = element.context.parentNode.clientHeight - 56;
                    if (maxHeight > 300) {
                        return maxHeight;
                    }
                }
                return 300;
            };

            scope.MomentHelper = MomentHelper;
            scope.activeMode = 'Comments';

            scope.newComment = {
                attachments: []
            };
            scope.tmpFiles = {};

            scope.checkPermission = (permission) => {
                return scope.task.permissions ? scope.task.permissions.indexOf(permission) !== -1 : null;
            };

            scope.switchComments = () => {
                scope.activeMode = 'Comments';
            };

            scope.switchAttachments = () => {
                if (!scope.task.attachmentsCount) {
                    scope.activeMode = 'Attachments';
                    return;
                }
                ApiCalls.getTaskAttachments(scope.task.id)
                    .then(data => {
                        scope.attachments = data.list || [];
                        $('.attachments > main').scrollTop(0); //eslint-disable-line
                    })
                    .catch(PageSettings.errorHandlerModal)
                    .finally(() => scope.activeMode = 'Attachments');
            };

            scope.switchHistory = () => {
                scope.fetchTaskHistory().then(() => {
                    $('.history > main').scrollTop(0); //eslint-disable-line
                    scope.activeMode = 'History';
                });
            };

            if (scope.task) {
                if (scope.checkPermission('viewComments')) {
                    if (scope.task.commentsCount) {
                        ApiCalls.getTaskComments(scope.task.id)
                            .then(data => {
                                scope.comments = data.list || [];
                                scope.task.version = data.taskVersion;
                                $timeout(scope.scrollBottom);
                            })
                            .catch(PageSettings.errorHandlerModal);
                    }
                    if (scope.task.attachmentsCount) {
                        ApiCalls.getTaskAttachments(scope.task.id)
                            .then(data => scope.attachments = data.list || [])
                            .catch(PageSettings.errorHandlerModal);
                    }
                } else if (scope.checkPermission('viewHistory')) {
                    scope.switchHistory();
                }
            }

            scope.getColor = (index) => {
                return colors[index - 1];
            };

            scope.checkField = (form, fieldName) => {
                if (!form || !form[fieldName]) {
                    return;
                }
                return (form[fieldName].$invalid && form[fieldName].$dirty) || (form[fieldName].$invalid);
            };

            scope.activate = () => {
                $timeout(() => {
                    let commentEl = document.getElementById('comments-input'); // eslint-disable-line
                    commentEl.focus();
                    commentEl.selectionStart = commentEl.selectionEnd = commentEl.value.length;
                });
            };

            // Comments actions
            scope.deleteComment = (comment) => {
                if (comment.isDeleted) {
                    return;
                }
                comment.isDeleted = true;
                ApiCalls.deleteComment(scope.task.id, comment.id).then(data => {
                    if (data.success) {
                        if (scope.comments.find(c => c.id === comment.id)) {
                            scope.comments = scope.comments.filter(locComment => {
                                return comment.id !== locComment.id;
                            });
                            if (scope.attachments) {
                                scope.attachments = scope.attachments.filter(a => a.linkId !== comment.id);
                            }
                            Object.assign(scope.task, data.result.task);
                        }
                        scope.serverError = null;
                    } else {
                        scope.serverError = $translate.instant('comment.delete.failure');
                    }
                }, errorResponse => {
                    if (errorResponse.data && errorResponse.data.displayError) {
                        scope.serverError = errorResponse.data.displayError;
                    } else {
                        scope.serverError = $translate.instant('comment.delete.failure');
                    }
                    PageSettings.errorHandlerModal(errorResponse);
                }).finally(() => {
                    comment.isDeleted = false;
                });
            };

            let attachedFiles = [];
            let clearNewComment = () => {
                scope.newComment = {
                    attachments: []
                };
                scope.files = [];
                scope.errFiles = [];
                attachedFiles = [];
                scope.commentForm.$setPristine();
                scope.serverError = '';
            };

            let addComment = () => {
                if (scope.inProcess) {
                    return;
                }
                scope.notUploaded = [];
                scope.inProcess = true;
                let allValid = true;

                if (scope.files) {
                    scope.newComment.attachments = scope.files.map(file => {
                        if (angular.isArray(file.result) && file.result[0]) {
                            return file.result[0];
                        } else {
                            scope.notUploaded.push(file.name);
                            allValid = false;
                        }
                    });
                }
                if (!allValid || (!scope.newComment.attachments.length && !scope.newComment.text)) {
                    scope.inProcess = false;
                    return;
                }

                ApiCalls.addComment(scope.task.id, scope.newComment).then(data => {
                    scope.$broadcast('comment:new');
                    if (data.success) {
                        if (!scope.comments) {
                            scope.comments = [];
                        }
                        if (data.result && !scope.comments.find(c => c.id === data.result.comment.id)) {
                            scope.scrollBottom();
                            let newComment = data.result.comment;
                            newComment.isNew = true;
                            scope.comments.unshift(newComment);
                            $timeout(() => {
                                delete newComment.isNew;
                            }, unhighlightDelay);
                            Object.assign(scope.task, data.result.task);
                        }
                        scope.serverError = null;
                    } else {
                        scope.serverError = $translate.instant('comment.add.failure');
                    }
                    clearNewComment();
                    scope.inProcess = false;
                }, errorResponse => {
                    if (errorResponse.data && errorResponse.data.displayError) {
                        scope.serverError = errorResponse.data.displayError;
                    } else {
                        scope.serverError = $translate.instant('comment.add.failure');
                    }
                    scope.inProcess = false;
                    PageSettings.errorHandlerModal(errorResponse, undefined, () => {
                        clearNewComment();
                    });
                });
            };

            scope.keyHandler = (event) => {
                if (!event) {
                    addComment();
                } else if (event.key === Constants.BUTTONS.ENTER && !event.shiftKey) {
                    event.preventDefault();
                    addComment();
                }
            };

            let checkUpload = files => {
                if (!files || !files.length) {
                    return false;
                }
                return files.find(f => attachedFiles.indexOf(f.name) === -1);
            };

            // Attachments actions
            scope.uploadFiles = (files, errFiles) => {
                const validatedFiles = validateAttachedFiles(files, errFiles, config);

                scope.files = validatedFiles[0];
                scope.errFiles = validatedFiles[1];

                if (scope.newComment.text) {
                    $timeout(() => {
                        let currentHeight = document.getElementById('comments-input').style.height; // eslint-disable-line
                        if (currentHeight) {
                            currentHeight = Number(currentHeight.replace('px', ''));
                            document.getElementById('comment-wrapper').scrollTop // eslint-disable-line
                                = currentHeight > 30 ? currentHeight - 30 : 0;
                        }
                    });
                }

                if (!checkUpload(scope.files)) {
                    return;
                }

                // Upload files directly to Amazon (@param: files policies)
                let uploadFunc = allPolicies => {
                    scope.files.forEach((file, fileIndex) => {
                        if (attachedFiles.indexOf(file.name) !== -1) {
                            return;
                        }

                        let filePolicy = allPolicies[fileIndex];
                        file.upload = Upload.upload({
                            url: `https://${filePolicy.bucket}.s3.amazonaws.com/`,
                            method: 'POST',
                            data: {
                                key: filePolicy.key,
                                'AWSAccessKeyId': filePolicy.accessKey,
                                'acl': filePolicy.acl,
                                'policy': filePolicy.policy,
                                'signature': filePolicy.signature,
                                'x-amz-server-side-encryption': filePolicy.xAmzServerSideEncryption,
                                'x-amz-credential': filePolicy.xAmzCredential,
                                'x-amz-algorithm': filePolicy.xAmzAlgorithm,
                                'x-amz-date': filePolicy.xAmzDate,
                                'Content-Type': filePolicy.contentType, // content type of the file (NotEmpty)
                                'Content-Disposition': filePolicy.contentDisposition,
                                filename: filePolicy.fileName, // this is needed for Flash polyfill IE8-9
                                file: file
                            }
                        });

                        file.upload.then(response => {
                            if (response.status === 204) {
                                if (!file.$deleted) {
                                    file.result = [{
                                        id: filePolicy.id,
                                        fileName: filePolicy.fileName,
                                        fileSize: filePolicy.fileSize,
                                        contentType: filePolicy.contentType,
                                        url: response.headers('Location'),
                                        hashToken: response.headers('ETag').replace(/\"/g, ''),
                                        creationDate: MomentHelper.getUTCTimestampFromString(response.headers('Date'))
                                    }];
                                    attachedFiles.push(filePolicy.fileName);
                                }
                            } else {
                                let copy = angular.copy(file);
                                copy.$error = 'upload';
                                scope.errFiles.push(copy);
                            }
                        }, (response) => {
                            if (response.status > 0 && response.data) {
                                if (response.data.displayError) {
                                    scope.serverError = response.data.displayError;
                                } else {
                                    scope.serverError = $translate.instant('comment.add.failure');
                                }
                            }
                        }, (evt) => {
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });
                    });
                };

                //gatherFilesInfo - all files we are going to upload
                let filesInfo = scope.files.map(file => {
                    return {
                        fileName: file.name,
                        fileSize: file.size,
                        contentType: file.type
                    };
                });

                //make a call to server - generate policy for files first
                ApiCalls.generateFilesUploadPolicy(filesInfo).then(response => {
                    if (response.success) {
                        uploadFunc(response.result);
                    } else {
                        scope.serverError = $translate.instant('validation.text.getPolicy');
                    }
                }, () => {
                    scope.serverError = $translate.instant('validation.text.getPolicy');
                });
            };

            scope.deleteFile = (file, event) => {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                let fileIndex = scope.files.indexOf(file);
                attachedFiles = attachedFiles.filter(f => f !== file.name);
                file.$deleted = true;

                if (fileIndex !== -1) {
                    scope.files.splice(fileIndex, 1);
                }
                if (file.result && file.result[0] && file.result[0].id) {
                    ApiCalls.deleteFile(file.result[0].id);
                }
            };

            scope.getRevision = comment => comment.revision || 0;
        }
    };
}
