import config, { SSO_SIGN_IN_TARGET_KEY } from '../../../config'
import utils, { localStorageHelper } from '../../../utils'

export class LoginController {
    constructor($scope, ServerConfig, FormErrors, AuthApiCalls, CurrentUserService, CompanyCurrenciesService, DevicesDetector,
                $stateParams, $window, $state, $modalStack, $translate, $timeout, HomeSettings, ProcessesSettings, $log, $location) {
        'ngInject';
        $modalStack.dismissAll();
        let checkerTime = 0;
        let goHome = result => {
            CurrentUserService.updateUser(result);
            HomeSettings.reset();
            ProcessesSettings.reset();

            const returnAfterLoginUrl = localStorageHelper.get('returnAfterLoginUrl')
            if (returnAfterLoginUrl) {
                localStorageHelper.remove('returnAfterLoginUrl')
                return $location.path(returnAfterLoginUrl)
            }

            //do redirect OR go to some path
            if ($stateParams.redir) {
                $window.location.href = $stateParams.redir + ($stateParams.redir.indexOf('?') >= 0 ? '&' : '?')
                  + 'authToken=' + encodeURIComponent(result.token);
            } else if ($stateParams.target) {
                const {state, params} = $stateParams.target;
                $state.go(state, params);
            } else {
                $state.go('main.home');
            }
        };

        let goToActivatePage = result => {
            CurrentUserService.updateUser(result, undefined, false, true);
            $state.go('billing-disabled');
        };

        if ($stateParams.message || $stateParams.error) {
            $scope.formHint = $stateParams.message || $translate.instant('login.error.' + $stateParams.error);
        }

        const authTypeIsEnabled = type => ServerConfig.auth.supported && ServerConfig.auth.supported.includes(type)
        $scope.userLoginIsEnabled = authTypeIsEnabled('user')
        $scope.googleLoginIsEnabled = authTypeIsEnabled('google')
        $scope.microsoftLoginIsEnabled = authTypeIsEnabled('microsoft')
        $scope.samlLoginIsEnabled = authTypeIsEnabled('saml')

        $scope.user = {rememberMe: false};
        $scope.signUpLink = ServerConfig.signUpUrl || 'http://workblocks.me/team-creation';
        $scope.teamHost = $window.location.host;
        $scope.teamSubdomain = $window.location.host ? $window.location.host.split('.')[0] : '';
        $scope.emailPattern = config.pattern_emails;
        $scope.disabledMode = $stateParams.disabledMode;
        $scope.isMobile = DevicesDetector.isMobile();

        $scope.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        $scope.fieldError = fieldName => FormErrors($scope.loginForm, fieldName);

        $scope.checkField = fieldName => {
            if (!$scope.loginForm || !$scope.loginForm[fieldName]) {
                return;
            }
            return $scope.loginForm[fieldName].$invalid && $scope.loginForm[fieldName].$dirty;
        };

        const loginApiCall = $scope.disabledMode ? AuthApiCalls.loginDisabled : AuthApiCalls.login;
        const loginSuccessHandler = $scope.disabledMode ? goToActivatePage : goHome;
        let isWaiting = false;
        $scope.formSubmit = () => {
            if (isWaiting) {
                return;
            }
            let form = $scope.loginForm;
            let userData = angular.toJson($scope.user);

            if (form.$valid) {
                isWaiting = true;
                loginApiCall(userData)
                    .then(data => {
                        if (data.success) {
                            loginSuccessHandler(data.result);
                        } else {
                            $scope.formHint = data.error || $translate.instant('error.common');
                        }
                    })
                    .catch(resp => $scope.formHint = resp.data && resp.data.error || $translate.instant('error.common'))
                    .finally(() => isWaiting = false);
            }
        };

        $scope.signInWithSSO = (provider, accountIsDisabled = false) => {
            if (!['google', 'microsoft', 'saml'].includes(provider)) {
                $log.error('Unknown SSO provider')
            }
            if (accountIsDisabled) {
                utils.localStorage.set(SSO_SIGN_IN_TARGET_KEY, JSON.stringify({ state: 'billing-disabled' }))
                utils.queryAPI(`/api/disabled/authenticate/${provider}`)
                return
            }

            if ($stateParams.target) {
                utils.localStorage.set(SSO_SIGN_IN_TARGET_KEY, JSON.stringify($stateParams.target))
            } else {
                utils.localStorage.remove(SSO_SIGN_IN_TARGET_KEY)
            }
            const end = $stateParams.redir ? '?redir=' + encodeURIComponent($stateParams.redir) : ''
            utils.queryAPI(`/api/authenticate/${provider}${end}`)
        }

        $scope.clear = () => {
            checkerTime = 10;
            if ($scope.forceEnable) {
                delete $scope.forceEnable;
            }
        };

        let autofillChecker = () => {
            $timeout(() => {
                if (document.querySelector('.form-container input[type=password]:-webkit-autofill')) {
                    $scope.forceEnable = true;
                } else {
                    checkerTime++;
                    if (checkerTime < 10) {
                        autofillChecker();
                    }
                }
            }, 150);
        };
        autofillChecker();
    }
}
