import { flow, types } from 'mobx-state-tree'
import { UserGroupId, UserId, User, UserGroup } from './user'
import { getService } from '../../react/utils'

export const Actors = types.model({
    groups: types.array(UserGroup),
    users: types.array(User),
    specialRoles: types.array(types.frozen())
}).views(self => ({
    getOptionsModel (enabledSpecialRoles = []) {
        const items = {
            specialRoles: [],
            groups: [],
            users: []
        }

        self.specialRoles.forEach(role => {
            const id = Object.keys(role).filter(k => k !== 'name')[0]
            if (enabledSpecialRoles.includes(id)) {
                items.specialRoles.push({
                    name: role.name,
                    [id]: true
                })
            }
        })

        self.groups.forEach(group => {
            items.groups.push(group.asOption)
        })

        self.users.forEach(user => {
            items.users.push(user.asOption)
        })

        return items
    },
    get allUsers() {
        const allUsers = self.users.map(u => u.toJSON())
        self.groups.forEach(group => {
            group.users.forEach(u => {
                allUsers.push(u.toJSON())
            })
        })

        return allUsers
    }
})).actions(self => {
    return {
        fetch: flow(function * () {
            try {
                const result = yield getService('ApiCalls').getActors()
                self.groups = result.groups
                self.users = result.users
                self.specialRoles = result.specialRoles
            } catch (e) {
                console.log(e)
            }
        }),
        afterCreate () {
            self.fetch()
        },
        addNewGroup (group) {
            if (!self.groups.find(({id}) => id === group.id)) {
                self.groups.push(UserGroup.create(group))
            }
        },
        addNewUser (user) {
            if (!self.users.find(({id}) => id === user.id)) {
                self.users.push(User.create(user))
            }
        }
    }
})

export const ActorsValue = types.model({
    specialRoles: types.array(types.frozen()),
    groups: types.array(UserGroupId),
    users: types.array(UserId)
}).actions(self => ({
    update ({ users, groups, specialRoles }) {
        self.users = users
        self.groups = groups
        self.specialRoles = specialRoles
    }
})).views(self => ({
    get isEmpty () {
        return !self.users.length && !self.groups.length && !self.specialRoles.length
    }
}))
