import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import Modal from '../../../../components/react/modal.jsx'
import ActorsSelector from '../../../../components/react/users/actors-selector.jsx'
import { translate } from '../../../react/utils'

function PermissionsDialog ({ table, actors, isOpen, onClose, ...props }) {
    return (
        <Modal
            isOpen={isOpen}
            closeAction={onClose}
            title={translate('tablePermissions.title')}
            className="permissions-dialog"
            {...props}
        >
            <div className="section">
                <h3>{translate('tablePermissions.editTable')}</h3>
                <div>
                    <ActorsSelector
                        actors={actors}
                        model={table.owners}
                        onChange={table.changeOwners}
                    />
                </div>
            </div>
            <div className="section">
                <h3>{translate('tablePermissions.editRecords')}</h3>
                <div>
                    <ActorsSelector
                        actors={actors}
                        specialRoles={['allUsers']}
                        model={table.editors}
                        onChange={table.changeEditors}
                    />
                </div>
            </div>
            <div className="section">
                <h3>{translate('tablePermissions.viewRecords')}</h3>
                <div>
                    <ActorsSelector
                        actors={actors}
                        specialRoles={['allUsers']}
                        model={table.viewers}
                        onChange={table.changeViewers}
                    />
                </div>
            </div>
        </Modal>
    )
}

PermissionsDialog.propTypes = {
    table: PropTypes.object.isRequired,
    actors: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired
}

export default observer(PermissionsDialog)
