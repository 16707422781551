export function autoFocus($timeout) {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, element, attrs) => {
            $timeout(() => {
                element[0].focus();
            }, attrs.focusDelay || 0);
        }
    };
}
