import config from '../config';

export function contentEditable($log) {
    'ngInject';
    let _link = (scope, elem, attrs, ngModel) => {

        // return if ng model not specified
        if (!ngModel) {
            $log.warn('Error: ngModel is required in elem: ', elem);
            return;
        }
        if (!scope.options) {
            scope.options = {};
        }

        var originalElement = elem[0];

        // if model is invalid or null
        // fill his value with elem html content
        if (!scope.ngModel) {
            ngModel.$setViewValue(elem.html());
        }

        ngModel.$parsers.unshift(viewValue => {
            if (!viewValue) {
                ngModel.$setValidity('notUnique', true);
                ngModel.$setValidity('empty', true);
                ngModel.$setValidity('itemMaxlength', true);
                ngModel.$setValidity('pattern', true);
                return viewValue;
            }
            let lines = viewValue.split('\n');
            let maxLength = attrs.perLineText || 30;
            let notUnique, notEmpty, itemMaxlength, pattern;

            lines.forEach(l => {
                if (lines.filter(i => l === i).length > 1) {
                    ngModel.$setValidity('notUnique', false);
                    notUnique = true;
                }
                if (!l) {
                    ngModel.$setValidity('empty', false);
                    notEmpty = true;
                }
                if (l && l.length > maxLength && lines.filter(i => l === i).length === 1) {
                    ngModel.$setValidity('itemMaxlength', false);
                    itemMaxlength = true;
                }
                if (l && !l.match(config.pattern)) {
                    ngModel.$setValidity('pattern', false);
                    pattern = true;
                }
            });
            if (!notUnique) {
                ngModel.$setValidity('notUnique', true);
            }
            if (!notEmpty) {
                ngModel.$setValidity('empty', true);
            }
            if (!itemMaxlength) {
                ngModel.$setValidity('itemMaxlength', true);
            }
            if (!pattern) {
                ngModel.$setValidity('pattern', true);
            }

            viewValue = lines.join('\n');
            return viewValue;
        });

        // add editable class
        attrs.$addClass(scope.options.editableClass);

        // render always with model value
        ngModel.$render = () => {
            elem.html(ngModel.$modelValue);
        };

        let onClick = (e) => {
            e.preventDefault();
            scope.activeMode = true;
            attrs.$set('contenteditable', 'true');
            return originalElement.focus();
        };

        let pasteListener = e => {
            e.preventDefault();
            var text = e.originalEvent.clipboardData.getData('text/plain');
            document.execCommand('insertHTML', false, text); // eslint-disable-line
        };

        let onBlur = () => {
            scope.activeMode = false;
            attrs.$set('contenteditable', 'false');
        };

        let inputListener = () => {
            let html = elem.html()
                .replace(/&nbsp;/g, ' ')
                .replace(new RegExp('<div>', 'g'), '\n')
                .replace(new RegExp('</div>', 'g'), '')
                .replace(new RegExp('<br>', 'g'), '');
            ngModel.$setViewValue(html);
            if (scope.editCallback) {
                return scope.$apply(scope.editCallback(html, elem));
            }
        };

        let onKeyDown = (e) => {
            if (e && e.ctrlKey && ([65, 67, 86, 90].indexOf(e.which) === -1)) {
                e.preventDefault();
                e.stopPropagation();
            }
            // on tab key blur and
            // TODO: focus to next
            if (e.which === 9) {
                originalElement.blur();
                return;
            }

            // on esc key roll back value and blur
            if (e.which === 27) {
                ngModel.$rollbackViewValue();
                return originalElement.blur();
            }

            // if single line or ctrl key is
            // pressed trigger the blur event
            if (e.which === 13 && (scope.options.singleLine || e.ctrlKey)) {
                return originalElement.blur();
            }
        };

        elem.bind('input', inputListener);
        elem.bind('click', onClick);
        elem.bind('blur', onBlur);
        elem.bind('keydown', onKeyDown);
        elem.bind('paste', pasteListener);

        scope.$on('$destroy', () => {
            elem.unbind(inputListener);
            elem.unbind(onClick);
            elem.unbind(onBlur);
            elem.unbind(pasteListener);
        });
    };

    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            activeMode: '=?',
            ngModel: '=',
            options: '=?'
        },
        link: _link
    };
}