import TableSourceField from '../react/table-source-field.jsx'

export function reactTableSourceField (reactDirective) {
    'ngInject'
    const mapProps = [
        'tableId',
        'templateId',
        'processId',
        'taskId',
        'fieldId',
        'displayFields',
        'value',
        'onChange',
        'placeholder',
        'isMulti',
        'isDisabled',
        'withEmptyValue'
    ]
    return reactDirective(TableSourceField, mapProps, {}, {})
}
