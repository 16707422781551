import React from 'react'

import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    DATE_WITHOUT_TIME,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import DateTimeFormatter from '../../../modules/reports/components/date-time-formatter'
import { translate } from '../../../modules/react/utils'
import {
    getFormattedMultiChoiceValue,
    getFormattedRadioButtonValue
} from '../../../modules/database/models/database-utils'
import FilePreview from '../file-preview'
import { formatNumber } from '../../../modules/reports/store/utils'

function FieldValuePrintView ({ value, dataType, displayEmptyValue = true, ...props }) {
    const wrap = content => (<div className={'value'} {...props}>{content}</div>)
    const emptyValue = () => wrap(displayEmptyValue ? <span
        className={'muted'}>{translate('label.emptyValue')}</span> : null)

    if (!value) {
        return emptyValue()
    }

    switch (dataType) {
        case STRING_SINGLE_LINE:
        case STRING_MULTI_LINE:
            // const parsedText = textParser(value.stringValue || value)
            // return htmlToReactParser.parse(parsedText)
            return wrap(value.stringValue)
        case DATE_WITHOUT_TIME:
        case DATE_ONLY_VALUE:
        case DATE_AND_TIME:
            const timestamp = Number(value.dateValue || value)
            return wrap(
                <DateTimeFormatter key={'value'} timestamp={timestamp} withTime={dataType === DATE_AND_TIME}/>
            )
        case USER_FIELD:
            return wrap(value.userValue.fullName)
        case YES_NO:
            return wrap(value.yesNoValue === 'yes' ? translate('label.yes') : translate('label.no'))
        case EMAIL:
            return wrap(value.emailValue)
        case RADIO_SELECTOR:
            return wrap(<span key={'value'}>{getFormattedRadioButtonValue(value.radioButtonValue)}</span>)
        case MULTI_SELECTOR:
            return wrap(getFormattedMultiChoiceValue(value.multiChoiceValue).map(string => string).join(', '))
        case FILES_LIST:
            const gallery = value.files.map(f => ({ ...f }))
            return wrap([
                ...gallery.map(file => (
                    <div key={file.id} className="file">
                        <i className="icon-attachment_document"/>
                        <FilePreview className="file" file={file} gallery={gallery} isopen={false}/>
                    </div>
                ))
            ])
        case NUMERIC_VALUE:
            return wrap(formatNumber(value.numericValue))
        case MONEY:
            let { amount, currencyInfo } = value.moneyValue
            if (typeof amount === 'string') {
                amount = Number(amount.split(' ').join(''))
            }
            if (amount) {
                return wrap([
                    <span key={'value'} className="amount">{formatNumber(amount)}</span>,
                    <span key={'icon'} className="unit single">{currencyInfo.symbol || currencyInfo.code}</span>
                ])
            }
            return emptyValue()
        case FORMULA:
            const { numericValue, moneyValue } = value
            if (numericValue) {
                return wrap(formatNumber(numericValue))
            }
            if (moneyValue && moneyValue.amount !== undefined) {
                return wrap([
                    <span key={'value'} className="amount">{formatNumber(moneyValue.amount)}</span>,
                    <span key={'icon'}
                          className="unit single">{moneyValue.currencyInfo.symbol || moneyValue.currencyInfo.code}</span>
                ])
            }
            return emptyValue()
        default:
            return emptyValue()
    }
}

export default FieldValuePrintView
