import {AuthenticationConfiguration} from './conf/auth';
import {TranslateConfiguration} from './conf/translate';
import {HttpConfiguration} from './conf/http';

export function config($logProvider, $qProvider, $httpProvider, $translateProvider, $locationProvider, $authProvider, ServerConfig) {
    'ngInject';
    $logProvider.debugEnabled(true);
    if ($qProvider.errorOnUnhandledRejections) {
        $qProvider.errorOnUnhandledRejections(false);
    }

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    }).hashPrefix('!');

    HttpConfiguration($httpProvider);
    AuthenticationConfiguration($authProvider, ServerConfig);
    TranslateConfiguration($translateProvider);
}
