import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import filesize from 'filesize'
import cn from 'classnames'

import PDFViewer from './pdf-viewer.jsx'
import ImageViewer from './image-viewer.jsx'
import Tooltip from '../../modules/react/tooltip.jsx'
import { downloadFile } from '../../modules/reports/store/utils'

import { getService } from '../../modules/react/utils'
import clsx from 'clsx'

import './file-preview.less'

const PDF_CONTENT_TYPE = 'application/pdf'
const IMAGE_CONTENT_TYPES = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']
const fileIsPDF = ({ contentType }) => contentType === PDF_CONTENT_TYPE
const fileIsImage = ({ contentType }) => IMAGE_CONTENT_TYPES.includes(contentType)
import { translate } from '../../modules/react/utils'

class FilePreview extends React.Component {
    static propTypes = {
        file: PropTypes.object.isRequired,
        gallery: PropTypes.array,
        isopen: PropTypes.bool,
        onclose: PropTypes.func,
        className: PropTypes.string
    }

    constructor (props) {
        super(props)

        this.state = {
            isOpen: false,
            openedFileIndex: null,
            loadedFiles: {},
            scaleToFit: true,
            hasError: false,
            imageRotateAngle: 0
        }

        this.DevicesDetector = getService('DevicesDetector')
    }

    componentDidUpdate (prevProps, prevState) {
        if (!prevProps.isopen && this.props.isopen) {
            this.setState({ isOpen: true })
        }
        if (this.state.isOpen) {
            const file = this.getOpenedFile()
            if (fileIsImage(file)) {
                this.loadImageIfNotLoaded(file)
            }
        }
    }

    loadImageIfNotLoaded = file => {
        const { loadedFiles } = this.state
        if (!loadedFiles[file.id]) {
            file.$image = new Image()
            file.$image.onload = () => {
                this.setState({
                    loadedFiles: { ...this.state.loadedFiles, [file.id]: true }
                })
            }
            file.$image.src = file.url
        }
    }

    openPreview = e => {
        if (e.button === 0 || e.type === 'touchstart') {
            e.preventDefault()
            e.stopPropagation()
            const file = this.getOpenedFile()
            if (fileIsImage(file) || fileIsPDF(file)) {
                this.setState({
                    isOpen: true,
                    openedFileIndex: this.props.gallery
                        ? this.props.gallery.findIndex(f => f.id === this.props.file.id)
                        : null
                })
            } else {
                this.downloadFile()
            }
        }
    }

    closePreview = e => {
        if (e) {
            e.stopPropagation()
        }
        this.setState({ isOpen: false, openedFileIndex: null, scaleToFit: true, hasError: false })
        if (this.props.onclose) {
            this.props.onclose()
        }
    }

    downloadFile = e => {
        if (e) {
            e.stopPropagation()
        }

        downloadFile(this.getOpenedFile().url, this.DevicesDetector.isMobile())
    }

    renderFileTypeIcon = (file = this.getOpenedFile()) => {
        let icon = 'file-type-any.svg'
        if (fileIsPDF(file)) {
            icon = 'file-type-pdf.svg'
        } else if (fileIsImage(file)) {
            icon = 'file-type-img.svg'
        }

        return (
            <img src={`/assets/icons/${icon}`} alt="" className="file-type"/>
        )
    }

    renderGalleryItem = file => {
        const { loadedFiles } = this.state
        if (fileIsImage(file)) {
            if (loadedFiles[file.id]) {
                return <img src={file.url} alt={file.fileName}/>
            }
            this.loadImageIfNotLoaded(file)
        }

        return this.renderFileTypeIcon(file)
    }

    rotateImage = e => {
        if (e) {
            e.stopPropagation()
        }
        const { imageRotateAngle } = this.state
        this.setState({ imageRotateAngle: ((imageRotateAngle || 360) - 90) % 360 })
    }

    getOpenedFile = () => {
        const { openedFileIndex } = this.state
        const { file, gallery } = this.props
        return gallery && gallery[openedFileIndex] ? gallery[openedFileIndex] : file
    }

    openPrevFile = e => {
        if (e) {
            e.stopPropagation()
        }
        const { gallery } = this.props
        const { openedFileIndex } = this.state

        if (gallery && openedFileIndex > 0) {
            this.setState({ openedFileIndex: openedFileIndex - 1, scaleToFit: true, hasError: false })
        }
    }

    openNextFile = e => {
        if (e) {
            e.stopPropagation()
        }
        const { gallery } = this.props
        const { openedFileIndex } = this.state

        if (gallery && openedFileIndex < gallery.length - 1) {
            this.setState({ openedFileIndex: openedFileIndex + 1, scaleToFit: true, hasError: false })
        }
    }

    openFileAtIndex = (index, e) => {
        if (e) {
            e.stopPropagation()
        }
        this.setState({ openedFileIndex: index, scaleToFit: true, hasError: false })
    }

    toggleScale = e => {
        if (e) {
            e.stopPropagation()
        }
        this.setState({ scaleToFit: !this.state.scaleToFit })
    }

    renderPreview = () => {
        const { isOpen, scaleToFit, hasError, imageRotateAngle } = this.state
        if (!isOpen) {
            return null
        }

        const file = this.getOpenedFile()

        if (fileIsImage(file)) {
            return (
                <ImageViewer
                    src={file.url}
                    fit={this.state.scaleToFit}
                    angle={imageRotateAngle}
                    onImageClick={() => this.setState({ scaleToFit: !scaleToFit })}
                />
            )
        } else if (fileIsPDF(file) && !hasError) {
            return (
                <PDFViewer
                    file={file.previewUrl}
                    scale={this.state.scaleToFit ? .45 : .9}
                    onError={() => this.setState({ hasError: true })}
                />
            )
        }

        return this.renderOtherFilesScreen()
    }

    renderOtherFilesScreen = () => {
        return (
            <div className="file-preview__content__other">
                <div className="file-preview__content__other__icon">{this.renderFileTypeIcon()}</div>
                <div className="file-preview__content__other__text">{translate('text.filePreview.noPreview')}</div>
                <div className="file-preview__content__other__download">
                    <a onClick={this.downloadFile}>{translate('label.filePreview.download')}</a>
                </div>
            </div>
        )
    }

    render () {
        const { file, gallery } = this.props
        const { openedFileIndex, isOpen } = this.state
        const openedFile = this.getOpenedFile()
        return (
            <React.Fragment>
                <a className={clsx('file-preview__link', this.props.className)} onMouseDown={this.openPreview}
                   onTouchStart={this.openPreview}>
                    <span>{file.fileName}</span>
                </a>
                <ReactModal
                    isOpen={isOpen}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={this.closePreview}
                    ariaHideApp={false}
                    className="file-preview"
                    overlayClassName="file-preview__overlay">
                    <div className="file-preview__header">
                        <div className="file-preview__header__icon">{this.renderFileTypeIcon()}</div>
                        <div className="file-preview__header__filename">
                            <h1>{openedFile.fileName}</h1>
                            {openedFile.fileSize && (
                                <small>{filesize(openedFile.fileSize, { round: 0, base: 10 })}</small>
                            )}
                        </div>
                        <div className="file-preview__header__actions">
                            {fileIsImage(file) && (
                                <span className="file-preview__header__actions__rotate" onClick={this.rotateImage}>
                                    <Tooltip
                                        text="tooltips.filePreview.rotate"
                                        isDisabled={false}>
                                        <i className="icon-button_rotate"/>
                                    </Tooltip>
                                </span>
                            )}
                            <span className="file-preview__header__actions__zoom" onClick={this.toggleScale}>
                                <Tooltip
                                    text="tooltips.filePreview.zoom"
                                    isDisabled={false}>
                                    {this.state.scaleToFit ? (
                                        <i className="icon-button_zoom_in"/>
                                    ) : (
                                        <i className="icon-button_zoom_out"/>
                                    )}
                                </Tooltip>
                            </span>
                            <span className="file-preview__header__actions__download" onClick={this.downloadFile}>
                                <Tooltip
                                    text="tooltips.filePreview.download"
                                    isDisabled={false}>
                                    <i className="icon-button_download"/>
                                </Tooltip>
                            </span>
                            <span className="file-preview__header__actions__close" onClick={this.closePreview}>
                                <i className="icon-common_close"/>
                            </span>
                        </div>
                    </div>
                    <div
                        className={cn('file-preview__content', { pdf: fileIsPDF(openedFile) })}
                        onClick={this.closePreview}
                    >
                        {this.renderPreview()}
                    </div>
                    {(isOpen && gallery && gallery.length) && (
                        <div className="file-preview__footer">
                            {openedFileIndex > 0 && (
                                <span className="file-preview__gallery__prev" onClick={this.openPrevFile}>
                                    <i className="icon-task_previous"/>
                                </span>
                            )}
                            <ul className="file-preview__gallery">
                                {gallery.map((f, i) => (
                                    <li
                                        key={f.id || i}
                                        onClick={(e) => f.url ? this.openFileAtIndex(i, e) : false}
                                        className={cn('file-preview__gallery__item', { active: openedFileIndex === i })}
                                    >
                                        <Tooltip
                                            text={f.fileName}
                                            forceDisplay={true}
                                            isDisabled={false}>
                                            {this.renderGalleryItem(f)}
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                            {(openedFileIndex < gallery.length - 1) && (
                                <span className="file-preview__gallery__next" onClick={this.openNextFile}>
                                    <i className="icon-task_next"/>
                                </span>
                            )}
                        </div>
                    )}
                </ReactModal>
            </React.Fragment>
        )
    }
}

export default FilePreview
