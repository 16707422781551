import globalUtils from '../../../utils';

export function HomeSettings(PageSettings) {
    'ngInject';
    let filterModes = ['My', 'Delegated', 'Completed', 'DelegatedCompleted'];
    let settings = {
        showList: !PageSettings.smallScreensMode(),
        isToggled: false,
        filterModes: filterModes,
        activeFilterId: filterModes[0],
        mainMode: true, // true - My; false - Delegated
        subMode: true, // true - Active; false - Completed
        myTasksGroup: globalUtils.localStorage.get('MyTasksGroup') != null && !isNaN(Number(globalUtils.localStorage.get('MyTasksGroup')))
            ? Number(globalUtils.localStorage.get('MyTasksGroup'))
            : 0,
        availableTasksGroup: globalUtils.localStorage.get('AvailableTasksGroup') != null && !isNaN(Number(globalUtils.localStorage.get('AvailableTasksGroup')))
            ? Number(globalUtils.localStorage.get('AvailableTasksGroup'))
            : 0,
        scrollTopValues: {},
        reset: () => {
            settings.activeFilterId = filterModes[0];
            settings.mainMode = settings.subMode = true;
            settings.isToggled = false;
            settings.showList = !PageSettings.smallScreensMode();
        },
        toggleList: () => {
            settings.isToggled = true;
            settings.showList = !settings.showList;
        },
        toggleMode: () => {
            if (settings.mainMode) {
                settings.activeFilterId = settings.subMode ? filterModes[0] : filterModes[2];
            } else {
                settings.activeFilterId = settings.subMode ? filterModes[1] : filterModes[3];
            }
        },
        clearStorage: () => {
            globalUtils.localStorage.remove('MyTasksGroup');
            globalUtils.localStorage.remove('AvailableTasksGroup');
        }
    };
    return settings;
}
