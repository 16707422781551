export function paymentStatus($translate) {
    'ngInject';
    
    return (input) => {
        switch(input) {
            case 'PAID':
                return $translate.instant('label.invoice.status.value.PAID');
            case 'PAYMENT_DUE':
                return $translate.instant('label.invoice.status.value.PAYMENT_DUE');
            case 'POSTED':
                return $translate.instant('label.invoice.status.value.POSTED');
            case 'NOT_PAID':
                return $translate.instant('label.invoice.status.value.NOT_PAID');
            case 'VOIDED':
                return $translate.instant('label.invoice.status.value.VOIDED');
            case 'PENDING':
                return $translate.instant('label.invoice.status.value.PENDING');
            default:
                break;
        }
    };
}
