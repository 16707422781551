import MarkdownIt from 'markdown-it';
import BlockEmbedPlugin from 'markdown-it-block-embed'

export function markdownContent() {
    return {
        restrict: 'A',
        scope: {
            content: '=markdownContent'
        },
        link: ($scope, element) => {
            let md = new MarkdownIt({
                html: true,
                linkify: true,
                typographer: true,
                breaks: true
            }).use(BlockEmbedPlugin, {
                containerClassName: "video-embed",
                serviceClassPrefix: "video-embed-service-",
                outputPlayerSize: false
            });
            const defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, selfParam) {
                return selfParam.renderToken(tokens, idx, options);
            };
            md.renderer.rules.link_open = function(tokens, idx, options, env, selfParam) {
                let aIndex = tokens[idx].attrIndex('target');
                if (aIndex < 0) {
                    tokens[idx].attrPush(['target', '_blank']); // add new attribute
                } else {
                    tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
                }
                return defaultRender(tokens, idx, options, env, selfParam);
            };
            $scope.$watch('content', val => {
                if (val) {
                    element.html(md.render(val));
                } else {
                    element.html('');
                }
            });
        }
    };
}
