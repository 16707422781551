import React from 'react'
import PropTypes from 'prop-types'
import ReportHeader from './report-header.jsx'
import ReportFilters from '../containers/report-filters-container'
import ReportDataTable from './report-data-table.jsx'
import ContainerDimensions from 'react-container-dimensions'
import { DataLoader, reportHasData, reportHasValidFilters } from '../store/utils'
import Modal from '../../../components/react/modal.jsx'
import ReportRecordView from './report-record-view.jsx'
import { translate } from '../../react/utils'

import '../less/report.less'

class ReportView extends React.Component {
    static propTypes = {
        report: PropTypes.object,
        selectedRowIndex: PropTypes.number,
        textParser: PropTypes.func.isRequired,
        requestReportDataSizeAction: PropTypes.func.isRequired,
        requestReportDataAction: PropTypes.func.isRequired,
        receiveReportDataAction: PropTypes.func.isRequired,
        exportReportAction: PropTypes.func.isRequired,
        onColumnsChange: PropTypes.func.isRequired,
        selectRowAction: PropTypes.func.isRequired,
        DevicesDetector: PropTypes.object
    }

    constructor (props) {
        super(props)

        const { requestReportDataAction, receiveReportDataAction } = props
        this.dataLoader = new DataLoader(requestReportDataAction, receiveReportDataAction)
    }

    componentDidMount () {
        const { report } = this.props
        if (report && report.data.isReceived) {
            this.props.requestReportDataSizeAction()
        }
    }

    componentWillUnmount () {
        const { selectRowAction } = this.props
        selectRowAction(null)
    }

    componentDidUpdate (prevProps) {
        const { report } = this.props
        if (!reportHasData(prevProps.report) && reportHasData(report)) {
            this.dataLoader.initialize()
            this.dataLoader.getObjectAt(report.data.list, 0)
        }
    }

    render () {
        const {
            report,
            selectedRowIndex,
            textParser,
            requestReportDataSizeAction,
            exportReportAction,
            selectRowAction,
            onColumnsChange,
            DevicesDetector
        } = this.props

        if (!report) {
            return null
        }

        const dataIsLoading = report.data.isLoading
        const dataIsReceived = report.data.isReceived
        const filterIsEmpty = !dataIsLoading && !reportHasValidFilters(report)
        const filterIsNotApplied = !dataIsLoading && !filterIsEmpty
        const dataIsEmpty = !reportHasData(report)

        const selectedRecord = dataIsReceived && selectedRowIndex !== null
            ? report.data.list[selectedRowIndex]
            : null

        return (
            <div className="report">
                <ReportHeader
                    report={report}
                    onReportExport={exportReportAction}
                    dataIsReceived={dataIsReceived && !dataIsEmpty}
                />
                <div className="report-layout">
                    <ReportFilters onApplyChanges={requestReportDataSizeAction}/>
                    <div className="report-content">
                        {(dataIsReceived && !dataIsEmpty) && (
                            <div className="report-data-container">
                                <div className="report-data-count">
                                    {translate('plural.report.itemsFound', { COUNT: report.data.total }, true)}
                                </div>
                                <ContainerDimensions>
                                    <ReportDataTable
                                        data={report.data}
                                        columns={report.columns}
                                        onColumnsChange={(columns) => onColumnsChange(report.id, columns)}
                                        dataLoader={this.dataLoader}
                                        selectRowAction={selectRowAction}
                                        selectedRowIndex={selectedRowIndex}
                                        isMobile={DevicesDetector.isMobile()}
                                    />
                                </ContainerDimensions>
                            </div>
                        )}
                        {(dataIsReceived && dataIsEmpty) && (
                            <div className="report-states">
                                <div className="report-state">
                                    <i className="icon-empty_search report-state__icon"/>
                                    <h3 className="report-state__header">{translate('reportStates.empty.header')}</h3>
                                    <p className="report-state__summary">{translate('reportStates.empty.text')}</p>
                                </div>
                            </div>
                        )}
                        {(!dataIsReceived && filterIsEmpty) && (
                            <div className="report-states">
                                <div className="report-state">
                                    <i className="icon-empty_filter report-state__icon"/>
                                    <h3 className="report-state__header">{translate('reportStates.notReceived.header')}</h3>
                                    <p className="report-state__summary">{translate('reportStates.notReceived.text')}</p>
                                </div>
                            </div>
                        )}
                        {(!dataIsReceived && filterIsNotApplied) && (
                            <div className="report-states">
                                <div className="report-state">
                                    <i className="icon-empty_filter report-state__icon"/>
                                    <h3 className="report-state__header">{translate('reportStates.notReceived.header')}</h3>
                                    <p className="report-state__summary">
                                        <button
                                            type="button"
                                            className="btn btn-link btn-inline"
                                            onClick={() => requestReportDataSizeAction()}
                                        >
                                            {translate('report.filters.applyButton')}
                                        </button>
                                    </p>
                                </div>
                            </div>
                        )}
                        {dataIsLoading && (
                            <div className="report-states">
                                <div className="report-state">
                                    <i className="icon-task_field_save_ring report-state__icon"/>
                                    <h3 className="report-state__header">{translate('label.loading')}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <Modal
                        isOpen={selectedRecord !== null && report.data.list[selectedRowIndex] !== undefined}
                        closeAction={() => {selectRowAction(null)}}
                        title={selectedRecord ? selectedRecord.name : ''}
                        className="report-record-modal"
                    >
                        {(selectedRecord !== null && report.data.list[selectedRowIndex] !== undefined) && (
                            <ReportRecordView
                                columns={report.columns}
                                record={report.data.list[selectedRowIndex]}
                                textParser={textParser}
                            />
                        )}
                    </Modal>
                </div>
            </div>
        )
    }
}

export default ReportView
