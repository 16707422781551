import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { PROCESS_STATUS_FILTER_OPTIONS, TASK_STATUS_FILTER_OPTIONS } from '../store/constants'
import { translate } from '../../react/utils'

const StatusFormatter = ({ status, isProcess, withColor = true }) => {
    const statusId = status.toLowerCase()
    if (status) {
        const statusName = (isProcess ? PROCESS_STATUS_FILTER_OPTIONS : TASK_STATUS_FILTER_OPTIONS).find(opt => opt.id === status).name

        return (
            <span className={cn('status-formatter', { [statusId]: withColor })}>
            {translate(statusName)}
        </span>
        )
    }

    return null
}

StatusFormatter.propTypes = {
    status: PropTypes.string,
    isProcess: PropTypes.bool,
    withColor: PropTypes.bool
}

export default StatusFormatter
