import moment from 'moment-timezone'
import { FILE_ERRORS } from './services/print-errors'
import colors from './libs/colors'

const HTML_ENTITIES_MAP = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
}

export function escapeHtml(string) {
    return String(string).replace(/[&<>]/g, (s) => HTML_ENTITIES_MAP[s]);
}

export function unescapeHtml(string) {
    let result = string;

    Object.keys(HTML_ENTITIES_MAP).forEach(key => {
        let re = new RegExp(HTML_ENTITIES_MAP[key], 'g');
        result = result.replace(re, key);
    });

    return result;
}

export function getTextWidth(text, fontStyle) {
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = fontStyle || window.getComputedStyle(document.body).font;

    return context.measureText(text).width;
}

export function getTextWidthForNode(node, text) {
    const inputStyle = window.getComputedStyle(node)
    const inputFontStyle = inputStyle.font || inputStyle['font-size'] + ' ' + inputStyle['font-family']
    return getTextWidth(text, inputFontStyle)
}

export function getUserName(user) {
    const keys = ['fullName', 'name', 'email'];
    return keys.map(key => user[key] && user[key].trim() ? user[key] : '').filter(key => key)[0];
}

export function getUserColor({colorIndex}) {
    return colors[colorIndex - 1];
}

const getMentionsList = (currentUser, task, taskComments, allActiveUsers, tree, isEmpty, process) => {
    let idsInUse = [currentUser.id];
    let idsProcessStarters = [];
    let idsProcessManager = [];
    let idsTaskOwners = [];
    let idsTaskAssignees = [];
    let result = [];
    let processObj = process || task.process;

    // Calculate process actors' id
    if (processObj && processObj.managers) {
        processObj.managers.users.forEach(m => {
            if (m.id !== currentUser.id) {
                idsInUse.push(m.id);
                idsProcessManager.push(m.id);
            }
        });
        processObj.managers.groups.forEach(g => {
            const group = tree && tree.groups.find(gT => gT.id === g.id);
            if (group && group.users) {
                group.users.forEach(u => {
                    if (!~idsInUse.indexOf(u.id)) {
                        idsInUse.push(u.id);
                        idsProcessManager.push(u.id);
                    }
                });
            }
        });
    }
    // Calculate task creators' id
    if (task.creator && task.creator.id && !~idsInUse.indexOf(task.creator.id)) {
        idsInUse.push(task.creator.id);
        idsTaskOwners.push(task.creator.id);
    }
    // Calculate assignees' id
    if (task.assignee && task.assignee.id && !~idsInUse.indexOf(task.assignee.id)) {
        idsInUse.push(task.assignee.id);
        idsTaskAssignees.push(task.assignee.id);
    }
    // Calculate process starters' id
    if (processObj && processObj.starter && processObj.starter.id
        && processObj.starter.id !== currentUser.id && !~idsInUse.indexOf(processObj.starter.id)) {
        idsInUse.push(processObj.starter.id);
        idsProcessStarters.push(processObj.starter.id);
    }
    // Calculate commentator' id
    let idsCommentsCreators = taskComments ? taskComments.filter(c => {
        return !!c.creator && !!c.creator.id && !~idsInUse.indexOf(c.creator.id) && idsInUse.push(c.creator.id);
    }).map(c => c.creator.id) : [];

    allActiveUsers.forEach(_user => {
        let userCopy = Object.assign({}, _user);
        if (~idsProcessManager.indexOf(userCopy.id)) {
            userCopy.isProcessManager = true;
        } else if (~idsTaskOwners.indexOf(userCopy.id)) {
            userCopy.isTaskOwner = true;
        } else if (~idsTaskAssignees.indexOf(_user.id)) {
            userCopy.isAssignee = true;
        } else if (~idsProcessStarters.indexOf(userCopy.id)) {
            userCopy.isProcessStarter = true;
        } else if (~idsCommentsCreators.indexOf(_user.id)) {
            userCopy.isCommentator = true;
        } else if (_user.id !== currentUser.id) {
            userCopy.isAnother = true;
        }
        result.push(userCopy);
    });

    if (isEmpty) {
        return result.filter(u => u.isProcessStarter || u.isProcessManager || u.isTaskOwner || u.isAssignee || u.isCommentator)
            .sort((a, b) => {
                if (a.isProcessManager) {
                    return 0;
                } else if (b.isProcessManager) {
                    return 1;
                } else if (a.isAssignee) {
                    return 0;
                } else if (b.isAssignee) {
                    return 1;
                }
                return 0;
            });
    }
    return allActiveUsers;
};

const getTmplDataModel = (tmpl) => {
    const defaultTemplateData = {
        processStart: {
            titleSettings: {
                isAutoGenerated: false
            }
        },
        dataModel: {
            list: []
        }
    };

    const templateInstance = Object.assign({}, defaultTemplateData, tmpl);

    if (templateInstance.processStart.titleSettings && templateInstance.processStart.titleSettings.templateString) {
        templateInstance.processStart.titleSettings.templateString = unescapeHtml(templateInstance.processStart.titleSettings.templateString);
    }

    return templateInstance;
};

const checkLSFn = () => {
    let testKey = 'test';
    let storage = window.sessionStorage; //eslint-disable-line
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch(e) {
        return false;
    }
};
const isLocalStorageSupported = checkLSFn();

function deepCopyIfNeeded(obj1, obj2) {
    for(let name1 in obj1) {
        if(!obj2.hasOwnProperty(name1)) {
            if(obj1 instanceof Array) {
                obj1.splice(name1, 1);
            } else {
                delete obj1[name1];
            }
        } else if (obj1[name1] instanceof Object && obj2[name1]) {
            obj1[name1] = deepCopyIfNeeded(obj1[name1], obj2[name1]); // same reference
        } else if (obj1[name1] !== obj2[name1]) {
            obj1[name1] = obj2[name1];
        }
    }
    for(let name2 in obj2) {
        if (obj2[name2] instanceof Object && obj1[name2]) {
            obj1[name2] = deepCopyIfNeeded(obj1[name2], obj2[name2]); // same reference
        } else if (obj1[name2] !== obj2[name2]) {
            obj1[name2] = obj2[name2];
        }
    }
    return obj1;
}

export function validateAttachedFiles(validFiles, invalidFiles, configObject) {
    const files = [];
    const errFiles = [];
    const {fileNameMinLength, fileNameMaxLength, pattern_extension, allowedFileFormats} = configObject;
    const fileNameHasValidLength = (name) => {
        return name.length >= fileNameMinLength && name.length <= fileNameMaxLength;
    };

    validFiles.filter(file => !invalidFiles.find(f => f === file)).forEach(file => {
        if (!fileNameHasValidLength(file.name)) {
            invalidFiles.push(file);
        } else {
            files.push(file);
        }
    });

    invalidFiles.forEach(file => {
        const ext = pattern_extension.exec(file.name)[1];

        if (ext && allowedFileFormats.indexOf(ext) > -1 && fileNameHasValidLength(file.name)) {
            files.push(file);
        } else {
            if (file.name.length < fileNameMinLength) {
                file.$error = FILE_ERRORS.fileNameMinLength;
            }
            if (file.name.length > fileNameMaxLength) {
                file.$error = FILE_ERRORS.fileNameMaxLength;
            }

            errFiles.push(file);
        }
    });

    return [files, errFiles];
}

export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        let textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            let range = document.createRange();
            textarea.contenteditable = true;
            range.selectNodeContents(textarea);

            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            textarea.setSelectionRange(0, 999999);
        } else {
            textarea.select();
        }

        try {
            return document.execCommand('copy');
        } catch (ex) {
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
}

export function uuid() {
    if (window.crypto) {
        return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c => //eslint-disable-line
            (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    } else {
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); //eslint-disable-line
            return v.toString(16);
        });
    }
}

export const localStorageHelper = {
    set: (key, value) => {
        return isLocalStorageSupported ? localStorage.setItem(key, value) : null;
    },
    get: key => {
        return isLocalStorageSupported ? localStorage.getItem(key) : null;
    },
    remove: key => {
        return isLocalStorageSupported ? localStorage.removeItem(key) : null;
    }
};

/**
 * unique id without dashes
 * @returns {string}
 */
export function uid4 (savedFieldIds) {
    let uid = uuid()
    if (savedFieldIds && savedFieldIds.some(id => id === uid)) {
        return uid4(savedFieldIds)
    } else {
        return uid
    }
}

export default {
    localStorage: localStorageHelper,
    getTmplDataModel: getTmplDataModel,
    getMentionsList: getMentionsList,
    deepCopyIfNeeded: deepCopyIfNeeded,
    queryAPI: (path, method) => {
        const form = document.createElement('form');
        form.setAttribute('method', method || 'POST');
        form.setAttribute('action', path);
        form.setAttribute('enctype', 'application/json');

        let input = document.createElement('input');
        input.type = 'text';
        input.name = 'timeZoneId';
        input.value = moment.tz.guess();

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
    },
    getParamByName: (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    uid4: uid4
};
