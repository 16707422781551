export function timerFromNow(MomentHelper) {
    'ngInject';
    let interval = 60 * 1000;

    return {
        restrict: 'E',
        scope: {
            start: '='
        },
        replace: true,
        template: '<span>&nbsp;{{time}}&nbsp;</span>',
        controller: ($scope, $timeout) => {
            'ngInject';

            $scope.tick = () => {
                $scope.time = MomentHelper.fromNow($scope.start);

                $scope.timerId = $timeout(() => {
                    $scope.tick();
                }, interval);
            };
            $scope.tick();

            $scope.$on('$destroy', () => {
                $timeout.cancel($scope.timerId);
            });
        },
        link: (scope) => {
            scope.time = MomentHelper.fromNow(scope.start);
        }
    };
}
