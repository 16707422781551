import React from 'react'
import PropTypes from 'prop-types'
import { Cell, Column, Table } from 'fixed-data-table-2'
import ReportDataCell from './report-data-cell.jsx'
import { DEFAULT_COLUMN_WIDTH, DEFAULT_HEADER_HEIGHT, DEFAULT_ROW_HEIGHT } from '../store/constants'
import { translate } from '../../react/utils'

class ReportDataTable extends React.Component {
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        data: PropTypes.object.isRequired,
        columns: PropTypes.object.isRequired,
        onColumnsChange: PropTypes.func.isRequired,
        dataLoader: PropTypes.object.isRequired,
        selectRowAction: PropTypes.func.isRequired,
        selectedRowIndex: PropTypes.number,
        isMobile: PropTypes.bool
    }

    constructor (props) {
        super(props)

        this.state = {
            activeRowIndex: null
        }
    }

    onColumnResizeEndCallback (newColumnWidth, columnKey) {
        const { columns, onColumnsChange } = this.props
        const newColumnState = { ...columns[columnKey], width: newColumnWidth, flexGrow: 0 }
        onColumnsChange({ ...columns, [columnKey]: newColumnState })
    }

    toggleRowHover (rowIndex, state) {
        this.setState({
            activeRowIndex: state ? rowIndex : null
        })
    }

    selectRow (rowIndex) {
        this.props.selectRowAction(rowIndex)
    }

    render () {
        const { height, width, data, columns, selectedRowIndex, dataLoader, isMobile } = this.props
        const columnKeys = Object.keys(columns)
        const dataCountElementHeight = 20

        return (
            <Table
                rowHeight={DEFAULT_ROW_HEIGHT}
                headerHeight={DEFAULT_HEADER_HEIGHT}
                rowsCount={data.total}
                width={width}
                height={height - dataCountElementHeight}
                onColumnResizeEndCallback={this.onColumnResizeEndCallback.bind(this)}
                isColumnResizing={false}
                allowCellsRecycling={true}
                touchScrollEnabled={isMobile}
                onRowClick={(event, rowIndex) => this.selectRow(rowIndex)}
                onRowMouseEnter={(event, rowIndex) => this.toggleRowHover(rowIndex, true)}
                onRowMouseLeave={(event, rowIndex) => this.toggleRowHover(rowIndex, false)}
            >
                {columnKeys.filter(key => !columns[key].isHidden).map(columnKey => (
                    <Column
                        key={columnKey}
                        columnKey={columnKey}
                        isResizable={true}
                        fixed={columns[columnKey].isFixed}
                        width={columns[columnKey].width || DEFAULT_COLUMN_WIDTH}
                        flexGrow={columns[columnKey].flexGrow}
                        header={
                            <Cell className="report-column-header">
                                <span className="report-column-header-content">
                                    {translate(columns[columnKey].label)}
                                </span>
                            </Cell>
                        }
                        cell={
                            <ReportDataCell
                                data={data.list}
                                column={columns[columnKey]}
                                dataLoader={dataLoader}
                                activeRowIndex={this.state.activeRowIndex}
                            />
                        }
                    />
                ))}
            </Table>
        )
    }
}

export default ReportDataTable

