import config from '../../../config';
import stringEntropy from 'fast-password-entropy'

export class ResetController {
    constructor($scope, data, $translate, $auth, $state, $stateParams, FormErrors, ServerConfig, AuthApiCalls,
                CurrentUserService) {
        'ngInject';
        $scope.userEmail = data && data.result ? data.result.email : ' ';
        $scope.disabledMode = $stateParams.disabledMode;
        $scope.emailPattern = config.pattern_emails;

        $scope.checkField = fieldName => {
            if (!$scope.resetForm || !$scope.resetForm[fieldName]) {
                return;
            }
            return $scope.resetForm[fieldName].$invalid && $scope.resetForm[fieldName].$dirty;
        };
        $scope.fieldError = fieldName => {
            return FormErrors($scope.resetForm, fieldName);
        };

        $scope.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        $scope.passwordIsWeak = undefined
        $scope.handleOnChange = () => {
            $scope.passwordIsWeak = $scope.user?.password && stringEntropy($scope.user.password) < config.minimal_entropy
        }

        const resetApiCall = $scope.disabledMode ? AuthApiCalls.resetDisabled : AuthApiCalls.reset;
        const resetStartApiCall = $scope.disabledMode ? AuthApiCalls.resetDisabledStart : AuthApiCalls.resetStart;

        let isWaiting = false;
        $scope.sendMail = () => {
            if (isWaiting) {
                return;
            }
            let form = $scope.resetForm;
            let userData = angular.toJson($scope.user);

            if (form.$valid) {
                isWaiting = true;
                resetStartApiCall(userData)
                    .then(resp => {
                        if (resp.success) {
                            $scope.sended = true;
                        }
                    })
                    .catch(response => {
                        let respData = response.data;
                        $scope.formHint = respData && respData.error || $translate.instant('error.common');
                    })
                    .finally(() => isWaiting = false);
            }
        };

        $scope.resetPassword = () => {
            if (isWaiting) {
                return;
            }
            let form = $scope.resetForm;
            let userData = angular.toJson($scope.user);

            if (form.$valid) {
                isWaiting = true;
                resetApiCall($stateParams.mailToken, userData)
                    .then(resp => {
                        if (resp.result) {
                            $auth.setToken(resp.result.token);
                            return CurrentUserService.reload(resp.result.token, $scope.disabledMode)
                                .finally(() => $state.go($scope.disabledMode ? 'billing-disabled' : 'main.home'));
                        }
                    })
                    .catch((response) => {
                        let respData = response.data;
                        if (respData.password && respData.password[0] && respData.password[0].fieldError) {
                            $scope.formHint = respData.password[0].fieldError;
                        } else {
                            $scope.formHint = respData && respData.error || $translate.instant('error.common');
                        }
                        isWaiting = false;
                    });
            }
        };
    }
}
