import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Cell } from 'fixed-data-table-2'
import isNil from 'lodash/isNil'
import { DURATION } from '../store/constants'
import StatusFormatter from './status-formatter.jsx'
import { calculateDuration, formatDuration, formatNumber } from '../store/utils'
import FilePreview from '../../../components/react/file-preview.jsx'
import { getFormattedMultiChoiceValue, getFormattedRadioButtonValue } from '../../database/models/database-utils'
import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    DATE_WITHOUT_TIME,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STATUS,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import DateTimeFormatter from './date-time-formatter'
import { translate } from '../../react/utils'

function renderCell (datum, column) {
    if (!datum) {
        return ''
    }

    let { getter, dataType = STRING_SINGLE_LINE, dataTypeGetter } = column
    if (dataTypeGetter) {
        dataType = dataTypeGetter(datum)
    }

    let value = getter ? getter(datum) : null
    if (isNil(value)) {
        return null
    }

    switch (dataType) {
        case STRING_SINGLE_LINE:
        case STRING_MULTI_LINE:
            return value.stringValue || value
        case DATE_WITHOUT_TIME:
        case DATE_ONLY_VALUE:
        case DATE_AND_TIME:
            const timestamp = Number(value.dateValue || value)
            return (
                <DateTimeFormatter timestamp={timestamp} withTime={dataType === DATE_AND_TIME}/>
            )
        case USER_FIELD:
            return value.userValue.fullName
        case YES_NO:
            return (<span className="capitalized">{translate(`label.${value.yesNoValue}`)}</span>)
        case STATUS:
            return (
                <StatusFormatter {...value}/>
            )
        case EMAIL:
            return (
                <a href={`mailto:${value.emailValue}`} target="_blank">{value.emailValue}</a>
            )
        case RADIO_SELECTOR:
            return getFormattedRadioButtonValue(value.radioButtonValue)
        case MULTI_SELECTOR:
            return getFormattedMultiChoiceValue(value.multiChoiceValue).join(', ')
        case FILES_LIST:
            const file = value.files[0] ? { ...value.files[0] } : undefined
            const gallery = value.files.map(f => ({ ...f }))
            return (
                <div onClick={(e) => e.stopPropagation()}>
                    {file && (
                        <FilePreview className="file" file={file} gallery={gallery}/>
                    )}
                    {gallery.length > 1 && (
                        <span className="more-files-count">+{gallery.length - 1}</span>
                    )}
                </div>
            )
        case NUMERIC_VALUE:
            return formatNumber(value.numericValue)
        case MONEY:
            const { amount, currencyInfo } = value.moneyValue
            return amount ? `${formatNumber(amount)} ${currencyInfo.symbol || currencyInfo.code}` : null
        case DURATION:
            return formatDuration(calculateDuration(value))
        case FORMULA:
            const { numericValue, moneyValue } = value
            if (numericValue) {
                return formatNumber(numericValue)
            }
            if (moneyValue && moneyValue.amount !== undefined) {
                return `${formatNumber(moneyValue.amount)} ${moneyValue.currencyInfo.symbol || moneyValue.currencyInfo.code}`
            }
            return null
        default:
            return value
    }
}

class ReportDataCell extends React.Component {
    static propTypes = {
        rowIndex: PropTypes.number,
        activeRowIndex: PropTypes.number,
        data: PropTypes.array.isRequired,
        column: PropTypes.object.isRequired,
        dataLoader: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)
    }

    isHighlighted () {
        const { rowIndex, selectedRowIndex, activeRowIndex } = this.props

        return rowIndex === selectedRowIndex || rowIndex === activeRowIndex
    }

    render () {
        const { rowIndex, activeRowIndex, selectedRowIndex, column, data, dataLoader, ...props } = this.props
        const datum = dataLoader.getObjectAt(data, rowIndex)

        return (
            <Cell {...props} className={cn('report-data-cell', 'no-wrap', { active: this.isHighlighted() })}>
                <div className="report-data-cell-value" style={{ width: column.width }}>
                    {renderCell(datum, column)}
                </div>
            </Cell>
        )
    }
}

export default ReportDataCell
