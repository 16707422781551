import config from '../../config';

const checkStringError = field => {
    if (field.settings.isRequiredToFill && (!field.value || !field.value.stringValue)) {
        return 'required';
    }
    if (field.value && field.value.stringValue && field.name.options && field.name.options.minLength
        && field.value.stringValue.length < field.name.options.minLength) {
        return true;
    }
};

const checkEmailError = field => {
    if (field.settings.isRequiredToFill && (!field.value || !field.value.emailValue)) {
        return 'required';
    }
    if (field.value && field.value.emailValue && !config.pattern_emails.test(field.value.emailValue)) {
        return true;
    }
};

const checkYesNoError = field => {
    if (field.settings.isRequiredToFill && (!field.value || !field.value.yesNoValue)) {
        return 'required';
    }
};

const checkNumberError = field => {
    if (field.settings && field.settings.isRequiredToFill) {
        if (!field.value || field.value.numericValue == null
            || field.value.numericValue === '' || field.value.numericValue === '-') {
            return 'required';
        }
    }
    let resValue;
    if (field.value && field.value.numericValue != null && angular.isString(field.value.numericValue)) {
        resValue = Number(field.value.numericValue.replace(/[^0-9\.-]/g, ''));
    } else {
        resValue = field.value ? field.value.numericValue : null;
    }
    if (resValue != null && field.name.options) {
        if (field.name.options.minValue != null && resValue < field.name.options.minValue) {
            return true;
        }
        if (field.name.options.maxValue != null && resValue > field.name.options.maxValue) {
            return true;
        }
    }
};

const checkMoneyError = field => {
    if (field.settings && field.settings.isRequiredToFill) {
        if (!field.value || !field.value.moneyValue || field.value.moneyValue.amount == null
            || field.value.moneyValue.amount === '' || field.value.moneyValue.amount === '-') {
            return 'required';
        }
    }
    let resValue;
    if (field.value && field.value.moneyValue != null && field.value.moneyValue.amount != null
        && angular.isString(field.value.moneyValue.amount)) {
        resValue = Number(field.value.moneyValue.amount.replace(/[^0-9\.-]/g, ''));
    } else {
        resValue = field.value && field.value.moneyValue ? field.value.moneyValue.amount : null;
    }
    if (resValue != null && field.name.options) {
        if (field.name.options.minValue != null && resValue < field.name.options.minValue) {
            return true;
        }
        if (field.name.options.maxValue != null && resValue > field.name.options.maxValue) {
            return true;
        }
    }
};

const checkDateError = field => {
    if (field.settings.isRequiredToFill && (!field.value || !field.value.dateValue)) {
        return 'required';
    }
    if (field.value && field.value.dateValue && field.name.options) {
        if (field.name.options.minValue && field.value.dateValue < field.name.options.minValue) {
            return true;
        }
        if (field.name.options.maxValue && field.value.dateValue > field.name.options.maxValue) {
            return true;
        }
    }
};

const checkDropdownError = field => {
    if (field.settings.isRequiredToFill && (!field.value || !field.value.radioButtonValue)) {
        return 'required';
    }
};

const checkMultiselectorError = field => {
    if (field.settings.isRequiredToFill
        && (!field.value || !field.value.multiChoiceValue || !field.value.multiChoiceValue.length)) {
        return 'required';
    }
};

const checkFilesError = field => {
    return field.settings.isRequiredToFill && (!field.uploadFiles || !field.uploadFiles.length)
        && (!field.value || !field.value.files || !field.value.files.length) && 'required';
};

const checkUserFieldError = field => {
    return field.settings.isRequiredToFill && (!field.value || !field.value.userValue) && 'required';
};

export default {
    checkField: field => {
        if (field.name.dataType === 'FILES_LIST') {
            return checkFilesError(field);
        } else if (~['STRING_MULTI_LINE', 'STRING_SINGLE_LINE'].indexOf(field.name.dataType)) {
            return checkStringError(field);
        } else if (field.name.dataType === 'NUMERIC_VALUE') {
            return checkNumberError(field);
        } else if (field.name.dataType === 'EMAIL') {
            return checkEmailError(field);
        } else if (field.name.dataType === 'YES_NO') {
            return checkYesNoError(field);
        } else if (field.name.dataType === 'MONEY') {
            return checkMoneyError(field);
        } else if (~['DATE_ONLY_VALUE', 'DATE_AND_TIME'].indexOf(field.name.dataType)) {
            return checkDateError(field);
        } else if (field.name.dataType === 'RADIO_SELECTOR') {
            return checkDropdownError(field);
        } else if (field.name.dataType === 'MULTI_SELECTOR') {
            return checkMultiselectorError(field);
        } else if (field.name.dataType === 'USER_FIELD') {
            return checkUserFieldError(field);
        }
    }
};
