const icons = [
    { id: 'icon-Male' },
    { id: 'icon-Girl' },
    { id: 'icon-Add-User' },
    { id: 'icon-Remove-User' },
    { id: 'icon-Target-Market' },
    { id: 'icon-Mens' },
    { id: 'icon-Medal-2' },
    { id: 'icon-Like-2' },
    { id: 'icon-Unlike-2' },
    { id: 'icon-Smile' },
    { id: 'icon-Thumbs-Up-Smiley' },
    { id: 'icon-Thumbs-Down-Smiley' },
    { id: 'icon-Diploma-2' },
    { id: 'icon-Angry' },
    { id: 'icon-Handshake' },
    { id: 'icon-Student-Hat' },
    { id: 'icon-Check' },
    { id: 'icon-Target' },
    { id: 'icon-Idea' },
    { id: 'icon-Sun' },
    { id: 'icon-Half-Moon' },
    { id: 'icon-Storm' },
    { id: 'icon-Snow-2' },
    { id: 'icon-Flash-2' },
    { id: 'icon-Globe' },
    { id: 'icon-Map2' },
    { id: 'icon-Location-2' },
    { id: 'icon-Post-Sign2ways' },
    { id: 'icon-Office' },
    { id: 'icon-Building' },
    { id: 'icon-Summer' },
    { id: 'icon-Plane' },
    { id: 'icon-Bus' },
    { id: 'icon-Car-2' },
    { id: 'icon-Wheelchair' },
    { id: 'icon-Gas-Pump' },
    { id: 'icon-Computer-2' },
    { id: 'icon-Monitor-5' },
    { id: 'icon-Laptop-2' },
    { id: 'icon-Smartphone-2' },
    { id: 'icon-Usb-2' },
    { id: 'icon-CD' },
    { id: 'icon-Wifi' },
    { id: 'icon-Data-Center' },
    { id: 'icon-Printer' },
    { id: 'icon-Box-Open' },
    { id: 'icon-Present' },
    { id: 'icon-Betvibes' },
    { id: 'icon-Management' },
    { id: 'icon-Basket-Ball' },
    { id: 'icon-Balloon' },
    { id: 'icon-Telephone' },
    { id: 'icon-Key' },
    { id: 'icon-Newspaper-2' },
    { id: 'icon-Video-Tripod' },
    { id: 'icon-Camera-3' },
    { id: 'icon-Roller' },
    { id: 'icon-Wrench' },
    { id: 'icon-Oil' },
    { id: 'icon-Drill' },
    { id: 'icon-Scissor' },
    { id: 'icon-Hamburger' },
    { id: 'icon-Coffee' },
    { id: 'icon-Coffee-to-Go' },
    { id: 'icon-Juice' },
    { id: 'icon-Pie-Chart3' },
    { id: 'icon-Bar-Chart-5' },
    { id: 'icon-Line-Chart4' },
    { id: 'icon-Statistic' },
    { id: 'icon-Dollar' },
    { id: 'icon-Shopping-Cart' },
    { id: 'icon-Add-Cart' },
    { id: 'icon-Checkout' },
    { id: 'icon-Financial' },
    { id: 'icon-Shop-3' },
    { id: 'icon-Cash-register2' },
    { id: 'icon-Credit-Card2' },
    { id: 'icon-Bank' },
    { id: 'icon-Coins' },
    { id: 'icon-Tag-3' },
    { id: 'icon-Bar-Code' },
    { id: 'icon-Calculator' },
    { id: 'icon-Magnifi-Glass2' },
    { id: 'icon-Gear' },
    { id: 'icon-Gears' },
    { id: 'icon-Filter-2' },
    { id: 'icon-Repeat-3' },
    { id: 'icon-Add' },
    { id: 'icon-Yes' },
    { id: 'icon-Cursor' },
    { id: 'icon-URL-Window' },
    { id: 'icon-Download-from-Cloud' },
    { id: 'icon-Upload-to-Cloud' },
    { id: 'icon-Cloud' },
    { id: 'icon-Files' },
    { id: 'icon-File-Search' },
    { id: 'icon-File-Pictures' },
    { id: 'icon-Folder' },
    { id: 'icon-Folder-With-Document' },
    { id: 'icon-File' },
    { id: 'icon-Box-withFolders' },
    { id: 'icon-Note' },
    { id: 'icon-Notepad' },
    { id: 'icon-ID-Card' },
    { id: 'icon-Pen' },
    { id: 'icon-Stamp' },
    { id: 'icon-Open-Book' },
    { id: 'icon-Stamp-2' },
    { id: 'icon-Fingerprint-2' },
    { id: 'icon-Unlock' },
    { id: 'icon-Lock' },
    { id: 'icon-Danger' },
    { id: 'icon-Eye' },
    { id: 'icon-Scale' },
    { id: 'icon-Mail-Read' },
    { id: 'icon-Speach-Bubble-Dialog' },
    { id: 'icon-Speach-Bubbles' },
    { id: 'icon-Phone-SMS' },
    { id: 'icon-Mail' },
    { id: 'icon-Speach-BubbleAsking' },
    { id: 'icon-Sand-watch' },
    { id: 'icon-Stopwatch' },
    { id: 'icon-Clock' },
    { id: 'icon-Bell' },
    { id: 'icon-Megaphone' },
    { id: 'icon-Questions' },
    { id: 'icon-Workflow' },
    { id: 'icon-Flow-chart' },
    { id: 'icon-Crowdfunding' },
    { id: 'icon-Crowd' },
    { id: 'icon-Social-network' },
    { id: 'icon-Worldwide-delivery' },
    { id: 'icon-Package' },
    { id: 'icon-House' },
    { id: 'icon-Factory' },
    { id: 'icon-Training' },
    { id: 'icon-QRcode' },
    { id: 'icon-Tracking' },
    { id: 'icon-Today' },
    { id: 'icon-Translation' },
    { id: 'icon-Library' },
    { id: 'icon-Diamond' },
    { id: 'icon-Chart3' },
    { id: 'icon-Chart1' },
    { id: 'icon-Chart4' },
    { id: 'icon-Pound' },
    { id: 'icon-Euro' },
    { id: 'icon-dollar' },
    { id: 'icon-Sum' },
    { id: 'icon-Calendar' },
    { id: 'icon-Calendar3' },
    { id: 'icon-Live-tube' },
    { id: 'icon-Cargo-ship' },
    { id: 'icon-Bot' },
    { id: 'icon-Find-doc' },
    { id: 'icon-Approval' },
    { id: 'icon-Last-24-hours' },
    { id: 'icon-Test-passed' },
    { id: 'icon-Realtime' },
    { id: 'icon-Email-forward' },
    { id: 'icon-Email-reply' },
    { id: 'icon-Email-fav' },
    { id: 'icon-Email-delete' },
    { id: 'icon-Email-add' },
    { id: 'icon-Email-in' },
    { id: 'icon-Email-out' },
    { id: 'icon-Email-go' },
    { id: 'icon-Recycling' },
    { id: 'icon-Flower' },
    { id: 'icon-Flag2' },
    { id: 'icon-Flag' },
    { id: 'icon-Paper-plane' },
    { id: 'icon-Rainbow' },
    { id: 'icon-Tablet' },
    { id: 'icon-Umbrella' },
    { id: 'icon-Paw' },
    { id: 'icon-Pizza' },
    { id: 'icon-Cake' },
    { id: 'icon-Folder-upload' },
    { id: 'icon-Folder-minus' },
    { id: 'icon-Folder-download' },
    { id: 'icon-Folder-plus' },
    { id: 'icon-Trolley' },
    { id: 'icon-Truck' },
    { id: 'icon-Agreement' },
    { id: 'icon-Hierarchy' },
    { id: 'icon-Receipt' },
    { id: 'icon-Template' },
    { id: 'icon-Timetable' },
    { id: 'icon-Data-grid' },
    { id: 'icon-Grid' },
    { id: 'icon-Plug' },
    { id: 'icon-Education' },
    { id: 'icon-Headset' },
    { id: 'icon-Fan' }
]
export default icons
