export function scrollShadow($timeout) {
    'ngInject';
    return {
        restrict: 'C',
        link: ($scope, element) => {
            let scrollListener = e => {
                let el = e.target;
                if (el.scrollTop > 0) {
                    el.parentNode.classList.add('top-shadow');
                } else {
                    el.parentNode.classList.remove('top-shadow');
                }
                if (el.scrollTop + el.clientHeight >= el.scrollHeight - 5) {
                    el.parentNode.classList.remove('bottom-shadow');
                } else if (el.scrollHeight > el.clientHeight) {
                    el.parentNode.classList.add('bottom-shadow');
                }
            };
    
            $timeout(() => {
                if (element[0].scrollHeight > element[0].clientHeight) {
                    element[0].parentNode.classList.add('bottom-shadow');
                }
            }, 0);
    
            element.bind('scroll', scrollListener);
            $scope.$on('$destroy', () => {
                element.unbind('scroll', scrollListener);
            });
        }
    };
}
