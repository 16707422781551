export const BUTTONS = {
    ARROW_LEFT: 'ArrowLeft',
    ARROW_UP: 'ArrowUp',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_DOWN: 'ArrowDown',
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    BACK_SPACE: 'Backspace',
    DELETE: 'Delete',
    META: 'Meta',
    ALT: 'Alt',
    CONTROL: 'Control',
    SPACE: 'Space'
}

export const ALLOWED_FILE_TYPES_OPTIONS = [
    { label: 'formField.fileTypes.all', accept: '' },
    { label: 'formField.fileTypes.images', accept: 'image/*' },
    { label: 'formField.fileTypes.docs', accept: '.doc,.docx,.docm,.docb,.pages,.txt,.rtf,.odt' },
    { label: 'formField.fileTypes.spreadsheets', accept: '.xls,.xlsx,.xlsm,.xlsb,.numbers,.csv,.ods' },
    { label: 'formField.fileTypes.pdf', accept: 'application/pdf' }
]

export const FILES_ACCEPT_OPTIONS = ALLOWED_FILE_TYPES_OPTIONS.map(({ label, accept }) => ({ label, value: accept }))

export const DEFAULT_FIELD_SETTINGS = {
    maxFiles: 10,
    minLength: 1,
    maxLength: 255,
    maxMultilineLength: 2000,
    precision: 2
}

export const CREATE_NEW_TABLE = 'CREATE_NEW_TABLE'
export const USE_EXISTING_TABLE = 'USE_EXISTING_TABLE'
export const PROVIDE_LIST_ITEMS = 'PROVIDE_LIST_ITEMS'

export const LIST_SOURCE_OPTIONS = [
    { label: 'label.dataForm.createNewTable', id: CREATE_NEW_TABLE, adminOnly: true },
    { label: 'label.dataForm.useExistingTable', id: USE_EXISTING_TABLE },
    { label: 'label.dataForm.provideList', id: PROVIDE_LIST_ITEMS, default: true }
]

export const ONE_TIME = 'NO'
export const DAY = 'DAY'
export const WEEK = 'WEEK'
export const MONTH = 'MONTH'
export const YEAR = 'YEAR'
export const DAYS_OF_WEEK = {
    MON: { id: 'MON', expression: 'MON' },
    TUE: { id: 'TUE', expression: 'TUE' },
    WED: { id: 'WED', expression: 'WED' },
    THU: { id: 'THU', expression: 'THU' },
    FRI: { id: 'FRI', expression: 'FRI' },
    SAT: { id: 'SAT', expression: 'SAT' },
    SUN: { id: 'SUN', expression: 'SUN' }
}
export const DAYS_OF_WEEK_NUMBERS = {
    FIRST: { id: 'FIRST', expression: '#1' },
    SECOND: { id: 'SECOND', expression: '#2' },
    THIRD: { id: 'THIRD', expression: '#3' },
    LAST: { id: 'LAST', expression: 'L' }
}

export const DAYS_OF_WEEK_OPTIONS = Object.keys(DAYS_OF_WEEK).map(id => ({ value: id, label: `daysOfWeek.${id}` }))
export const DAYS_OF_WEEK_NUMBERS_OPTIONS = Object.keys(DAYS_OF_WEEK_NUMBERS).map(id => ({
    value: id,
    label: `daysOfWeekNumbers.${id}`
}))

export const MONTH_TYPES = {
    DAY_OF_MONTH: 'DAY_OF_MONTH',
    DAY_OF_WEEK: 'DAY_OF_WEEK',
    LAST_DAY: 'LAST_DAY'
}

export const REPEAT_PERIODS = {
    [ONE_TIME]: {
        settings: {}
    },
    [DAY]: {
        settings: {
            interval: 1
        }
    },
    [WEEK]: {
        settings: {
            daysOfWeek: [DAYS_OF_WEEK.MON.id]
        }
    },
    [MONTH]: {
        settings: {
            interval: 1,
            type: MONTH_TYPES.DAY_OF_MONTH,
            dayOfMonth: 1,
            dayOfWeek: DAYS_OF_WEEK.MON.id,
            dayOfWeekNumber: DAYS_OF_WEEK_NUMBERS.FIRST.id,
        }
    },
    [YEAR]: {
        settings: {
            interval: 1
        }
    }
}

export const REPEAT_OPTIONS = Object.keys(REPEAT_PERIODS).map(id => ({ value: id, label: `schedule.label.repeats.${id}` }))

export const SELECT_BUTTONS = [
    BUTTONS.ARROW_UP,
    BUTTONS.ARROW_DOWN,
    BUTTONS.ENTER,
    BUTTONS.ESCAPE
]

export const constants = {
    TASK: {
        STATUS: {
            NOT_ASSIGNED: 2,
            ASSIGNED: 4,
            COMPLETED: 16,
            SKIPPED: 32,
            INACTIVE: 64,
            APPROVED: 128,
            REJECTED: 256,
            STOPPED_ON_REJECT: 512, // Cancelled, not assigned,
            INACTIVE_REVIEW: 1024,
            STOPPED_ON_CANCEL: 2048
        },
        TYPE: {
            SIMPLE: 'SIMPLE',
            APPROVAL: 'APPROVAL',
            REVIEW: 'REVIEW'
        }
    },
    GROUP: {
        STATUS: {
            ACTIVE: 2,
            COMPLETED: 16,
            SKIPPED: 32,
            INACTIVE: 64,
            CANCELLED: 128,
            INACTIVE_REVIEW: 1024
        }
    },
    PROCESS: {
        EXECUTION: {
            SEQUENTIAL: 'SEQUENTIAL',
            PARALLEL: 'PARALLEL'
        },
        NAME_MAX_LENGTH: 255,
        SPECIAL_NAME_VALUES: {
            PROCESS_STARTER: '<@processStarter>',
            START_DATE: '<@startDate>',
            START_DATE_TIME: '<@startDateTime>',
            START_MONTH: '<@startMonth>',
            START_YEAR: '<@startYear>',
            TEMPLATE_NAME: '<@templateName>'
        }
    },
    BUTTONS: BUTTONS,
    SELECT_BUTTONS,
    META_KEYS: [
        BUTTONS.ALT,
        BUTTONS.CONTROL,
        BUTTONS.META
    ],
    USER_LIMITS: {
        TASK: 5,
        PROCESS_STARTERS: 5,
        PROCESS_MANAGERS: 5,
        PROCESS_WATCHERS: 5,
        TEMPLATE_EDITORS: 5
    }
}

export function Constants () {
    'ngInject'

    return constants
}

export const HIDDEN = 'HIDDEN'
export const READ_ONLY = 'READ_ONLY'
export const EDITABLE = 'EDITABLE'
export const REQUIRED = 'REQUIRED'

export const DATE_SYSTEM_FORMAT = 'YYYY-MM-DD'
export const TIME_SYSTEM_FORMAT = 'HH:mm:ss'
export const DATE_TIME_SYSTEM_FORMAT = `${DATE_SYSTEM_FORMAT} ${TIME_SYSTEM_FORMAT}`
