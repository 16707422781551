export function mentionChecker($timeout) {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, element) => {
            let bottomFlag = false;

            let newCommentListener = () => {
                if (bottomFlag) {
                    [].slice.call(element.find('.mention')).forEach(e => e.classList.remove('top-align'));
                }
            };

            let mentionCheck = mEl => {
                let parentElSize = mEl.parentElement.clientWidth + mEl.parentElement.offsetLeft;
                if (parentElSize < mEl.clientWidth + mEl.offsetLeft + 50) {
                    let offsetRight = parentElSize - mEl.offsetLeft - mEl.clientWidth;
                    let child = mEl.children[0];
                    child.style.right = `-${offsetRight}px`;
                    child.style.left = 'auto';
                    let arrowEl = angular.element(child).find('.arrow')[0];
                    arrowEl.style.right = `${offsetRight + 12}px`;
                    arrowEl.style.left = 'auto';
                }
            };

            let topCheck = mEl => {
                if (mEl.offsetTop + element[0].offsetTop + 60 > element.parent()[0].scrollHeight) {
                    mEl.classList.add('top-align');
                    bottomFlag = true;
                } else {
                    mEl.classList.remove('top-align');
                }
            };
            $timeout(() => {
                [].slice.call(element.find('.mention')).forEach(e => {
                    mentionCheck(e);
                    topCheck(e);
                });
                if (bottomFlag) {
                    $scope.$on('comment:new', newCommentListener);
                }
            });
        }
    };
}
