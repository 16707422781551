import FormulaParserListener from './FormulaParserListener'

export default class FormulaValidator extends FormulaParserListener {

    constructor (resolve, reject) {
        super()
        this.resolve = resolve
        this.reject = reject
    }

    visitErrorNode (ctx) {
        this.reject && this.reject(ctx)
    }

    visitTerminal (ctx) {
        this.resolve && this.resolve()
    }
}
