// Generated from FormulaParser.g4 by ANTLR 4.9.2
// jshint ignore: start
import antlr4 from '../antlr4'

// This class defines a complete listener for a parse tree produced by FormulaParser.
export default class FormulaParserListener extends antlr4.tree.ParseTreeListener {

    // Enter a parse tree produced by FormulaParser#fieldExp.
    enterFieldExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#fieldExp.
    exitFieldExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#mulDivExp.
    enterMulDivExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#mulDivExp.
    exitMulDivExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#numericExp.
    enterNumericExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#numericExp.
    exitNumericExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#parenthesisExp.
    enterParenthesisExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#parenthesisExp.
    exitParenthesisExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#addSubExp.
    enterAddSubExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#addSubExp.
    exitAddSubExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#totalExp.
    enterTotalExp (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#totalExp.
    exitTotalExp (ctx) {
    }

    // Enter a parse tree produced by FormulaParser#resultExpression.
    enterResultExpression (ctx) {
    }

    // Exit a parse tree produced by FormulaParser#resultExpression.
    exitResultExpression (ctx) {
    }

}
