import { types } from 'mobx-state-tree'
import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import moment from 'moment-timezone'
import { getService, translate } from '../../react/utils'
import config from '../../../config'
import { User } from './user'
import { flattenFieldError } from './database-utils'

const { string, number, maybe } = types

const DataTypeValueBase = types
    .model(
        'DataTypeValueBase',
        {
            dataType: maybe(string),
            error: maybe(string),
            isSaving: false,
            isSaved: false,
            isInvalid: false,
            columnId: maybe(string)
        })
    .views(self => ({
        get isEmpty () {
            const key = Object.keys(self).filter(k => !['error', 'dataType', 'isSaving', 'isSaved', 'isUploading', 'isInvalid', 'columnId'].includes(k))[0]
            return self[key] === undefined || self[key] === '' || self[key].length === 0
        },
        get isValid () {
            return self.isEmpty || !self.error
        }
    }))
    .actions(self => ({
        toServerJSON () {
            const { dataType, error, isSaving, isSaved, isUploading, isInvalid, ...json } = self.toJSON()
            return json
        },
        validate () {},
        saving () {
            self.isSaved = false
            self.isSaving = true
        },
        saved (success = true) {
            self.isSaving = false
            self.isSaved = success
            if (success) {
                setTimeout(() => {
                    self.saved(false)
                }, 1000)
            }
        },
        setValidity (isValid = true) {
            self.isInvalid = !isValid
        },
        setError (fieldError) {
            self.error = fieldError ? flattenFieldError(fieldError) : undefined
        }
    }))

export const StringValue = types.compose(
    'SingleValue',
    DataTypeValueBase,
    types.model({
        stringValue: maybe(string)
    }).actions(self => ({
        update ({ stringValue = '' }) {
            self.stringValue = stringValue
        },
        validate (settings) {
            self.error = ''
            const minLength = settings.minLength.value
            const maxLength = settings.maxLength.value

            if (self.stringValue && self.stringValue.length && self.stringValue.length < minLength) {
                self.error = translate('validation.minLength', {value: minLength})
                return
            }

            if (self.stringValue && self.stringValue.length > maxLength) {
                self.error = translate('validation.maxLength', {value: maxLength})
            }
        }
    })).views(self => ({
        get displayValue () {
            return self.stringValue || ''
        }
    }))
)

export const EmailValue = types.compose(
    'EmailValue',
    DataTypeValueBase,
    types.model({
        emailValue: maybe(string)
    }).actions(self => ({
        update ({ emailValue = '' }) {
            self.emailValue = emailValue
        },
        validate () {
            self.error = ''
            if (!self.isEmpty && !self.emailValue.match(config.pattern_emails)) {
                self.error = translate('validation.email')
            }
        }
    })).views(self => ({
        get displayValue () {
            return self.emailValue || ''
        }
    }))
)

export const BaseListItem = types.model({
    id: maybe(string),
    item: string,
    isValid: types.optional(types.boolean, true)
})

export const SingleSelectValue = types.compose(
    'SingleSelectValue',
    DataTypeValueBase,
    types.model({
        radioButtonValue: maybe(BaseListItem)
    }).actions(self => ({
        update ({ radioButtonValue = {} }) {
            self.radioButtonValue = radioButtonValue && radioButtonValue.id ? BaseListItem.create({ ...radioButtonValue }) : undefined
        },
        toServerJSON () {
            return { radioButtonValue: self.radioButtonValue ? self.radioButtonValue.item : undefined }
        }
    })).views(self => ({
        get displayValue () {
            return self.radioButtonValue ? self.radioButtonValue.item : ''
        }
    }))
)

export const MultiSelectValue = types.compose(
    'MultiSelectValue',
    DataTypeValueBase,
    types.model({
        multiChoiceValue: types.optional(types.array(BaseListItem), [])
    }).actions(self => ({
        update ({ multiChoiceValue = [] }) {
            self.multiChoiceValue = multiChoiceValue.map(v => BaseListItem.create(v))
        },
        toServerJSON () {
            return { multiChoiceValue: self.multiChoiceValue.map(i => i.item) }
        },
        isChecked (str) {
            return self.multiChoiceValue.find(({ item }) => item === str)
        },
        generateNewItems ({ item, state, options }) {
            const newItems = []
            options.forEach(option => {
                let itemIsNotChanged = option !== item && self.isChecked(option)
                let itemIsAdded = option === item && state
                if (itemIsNotChanged || itemIsAdded) {
                    newItems.push(option)
                }
            })
            return newItems
        }
    })).views(self => ({
        get displayValue () {
            return self.multiChoiceValue.length ? self.multiChoiceValue.map(i => i.item).join(', ') : ''
        }
    }))
)

export const BooleanValue = types.compose(
    'BooleanValue',
    DataTypeValueBase,
    types.model({
        yesNoValue: maybe(string)
    }).actions(self => ({
        update ({ yesNoValue }) {
            self.yesNoValue = yesNoValue
        }
    })).views(self => ({
        get displayValue () {
            return self.yesNoValue
        }
    }))
)

const NumericValue = types.compose(
    'NumericValue',
    DataTypeValueBase,
    types.model({
        numericValue: maybe(number)
    })
).views(self => ({
        get displayValue () {
            return self.numericValue !== undefined ? self.numericValue.toString() : ''
        }
    })
).actions(self => ({
    update ({ numericValue }) {
        self.numericValue = numericValue !== null ? numericValue : undefined
    },
    validate ({ minValue, maxValue }) {
        self.error = ''

        if (minValue.value && self.numericValue < minValue.value) {
            self.error = translate('validation.minValue.extended', {value: minValue.value})
        }

        if (maxValue.value && self.numericValue > maxValue.value) {
            self.error = translate('validation.maxValue.extended', {value: maxValue.value})
        }
    }
}))

export const Currency = types.model(
    'Currency',
    {
        id: string,
        name: string,
        code: string,
        symbol: maybe(string),
        numberOfUnits: maybe(number),
        order: maybe(number)
    }
).views(self => ({
    get label () {
        return `${self.code} - ${self.name}`
    },
    get displayCode () {
        return self.symbol || self.code
    }
}))

const Money = types.model(
    'Money',
    {
        amount: number,
        currency: string,
        currencyInfo: maybe(Currency)
    }
).actions(self => ({
        update ({ amount, currency, currencyInfo = {} }) {
            self.amount = amount
            self.currency = currency || self.currency
            self.currencyInfo = currencyInfo || self.currencyInfo
        }
    })
).views(self => ({
        get displayValue () {
            return self.amount ? `${self.amount} ${self.currencyInfo.displayCode}` : ''
        }
    })
)

export const MoneyValue = types.compose(
    'MoneyValue',
    DataTypeValueBase,
    types.model({
        moneyValue: maybe(Money)
    }).actions(self => ({
        validate ({ minValue, maxValue, ...settings }) {
            self.error = ''

            if (minValue.value && self.moneyValue && self.moneyValue.amount < minValue.value) {
                self.error = translate('validation.minValue.extended', {value: minValue.value})
            }

            if (maxValue.value && self.moneyValue && self.moneyValue.amount > maxValue.value) {
                self.error = translate('validation.maxValue.extended', {value: maxValue.value})
            }
        },
        update ({ moneyValue }) {
            if (moneyValue === undefined) {
                self.moneyValue = undefined
            } else {
                if (!self.moneyValue) {
                    self.moneyValue = Money.create({ ...moneyValue })
                } else {
                    self.moneyValue.update({ ...moneyValue })
                }
            }
        }
    })).views(self => ({
        get displayValue () {
            return self.moneyValue ? self.moneyValue.displayValue : ''
        },
        get controlValue () {
            const amount = self.moneyValue ? self.moneyValue.amount.toString() : ''
            const currency = self.moneyValue ? self.moneyValue.currencyInfo : undefined

            return {
                amount,
                currency
            }
        }
    }))
)

export const FileValue = types.model({
    id: maybe(string),
    fileName: maybe(string),
    fileSize: maybe(number),
    contentType: maybe(string),
    url: maybe(string),
    previewUrl: maybe(string),
    hashToken: maybe(string),
    creationDate: maybe(number)
}).views(self => ({
    get name () {
        return self.fileName || self.id || ''
    }
})).actions(self => ({
    update (attrs) {
        Object.keys(attrs)
            .filter(key => ['id', 'fileName', 'fileSize', 'contentType', 'url', 'previewUrl', 'hashToken', 'creationDate'].includes(key))
            .forEach(key => {
                self[key] = attrs[key]
            })
    },
    afterCreate () {
        if (self.id && !self.fileName) {
            self.loadInfo()
        }
    },
    loadInfo (callback) {
        getService('ApiCalls').getFile(self.id).then(fileInfo => {
            self.update({ ...fileInfo })
            if (callback !== undefined) {
                callback()
            }
        })
    }
}))

function findFileInList (list, file) {
    return list.find(f => f.id === file.id)
}

export const FilesList = types.compose(
    'FilesList',
    DataTypeValueBase,
    types.model({
        files: types.optional(types.array(FileValue), []),
        isUploading: false
    }).actions(self => ({
        update ({ files = [] }, { tableId, columnId, recordId } = {}) {
            const ApiCalls = getService('ApiCalls')
            const filesToUpload = files.filter(f => !findFileInList(self.files, f))
            const filesToRemove = self.files.filter(f => !findFileInList(files, f))

            filesToRemove.forEach(({ id }) => {
                const originFiles = self.files.slice()
                self.files = self.files.filter(f => f.id !== id)
                if (recordId) {
                    self.saving()
                    ApiCalls.deleteDatabaseRecordFile(tableId, columnId, recordId, id).then(
                        () => self.saved(), // Success
                        ({ data }) => { // Error
                            if (data && data.displayError) {
                                self.setError(data.displayError)
                            }
                            self.saved(false)
                            self.update({ files: originFiles })
                        }
                    )
                }
            })

            if (filesToUpload.length) {
                const originFiles = self.files.slice()
                self.files = [...self.files, ...filesToUpload]
                if (recordId) {
                    self.saving()
                    ApiCalls.addDatabaseRecordFiles(tableId, columnId, recordId, {
                        value: {
                            files: filesToUpload.map(f => f.toJSON())
                        }
                    }).then(
                        () => self.saved(), // Success
                        ({ data }) => { // Error
                            if (data && data.displayError) {
                                self.setError(data.displayError)
                            }
                            self.saved(false)
                            self.update({ files: originFiles })
                        }
                    )
                }
            }
        },
        uploading (state) {
            self.isUploading = state
        }
    })).views(self => ({
        get displayValue () {
            return self.files.map(file => file.name).join(', ')
        }
    }))
)

export const DateOnlyValue = types.compose(
    'DateOnlyValue',
    DataTypeValueBase,
    types.model({
        dateValue: maybe(number)
    }).actions(self => ({
        update ({ dateValue }) {
            self.dateValue = dateValue
        }
    })).views(self => {
        const DateHelper = getService('DateHelper')
        return {
            get displayValue () {
                return self.dateValue ? moment.unix(self.dateValue).format(DateHelper.DATE_FORMATS().DATE_INPUT) : ''
            }
        }
    })
)

export const DateTimeValue = types.compose(
    'DateTimeValue',
    DataTypeValueBase,
    types.model({
        dateValue: maybe(number)
    }).actions(self => ({
        update ({ dateValue }) {
            self.dateValue = dateValue
        }
    })).views(self => {
        const tz = getService('DateHelper').getTZ()
        const DateHelper = getService('DateHelper')

        return {
            get displayValue () {
                return self.dateValue ? moment.unix(self.dateValue).utc().tz(tz).format(DateHelper.DATE_FORMATS().DATE_TIME_INPUT) : ''
            }
        }
    })
)

export const UserFieldValue = types.compose(
    'UserFieldValue',
    DataTypeValueBase,
    types.model({
        userValue: maybe(User)
    }).actions(self => {
        return {
            update ({ userValue }) {
                self.userValue = userValue ? { ...userValue } : undefined
            }
        }
    }).views(self => ({
        get displayValue () {
            return self.userValue ? self.userValue.fullName : ''
        }
    }))
)

export const DataTypedValue = types.union({
    dispatcher ({ dataType } = {}) {
        switch (dataType) {
            case STRING_SINGLE_LINE:
            case STRING_MULTI_LINE:
                return StringValue
            case EMAIL:
                return EmailValue
            case RADIO_SELECTOR:
                return SingleSelectValue
            case MULTI_SELECTOR:
                return MultiSelectValue
            case YES_NO:
                return BooleanValue
            case MONEY:
                return MoneyValue
            case FILES_LIST:
                return FilesList
            case DATE_ONLY_VALUE:
                return DateOnlyValue
            case DATE_AND_TIME:
                return DateTimeValue
            case NUMERIC_VALUE:
                return NumericValue
            case USER_FIELD:
                return UserFieldValue
            default:
                return types.undefined
        }
    }
})


