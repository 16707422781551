import utils, { localStorageHelper } from '../utils'

export function ResponseObserver($q, $rootScope, $location, ServerConfig, ServerDateConverter, TemplateCacheHelper) {
    'ngInject';
    return {
        request: config => {
            ServerDateConverter.convertDatesOnRemotePost(config.data);
            return config;
        },
        requestError: rejection => {
            return $q.reject(rejection);
        },

        response: response => {
            if (!response.data || angular.isObject(response.data)) {
                let serverVersion = response.headers('x-server-version');
                if (serverVersion) {
                    let storedBuildVersion = utils.localStorage.get('meta-build-version');
                    if (storedBuildVersion && serverVersion !== storedBuildVersion) {
                        Object.assign($rootScope.notification, {
                            toDisplay: true,
                            detailsUrl: null,
                            buildInfoUrl: ServerConfig.buildInfoUrl.replace('{version}', serverVersion.replace(/\./g, '-'))
                        });
                    }
                    utils.localStorage.set('meta-build-version', serverVersion);
                }
            }
            if (response.config.url) {
                TemplateCacheHelper.storeKey(response.config.url);
            }
            return response;
        },
        responseError: errorResponse => {
            const errorString = `Server error ${errorResponse.status}: `
                + (errorResponse.data ? errorResponse.data.displayError || errorResponse.data.error : errorResponse.data);
            console.log(errorString); //eslint-disable-line
            if ((errorResponse.status === 474 || errorResponse.status === 470) && $location.path() !== '/account-disabled') {
                window.location.href = '/account-disabled'; //eslint-disable-line
                return false;
            }
            if (errorResponse.status === 401 && $location.path() !== '/login' && $location.path() !== '/disabled/login') {
                localStorageHelper.set('returnAfterLoginUrl', $location.path())
                window.location.href = '/login'; //eslint-disable-line
                return false;
            }
            return $q.reject(errorResponse);
        }
    };
}
