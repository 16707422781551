import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import clsx from 'clsx'

import SelectDropdown from '../select-dropdown.jsx'
import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import moment from 'moment-timezone'
import DateInput from '../date-input.jsx'
import Checkbox from '../checkbox.jsx'
import { getService, translate } from '../../../modules/react/utils'
import { itemsAsSelectOptions } from '../../../services/data-types-service'
import DataFormGroup from './data-form-group.jsx'
import NumberInput from '../form/number-input.jsx'
import MoneyField from './money-field.jsx'
import FilesList from '../form/files-list.jsx'
import UserSelect from '../selectize/user-select.jsx'

class FormField extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.object,
        error: PropTypes.string,
        dataType: PropTypes.string,
        settings: PropTypes.object,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        autoFocus: PropTypes.bool
    }

    constructor (props) {
        super(props)

        this.tz = getService('DateHelper').getTZ()
        this.MomentHelper = getService('MomentHelper')
    }

    render () {
        const { label, error, required, value, disabled } = this.props

        return (
            <DataFormGroup
                label={label}
                error={error}
                required={required}
                isSaving={value.isSaving}
                isSaved={value.isSaved}
                isUploading={value.isUploading}
                controlClassName={clsx(this.getFieldControlClassName(), { disabled })}
            >
                {this.renderFieldControl()}
            </DataFormGroup>
        )
    }

    renderFieldControl () {
        const { dataType, value, settings, disabled, autoFocus } = this.props

        switch (dataType) {
            case STRING_SINGLE_LINE:
                return (
                    <input
                        type="text"
                        value={value.displayValue}
                        minLength={settings.minLength.value}
                        maxLength={settings.maxLength.value}
                        onChange={(e) => this.onChange(e.target.value)}
                        disabled={disabled}
                        autoFocus={autoFocus}
                    />
                )
            case STRING_MULTI_LINE:
                return (
                    <textarea
                        value={value.displayValue}
                        minLength={settings.minLength.value}
                        maxLength={settings.maxLength.value}
                        onChange={(e) => this.onChange(e.target.value)}
                        disabled={disabled}
                    />
                )
            case EMAIL:
                return (
                    <React.Fragment>
                        <i className="icon-toaster_mail"/>
                        <input
                            type="text"
                            value={value.displayValue}
                            onChange={(e) => this.onChange(e.target.value)}
                            disabled={disabled}
                        />
                    </React.Fragment>
                )
            case RADIO_SELECTOR:
                const selectorValue = value && value.radioButtonValue
                    ? { value: value.radioButtonValue.item, label: value.radioButtonValue.item }
                    : { value: null, label: '' }

                return (
                    <SelectDropdown
                        value={selectorValue}
                        options={itemsAsSelectOptions([{}, ...settings.allowedValues.value])}
                        onChange={(data) => this.onChange(data)}
                        disabled={disabled}
                    />
                )
            case MULTI_SELECTOR:
                return settings.allowedValues.asListOfValues.map(item => (
                    <Checkbox
                        key={item}
                        label={item}
                        className="field-value-checkbox"
                        checked={value && value.isChecked(item)}
                        onChange={(state) => this.onChange(value.generateNewItems({
                            item,
                            state,
                            options: settings.allowedValues.asListOfValues
                        }))}
                        disabled={disabled}
                    />
                ))
            case YES_NO:
                return (
                    <ul tabIndex="-1">
                        <li className={clsx('yes', { active: value.yesNoValue === 'yes' })}
                            onClick={!disabled ? () => this.onChange(value.yesNoValue === 'yes' ? undefined : 'yes') : undefined}>
                            <span className="helper"/>
                            {translate('label.yes')}
                        </li>
                        <li className={clsx('no', { active: value.yesNoValue === 'no' })}
                            onClick={!disabled ? () => this.onChange(value.yesNoValue === 'no' ? undefined : 'no') : undefined}>
                            <span className="helper"/>
                            {translate('label.no')}
                        </li>
                    </ul>
                )
            case DATE_AND_TIME:
                return (
                    <DateInput
                        formatDate={this.MomentHelper.formatInputDateTime}
                        parseDate={this.parseInputDate}
                        value={value.dateValue}
                        onDateChange={this.onChangeDateTime}
                        minValue={settings.minValue.value}
                        maxValue={settings.maxValue.value}
                        withIcon
                        withTimeInput
                        isClearable
                        disabled={disabled}
                    />
                )
            case DATE_ONLY_VALUE:
                return (
                    <DateInput
                        formatDate={this.MomentHelper.formatInputDate}
                        parseDate={this.parseInputDate}
                        value={value.dateValue}
                        onDateChange={this.onChangeDate}
                        minValue={settings.minValue.value}
                        maxValue={settings.maxValue.value}
                        withIcon
                        isClearable
                        disabled={disabled}
                    />
                )
            case NUMERIC_VALUE:
                return (
                    <NumberInput
                        value={value.displayValue}
                        precision={settings.precision.value}
                        unsigned={settings.isInteger.value}
                        min={settings.minValue.value}
                        max={settings.maxValue.value}
                        onChange={numericValue => this.onChange(numericValue)}
                        disabled={disabled}
                    />
                )
            case MONEY:
                return (
                    <MoneyField
                        value={value.controlValue}
                        settings={settings.controlSettings}
                        onChange={moneyValue => this.onChange(moneyValue)}
                        disabled={disabled}
                    />
                )
            case FILES_LIST:
                return (
                    <FilesList
                        value={value.files}
                        onChange={files => this.onChange(files)}
                        onStartUploading={() => value.uploading(true)}
                        onCompleteUploading={() => value.uploading(false)}
                        accept={settings.accept.value}
                        maxFiles={settings.maxFiles.value}
                        disabled={disabled}
                    />
                )
            case USER_FIELD:
                return (
                    <UserSelect
                        value={value && value.userValue ? value.userValue.asSelectOption : null}
                        options={settings.allowedUsers.map(u => u.asSelectOption)}
                        onChange={users => this.onChange(users[0])}
                        disabled={disabled}
                    />
                )
            default:
                return (
                    <input type="text" readOnly={true} defaultValue={value.displayValue}/>
                )
        }
    }

    getFieldControlClassName () {
        const { dataType } = this.props
        switch (dataType) {
            case EMAIL:
                return 'email-data-field email'
            case YES_NO:
                return 'yesno-data-field yes-no'
            default:
                return dataType.replace(/_/g, '-').toLowerCase()
        }
    }

    onChangeDate = date => {
        if (date) {
            return this.onChange(this.MomentHelper.dateToUnix(date))
        }
        return this.onChange(undefined)
    }

    onChangeDateTime = datetime => {
        if (datetime) {
            return this.onChange(this.MomentHelper.dateTimeToUnix(datetime))
        }
        return this.onChange(undefined)
    }

    onChange = value => {
        const { dataType, onChange } = this.props

        switch (dataType) {
            case EMAIL:
                return onChange({
                    emailValue: value
                })
            case RADIO_SELECTOR:
                return onChange({
                    radioButtonValue: {
                        id: value.value,
                        item: value.label
                    }
                })
            case MULTI_SELECTOR:
                return onChange({
                    multiChoiceValue: value.map(item => ({ item }))
                })
            case DATE_ONLY_VALUE:
            case DATE_AND_TIME:
                return onChange({
                    dateValue: value
                })
            case YES_NO:
                return onChange({
                    yesNoValue: value
                })
            case NUMERIC_VALUE:
                return onChange({
                    numericValue: value
                })
            case MONEY:
                return onChange({
                    moneyValue: value ? {
                        amount: value.amount,
                        currency: value.currency.id,
                        currencyInfo: value.currency
                    } : undefined
                })
            case FILES_LIST:
                return onChange({
                    files: value
                })
            case STRING_SINGLE_LINE:
            case STRING_MULTI_LINE:
                return onChange({
                    stringValue: value
                })
            case USER_FIELD:
                return onChange({ userValue: value || null })
            default:
        }
    }

    parseInputDate = (string, format) => {
        const m = moment(string, format)
        if (m.isValid()) {
            return m.toDate()
        }
        return undefined
    }
}

export default observer(FormField)
