import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import FormGroup from '../form/form-group.jsx'

import './data-form-group.less'

function DataFormGroup ({ error, ...props }) {
    return (
        <React.Fragment>
            <FormGroup
                className={cn('data-form-group')}
                error={error}
                {...props}
            />
        </React.Fragment>
    )
}

DataFormGroup.propTypes = {
    error: PropTypes.string
}

export default DataFormGroup
