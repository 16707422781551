/* global document */
import {ProcessesListBaseController} from './processes-list-base.controller';
import utils from '../utils';

const AMOUNT_OF_RECENTLY_COMPLETED_ITEMS = 3;

export class MyProcessesController extends ProcessesListBaseController {
    constructor($scope, $state, $auth, $location, $timeout, ProcessesSettings, ServerConfig, PageSettings, ApiCalls,
                DeferredAction, $log, PusherHelper, currentUser) {
        'ngInject';
        super($scope, $state, $auth, $location, $timeout, ProcessesSettings, ServerConfig, PageSettings, ApiCalls,
                DeferredAction, $log, PusherHelper, currentUser);

        this.container = document.getElementsByClassName('processes__main__content')[0];
        this.recentlyCompletedTree = [];

        this.api.active = ApiCalls.getMyProcesses;
        this.api.completed = ApiCalls.getMyCompletedProcesses;

        $scope.$on(DeferredAction.TaskDelete.CONST.CANCELED, () => {
            this.firstPage();
        });

        $scope.$on('grouping:changed', () => {
            this.firstPage();
        });

        $scope.$watch(() => this.ProcessesSettings.activeFilterMode, () => {
            this.firstPage(true);
        }, true);

        $scope.$on('searchProcesses:start', () => {
            this.firstPage(true);
        });

        $scope.$on('processList:changed', () => {
            this.loadRecentlyCompletedProcesses();
        });

        this.ProcessesSettings.setDefaultAll();

        if ($auth.isAuthenticated()) {
            $scope.payload = $auth.getPayload();
        }

        this.firstPage(true);
    }

    loadRecentlyCompletedProcesses() {
        if (this.getActiveSectionId() === 'active' && this.ProcessesSettings.processesGroup === 0) {
            if (this.recentlyCompletedFetching) {
                return;
            }

            let params = this.getApiParams();
            params.count = AMOUNT_OF_RECENTLY_COMPLETED_ITEMS;

            this.recentlyCompletedFetching = true;
            this.api.completed(params).then(data => {
                this.displayLinkToCompleteSection = data.nextOffset !== undefined;
                if (data.list && data.list.length) {
                    let recentlyCompletedList = utils.getProcessesList(data);
                    this.recentlyCompletedTree = [{processes: this.prepareAllCounters(recentlyCompletedList)}];
                    this.triggerListChange();
                } else {
                    this.recentlyCompletedTree = [];
                }

                this.serverError = false;
            }, () => {
                this.serverError = true;
            }).finally(() => {
                this.$timeout(() => this.recentlyCompletedFetching = false);
            });
        } else {
            this.recentlyCompletedTree = [];
            this.displayLinkToCompleteSection = false;
        }
    }

    viewCompleted() {
        this.ProcessesSettings.activeFilterMode = this.ProcessesSettings.filterModes.find(f => f.id === 'completed');
        this.processesTree = [];
        this.recentlyCompletedTree = [];
    }

    firstPage(showListStub = false) {
        this.loadRecentlyCompletedProcesses();
        super.firstPage(showListStub);
    }
}
