import TemplateScheduleDialog from '../react/schedule/template-schedule-dialog'

export function templateScheduleDialog(reactDirective) {
    'ngInject';

    const mapProps = [
        'template',
        'onChange',
        'onClose'
    ]

    return reactDirective(TemplateScheduleDialog, mapProps, {}, {});
}
