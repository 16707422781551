import { DATA_TYPES, YES_NO, RADIO_SELECTOR } from './data-types'

export function getOptionLabel (dataTypeModel, key) {
    return dataTypeModel.options[key].label
}

export function getDefaultOptionValue (dataTypeModel, key) {
    return `${dataTypeModel.options[key].value} (default)`
}

export function asOptionalNumber (value) {
    return value ? Number(value) : ''
}

export function asKeyValues (options) {
    let opts = {}
    Object.keys(options).forEach(key => {
        opts[key] = options[key].value
    })
    return opts
}

export function itemAsSelectOption ({ item } = {}) {
    return item
        ? {
            value: item,
            label: item
        }
        : {
            label: 'label.emptyValue',
            value: null
        }
}

export function itemsAsSelectOptions (items) {
    return items.filter(({ item }, index) => item || index === 0).map(itemAsSelectOption)
}

export function selectOptionAsItem ({ value }) {
    return { item: value }
}

export function defaultValueAsSelectOption (defaultValue) {
    return defaultValue ? { value: defaultValue, label: defaultValue } : { value: null, label: '' }
}

export function getDataTypeSettings (dataType, savedSettings = {}) {
    const settings = {}
    const defaultSettings = DATA_TYPES[dataType].settings ? DATA_TYPES[dataType].settings : {}

    Object.keys(defaultSettings).forEach(key => {
        // set default settings
        switch (key) {
            case 'allowedUsers':
                settings[key] = []
                break
            case 'allUsers':
                settings[key] = defaultSettings[key]
                break
            default:
                settings[key] = { ...defaultSettings[key] }
        }

        // set saved settings
        if (savedSettings && savedSettings[key] !== undefined) {
            switch (key) {
                case 'allowedUsers':
                    settings.allowedUsers = savedSettings.allowedUsers
                    break
                case 'allUsers':
                    break
                case 'defaultValue':
                    settings[key].value = savedSettings[key][dataType === RADIO_SELECTOR ? 'radioButtonValue' : 'yesNoValue']
                    break
                case 'currency':
                    Object.keys(savedSettings[key]).forEach(k2 => {
                        settings[key][k2] = { ...defaultSettings[key][k2], value: savedSettings[key][k2] }
                    })
                    break
                case 'precision':
                    if (savedSettings.precision === 0) {
                        settings.isInteger.value = true
                    } else {
                        settings.precision.value = savedSettings.precision
                    }
                    break
                default:
                    settings[key].value = savedSettings[key]
            }
        }
    })

    return { ...settings, dataType }
}

export function currencyAsSelectOption (currency) {
    return currency !== undefined ? { value: currency.id, label: currency.label } : undefined
}

export function groupAsSelectOption (group) {
    return group !== undefined ? { value: group.id, label: group.name } : undefined
}

export function getPreparedDefaultValue ({ dataType, defaultValue }) {
    const { value } = defaultValue
    switch (dataType) {
        case RADIO_SELECTOR:
            return { radioButtonValue: value ? { item: value } : undefined }
        case YES_NO:
            return { yesNoValue: value }
    }
}

export function entityAsSelectOption ({ id, name, ...attrs }) {
    return {
        value: id,
        label: name,
        ...attrs
    }
}
