import utils from '../../processes/utils'

export class TemplatesController {
    constructor($scope, $translate, $state, $timeout, $location, ApiCalls, PageSettings) {
        'ngInject';
        this.PageSettings = PageSettings;
        this.ApiCalls = ApiCalls;
        this.$timeout = $timeout;
        this.$scope = $scope;

        this.busyLoading = false;
        this.nextOffsetId = 0;
        this.listStub = Array.from({length: this.PageSettings.itemsPerPage}, (v, k) => ({id: k + 1}));

        this.startProcess = (template, event) => {
            if (event) {
                event.stopPropagation();
            }
            if (!this.checkPermission(template, 'start')) {
                return;
            }
            PageSettings.startProcess(template);
        };

        this.copyTemplate = (template, event) => {
            if (event) {
                event.stopPropagation()
            }
            PageSettings.copyTemplate(template).result.then(data => {
                if (data) {
                    this.templates.push(this.prepareTemplate(data))
                    PageSettings.toasterData = {
                        iconClass: 'icon-template_duplicate_roundless',
                        text: $translate.instant('copyTemplate.toaster.text'),
                        linkText: $translate.instant('copyTemplate.toaster.linkText'),
                        route: {
                            name: 'main.template',
                            data: { id: data.id }
                        }
                    }
                }
            })
        }

        this.deleteTemplate = (template, event) => {
            if (event) {
                event.stopPropagation();
            }
            let modalInstance = PageSettings.deleteConfirmation('template');
            modalInstance.result.then(() => {
                const deleteCompleteAction = () => {
                    this.templates = this.templates.filter(t => t.id !== template.id);
                    this.triggerListChange();
                };

                ApiCalls.deleteTemplate(template.id, template.version)
                    .then(deleteCompleteAction)
                    .catch(res => PageSettings.errorHandlerModal(res, deleteCompleteAction));
            });
        };

        this.goToTemplate = template => {
            if (!template.name || !this.checkPermission(template, 'view')) {
                return;
            }
            return this.checkScreenSize(() => {
                return $state.go('main.template', {id: template.id, url: $location.path()});
            });
        };

        this.goToEdit = (template, event) => {
            if (event) {
                event.stopPropagation();
            }
            return this.checkScreenSize(() => {
                return $state.go('main.templateEdit', {id: template.id, url: $location.path()});
            });
        };

        this.checkScreenSize = (callback) => {
            if (PageSettings.smallScreensMode()) {
                let modalScope = $scope.$new();
                modalScope.title = $translate.instant('desktopIsNeeded.title');
                modalScope.text = $translate.instant('desktopIsNeeded.text');
                return PageSettings.openAlertModal(modalScope).finally(() => modalScope.$destroy());
            } else {
                return callback();
            }
        };

        this.checkPermission = (tmpl, permission) => {
            return tmpl.permissions ? tmpl.permissions.indexOf(permission) !== -1 : null;
        };

        $scope.$on('templates:refresh', () => {
            this.firstPage();
        });

        $scope.$on('content:search', (event, searchString) => {
            this.searchString = searchString;
            this.firstPage();
        });

        this.firstPage();
    }

    triggerListChange() {
        this.$timeout(() => this.$scope.$emit(`list:changed`), 100);
    }

    getApiParams() {
        const params = {
            count: this.PageSettings.itemsPerPage
        };

        if (this.searchString) {
            params.searchString = this.searchString;
        }

        if (this.nextOffsetId && this.nextOffsetId !== -1) {
            params.nextOffset = this.nextOffsetId;
        }

        return params;
    }

    prepareTemplate(tpl) {
        tpl.intervalText = angular.toJson({COUNT: tpl.dueDateInterval ? tpl.dueDateInterval.interval : 0});
        tpl.tasksCountText = angular.toJson({COUNT: tpl.tasksCount || 0});
        tpl.processRunningText = angular.toJson({COUNT: tpl.processesRunningCount || 0});
        return tpl;
    }

    prepareTemplates(list) {
        return list.map(item => this.prepareTemplate(item));
    }

    getTemplates() {
        return this.nextOffsetId === 0 && !this.templates.length ? this.listStub : this.templates;
    }

    nextPage() {
        if (this.nextOffsetId === -1 || this.busyLoading) {
            return;
        }

        const params = this.getApiParams();

        this.busyLoading = true;
        this.ApiCalls.getTemplates(params)
            .then(data => {
                this.nextOffsetId = data.nextOffset || -1;
                if (data.list && data.list.length) {
                    utils.mergeToArray(this.templates, this.prepareTemplates(data.list), true);
                    this.triggerListChange();
                    this.serverError = false;
                }
            }, () => this.serverError = true)
            .finally(() => {
                this.$timeout(() => this.busyLoading = false);
            });
    }

    toggleDropdownFlag(template, state = false) {
        this.$timeout(() => template.dropdownIsOpened = state);
    }

    firstPage() {
        this.templates = [];
        this.nextOffsetId = 0;
        this.nextPage();
    }

    emptyStateVisible() {
        return !this.serverError && !this.getTemplates().length && !this.searchString;
    }

    notFoundStateVisible() {
        return !this.serverError && !this.getTemplates().length && this.searchString;
    }
}
