import config from '../../../config';
import colors from '../../../libs/colors';
import {validateAttachedFiles} from '../../../utils';

const unhighlightDelay = 700;
export function processComments(ApiCalls, MomentHelper, Upload, currentUser, $timeout, $translate, DeferredAction,
                                PageSettings, ProcessesSettings, Constants) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            process: '=',
            tree: '=',
            allUsers: '=',
            onUpdate: '&'
        },
        template: require('./process-comments.html'),
        controller: ($scope, ServerConfig, PrintErrors, PusherHelper, $window, $state, $location) => {
            'ngInject';
            const store = {
                taskWithCommentsById: {}
            };

            const commentsForNotDeletedTasks = (item) => item.task && !DeferredAction.TaskDelete.isDeferred(item.task);
            const groupCommentsByTask = (tasksStore, comment) => {
                let {task} = comment;
                let comments = tasksStore[task.id] ? tasksStore[task.id].comments : [];
                comments.push(comment);
                comments.sort((a, b) => a.creationDate - b.creationDate);
                tasksStore[task.id] =  {
                    task,
                    comments
                };

                return tasksStore;
            };
            const prepareTasksWithComments = (comments) => {
                store.taskWithCommentsById = comments.filter(commentsForNotDeletedTasks).reduce(groupCommentsByTask, {});
            };
            const getTaskWithCommentsAsList = () => {
                return Object.keys(store.taskWithCommentsById).map(taskId => store.taskWithCommentsById[taskId]);
            };
            const updateTaskFlagsAndComments = (item) => {
                let {task, comments} = item;
                if (task.commentsCount <= 2) {
                    task.isFull = true;
                }
                if (!task.isExpanded) {
                    comments = comments.slice(Math.max(comments.length - 2, 0));
                }
                return {task, comments};
            };
            const itemsWithComments = (item) => item.comments.length > 0;
            const updateCommentsTree = () => {
                $scope.commentsTree = getTaskWithCommentsAsList().map(updateTaskFlagsAndComments).filter(itemsWithComments);
            };
            const fetchProcessCommentsAndFiles = () => {
                ApiCalls.getProcessComments($scope.process.id).then(data => {
                    prepareTasksWithComments(data.list);
                    updateCommentsTree();
                });
                ApiCalls.getProcessAttachments($scope.process.id).then(data => {
                    $scope.attachments = data.list || [];
                });
            };
            const fetchProcessHistory = () => {
                ApiCalls.getProcessHistory($scope.process.id)
                    .then(data => {
                        $scope.history = data.list || [];
                    })
                    .catch(PageSettings.errorHandlerModal);
            };

            $scope.printErrors = PrintErrors.printFilesErrors;
            $scope.getMaxSize = () => ServerConfig.fileMaxSize;
            $scope.getMatchesCount = (matches) => {
                return angular.toJson({COUNT: matches ? matches.length : 0});
            };

            $scope.activeMode = 'Comments';
            $scope.attachments = [];
            $scope.comments = [];
            $scope.commentsTree = [];

            $scope.getProcessCommentsCount = () => {
                return getTaskWithCommentsAsList().reduce((prev, el) => prev + el.task.commentsCount, 0) || 0;
            };
            $scope.getProcessComments = () => {
                let count = $scope.getProcessCommentsCount();
                return angular.toJson({COUNT: count});
            };
            $scope.getProcessAttachmentsCount = () => {
                return getTaskWithCommentsAsList().reduce((prev, el) => prev + el.task.attachmentsCount, 0) || 0;
            };
            $scope.getProcessAttachments = () => {
                let count = $scope.getProcessAttachmentsCount();
                return angular.toJson({COUNT: count});
            };
            $scope.getTextCommentsCount = (task) => {
                return angular.toJson({COUNT: task.commentsCount || 0});
            };
            $scope.checkPermission = (obj, permission) => {
                return obj.permissions ? obj.permissions.indexOf(permission) !== -1 : null;
            };
            $scope.switchHistory = () => {
                $scope.activeMode = 'History';
                fetchProcessHistory();
            };
            $scope.switchAttachments = () => {
                $scope.activeMode = 'Attachments';
            };
            $scope.switchComments = () => {
                $scope.activeMode = 'Comments';
            };

            $scope.toggleComments = task => {
                if (task.isExpanded) {
                    task.isExpanded = false;
                    updateCommentsTree();
                } else if (task.isFull) {
                    task.isExpanded = true;
                    updateCommentsTree();
                } else {
                    ApiCalls.getTaskComments(task.id)
                        .then(data => {
                            let changedStoreItem = store.taskWithCommentsById[task.id];
                            changedStoreItem.task.isFull = true;
                            changedStoreItem.task.isExpanded = true;
                            changedStoreItem.comments = data.list.map(comment => Object.assign({task: task}, comment));
                            changedStoreItem.comments.sort((a, b) => a.creationDate - b.creationDate);
                            updateCommentsTree();
                        })
                        .catch(PageSettings.errorHandlerModal);
                }
            };

            // Displaying errors
            $scope.getErrUploads = () => {
                return {fileNames: $scope.notUploaded.join(', ')};
            };

            $scope.taskView = (task) => {
                $state.go('main.task', {id: task.id, url: $location.path(), tasks: $scope.process.subTree.filter(item => item.task).map(item => item.task)});
            };

            // Confirmations
            let checkUploads = () => {
                return $scope.commentsTree.some(({comments}) => {
                    return comments.some(({files = []}) => {
                        return files.some(file => !angular.isArray(file.result));
                    });
                });
            };

            let checkUnsavedComment = (comment) => {
                const changedText = comment.newCommentText && comment.newCommentText !== $scope.getDefaultCommentText(comment);
                const hasFiles = comment.files && comment.files.length;
                return changedText || hasFiles;
            };

            let findUnsavedComments = () => {
                return $scope.commentsTree.reduce((acc, {comments}) => {
                    return [...acc, ...comments.filter(checkUnsavedComment)];
                }, []);
            };

            let checkUnsavedComments = () => {
                return $scope.commentsTree.some(({comments}) => {
                    return comments.some(checkUnsavedComment);
                });
            };

            $scope.focusOnUnsavedComment = (comment) => {
                $timeout(() => {
                    let commentEl = document.getElementById(`reply-to-${comment.id}`); //eslint-disable-line
                    if (commentEl !== document.activeElement) { //eslint-disable-line
                        commentEl.focus();
                        commentEl.selectionStart = commentEl.value.length;
                        commentEl.selectionEnd = commentEl.value.length;
                    }
                }, 100);
            };

            let customExit = (event, nextState, nextStateParams) => {
                if (nextState.name === 'login') {
                    return;
                }
                if (checkUploads()) {
                    event.preventDefault();
                    ProcessesSettings.callTaskViewExitModal({
                        title: 'uploadingFiles.text.leaveThisPage',
                        text: 'uploadingFiles.text.unsavedChanges'
                    });
                } else if (checkUnsavedComments()) {
                    event.preventDefault();
                    let modalInstance = ProcessesSettings.callExitModal({
                        title: 'comments.text.leaveThisPage',
                        text: 'comments.text.unsavedChanges',
                        confirmText: 'comments.label.continueEditing',
                        cancelText: 'comments.label.discardChanges'
                    });
                    modalInstance.result.then(() => {
                        $scope.commentsTree.forEach(({comments}) => {
                            comments.forEach((comment) => {
                                comment.newCommentText = $scope.getDefaultCommentText(comment);
                                comment.files = [];
                            });
                        });
                        $state.go(nextState.name, nextStateParams);
                    }).catch(() => {
                        let unsavedComments = findUnsavedComments();
                        if (unsavedComments[0]) {
                            $scope.focusOnUnsavedComment(unsavedComments[0]);
                        }
                    });
                }
            };

            let defaultExit = () => {
                if (checkUnsavedComments() || checkUploads()) {
                    $window.event.returnValue = $translate.instant('comments.text.unsavedChanges');
                }
            };

            let addComment = comment => {
                if (comment.attachmentsCount) {
                    comment.attachments.forEach(a => {
                        a.isNew = true;
                        a.linkId = comment.id;
                        a.creator = angular.copy(comment.creator);
                        $scope.attachments.unshift(a);
                        $timeout(() => {
                            delete a.isNew;
                        }, unhighlightDelay);
                    });

                    if ($scope.activeMode === 'Attachments') {
                        $timeout(() => {
                            $('.attachments > main').scrollTop(0); //eslint-disable-line
                        }, 100);
                    }
                }
                comment.isNew = true;
                let changedStoreItem = store.taskWithCommentsById[comment.task.id];
                if (changedStoreItem.comments.find(c => c.id === comment.id)) {
                    changedStoreItem.comments = changedStoreItem.comments.filter(c => c.id !== comment.id);
                }
                changedStoreItem.comments.push(comment);
                changedStoreItem.comments.sort((a, b) => a.creationDate - b.creationDate);
                updateCommentsTree();
                $scope.onUpdate();
                $timeout(() => {
                    delete comment.isNew;
                }, unhighlightDelay);
            };

            let commentAddListener = ({task, comment, missingData}) => {
                if (!task || !comment || task.processId !== $scope.process.id || task.status === Constants.TASK.STATUS.INACTIVE_REVIEW) {
                    return;
                }

                if (!store.taskWithCommentsById[task.id]) {
                    store.taskWithCommentsById[task.id] = {
                        task,
                        comments: [comment]
                    };
                } else {
                    let changedStoreItem = store.taskWithCommentsById[task.id];
                    changedStoreItem.task = Object.assign(changedStoreItem.task, task);
                }

                updateCommentsTree();

                if (missingData) {
                    ApiCalls.getTaskComment(task.id, comment.id).then(c => {
                        addComment(Object.assign({task}, c));
                    });
                } else {
                    addComment(Object.assign({task}, comment));
                }
            };

            let commentDelListener = (data) => {
                let {task, comment} = data;
                if (!task || !comment || !store.taskWithCommentsById[task.id]) {
                    return;
                }

                let changedStoreItem = store.taskWithCommentsById[task.id];
                changedStoreItem.task = Object.assign(changedStoreItem.task, task);

                let processSubTreeItem = $scope.process.subTree.find(t => t.task && t.task.id === task.id);
                if (processSubTreeItem) {
                    processSubTreeItem.task.commentsCount = task.commentsCount;
                    processSubTreeItem.task.attachmentsCount = task.attachmentsCount;
                    processSubTreeItem.task.version = task.version;
                }

                if ($scope.attachments) {
                    $scope.attachments = $scope.attachments.filter(a => a.linkId !== comment.id);
                }
                $scope.onUpdate();
                changedStoreItem.comments = changedStoreItem.comments.filter(c => c.id !== comment.id);
                if (!task.isExpanded && task.commentsCount > 1) {
                    ApiCalls.getTaskComments(task.id)
                        .then(result => {
                            changedStoreItem.comments = result.list.map(c => Object.assign({task: task}, c));
                            changedStoreItem.comments.sort((a, b) => a.creationDate - b.creationDate);
                            updateCommentsTree();
                        })
                        .catch(PageSettings.errorHandlerModal);
                } else {
                    updateCommentsTree();
                }
            };

            let updateUserOrGroup = (data) => {
                let updateLocUser = (u, origin) => Object.assign(u, origin, {avatarUrl: origin.avatarUrl || null});
                if (data.user) {
                    getTaskWithCommentsAsList().forEach(item => {
                        item.comments.forEach(comment => {
                            if (comment.creator && comment.creator.id === data.user.id) {
                                updateLocUser(comment.creator, data.user);
                            }
                            if (comment.mentions) {
                                let locUser = comment.mentions.find(m => m.id === data.user.id);
                                if (locUser) {
                                    updateLocUser(locUser, data.user);
                                }
                            }
                        });
                    });
                    updateCommentsTree();
                    let user = $scope.allUsers.find(u => u.id === data.user.id);
                    if (user) {
                        updateLocUser(user, data.user);
                    }
                    if ($scope.attachments) {
                        $scope.attachments.forEach(a => {
                            if (a.creator && a.creator.id === data.user.id) {
                                updateLocUser(a.creator, data.user);
                            }
                        });
                    }
                    if ($scope.process.subTree) {
                        $scope.process.subTree.forEach(item => {
                            let {task} = item;
                            if (task && task.completedBy && task.completedBy.id === data.user.id) {
                                updateLocUser(task.completedBy, data.user);
                            }
                            if (task && task.assignee && task.assignee.id === data.user.id) {
                                updateLocUser(task.assignee, data.user);
                            }
                        });
                        $scope.onUpdate();
                    }
                }
            };

            // Initialize
            if ($scope.process) {
                if ($scope.checkPermission($scope.process, 'viewComments')) {
                    fetchProcessCommentsAndFiles();
                } else if ($scope.checkPermission($scope.process, 'viewHistory')) {
                    $scope.switchHistory();
                }
            }

            let destroyCallback = $scope.$on('$stateChangeStart', customExit);
            $window.addEventListener('beforeunload', defaultExit);
            $scope.$on('process-comments:update-tree', () => updateCommentsTree());
            $scope.$on(DeferredAction.TaskDelete.CONST.CANCELED, () => updateCommentsTree());

            const processUpdatedListener = ({processId, historyChanged, socketId, isDeleted}) => {
                if (processId !== $scope.process.id || isDeleted) {
                    return;
                }

                if (!PusherHelper.checkIfEventShouldBeSkipped(socketId) && $scope.checkPermission($scope.process, 'viewComments')) {
                    fetchProcessCommentsAndFiles();
                }

                if (historyChanged && $scope.checkPermission($scope.process, 'viewHistory')) {
                    fetchProcessHistory();
                }
            };

            PusherHelper.subscribe('commentAdded', commentAddListener);
            PusherHelper.subscribe('commentDeleted', commentDelListener);
            PusherHelper.subscribe('userOrGroupUpdated', updateUserOrGroup);
            PusherHelper.subscribe('processUpdated', processUpdatedListener);

            $scope.$on('$destroy', () => {
                destroyCallback();
                $window.removeEventListener('beforeunload', defaultExit);
                PusherHelper.unsubscribe('commentAdded', commentAddListener);
                PusherHelper.unsubscribe('commentDeleted', commentDelListener);
                PusherHelper.unsubscribe('userOrGroupUpdated', updateUserOrGroup);
                PusherHelper.unsubscribe('processUpdated', processUpdatedListener);
            });

            $scope.getDefaultCommentText = comment => {
                return comment.creator.id !== currentUser.id ? `<@${comment.creator.id}> ` : '';
            };
        },

        link: (scope) => {
            scope.MomentHelper = MomentHelper;
            scope.getColor = (index) => colors[index - 1];
            scope.checkField = (form, fieldName) => {
                if (!form || !form[fieldName]) {
                    return;
                }
                return (form[fieldName].$invalid && form[fieldName].$dirty) || (form[fieldName].$invalid);
            };

            let closeTimeoutId, toSkip;
            scope.skipClose = () => {
                if (closeTimeoutId) {
                    $timeout.cancel(closeTimeoutId);
                }
            };
            scope.skipWithDelay = () => {
                toSkip = true;
            };
            let checkIfCommentIsUnsaved = comment => {
                const {newCommentText = '', files = []} = comment;
                return (newCommentText && newCommentText !== scope.getDefaultCommentText(comment)) || files.length;
            };
            scope.activate = (comment, skipPreloadText) => {
                if (closeTimeoutId) {
                    $timeout.cancel(closeTimeoutId);
                }
                if (!skipPreloadText && comment.newCommentText == null) {
                    comment.newCommentText = scope.getDefaultCommentText(comment);
                }
                scope.commentsTree.forEach(({comments}) => {
                    comments.filter(c => !checkIfCommentIsUnsaved(c)).forEach(c => c.isReply = false);
                });
                comment.isReply = true;

                scope.focusOnUnsavedComment(comment);
            };

            let attachedFiles = [];
            let closeFn = (comment, isSave) => {
                if (!isSave && checkIfCommentIsUnsaved(comment)) {
                    return;
                }
                comment.isReply = false;
                scope.serverError = null;
                if (isSave) {
                    attachedFiles = [];
                    comment.files = [];
                    comment.errFiles = [];
                    comment.newCommentText = '';
                }
            };
            scope.closeCommentForm = (comment, isSave) => {
                comment.isReplyActive = false;
                if (toSkip) {
                    toSkip = false;
                    return;
                }
                if (isSave) {
                    closeFn(comment, isSave);
                } else {
                    closeTimeoutId = $timeout(() => closeFn(comment, isSave), 200);
                }
            };
            scope.activateCommentForm = comment => {
                comment.isReplyActive = true;
            };

            let addComment = (comment) => {
                if (scope.inProcess) {
                    return;
                }
                scope.notUploaded = [];
                scope.inProcess = true;
                let allValid = true;

                let newAttachments = comment.files ? comment.files.map(file => {
                    if (angular.isArray(file.result) && file.result[0]) {
                        return file.result[0];
                    } else {
                        scope.notUploaded.push(file.name);
                        allValid = false;
                    }
                }) : [];
                if (!allValid || (!newAttachments.length && !comment.newCommentText)) {
                    scope.inProcess = false;
                    return;
                }

                ApiCalls.addComment(comment.task.id, {text: comment.newCommentText, attachments: newAttachments})
                    .then(data => {
                        scope.serverError = !data.success ? $translate.instant('comment.add.failure') : null;
                        scope.closeCommentForm(comment, true);
                    })
                    .catch((errorResponse) => {
                        PageSettings.errorHandlerModal(errorResponse, undefined, () => {
                            scope.closeCommentForm(comment, true);
                        });
                    })
                    .finally(() => scope.inProcess = false);
            };

            scope.keypress = (comment, event) => {
                if (!event) {
                    addComment(comment);
                } else if (event.key === Constants.BUTTONS.ENTER && !event.shiftKey) {
                    event.preventDefault();
                    addComment(comment);
                }
            };

            let checkUpload = files => {
                if (!files || !files.length) {
                    return false;
                }
                return files.find(f => attachedFiles.indexOf(f.name) === -1);
            };

            // Attachments actions
            scope.uploadFiles = (files, errFiles, comment) => {
                const validatedFiles = validateAttachedFiles(files, errFiles, config);

                comment.files = validatedFiles[0];
                comment.errFiles = validatedFiles[1];

                if (!checkUpload(comment.files)) {
                    return;
                }

                // Upload files directly to Amazon (@param: files policies)
                let uploadFunc = allPolicies => {
                    comment.files.forEach((file, fileIndex) => {
                        if (attachedFiles.indexOf(file.name) !== -1) {
                            return;
                        }

                        let filePolicy = allPolicies[fileIndex];
                        file.upload = Upload.upload({
                            url: `https://${filePolicy.bucket}.s3.amazonaws.com/`,
                            method: 'POST',
                            data: {
                                key: filePolicy.key,
                                'AWSAccessKeyId': filePolicy.accessKey,
                                'acl': filePolicy.acl,
                                'policy': filePolicy.policy,
                                'signature': filePolicy.signature,
                                'x-amz-server-side-encryption': filePolicy.xAmzServerSideEncryption,
                                'x-amz-credential': filePolicy.xAmzCredential,
                                'x-amz-algorithm': filePolicy.xAmzAlgorithm,
                                'x-amz-date': filePolicy.xAmzDate,
                                'Content-Type': filePolicy.contentType, // content type of the file (NotEmpty)
                                'Content-Disposition': filePolicy.contentDisposition,
                                filename: filePolicy.fileName, // this is needed for Flash polyfill IE8-9
                                file: file
                            }
                        });

                        file.upload.then(response => {
                            if (response.status === 204) {
                                if (!file.$deleted) {
                                    file.result = [{
                                        id: filePolicy.id,
                                        fileName: filePolicy.fileName,
                                        fileSize: filePolicy.fileSize,
                                        contentType: filePolicy.contentType,
                                        url: response.headers('Location'),
                                        hashToken: response.headers('ETag').replace(/\"/g, ''),
                                        creationDate: MomentHelper.getUTCTimestampFromString(response.headers('Date'))
                                    }];
                                    attachedFiles.push(filePolicy.fileName);
                                }
                            } else {
                                let copy = angular.copy(file);
                                copy.$error = 'upload';
                                comment.errFiles.push(copy);
                            }
                        }, (response) => {
                            if (response.status > 0 && response.data) {
                                if (response.data.displayError) {
                                    scope.serverError = response.data.displayError;
                                } else {
                                    scope.serverError = $translate.instant('comment.add.failure');
                                }
                            }
                        }, (evt) => {
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });
                    });
                    scope.activate(comment, true);
                };

                //gatherFilesInfo - all files we are going to upload
                let filesInfo = comment.files.map(file => {
                    return {
                        fileName: file.name,
                        fileSize: file.size,
                        contentType: file.type
                    };
                });

                //make a call to server - generate policy for files first
                ApiCalls.generateFilesUploadPolicy(filesInfo).then(response => {
                    if (response.success) {
                        uploadFunc(response.result);
                    } else {
                        scope.serverError = $translate.instant('validation.text.getPolicy');
                    }
                }, () => {
                    scope.serverError = $translate.instant('validation.text.getPolicy');
                });
            };

            scope.deleteFile = (file, event, comment) => {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                let fileIndex = comment.files.indexOf(file);
                attachedFiles = attachedFiles.filter(f => f !== file.name);
                file.$deleted = true;

                if (fileIndex !== -1) {
                    comment.files.splice(fileIndex, 1);
                }
                if (file.result && file.result[0] && file.result[0].id) {
                    ApiCalls.deleteFile(file.result[0].id);
                }
            };
        }
    };
}
