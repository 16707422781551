export function onboardingRoute($stateProvider, $urlRouterProvider) {
    'ngInject';

    $stateProvider
        .state('onboarding', {
            url: '/onboarding',
            template: require('./index.html'),
            controller: 'OnboardingController',
            controllerAs: 'ctrl',
            params: {
                type: null
            }
        });

    $urlRouterProvider.otherwise('/');
}
