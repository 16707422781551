import Drop from 'tether-drop';

export function tetherDrop() {
    'ngInject';

    return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
            ngModel: '=?',
            values: '=?',
            isDisabled: '&?',
            placeholder: '@?'
        },
        template: `
            <div class="dropdown-tether">
                <button class="tether-handler" data-ng-disabled="!values.length || readOnly" type="button">
                    <span>{{getValue(ngModel)}}</span>
                    <i class="icon-common_arrow_down"></i>
                </button>
                <ul class="dropdown-menu" ng-hide="readOnly">
                    <li ng-repeat="value in values" ng-class="{active: ngModel == value.id}"
                        ng-click="setValue(value)">
                        <i class="icon-menu_checkmark"></i>{{::value.item}}
                    </li>
                </ul>
            </div>
        `,
        link: (scope, element, attrs, ctrl) => {
            if (scope.isDisabled) {
                scope.$watch(() => scope.isDisabled(), val => scope.readOnly = val);
            }
            const DropdownMenuClass = Drop.createContext({
                classPrefix: attrs.class ? attrs.class : 'dropdown-tether'
            });
            let drop = new DropdownMenuClass({
                target: element.find('.tether-handler')[0],
                content: element.find('.dropdown-menu')[0],
                position: 'bottom left',
                openOn: 'click',
                remove: true
            });

            scope.getValue = () => {
                const hasValues = scope.values && scope.values.length;
                const noSelectedValue = scope.ngModel == null;

                if (scope.placeholder && (noSelectedValue || !hasValues)) {
                    return scope.placeholder;
                }

                if (hasValues) {
                    if (noSelectedValue) {
                        return scope.values[0].item;
                    }
                    const value = scope.values.find(v => v.id === scope.ngModel);
                    if (value) {
                        return value.item;
                    }
                }
            };

            scope.setValue = value => {
                if (scope.ngModel === value.id) {
                    drop.close();
                    return;
                }
                if (!scope.ngModel && !value.item) {
                    drop.close();
                    return;
                }
                ctrl.$setViewValue(value.id || null);
                drop.close();
            };

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
