import React from 'react';
import { generateInitials } from "../../../filters/initials";
import { getUserColor } from '../../../utils';

const UserAvatar = ({ user }) => {
    const icon = user.avatarUrl
        ? (<img src={`${user.avatarUrl}_24`} alt={user.label} />)
        : (<span>{generateInitials(user.label)}</span>);

    return (
        <div className="thumbnail" style={{ background: getUserColor(user) }}>
            {icon}
            {user.isDeleted && (
                <div className="badge-deleted">
                    <i className="icon-assignee_badge_issued" />
                </div>
            )}
        </div>
    )
};

export default UserAvatar;
