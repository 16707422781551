import Drop from 'tether-drop'
import './single-select.less'

const baseClass = 'single-select'
const SelectDropdown = Drop.createContext({ classPrefix: `${baseClass}-drop-tether` })
const searchIcon = 'icon-header_search'

const renderIcon = (classList) => {
    if (classList instanceof Array) {
        classList = classList.join(' ')
    }
    return `<i class="${classList}"></i>`
}

const renderEntityIcon = icon => {
    return `<div class="entity-icon" style="background-color: ${icon.color}"><i class="icon ${icon.id}"></i></div>`
}

export function SingleSelect () {
    return {
        scope: true,
        bindToController: {
            id: '<?',
            items: '<',
            onSelect: '&?',
            onSearch: '&?',
            onOpen: '&?',
            onClose: '&?',
            placeholder: '@',
            defaultIcon: '<',
            dropdownClass: '@?',
            readonly: '<?',
            noResults: '<?'
        },
        template: `
<div class="${baseClass}" ng-class="selectCtrl.getElementClasses()">
    <div class="${baseClass}-header" ng-click="selectCtrl.focusIn()" tabindex="-1">
        <div class="icon" ng-bind-html="selectCtrl.renderHeaderIcon()"></div>
        <div class="badge-deleted" ng-if="selectCtrl.isDisabled()">
            <i class="icon-assignee_badge_issued"></i>
        </div>
        <div class="control" ng-hide="selectCtrl.readonly">
            <div class="value">{{ selectCtrl.getValue() }}</div>
            <input type="text"
                ng-attr-id="{{ selectCtrl.id }}"
                placeholder="{{ selectCtrl.getValue() }}"
                ng-focus="selectCtrl.focusIn()"
                ng-blur="selectCtrl.focusOut()"
                ng-keydown="selectCtrl.keyDown($event)"
                ng-model="selectCtrl.searchText" />
        </div>
        <div class="control" ng-hide="!selectCtrl.readonly">
            <div class="value">{{ selectCtrl.getValue() }}</div>
        </div>
        <div class="action" ng-hide="selectCtrl.readonly">
            <i class="icon-common_arrow_down caret" ng-if="!selectCtrl.value"></i>
            <button class="clear" type="button"
                ng-if="selectCtrl.value"
                ng-click="selectCtrl.clear($event)"
                ng-keydown="$event.stopPropagation()">
                <i class="icon-common_close"></i>
            </button>
        </div>
    </div>
    <div class="${baseClass}-dropdown">
        <div class="dropdown-list {{selectCtrl.dropdownClass}}">
            <ul>
                <li ng-repeat="item in filteredItems = (selectCtrl.items | filter:selectCtrl.activeItemsFilter)"
                    ng-click="selectCtrl.select(item)"
                    ng-class="{active: $index===selectCtrl.activeItemIndex, selected: item.selected}"
                    ng-mouseenter="selectCtrl.activate($index)"
                    tabindex="-1">
                    <span class="item-label">
                        <span class="item-icon" ng-bind-html="selectCtrl.renderItemIcon(item)"></span>
                        <span class="item-text" ng-bind-html="item.name | highlight:selectCtrl.searchText"></span>
                    </span>
                </li>
                <li
                    class="no-results"
                    ng-hide="filteredItems.length"
                    ng-click="selectCtrl.select({noResults: true, searchText: selectCtrl.searchText})"
                    ng-bind-html="selectCtrl.noResults"
                ></li>
            </ul>
        </div>
    </div>
</div>`,
        controllerAs: 'selectCtrl',
        controller: function ($scope, $element, $timeout, $translate, $interval, $sce) {
            'ngInject'

            let blurTimeout

            this.searchText = ''
            this.activeItemIndex = 0
            this.value = null

            this.readonly = false
            this.defaultIcon = ''
            this.isFocused = false
            this.isOpened = false
            this.noResults = $translate.instant('text.userField.noResults')

            const renderDefaultIcon = () => {
                return renderIcon(this.defaultIcon || searchIcon)
            }

            const removeSearchText = () => {
                this.searchText = ''
                return false
            }

            const adjustDropdownWidth = () => {
                const width = window.getComputedStyle(this.dropdownTarget).width
                this.dropdownContent.style.width = Math.max(parseInt(width), 260) + 'px'
            }

            const open = () => {
                this.isOpened = true
                if (this.onOpen) {
                    this.onOpen()
                }
                if (this.dropdown && !this.dropdown.isOpened()) {
                    adjustDropdownWidth()
                    this.dropdown.open()
                    this.repositionInterval = $interval(() => {
                        if (this.dropdown) {
                            this.dropdown.position()
                        }
                    }, 200)
                }
            }

            const close = () => {
                this.isOpened = false
                if (this.repositionInterval) {
                    $interval.cancel(this.repositionInterval)
                }
                if (this.dropdown) {
                    this.dropdown.remove()
                }
                if (this.onClose) {
                    this.onClose()
                }
                removeSearchText()
            }

            const scrollListToActiveItem = () => {
                const activeEl = this.dropdownScrollableArea.find('.active')

                if (activeEl.length) {
                    const itemPosition = activeEl.position().top
                    const itemHeight = activeEl.height()

                    const listScrollTop = this.dropdownScrollableArea.scrollTop()
                    const listHeight = this.dropdownScrollableArea.height()

                    let newScrollTop
                    if (itemPosition < 0) {
                        newScrollTop = listScrollTop + itemPosition
                    }

                    if (itemPosition + itemHeight >= listHeight) {
                        newScrollTop = listScrollTop + (itemPosition - listHeight) + itemHeight
                    }

                    if (newScrollTop !== undefined) {
                        this.dropdownScrollableArea.scrollTop(newScrollTop)
                    }
                }
            }

            const focus = () => {
                $timeout.cancel(blurTimeout)
                this.inputElement.focus()
                this.isFocused = true
            }

            this.getElementClasses = () => {
                return {
                    focused: this.isFocused,
                    opened: this.isOpened,
                    empty: !this.value,
                    'has-value': this.value,
                    readonly: this.readonly
                }
            }

            this.renderItemIcon = (item) => {
                if (typeof item.icon === 'function') {
                    return item.icon()
                }

                if (item.icon && item.icon.id) {
                    return $sce.trustAsHtml(renderEntityIcon(item.icon))
                }

                return item.icon ? renderIcon(item.icon) : ''
            }

            this.renderHeaderIcon = () => {
                if (!this.isOpened) {
                    return this.value ? this.renderItemIcon(this.value) : renderDefaultIcon()
                }

                return renderIcon(searchIcon)
            }

            this.focusIn = () => {
                if (!this.readonly) {
                    focus()
                    open()
                }
            }

            this.focusOut = () => {
                blurTimeout = $timeout(() => {
                    this.activeItemIndex = 0
                    scrollListToActiveItem()
                    this.isFocused = false
                    close()
                }, 300)
            }

            this.keyDown = (event) => {
                let preventDefault = () => {
                    event.preventDefault()
                    event.stopPropagation()
                }

                switch (event.key) {
                    case 'ArrowDown':
                        preventDefault()
                        if (this.isOpened) {
                            this.incrementActiveItemIndex()
                            $timeout(() => scrollListToActiveItem())
                        } else {
                            open()
                        }
                        break
                    case 'ArrowUp':
                        preventDefault()
                        if (this.isOpened) {
                            this.decrementActiveItemIndex()
                            $timeout(() => scrollListToActiveItem())
                        } else {
                            open()
                        }
                        break
                    case 'Enter':
                        preventDefault()
                        if (this.isOpened) {
                            this.selectActiveItem()
                        } else {
                            open()
                        }
                        break
                    case 'Escape':
                        preventDefault()
                        close()
                        break
                    default:
                }
            }

            this.clear = (event) => {
                if (event) {
                    event.preventDefault()
                }

                this.focusIn()

                if (this.onSelect) {
                    this.onSelect(null)
                }
            }

            this.search = () => {
                if (this.onSearch) {
                    this.onSearch({ query: this.searchText })
                }
            }

            this.getValue = () => {
                return $translate.instant(this.value ? this.value.name : this.placeholder)
            }

            this.select = (item) => {
                focus()
                $timeout(() => {
                    if (this.onSelect) {
                        this.onSelect({ item })
                    }
                    close()
                }, 50)
            }

            this.getMaxIndex = () => {
                return this.items.length - 1
            }

            this.incrementActiveItemIndex = () => {
                let nextIndex = this.activeItemIndex + 1
                this.activeItemIndex = Math.min(nextIndex, this.getMaxIndex())
                return false
            }

            this.decrementActiveItemIndex = () => {
                let nextIndex = this.activeItemIndex - 1
                this.activeItemIndex = Math.max(nextIndex, 0)
                return false
            }

            this.selectActiveItem = () => {
                this.select(this.items[this.activeItemIndex])
                return false
            }

            this.isDisabled = () => {
                return this.value && (this.value.isDeleted || this.value.isDisabled || this.value.isInvalid)
            }

            this.activeItemsFilter = (item) => {
                return !item.isDisabled && !item.isDeleted && !item.isHidden
            }

            this.activate = (index) => {
                this.activeItemIndex = index
            }

            this.$onChanges = () => {
                this.activeItemIndex = Math.min(this.activeItemIndex, this.items.length)
                this.value = this.items.find(item => item.selected)

                if (this.dropdownScrollableArea) {
                    scrollListToActiveItem()
                }
            }

            this.createDropdown = (element) => {
                this.inputElement = element.find('input')[0]
                this.dropdownTarget = element.find(`.${baseClass}-header`)[0]
                this.dropdownContent = element.find(`.${baseClass}-dropdown`)[0]
                this.dropdownScrollableArea = element.find('.dropdown-list')
                this.dropdown = new SelectDropdown({
                    target: this.dropdownTarget,
                    content: this.dropdownContent,
                    position: 'bottom left',
                    openOn: null,
                    constrainToScrollParent: false
                })
            }

            this.destroyDropdown = () => {
                this.dropdown.destroy()
                this.dropdown = null
            }
        },
        link: (scope, element, attrs, ctrl) => {
            scope.$watch(() => ctrl.searchText, () => {
                ctrl.search()
            })

            ctrl.createDropdown(element)

            if (attrs.autofocus !== undefined) {
                ctrl.focusIn()
            }

            scope.$on('$destroy', () => ctrl.destroyDropdown())
        }
    }
}
