import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { Document, Page, pdfjs } from 'react-pdf'

import './pdf-viewer.less'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const MARGIN_BOTTOM = 10

export default class PDFViewer extends React.Component {
    static propTypes = {
        file: PropTypes.string.isRequired,
        scale: PropTypes.number,
        onError: PropTypes.func
    }

    static defaultProps = {
        scale: .5
    }

    constructor (props) {
        super(props)

        this.state = {
            pages: [],
            scrollTop: 0,
            container: { width: 0, height: 0 },
            documentIsLoaded: false
        }

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
        this.onDocumentLoadError = this.onDocumentLoadError.bind(this)
        this.onScroll = throttle(this.onScroll.bind(this), 100)

        this.nodes = []
    }

    updatePageDimensions (index) {
        const { container, pages } = this.state
        const { scale } = this.props

        pages.forEach((p, i) => {
            let pageScale = container.width * scale / p.width
            p.scaledWidth = container.width * scale
            p.scaledHeight = p.height * pageScale
            p.offset = (i === 0 ? 0 : pages[i - 1].scaledHeight) + MARGIN_BOTTOM
        })

        this.setState({ pages })
    }

    onDocumentLoadSuccess ({ numPages }) {
        const { container } = this.state
        const { scale } = this.props

        const pages = Array.from(new Array(numPages)).map(() => {
            let width = container.width * scale
            let height = width / 3 * 4

            this.nodes.push(React.createRef())

            return {
                width,
                height
            }
        })

        this.setState({ pages, documentIsLoaded: true })
    }

    onDocumentLoadError () {
        if (this.props.onError) {
            this.props.onError()
        }
    }

    onPageLoadSuccess (page, index) {
        const { pages } = this.state
        const { width, height } = page.getViewport({ scale: 1 })

        pages[index] = {
            loaded: true,
            width,
            height
        }

        if (index === 0) {
            pages.forEach((p, i) => {
                if (!p.loaded) {
                    p.width = pages[0].width
                    p.height = pages[0].height
                }
            })
        }

        this.setState({ pages })
    }

    onScroll (e) {
        this.setState({ scrollTop: e.target.scrollTop })
    }

    componentDidMount () {
        this.container = ReactDOM.findDOMNode(this).parentNode
        this.container.addEventListener('scroll', this.onScroll)
        const { height, width } = this.container.getBoundingClientRect()
        this.setState({
            container: { height, width }
        })
    }

    componentWillUnmount () {
        this.container.removeEventListener('scroll', this.onScroll)
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.file !== this.props.file) {
            this.setState({ pages: [], documentIsLoaded: false })
            this.nodes = []
        }
        if (prevProps.scale !== this.props.scale) {
            this.updatePageDimensions(this.state.pages)
        }
    }

    getFittedPageSize (index) {
        const { container, pages } = this.state
        const { width, height } = pages[index]
        const { scale } = this.props
        const pageWidth = container.width * scale
        const pageScale = pageWidth / width

        return {
            width: pageWidth,
            height: height * pageScale
        }
    }

    pageIsVisible (index) {
        const { pages, scrollTop, container } = this.state
        const page = pages[index]
        const node = this.nodes[index]
        const pageSize = this.getFittedPageSize(index)

        // Page is below visible area
        if (node.current && node.current.offsetTop > scrollTop + container.height) {
            return false
        }

        // Page is above visible area
        if (page.loaded && node.current && scrollTop > node.current.offsetTop + pageSize.height) {
            return false
        }

        return true
    }

    getPageContainerStyles (index) {
        const { width, height } = this.getFittedPageSize(index)

        return {
            marginBottom: MARGIN_BOTTOM,
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#fff',
            width,
            height
        }
    }

    renderDocumentLoading () {
        return (
            <span className="new-loading-spinner"/>
        )
    }

    render () {
        const { file, scale } = this.props
        const { pages, container, documentIsLoaded } = this.state
        const pageWidth = container.width * scale
        const loadingNode = this.renderDocumentLoading()

        return (
            <Document
                file={file}
                className="pdf-viewer"
                onLoadSuccess={this.onDocumentLoadSuccess}
                onLoadError={this.onDocumentLoadError}
                onPassword={this.onDocumentLoadError}
                loading={loadingNode}
            >
                {
                    documentIsLoaded && pages.map((page, index) => (
                        <div
                            key={`page_${index + 1}`}
                            ref={this.nodes[index]}
                            style={this.getPageContainerStyles(index)}
                            onClick={e => e.stopPropagation()}>
                            {this.pageIsVisible(index) ? (
                                <Page
                                    className={`pdf-viewer__page`}
                                    pageNumber={index + 1}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                    onLoadSuccess={(p) => this.onPageLoadSuccess(p, index)}
                                    loading={loadingNode}
                                    width={pageWidth}
                                />
                            ) : (
                                <div className="pdf-viewer__page">{loadingNode}</div>
                            )}
                        </div>
                    ))
                }
            </Document>
        )
    }
}
