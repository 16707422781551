// Generated from FormulaLexer.g4 by ANTLR 4.9.2
// jshint ignore: start
import antlr4 from '../antlr4'

const serializedATN = ['\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786',
    '\u5964\u0002\u0010\u0084\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003',
    '\u0004\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007',
    '\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004',
    '\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010',
    '\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0003\u0002\u0006\u0002',
    '\'\n\u0002\r\u0002\u000e\u0002(\u0003\u0002\u0003\u0002\u0003\u0003',
    '\u0006\u0003.\n\u0003\r\u0003\u000e\u0003/\u0003\u0003\u0003\u0003\u0006',
    '\u00034\n\u0003\r\u0003\u000e\u00035\u0005\u00038\n\u0003\u0003\u0004',
    '\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0007',
    '\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t',
    '\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003',
    '\u000b\u0003\u000b\u0003\f\u0003\f\u0003\r\u0003\r\u0003\u000e\u0003',
    '\u000e\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003',
    '\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003',
    '\u0010\u0003\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0003',
    '\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003',
    '\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003',
    '\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0005',
    '\u0011{\n\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0012\u0005',
    '\u0012\u0081\n\u0012\u0003\u0012\u0003\u0012\u0002\u0002\u0013\u0003',
    '\u0003\u0005\u0004\u0007\u0005\t\u0006\u000b\u0007\r\b\u000f\t\u0011',
    '\n\u0013\u000b\u0015\f\u0017\r\u0019\u000e\u001b\u0002\u001d\u0002\u001f',
    '\u0002!\u000f#\u0010\u0003\u0002\u0004\u0004\u0002\u000b\u000b""\u0005',
    '\u00022;CHch\u0002\u0086\u0002\u0003\u0003\u0002\u0002\u0002\u0002\u0005',
    '\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002\u0002\t',
    '\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002\r',
    '\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002\u0002\u0011',
    '\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002\u0002\u0015',
    '\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002\u0002\u0019',
    '\u0003\u0002\u0002\u0002\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003',
    '\u0002\u0002\u0002\u0003&\u0003\u0002\u0002\u0002\u0005-\u0003\u0002',
    '\u0002\u0002\u00079\u0003\u0002\u0002\u0002\t;\u0003\u0002\u0002\u0002',
    '\u000b=\u0003\u0002\u0002\u0002\r?\u0003\u0002\u0002\u0002\u000fA\u0003',
    '\u0002\u0002\u0002\u0011E\u0003\u0002\u0002\u0002\u0013I\u0003\u0002',
    '\u0002\u0002\u0015M\u0003\u0002\u0002\u0002\u0017Q\u0003\u0002\u0002',
    '\u0002\u0019S\u0003\u0002\u0002\u0002\u001bU\u0003\u0002\u0002\u0002',
    '\u001dW\u0003\u0002\u0002\u0002\u001f\\\u0003\u0002\u0002\u0002!z\u0003',
    '\u0002\u0002\u0002#\u0080\u0003\u0002\u0002\u0002%\'\t\u0002\u0002\u0002',
    '&%\u0003\u0002\u0002\u0002\'(\u0003\u0002\u0002\u0002(&\u0003\u0002',
    '\u0002\u0002()\u0003\u0002\u0002\u0002)*\u0003\u0002\u0002\u0002*+\b',
    '\u0002\u0002\u0002+\u0004\u0003\u0002\u0002\u0002,.\u00042;\u0002-,',
    '\u0003\u0002\u0002\u0002./\u0003\u0002\u0002\u0002/-\u0003\u0002\u0002',
    '\u0002/0\u0003\u0002\u0002\u000207\u0003\u0002\u0002\u000213\u00070',
    '\u0002\u000224\u00042;\u000232\u0003\u0002\u0002\u000245\u0003\u0002',
    '\u0002\u000253\u0003\u0002\u0002\u000256\u0003\u0002\u0002\u000268\u0003',
    '\u0002\u0002\u000271\u0003\u0002\u0002\u000278\u0003\u0002\u0002\u0002',
    '8\u0006\u0003\u0002\u0002\u00029:\u0007-\u0002\u0002:\b\u0003\u0002',
    '\u0002\u0002;<\u0007/\u0002\u0002<\n\u0003\u0002\u0002\u0002=>\u0007',
    ',\u0002\u0002>\f\u0003\u0002\u0002\u0002?@\u00071\u0002\u0002@\u000e',
    '\u0003\u0002\u0002\u0002AB\u0007U\u0002\u0002BC\u0007W\u0002\u0002C',
    'D\u0007O\u0002\u0002D\u0010\u0003\u0002\u0002\u0002EF\u0007C\u0002\u0002',
    'FG\u0007X\u0002\u0002GH\u0007I\u0002\u0002H\u0012\u0003\u0002\u0002',
    '\u0002IJ\u0007O\u0002\u0002JK\u0007K\u0002\u0002KL\u0007P\u0002\u0002',
    'L\u0014\u0003\u0002\u0002\u0002MN\u0007O\u0002\u0002NO\u0007C\u0002',
    '\u0002OP\u0007Z\u0002\u0002P\u0016\u0003\u0002\u0002\u0002QR\u0007*',
    '\u0002\u0002R\u0018\u0003\u0002\u0002\u0002ST\u0007+\u0002\u0002T\u001a',
    '\u0003\u0002\u0002\u0002UV\t\u0003\u0002\u0002V\u001c\u0003\u0002\u0002',
    '\u0002WX\u0005\u001b\u000e\u0002XY\u0005\u001b\u000e\u0002YZ\u0005\u001b',
    '\u000e\u0002Z[\u0005\u001b\u000e\u0002[\u001e\u0003\u0002\u0002\u0002',
    '\\]\u0005\u001d\u000f\u0002]^\u0005\u001d\u000f\u0002^_\u0005\u001d',
    '\u000f\u0002_`\u0005\u001d\u000f\u0002`a\u0005\u001d\u000f\u0002ab\u0005',
    '\u001d\u000f\u0002bc\u0005\u001d\u000f\u0002cd\u0005\u001d\u000f\u0002',
    'd \u0003\u0002\u0002\u0002ef\u0007>\u0002\u0002fg\u0007B\u0002\u0002',
    'gh\u0007h\u0002\u0002hi\u0007k\u0002\u0002ij\u0007g\u0002\u0002jk\u0007',
    'n\u0002\u0002kl\u0007f\u0002\u0002l{\u0007a\u0002\u0002mn\u0007>\u0002',
    '\u0002no\u0007B\u0002\u0002op\u0007h\u0002\u0002pq\u0007k\u0002\u0002',
    'qr\u0007g\u0002\u0002rs\u0007n\u0002\u0002st\u0007f\u0002\u0002tu\u0007',
    'a\u0002\u0002uv\u0007v\u0002\u0002vw\u0007g\u0002\u0002wx\u0007o\u0002',
    '\u0002xy\u0007r\u0002\u0002y{\u0007a\u0002\u0002ze\u0003\u0002\u0002',
    '\u0002zm\u0003\u0002\u0002\u0002{|\u0003\u0002\u0002\u0002|}\u0005\u001f',
    '\u0010\u0002}~\u0007@\u0002\u0002~"\u0003\u0002\u0002\u0002\u007f\u0081',
    '\u0007\u000f\u0002\u0002\u0080\u007f\u0003\u0002\u0002\u0002\u0080\u0081',
    '\u0003\u0002\u0002\u0002\u0081\u0082\u0003\u0002\u0002\u0002\u0082\u0083',
    '\u0007\f\u0002\u0002\u0083$\u0003\u0002\u0002\u0002\t\u0002(/57z\u0080',
    '\u0003\b\u0002\u0002'].join('')

const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN)

const decisionsToDFA = atn.decisionToState.map((ds, index) => new antlr4.dfa.DFA(ds, index))

export default class FormulaLexer extends antlr4.Lexer {

    static grammarFileName = 'FormulaLexer.g4'
    static channelNames = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN']
    static modeNames = ['DEFAULT_MODE']
    static literalNames = [null, null, null, '\'+\'', '\'-\'', '\'*\'', '\'/\'', '\'SUM\'',
        '\'AVG\'', '\'MIN\'', '\'MAX\'', '\'(\'', '\')\'']
    static symbolicNames = [null, 'WS', 'NUMBER', 'ADD', 'SUB', 'MUL', 'DIV',
        'SUM', 'AVG', 'MIN', 'MAX', 'OPEN_P', 'CLOSE_P',
        'FIELD', 'NEWLINE']
    static ruleNames = ['WS', 'NUMBER', 'ADD', 'SUB', 'MUL', 'DIV', 'SUM',
        'AVG', 'MIN', 'MAX', 'OPEN_P', 'CLOSE_P', 'HEX', 'BLOCK',
        'GUID', 'FIELD', 'NEWLINE']

    constructor (input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache())
    }

    get atn () {
        return atn
    }
}

FormulaLexer.EOF = antlr4.Token.EOF
FormulaLexer.WS = 1
FormulaLexer.NUMBER = 2
FormulaLexer.ADD = 3
FormulaLexer.SUB = 4
FormulaLexer.MUL = 5
FormulaLexer.DIV = 6
FormulaLexer.SUM = 7
FormulaLexer.AVG = 8
FormulaLexer.MIN = 9
FormulaLexer.MAX = 10
FormulaLexer.OPEN_P = 11
FormulaLexer.CLOSE_P = 12
FormulaLexer.FIELD = 13
FormulaLexer.NEWLINE = 14



