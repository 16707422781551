import React from 'react'
import PropTypes from 'prop-types'

import ActorsPrintView from './actors-print-view'

import './process-print-view.less'
import StatusFormatter from '../../../modules/reports/components/status-formatter'
import { decodeProcessStatus } from '../../../modules/reports/store/constants'
import { getService, translate } from '../../../modules/react/utils'
import UserPrintView from './user-print-view'
import DateTimeFormatter from '../../../modules/reports/components/date-time-formatter'
import ProcessSubTreePrintView from './process-sub-tree-print-view'
import TableSectionPrintView from './table-section-print-view'
import SimpleSectionPrintView from './simple-section-print-view'
import Icon from '../icon'

const COMPONENT_CLASS_NAME = 'process-print-view'
const BODY_CLASS_NAME = 'with-process-print-view'

class ProcessPrintView extends React.Component {
    static propTypes = {
        process: PropTypes.object
    }

    render () {
        const { process } = this.props
        if (!process?.id) {
            return (
                <div className={COMPONENT_CLASS_NAME}>
                    Data is not loaded, please use &#34;print&#34; action from dropdown menu (next to process title)
                </div>
            )
        }
        const status = decodeProcessStatus(process.status, process.isRejected)

        return (
            <div className={COMPONENT_CLASS_NAME}>
                <h1 className={'process-name'}>{process.name}</h1>
                <div className={'section status'}>
                    {process.template !== undefined && (
                        <div className={'status-field'}>
                            <label>{translate('label.template')}</label>
                            <span className={'template-name'}>{process.template.name}</span>
                        </div>
                    )}
                    <div className={'status-field'}>
                        <label>{translate('label.started')}</label>
                        <DateTimeFormatter timestamp={process.startedDate} withTime={true}/>
                    </div>
                    <div className={'status-field'}>
                        <label>{translate('label.processStarter')}</label>
                        <UserPrintView user={process.starter}/>
                    </div>
                    {process.dueDate !== undefined && (
                        <div className="status-field">
                            <label>{translate('label.dueDate')}</label>
                            <DateTimeFormatter timestamp={process.dueDate} withTime={false}/>
                        </div>
                    )}
                    <div className={'status-field'}>
                        <label>{translate('label.status')}</label>
                        <span>
                            <StatusFormatter status={status} isProcess={true} withColor={false}/>
                            {' '}
                            {process.completedDate !== undefined && (
                                <DateTimeFormatter timestamp={process.completedDate} withTime={true}/>
                            )}
                        </span>
                    </div>
                    {(process.rejectReason && process.rejectReason.length) && (
                        <div className={'status-field'}>
                            <label>{translate('label.rejectionReason')}</label>
                            <span>{process.rejectReason[0]}</span>
                        </div>
                    )}
                    {(process.stoppedReason !== undefined) && (
                        <div className={'status-field'}>
                            <label>{translate('label.stoppedReason')}</label>
                            <span>{process.stoppedReason}</span>
                        </div>
                    )}
                    <div className={'status-field'}>
                        <label>{translate('label.processManagers')}</label>
                        <ActorsPrintView actors={process.managers}/>
                    </div>
                    {process.watchers && (
                        <div className={'status-field'}>
                            <label>{translate('label.processWatchers')}</label>
                            <ActorsPrintView actors={process.watchers}/>
                        </div>
                    )}
                </div>
                <div className={'section steps'}>
                    <h2>
                        {translate('plural.items.button', { COUNT: process.subTree.length }, true)}
                    </h2>
                    <ProcessSubTreePrintView subTree={process.subTree} execution={process.execution}/>
                </div>
                <div className={'section data-form'}>
                    <h2>{translate('label.dataForm')}</h2>
                    {process.dataModel?.list.map(({ section }) => (
                        section.isTable
                            ? (<TableSectionPrintView key={section.id} section={section}/>)
                            : (<SimpleSectionPrintView key={section.id} section={section}/>)
                    ))}
                </div>
                {(process.comments !== undefined && process.commentsCount > 0) && (
                    <div className={'section comments'}>
                        <h2>
                            {translate('label.comments')}
                            {' '}
                            <small>{translate('plural.comments', { COUNT: process.commentsCount }, true)}</small>
                            {process.attachmentsCount > 0 && (
                                <small>{' • '}{translate('plural.files', { COUNT: process.attachmentsCount }, true)}</small>
                            )}
                        </h2>
                        {process.comments.map(({ task, comments }) => (
                            <div className={'task'} key={task.id}>
                                <h4>
                                    {task.name}
                                    {' '}
                                    <small>{translate('plural.comments', { COUNT: task.commentsCount }, true)}</small>
                                    {task.attachmentsCount > 0 && (
                                        <small>{' • '}{translate('plural.files', { COUNT: task.attachmentsCount }, true)}</small>
                                    )}
                                </h4>
                                {comments.map(comment => (
                                    <div className={'comment'} key={comment.id}>
                                        <header>
                                            <UserPrintView user={comment.creator}/>
                                            {' '}
                                            <DateTimeFormatter timestamp={comment.creationDate} withTime={true}
                                                               className={'date'}/>
                                            {' '}
                                            {comment.revision !== undefined && (
                                                <span
                                                    className={'revision'}>{translate('commentsList.label.revision')} {comment.revision}</span>
                                            )}
                                        </header>
                                        {comment.commentType === 'AUTO' ? (
                                            <p>{translate('commentsList.text.reviewComment')}: {this.commentParser(comment.text, comment.mentions, true).toString()}</p>
                                        ) : (
                                            <p>{this.commentParser(comment.text, comment.mentions, true).toString()}</p>
                                        )}
                                        {comment.attachments?.length > 0 && (
                                            <div className={'files'}>
                                                {comment.attachments.map(({ id, fileName }) => (
                                                    <span key={id}>
                                                        <Icon name={'task_attachment'}/>{fileName}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
                {process.history !== undefined && (
                    <div className={'section history'}>
                        <h2>{translate('label.history')}</h2>
                        <table>
                            <tbody>
                            {process.history.map(log => (
                                <tr className={'log'} key={log.id}>
                                    <td className={'datetime'}><DateTimeFormatter timestamp={log.date} withTime={true}/>
                                    </td>
                                    <td className={'description'}>{log.description}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )
    }

    componentDidMount () {
        document.body.classList.add(BODY_CLASS_NAME)
        this.commentParser = getService('$filter')('parseComment')
    }

    componentWillUnmount () {
        document.body.classList.remove(BODY_CLASS_NAME)
    }
}

export default ProcessPrintView
