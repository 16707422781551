import { excludeServiceFields } from '../modules/templates/services/data-model-utils'
import { EDITABLE, HIDDEN, READ_ONLY, REQUIRED } from '../services/constants'

export function templateFieldsVisibility(companyCurrencies, allCurrencies, $translate, $timeout) {
    'ngInject';

    const types = [
        {
            name: HIDDEN,
            icon: 'icon-template_create_field_hidden',
            label: {
                task: 'template.task.fieldVisibility.hidden',
                process: 'template.process.fieldVisibility.hidden',
            },
            tooltip: {
                tab: {
                    task: 'tooltips.templateTaskDataModel.hiddenTab',
                    process: 'tooltips.templateEdit.processStartFields.hiddenTab'
                },
                view: {
                    task: '',
                    process: ''
                }
            }
        },
        {
            name: READ_ONLY,
            icon: 'icon-template_create_field_read_only',
            label: {
                task: 'template.task.fieldVisibility.read_only',
                process: 'template.process.fieldVisibility.read_only',
            },
            tooltip: {
                tab: {
                    task: 'tooltips.templateTaskDataModel.readOnlyTab',
                    process: 'tooltips.templateEdit.processStartFields.readOnlyTab'
                },
                view: {
                    task: 'tooltips.dataField.read_only',
                    process: 'tooltips.processStartField.read_only'
                }
            }
        },
        {
            name: EDITABLE,
            icon: 'icon-template_create_field_editable',
            label: {
                task: 'template.task.fieldVisibility.editable',
                process: 'template.process.fieldVisibility.editable',
            },
            tooltip: {
                tab: {
                    task: 'tooltips.templateTaskDataModel.editableTab',
                    process: 'tooltips.templateEdit.processStartFields.editableTab'
                },
                view: {
                    task: 'tooltips.dataField.editable',
                    process: 'tooltips.processStartField.editable'
                }
            }
        },
        {
            name: REQUIRED,
            icon: 'icon-template_create_field_required',
            label: {
                task: 'template.task.fieldVisibility.required',
                process: 'template.process.fieldVisibility.required',
            },
            tooltip: {
                tab: {
                    task: 'tooltips.templateTaskDataModel.requiredTab',
                    process: 'tooltips.templateEdit.processStartFields.requiredTab'
                },
                view: {
                    task: 'tooltips.dataField.required',
                    process: 'tooltips.processStartField.required'
                }
            }
        }
    ];

    const emptyStateTitles = {
        task: {
            edit: 'emptyState.fieldsVisibility.templateEdit.noDataForm',
            view: 'emptyState.fieldsVisibility.templateView.taskAllHidden'
        },
        process: {
            edit: 'emptyState.fieldsVisibility.templateEdit.noDataForm.processStart',
            view: 'text.formFieldsHelpPageLink'
        },
        link: 'emptyState.fieldsVisibility.templateEdit.noDataForm.link',
        emptyDataModel: 'emptyState.fieldsVisibility.templateView.noDataForm'
    };

    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            settings: '=',
            sourceDataModel: '<',
            readonly: '<',
            isProcessStart: '<',
            emptyStateAction: '&',
            formFieldActivateAction: '&'
        },
        controllerAs: '$ctrl',
        controller: function() {
            this.types = [];

            this.getSettings = () => {
                return this.settings || [];
            };

            this.getDataModel = () => {
                return this.sourceDataModel && this.sourceDataModel.list ? this.sourceDataModel.list : [];
            };

            this.getFieldId = (field) => {
                let {id, tempId} = field.name;
                return id || tempId;
            };

            this.getField = (field) => {
                return this.byId[this.getFieldId(field)];
            };

            this.getFieldSettings = (field) => {
                return this.getField(field).settings;
            };

            this.getFieldType = (field) => {
                let fieldSettings = this.getFieldSettings(field);
                return types.find(t => t.name === fieldSettings.visibility);
            };

            this.getFieldTypeIcon = (field) => {
                return this.getFieldType(field).icon;
            };

            this.getFieldTypeTooltip = (field) => {
                return this.getTypeTooltip(this.getFieldType(field));
            };

            this.findVisibleField = (field) => {
                return this.getFieldSettings(field).visibility !== 'HIDDEN';
            };

            this.sectionFilter = (section) => {
                let hasVisibleFields = section.fieldsWithValues.filter(excludeServiceFields).filter(this.findVisibleField).length > 0;
                return !(this.readonly && !hasVisibleFields);
            };

            this.setAllTypes = (section, value) => {
                section.fieldsWithValues.filter(excludeServiceFields).forEach(field => {
                    this.setType(this.getFieldId(field), value);
                });
            };

            this.setType = (fieldId, value) => {
                let foundField = this.settings.find(f => f.fieldId === fieldId  && !(value === 'HIDDEN' && f.isUsed));
                if (foundField) {
                    foundField.settings.visibility = foundField.$isFormula && [EDITABLE, REQUIRED].includes(value) ? READ_ONLY : value
                }
            };

            this.getSectionIcon = (section) => {
                return section.isTable
                    ? 'icon-template_create_section_table'
                    : 'icon-template_create_section_form';
            };

            this.getTypeTooltip = (type, tab) => {
                return type.tooltip[tab ? 'tab' : 'view'][this.isProcessStart ? 'process' : 'task'];
            };

            this.getDefaultCurrency = (field) => {
                if (field.name.options && field.name.options.currency) {
                    let val = allCurrencies.find(c => c.id === field.name.options.currency.default.id);
                    if (val) {
                        return val.code;
                    }
                }
                return companyCurrencies.defaultCurrency ? companyCurrencies.defaultCurrency.code : null;
            };

            const convertLinkTextToHtml = (text) => {
                let matches = /(\[.+\])(\(.+\))/g.exec(text);
                if (matches && matches[1] && matches[2]) {
                    let location = matches[2].slice(1, -1);
                    let linkText = matches[1].slice(1, -1);
                    let linkHtml = `<a target="_blank" href="${location}">${linkText}</a>`;
                    return text.replace(matches[1] + matches[2], linkHtml);
                }
                return text;
            };

            this.getEmptyStateDescription = () => {
                if (!this.sections.length) {
                    if (this.readonly) {
                        let translateId = !this.getDataModel().length
                            ? emptyStateTitles.emptyDataModel
                            : emptyStateTitles[this.isProcessStart ? 'process' : 'task'].view;
                        let linkText = $translate.instant(translateId);
                        return convertLinkTextToHtml(linkText);
                    }

                    let link = `<a href="" class="empty-state-action-link">${$translate.instant(emptyStateTitles.link)}</a>`;
                    let text = $translate.instant(emptyStateTitles[this.isProcessStart ? 'process' : 'task'].edit, {link: '@link'});
                    return text.replace('@link', link);
                }
                return null;
            };

            this.updateStoredSettings = () => {
                this.byId = this.getSettings().reduce((acc, field) => Object.assign(acc, {[field.fieldId]: angular.copy(field)}), {});
                this.sections = this.getDataModel().map(s => s.section).filter(this.sectionFilter);
                this.emptyStateDesription = this.getEmptyStateDescription();
            };

            this.emptyStateActionHandler = () => {
                if (this.emptyStateAction) {
                    this.emptyStateAction();
                }
            };

            this.formFieldActivate = field => {
                this.formFieldActivateAction({field})
            }

            this.$onChanges = () => {
                this.updateStoredSettings();
            }

            this.types = types.slice()
        },
        template: require('../templates/template-fields-visibility.html'),
        link: (scope, element, attrs, ctrl) => {
            scope.$watch(() => {
                return ctrl.getSettings().map(f => `${f.fieldId}:${f.isUsed}:${f.$isFormula}:${f.settings.visibility}`).join(',');
            }, ctrl.updateStoredSettings);

            element.on('click', '.empty-state-action-link', (event) => {
                event.preventDefault();
                $timeout(ctrl.emptyStateActionHandler);
            });
        }
    };
}
