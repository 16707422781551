import { types, flow, applySnapshot } from 'mobx-state-tree'
import sortBy from 'lodash/sortBy'

import { getService, translate } from '../../react/utils'
import { Record, Column } from './record'
import { DEFAULT_DATA_TYPE, EMAIL, STRING_MULTI_LINE, STRING_SINGLE_LINE } from '../../../services/data-types'
import { getDataTypeSettings } from '../../../services/data-types-service'
import { orderColumns, prepareColumnSnapshot } from './database-utils'
import { ActorsValue } from './actors'
import { UserId } from './user'

const { identifier, string, number, integer, maybe, optional, array, map, boolean } = types

export const Icon = types.model({
    id: identifier,
    color: string
})

export const callAlertTaskPageModal = (errResponse, callback) => {
    const PageSettings = getService('PageSettings')
    const $rootScope = getService('$rootScope')
    const $window = getService('$window')
    const $translate = getService('$translate')

    if (errResponse.status === 473) {
        return PageSettings.errorHandlerModal(errResponse)
    }

    let modalScope = $rootScope.$new()
    modalScope.title = $translate.instant('error.commonTitle')
    modalScope.text = errResponse.data.displayError || errResponse.data.error
    modalScope.additionalText = $translate.instant('error.okText')
    PageSettings.openAlertModal(modalScope)
        .then(() => callback ? callback() : $window.location.reload())
        .finally(() => modalScope.$destroy())
}

export const callDeletedRecordErrorModal = (errResponse, callback) => {
    const PageSettings = getService('PageSettings')
    const $rootScope = getService('$rootScope')
    const $window = getService('$window')
    const $translate = getService('$translate')

    let modalScope = $rootScope.$new()
    modalScope.title = errResponse.data.displayError || errResponse.data.error
    modalScope.text = $translate.instant('error.clickToRefresh')
    PageSettings.openAlertModal(modalScope)
        .then(() => callback ? callback() : $window.location.reload())
        .finally(() => modalScope.$destroy())
}

export const TableForm = types.model({
    id: maybe(string),
    name: string,
    description: maybe(string),
    icon: maybe(Icon),
    owners: ActorsValue,
    editors: ActorsValue,
    viewers: ActorsValue,
    columns: array(Column)
})
    .actions(self => ({
        addNewColumn: (name = '') => {
            const isTitle = self.columns.length === 0
            const columnProps = {
                name,
                dataType: DEFAULT_DATA_TYPE,
                settings: getDataTypeSettings(DEFAULT_DATA_TYPE),
                isTitle
            }
            self.columns.push(Column.create(columnProps))
        },
        save: flow(function * save (silent = true) {
            try {
                const response = yield getService('ApiCalls').createTable(self.toServerJSON())
                self.id = response.result.id
                return { response }
            } catch (error) {
                if (!silent) {
                    console.log('New table save error:', error)
                    callAlertTaskPageModal(error)
                }
                return { error: error && error.data ? error.data : error }
            }
        }),
        toServerJSON: () => {
            return {
                id: self.id,
                name: self.name,
                description: self.description,
                icon: self.icon ? self.icon.toJSON() : undefined,
                owners: self.owners.toJSON(),
                editors: self.editors.toJSON(),
                viewers: self.viewers.toJSON(),
                columns: self.columns.map(column => {
                    const { id, name, isTitle, order, dataType } = column.toJSON()
                    const options = column.settings.toServerJSON()

                    return {
                        id,
                        name,
                        isTitle,
                        order,
                        options,
                        dataType
                    }
                })
            }
        }
    }))

export const Table = types.model({
    id: types.identifier,
    version: maybe(number),
    name: maybe(string),
    $name: maybe(string),
    description: maybe(string),
    icon: maybe(Icon),
    permissions: array(string),
    columns: maybe(array(Column)),
    records: map(Record),
    recordsKeys: optional(array(string), []),
    total: optional(integer, 0),
    viewers: maybe(ActorsValue),
    owners: maybe(ActorsValue),
    editors: maybe(ActorsValue),
    pageSize: optional(integer, 40),
    searchString: optional(string, ''),
    isChanged: maybe(boolean),
    isLoading: maybe(boolean)
}).views(self => {
    return {
        get nameValue () {
            return (self.$name !== undefined ? self.$name : self.name) || ''
        },
        get nameIsRequired () {
            return self.isChanged && (!self.nameValue || self.nameValue.length < 1)
        },
        get nameIsShort () {
            return self.isChanged && (self.nameValue && self.nameValue.length < 3)
        },
        get columnsOrdered () {
            return self.columns ? orderColumns(self.columns) : []
        },
        get isEditable () {
            return self.permissions.includes('edit')
        },
        get isRecordEditable () {
            return self.permissions.includes('editRecord')
        },
        get dataIsLoading () {
            return self.total === 0 && self.isLoading
        },
        get noSearchResults () {
            return self.total === 0 && self.searchString && !self.isLoading
        }
    }
}).actions(self => {
    const ApiCalls = getService('ApiCalls')
    let promises = {}
    let loadingQueue = []
    let reloadTimeOut
    return {
        addRecord ({ id, values }) {
            self.records.put({
                id,
                values: values.map((value, index) => {
                    const column = self.columns[index]
                    return {
                        dataType: column.dataType,
                        ...value.toJSON(),
                        isSaved: false,
                        isSaving: false
                    }
                })
            })
            self.recordsKeys = [id, ...self.recordsKeys]
            self.total = self.total + 1
        },
        updateRecord ({ id, values }) {
            self.records.get(id).values = values.map(v => v.toJSON())
        },
        deleteRecord (id) {
            if (self.records.has(id)) {
                self.records.get(id).delete()
                self.records.delete(id)
                self.recordsKeys = self.recordsKeys.filter(key => key !== id)
                self.total = self.total - 1
            }
        },
        updateColumns (columns) {
            self.columns = columns.map(prepareColumnSnapshot)
        },
        reorderColumns: flow(function * (columnsIds) {
            const orders = []
            columnsIds.forEach((columnId, i) => {
                const column = self.columns.find(c => c.id === columnId)
                orders.push({columnId, order: column.order})
                column.order = i
            })

            try {
                const response = yield ApiCalls.reorderTableColumns(self.id, {columns: columnsIds.map(id => ({id}))})
                self.updateColumns(response.result.columns)
            } catch (e) {
                // Restore orders
                orders.forEach(({columnId, order}) => {
                    const column = self.columns.find(c => c.id === columnId)
                    column.order = order
                })

                console.log('Error while reordering columns:', e)
                callAlertTaskPageModal(e)

                return { error: e && e.data ? e.data : e }
            }
        }),
        mergeInfo (tableInfo) {
            const keys = ['version', 'name', 'description', 'icon', 'permissions']
            keys.forEach(key => {
                if (tableInfo[key] !== undefined) {
                    self[key] = tableInfo[key]
                }
            })
            self.$name = undefined
        },
        mergeRecords (records, columns, firstRecordIndex = 0) {
            records.forEach((record, recordIndex) => {
                self.records.put(
                    {
                        id: record.id,
                        values: record.values.map(({ value, isInvalid }, columnIndex) => {
                            const column = columns[columnIndex]
                            return { dataType: column.dataType, ...value, isInvalid, columnId: column.id }
                        })
                    }
                )
                const position = firstRecordIndex + recordIndex
                const newRecordsKeys = self.recordsKeys.slice()
                newRecordsKeys.splice(position, 0, record.id)
                self.recordsKeys = newRecordsKeys
            })
        },
        getInfo: flow(function * (callback) {
            try {
                const response = yield ApiCalls.getTableInfo(self.id)
                self.mergeInfo(response.result)
                return { response }
            } catch (e) {
                console.log('Error while loading table info:', e)
                callAlertTaskPageModal(e, callback)
                return { error: e && e.data ? e.data : e }
            }
        }),
        getRecords: flow(function * () {
            const params = {
                count: self.pageSize,
                index: 0
            }

            if (self.searchString) {
                params.searchString = self.searchString
            }

            self.isLoading = true

            try {
                const response = yield ApiCalls.getTableRecords(self.id, params)
                self.updateColumns(response.columns)
                self.version = response.tableVersion

                self.recordsKeys = []
                self.records.clear()

                self.mergeRecords(response.list, response.columns)
                self.total = response.total
                self.viewers = response.viewers
                self.owners = response.owners
                self.editors = response.editors
                self.isLoading = false
            } catch (e) {
                console.log('Error while loading table records:', e)
                callAlertTaskPageModal(e)
            }
        }),
        getRecordAtIndex (recordIndex) {
            const recordId = self.recordsKeys.length > recordIndex ? self.recordsKeys[recordIndex] : undefined
            if (recordId) {
                return self.records.get(recordId)
            }

            const pageIndex = (recordIndex - recordIndex % self.pageSize) / self.pageSize

            if (loadingQueue.indexOf(pageIndex) === -1) {
                loadingQueue = [pageIndex, ...loadingQueue]
                self.loadNextPage()
            }

            return {
                isLoading: true
            }
        },
        loadNextPage: flow(function * () {
            if (!loadingQueue.length) {
                return
            }

            const pageIndex = loadingQueue[0]
            const pageFirstIndex = pageIndex * self.pageSize

            const params = {
                count: self.pageSize,
                index: pageIndex
            }

            if (self.searchString) {
                params.searchString = self.searchString
            }

            self.isLoading = true

            try {
                const response = yield ApiCalls.getTableRecords(self.id, params)
                if (self.version !== response.tableVersion) {
                    self.updateColumns(response.columns)
                    self.version = response.tableVersion
                }

                self.mergeRecords(response.list, response.columns, pageFirstIndex)
                self.total = response.total
                self.isLoading = false
                loadingQueue = loadingQueue.filter(pIndex => pIndex !== pageIndex)
            } catch (e) {
                console.log('Error while loading table records:', e)
                callAlertTaskPageModal(e)
            }
        }),
        changeName: value => {
            self.isChanged = true
            self.$name = value.slice(0, 100)
            if (!self.nameIsRequired && !self.nameIsShort) {
                self.name = self.$name
                self.$name = undefined
                self.saveAttribute('name', self.name)
            }
        },
        changeDescription: (value = '', silent = false) => {
            self.isChanged = true
            if (silent) {
                self.description = value.trim().slice(0, 1000)
            } else {
                self.description = value.slice(0, 1000)
                self.saveAttribute('description', self.description.trim())
            }
        },
        changeIcon: value => {
            self.icon = value ? Icon.create(value) : undefined
            self.saveAttribute('icon', self.icon)
        },
        changeOwners: value => {
            const owners = ActorsValue.create(value)
            if (!owners.isEmpty) {
                self.owners = owners
                self.saveAttribute('owners', self.owners)
            }
        },
        changeEditors: value => {
            const editors = ActorsValue.create(value)
            if (!editors.isEmpty) {
                self.editors = editors
                self.saveAttribute('editors', self.editors)
            }
        },
        changeViewers: value => {
            const viewers = ActorsValue.create(value)
            if (!viewers.isEmpty) {
                self.viewers = viewers
                self.saveAttribute('viewers', self.viewers)
            }
        },
        saveAttribute: function (field, value) {
            self.isChanged = false
            clearTimeout(promises[field])
            promises[field] = setTimeout(() => {
                self.saveAttributeAction(field, value)
            }, 1000)
        },
        saveAttributeAction: flow(function * (field, value) {
            try {
                const tableStatus = yield ApiCalls.checkTableLockStatus(self.id)
                if (tableStatus.success && !tableStatus.result.isLocked) {
                    yield getService('ApiCalls').modifyTable(self.id, { field, [field]: value })
                } else {
                    console.log('Table is locked')
                }
            } catch (e) {
                console.log(`Error while saving table's ${field}:`, e)
                callAlertTaskPageModal(e)
            }
        }),
        delete: flow(function * () {
            try {
                const tableStatus = yield ApiCalls.checkTableLockStatus(self.id)
                if (tableStatus.success && !tableStatus.result.isLocked) {
                    yield getService('ApiCalls').deleteTable(self.id)
                } else {
                    console.log('Table is locked')
                }
            } catch (e) {
                console.log(`Error while deleting table:`, e)
                callAlertTaskPageModal(e)
            }
        }),
        changeTitleColumn: flow(function * (columnId) {
            const column = self.columns.find(column => column.id === columnId)
            try {
                if (column.canBeTitle) {
                    yield self.getInfo()
                    const response = yield ApiCalls.modifyTableColumn(self.id, columnId, {
                        field: 'isTitle',
                        isTitle: true
                    }, self.version)
                    self.changeVersion(response.result.tableVersion)
                    self.columns.filter(c => c.id !== columnId && c.isTitle).forEach(c => c.isTitle = false)
                    column.order = response.result.column.order
                    column.isTitle = true
                    self.reloadRecords()
                }
            } catch (e) {
                console.log(`Error while changing title column:`, e)
                callAlertTaskPageModal(e)
            }
        }),
        changeVersion: version => self.version = version,
        updateColumn: columnData => {
            const column = self.columns.find(column => column.id === columnData.id)
            if (column) {
                applySnapshot(column, prepareColumnSnapshot(columnData))
            }
            column.settings.initialize()
        },
        deleteColumn: flow(function * (columnId) {
            const currentTableVersion = self.version
            try {
                yield self.getInfo()
                if (currentTableVersion !== self.version) {
                    yield self.getRecords()
                }
                if (self.columns.find(c => c.id === columnId)) {
                    const { result } = yield ApiCalls.deleteTableColumn(self.id, columnId, self.version)
                    self.changeVersion(result.tableVersion)
                    self.columns = self.columns.filter(c => c.id !== columnId)
                }
            } catch (e) {
                console.log(`Error while deleting column:`, e)
                callAlertTaskPageModal(e)
            }
        }),
        setSearchString: value => {
            self.searchString = value || ''
            self.filterRecords()

            clearTimeout(reloadTimeOut)
            self.isLoading = true
            reloadTimeOut = setTimeout(() => {
                self.reloadRecords()
            }, 1000)
        },
        filterRecords: () => {
            if (!self.searchString) {
                return
            }
            const filteredRecords = []
            self.records.forEach(record => {
                if (record.values.find(value => {
                    return (value.dataType === STRING_SINGLE_LINE ||
                        value.dataType === STRING_MULTI_LINE ||
                        value.dataType === EMAIL) && value.displayValue.toLowerCase().includes(self.searchString.toLowerCase())
                })) {
                    filteredRecords.push(record)
                }
            })

            self.recordsKeys = filteredRecords.map(record => record.id)
            self.total = filteredRecords.length
        },
        reloadRecords: () => {
            self.getRecords()
        }
    }
})

const STOP_LOADING_OFFSET = '-1'

export const Database = types.model({
    tables: types.map(Table),
    nextOffset: maybe(string)
}).actions(self => {

    let isLoading

    return {
        nextPage: flow(function * (count, page) {
            if (self.nextOffset === STOP_LOADING_OFFSET || isLoading || count * page <= self.tables.size) {
                return
            }

            try {
                isLoading = true
                const response = yield getService('ApiCalls').getTables({ count, nextOffset: self.nextOffset })
                self.nextOffset = response.nextOffset || STOP_LOADING_OFFSET
                response.list.forEach(table => self.tables.put(table))
                isLoading = false
            } catch (e) {
                console.log('Load tables failed', e)
                isLoading = false
                callAlertTaskPageModal(e)
            }
        }),
        createNewTable: flow(function * () {
            try {
                const currentUser = { id: getService('currentUser').id }
                const uniqueNameResponse = yield getService('ApiCalls').getTableUniqueName()
                const model = TableForm.create({
                    name: uniqueNameResponse.result.name,
                    owners: ActorsValue.create({ users: [UserId.create(currentUser)] }),
                    editors: ActorsValue.create({ users: [UserId.create(currentUser)] }),
                    viewers: ActorsValue.create({ users: [UserId.create(currentUser)] }),
                })
                model.addNewColumn(translate('text.database.columnName', {INDEX: 1}))

                yield model.save()
                self.tables.put({ id: model.id, name: model.name })
                getService('$state').go('main.database.table', { tableId: model.id })
            } catch (e) {
                console.log('Create new table failed', e)
                callAlertTaskPageModal(e)
            }
        }),
        insertTableModel: (tableId) => {
            if (!self.tables.has(tableId)) {
                self.tables.put({ id: tableId })
            }
        },
        deleteTable: flow(function * (table) {
            try {
                yield table.delete()
                self.closeTable(table.id)
            } catch (e) {
                console.log('Delete table failed', e)
            }
        }),
        closeTable: tableId => {
            self.tables.delete(tableId)
            getService('$state').go('main.database.index')
        },
        // createDemoTable: flow(function * ({ tableName, columnsCount = 1, recordsCount = 100 }) {
        //     if (tableName && [...self.tables.values()].find(t => t.name === tableName)) {
        //         console.log(`table "${tableName}" is existed`)
        //         return
        //     }
        //
        //     try {
        //         console.log(`Create table "${tableName}" with ${columnsCount} columns and ${recordsCount} records`)
        //         const currentUser = { id: getService('currentUser').id }
        //         const model = TableForm.create({
        //             name: tableName,
        //             owners: ActorsValue.create({ users: [UserId.create(currentUser)] }),
        //             editors: ActorsValue.create({ users: [UserId.create(currentUser)] }),
        //             viewers: ActorsValue.create({ specialRoles: [{ allUsers: true }] }),
        //         })
        //
        //         let columnIndex = 0
        //         while (columnIndex < columnsCount) {
        //             model.addNewColumn(`Column ${columnIndex + 1}`)
        //             columnIndex++
        //         }
        //
        //         yield model.save()
        //         console.log('table is saved')
        //         yield self.nextPage()
        //
        //         const table = self.tables.get(model.id)
        //         yield table.getInfo()
        //         yield table.getRecords()
        //         console.log('table', table)
        //
        //         let recordIndex = 0
        //         while (recordIndex < recordsCount) {
        //             yield getService('ApiCalls').createTableRecord(table.id, {
        //                 values: table.columnsOrdered.map((column, ci) => {
        //                     return {
        //                         columnId: column.id,
        //                         value: { stringValue: ci === 0 ? `${recordIndex + 1}` : `${recordsCount - recordIndex}` }
        //                     }
        //                 })
        //             })
        //             yield sleep(1000)
        //             recordIndex++
        //         }
        //
        //         console.log('demo table created')
        //
        //         self.nextPage()
        //     } catch (e) {
        //         console.log('Create demo table failed', e)
        //     }
        // })
    }
}).views(self => ({
    get tablesOrdered () {
        return sortBy(Array.from(self.tables.values()), (table) => table.name.toLowerCase())
    },
    get hasTablesToLoad () {
        return self.nextOffset !== STOP_LOADING_OFFSET
    }
}))

export const DEFAULT_TABLE_ICON = { id: 'icon-Grid', color: 'rgb(184, 225, 125)' }

function sleep (ms) {
    return new Promise((resolve) =>
        setTimeout(resolve, ms)
    )
}
