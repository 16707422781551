import colors from '../libs/colors';
import config from '../config';

export class ModalAssignController {
    constructor(users, $scope, $timeout, $modalInstance, $translate, $modal, $filter, $translateSanitization, $window,
                ApiCalls, ProcessesSettings, PageSettings, currentUser) {
        'ngInject';
        let inviteInProgress = false;
        let itemsContainer;

        $scope.candidates = [];
        $scope.users = [];
        $scope.currentUser = currentUser;
        $scope.selectedUserIndex = -1;

        const getUsersLength = () => $scope.candidates.length + $scope.users.length;

        const getUserByIndex = (index) => {
            if (index === -1) {
                return undefined;
            }
            if (index > $scope.candidates.length - 1) {
                return $scope.users[index - $scope.candidates.length];
            }
            return $scope.candidates[index];
        };

        const scrollToItem = (index) => {
            const activeElNode = itemsContainer.find('li')[index];
            const activeEl = angular.element(activeElNode);

            if (activeEl.length) {
                const itemPosition = activeEl.position().top;
                const itemHeight = activeEl.height();

                const listScrollTop = itemsContainer.scrollTop();
                const listHeight = itemsContainer.height();

                let newScrollTop;
                if (itemPosition < 0) {
                    newScrollTop = listScrollTop + itemPosition;
                }

                if (itemPosition + itemHeight >= listHeight) {
                    newScrollTop = listScrollTop + (itemPosition - listHeight) + itemHeight;
                }

                if (newScrollTop !== undefined) {
                    itemsContainer.scrollTop(newScrollTop);
                }
            }
        };

        const selectNextUser = () => {
            if ($scope.selectedUserIndex < getUsersLength()) {
                $scope.selectedUserIndex++;
                scrollToItem($scope.selectedUserIndex);
                $scope.$apply();
            }
        };

        const selectPrevUser = () => {
            if ($scope.selectedUserIndex > 0) {
                $scope.selectedUserIndex--;
                scrollToItem($scope.selectedUserIndex);
                $scope.$apply();
            }
        };

        const updateUsers = () => {
            if ($scope.searchText) {
                let actors = $filter('userSearch')(users.actors, $scope.searchText);
                let others = $filter('userSearch')(users.others, $scope.searchText);
                $scope.candidates = $filter('orderBy')(actors, $scope.getUserName);
                $scope.users = $filter('orderBy')(others, $scope.getUserName);
            } else {
                $scope.candidates = $filter('orderBy')(users.actors, $scope.getUserName);
                $scope.users = $filter('orderBy')(users.others, $scope.getUserName);
            }
        };

        const globalKeydownHandler = (event) => {
            switch (event.key) {
                case 'Enter':
                    if ($scope.getSelectedUser() && !inviteInProgress) {
                        $scope.assign();
                    }
                    break;
                case 'ArrowUp':
                    selectPrevUser();
                    break;
                case 'ArrowDown':
                    selectNextUser();
                    break;
                default:
            }
        };

        $scope.getSelectedUser = () => getUserByIndex($scope.selectedUserIndex);

        $scope.userIsActive = (index, withCandidates) => {
            if (withCandidates) {
                return $scope.candidates.length + index === $scope.selectedUserIndex;
            } else {
                return index === $scope.selectedUserIndex;
            }
        };

        $scope.getBtnText = () => {
            const selectedUser = $scope.getSelectedUser();
            if (selectedUser) {
                return [
                    $translate.instant('label.assign'),
                    $translate.instant('label.to'),
                    $scope.getUserName(selectedUser)
                ].join(' ');
            }
            return $translate.instant('label.assign');
        };

        $scope.selectUser = (index, withCandidates) => {
            if (withCandidates) {
                index = $scope.candidates.length + index;
            }
            $scope.selectedUserIndex = index;
        };

        $scope.getColor = index => {
            return colors[index - 1];
        };

        $scope.clearSearch = () => {
            $scope.searchText = '';
        };

        $scope.actionInProgress = false;
        $scope.assign = () => {
            if ($scope.actionInProgress) {
                return;
            }
            $scope.actionInProgress = true;
            let promise;
            if ($scope.isReassign) {
                promise = ApiCalls.reAssignTask($scope.taskId, {id: $scope.getSelectedUser().id}, $scope.additionalQuery);
            } else {
                promise = ApiCalls.assignToOtherTask($scope.taskId, {id: $scope.getSelectedUser().id}, $scope.additionalQuery);
            }
            promise
                .then($modalInstance.close)
                .catch($modalInstance.close)
                .finally(() => $scope.actionInProgress = false);
        };

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        $scope.getUserName = (user) => {
            if (user) {
                const name = user.fullName || user.name;
                return name.trim() ? name : user.email;
            }
            return '';
        };

        $scope.searchTextIsEmail = (text) => {
            return !!(text && config.pattern_emails.test(text));
        };

        $scope.handleKeypress = ($event) => {
            if ($event.key === 'Enter') {
                const isEmail = $scope.searchTextIsEmail($scope.searchText);
                const emptyActors = $scope.candidates.length === 0;
                const emptyUsers = $scope.users.length === 0;
                if (emptyActors && emptyUsers && isEmail) {
                    $event.stopPropagation();
                    inviteInProgress = true;
                    $scope.inviteUser();
                }
            }
        };

        $scope.inviteUser = () => {
            let modal = $modal.open({
                animation: true,
                windowClass: 'invite',
                template: require('../templates/modals/invite.html'),
                controller: 'InviteModalController',
                backdrop: 'static',
                resolve: {
                    groups: () => {
                        return ApiCalls.getActorsGroups();
                    },
                    preloadedState: () => {
                        return [{email: $scope.searchText}];
                    }
                }
            });
            modal.result.then(data => {
                data.result.forEach(user => {
                    $scope.selectUser(0);
                    users.others.push(user);
                    updateUsers();

                    if(!$scope.isDisableToasterPopup) {
                        $translateSanitization.useStrategy(null);
                        PageSettings.toasterData = {
                            iconClass: ' icon-company_groups',
                            text: $translate.instant('label.userInvited', {name: user.fullName || user.email}),
                            timeout: 3000
                        };
                        $translateSanitization.useStrategy(config.sanitize_strategy);
                    }
                });
            }).finally(() => inviteInProgress = false);
        };

        $window.addEventListener('keydown', globalKeydownHandler);

        $scope.$on('$destroy', () => {
            $window.removeEventListener('keydown', globalKeydownHandler);
        });

        $scope.$watch('searchText', updateUsers);

        $timeout(() => {
            $scope.loaded = true;
            itemsContainer = angular.element(document.getElementById('assign-modal-users'));
        }, 300);
    }
}
