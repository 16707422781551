import { helpersRoute } from './helpers.config.js'
import { HelpersController } from './helpers.controller.js'

import './index.less'

let ngModule = angular.module('helpers', [])
    .config(helpersRoute)
    .controller('HelpersController', HelpersController)

export default ngModule
