/* global document */
import { copyToClipboard } from '../../../utils'
import utils from '../../processes/utils'
import adminUtils from '../utils'
import { FILTER_MODES } from '../services/admin-settings'
import colors from '../../../libs/colors'

export class AdminUsersController {
    constructor ($scope, AdminSettings, DateHelper, PageSettings, ApiCalls, AdminApiCalls, $modal, $timeout, $translate,
                DeferredAction, $state, currentUser, $filter, $sce, MomentHelper) {
        'ngInject'

        this.AdminSettings = AdminSettings
        this.PageSettings = PageSettings
        this.$scope = $scope
        this.$translate = $translate
        this.$timeout = $timeout
        this.api = {
            active: AdminApiCalls.getActiveUsers,
            disabled: AdminApiCalls.getDisabledUsers
        }
        this.listStub = Array.from({ length: this.PageSettings.itemsPerPage }, (v, k) => ({ id: k + 1 }))
        this.busyLoading = {}
        this.nextOffsetIds = { active: 0, disabled: 0 }
        this.storage = { active: [], disabled: [] }
        this.actors = { users: [], groups: [], specialRoles: [] }
        this.actorsModel = angular.copy(this.actors)

        this.getGroupsStr = assignee => {
            if (assignee.groups && assignee.groups.length > 3) {
                let count = assignee.groups.length - 3
                let begin = assignee.groups.slice(0, 3).map(group => group.name).join(', ')
                let end = $translate.instant('text.andMoreGroups', { count }, 'messageformat')
                return begin + ' ' + end
            }
            return assignee.groups.map(group => {
                return group.name
            }).join(', ')
        }

        let callAfterSelect = (callback, params) => {
            if (this.showSelect) {
                let promise = this.saveGroups()
                if (promise) {
                    promise.then(() => callback(params))
                } else {
                    callback(params)
                }
            } else {
                callback(params)
            }
        }
        let setActiveUser = user => {
            if (!user.fullName) {
                return
            }
            this.currentIndex = this.users.indexOf(user)
            this.subordinatesList = this.subordinatesListSource.filter(({id}) => id !== user.id)
            if (!this.activeUser || this.activeUser.id !== user.id) {
                this.activeUser = {}

                AdminApiCalls.getUserInfo(user.id).then(data => {
                    this.activeUser = data
                    this.activeUser.isInvited = user.isInvited
                    this.activeUser.groups = data.groups || []
                    this.actorsModel.groups = angular.copy(this.activeUser.groups)
                    this.updateManagers()
                })
            }
            AdminSettings.showList = true
        }
        this.showSettings = user => {
            this.showSubordinatesSelect = false
            callAfterSelect(setActiveUser, user)
        }

        this.getInviteLabel = () => {
            return $translate.instant('label.invitedAtBy', {DATE: MomentHelper.calendarDate(this.activeUser.invitedDate, true)})
            // let utcDate = DateHelper.getUtcFromTimestamp(this.activeUser.invitedDate)
            // let tz = DateHelper.getTZ()
            // let b = moment(utcDate).tz(tz)
            // let a = moment().tz(tz)
            // if (a.dayOfYear() === b.dayOfYear() && a.year() === b.year()) {
            //     return 'invite.at'
            // }
            // if (a.dayOfYear() - b.dayOfYear() < 2 && a.year() === b.year()) {
            //     return $translate.instant('label.invited')
            // }
            // return 'invite.on'
        }

        this.closeSettings = () => {
            AdminSettings.showList = false
            $timeout(() => {
                this.activeUser = null
                this.actorsModel.groups = []
            }, 150)
        }

        let getIdArray = (arr) => {
            if (!arr) {
                return arr
            }
            return arr.map(i => i.id).sort()
        }

        let updateStorage = user => {
            let activeUser = this.storage.active.find(u => u.id === user.id)
            if (activeUser) {
                activeUser.groups = user.groups || []
            }
        }

        // Save groups on enter / onblur selection control
        this.saveGroups = () => {
            $timeout(() => {
                this.showSelect = false
            })
            if (!angular.equals(getIdArray(this.activeUser.groups), getIdArray(this.actorsModel.groups))) {
                this.activeUser.groups = angular.copy(this.actorsModel.groups)
                let backup = []
                if (this.users[this.currentIndex]) {
                    backup = this.users[this.currentIndex].groups || []
                }

                let hashTable = new Map()
                backup.forEach(g => hashTable.set(g.id, -1))
                let postData = {
                    groups: this.activeUser.groups.map(g => ({ id: g.id })),
                    version: this.activeUser.version
                }

                return AdminApiCalls.changeUserGroups(this.activeUser.id, postData).then(resp => {
                    if (resp.success) {
                        this.activeUser = Object.assign({}, resp.result, { isInvited: this.activeUser.isInvited })
                        // Calculate usersCounts for actors groups
                        updateStorage(resp.result)
                        let resUser = this.users.find(u => u.id === this.activeUser.id)
                        if (resUser) {
                            resUser.groups = this.activeUser.groups || []
                        }

                        if (resp.result.groups) {
                            resp.result.groups.forEach(group => {
                                if (hashTable.has(group.id)) {
                                    hashTable.set(group.id, 0)
                                } else {
                                    hashTable.set(group.id, 1)
                                }
                            })
                        }
                        hashTable.forEach((value, key) => {
                            let currGroup = this.actors.groups.find(group => {
                                return group.id === key
                            })
                            if (currGroup) {
                                currGroup.usersCount = currGroup.usersCount + value
                            }
                        })
                    }
                })
            }
        }

        this.getGroupsCount = () => {
            if (this.activeUser && this.activeUser.groups) {
                return angular.toJson({ COUNT: this.activeUser.groups.length })
            }
            return angular.toJson({ COUNT: 0 })
        }

        this.onCreateGroupError = (errorMsg, errorResp) => {
            PageSettings.createGroupHandlerModal(errorMsg, errorResp)
        }

        // Disable user
        let disableClicked
        let disableUserFn = (userId, version) => {
            const user = this.users.find(u => u.id === userId)
            AdminApiCalls.disableUser(userId, { version }).then(() => {
                this.storage.active = this.storage.active.filter(u => u.id !== userId)
                this.storage.disabled = this.storage.disabled.filter(u => u.id !== userId)
                this.storage.disabled.push(user)
                this.updateVisualUsers(this.storage[this.getActiveSectionId()])
                this.triggerListChange()
                if (this.activeUser && userId === this.activeUser.id) {
                    this.closeSettings()
                }
            })
        }

        this.disableUser = () => {
            if (!disableClicked) {
                disableClicked = true

                const { id, version } = this.activeUser
                AdminApiCalls.getUserWarnings(id).then(resp => {
                    if (resp.success) {
                        if (resp.warnings.tasks || resp.warnings.processes || resp.warnings.templates) {
                            PageSettings.openWarningsModal(resp.warnings, 'users').result.then(() => disableUserFn(id, version))
                        } else {
                            disableUserFn(id, version)
                        }
                    }
                }).finally(() => {
                    disableClicked = false
                })
            }
        }

        this.toggleRole = (prefix) => {
            if (this.activeUser) {
                const { id, firstName, version } = this.activeUser
                let modalScope = $scope.$new()
                modalScope.title = $translate.instant(`manageRole.title.${prefix}`, { name: firstName })
                modalScope.text = $translate.instant(`manageRole.text.${prefix}`)
                modalScope.confirmText = $translate.instant(`manageRole.btn.${prefix}`)
                modalScope.cancelText = $translate.instant('label.cancel')

                let modalInstance = $modal.open({
                    animation: true,
                    windowClass: 'confirm-alt-modal',
                    template: require('../../../templates/modals/confirm-alt.html'),
                    controller: 'ModalInstanceController',
                    scope: modalScope
                })

                modalInstance.result.then(() => {
                    let promise
                    if (prefix === 'admin') {
                        promise = AdminApiCalls.setAdmin(id, { version })
                    } else {
                        promise = AdminApiCalls.rmAdmin(id, { version })
                    }
                    promise.then((data) => {
                        if (data.success && this.activeUser && this.activeUser.id === id) {
                            this.activeUser = Object.assign({}, data.result, { isInvited: this.activeUser.isInvited })
                        }
                    })
                }).finally(() => {
                    modalScope.$destroy()
                })
            }
        }

        // Enable user
        let enableUserFn = () => {
            const { id, version } = this.activeUser
            const user = this.users.find(u => u.id === id)
            AdminApiCalls.enableUser(id, { version }).then(() => {
                this.storage.active = this.storage.active.filter(u => u.id !== id)
                this.storage.active.push(user)
                this.storage.disabled = this.storage.disabled.filter(u => u.id !== id)
                this.updateVisualUsers(this.storage[this.getActiveSectionId()])
                this.triggerListChange()
                if (this.activeUser && id === this.activeUser.id) {
                    this.closeSettings()
                }
            })
        }
        this.enableUser = () => {
            callAfterSelect(enableUserFn)
        }

        // Resend invite
        let resendInvitation = () => {
            const { id, version, isInvited } = this.activeUser
            AdminApiCalls.resendInvite(id, { version })
                .then(resp => {
                    if (resp.success) {
                        this.storage.active = this.storage.active.map(user => {
                            if (user.id === id) {
                                // Update data for selected user
                                this.activeUser = Object.assign(this.activeUser, resp.result, { isInvited })
                                return Object.assign({}, resp.result, { isInvited })
                            }
                            return user
                        })
                        this.updateVisualUsers(this.storage[this.getActiveSectionId()])
                        this.triggerListChange()
                    }
                })
        }
        this.resendInvite = () => {
            callAfterSelect(resendInvitation)
        }

        // call change password modal
        let callPasswordModal = () => {
            if (!this.isModalMode) {
                this.isModalMode = true
                let scope = $scope.$new()
                scope.user = this.activeUser
                let modal = $modal.open({
                    animation: true,
                    windowClass: 'password-modal',
                    template: require('../../../templates/modals/password.html'),
                    controller: 'PasswordModalController',
                    backdrop: 'static',
                    scope: scope
                })
                modal.result.then(resp => {
                    if (resp && resp.success) {
                        this.activeUser = resp.result
                    }
                }).finally(() => {
                    scope.$destroy()
                })
                modal.opened.finally(() => {
                    this.isModalMode = false
                })
            }
        }
        this.changePassword = () => {
            if (this.activeUser.isAdmin && currentUser.id === this.activeUser.id) {
                $state.go('main.profile.password')
            } else if (this.activeUser.canChangePassword) {
                callAfterSelect(callPasswordModal)
            }
        }

        // Initialize
        $scope.$watch(() => AdminSettings.activeFilterMode, () => this.firstPage(), true)

        $scope.$on(DeferredAction.GroupDelete.CONST.COMPLETED, (event, deletedGroup) => {
            const groupFilterFn = group => group.id !== deletedGroup.id
            this.actors.groups = this.actors.groups.filter(groupFilterFn)
            this.users = this.users.map(user => {
                user.groups = user.groups.filter(groupFilterFn)
                return user
            })
        })

        $scope.$on('invite', (event, invitedUsers = []) => {
            invitedUsers.forEach(user => {
                this.storage.active.push(user)
            })
            if (this.getActiveSectionId() === FILTER_MODES.ACTIVE) {
                this.updateVisualUsers(this.storage.active)
            }
        })

        $scope.$on('content:search', (event, searchString) => {
            this.searchString = searchString
            this.firstPage()
        })

        ApiCalls.getActorsGroups().then(data => this.actors.groups = data)
        ApiCalls.getActorsUsers().then(data => {
            this.subordinatesListSource = data
            this.managersList = data
        })
        AdminSettings.showList = false
        this.firstPage()

        const getColor = (index) => {
            return colors[index - 1]
        }

        const getIcon = (user) => {
            let icon
            let background = 'transparent'
            let deleted = ''
            if (user.avatarUrl) {
                icon = `<img src="${user.avatarUrl + '_24'}" alt="${user.fullName.trim() ? user.fullName : user.email}"/>`
            } else {
                let initials = $filter('initials')(user.fullName.trim() ? user.fullName : user.email)
                icon = `<span>${initials}</span>`
                background = getColor(user.colorIndex)
            }

            return $sce.trustAsHtml(`
                <div class="thumbnail" style="background-color: ${background}">
                    ${icon}
                    ${deleted}
                </div>
            `)
        }

        this.updateManagers = (searchText) => {
            const filteredItems = searchText
                ? $filter('search')(this.managersList, searchText)
                : this.managersList.slice()

            const mappedItems = filteredItems.filter(u => this.activeUser && u.id !== this.activeUser.id).map(user => ({
                id: user.id,
                name: user.fullName.trim() ? user.fullName : user.email,
                selected: user.selected || (this.activeUser && this.activeUser.directManager && this.activeUser.directManager.id === user.id),
                icon: () => getIcon(user),
                isDisabled: user.isDisabled,
                // isHidden: user.isHidden
            }))

            this.managers = $filter('orderBy')(mappedItems, 'name')
        }

        this.saveUserManager = () => {
            if (this.activeUser) {
                const { id, version, directManager } = this.activeUser
                const params = {
                    version,
                    directManager: directManager ? { id: directManager.id } : {}
                }
                AdminApiCalls.changeUserManager(id, params).then(data => {
                    if (data.success && this.activeUser && this.activeUser.id === data.result.id) {
                        this.activeUser.version = data.result.version
                    }
                })
            }
        }

        this.saveSubordinates = () => {
            AdminApiCalls.changeUserSubordinates(this.activeUser.id, {
                version: this.activeUser.version,
                subordinates: this.activeUser.subordinates.map(({id}) => ({id}))
            }).then(data => {
                if (data.success && this.activeUser && this.activeUser.id === data.result.id) {
                    this.activeUser.version = data.result.version
                }
            })
        }
    }

    scrollToTop () {
        let container = document.querySelector('.admin__main__content')
        if (container) {
            container.scrollTop = 0
        }
    }

    copyInviteLink () {
        if (this.activeUser && this.activeUser.inviteLink) {
            if (copyToClipboard(this.activeUser.inviteLink)) {
                this.PageSettings.toasterData = {
                    iconClass: 'icon-attachment_link',
                    text: this.$translate.instant('copyInviteLink.toaster.text'),
                    timeout: 3000
                }
            }
        }
    }

    isSelectedUser (user) {
        return this.activeUser && user && this.activeUser.id === user.id
    }

    isActiveUser () {
        return this.getActiveSectionId() !== FILTER_MODES.DISABLED && !this.activeUser.isInvited
    }

    isInvitedUser () {
        return this.getActiveSectionId() !== FILTER_MODES.DISABLED && this.activeUser.isInvited
    }

    inviteLinkExpired () {
        return this.activeUser.canResendInvite && !this.activeUser.inviteLink
    }

    getActiveSectionId () {
        return this.AdminSettings.activeFilterMode.id
    }

    isSectionLoading () {
        return this.busyLoading[this.getActiveSectionId()]
    }

    getUsers () {
        const section = this.getActiveSectionId()
        return this.nextOffsetIds[section] === 0 && !this.users.length
            ? this.listStub
            : this.users
    }

    updateVisualUsers (users) {
        this.users = users.slice().sort(adminUtils.usersSort)
    }

    getApiParams (section) {
        const params = {
            count: this.PageSettings.itemsPerPage
        }

        if (this.searchString) {
            params.searchString = this.searchString
        }

        if (this.nextOffsetIds[section] && this.nextOffsetIds[section] !== -1) {
            params.nextOffset = this.nextOffsetIds[section]
        }

        return params
    }

    nextPage () {
        const section = this.getActiveSectionId()
        if (this.nextOffsetIds[section] === -1 || this.isSectionLoading()) {
            return
        }

        const params = this.getApiParams(section)
        this.busyLoading[section] = true

        this.api[section](params).then(data => {
            this.nextOffsetIds[section] = data.nextOffset || -1
            this.serverError = false
            if (data.list && data.list.length) {
                utils.mergeToArray(this.storage[section], data.list, true)
                this.updateVisualUsers(this.storage[section])
                this.triggerListChange()
            }
        }, () => {
            this.serverError = true
        }).finally(() => {
            this.$timeout(() => this.busyLoading[section] = false)
        })
    }

    firstPage () {
        this.closeSettings()
        this.scrollToTop()

        const section = this.getActiveSectionId()
        this.nextOffsetIds[section] = 0
        this.storage[section] = []
        this.updateVisualUsers([])

        this.nextPage()
    }

    triggerListChange () {
        this.$timeout(() => this.$scope.$emit(`list:changed`), 100)
    }

    getGroupsNames (user) {
        if (!user || !user.groups) {
            return
        }
        return user.groups.map(g => g.name).sort().join(', ')
    }

    getActiveUserIconName () {
        const { firstName, lastName, email } = this.activeUser
        if (firstName) {
            return [firstName, lastName].join(' ')
        }
        return email
    }

    emptyStateForActiveUsersVisible () {
        return !this.serverError
            && this.getUsers().length < 2
            && !this.searchString
            && this.AdminSettings.activeFilterMode.id === FILTER_MODES.ACTIVE
    }

    notFoundStateForActiveUsersVisible () {
        return !this.serverError
            && !this.getUsers().length
            && this.searchString
            && this.AdminSettings.activeFilterMode.id === FILTER_MODES.ACTIVE
    }

    emptyStateForDisabledUsersVisible () {
        return !this.serverError
            && !this.getUsers().length
            && !this.searchString
            && this.AdminSettings.activeFilterMode.id === FILTER_MODES.DISABLED
    }

    notFoundStateForDisabledUsersVisible () {
        return !this.serverError
            && !this.getUsers().length
            && this.searchString
            && this.AdminSettings.activeFilterMode.id === FILTER_MODES.DISABLED
    }

    switchToActiveUsers () {
        this.AdminSettings.activeFilterMode = this.AdminSettings.filterModes.find(m => m.id === FILTER_MODES.ACTIVE)
    }

    switchToDisabledUsers () {
        this.AdminSettings.activeFilterMode = this.AdminSettings.filterModes.find(m => m.id === FILTER_MODES.DISABLED)
    }

    selectManager (item) {
        this.activeUser.directManager = this.managersList.find(u => item && u.id === item.id)
        this.managers.forEach(u => u.selected = (item && u.id === item.id))
        this.saveUserManager()
        this.closeManagerSelect()
    }

    closeManagerSelect () {
        this.showManagerSelect = false
    }

    closeSubordinatesSelect () {
        this.showSubordinatesSelect = false
        this.saveSubordinates()
        this.$scope.$apply()
    }
}
