import utils from '../../processes/utils';

export function tasksMainHeader() {
    return {
        restrict: 'E',
        replace: true,
        template: require('./tasks-main-header.html'),
        controller: ($scope, $rootScope, currentUser, ApiCalls, HomeSettings, PageSettings, $translate, $document) => {
            'ngInject';
            let addClick;
            $scope.newTask = {name: '', actors: {users: [], groups: [], specialRoles: []}};

            /*
             *  AD-HOC TASK
             */
            let handleClickTask = (event) => {
                if (event && event.which === 13) {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    if ((event.target && event.target.nodeName === 'BUTTON') || addClick) {
                        return;
                    }
                    $scope.clickedAddTask = true;
                    $scope.$applyAsync();

                    if (!$scope.newTask.name || (!HomeSettings.mainMode && utils.checkActorsIsEmpty($scope.newTask.actors))) {
                        return;
                    }
                    if (HomeSettings.mainMode) {
                        $scope.newTask.actors.users.push(currentUser);
                    }
                    addClick = true;
                    ApiCalls.addTask($scope.newTask).finally(() => {
                        $scope.closeAdHocTask();
                        addClick = false;
                    });
                }
            };
            $scope.onErrorAssignmentCandidates = (errorMsg) => {
                $scope.assignmentCandidatesError = errorMsg;
            };
            $scope.updateAdHocTitle = () => {
                if ($scope.newTask.name) {
                    $scope.newTask.name = $scope.newTask.name.trim();
                }
            };
            $scope.openAdHocTask = () => {
                $scope.adHocTaskOpened = true;
                $scope.clickedAddTask = null;
                $document.on('keyup', handleClickTask);
            };
            $scope.closeAdHocTask = () => {
                $scope.adHocTaskOpened = false;
                $document.off('keyup', handleClickTask);
                $scope.newTask = {name: '', actors: {users: [], groups: [], specialRoles: []}};
                $scope.clickedAddTask = null;
            };
            $scope.saveAdHocTask = ($event) => {
                if ($event && $event.which !== 13) {
                    return;
                }
                handleClickTask($event || {which: 13});
            };
            $scope.checkAdHocField = (form, fieldName) => {
                if (!form || !form[fieldName]) {
                    return;
                }
                if (form[fieldName].$invalid) {
                    if (form[fieldName].$error.minlength) {
                        return $translate.instant('validation.minLength', {value: 3});
                    } else if (form[fieldName].$error.required) {
                        return $translate.instant('validation.required');
                    }
                    return null;
                }
            };
            $scope.checkActorsIsEmpty = actors => utils.checkActorsIsEmpty(actors);
            $scope.focusAdHocTitle = () => $rootScope.$broadcast('selectize:open');
        }
    };
}
