import utils, { checkShowTotal, fieldValueToString } from '../modules/processes/utils'
import { copyToClipboard, uid4 } from '../utils'
import dataModelUtils, {
    calculateDataForm,
    getErrorSourceFieldName
} from '../modules/templates/services/data-model-utils'
import { getFormattedMultiChoiceValue, getFormattedRadioButtonValue } from '../modules/database/models/database-utils'
import { AGGREGATION, FILES_LIST, FORMULA, MONEY, STRING_MULTI_LINE, YES_NO } from '../services/data-types'

const FIELD_DISABLE_DELAY = 1500
const FIELD_SAVED_STATUS_DELAY = 1000

export function processViewDataModel ($modal, $translate, $filter, $timeout, $q, DateHelper, PageSettings, ApiCalls, $state, $stateParams,
    DevicesDetector, ProcessesSettings, currentUser) {
    'ngInject'
    const getFieldValueAsString = field => fieldValueToString(field)

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dataModel: '=ngModel',
            processId: '=?',
            processStartedDate: '=?'
        },
        template: require('../templates/process-view-data-model.html'),
        link: ($scope) => {
            $scope.checkStringType = dataModelUtils.checkStringType
            $scope.checkEmailType = dataModelUtils.checkEmailType
            $scope.checkMoneyType = dataModelUtils.checkMoneyType
            $scope.checkDateType = dataModelUtils.checkDateType
            $scope.checkNumberType = dataModelUtils.checkNumberType
            $scope.checkMultiselectType = dataModelUtils.checkMultiselectType
            $scope.checkRadioType = dataModelUtils.checkRadioType
            $scope.checkFilesType = dataModelUtils.checkFilesType
            $scope.checkYesNoType = dataModelUtils.checkYesNoType
            $scope.checkUserFieldType = dataModelUtils.checkUserFieldType
            $scope.checkFormulaFieldType = dataModelUtils.checkFormulaFieldType
            $scope.getNumberWidth = dataModelUtils.getNumberWidth
            $scope.checkFieldError = (field, isProcessStartMode, isTableSectionRow) => {
                let error = dataModelUtils.checkFieldError(field, isProcessStartMode, isTableSectionRow)
                return error && error !== 'required' ? error : ''
            }
            $scope.getTotalOfColumn = (section, fieldIndex) => utils.getTotalOfColumn($scope.dataModel.list, section, fieldIndex)
            $scope.updateValue = (field, useDefaultValue, useProcessStartedDate) => {
                if (!field.value) {
                    field.value = {}
                }
                const processStartedDate = useProcessStartedDate ? $scope.processStartedDate : undefined
                dataModelUtils.updateValue(field, useDefaultValue, DateHelper.getTZ(), processStartedDate)

                const { defaultValue, options } = field.name

                if (dataModelUtils.checkUserFieldType(field) && useDefaultValue && !field.value.userValue && defaultValue) {
                    if (defaultValue.specialValue && defaultValue.specialValue === 'PROCESS_STARTER') {
                        const processStarter = { id: currentUser.id, fullName: currentUser.fullName }
                        if (options.allUsers || options.allowedUsers.find(u => u.id === processStarter.id)) {
                            field.value.userValue = processStarter
                        }
                    } else if (defaultValue.specialValue && defaultValue.specialValue === 'PROCESS_STARTER_DIRECT_MANAGER') {
                        ApiCalls.getDirectManager().then(({ directManager }) => {
                            if (directManager && (options.allUsers || options.allowedUsers.find(u => u.id === directManager.id))) {
                                field.value.userValue = directManager
                            }
                        })
                    }
                }
            }
            $scope.DevicesDetector = DevicesDetector

            $scope.getHints = (field) => dataModelUtils.getFieldHints(field, $translate, $filter)

            $scope.getValue = (field, key) => {
                let fieldData = field.name || field
                if (fieldData.options && fieldData.options[key] != null) {
                    return angular.toJson({ value: fieldData.options[key] })
                }
            }

            $scope.checkShowTotal = checkShowTotal

            $scope.mapTableSectionField = (field, column) => {
                return Object.assign(field, { name: column.name })
            }

            $scope.fieldCanBeCopy = (field) => {
                return field.name.dataType !== FILES_LIST && field.name.dataType !== YES_NO && $scope.checkFieldValue(field)
            }

            $scope.copyFieldValue = (field) => {
                const string = getFieldValueAsString(field)

                if (string && copyToClipboard(string)) {
                    PageSettings.toasterData = {
                        iconClass: 'icon-template_duplicate_roundless',
                        text: $translate.instant('text.toaster.copyFieldValue', { fieldName: field.name.label }),
                        timeout: 3000
                    }
                }
            }

            $scope.fieldCanBeEdited = (field) => {
                const { name = {}, settings = {} } = field
                return name.dataType !== FORMULA && (settings.isRequiredToFill || settings.isEditable)
            }

            $scope.editModeOn = (field) => {
                field.editMode = true
                field.value = field.value || {}
                if (field.name.dataType === MONEY) {
                    field.value.moneyValue = Object.assign(
                        {},
                        {
                            currency: field.name.options.currency.default.id,
                            currencyInfo: field.name.options.currency.default
                        },
                        field.value.moneyValue
                    )
                }
                $scope.dumpField(field)

                const controlWrapper = document.getElementById($scope.getFieldWrapperId(field))
                field.clickOutsideHandler = (event) => {
                    const targetIsFileInput = event.target.tagName === 'INPUT' && event.target.type === 'file'
                    $timeout(() => {
                        if (!controlWrapper.contains(event.target) && !targetIsFileInput) {
                            $scope.editModeOff(field)
                        }
                    }, 250)
                }

                setTimeout(() => {
                    const input = document.getElementById(field.name.id)
                    if (input) {
                        input.focus()
                    }
                    document.addEventListener('click', field.clickOutsideHandler)
                }, 50)
            }

            $scope.editModeOff = field => {
                if (!$scope.checkFieldError(field) && !field.uploading) {
                    $timeout(() => {
                        field.editMode = false
                        document.removeEventListener('click', field.clickOutsideHandler)
                    }, 50)
                }
            }

            $scope.dumpField = field => {
                field.$originalValue = getFieldValueAsString(field)
            }

            $scope.dumpFileField = field => {
                $scope.editModeOff(field)
                $scope.dumpField(field)
            }

            $scope.openFilesPreview = (field) => {
                field.preview = true
                field.onPreviewClose = () => {
                    field.preview = false
                }
            }

            const saveFieldAction = (field) => {
                if ($scope.checkFieldError(field) || field.$originalValue === getFieldValueAsString(field)) {
                    return $q.reject()
                }

                const putData = dataModelUtils.getFieldValueForSaving(field)

                return ApiCalls.updateProcessField($scope.processId, field.name.id, putData)
            }

            $scope.saveFieldQueue = (field, disableEditMode = true) => {
                if ($scope.checkFieldError(field)) {
                    return
                }

                if (disableEditMode) {
                    $scope.editModeOff(field)
                }

                let action

                action = () => {
                    field.saving = true
                    field.isChanged = false
                    saveFieldAction(field)
                        .then(() => {
                            if (field.isChanged) {
                                action()
                            } else {
                                field.saved = true
                                $timeout(() => field.saved = false, FIELD_SAVED_STATUS_DELAY)
                            }
                        })
                        .catch(ProcessesSettings.callAlertTaskPageModal)
                        .finally(() => {
                            field.saving = false
                            $scope.dumpField(field)
                        })
                }

                if (field.saving) {
                    field.isChanged = true
                } else {
                    action()
                }
            }

            $scope.getFormulaCalculationError = field => {
                const errorSourceFieldName = getErrorSourceFieldName(field, $scope.dataModel)
                if (errorSourceFieldName) {
                    return $translate.instant('validation.calculation.divisionByZero.withFieldId', { fieldId: errorSourceFieldName })
                }
                return 'validation.calculation.divisionByZero'
            }

            const disableWithDelayTimers = {}
            $scope.stopFieldDisableTimer = (field) => {
                const { id } = field.name
                if (disableWithDelayTimers[id]) {
                    $timeout.cancel(disableWithDelayTimers[id])
                }
            }
            $scope.saveFieldAndDisableWithDelay = (field) => {
                field.isChanged = true
                if ($scope.checkFieldError(field)) {
                    return
                }
                const { id } = field.name
                $scope.saveFieldQueue(field, false)
                $scope.stopFieldDisableTimer(field)
                disableWithDelayTimers[id] = $timeout(() => {
                    $scope.editModeOff(field)
                }, FIELD_DISABLE_DELAY)
            }

            $scope.fieldKeyHandler = (event, field) => {
                const { dataType } = field.name
                if (dataType === STRING_MULTI_LINE) {
                    if (event && event.key === 'Enter' && event.ctrlKey && !$scope.checkFieldError(field)) {
                        event.preventDefault()
                        $scope.saveFieldQueue(field)
                    }
                } else if (event && event.key === 'Enter' && !$scope.checkFieldError(field)) {
                    event.preventDefault()
                    $scope.saveFieldQueue(field)
                }
            }

            $scope.checkFieldValue = field => getFieldValueAsString(field).length > 0

            $scope.checkTableIsEditable = (section) => {
                return section.columns.find(c => !c.settings || c.settings.isEditable)
            }

            $scope.newRow = ($event, section) => {
                if ($event) {
                    $event.stopPropagation()
                }
                $state.go('main.processView.dataForm.newRow', { sectionId: section.id })
            }

            $scope.editRow = ($event, section, row) => {
                if ($event) {
                    $event.stopPropagation()
                }
                $state.go('main.processView.dataForm.editRow', { sectionId: section.id, rowIndex: row.rowIndex })
            }

            let modalInstance

            const editDataModelTableRow = (section, row) => {
                if (!$scope.checkTableIsEditable(section)) {
                    return
                }

                const dataModelCopy = angular.copy($scope.dataModel)
                const modalScope = $scope.$new()
                modalScope.section = dataModelCopy.list.find(s => s.section.id === section.id)?.section
                modalScope.processId = $scope.processId

                if (row) {
                    // Edit existed row case
                    modalScope.row = angular.copy(modalScope.section.rows.find(r => r.rowIndex === row.rowIndex))
                    modalScope.section.columns.forEach(c => {
                        let valueT = modalScope.row.values.find(v => v.columnId === c.name.id)
                        if (valueT && valueT.value && !valueT.value.noValue) {
                            c.value = valueT.value
                        }
                    })
                    row.editMode = true
                    modalScope.$watch(() => row.isDeleted, (isDeleted) => {
                        if (isDeleted) {
                            modalScope.isNew = true
                            modalScope.isDeleted = true
                        }
                    })
                } else {
                    // Add new row case
                    modalScope.isNew = true
                    modalScope.section.columns.forEach(c => {
                        c.value = c.value || {}
                    })
                }
                modalScope.isIgnoreSaveRow = $scope.isDisableSendSaveRequest
                modalScope.onChangeSavingState = isSaving => {
                    $scope.isSavingRow = isSaving
                }

                if (!modalScope.section.rows) {
                    modalScope.section.rows = []
                }

                modalScope.calculateFormulaForField = fieldId => {
                    const realColumnsValues = modalScope.section.columns.map(c => ({
                        columnId: c.name.id,
                        value: c.name.dataType !== FORMULA ? c.value : {}
                    }))
                    const existedRow = modalScope.section.rows.find(r => modalScope.row && r.rowIndex === modalScope.row.rowIndex)
                    if (!existedRow) {
                        const newRow = { rowIndex: uid4(), values: realColumnsValues }
                        modalScope.section.rows.push(newRow)
                        modalScope.row = newRow
                    } else {
                        existedRow.values = realColumnsValues
                        modalScope.row = existedRow
                    }
                    calculateDataForm(dataModelCopy, fieldId, modalScope.row.rowIndex)
                    modalScope.section.columns.forEach(c => {
                        if (c.name.dataType === FORMULA) {
                            const valueT = modalScope.row.values.find(v => v.columnId === c.name.id)
                            if (valueT && valueT.value && !valueT.value.noValue && Object.keys(valueT.value).length) {
                                c.value = valueT.value
                            }
                        }
                    })
                }

                modalScope.section.columns.forEach(c => {
                    $scope.updateValue(c, modalScope.isNew, true)
                    if (modalScope.isNew) {
                        modalScope.calculateFormulaForField(c.name.id)
                    }
                })

                modalInstance = $modal.open({
                    animation: true,
                    template: require('../templates/modals/data-model-table-row.html'),
                    controller: 'DataModelTableRowController',
                    windowClass: 'data-model-table-row-modal',
                    scope: modalScope
                })
                modalInstance.result.then(savedRow => {
                    const { values, ...rowData } = savedRow
                    const realValues = values.filter(({ columnId }) => section.fieldsWithValues.find(field => field.name.id === columnId && field.name.dataType !== AGGREGATION))
                    if (row) {
                        // Edit existed row case
                        Object.assign(row, rowData, { values: realValues })
                    } else {
                        // Add new row case
                        section.rows = [...(section.rows || []), { ...rowData, values: realValues }]
                    }
                })
                    .catch(ProcessesSettings.callAlertTaskPageModal)
                    .finally(() => {
                        modalScope.$destroy()
                        if (row) {
                            row.editMode = false
                        }
                        $state.go('main.processView.dataForm')
                    })
            }

            let isRowDeletion = false
            $scope.deleteRow = (section, rowIndex, event) => {
                if (isRowDeletion) {
                    return
                }
                if (event) {
                    event.stopPropagation()
                }
                const deleteFromList = () => {
                    let item = $scope.dataModel.list.find(i => i.section.id === section.id)
                    if (item && item.section && item.section.rows) {
                        item.section.rows = item.section.rows.filter(r => r.rowIndex !== rowIndex)
                    }
                }

                isRowDeletion = true
                let modalInstance = PageSettings.deleteConfirmation('tableSection')
                modalInstance.result.then(() => {
                    $scope.isSavingRow = true
                    ApiCalls.deleteProcessDataModelTableRow($scope.processId, section.id, rowIndex)
                        .then(() => deleteFromList())
                        .catch(ProcessesSettings.callAlertTaskPageModal)
                        .finally(() => {
                            $scope.isSavingRow = false
                            return isRowDeletion = false
                        })
                }).catch(() => {
                    isRowDeletion = false
                })
            }

            $scope.expand = (section, event) => {
                if (event) {
                    event.stopPropagation()
                }
                $state.go('main.processView.dataForm.tableView', { sectionId: section.id })
            }

            const expandTableSection = (section) => {
                let modalScope = $scope.$new()
                modalScope.section = section
                modalScope.isProcessView = true
                let modalInstance = $modal.open({
                    animation: true,
                    template: require('../templates/modals/data-model-table-full-view.html'),
                    controller: 'ModalInstanceController',
                    windowClass: 'data-model-table-full-view',
                    scope: modalScope
                })
                modalInstance.result.finally(() => {
                    modalScope.$destroy()
                    $state.go('main.processView.dataForm')
                })
            }

            $scope.fieldIsRequired = () => {
                return false
            }

            $scope.getColumnClasses = column => {
                if (column.name.dataType === FORMULA) {
                    return [FORMULA, column.name.options.format.dataType]
                }
                return column.name.dataType
            }

            $scope.getFieldWrapperId = (field) => {
                return `field-${field.name.id}`
            }

            $scope.formatDropdownFieldValue = field => {
                return getFormattedRadioButtonValue(field.value.radioButtonValue)
            }

            $scope.formatMultiselectFieldValue = field => {
                return getFormattedMultiChoiceValue(field.value.multiChoiceValue).join(', ')
            }

            let activeScenario

            const runScenario = (scenario, params = {}) => {
                if (scenario === activeScenario) return
                if (modalInstance) {
                    modalInstance.close(false)
                }

                activeScenario = scenario

                if (scenario === 'NEW_ROW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    if (section) {
                        return editDataModelTableRow(section)
                    }
                    $state.go('main.processView.dataForm')
                }

                if (scenario === 'EDIT_ROW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    const row = section ? section.rows.find(r => Number(r.rowIndex) === Number(params.rowIndex)) : undefined
                    if (section && row) {
                        return editDataModelTableRow(section, row)
                    }
                    $state.go('main.processView.dataForm')
                }

                if (scenario === 'TABLE_VIEW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    if (section) {
                        return expandTableSection(section)
                    }
                    $state.go('main.processView.dataForm')
                }
            }

            runScenario($state.current.data.scenario, $stateParams)

            $scope.$on('$stateChangeSuccess', (event, state, params) => {
                runScenario(state.data.scenario, params)
            })
        }
    }
}
