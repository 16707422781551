import config from '../../../config'
import { asSelectOption } from '../../reports/store/utils'

const COMPANY_LOGO_RATIO = 120 / 40

export class AdminCompanyController {
    constructor ($scope, $modal, $translate, $timeout, timezones, allCurrencies, currentUser,
        AdminApiCalls, CompanyCurrenciesService, FormErrors, PageSettings, ServerConfig) {
        'ngInject'
        let timeoutId
        this.timezones = timezones
        this.languages = ServerConfig.languages.map(({ code, name }) => asSelectOption({ id: code, name }))
        this.setCompanyLanguage = ({ value }) => {
            const selectedLanguage = ServerConfig.languages.find(l => l.code === value) || ServerConfig.languages.find(l => l.code === 'en')
            this.companyLanguage = asSelectOption({ id: selectedLanguage.code, ...selectedLanguage })
        }

        const init = company => {
            this.company = company
            this.currencies = allCurrencies
            this.pattern = config.pattern

            this.defaultCurrency = allCurrencies.find(c => c.id === company.defaultCurrency)
            this.availableCurrencies = this.company.availableCurrencies
                ? this.company.availableCurrencies.map(id => allCurrencies.find(c => c.id === id))
                : []
            this.setCompanyLanguage({ value: this.company.lang })
        }

        const updateCompany = (companyData) => {
            this.formHint = $translate.instant('text.companyHasBeenUpdated')
            if (timeoutId) {
                $timeout.cancel(timeoutId)
            }
            timeoutId = $timeout(() => this.formHint = null, config.errorDelay)
            delete this.company.logoUrl
            this.company = companyData
            ServerConfig.defaultTimeZone = companyData.timeZoneId
            this.setCompanyLanguage({ value: this.company.lang })
            Object.assign(currentUser, { companyName: this.company.name, companyLogoUrl: this.company.logoUrl || '' })
            CompanyCurrenciesService.reload()
        }

        const updateTemplates = (updateInput, isChecked) => {
            AdminApiCalls.updateCompanyInfo(updateInput, isChecked ? 1 : 2).then(updateCompany)
        }

        this.isPreloading = true
        AdminApiCalls.getCompanyInfo()
            .then(data => init(data))
            .finally(() => this.isPreloading = false)

        this.checkField = fieldName => {
            if (!$scope.companyForm || !$scope.companyForm[fieldName]) {
                return
            }
            return $scope.companyForm[fieldName].$invalid && $scope.companyForm[fieldName].$dirty
        }

        this.fieldError = fieldName => {
            return $scope.companyForm && FormErrors($scope.companyForm, fieldName)
        }

        this.formSubmit = () => {
            let form = $scope.companyForm
            if (form.$valid) {
                this.serverErrorData = null
                let updateInput = {
                    name: this.company.name,
                    version: this.company.version,
                    defaultCurrency: this.defaultCurrency.id,
                    availableCurrencies: this.availableCurrencies.map(c => c.id),
                    timeZoneId: this.company.timeZoneId,
                    lang: this.company.lang.value
                }
                if (this.companyLanguage.value !== this.company.lang) {
                    updateInput.lang = this.companyLanguage.value
                }
                AdminApiCalls.updateCompanyInfo(updateInput).then(data => {
                    if (data.warnings && data.warnings.length) {
                        let modalScope = $scope.$new()
                        modalScope.warnings = data.warnings
                        modalScope.isChecked = true

                        let modalInstance = $modal.open({
                            animation: true,
                            template: require('../../../templates/modals/templates-warnings.html'),
                            controller: 'DeleteWarningsController',
                            windowClass: 'delete-warnings',
                            scope: modalScope
                        })

                        modalInstance.result.then(isChecked => {
                            updateTemplates(updateInput, isChecked)
                        }).finally(() => modalScope.$destroy())
                    } else {
                        updateCompany(data)
                    }
                }, errorResponse => {
                    if (errorResponse.data) {
                        if (errorResponse.status === 400) {
                            this.serverErrorData = errorResponse.data
                            if (angular.isObject(errorResponse.data)) {
                                this.formHintError = $translate.instant('error.commonIncorrectFields')
                            } else {
                                this.formHintError = $translate.instant('error.common')
                            }
                        } else if (errorResponse.data.displayError) {
                            this.formHintError = errorResponse.data.displayError
                        } else {
                            this.formHintError = errorResponse.error || $translate.instant('error.common')
                        }
                    } else {
                        this.formHintError = $translate.instant('error.common')
                    }
                    if (timeoutId) {
                        $timeout.cancel(timeoutId)
                    }
                    timeoutId = $timeout(() => this.formHintError = null, config.errorDelay)
                })
            }
        }

        this.getLogoUrl = (size) => {
            let { logoUrl } = this.company
            return logoUrl ? `${logoUrl}_${size}` : ''
        }

        this.uploadFiles = (file, errFiles) => {
            if (this.formHint) {
                this.formHint = null
            }
            if (this.formHintError) {
                this.formHintError = null
            }
            if (errFiles && errFiles.length) {
                let modalScope = $scope.$new()
                modalScope.title = $translate.instant('text.thisTypeIsNotSupported')
                modalScope.text = $translate.instant('companyLogo.error.shouldBe')
                modalScope.additionalText = $translate.instant('text.pleaseSelectAnotherFile')
                PageSettings.openAlertModal(modalScope).finally(() => modalScope.$destroy())
            }
            if (file) {
                let modalScope = $scope.$new()
                modalScope.file = file
                modalScope.url = '/api/company/logo'
                modalScope.aspectRatio = COMPANY_LOGO_RATIO
                modalScope.zoomEnabled = true

                let modalInstance = $modal.open({
                    animation: true,
                    windowClass: 'crop-image-modal',
                    template: require('../../../templates/modals/crop-company-logo.html'),
                    controller: 'CropImageController',
                    scope: modalScope,
                    backdrop: 'static'
                })

                modalInstance.result.then(data => updateCompany(data)).finally(() => {
                    modalScope.$destroy()
                })
            }
        }

        this.deleteCompanyLogo = () => {
            if (this.formHint) {
                this.formHint = null
            }
            if (this.formHintError) {
                this.formHintError = null
            }
            AdminApiCalls.deleteCompanyLogo().then((response) => updateCompany(response))
        }

        this.getMaxSize = () => {
            return ServerConfig.avatarMaxSize
        }

        this.getContentTypes = () => {
            return ServerConfig.avatarContentTypes.join(',')
        }

        $scope.$watch(() => this.defaultCurrency, () => {
            if (this.defaultCurrency) {
                this.availableCurrencies = this.availableCurrencies.filter(currency => currency.id !== this.defaultCurrency.id)
            }
        })

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId)
            }
        })
    }
}
