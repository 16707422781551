import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../libs/colors.js';
import utils from './utils.js';
import UserTooltip from './user-tooltip.jsx';

class ProcessAssigneesList extends React.Component {
    static propTypes = {
        assignees: PropTypes.arrayOf(Object)
    };
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
        };
    }
    changeState() {
        this.setState({showTooltip: true});
    }
    render() {
        const {assignees} = this.props;
        const mainList = assignees.slice(0, 3);
        const showTooltip = this.state.showTooltip;
        let popup = null;
        if (assignees.length > 3) {
            popup = {
                count: assignees.length - 3,
                list: assignees.slice(3)
            };
        }
        const changeState = () => this.changeState();
        return (
            <ul className="process-assignees-list">
                {mainList.map((assignee, index) => {
                    let style = {background: colors[assignee.colorIndex - 1]};
                    return (
                        <li key={index}>
                            {assignee.avatarUrl && <img className="avatar-small" src={assignee.avatarUrl + '_24'} height="24" width="24" alt=""/>}
                            {!assignee.avatarUrl && (
                                <div className="avatar-small" style={style}>
                                    {utils.getInitials(assignee.fullName)}
                                </div>
                            )}
                            <UserTooltip actor={assignee}/>
                        </li>
                    );
                })}
                {popup && popup.count && (
                    <li className="assignees-rest" onMouseEnter={changeState}>
                        <span className="assignees-rest-handler">+{popup.count}</span>
                        {showTooltip && (
                            <div className="popup-actors-list">
                                <div className="arrow"/>
                                <div className="container">
                                    {popup.list.map((user, uIndex) => {
                                        let style = {background: colors[user.colorIndex - 1]};
                                        return (
                                            <div key={uIndex}>
                                                {user.avatarUrl && (
                                                    <img className="avatar-small" src={user.avatarUrl + '_24'} width="24" height="24" alt=""/>
                                                )}
                                                {!user.avatarUrl && (
                                                    <div className="avatar-small" style={style}>
                                                        {utils.getInitials(user.fullName)}
                                                    </div>
                                                )}
                                                <span className="text">{user.fullName}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </li>
                )}
            </ul>
        );
    }
}

export default ProcessAssigneesList;