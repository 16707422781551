import React from 'react'
import PropTypes from 'prop-types'

import { Database } from '../models'
import TablesList from './tables-list.jsx'
import TableView from './table/view.jsx'

class DataBaseApp extends React.Component {
    static propTypes = {
        $rootScope: PropTypes.object.isRequired,
        $state: PropTypes.object,
        $stateParams: PropTypes.object
    }

    constructor (props) {
        super(props)

        this.state = {
            route: props.$state ? props.$state.current.name : '',
            data: props.$state ? props.$state.current.data : {},
            params: props.$stateParams || {},
            store: Database.create()
        }
    }

    componentDidMount () {
        this.unsubscribe = this.props.$rootScope.$on('$stateChangeSuccess', (event, state, params) => {
            this.setState({ route: state.name, data: state.data, params })
        })
    }

    componentWillUnmount () {
        this.unsubscribe()
    }

    render () {
        const { route, params, store, data } = this.state

        switch (route) {
            case 'main.database.table':
            case 'main.database.table.newRecord':
            case 'main.database.table.editRecord':
                store.insertTableModel(params.tableId)
                const table = store.tables.get(params.tableId)

                return (
                    <TableView
                        table={table}
                        onDelete={() => store.deleteTable(table)}
                        onTableLoadError={() => store.closeTable(params.tableId)}
                        action={{ id: data.action, params }}
                    />
                )
            default:
                return <TablesList store={store}/>
        }
    }
}

export default DataBaseApp
