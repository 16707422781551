import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './entity-icon.less'

export const DEFAULT_ICON = 'icon-File'

function EntityIcon ({ icon, defaultIcon, defaultColor, ...props }) {
    const backgroundColor = icon ? icon.color : defaultColor
    const style = backgroundColor ? { backgroundColor } : {}

    return (
        <div className="entity-icon" style={style} {...props}>
            <i className={clsx('icon', icon ? icon.id : defaultIcon)}/>
        </div>
    )
}

EntityIcon.propTypes = {
    icon: PropTypes.object,
    defaultIcon: PropTypes.string,
    defaultColor: PropTypes.string
}

EntityIcon.defaultProps = {
    defaultIcon: DEFAULT_ICON,
    defaultColor: '#ccc'
}

export default EntityIcon
