import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'

export const DEFAULT_COLUMN_WIDTH = 120
export const DEFAULT_ROW_HEIGHT = 40
export const DEFAULT_HEADER_HEIGHT = 40

export const DEFAULT_COLUMN_WIDTHS = {
    [DATE_AND_TIME]: 150,
    [DATE_ONLY_VALUE]: 120,
    [EMAIL]: 200,
    [FILES_LIST]: 150,
    [MONEY]: 120,
    [MULTI_SELECTOR]: 150,
    [NUMERIC_VALUE]: 120,
    [RADIO_SELECTOR]: 150,
    [STRING_MULTI_LINE]: 200,
    [STRING_SINGLE_LINE]: 200,
    [USER_FIELD]: 120,
    [YES_NO]: 90
}
