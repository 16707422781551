import icons from '../../../libs/icons';
import colors from '../../../libs/colors';

export function iconsSelect() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            iconObj: '=ngModel',
            ngShow: '='
        },
        template: require('./icons-select.html'),
        link: (scope) => {
            scope.icons = icons;
            scope.colors = colors;
            if (!scope.iconObj.color) {
                scope.iconObj.color = scope.colors[0];
            }
        },
        controller: ($scope) => {
            'ngInject';

            $scope.setColor = color => {
                $scope.iconObj.color = color;
            };

            $scope.setIcon = iconId => {
                $scope.iconObj.id = iconId;
            };

            $scope.close = () => {
                $scope.ngShow = false;
            };
        }
    };
}
