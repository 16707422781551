import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'

import './react-selectize.less'

const DropdownIndicator = ({ ...props }) => {
    return props.selectProps.isMulti ? null : (
        <components.DropdownIndicator {...props}>
            <i className="icon-common_arrow_down"/>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = ({ ...props }) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon-common_close"/>
        </components.ClearIndicator>
    )
}

class ReactSelectize extends React.Component {
    static propTypes = {
        isMulti: PropTypes.bool,
        options: PropTypes.array.isRequired,
        value: PropTypes.arrayOf(PropTypes.object),
        onChange: PropTypes.func,
        attachToBody: PropTypes.bool
    }

    static defaultProps = {
        isMulti: true,
        attachToBody: true
    }

    constructor (props) {
        super(props)

        this.components = {
            DropdownIndicator,
            ClearIndicator,
            ...props.components
        }
    }

    onChangeHandler (value) {
        const { isMulti, onChange } = this.props
        if (isMulti) {
            onChange(value ? value : [])
        } else {
            onChange(value ? [value] : [])
        }
    }

    filterOption ({ label }, string) {
        return !string || label.toLowerCase().includes(string.toLowerCase())
    }

    render () {
        const { components, onChange, attachToBody, ...props } = this.props
        return (
            <Select
                styles={{ menuPortal: (provided) => ({ ...provided, width: 300 }) }}
                components={this.components}
                className="react-selectize"
                classNamePrefix="react-selectize"
                menuPortalTarget={attachToBody ? document.body : undefined}
                menuPosition={'absolute'}
                menuPlacement={'bottom'}
                onChange={this.onChangeHandler.bind(this)}
                filterOption={this.filterOption.bind(this)}
                {...props}
            />
        )
    }
}

export default ReactSelectize
