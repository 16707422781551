/* global document */
import moment from 'moment-timezone'
import utils, { traverseSubTreeItems } from '../utils'
import globalUtils from '../../../utils'
import { AGGREGATION } from '../../../services/data-types'

export class TemplateController {
    constructor (template, $scope, Constants, $translate, $location, $stateParams, $timeout, DateHelper,
        ApiCalls, PageSettings, $state, actorsTree, currentUser) {
        'ngInject'
        this.activeMode = 'TASKS' // Show tasks as first view
        this.activeModeTab = 'DETAILS'
        this.TASK_TYPE = Constants.TASK.TYPE
        this.PROCESS_EXECUTION = Constants.PROCESS.EXECUTION
        this.url = $stateParams.url || '/templates'
        if ($stateParams.url) {
            globalUtils.localStorage.set('templateViewBackUrl', $stateParams.url)
        }

        this.activeLevel = {}
        this.openedGroups = []
        this.selectedItemIndex = -1
        this.selectedItem = null

        this.processDetailsAreActive = () => {
            return !this.openedGroups.length && this.selectedItemIndex === -1
        }

        this.groupSummaryIsActive = () => {
            return this.openedGroups.length && this.selectedItemIndex === -1
        }

        this.selectItem = (index) => {
            this.selectedItemIndex = index
        }

        this.itemIsSelected = (index) => {
            return index === this.selectedItemIndex
        }

        this.openGroup = (index) => {
            const groupItem = this.activeLevel.subTree[index]
            if (groupItem && groupItem.group) {
                this.openedGroups.push(groupItem.group)
                this.changeLevel(this.openedGroups.length)
            }
        }

        this.changeLevel = (level = 0) => {
            const source = level === 0 ? this.template : this.openedGroups[level - 1]
            this.selectedItemIndex = -1
            this.openedGroups = this.openedGroups.slice(0, level)
            if (source.subTree) {
                this.activeLevel = source
            }
        }

        this.updateSelectedItem = () => {
            if (this.groupSummaryIsActive()) {
                this.selectedItem = { group: this.activeLevel }
            } else {
                this.selectedItem = this.activeLevel.subTree[this.selectedItemIndex]
            }
        }

        this.getGroupItemsCount = group => angular.toJson({ COUNT: group.subTree.length || 0 })

        this.template = utils.prepareTemplateModel(template, currentUser, actorsTree, $translate)
        this.conditionFormFields = this.template.dataModel.list
            .map(item => item.section)
            .reduce((fields, section) => {
                const availableFields = section.isTable
                    ? section.fieldsWithValues.filter(f => f.name.dataType === AGGREGATION)
                    : section.fieldsWithValues.slice()
                return fields.concat(availableFields)
            }, [])
            .filter(field => field.name.label)
            .map(field => ({
                id: field.name.tempId || field.name.id,
                item: field.name.label,
                name: field.name
            }))

        this.dueDateFields = utils.updateDueDateFields(this.template.dataModel)
        traverseSubTreeItems(this.template.subTree, (item) => {
            if (item.task) {
                const { dueDateInterval } = item.task
                if (dueDateInterval && dueDateInterval.linkedFieldId) {
                    const field = this.dueDateFields.find(f => f.id === dueDateInterval.linkedFieldId)
                    dueDateInterval.label = `${$translate.instant(`text.${dueDateInterval.calculation}`).toLowerCase()} "${field.item}"`
                    dueDateInterval.calculation = `${dueDateInterval.linkedFieldId}_${dueDateInterval.calculation}`
                    dueDateInterval.linkedField = { id: field.id, name: field.item }
                }
            }
        })

        this.changeLevel(0)

        $timeout(() => {
            if (PageSettings.leftScroll) {
                document.getElementById('template-tasks').scrollTop = Number(PageSettings.leftScroll) // eslint-disable-line
                PageSettings.leftScroll = null
            }
            this.showTemplateTasks = true
        })

        this.back = () => {
            $location.path(globalUtils.localStorage.get('templateViewBackUrl') || this.url)
            $scope.$applyAsync()
        }

        // Select process details for settings view
        this.setActiveProcessDetails = () => {
            this.openedGroups = []
            this.selectedItemIndex = -1
        }

        this.setActiveGroupSummary = () => {
            this.selectedItemIndex = -1
        }

        this.setActiveTask = task => {
            this.activeTask = task
            let el = document.getElementById('aside-active-task')
            if (el) {
                el.scrollTop = 0
            }
        }

        this.getUnitsStr = (obj) => {
            if (obj.dueDateInterval) {
                if (obj.dueDateInterval.units === 'DAY' || !obj.dueDateInterval.units) {
                    return 'plural.calendarDays'
                }
                return 'plural.' + obj.dueDateInterval.units.toLowerCase() + 's'
            }
        }

        this.getActorsNames = task => {
            return utils.getActorsNames(task, $translate)
        }

        let getSettings = (dataModelSettings, field) => {
            if (dataModelSettings) {
                let foundField = dataModelSettings.find(f => f.fieldId === field.name.id)
                return foundField ? foundField.settings : {}
            }
            return {}
        }

        this.checkDataModel = (isProcessStart) => {
            let dataModelSettings
            if (isProcessStart && this.template.processStart) {
                dataModelSettings = this.template.processStart.dataModelSettings
            } else if (this.activeTask) {
                dataModelSettings = this.activeTask.dataModelSettings
            }
            let checkSection = section => {
                if (section && section.fieldsWithValues) {
                    return !section.fieldsWithValues.find(f => getSettings(dataModelSettings, f).visibility !== 'HIDDEN')
                }
            }
            return this.template.dataModel && this.template.dataModel.list.find(item => !checkSection(item.section))
        }

        this.getStatus = () => {
            let utcDate = DateHelper.getUtcFromTimestamp(this.template.creationDate)
            let tz = DateHelper.getTZ()

            let b = moment(utcDate).tz(tz)
            let a = moment().tz(tz)

            if (a.dayOfYear() === b.dayOfYear() && a.year() === b.year()) {
                return $translate.instant('template.createdAt.status')
            }
            if (a.dayOfYear() - b.dayOfYear() < 2 && a.year() === b.year()) {
                return $translate.instant('template.created.status')
            }
            return $translate.instant('template.createdOn.status')
        }

        this.getIntervalStr = task => {
            return angular.toJson({ COUNT: task.dueDateInterval ? task.dueDateInterval.interval : 0 })
        }

        this.startProcess = () => {
            if (!this.checkPermission('start')) {
                return
            }
            $state.go('main.template.start', { id: this.template.id })
        }

        this.copyTemplate = () => $state.go('main.template.copy', { id: this.template.id })

        this.goToEdit = (stateParams = {}) => {
            $state.go('main.templateEdit', {
                id: this.template.id,
                url: $location.path(),
                mode: this.activeMode || null,
                tab: this.activeModeTab || null,
                openedGroupIds: this.openedGroups.map(group => group.id),
                selectedItemIndex: this.selectedItemIndex,
                ...stateParams
            })
        }

        this.checkPermission = (permission) => {
            return this.template && this.template.permissions ? this.template.permissions.indexOf(permission) !== -1 : null
        }

        let editHighlight
        this.highlightEdition = () => {
            this.editIsHighlighted = true
            if (editHighlight) {
                $timeout.cancel(editHighlight)
            }
            editHighlight = $timeout(() => {
                this.editIsHighlighted = null
            }, 5000)
        }

        let isTmplDeletion = false
        this.deleteTemplate = () => {
            if (isTmplDeletion) {
                return
            }
            isTmplDeletion = true
            let modalInstance = PageSettings.deleteConfirmation('template')
            modalInstance.result.then(() => {
                const deleteCompleteAction = () => this.back()
                ApiCalls.deleteTemplate(this.template.id, this.template.version)
                    .then(deleteCompleteAction)
                    .catch(res => PageSettings.errorHandlerModal(res, deleteCompleteAction))
                    .finally(() => isTmplDeletion = false)
            }).catch(() => isTmplDeletion = false)
        }

        this.openDetailsTab = (event) => {
            event.stopPropagation()
            this.activeModeTab = 'DETAILS'
        }

        this.openFieldsTab = (event) => {
            event.stopPropagation()
            this.activeModeTab = 'FIELDS'
        }

        $scope.$watch(() => this.selectedItemIndex, this.updateSelectedItem)
        $scope.$watch(() => this.activeLevel.subTree, this.updateSelectedItem, true)

        $scope.$on('$destroy', () => {
            if (editHighlight) {
                $timeout.cancel(editHighlight)
            }
        })

        $scope.allowsSpecificDataEmpty = $translate.instant('text.allowsSpecificDataEmpty').replace('&lt;br&gt;', `<br>`)

        // Review task
        this.taskIsApproval = ({ type }) => {
            const { REVIEW, APPROVAL } = this.TASK_TYPE
            return type === APPROVAL || type === REVIEW
        }

        this.selectedTaskIsApproval = () => {
            return this.taskIsApproval(this.selectedItem.task)
        }

        this.reviewFeatureIsAvailable = () => {
            return currentUser.companySettings.review
        }

        this.groupHasConditions = (group) => {
            if (this.groupWithReview(group)) {
                return false
            }
            return group.conditions && group.conditions.list.length > 0
        }

        this.groupWithReview = (group) => {
            return group.subTree.filter(item => item.task && item.task.type === Constants.TASK.TYPE.REVIEW).length > 0
        }

        this.getReviewTypeFlag = () => {
            return this.selectedItem.task.type === this.TASK_TYPE.REVIEW
        }

        this.openTemplateSchedule = () => {
            if (template.isScheduleRestricted) {
                PageSettings.openScheduleRestrictedModal().result.then(() => this.goToEdit({ tab: 'FIELDS' }))
            } else {
                $state.go('main.template.schedule', { id: template.id })
            }
        }

        $scope.$on('template.schedule.updated', (event, { schedule }) => {
            template.schedule = schedule
        })
    }
}
