import ProcessPrintView from '../react/process/process-print-view'

export function processPrintView(reactDirective) {
    'ngInject';

    const mapProps = [
        'process'
    ]

    return reactDirective(ProcessPrintView, mapProps, {}, {});
}
