import React from 'react'
import FormGroup from '../form/form-group.jsx'

function OptionFormGroup (props) {
    return (
        <FormGroup {...props} />
    )
}

export default OptionFormGroup
