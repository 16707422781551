export function capitalizedValue() {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            ngModel: '='
        },
        controller: ($scope) => {
            'ngInject';
            let allowCapitalize = true;
            $scope.$watch('ngModel', (newValue, oldValue) => {
                if (!allowCapitalize) {
                    return;
                }
                if (oldValue) {
                    if (!newValue.match(/\S\S*$/) || newValue.match(/\S\S*$/).index < oldValue.match(/\S\S*$/).index) {
                        allowCapitalize = false;
                    }
                }
                if (newValue && newValue.length) {
                    $scope.ngModel = newValue.replace(/\S\S*/g, (txt) => {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                }
            });
        }
    };
}
