import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Cell } from 'fixed-data-table-2'
import clsx from 'clsx'

import GridCell from './cell.jsx'
import HeaderCell from './header-cell.jsx'
import ContainerDimensions from 'react-container-dimensions'
import DataGrid from '../../../../components/react/data-grid/data-grid.jsx'
import { translate } from '../../../react/utils'

import './grid.less'

function RecordsGrid (props) {
    const { model, onSelectRecord, onCreateColumn, onEditColumn, onDeleteColumn } = props
    const columns = model.columnsOrdered
    const columnKeys = Object.keys(columns)

    const initialColumnsState = columnKeys.reduce((state, key) => {
        const column = columns[key]
        const columnState = JSON.parse(localStorage.getItem(column.id) || '{}')
        state[key] = { width: columnState.width || column.defaultWidth, isFixed: columnState.isFixed }
        return state
    }, {})

    const [reorderingIsActive, setReorderingState] = useState(false)
    const [columnsState, setColumnsState] = useState(initialColumnsState)

    if (Object.keys(initialColumnsState).join(',') !== Object.keys(columnsState).join(',')) {
        // Sync columns keys from store with local state
        setColumnsState(initialColumnsState)
    }

    const selectRecordAction = (rowIndex) => {
        if (rowIndex === model.total) {
            onSelectRecord()
            return
        }

        const record = model.getRecordAtIndex(rowIndex)
        if (!record.isLoading) {
            onSelectRecord(record)
        }
    }

    const dataCellRenderer = (rowIndex, columnIndex, width = 0, height = 0, isHighlighted = false) => {
        if (rowIndex === model.total && model.isRecordEditable) {
            return <Cell
                style={{ width, height }}
                className={clsx('column-value-cell', { active: isHighlighted })}
            >
                {(Number(columnIndex) === 0) && (
                    <span style={{ width }} className="column-value-cell-content">
                        <span className="btn btn-icon muted" style={{ fontSize: 12 }}>
                            <i className="icon-template_create" style={{ fontSize: 10 }}/>
                            <span>{translate('label.addRecord')}</span>
                        </span>
                    </span>
                )}
            </Cell>
        }

        const column = model.columnsOrdered.length > columnIndex ? model.columnsOrdered[columnIndex] : undefined
        const record = model.getRecordAtIndex(rowIndex)

        return (
            <GridCell
                record={record}
                columnId={column.id}
                searchString={model.searchString}
                onClick={() => selectRecordAction(rowIndex)}
                style={{ width, height }}
                isHighlighted={isHighlighted}
                isRequired={column.isTitle}
            />
        )
    }

    const rowClassNameGetter = rowIndex => {
        if (rowIndex === model.total) {
            return ''
        }
        const record = model.getRecordAtIndex(rowIndex)
        const hasInvalidValues = record.values ? record.values.find(v => v.isInvalid) : undefined
        return hasInvalidValues ? 'has-invalid-values' : ''
    }

    const enableReorderingProxy = () => {
        setReorderingState(true)
    }

    const reorderColumnsProxy = (props) => {
        model.reorderColumns(props)
    }

    const saveLocalColumnState = (id, { width, isFixed }) => {
        localStorage.setItem(id, JSON.stringify({ width, isFixed }))
    }

    const toggleFixedStateAction = (columnKey) => {
        const column = columns[columnKey]
        const columnState = columnsState[columnKey]
        const newColumnState = { ...columnState, isFixed: !columnState.isFixed }
        setColumnsState({ ...columnsState, [columnKey]: newColumnState })
        saveLocalColumnState(column.id, newColumnState)
    }

    const onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        const column = columns[columnKey]
        const newColumnState = { ...columnsState[columnKey], width: newColumnWidth }
        setColumnsState({ ...columnsState, [columnKey]: newColumnState })
        saveLocalColumnState(column.id, newColumnState)
    }

    const onColumnReorderEndCallback = ({ reorderColumn, columnAfter, columnBefore }) => {
        if (columnAfter === undefined && columnBefore === undefined) {
            return
        }

        const newColumnKeys = columnKeys.filter(key => key !== reorderColumn)

        if (columnAfter) {
            // Place moved column at specific position
            let index = columns[columnAfter].isTitle
                ? 1 // Title column should stay on its place, moved column will be placed AFTER title column
                : newColumnKeys.indexOf(columnAfter)
            newColumnKeys.splice(index, 0, reorderColumn)
        } else {
            // Place moved column to the end of list
            newColumnKeys.push(reorderColumn)
        }

        reorderColumnsProxy(newColumnKeys.map(key => columns[key].id))
    }

    return (
        <ContainerDimensions>
            <DataGrid
                rowsCount={model.isRecordEditable ? model.total + 1 : model.total}
                columns={model.columnsOrdered}
                onSelectRow={selectRecordAction}
                columnHeaderRenderer={(column, columnKey) => (
                    <HeaderCell
                        column={column}
                        columnState={columnsState[columnKey]}
                        isEditable={model.isEditable}
                        editAction={() => onEditColumn(columnKey)}
                        deleteAction={() => onDeleteColumn(column.id)}
                        changeTitleAction={() => model.changeTitleColumn(column.id)}
                        toggleFixedStateAction={() => toggleFixedStateAction(columnKey)}
                        enableReordering={enableReorderingProxy}
                    />
                )}
                dataCellRenderer={dataCellRenderer}
                rowClassNameGetter={rowClassNameGetter}
                onCreateColumn={onCreateColumn}
                onColumnReorderEndCallback={onColumnReorderEndCallback}
                onColumnResizeEndCallback={onColumnResizeEndCallback}
                reorderingIsActive={reorderingIsActive}
                columnsState={columnsState}
            />
        </ContainerDimensions>
    )
}

RecordsGrid.propTypes = {
    model: PropTypes.object,
    onSelectRecord: PropTypes.func,
    onCreateColumn: PropTypes.func,
    onEditColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func
}

export default observer(RecordsGrid)
