import {connect} from '../store/connect';
import ColumnsDropdown from '../components/columns-dropdown.jsx';
import {toggleColumnLock, toggleColumnsDropdown, toggleColumnVisibility} from '../store/actions';

const mapStateToProps = (state) => {
    return {
        isOpen: state.ui.columnsDropdownIsOpen
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {reportId} = ownProps;
    return {
        toggleDropdown: (state) => {
            dispatch(toggleColumnsDropdown({state}));
        },
        toggleColumnVisibility: (columnKey, isHidden) => {
            dispatch(toggleColumnVisibility({columnKey, isHidden, reportId}));
        },
        toggleColumnLock: (columnKey, isFixed) => {
            dispatch(toggleColumnLock({columnKey, isFixed, reportId}));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColumnsDropdown);
