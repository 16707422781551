import moment from 'moment-timezone'
import config, { SSO_SIGN_IN_TARGET_KEY } from './config'
import utils from './utils'

const _getBrowserTimeZone = $window => {
    if ($window.jstz) {
        return $window.jstz.determine().name()
    }
}

const capterraInsert = () => {
    const capterra_prefix = document.location.protocol === 'https:' ? 'https://ct.capterra.com' : 'http://ct.capterra.com'
    const ct = document.createElement('script')
    ct.type = 'text/javascript'
    ct.async = true
    ct.src = capterra_prefix + '/capterra_tracker.js?vid=' + config.capterra_vid + '&vkey=' + config.capterra_vkey
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ct, s)
}

export function runBlock ($rootScope, $templateCache, $window, ServerConfig, $location, $auth, $state, PusherHelper,
    currentUser, PageSettings, HomeSettings, CurrentUserService, CompanyCurrenciesService,
    $modal, $modalStack, $translate, $timeout, DevicesDetector, companyCurrencies) {
    'ngInject'
    $rootScope.currentUser = currentUser
    $rootScope.$state = $state
    $rootScope.PageSettings = PageSettings
    $rootScope.DevicesDetector = DevicesDetector
    $rootScope.notification = {}

    $translate.use(currentUser?.lang || 'en')
    moment.locale(currentUser?.lang || 'en')

    if ($auth.isAuthenticated() && ServerConfig.errorStatus !== 474) {
        PusherHelper.initSocket(currentUser)
    }

    let timer, resizeTimer, isAuthReaded
    let expSessionIsCalled = false
    let expSessionFn = () => {
        if ($auth.isAuthenticated()) {
            PusherHelper.deleteSocket()
            let modalScope = $rootScope.$new()
            modalScope.title = $translate.instant('session.expTitle')
            modalScope.text = $translate.instant('session.expText')
            modalScope.confirmText = $translate.instant('label.ok')
            modalScope.expire = true
            modalScope.noClose = true

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal expiration',
                template: require('./templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                backdrop: 'static',
                keyboard: false,
                scope: modalScope
            })
            modalInstance.result.then(() => {
                $state.go('login')
            }).finally(() => {
                modalScope.$destroy()
            })
        }
    }

    let destroyCallback = $rootScope.$on('$stateChangeStart', (event, state, params) => {
        const authToken = $location.search().authToken || null
        const redirParam = $location.search().redir || null
        const isAuthenticated = $auth.isAuthenticated()

        if (state.name === 'accountDisabled') {
            if (isAuthenticated) {
                $auth.logout()
            }
            HomeSettings.clearStorage()
        }
        if (ServerConfig.errorStatus) {
            if (ServerConfig.errorStatus === 474 || ServerConfig.errorStatus === 470) {
                // Signin with google while account is disabled
                let signInTarget = utils.localStorage.get(SSO_SIGN_IN_TARGET_KEY)
                if (signInTarget && authToken) {
                    let target = JSON.parse(signInTarget)
                    if (target.state === 'billing-disabled') {
                        $auth.setToken(authToken)
                        CurrentUserService.reload(authToken, true)
                        utils.localStorage.remove(SSO_SIGN_IN_TARGET_KEY)
                        event.preventDefault()
                        return $state.go('billing-disabled')
                    }
                }

                if (!['accountDisabled', 'login-disabled', 'reset-start-disabled', 'reset-disabled', 'billing-disabled'].find(s => s === state.name)) {
                    event.preventDefault()
                    $state.go('accountDisabled')
                }
            } else if (state.name !== 'errorNotFound') {
                event.preventDefault()
                $state.go('errorNotFound')
            }
            return
        } else {
            if (state.name === 'login-disabled') {
                event.preventDefault()
                $state.go('login')
            }
            if (state.name === 'reset-start-disabled') {
                event.preventDefault()
                $state.go('reset-start')
            }
            if (state.name === 'billing-disabled') {
                event.preventDefault()
                $state.go('main.admin.billing')
            }
        }

        // Verification when authToken is provided like param
        if (isAuthReaded && !authToken) {
            isAuthReaded = false
        }
        if (authToken && !isAuthReaded) {
            isAuthReaded = true
            event.preventDefault()
            const from = $location.search().src
            $auth.setToken(authToken)
            if (redirParam) {
                return window.location.href = redirParam
            }
            CurrentUserService.reload(authToken)
            CompanyCurrenciesService.reload(authToken)
            let singInTarget = utils.localStorage.get(SSO_SIGN_IN_TARGET_KEY)
            if (singInTarget) {
                let target = JSON.parse(singInTarget)
                utils.localStorage.remove(SSO_SIGN_IN_TARGET_KEY)
                return $state.go(target.state, target.params)
            }
            if (from === 'registration') {
                capterraInsert()
                // return $state.go('main.templates.index');
                return $state.go('main.processes.my')
            }
            return $state.go('main.home')
        } else if ($auth.isAuthenticated() && !companyCurrencies.defaultCurrency) {
            CompanyCurrenciesService.reload(authToken)
        }

        if (isAuthenticated && $auth.getPayload()) {
            if (!expSessionIsCalled) {
                let timeoutDelay = moment.unix($auth.getPayload().exp).utc().diff(moment.utc(), 'milliseconds')
                expSessionIsCalled = true
                timer = $timeout(expSessionFn, timeoutDelay - 10000)
            }
        }

        if (state.name === 'login') {
            $timeout.cancel(timer)
            expSessionIsCalled = false
        }

        // Verification for authorized users
        if (!isAuthenticated && config.isAuthNeededState(state)) {
            event.preventDefault()
            return $state.go('login', {
                path: $location.path(),
                redir: redirParam,
                target: { state: state.name, params: params }
            })
        }

        if (state.name !== 'login' && state.name !== 'onboarding' && isAuthenticated && !currentUser.id) {
            event.preventDefault()
            return $state.go('login', { path: $location.path(), redir: redirParam })
        }

        if (isAuthenticated && currentUser.id && redirParam) {
            event.preventDefault()
            $window.location.href = redirParam + (redirParam.indexOf('?') >= 0 ? '&' : '?')
                + 'authToken=' + encodeURIComponent($auth.getToken())
            return
        }

        if (state.name === 'login' && isAuthenticated) {
            $auth.logout()
        }
        // Verification for company admins
        if (state.name.indexOf('admin') !== -1 && isAuthenticated) {
            if (currentUser && currentUser.id && !currentUser.isAdmin) {
                event.preventDefault()
                $state.go('main.home')
            }
        }
    })

    let destroySuccessCallback = $rootScope.$on('$stateChangeSuccess', (event, state) => {
        if (!config.isAuthNeededState(state)) {
            $modalStack.dismissAll()
        }
    })

    let resizeWindowCb = () => {
        if (resizeTimer) {
            $timeout.cancel(resizeTimer)
        }
        resizeTimer = $timeout(() => {
            PageSettings.recalcDetails()
        }, 500)
        if (!HomeSettings.isToggled) {
            $rootScope.$applyAsync(HomeSettings.showList = !PageSettings.smallScreensMode())
        }
    }
    angular.element($window).on('resize', resizeWindowCb)

    $rootScope.$on('$destroy', () => {
        $timeout.cancel(timer)
        $timeout.cancel(resizeTimer)
        angular.element($window).off('resize', resizeWindowCb)
        destroyCallback()
        destroySuccessCallback()
    })

    $rootScope.$on('$stateChangeStart', function (evt, to, params) {
        if (to.redirectTo) {
            evt.preventDefault()
            $state.go(to.redirectTo, params, { location: 'replace' })
        }
    })

    //add browser timezone to server config once during APP start
    ServerConfig.browserTimeZone = _getBrowserTimeZone($window)
}
