/*global angular*/
import utils from '../../processes/utils';
import adminUtils from '../utils';

export class AdminGroupsController {
    constructor($scope, ApiCalls, AdminApiCalls, DeferredAction, AdminSettings, PageSettings, FormErrors, $translate, $timeout, $q) {
        'ngInject';
        this.PageSettings = PageSettings;
        this.AdminApiCalls = AdminApiCalls;
        this.$timeout = $timeout;
        this.$scope = $scope;

        this.nextOffsetId = 0;
        this.groups = [];
        this.isRenaming = {};
        this.isDeleting = {};
        this.renameMode = {};
        this.listStub = Array.from({length: PageSettings.itemsPerPage}, (v, k) => ({id: k + 1}));

        this.actors = {users: [], groups: [], specialRoles: []};
        this.groupsModel = {};
        this.groupSaved = {};
        this.isGroupSaving = {};

        $scope.saveUsersData = {};
        $scope.deferedSaveUsers = {};

        ApiCalls.getActorsUsers().then(data => this.actors.users = data);
        AdminSettings.showList = false;

        let callAfterSelectCb = (callback, params, restCb) => {
            callback(params);
            if (restCb) {
                restCb();
            }
        };
        let callAfterSelect = (cb1, params, cb2) => {
            if (this.showSelect) {
                let promise = this.saveUsers(this.activeGroup.id);
                if (promise) {
                    promise.then(() => callAfterSelectCb(cb1, params, cb2));
                } else {
                    callAfterSelectCb(cb1, params, cb2);
                }
            } else {
                callAfterSelectCb(cb1, params, cb2);
            }
        };

        let updateListGroup = (group, groupId) => {
            if(groupId !== 1) {
                let groupT = this.groups.find(g => g.id === groupId);
                if (groupT) {
                    Object.assign(groupT, group, {users: group.users || null});
                }
                $timeout(() => {
                    $scope.$digest();
                });
            }
        };

        let updateActiveGroup = group => {
            if (this.activeGroup && this.activeGroup.id !== 1) {
                this.activeGroup = group;
                updateListGroup(group, this.activeGroup.id);
            }
        };
        let setActiveGroup = (group) => {
            this.activeGroup = angular.copy(group);
            this.groupSaved[group.id] = this.groupSaved[group.id] || angular.copy(group);
            if(!this.groupsModel[group.id]) {
                if (group.users) {
                    this.groupsModel[group.id] = group.users.slice();
                } else {
                    this.groupsModel[group.id] = [];
                }
            }
            this.renameMode[group.id] = false;
            AdminSettings.showList = true;
            $timeout(() => $scope.$digest());
        };

        this.showSettings = group => callAfterSelect(setActiveGroup, group);
        this.closeSettings = () => {
            AdminSettings.showList = false;
            this.showSelect = false;
            this.groupNameError = null;
            return $q((resolve) => {
                $timeout(() => {
                    this.activeGroup = null;
                    $scope.$digest();
                    resolve();
                }, 150);
            });
        };

        let getIdArray = (arr) => {
            if (!arr) {
                return [];
            }
            return arr.map(item => item.id).sort();
        };

        this.showUserInGroup = () => {
            if(!this.isRenaming[this.activeGroup.id] && !this.isDeleting[this.activeGroup.id]) {
                this.showSelect = true;
            }
        };

        this.onCloseUserInGroup = () => {
            this.showSelect = false;
            $scope.$digest();
        };

        // Save groups on enter / onblur selection control
        this.saveUsers = (id, deep) => {
            deep = deep || 1;
            if (!angular.equals(getIdArray(this.groupSaved[id].users), getIdArray(this.groupsModel[id]))) {
                $scope.saveUsersData[id] = {
                    group_id: id,
                    post_data: {
                        users: this.groupsModel[id].map(u => ({id: u.id, name: u.fullName || null})),
                        version: this.groupSaved[id].version
                    }
                };
                if($scope.deferedSaveUsers[id] === undefined) {
                    this.isGroupSaving[id] = true;
                    $scope.deferedSaveUsers[id] = AdminApiCalls.changeGroupUsers($scope.saveUsersData[id].group_id, $scope.saveUsersData[id].post_data)
                    .then((resp) => {
                        if (resp.success) {
                            this.groupSaved[id] = angular.copy(resp.result);
                            if(this.activeGroup.id === id) {
                                updateActiveGroup(resp.result);
                            } else if (this.activeGroup.id !== id) {
                                updateListGroup(resp.result, id);
                            }
                        }
                        return resp;
                    }).then(() => {
                        if($scope.saveUsersData[id]) {
                            $scope.deferedSaveUsers[id] = undefined;
                            return this.saveUsers(id, deep + 1).then(() => {
                                return true;
                            });
                        } else {
                            return false;
                        }
                    }).then((isCallWaitingSave) => {
                        return $q((resolve) => {
                            if(!isCallWaitingSave) {
                                $timeout(() => {
                                    $scope.deferedSaveUsers[id] = undefined;
                                    this.saveUsers(id, deep + 1).then(() => {
                                        $scope.deferedSaveUsers[id] = undefined;
                                        resolve();
                                    });
                                }, 1000);
                            } else {
                                resolve();
                            }
                        });
                    }).catch((reason) => {
                        if(deep === 1) {
                            this.groupsModel[id] = this.groupSaved[id].users ? angular.copy(this.groupSaved[id].users) : [];
                            if(this.activeGroup.id === id) {
                                updateActiveGroup(angular.copy(this.groupSaved[id]));
                            } else if (this.activeGroup.id !== id) {
                                updateListGroup(angular.copy(this.groupSaved[id]), id);
                            }
                            $scope.deferedSaveUsers[id] = undefined;
                        } else {
                            throw reason;
                        }
                    }).finally(() => {
                        if(deep === 1) {
                            this.isGroupSaving[id] = false;
                        }
                    });

                    $scope.saveUsersData[id] = undefined;
                    return $scope.deferedSaveUsers[id];
                } else {
                    return $scope.deferedSaveUsers[id].promise;
                }
            } else {
                return $q((resolve) => resolve());
            }
        };

        this.getUsersCount = () => {
            if (this.activeGroup && this.activeGroup.users) {
                return angular.toJson({COUNT: this.activeGroup.users.length});
            }
            return angular.toJson({COUNT: 0});
        };

        this.toggleRename = () => {
            let id = this.activeGroup.id;
            if (!this.activeGroup.name || this.isRenaming[id] || this.isDeleting[id]) {
                return;
            }

            if (!this.renameMode[id]) {
                this.renameMode[id] = !this.renameMode[id];
                if (this.activeGroup.id !== 1) {
                    $timeout(() => {
                        document.getElementById('groupName').focus(); //eslint-disable-line
                    }, 50);
                } else {
                    $timeout(() => {
                        document.getElementById('groupName').select(); //eslint-disable-line
                    }, 50);
                }
                this.currentName = this.activeGroup.name;
            } else if (this.currentName !== this.activeGroup.name) {
                this.isRenaming[id] = true;
                let postData = {
                    version: this.activeGroup.version,
                    name: this.activeGroup.name
                };
                AdminApiCalls.renameGroup(this.activeGroup.id, postData).then(resp => {
                    if (resp.success) {
                        if(id === this.activeGroup.id) {
                            updateActiveGroup(resp.result);
                        } else {
                            updateListGroup(resp.result, resp.result.id);
                        }
                        let rId = resp.result.id;
                        if(this.groupSaved[rId]) {
                            this.groupSaved[rId].name = resp.result.name;
                            this.groupSaved[rId].version = resp.result.version;
                        }
                        this.renameMode[id] = false;
                        this.groupNameError = null;
                    }
                }, errorResp => {
                    this.groupNameError = errorResp.data.displayError || errorResp.data.error;
                }).finally(() => this.isRenaming[id] = false);
            } else {
                this.renameMode[id] = !this.renameMode[id];
            }
        };

        this.cancelRename = () => {
            this.activeGroup.name = this.currentName;
            this.groupNameError = null;
            this.renameMode[this.activeGroup.id] = false;
        };

        this.isHasGroupWithSameName = () => {
            let groupName = this.activeGroup.name.toLowerCase();
            return this.groups.findIndex(group => group.name.toLowerCase() === groupName) !== -1;
        };

        this.pattern = () => {
            let sourceGroupName = this.activeGroup.name || '';
            this.activeGroup.name = adminUtils.groupNameTrimRestrictedChar(sourceGroupName);
            $scope.resetForm.groupName.delSymbols = (sourceGroupName !== this.activeGroup.name);
            if (this.groupNameError) {
                this.groupNameError = '';
            }
            if(this.isHasGroupWithSameName()) {
                this.groupNameError = $translate.instant('label.groupAlreadyExists');
            }
        };

        this.checkField = fieldName => {
            if (!$scope.resetForm || !$scope.resetForm[fieldName]) {
                return;
            }
            return $scope.resetForm[fieldName].$invalid && $scope.resetForm[fieldName].$dirty;
        };
        this.fieldError = fieldName => FormErrors($scope.resetForm, fieldName);

        this.getUsersNames = users => {
            if (!users) {
                return;
            }
            if (users.length > 3) {
                return users.slice(0, 3).map(u => u.fullName).join(', ') + ' '
                    + $translate.instant('text.andMore', {count: users.length - 3});
            }
            return users.map(u => u.fullName).join(', ');
        };

        let deleteGroupsFn = (group) => {
            let id = group.id;
            if (!this.isDeleting[id]) {
                this.isDeleting[id] = true;
                AdminApiCalls.getGroupWarnings(group.id).then(resp => {
                    if (resp.success) {
                        if (resp.warnings.tasks || resp.warnings.processes || resp.warnings.templates) {
                            return PageSettings.openWarningsModal(resp.warnings, 'groups').result.then(() => {
                                return DeferredAction.GroupDelete.immediately(group).then(() => {
                                    this.groups = this.groups.filter(g => g.id !== group.id);
                                    this.triggerListChange();
                                    return this.closeSettings();
                                });
                            });
                        } else {
                            DeferredAction.GroupDelete.addToDeferred(group);
                            this.triggerListChange();
                            return this.closeSettings();
                        }
                    }
                }).finally(() => this.isDeleting[id] = false);
            }
        };

        this.deleteGroups = (group, $event) => {
            if ($event) {
                $event.stopPropagation();
            }
            callAfterSelect(deleteGroupsFn, group);
        };

        this.isAllGroupsDeferredDelete = () => {
            return this.groups.length && this.groups.every(group => DeferredAction.GroupDelete.isDeferred(group));
        };

        this.saveGroup = () => {
            let createGroup = group => {
                this.groups.push(group);
                setActiveGroup(group);
                this.groupNameError = null;
            };
            let id = this.activeGroup.id;
            if (!this.isRenaming[id] && !this.isHasGroupWithSameName()) {
                this.isRenaming[id] = true;
                AdminApiCalls.addGroup({name: this.activeGroup.name}, true).then(data => {
                    if (data.success) {
                        createGroup(data.result);
                    }
                }, errorResp => {
                    if(errorResp.status === 517) {
                        this.groupNameError = errorResp.data.summaryError;
                        createGroup(errorResp.data.existing);
                    } else {
                        this.groupNameError = errorResp.data.displayError || errorResp.data.error;
                    }
                }).finally(() => this.isRenaming[id] = false);
            }
        };

        this.keypress = $event => {
            if ($event.which === 13) {
                if (this.activeGroup.id === 1) {
                    this.saveGroup();
                } else {
                    this.toggleRename();
                }
            }
        };

        $scope.$on(DeferredAction.GroupDelete.CONST.COMPLETED, (event, group) => {
            return this.groups = this.groups.filter(g => g.id !== group.id);
        });

        $scope.$on('group-creation', (e, group) => {
            if (group) {
                if (!this.groups.find(g => g.name === group.name)) {
                    this.groups.push(group);
                }
            } else {
                let fakeGroup = {id: 1, name: $translate.instant('label.newGroup')};
                callAfterSelect(this.showSettings, fakeGroup, this.toggleRename);
            }
            if (e) { /**/ }
        });

        $scope.getUserName = (user) => {
            const {fullName, email} = user;
            return fullName.trim() ? fullName : email;
        };

        this.firstPage();
    }

    triggerListChange() {
        this.$timeout(() => this.$scope.$emit(`list:changed`), 100);
    }

    getApiParams() {
        const params = {count: this.PageSettings.itemsPerPage};

        if (this.nextOffsetId && this.nextOffsetId !== -1) {
            params.nextOffset = this.nextOffsetId;
        }

        return params;
    }

    getGroups() {
        return this.nextOffsetId === 0 && !this.groups.length ? this.listStub : this.groups;
    }

    nextPage() {
        if (this.nextOffsetId === -1) {
            return;
        }

        const params = this.getApiParams();

        this.busyLoading = true;
        this.AdminApiCalls.getGroups(params)
            .then(data => {
                if (data.list && data.list.length) {
                    utils.mergeToArray(this.groups, data.list, true);
                    this.nextOffsetId = data.nextOffset || -1;
                    this.serverError = false;
                    this.triggerListChange();
                }
            }, () => this.serverError = true)
            .finally(() => {
                this.$timeout(() => this.busyLoading = false);
            });
    }

    firstPage() {
        this.groups = [];
        this.nextOffsetId = 0;
        this.nextPage();
    }
}
