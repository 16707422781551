import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';

export function groupByFilter() {
    'ngInject';

    return memoize(function(items, field) {
        return groupBy(items, field);
    }, (items = []) => {
        return items.map(i => i.id || i.toString()).join(',');
    });
}
