import { adminRoute } from './admin.config.js'

import { AdminController } from './controllers/admin.controller.js'
import { AdminBillingController } from './controllers/admin.billing.controller.js'
import { AdminUsersController } from './controllers/admin.users.controller.js'
import { AdminGroupsController } from './controllers/admin.groups.controller.js'
import { AdminCompanyController } from './controllers/admin.company.controller.js'
import { AdminCalendarController } from './controllers/admin.calendar.controller.js'
import { AdminIntegrationsController } from './controllers/admin.integrations.controller.js'

import { addHolidayForm } from './directives/addHolidayForm.js'
import { adminInfoPopup } from './directives/adminInfoPopup.js'
import { undoDeletionGroup } from './directives/undoDeletionGroup.js'
import { selectAdminFilterMode } from './directives/select-filter-mode.js'
import { userInGroup } from './directives/user-in-group.js'

import { AdminSettings } from './services/admin-settings.js'
import { AdminApiCalls } from './services/api-calls.admin.js'

import './less/index.less'

let ngModule = angular.module('admin', [])
    .config(adminRoute)

    .controller('AdminController', AdminController)
    .controller('AdminBillingController', AdminBillingController)
    .controller('AdminUsersController', AdminUsersController)
    .controller('AdminGroupsController', AdminGroupsController)
    .controller('AdminCompanyController', AdminCompanyController)
    .controller('AdminCalendarController', AdminCalendarController)
    .controller('AdminIntegrationsController', AdminIntegrationsController)

    .directive('addHolidayForm', addHolidayForm)
    .directive('adminInfoPopup', adminInfoPopup)
    .directive('undoDeletionGroup', undoDeletionGroup)
    .directive('selectAdminFilterMode', selectAdminFilterMode)
    .directive('userInGroup', userInGroup)

    .factory('AdminApiCalls', AdminApiCalls)
    .factory('AdminSettings', AdminSettings)

export default ngModule
