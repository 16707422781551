export function authRoute($stateProvider, $urlRouterProvider) {
    'ngInject';
    let currentPath = '/app/modules/auth';
    $stateProvider
        .state('login', {
            url: '/login?redir&error',
            template: require('./views/login.html'),
            controller: 'LoginController',
            controllerAs: 'loginCtrl',
            params: {
                message: null,
                path: '/',
                redir: null,
                error: null,
                target: null
            }
        })
        .state('reset-start', {
            url: '/reset',
            template: require('./views/reset-start.html'),
            controller: 'ResetController',
            controllerAs: 'resetCtrl',
            resolve: {
                data: () => {
                    return {};
                }
            }
        })
        .state('reset', {
            url: '/reset/:mailToken',
            template: require('./views/reset.html'),
            controller: 'ResetController',
            controllerAs: 'resetCtrl',
            resolve: {
                data: (ApiCalls, $stateParams, $translate, $state) => {
                    'ngInject'
                    return ApiCalls.getResetData($stateParams.mailToken).then(data => data, errorResp => {
                        let error;
                        if (errorResp && errorResp.data) {
                            error = errorResp.data.displayError;
                        }
                        if (errorResp.status === 518) { //check if user is already reset password with this link MBPM-2524
                            $state.go('main.home');
                        } else {
                            $state.go('error', {message: error || $translate.instant('error.reset'), reason: 'reset'});
                        }
                    });
                }
            },
            params: {
                mailToken: null
            }
        })
        .state('signup-start', {
            url: '/signup',
            template: require('./views/signup-start.html'),
            controller: 'SignupController',
            controllerAs: 'signupCtrl',
            resolve: {
                userData: () => {
                    return {};
                }
            }
        })
        .state('signup', {
            url: '/signup/:mailToken',
            template: require('./views/signup.html'),
            controller: 'SignupController',
            controllerAs: 'signupCtrl',
            resolve: {
                userData: (ApiCalls, $stateParams, $translate, $state) => {
                    'ngInject'
                    return ApiCalls.getInvitedUser($stateParams.mailToken)
                        .then(data => data, errorResp => {
                            if (errorResp.status === 518) { //check if user is already registered using this link MBPM-2524
                                $state.go('main.home');
                            } else {
                                $state.go('error', {message: $translate.instant('error.invite')});
                            }
                        });
                }
            }
        })
        .state('invite', {
            url: '/invite/:mailToken?error',
            template: require('./views/signup.html'),
            controller: 'SignupController',
            controllerAs: 'signupCtrl',
            resolve: {
                userData: (ApiCalls, $stateParams, $translate, $state) => {
                    'ngInject'
                    return ApiCalls.getInvitedUser($stateParams.mailToken)
                        .then(data => data, errorResp => {
                            if (errorResp.status === 518) { //check if user is already registered using this link MBPM-2524
                                $state.go('main.home');
                            } else {
                                $state.go('error', {message: $translate.instant('error.invite')});
                            }
                        });
                }
            },
            params: {
                error: null
            }
        });
    $urlRouterProvider.otherwise('/');
}
