import moment from 'moment-timezone';
import Drop from 'tether-drop';
import { DATE_SYSTEM_FORMAT } from '../services/constants'

export function prcDatePicker(DateHelper, MomentHelper, $timeout, ProcessesSettings) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dueDate: '=?',
            dueDateInterval: '=?',
            adHocTask: '@?',
            noBtns: '@?',
            noLeft: '@?',
            autofocus: '@?',
            placeholder: '@?',
            isDeadline: '@?',
            isTemplate: '@?',
            isHolidays: '@?',
            minValue: '=?',
            maxValue: '=?'
        },
        template: `
            <div class="prc-date-picker">
                <span class="handler" ng-class="{'adHoc-handler': adHocTask, tmplDeadline: isTemplate}">
                    <i class="icon-task_due_date_add" ng-if="!adHocTask && !isTemplate"></i>
                    <i class="icon-task_due_date_add_small" ng-if="!adHocTask && isTemplate"></i>
                    <span ng-if="!dueDateValue">
                        {{placeholder | translate}}
                    </span>
                    <span ng-if="dueDateValue" ng-class="{red: checkPastDue() && !isHolidays}">
                        {{dueDateValue}}
                    </span>
                    <i ng-if="dueDateValue" class="icon-common_remove" touch-start="clearDate()" tabindex="-1"
                       ng-click="clearDate($event)"></i>
                    <i class="icon-task_due_date_add_small" ng-if="adHocTask"></i>
                </span>
                <input ng-focus="focus()" ng-blur="blur()" ng-keydown="key($event)" ng-keyup="keyup($event)" type="text" tabindex="1"/>
                <div class="prc-picker-content" ng-click="pickerContentClick($event)" ng-show="dropOpened"
                     ng-class="{empty: !dueDate && !dueDateInterval, 'with-holidays': isHolidays, adHoc: adHocTask, noLeft: noLeft, start: isDeadline && isTemplate}">
                    <div ng-model="pickerValue" ng-click="pickerClick($event)" date-picker="pickerValue" is-due="true"
                         date-change="onChange" watch-direct-changes="true"
                         min-view="date" max-view="date" ng-if="dropOpened"
                         after="minValue" before="maxValue"></div>
                    <ul class="buttons" ng-if="dropOpened && !noBtns">
                        <li ng-click="setDueDate(0)">{{'date.today' | translate}}</li>
                        <li ng-click="setDueDate(1)">{{'date.tomorrow' | translate}}</li>
                        <li ng-click="setDueDate(2)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(2):'messageformat'}}</li>
                        <li ng-click="setDueDate(7)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(7):'messageformat'}}</li>
                        <li ng-click="setDueDate(14)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(14):'messageformat'}}</li>
                        <li ng-click="setDueDate(1, true)">{{'date.inAMonth' | translate}}</li>
                    </ul>
                </div>
            </div>
        `,

        link: (scope, element) => {
            let tz = DateHelper.getTZ();
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.prc-picker-content')[0],
                position: 'bottom left',
                openOn: 'click',
                constrainToWindow: true,
                constrainToScrollParent: true
            });
            const recalc = (date) => {
                scope.pickerValue = date;
                scope.dueDate = ProcessesSettings.calculateDueDateForProcess(date);
                scope.dueDateValue = moment.utc(moment(date).format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).format(DateHelper.DATE_FORMATS().DATE_INPUT);
            };

            let initial;

            if (scope.dueDate) {
                recalc(moment.unix(scope.dueDate).utc());
            }
            if (scope.dueDateInterval) {
                recalc(ProcessesSettings.calculateDueDateFromInterval(scope.dueDateInterval));
            }

            scope.getIntervalStr = (count) => {
                return angular.toJson({COUNT: count || 0});
            };
            scope.onChange = (e, data) => {
                if (e) {
                    /**/
                }
                return recalc(data);
            };

            scope.pickerClick = (event) => {
                if (event.target.nodeName === 'SPAN') {
                    drop.close();
                }
            };

            scope.pickerContentClick = (event) => {
                event.stopPropagation();
                initial = true;
                $timeout(() => {
                    element.find('input').focus();
                });
            };

            scope.focus = () => {
                if (!initial) {
                    drop.open();
                    initial = true;
                }
            };

            if (scope.autofocus) {
                scope.$applyAsync(() => drop.open());
            }

            scope.key = event => {
                switch(event.which) {
                    case 9:
                    case 27:
                        if (scope.dropOpened) {
                            event.stopPropagation();
                        }
                        drop.close();
                        break;
                    default:
                }
            };

            scope.dropOpened = false;
            scope.keyup = event => {
                if (event.which === 13 && scope.dropOpened) {
                    event.stopPropagation();
                    event.preventDefault();
                    if (!scope.dueDate && !scope.dueDateValue) {
                        recalc(moment.utc().tz(tz));
                    }
                    scope.dropOpened = false;
                    drop.close();
                }
            };

            scope.blur = () => {
                initial = false;
            };

            scope.checkPastDue = () => {
                if (moment.unix(scope.dueDate).utc().year() !== moment.utc().tz(tz).year()) {
                    return moment.unix(scope.dueDate).utc().year() < moment.utc().tz(tz).year();
                }
                return moment.unix(scope.dueDate).utc().dayOfYear() < moment.utc().tz(tz).dayOfYear();
            };

            scope.setDueDate = (count, isMonth) => {
                recalc(moment.utc().tz(tz).add(count, isMonth ? 'months' : 'days'));
                drop.close();
            };

            scope.clearDate = (event) => {
                if (event) {
                    event.stopPropagation();
                }
                scope.dropOpened = null;
                scope.dueDateValue = null;
                scope.dueDate = null;
                scope.pickerValue = null;
                drop.close();
                scope.$applyAsync();
            };

            drop.on('open', () => {
                scope.dropOpened = true;
                $timeout(() => {
                    element.find('input').focus();
                });
            });
            drop.on('close', () => {
                scope.$broadcast('reset:datepicker');
                scope.$applyAsync(scope.dropOpened = null);
            });

            scope.$on('selectize:open', () => {
                scope.dropOpened = null;
                drop.close();
            });
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
