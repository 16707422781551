import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'
import {
    clearFilters,
    receiveCompletedReportExport,
    receiveReportData,
    receiveReportDataSize,
    receiveReportExportProgress,
    receiveTemplates,
    receiveUsers,
    requestReportDataSize,
    requestReportExport,
    selectReport,
    selectRow,
    toggleColumnLock,
    toggleColumnsDropdown,
    toggleColumnVisibility,
    toggleExportDropdown,
    updateColumns,
    updateFilters
} from './actions'
import { getDefaultReportFilters } from './utils'
import { ALL_TEMPLATES_OPTION, MIN_EXPORT_PROGRESS, NO_TEMPLATE_OPTION } from './constants'

export const currentReportIdReducer = createReducer(null, {
    [selectReport]: (state, action) => {
        return action.payload
    }
})

export const selectedRowIndexReducer = createReducer(null, {
    [selectRow]: (state, action) => {
        return action.payload
    }
})

export const templatesReducer = createReducer([], {
    [receiveTemplates]: (state, action) => {
        return action.payload.list.slice()
    }
})

export const usersReducer = createReducer([], {
    [receiveUsers]: (state, action) => {
        return action.payload.slice()
    }
})

const getReportById = (state, action) => state.find(report => report.id === action.payload.reportId)

export const reportsReducer = createReducer([], {
    [updateFilters]: (state, action) => {
        const report = getReportById(state, action)
        report.filters = action.payload.filters
        report.data.isReceived = false
        report.data.total = 0
        report.data.list = []
    },
    [clearFilters]: (state, action) => {
        const report = getReportById(state, action)
        report.filters = getDefaultReportFilters(report.filters, true)
        report.data = {
            total: 0,
            list: [],
            isLoading: false,
            isReceived: false
        }
    },
    [requestReportDataSize]: (state, action) => {
        const report = getReportById(state, action)
        report.data = {
            total: 0,
            list: [],
            isLoading: true,
            isReceived: false
        }
    },
    [receiveReportDataSize]: (state, action) => {
        const report = getReportById(state, action)
        report.data.total = action.payload.total
        report.data.isLoading = false
        report.data.isReceived = true
    },
    [receiveReportData]: (state, action) => {
        const report = getReportById(state, action)
        const { data } = action.payload
        const { list = [] } = report.data
        report.data = { ...report.data, ...data, list: [...list, ...data.list] }
    },
    [updateColumns]: (state, action) => {
        const report = getReportById(state, action)
        Object.keys(action.payload.columns).forEach(key => {
            report.columns[key] = {
                ...report.columns[key],
                ...action.payload.columns[key]
            }
        })
    },
    [toggleColumnVisibility]: (state, action) => {
        const report = getReportById(state, action)
        const { columnKey, isHidden } = action.payload
        report.columns[columnKey] = { ...report.columns[columnKey], isHidden }
    },
    [toggleColumnLock]: (state, action) => {
        const report = getReportById(state, action)
        const { columnKey, isFixed } = action.payload
        report.columns[columnKey] = { ...report.columns[columnKey], isFixed }
    },
    [receiveTemplates]: (state, action) => {
        const { list } = action.payload
        state.forEach(report => {
            const { templatesIds } = report.filters
            templatesIds.slice().forEach(id => {
                if (id !== ALL_TEMPLATES_OPTION.id && id !== NO_TEMPLATE_OPTION.id && !list.find(template => template.id === id)) {
                    report.filters.templatesIds = templatesIds.filter(tid => tid !== id)
                    if (!report.filters.templatesIds.length) {
                        report.data.isReceived = false
                    }
                }
            })
        })
    },
    [receiveUsers]: (state, action) => {
        const list = action.payload.slice()
        state.forEach(report => {
            const { startersIds, assigneesIds } = report.filters
            startersIds.slice().forEach(id => {
                if (!list.find(user => user.id === id)) {
                    report.filters.startersIds = startersIds.filter(tid => tid !== id)
                }
            })
            assigneesIds.slice().forEach(id => {
                if (!list.find(user => user.id === id)) {
                    report.filters.assigneesIds = assigneesIds.filter(tid => tid !== id)
                }
            })
        })
    }
})

export const uiReducer = createReducer({}, {
    [toggleColumnsDropdown]: (state, action) => {
        state.columnsDropdownIsOpen = action.payload.state
    },
    [toggleExportDropdown]: (state, action) => {
        state.exportDropdownIsOpen = action.payload.state
    },
    [requestReportExport]: (state) => {
        state.exportProgress = MIN_EXPORT_PROGRESS
        state.exportDropdownIsAnimated = true
    },
    [receiveReportExportProgress]: (state, action) => {
        state.exportProgress = action.payload.progress
    },
    [receiveCompletedReportExport]: (state) => {
        state.exportDropdownIsAnimated = false
    },
    [selectReport]: (state) => {
        state.columnsDropdownIsOpen = false
        state.exportDropdownIsOpen = false
        state.exportDropdownIsAnimated = false
    }
})

export default combineReducers({
    currentReportId: currentReportIdReducer,
    selectedRowIndex: selectedRowIndexReducer,
    templates: templatesReducer,
    users: usersReducer,
    reports: reportsReducer,
    ui: uiReducer
})
