export const generateInitials = (input) => {
    if (!input) {
        return input;
    }

    let words = input.split(' ');
    if (words.length) {
        if (words.length > 1) {
            return words[0].charAt(0) + words[words.length - 1].charAt(0);
        }
        return words[0].charAt(0);
    }
    return '???';
};


export function initials() {
    'ngInject';

    return generateInitials;
}
