import stringEntropy from 'fast-password-entropy'
import config from '../../config'

export class HelpersController {
    constructor(info, $scope, $state, $stateParams, $auth, CurrentUserService, AuthApiCalls, $translate,
                ServerConfig, FormErrors) {
        'ngInject';

        this.info = info;
        if (this.info.success) {
            if (this.info.result && this.info.result.setPassword) {
                this.setPassword = true;
                this.info = null;
            } else {
                this.info = $translate.instant('text.confirmEmailSuccess');
            }
        } else {
            this.error = this.info.error || $translate.instant('error.site');
            this.info = null;
        }

        this.checkField = fieldName => {
            if (!$scope.resetForm || !$scope.resetForm[fieldName]) {
                return;
            }
            return $scope.resetForm[fieldName].$invalid && $scope.resetForm[fieldName].$dirty;
        };
        this.fieldError = fieldName => {
            return FormErrors($scope.resetForm, fieldName);
        };

        this.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        this.passwordIsWeak = undefined
        this.handleOnChange = () => {
            this.passwordIsWeak = $scope.password && stringEntropy($scope.password) < config.minimal_entropy
        }

        let isWaiting = false;
        this.resetPassword = (isValid) => {
            if (isWaiting) {
                return;
            }
            if (isValid) {
                isWaiting = true;
                AuthApiCalls.reset($stateParams.mailToken, {password: $scope.password})
                    .then(resp => {
                        if (resp.result) {
                            $auth.setToken(resp.result.token);
                            return CurrentUserService.reload(resp.result.token)
                                .finally(() => $state.go('main.home'));
                        }
                    })
                    .catch(response => {
                        this.formHint = response.data && response.data.error || $translate.instant('error.common');
                        isWaiting = false;
                    });
            }
        };
    }
}
