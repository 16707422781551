export function hasError() {
    return {
        restrict: 'A',
        require: '^form',
        scope: {
            formElement: '@hasError'
        },
        link: ($scope, element, attr, formCtrl) => {
            // Обычный $watch тут уже не справляется
            $scope.$watchCollection(() => formCtrl[$scope.formElement], (formElement) => {
                if (!formElement) {
                    return formElement;
                }

                if (formElement.$invalid) {
                    element.addClass('error');
                } else {
                    element.removeClass('error');
                }
            });
        }
    };
}
