import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../components/react/button.jsx'
import Checkbox from '../../../components/react/checkbox.jsx'
import {
    ALL_TEMPLATES_OPTION,
    DEFAULT_DATE_RANGE_PRESET,
    NO_TEMPLATE_OPTION,
    PROCESS_STATUS_FILTER_OPTIONS,
    TASK_STATUS_FILTER_OPTIONS
} from '../store/constants'
import { updateStatuses, updateTaskStatuses } from '../store/utils'
import DateRangeControl from './date-range-control.jsx'
import TemplateOption from './template-option.jsx'
import TemplateValue from './template-value.jsx'
import ReactSelectize from '../../../components/react/react-selectize.jsx'
import Tooltip from '../../react/tooltip.jsx'
import UserOption from './user-option.jsx'
import UserValue from './user-value.jsx'

import { translate } from '../../react/utils'

import '../less/filters.less'

class ReportFilters extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        options: PropTypes.object.isRequired,
        withTaskStatus: PropTypes.bool,
        updateFilters: PropTypes.func.isRequired,
        onApplyChanges: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
    }

    changeTemplates (values) {
        const { filters, updateFilters } = this.props
        const newTemplatesIds = values.map(v => v.value)
        updateFilters({ ...filters, templatesIds: newTemplatesIds })
    }

    changeStarters (values) {
        const { filters, updateFilters } = this.props
        const newStartersIds = values.map(v => v.value)
        updateFilters({ ...filters, startersIds: newStartersIds })
    }

    changeAssignees (values) {
        const { filters, updateFilters } = this.props
        const newAssigneesIds = values.map(v => v.value)
        updateFilters({ ...filters, assigneesIds: newAssigneesIds })
    }

    removeSelectedItem (id) {
        const { templatesIds } = this.props.filters
        this.changeTemplates(templatesIds.filter(templateId => templateId !== id))
    }

    changeDateRange (dateRange) {
        const { filters, updateFilters } = this.props
        updateFilters({ ...filters, dateRange })
    }

    changeStatus (status, state) {
        const { filters, updateFilters } = this.props
        const updatedStatuses = updateStatuses(filters.statuses, status, state)
        updateFilters({ ...filters, statuses: updatedStatuses })
    }

    changeTaskStatus (status, state) {
        const { filters, updateFilters } = this.props
        const updatedStatuses = updateTaskStatuses(filters.taskStatuses, status, state)
        updateFilters({ ...filters, taskStatuses: updatedStatuses })
    }

    filtersAreNotEmpty () {
        const { filters, withTaskStatus } = this.props
        const templatesIdsSelected = filters.templatesIds.length > 0
        const statusesSelected = filters.statuses.length > 0
        const taskStatusesSelected = !withTaskStatus || filters.taskStatuses.length > 0
        const dateRangeChanged = filters.dateRange.preset !== DEFAULT_DATE_RANGE_PRESET
        return templatesIdsSelected || statusesSelected || taskStatusesSelected || dateRangeChanged
    }

    filtersAreValid () {
        const { filters, withTaskStatus } = this.props
        const templatesIdsSelected = filters.templatesIds.length > 0
        const statusesSelected = filters.statuses.length > 0
        const taskStatusesSelected = !withTaskStatus || filters.taskStatuses.length > 0
        return templatesIdsSelected && statusesSelected && taskStatusesSelected && filters.dateRange.hasError === undefined
    }

    getTemplatesValue () {
        const { templatesIds = [] } = this.props.filters
        const { templates } = this.props.options

        return templatesIds.map(id => templates.find(tpl => tpl.value === id))
    }

    getStartersValue () {
        const { startersIds = [] } = this.props.filters
        const { users } = this.props.options

        return startersIds.map(id => users.find(user => user.value === id))
    }

    getAssigneesValue () {
        const { assigneesIds = [] } = this.props.filters
        const { users } = this.props.options

        return assigneesIds.map(id => users.find(user => user.value === id))
    }

    render () {
        const { filters, options, withTaskStatus, updateFilters, onApplyChanges } = this.props

        return (
            <div className="report-filters">
                <div className="filters-header">
                    <span>{translate('report.filters.header.label')}</span>
                    {this.filtersAreNotEmpty() && (
                        <Button style="link" onClick={() => updateFilters()}>
                            {translate('label.clear')}
                        </Button>
                    )}
                </div>
                <div className="filters-fields">
                    <div className="field">
                        <label className="field-label" htmlFor="filterTemplate">
                            {translate('report.filters.field.template.label')} *
                        </label>
                        <div>
                            <ReactSelectize
                                name="filterTemplate"
                                placeholder={translate('report.filters.field.template.placeholder', { isMulti: filters.multipleTemplates }, true)}
                                options={options.templates.map(this.translateSpecialTemplateValue)}
                                onChange={this.changeTemplates.bind(this)}
                                value={this.getTemplatesValue().map(this.translateSpecialTemplateValue)}
                                isMulti={filters.multipleTemplates}
                                isClearable={!filters.multipleTemplates}
                                components={{
                                    Option: TemplateOption,
                                    MultiValueLabel: TemplateValue,
                                    SingleValue: TemplateValue
                                }}
                                noOptionsMessage={() => (translate('report.filters.field.template.noResults'))}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="field-label" htmlFor="filterStarter">
                            {translate('report.filters.field.starter.label')}
                        </label>
                        <div>
                            <ReactSelectize
                                name="filterStarter"
                                placeholder={translate('report.filters.field.starter.placeholder')}
                                options={options.users}
                                onChange={this.changeStarters.bind(this)}
                                value={this.getStartersValue()}
                                isMulti={true}
                                isClearable={true}
                                components={{
                                    Option: UserOption,
                                    MultiValueLabel: UserValue,
                                    SingleValue: UserValue
                                }}
                                noOptionsMessage={() => translate('report.filters.field.starter.noResults')}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="field-label" htmlFor="filterDateRange">
                            {translate('report.filters.field.dateRange.label')} *
                        </label>
                        <DateRangeControl
                            values={filters.dateRange}
                            options={options.dateRange}
                            onChange={this.changeDateRange.bind(this)}
                        />
                    </div>
                    <div className="field">
                        <span className="field-label">
                            {translate('report.filters.field.processStatus.label')} *
                        </span>
                        <div className="status-list">
                            {PROCESS_STATUS_FILTER_OPTIONS.map((option) => (
                                <Checkbox
                                    key={option.id}
                                    label={translate(option.name)}
                                    className="filter-checkbox"
                                    checked={filters.statuses.find(s => s === option.id)}
                                    onChange={(state) => this.changeStatus(option, state)}
                                />
                            ))}
                        </div>
                    </div>
                    {withTaskStatus && (
                        <div className="field">
                            <label className="field-label" htmlFor="filterAssignee">
                                {translate('report.filters.field.assignee.label')}
                            </label>
                            <div>
                                <ReactSelectize
                                    name="filterAssignee"
                                    placeholder={translate('report.filters.field.assignee.placeholder')}
                                    options={options.users}
                                    onChange={this.changeAssignees.bind(this)}
                                    value={this.getAssigneesValue()}
                                    isMulti={true}
                                    isClearable={true}
                                    components={{
                                        Option: UserOption,
                                        MultiValueLabel: UserValue,
                                        SingleValue: UserValue
                                    }}
                                    noOptionsMessage={() => translate('report.filters.field.assignee.noResults')}
                                />
                            </div>
                        </div>
                    )}
                    {withTaskStatus && (
                        <div className="field">
                            <span className="field-label">
                                {translate('report.filters.field.taskStatus.label')} *
                            </span>
                            <div className="status-list">
                                {TASK_STATUS_FILTER_OPTIONS.map((option) => (
                                    <Checkbox
                                        key={option.id}
                                        label={translate(option.name)}
                                        className="filter-checkbox"
                                        checked={filters.taskStatuses.find(s => s === option.id)}
                                        onChange={(state) => this.changeTaskStatus(option, state)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {this.filtersAreValid() ? (
                    <Button
                        style="green"
                        onClick={onApplyChanges}
                    >
                        {translate('report.filters.applyButton')}
                    </Button>
                ) : (
                    <Tooltip text="tooltips.reportFilters" isDisabled={true}>
                        <Button
                            style="green"
                            disabled={true}
                        >
                            {translate('report.filters.applyButton')}
                        </Button>
                    </Tooltip>
                )}

            </div>
        )
    }

    translateSpecialTemplateValue = (template) => {
        if ([NO_TEMPLATE_OPTION.id, ALL_TEMPLATES_OPTION.id].includes(template.value)) {
            return { ...template, label: translate(template.label) }
        }
        return template
    }
}

export default ReportFilters
