import Drop from 'tether-drop';

export function selectTasksGroup($translate) {
    'ngInject';
    const types = [
        {id: 0, name: $translate.instant('tasks-group.noGroups')},
        {id: 1, name: $translate.instant('tasks-group.byProcess')},
        {id: 2, name: $translate.instant('tasks-group.byDueDate')}
    ];

    return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        scope: {
            ngModel: '=?'
        },
        template: require('./select-tasks-group.html'),
        link: (scope, element, attrs, ctrl) => {
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--tasks-group')[0],
                position: attrs.right ? 'bottom right' : 'bottom left',
                openOn: 'click'
            });

            scope.types = types.slice();
            scope.activeType = scope.types.find(t => t.id === scope.ngModel) || types[0];

            scope.setType = type => {
                if (type.id !== scope.ngModel) {
                    ctrl.$setViewValue(type.id);
                    drop.close();
                }
            };

            scope.getIcon = index => {
                return ['icon-process_tasks', 'icon-tasks_group_process', 'icon-task_due_date_add_small'][index];
            };

            scope.$watch('ngModel', val => {
                scope.activeType = scope.types.find(t => t.id === val) || types[0];
            });

            scope.$on('tether:close', () => {
                drop.close();
            });
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
