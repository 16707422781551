import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import UserOption from './user-option.jsx'
import UserValue from './user-value.jsx'
import { ClearIndicator, DropdownIndicator } from './index.jsx'
import { translate } from '../../../modules/react/utils'

const userSelectStyles = {
    container: provided => ({ ...provided, minWidth: 90 }),
    placeholder: (provided) => {
        provided.position = undefined
        provided.top = undefined
        provided.transform = undefined
        provided.maxWidth = undefined

        return { ...provided }
    },
    singleValue: (provided) => {
        provided.position = undefined
        provided.top = undefined
        provided.transform = undefined
        provided.maxWidth = undefined

        return { ...provided }
    },
    menu: (provided) => ({ ...provided, width: 'auto', zIndex: 10 })
}

class UserSelect extends React.Component {
    static propTypes = {
        isMulti: PropTypes.bool,
        options: PropTypes.array.isRequired,
        value: PropTypes.any,
        onChange: PropTypes.func,
        attachToBody: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        isMulti: false,
        attachToBody: false
    }

    constructor (props) {
        super(props)

        this.components = {
            DropdownIndicator,
            ClearIndicator,
            Option: UserOption,
            MultiValueLabel: UserValue,
            SingleValue: UserValue
        }
    }

    onChangeHandler (value) {
        const { isMulti, onChange } = this.props
        onChange(isMulti ? value : [value])
    }

    filterOption ({ label }, string) {
        return !string || label.toLowerCase().includes(string.toLowerCase())
    }

    render () {
        const { onChange, attachToBody, disabled, ...props } = this.props
        return (
            <Select
                styles={userSelectStyles}
                components={this.components}
                className="react-selectize user-field"
                classNamePrefix="react-selectize"
                placeholder={translate('label.selectUser')}
                menuPortalTarget={attachToBody ? document.body : undefined}
                menuPlacement={'auto'}
                onChange={this.onChangeHandler.bind(this)}
                filterOption={this.filterOption.bind(this)}
                isClearable
                isDisabled={disabled}
                {...props}
            />
        )
    }
}

export default UserSelect
