import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import clsx from 'clsx'

import { getHighlightedText } from '../../modules/react'
import { getService, translate } from '../../modules/react/utils'
import { entityAsSelectOption } from '../../services/data-types-service'
import { getFormattedRecordValues } from '../../modules/database/models/database-utils'

import './table-source-field.less'

const DEFAULT_RECORDS_COUNT = 200

class TableSourceField extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        tableId: PropTypes.string,
        templateId: PropTypes.string,
        processId: PropTypes.string,
        taskId: PropTypes.string,
        fieldId: PropTypes.string,
        displayFields: PropTypes.array,
        onChange: PropTypes.func,
        isMulti: PropTypes.bool,
        isDisabled: PropTypes.bool,
        withEmptyValue: PropTypes.bool
    }

    static defaultProps = {
        isMulti: false,
        attachToBody: false,
        withEmptyValue: true
    }

    constructor (props) {
        super(props)

        this.state = {
            records: [],
            searchString: '',
            nextOffset: undefined,
            isLoaded: false
        }

        this.ApiCalls = getService('ApiCalls')
        this.components = {
            Option,
            DropdownIndicator,
            ClearIndicator,
            ValueContainer,
            MultiValueLabel: ValueLabel,
            SingleValue: ValueLabel
        }
    }

    render () {
        const { isMulti, isDisabled } = this.props
        const { records } = this.state
        const value = this.getValue()

        return (
            <AsyncSelect
                styles={this.getComputedStyles()}
                className={clsx('table-source-field', { disabled: isDisabled })}
                classNamePrefix="table-source-field"
                menuPortalTarget={document.body}
                menuPosition={'absolute'}
                menuPlacement={'auto'}
                onChange={this.onChangeHandler}
                cacheOptions
                loadOptions={this.loadOptions}
                defaultOptions={this.getPreparedOptions(records)}
                formatOptionLabel={(object, { inputValue }) => {
                    return inputValue ? getHighlightedText(object.label, inputValue) : object.label
                }}
                value={value}
                isMulti={isMulti}
                isDisabled={isDisabled}
                components={this.components}
                isOptionDisabled={(option) => option.isDisabled}
                // menuIsOpen
            />
        )
    }

    componentDidMount () {
        this._ismounted = true
        if (!this.props.isDisabled) {
            this.loadDefaultOptions()
        }
    }

    componentWillUnmount () {
        this._ismounted = false
    }

    componentDidUpdate (prevProps) {
        const fieldsString = this.props.displayFields ? this.props.displayFields.join(',') : ''
        const prevFieldsString = prevProps.displayFields ? prevProps.displayFields.join(',') : ''
        if ((prevProps.isDisabled && !this.props.isDisabled) || (fieldsString !== prevFieldsString)) {
            this.loadDefaultOptions()
        }
    }

    filterRecords = searchString => {
        const { records } = this.state
        const filtered = records.filter(record => !searchString || record.name.toLowerCase().includes(searchString.toLowerCase()))
        return this.getPreparedOptions(filtered, false)
    }

    loadDefaultOptions = () => {
        this.fetchRecords().then(response => {
            if (this._ismounted) {
                const { nextOffset } = response
                const records = this.prepareRecords(response)
                this.setState({
                    nextOffset,
                    records,
                    isLoaded: true
                })
            }
        })
    }

    loadOptions = (searchString) => {
        const { nextOffset } = this.state
        if (nextOffset) {
            return new Promise(resolve => {
                this.fetchRecords(searchString).then(response => {
                    const records = this.prepareRecords(response)
                    resolve(this.getPreparedOptions(records, false))
                })
            })
        } else {
            return new Promise(resolve => {
                resolve(this.filterRecords(searchString))
            })
        }
    }

    prepareRecords = ({ list, columns }) => {
        return list.map(record =>
            getFormattedRecordValues({
                ...record,
                values: record.values.map(({ value }, index) => ({
                    ...value,
                    dataType: columns[index].dataType
                }))
            })
        )
    }

    getValue = () => {
        if (this.props.isMulti) {
            return this.getMultiValue()
        } else {
            return this.getSingleValue()
        }
    }

    getMultiValue = () => {
        const { value } = this.props
        return value.map(v => {
            const recordData = {
                ...v,
                id: v.recordId
            }
            return entityAsSelectOption(getFormattedRecordValues(recordData))
        }).filter(v => v)
    }

    getSingleValue = () => {
        const { value } = this.props
        return value ? entityAsSelectOption(getFormattedRecordValues(value)) : null
    }

    onChangeHandler = (selected) => {
        const { isMulti, onChange } = this.props
        if (!isMulti) {
            const { value, label, ...attrs } = selected
            if (onChange) {
                onChange(value ? { id: value, name: label, ...attrs } : undefined)
            }
        } else {
            let values = selected ? selected.map(({ value, label, ...attrs }) => {
                return { id: value, name: label, ...attrs }
            }) : []
            if (onChange) {
                onChange(values)
            }
        }
    }

    fetchRecords = (searchString) => {
        const { tableId, templateId, processId, taskId, fieldId, displayFields = [] } = this.props

        const params = {
            count: !searchString ? DEFAULT_RECORDS_COUNT : undefined,
            searchString,
            displayFields: displayFields.length ? displayFields.join(',') : undefined
        }

        let request
        if (templateId) {
            request = this.ApiCalls.getSourceTableRecordsForTemplate(tableId, templateId, fieldId, params)
        } else if (processId) {
            request = this.ApiCalls.getSourceTableRecordsForProcess(tableId, processId, fieldId, params)
        } else if (taskId) {
            request = this.ApiCalls.getSourceTableRecordsForTask(tableId, taskId, fieldId, params)
        } else {
            request = this.ApiCalls.getSourceTableRecords(tableId, params)
        }

        return request
    }

    getPreparedOptions = (options, withSearchHint = true) => {
        const { isMulti, withEmptyValue } = this.props
        const { nextOffset } = this.state
        const startItems = withEmptyValue && !isMulti ? [{ value: null, label: translate('label.emptyValue') }] : []
        const endItems = withSearchHint && nextOffset ? [{
            value: null,
            label: translate('text.searchRecords'),
            isDisabled: true
        }] : []
        return [...startItems, ...options.map(entityAsSelectOption), ...endItems]
    }

    getComputedStyles = () => {
        return {
            singleValue: provided => ({
                ...provided,
                color: undefined,
                marginLeft: undefined,
                marginRight: undefined,
                maxWidth: undefined
            }),
            menuPortal: base => ({ ...base, zIndex: 10000 }),
            container: base => ({ ...base, minWidth: 150, maxWidth: 615 }),
            clearIndicator: base => ({ ...base, color: undefined }),
            valueContainer: base => ({ ...base, padding: undefined }),
            placeholder: base => ({ ...base, marginRight: undefined, marginLeft: undefined })
        }
    }
}

export default TableSourceField

const ValueContainer = ({ children, ...props }) => {
    return (
        <components.ValueContainer {...props}>
            {children}
            <div className="value-container-stretcher"/>
        </components.ValueContainer>
    )
}

const ValueLabel = ({ children, data, ...props }) => {
    let iconComponent = data.isInvalid ? (
        <span className="value-is-deleted" style={{ color: '' }}>
            <i className="icon-assignee_badge_issued"/>
        </span>
    ) : null

    return props.selectProps.isMulti ? (
        <components.MultiValueLabel {...props}>
            {iconComponent}
            {children}
        </components.MultiValueLabel>
    ) : (
        <components.SingleValue {...props}>
            {iconComponent}
            {children}
        </components.SingleValue>
    )
}

const Option = ({ children, data, ...props }) => {
    return (
        <components.Option {...props}>
            <span className={clsx('option-label', { 'empty-value': !data.value })}>
                {children}
            </span>
        </components.Option>
    )
}

const DropdownIndicator = ({ ...props }) => {
    return props.selectProps.isMulti ? null : (
        <components.DropdownIndicator {...props}>
            <i className="icon-common_arrow_down"/>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = ({ ...props }) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon-common_close"/>
        </components.ClearIndicator>
    )
}
