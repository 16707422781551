import React from 'react'
import PropTypes from 'prop-types'
import UserAvatar from './user-avatar.jsx'

import './user-label.less'

function UserLabel ({ user }) {
    return (
        <span className={'user-label'}>
            <span className={'user-label-icon'}>
                <UserAvatar user={user}/>
            </span>
            <span className={'user-label-name'}>
                {user.fullName}
            </span>
        </span>
    )
}

UserLabel.propTypes = {
    user: PropTypes.object
}

export default UserLabel
