import utils, { checkShowTotal, fieldValueToString } from '../modules/processes/utils'
import { copyToClipboard, uid4 } from '../utils'
import dataModelUtils, {
    calculateDataForm,
    getErrorSourceFieldName
} from '../modules/templates/services/data-model-utils'
import { getFormattedMultiChoiceValue, getFormattedRadioButtonValue } from '../modules/database/models/database-utils'
import { AGGREGATION, FORMULA } from '../services/data-types'

const FIELD_SAVED_STATUS_DELAY = 1000

export function taskDataModel ($timeout, $filter, $translate, $modal, $q, $state, $stateParams,
    ApiCalls, PageSettings, ProcessesSettings, DevicesDetector, DateHelper, currentUser) {
    'ngInject'
    const getFieldValueAsString = field => fieldValueToString(field)

    return {
        restrict: 'E',
        replace: true,
        scope: {
            task: '=',
            onSavedField: '&',
            onFieldChange: '&',
            saveFieldErrorHandler: '&',
            isSaving: '=',
            isHideNotEditableFields: '=',
            isHidePreviewButton: '=',
            isDisableSendSaveRequest: '<',
            isProcessStartMode: '<'
        },
        template: require('../templates/task-data-model.html'),
        link: ($scope, element, attrs) => {
            $scope.uploadTaskFiles = $scope.uploadTaskFiles || false
            $scope.dataModel = $scope.task.dataModel
            $scope.DevicesDetector = DevicesDetector
            $scope.isSavingRow = $scope.isSavingField = false

            $scope.dumpField = field => { // first init savedValue
                field.editMode = true
                field.$originalValue = getFieldValueAsString(field)
            }

            $scope.checkStringType = dataModelUtils.checkStringType
            $scope.checkEmailType = dataModelUtils.checkEmailType
            $scope.checkMoneyType = dataModelUtils.checkMoneyType
            $scope.checkDateType = dataModelUtils.checkDateType
            $scope.checkNumberType = dataModelUtils.checkNumberType
            $scope.checkMultiselectType = dataModelUtils.checkMultiselectType
            $scope.checkRadioType = dataModelUtils.checkRadioType
            $scope.checkFilesType = dataModelUtils.checkFilesType
            $scope.checkYesNoType = dataModelUtils.checkYesNoType
            $scope.checkUserFieldType = dataModelUtils.checkUserFieldType
            $scope.checkFormulaFieldType = dataModelUtils.checkFormulaFieldType
            $scope.getNumberWidth = dataModelUtils.getNumberWidth
            $scope.checkFieldError = dataModelUtils.checkFieldError
            $scope.getTotalOfColumn = (section, fieldIndex) => utils.getTotalOfColumn($scope.dataModel.list, section, fieldIndex)
            $scope.updateValue = (field, useDefaultValue, useProcessStartedDate) => {
                if (!field.value) {
                    field.value = {}
                }

                const processStartedDate = useProcessStartedDate && $scope.task.process
                    ? $scope.task.process.startedDate
                    : undefined
                dataModelUtils.updateValue(field, useDefaultValue, DateHelper.getTZ(), processStartedDate)

                const { defaultValue, options } = field.name

                if (dataModelUtils.checkUserFieldType(field) && useDefaultValue && !field.value.userValue && defaultValue) {
                    if (defaultValue.specialValue && defaultValue.specialValue === 'PROCESS_STARTER') {
                        const processStarter = { id: currentUser.id, fullName: currentUser.fullName }
                        if (options.allUsers || options.allowedUsers.find(u => u.id === processStarter.id)) {
                            field.value.userValue = processStarter
                        }
                    } else if (defaultValue.specialValue && defaultValue.specialValue === 'PROCESS_STARTER_DIRECT_MANAGER') {
                        ApiCalls.getDirectManager().then(({ directManager }) => {
                            if (directManager && (options.allUsers || options.allowedUsers.find(u => u.id === directManager.id))) {
                                field.value.userValue = directManager
                            }
                        })
                    }
                }
            }

            $scope.getMinLength = field => {
                let fieldData = field.name || field
                if (fieldData.options && fieldData.options.minLength) {
                    return angular.toJson({ value: fieldData.options.minLength })
                }
            }

            $scope.getHints = (field) => dataModelUtils.getFieldHints(field, $translate, $filter)

            $scope.getValue = (field, key) => {
                let fieldData = field.name || field
                if (fieldData.options && fieldData.options[key] != null) {
                    return angular.toJson({ value: fieldData.options[key] })
                }
            }

            $scope.checkSectionError = section => {
                if (section.isClosed) {
                    if (section.fieldsWithValues
                        && section.fieldsWithValues.find(f => $scope.checkFieldError(f) === 'required')) {
                        return true
                    }
                    return section.rows && section.rows.find(r => $scope.checkRowErrors(section, r) === 'required')
                }
            }

            if (attrs.isSaving !== undefined) {
                $scope.$watch('isSavingField || isSavingRow', isSomeSaving => {
                    $scope.isSaving = isSomeSaving
                })
            }

            const saveFieldAction = (field) => {
                const fieldError = $scope.checkFieldError(field)
                if (fieldError && fieldError !== 'required' || field.$originalValue === getFieldValueAsString(field)) {
                    return $q.reject()
                }

                const { id } = field.name || field
                const el = document.getElementById(id) //eslint-disable-line
                if (el && el.blur) {
                    el.blur()
                }

                const putData = dataModelUtils.getFieldValueForSaving(field)

                const apiCall = ApiCalls.updateSingleTaskField($scope.task.id, field.name.id, putData)

                const originalValue = getFieldValueAsString(field)

                apiCall.then(() => {
                    if ($scope.checkUserFieldType(field)) {
                        field.saving = true
                        ApiCalls.getTask($scope.task.id).then(data => {
                            let updatedField
                            let { dataModel = { list: [] } } = data
                            dataModel.list.forEach(s => {
                                if (!updatedField) {
                                    updatedField = s.section.fieldsWithValues.find(f => f.name.id === field.name.id)
                                }
                            })
                            if (updatedField) {
                                field.name = updatedField.name
                                field.settings = updatedField.settings
                                field.value = updatedField.value
                            }
                            field.saving = false
                            field.saved = true
                            field.editMode = false
                            field.$originalValue = originalValue

                            // Update selected task fields
                            $scope.task.assignee = data.assignee
                            $scope.task.actors = data.actors
                            $scope.task.actorsForEdit = data.actorsForEdit
                            $scope.task.permissions = data.permissions

                            $scope.onSavedField({ field: field })
                            $timeout(() => field.saved = false, FIELD_SAVED_STATUS_DELAY)
                        })
                    } else {
                        field.$originalValue = originalValue
                        field.saved = true
                        field.editMode = false
                        $scope.onSavedField({ field: field })
                        $timeout(() => field.saved = false, FIELD_SAVED_STATUS_DELAY)
                    }
                }).catch((errResponse) => {
                    if ($scope.checkUserFieldType(field) && errResponse.status === 400) {
                        $timeout(() => {
                            field.value = null
                            field.name.options.allowedUsers = field.name.options.allowedUsers.filter(user => user.id !== putData.userValue.id)
                        })

                        return ProcessesSettings.callUserFieldAccessErrorModal(errResponse)
                    }

                    if ($scope.saveFieldErrorHandler) {
                        return $scope.saveFieldErrorHandler({ errResponse })
                    }
                })

                return apiCall
            }

            $scope.saveFieldQueue = (field) => {
                field.isChanged = true
                const fieldError = $scope.checkFieldError(field)

                field.editMode = true
                field.hasDeletedRecord = fieldError === 'deletedRecord'

                if ($scope.onFieldChange) {
                    $timeout(() => $scope.onFieldChange(), 100)
                }

                if ($scope.isProcessStartMode) {
                    calculateDataForm($scope.dataModel, field.name.id)
                }

                if (fieldError && fieldError !== 'required' || $scope.isDisableSendSaveRequest) {
                    field.editMode = false
                    return
                }

                let action

                action = () => {
                    $scope.isSavingField = true
                    field.saving = true
                    field.isChanged = false
                    field.editMode = false
                    saveFieldAction(field)
                        .then(() => {
                            if (field.isChanged) {
                                action()
                            }
                        })
                        .finally(() => {
                            field.saving = false
                            $scope.isSavingField = false
                            field.hasDeletedRecord = false
                        })
                }

                if (field.saving) {
                    field.isChanged = true
                } else {
                    action()
                }
            }

            $scope.fieldKeyHandler = (event, field) => {
                let fieldData = field.name || field
                if (fieldData.dataType === 'STRING_MULTI_LINE') {
                    if (event && (event.which === 10 || event.which === 13) && event.ctrlKey) {
                        event.preventDefault()
                        $scope.saveFieldQueue(field)
                    }
                } else if (event && event.which === 13) {
                    event.preventDefault()
                    $scope.saveFieldQueue(field)
                }
            }

            $scope.newRow = ($event, section) => {
                if ($event) {
                    $event.stopPropagation()
                }
                if ($scope.isProcessStartMode) {
                    return editDataModelTableRow(section)
                } else {
                    $state.go('main.task.newRow', { sectionId: section.id })
                }
            }

            $scope.editRow = ($event, section, row) => {
                if ($event) {
                    $event.stopPropagation()
                }
                if ($scope.isProcessStartMode) {
                    return editDataModelTableRow(section, row)
                } else {
                    $state.go('main.task.editRow', { sectionId: section.id, rowIndex: row.rowIndex })
                }
            }

            let modalInstance

            const editDataModelTableRow = (section, row) => {
                if (!$scope.checkTableIsEditable(section)) {
                    return
                }

                const dataModelCopy = angular.copy($scope.dataModel)
                const modalScope = $scope.$new()
                modalScope.section = dataModelCopy.list.find(s => s.section.id === section.id)?.section
                modalScope.taskId = $scope.task.id
                modalScope.isProcessStartMode = $scope.isProcessStartMode

                if (row) {
                    // Edit existed row case
                    modalScope.row = angular.copy(modalScope.section.rows.find(r => r.rowIndex === row.rowIndex))
                    modalScope.section.columns.forEach(c => {
                        let valueT = modalScope.row.values.find(v => v.columnId === c.name.id)
                        if (valueT && valueT.value && !valueT.value.noValue) {
                            c.value = valueT.value
                        }
                    })
                    row.editMode = true
                    modalScope.$watch(() => row.isDeleted, (isDeleted) => {
                        if (isDeleted) {
                            modalScope.isNew = true
                            modalScope.isDeleted = true
                        }
                    })
                } else {
                    // Add new row case
                    modalScope.isNew = true
                }

                modalScope.isIgnoreSaveRow = $scope.isDisableSendSaveRequest
                modalScope.onChangeSavingState = isSaving => {
                    $scope.isSavingRow = isSaving
                }

                if (!modalScope.section.rows) {
                    modalScope.section.rows = []
                }

                modalScope.calculateFormulaForField = fieldId => {
                    const realColumnsValues = modalScope.section.columns.map(c => ({
                        columnId: c.name.id,
                        value: c.value
                    }))
                    const existedRow = modalScope.section.rows.find(r => modalScope.row && r.rowIndex === modalScope.row.rowIndex)
                    if (!existedRow) {
                        const newRow = { rowIndex: uid4(), values: realColumnsValues }
                        modalScope.section.rows.push(newRow)
                        modalScope.row = newRow
                    } else {
                        existedRow.values = realColumnsValues
                        modalScope.row = existedRow
                    }
                    calculateDataForm(dataModelCopy, fieldId, modalScope.row.rowIndex)
                    modalScope.section.columns.forEach(c => {
                        if (c.name.dataType === FORMULA) {
                            const valueT = modalScope.row.values.find(v => v.columnId === c.name.id)
                            if (valueT && valueT.value && !valueT.value.noValue && Object.keys(valueT.value).length) {
                                c.value = valueT.value
                            } else {
                                c.value = undefined
                            }
                        }
                    })
                }

                modalScope.section.columns.forEach(c => {
                    $scope.updateValue(c, modalScope.isNew, true)
                    if (modalScope.isNew) {
                        modalScope.calculateFormulaForField(c.name.id)
                    }
                })

                modalInstance = $modal.open({
                    animation: true,
                    template: require('../templates/modals/data-model-table-row.html'),
                    controller: 'DataModelTableRowController',
                    windowClass: 'data-model-table-row-modal',
                    backdrop: 'static',
                    keyboard: false,
                    scope: modalScope
                })
                modalInstance.result.then(savedRow => {
                    const { values, ...rowData } = savedRow
                    const realValues = values.filter(({ columnId }) => section.fieldsWithValues.find(field => field.name.id === columnId && field.name.dataType !== AGGREGATION))
                    if (row) {
                        // Edit existed row case
                        Object.assign(row, rowData, { values: realValues })
                    } else {
                        // Add new row case
                        section.rows = [...(section.rows || []), { ...rowData, values: realValues }]
                    }

                    if ($scope.isProcessStartMode) {
                        values.forEach(({ columnId }) => calculateDataForm($scope.dataModel, columnId))
                    }
                }).finally(() => {
                    modalScope.$destroy()
                    if (row) {
                        row.editMode = false
                    }
                    if (!$scope.isProcessStartMode) {
                        $state.go('main.task')
                    }
                })
            }

            $scope.expand = (section, event) => {
                if (event) {
                    event.stopPropagation()
                }
                $state.go('main.task.tableView', { sectionId: section.id })
            }

            const expandTableSection = (section) => {
                let modalScope = $scope.$new()
                modalScope.section = section
                modalInstance = $modal.open({
                    animation: true,
                    template: require('../templates/modals/data-model-table-full-view.html'),
                    controller: 'ModalInstanceController',
                    windowClass: 'data-model-table-full-view',
                    scope: modalScope
                })
                modalInstance.result.finally(() => {
                    modalScope.$destroy()
                    $state.go('main.task')
                })
            }

            let isRowDeletion = false
            $scope.deleteRow = (section, rowIndex, event) => {
                if (event) {
                    event.stopPropagation()
                }
                if (isRowDeletion) {
                    return
                }
                const deleteFromList = () => {
                    const s = $scope.dataModel.list.find(i => i.section.id === section.id)?.section
                    if (s?.rows) {
                        s.rows = s.rows.filter(r => r.rowIndex !== rowIndex)
                    }
                }
                if (!$scope.isDisableSendSaveRequest) {
                    isRowDeletion = true
                    let modalInstance = PageSettings.deleteConfirmation('tableSection')
                    modalInstance.result.then(() => {
                        $scope.isSavingRow = true
                        ApiCalls.deleteDataModelTableRow($scope.task.id, section.id, rowIndex)
                            .then(() => deleteFromList())
                            .catch(ProcessesSettings.callAlertTaskPageModal)
                            .finally(() => {
                                $scope.isSavingRow = false
                                return isRowDeletion = false
                            })
                    }).catch(() => {
                        isRowDeletion = false
                    })
                } else {
                    let modalInstance = PageSettings.deleteConfirmation('tableSection')
                    modalInstance.result.then(() => {
                        deleteFromList()
                        section.fieldsWithValues.forEach(f => {
                            calculateDataForm($scope.dataModel, f.name.id)
                        })
                    })
                }
            }
            $scope.checkRowErrors = (section, row) => {
                let sectionT = angular.copy(section)
                sectionT.columns.forEach(c => {
                    let valueT = row.values.find(v => v.columnId === c.name.id)
                    if (valueT && valueT.value && !valueT.value.noValue) {
                        c.value = valueT.value
                    } else {
                        c.value = {}
                    }
                })
                let res = sectionT.columns.find(f => $scope.checkFieldError(f))
                if (res) {
                    return $scope.checkFieldError(res)
                }
            }

            $scope.checkShowTotal = checkShowTotal
            $scope.checkTableIsEditable = (section) => {
                return section.columns.find(c => !c.settings || c.settings.isEditable)
            }
            $scope.mapTableSectionField = (field, column) => {
                return Object.assign(field, { name: column.name })
            }
            $scope.getClassesForColumn = (section, columnIndex, row) => {
                let columnT = angular.copy(section.columns[columnIndex])
                let classes = $scope.getColumnClasses(columnT)
                let valueT = row.values.find(v => v.columnId === columnT.name.id)
                if (valueT && valueT.value && !valueT.value.noValue) {
                    columnT.value = valueT.value
                } else {
                    columnT.value = {}
                }
                if ($scope.checkFieldError(columnT)) {
                    classes = classes + ' error'
                }
                return classes
            }

            $scope.copyFieldValue = (field, event) => {
                if (event) {
                    event.preventDefault()
                }

                const string = getFieldValueAsString(field)

                if (string && copyToClipboard(string)) {
                    PageSettings.toasterData = {
                        iconClass: 'icon-template_duplicate_roundless',
                        text: $translate.instant('text.toaster.copyFieldValue', { fieldName: field.name.label }),
                        timeout: 3000
                    }
                }
            }

            $scope.openFilesPreview = (field) => {
                field.preview = true
                field.onPreviewClose = () => {
                    field.preview = false
                }
            }

            $scope.checkFieldValue = field => getFieldValueAsString(field).length > 0

            $scope.fieldIsRequired = (field) => {
                return field.settings && field.settings.isRequiredToFill
            }

            $scope.getColumnClasses = column => {
                if (column.name.dataType === FORMULA) {
                    return [FORMULA, column.name.options.format.options.dataType]
                }
                return column.name.dataType
            }

            let initialized = false
            $scope.$watch('task', (newTask) => {
                $scope.dataModel = newTask.dataModel

                $scope.dataModel.list.filter(s => !s.section.isTable).reduce((fields, s) => fields.concat(s.section.fieldsWithValues), []).forEach(f => {
                    $scope.updateValue(f, $scope.isProcessStartMode)
                })

                if ($scope.isProcessStartMode && !initialized) {
                    initialized = true
                    $scope.dataModel.list.reduce((fields, s) => fields.concat(s.section.fieldsWithValues), []).filter(f => f.name.dataType === FORMULA).forEach(f => {
                        calculateDataForm($scope.dataModel, f.name.id)
                    })
                }
            })

            $scope.formatDropdownFieldValue = field => {
                return getFormattedRadioButtonValue(field.value.radioButtonValue)
            }

            $scope.formatMultiselectFieldValue = field => {
                return getFormattedMultiChoiceValue(field.value.multiChoiceValue).join(', ')
            }

            $scope.getFormulaCalculationError = field => {
                const errorSourceFieldName = getErrorSourceFieldName(field, $scope.dataModel)
                if (errorSourceFieldName) {
                    return $translate.instant('validation.calculation.divisionByZero.withFieldId', { fieldId: errorSourceFieldName })
                }
                return 'validation.calculation.divisionByZero'
            }

            let activeScenario

            const runScenario = (scenario, params = {}) => {
                if (scenario === activeScenario) return
                if (modalInstance) {
                    modalInstance.close(false)
                }

                activeScenario = scenario

                if (scenario === 'NEW_ROW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    if (section) {
                        return editDataModelTableRow(section)
                    }
                    $state.go('main.task')
                }

                if (scenario === 'EDIT_ROW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    const row = section ? section.rows.find(r => Number(r.rowIndex) === Number(params.rowIndex)) : undefined
                    if (section && row) {
                        return editDataModelTableRow(section, row)
                    }
                    $state.go('main.task')
                }

                if (scenario === 'TABLE_VIEW') {
                    const section = $scope.dataModel.list.find(item => item.section.id === params.sectionId)?.section
                    if (section) {
                        return expandTableSection(section)
                    }
                    $state.go('main.task')
                }
            }

            if ($state.current.data?.scenario) {
                runScenario($state.current.data.scenario, $stateParams)
            }

            $scope.$on('$stateChangeSuccess', (event, state, params) => {
                runScenario(state.data.scenario, params)
            })
        }
    }
}
