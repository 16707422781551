/*global $*/

import { prepareStaticProcessName } from '../modules/processes/utils'
import { getService } from '../modules/react/utils'

export function PageSettings($rootScope, $modal, $state, $translate, $window, $timeout, DevicesDetector) {
    'ngInject';
    let service = {
        deferredActionsInfo: [],
        itemsPerPage: 35,
        homeItemsPerPage: 50,
        isSafari: navigator && navigator.userAgent
            ? navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
            : true,
        isPhoneWidth: $window.innerWidth < 768,
        phoneScreenMode() {
            return $window.innerWidth < 768;
        },
        smallScreensMode: () => $window.innerWidth <= 1023,
        phonePortraitMode: () => $window.innerWidth <= 414,
        recalcDetails: () => {
            service.isPhoneWidth = $window.innerWidth < 768;
        },
        recalcElementTextIndent: el => {
            if (DevicesDetector.isMobile()) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    if (window.innerWidth <= 768) {
                        el.css('text-indent', '-3px');
                    }
                } else if (window.innerWidth >= 768) {
                    el.css('text-indent', '3px');
                }
            } else if (window.innerWidth === 1024) {
                el.css('text-indent', '3px');
            }
        },
        scrollToElement: (elId, parentSelector) => {
            const el = $('#' + elId);
            $(parentSelector).animate({
                scrollTop: el.offset().top - $(parentSelector).offset().top + $(parentSelector).scrollTop() - 40
            });
            $timeout(() => el.focus(), 300);
        },
        startProcess: (tmpl) => {
            let modalScope = $rootScope.$new()

            // if (!tmpl.permissions?.includes('edit')) {}

            modalScope.template = angular.copy(tmpl)
            modalScope.processName = prepareStaticProcessName(tmpl.name)

            let modalInstance = $modal.open({
                animation: true,
                template: require('../templates/modals/start-process.html'),
                controller: 'StartProcessModalController',
                windowClass: 'start-process-modal',
                backdrop: 'static',
                scope: modalScope
            })
            modalInstance.result.finally(() => modalScope.$destroy())

            return modalInstance
        },
        copyTemplate: (tmpl) => {
            let modalScope = $rootScope.$new()
            modalScope.template = tmpl
            let modalInstance = $modal.open({
                animation: true,
                template: require('../templates/modals/copy-template.html'),
                controller: 'CopyTemplateModalController',
                windowClass: 'copy-template-modal',
                backdrop: 'static',
                scope: modalScope
            })
            modalInstance.result.finally(() => modalScope.$destroy())

            return modalInstance
        },
        openAlertModal: (modalScope) => {
            return $modal.open({
                animation: true,
                windowClass: 'alert-modal',
                template: require('../templates/modals/alert.html'),
                controller: 'ModalInstanceController',
                scope: modalScope,
                backdrop: 'static'
            }).result;
        },
        errorHandlerModal: (errResp, notFoundAction, callback) => {
            if (errResp.status === 515 && notFoundAction) {
                notFoundAction();
            }
            if (errResp.status === 473) {
                let modalScope = $rootScope.$new();
                modalScope.title = $translate.instant('permissions.error.title');
                modalScope.text = errResp.data
                    ? errResp.data.displayError || $translate.instant('permissions.error.text')
                    : $translate.instant('permissions.error.text');
                service.openAlertModal(modalScope)
                    .then(() => {
                        if (callback) {
                            callback();
                        }
                        $window.location.reload();
                    })
                    .finally(() => modalScope.$destroy());
            }
        },
        createGroupHandlerModal: (errorMsg, errorResp) => {
            if(errorResp.status !== 403) {
                let modalScope = $rootScope.$new();
                modalScope.title = $translate.instant('error.commonTitle');
                modalScope.text = errorMsg;
                modalScope.additionalText = $translate.instant('error.okText');
                service.openAlertModal(modalScope)
                    .then(() => $window.location.reload())
                    .finally(() => modalScope.$destroy());
            } else {
                let modalScope = $rootScope.$new();
                modalScope.title = $translate.instant('manageRole.error.title');
                modalScope.text = $translate.instant('manageRole.error.text');
                service.openAlertModal(modalScope)
                    .then(() => $window.location.reload())
                    .finally(() => modalScope.$destroy());
            }
        },
        deleteConfirmation: (suffix, end) => {
            let modalScope = $rootScope.$new();
            modalScope.title = $translate.instant(suffix + '.delete.title');
            modalScope.text = $translate.instant(suffix + '.delete.confirmation');
            modalScope.confirmText = $translate.instant('label.delete');
            modalScope.cancelText = $translate.instant('label.cancel');
            modalScope.warning = true;
            modalScope.end = end;

            if (suffix === 'template') {
                modalScope.typedConfirmation = {
                    requested: 'template',
                    entered: ''
                };
            }

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'confirm-alt-modal',
                template: require('../templates/modals/confirm-alt.html'),
                controller: 'ModalInstanceController',
                scope: modalScope,
                backdrop: 'static',
                keyboard: false
            });

            modalInstance.result.finally(() => modalScope.$destroy());
            return modalInstance;
        },
        processCancelConfirmation: () => {
            let modalScope = $rootScope.$new();
            modalScope.title = $translate.instant( 'process.cancellation.title')
            modalScope.text = $translate.instant('task.rejectReasonModal.text')
            modalScope.confirmText = $translate.instant('label.stopProcess')
            modalScope.cancelText = $translate.instant('label.cancel')

            let modalInstance = $modal.open({
                animation: true,
                template: require('../templates/modals/confirm-with-reason.html'),
                controller: 'ConfirmWithReasonController',
                scope: modalScope,
                backdrop: 'static',
                keyboard: false
            })

            modalInstance.result.finally(() => modalScope.$destroy())
            return modalInstance
        },
        openWarningsModal: (warnings, screen) => {
            let modalScope = $rootScope.$new();
            modalScope.title = $translate.instant(screen === 'groups' ? 'group.delete.title' : 'user.disable.title');
            modalScope.confirmText = $translate.instant(screen === 'groups' ? 'group.delete.btn' : 'user.disable.btn');
            let warningsArr = [];
            if (warnings.tasks) {
                warningsArr = warningsArr.concat(warnings.tasks);
            }
            if (warnings.processes) {
                warningsArr = warningsArr.concat(warnings.processes);
            }
            if (warnings.templates) {
                warningsArr = warningsArr.concat(warnings.templates.map(t => {
                    t.isTemplate = true;
                    return t;
                }));
            }
            modalScope.warnings = warningsArr;

            let modalInstance = $modal.open({
                animation: true,
                template: require('../templates/modals/delete-warnings.html'),
                controller: 'DeleteWarningsController',
                windowClass: 'delete-warnings',
                scope: modalScope
            });
            modalInstance.result.finally(() => modalScope.$destroy());
            return modalInstance;
        },
        inviteUser: () => {
            return $modal.open({
                animation: true,
                windowClass: 'invite',
                template: require('../templates/modals/invite.html'),
                controller: 'InviteModalController',
                backdrop: 'static',
                resolve: {
                    groups: () => {
                        return getService('ApiCalls').getActorsGroups();
                    },
                    preloadedState: () => {
                        return undefined;
                    }
                }
            })
        },
        templatesGallery: (modalScope) => {
            return $modal.open({
                animation: true,
                windowClass: 'tmpl-gallery-modal popup',
                template: require('../templates/modals/tmpl-gallery.html'),
                controller: 'TmplGalleryController',
                scope: modalScope,
                backdrop: 'static',
                keyboard: false
            })
        }
    };

    service.openErrorModal =  ({title, text} = {}) => {
        let modalScope = $rootScope.$new();
        modalScope.title = title || $translate.instant('error.commonTitle');
        modalScope.text = text || $translate.instant('error.common');
        service.openAlertModal(modalScope)
            .finally(() => {
                modalScope.$destroy();
            });
    }

    service.savingIndicator = undefined
    service.openSavingIndicator = (type) => {
        $rootScope.$applyAsync(() => {
            service.savingIndicator = type
        })
    }

    service.closeSavingIndicator = (type) => {
        if (service.savingIndicator === type) {
            $rootScope.$applyAsync(() => {
                service.savingIndicator = undefined
            })
        }
    }

    service.openScheduleRestrictedModal = () => {
        let modalScope = $rootScope.$new()
        modalScope.title = $translate.instant('schedule.title.isRestricted')
        modalScope.text = $translate.instant('schedule.text.isRestricted')
        modalScope.confirmText = $translate.instant('label.template.edit')
        modalScope.cancelText = $translate.instant('label.cancel')
        modalScope.expire = true

        let modalInstance = $modal.open({
            animation: true,
            windowClass: 'confirm-alt-modal',
            template: require('../templates/modals/confirm-alt.html'),
            controller: 'ModalInstanceController',
            scope: modalScope,
            backdrop: 'static',
            keyboard: false
        })

        modalInstance.result.finally(() => modalScope.$destroy())
        return modalInstance
    }

    return service
}
