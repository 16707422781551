import config from '../config';
import {validateAttachedFiles} from '../utils';
import { ALLOWED_FILE_TYPES_OPTIONS } from '../services/constants'

function getMaxFilesCountFromField(fieldData) {
    return fieldData && fieldData.options && fieldData.options.maxFiles ? fieldData.options.maxFiles : config.maxFilesCount;
}

export function fileDataField(Upload, $translate, $q, $timeout, ApiCalls, MomentHelper) {
    'ngInject';
    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            field: '=',
            task: '=',
            processId: '<',
            isTable: '<',
            taskFilesShouldBeUploaded: '<',
            isDisabled: '<',
            onChange: '&?',
            checkFieldError: '&?'
        },
        template: require('../templates/file-data-field.html'),
        controllerAs: '$ctrl',
        controller: function($scope, ServerConfig, PageSettings, PrintErrors) {
            'ngInject';
            this.printErrors = PrintErrors.printFilesErrors;

            this.getFieldFiles = () => {
                let {value} = this.field;
                return value && value.files ? value.files : [];
            };

            this.setFieldFiles = (files) => {
                if (!this.field.value) {
                    this.field.value = {};
                }

                this.field.value.files = files.slice();
            };

            this.getFile = (fileName) => {
                return this.getFieldFiles().find(f => f.fileName === fileName);
            };

            this.replaceTempFile = (data) => {
                let files = this.getFieldFiles().map(f => {
                    return !f.id && f.fileName === data.fileName ? data : f;
                });

                this.setFieldFiles(files);
            };

            this.getFieldData = () => {
                return this.field.name || this.field;
            };

            this.getMaxCount = () => {
                return this.maxFilesCount - this.getFieldFiles().length;
            };

            this.limitIsReached = () => {
                return this.getMaxCount() < 1;
            };

            this.removeFile = (fileName, event) => {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                const file = this.getFile(fileName);

                if (!file) {
                    return;
                }

                if (file.id) {
                    if (this.taskFilesShouldBeUploaded && !this.isTable) {
                        this.field.saving = true;
                        let promise = this.processId
                            ? ApiCalls.deleteProcessDataModelFile(this.processId, this.getFieldData().id, file.id)
                            : ApiCalls.deleteDataModelFile(this.task.id, this.getFieldData().id, file.id);

                        promise.finally(() => {
                            this.field.saving = false;
                            this.field.saved = true;
                            if (this.onChange) {
                                this.onChange();
                            }

                            $timeout(() => this.field.saved = false, 1000);
                        });
                    } else {
                        ApiCalls.deleteFile(file.id);
                    }
                }

                this.field.uploading = false;
                let files = this.getFieldFiles().filter(f => f.fileName !== fileName);
                this.setFieldFiles(files);
            };

            this.uploadFiles = (files, allPolicies) => {
                let taskFiles = [];
                files.forEach((file, fileIndex) => {
                    const filePolicy = allPolicies[fileIndex];
                    file.uploading = new Promise((resolve, reject) => {
                        const awsUploading = Upload.upload({
                            url: `https://${filePolicy.bucket}.s3.amazonaws.com/`,
                            method: 'POST',
                            data: {
                                key: filePolicy.key,
                                'AWSAccessKeyId': filePolicy.accessKey,
                                'acl': filePolicy.acl,
                                'policy': filePolicy.policy,
                                'signature': filePolicy.signature,
                                'x-amz-server-side-encryption': filePolicy.xAmzServerSideEncryption,
                                'x-amz-credential': filePolicy.xAmzCredential,
                                'x-amz-algorithm': filePolicy.xAmzAlgorithm,
                                'x-amz-date': filePolicy.xAmzDate,
                                'Content-Type': filePolicy.contentType,
                                'Content-Disposition': filePolicy.contentDisposition,
                                filename: filePolicy.fileName, // this is needed for Flash polyfill IE8-9
                                file: file
                            }
                        });

                        if (this.taskFilesShouldBeUploaded && !this.isTable) {
                            taskFiles.push(file);
                        }

                        this.field.uploading = true;

                        awsUploading.then(response => {
                            if (response.status <= 204 && response.status >= 200) {
                                if (!this.getFieldFiles().find(f => f.fileName === filePolicy.fileName)) {
                                    // File was deleted during uploading
                                    ApiCalls.deleteFile(filePolicy.id);
                                    resolve();
                                    return;
                                }

                                ApiCalls.getFile(filePolicy.id).then(fileInfo => {
                                    const result = {
                                        ...fileInfo,
                                        hashToken: response.headers('ETag').replace(/\"/g, ''),
                                        creationDate: MomentHelper.getUTCTimestampFromString(response.headers('Date'))
                                    };

                                    file.result = result;

                                    if (this.isTable || !this.taskFilesShouldBeUploaded) {
                                        this.replaceTempFile(result);
                                    }

                                    resolve();
                                })
                            } else {
                                let copy = angular.copy(file);
                                copy.$error = 'upload';
                                this.errFiles.push(copy);
                                resolve();
                            }
                        }, response => {
                            if (response.data && response.data.displayError) {
                                this.serverError = response.data.displayError;
                            } else {
                                this.serverError = $translate.instant('validation.text.commonUpload');
                            }
                            this.field.uploading = false;
                            reject();
                        }, (evt) => {
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                            if (this.getFieldFiles().find(f => f.fileName === filePolicy.fileName)) {
                                this.field.uploading = true;
                            }
                        });
                    });
                });

                // Upload all "good" files to server
                if (taskFiles.length) {
                    $q.all(taskFiles.map(f => f.uploading)).finally(() => {
                        this.field.uploading = false;
                        let filesData = files.filter(f => {
                            return f.result;
                        }).map(f => f.result);

                        if (filesData && filesData.length) {
                            this.field.saving = true;
                            let promise = this.processId
                                ? ApiCalls.addProcessDataModelFile(this.processId, this.getFieldData().id, filesData)
                                : ApiCalls.addDataModelFile(this.task.id, this.getFieldData().id, filesData);

                            promise
                                .then(data => {
                                    data.result.value.files.forEach(result => {
                                        this.replaceTempFile(result);
                                    });
                                }, () => {
                                    this.serverError = $translate.instant('validation.text.commonUpload');
                                })
                                .finally(() => {
                                    this.field.saving = false;
                                    this.field.saved = true;
                                    if (this.onChange) {
                                        this.onChange();
                                    }

                                    $timeout(() => this.field.saved = false, 1000);
                                });
                        }
                    });
                } else {
                    $q.all(files.map(f => f.uploading)).finally(() => {
                        this.field.uploading = false;
                    });
                }
            };

            this.addFile = (file) => {
                let tempFile = Object.assign({}, file, {progress: 0});
                this.setFieldFiles([...this.getFieldFiles(), tempFile]);
            };

            this.addFiles = (files, errFiles) => {
                const validatedFiles = validateAttachedFiles(files, errFiles, config);

                let tempFiles = validatedFiles[0];
                this.errFiles = validatedFiles[1];

                let maxCount = this.getMaxCount();
                if (maxCount < 1) {
                    return;
                }

                let uniqueFiles = tempFiles.filter(temp => !this.getFieldFiles().find(file => file.fileName === temp.name));
                let newFiles = uniqueFiles.slice(0, maxCount);
                let policyData = newFiles.map(file => {
                    this.addFile({fileName: file.name});
                    return {
                        fileName: file.name,
                        fileSize: file.size,
                        contentType: file.type
                    };
                });

                if (!policyData.length) {
                    return;
                }

                let catchPolicyRequestError = () => {
                    this.field.uploading = false;
                    this.serverError = $translate.instant('validation.text.getPolicy');
                };

                this.serverError = null;
                this.field.uploading = true;
                ApiCalls.generateFilesUploadPolicy(policyData).then(response => {
                    if (response.success) {
                        this.uploadFiles(newFiles, response.result);
                    } else {
                        catchPolicyRequestError();
                    }
                }, catchPolicyRequestError);
            };

            this.$onInit = () => {
                this.maxFilesCount = getMaxFilesCountFromField(this.getFieldData());
                this.filesLimit = angular.toJson({COUNT: this.maxFilesCount});
                if (!this.field.value || !this.field.value.files) {
                    this.setFieldFiles([]);
                }

                this.fileTypesOption = this.field.name.options && this.field.name.options.accept
                    ? ALLOWED_FILE_TYPES_OPTIONS.find(({accept}) => accept === this.field.name.options.accept)
                    : undefined
            };

            this.toggleState = (state) => {
                this.isActive = state;
            };

            this.linkTo = url => {
                if (url) {
                    // Create link in memory
                    const a = window.document.createElement('a');
                    a.target = '_blank';
                    a.href = url;

                    // Dispatch fake click
                    const e = window.document.createEvent('MouseEvents');
                    e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    a.dispatchEvent(e);
                }
            };

            this.checkRequired = () => {
                if (this.checkFieldError) {
                    return this.checkFieldError();
                }
                if (this.field.settings && this.field.settings.isRequiredToFill) {
                    return !this.getFieldFiles().length;
                }
            };

            this.getMaxSize = () => {
                return ServerConfig.fileMaxSize;
            };
        }
    };
}
