export function helpTooltip($document, $compile, $filter, $translate, $timeout, currentUser, DevicesDetector) {
    'ngInject';

    return {
        restrict: 'A',
        scope: {
            helpTooltip: '@?',
            dynamicTooltip: '&?',
            watching: '@?',
            watchedProp: '=tooltipWatchedProp',
            watchingProps: '@?',
            tooltipDelay: '=?',
            tooltipDisabled: '=?'
        },

        link: (scope, element, attrs) => {
            if (DevicesDetector.isMobile()) {
                return;
            }
            scope.tooltipDelay = scope.tooltipDelay || 400;
            let isMounted = false;
            let activeClassName = 'visible';
            let isWhite = element.hasClass('tooltip-white');
            let tip, timeoutId;

            let initTip = (isDisabled) => {
                let text = scope.helpTooltip || scope.dynamicTooltip();
                if(text instanceof Object && !(text instanceof String)) {
                    text = text.text;
                }
                let tooltipText = isDisabled
                  ? $translate.instant(text + 'Disabled')
                  : $translate.instant(text);
                tip = $compile(`
                    <div class="help-tooltip ${isDisabled ? 'disabled' : ''} ${isWhite ? 'white' : ''}">
                        <span>${$filter('parseSymbols')(tooltipText)}</span>
                        <i class="arrow"></i>
                    </div>
                `)(scope);
            };

            const tooltipIsDisabled = () => {
                const hasOverflow = element[0].clientWidth < element[0].scrollWidth || element[0].clientHeight < element[0].scrollHeight;
                return scope.tooltipDisabled || (attrs.onlyOverflowing && !hasOverflow);
            };

            let mouseOutListener = () => {
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
                if (tip && tip.hasClass(activeClassName)) {
                    tip.removeClass(activeClassName);
                }
                if (scope.watchingProps) {
                    element.removeClass('active');
                    scope.watchedProp = null;
                }
            };

            let mouseOverListener = () => {
                if(tooltipIsDisabled()) {
                    return;
                }
                if (scope.watchingProps) {
                    element.addClass('active');
                }
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
                timeoutId = $timeout(() => {
                    if(tooltipIsDisabled()) {
                        return;
                    }
                    let isDisabled = false;
                    if (element.hasClass('disabled')) {
                        if (element.hasClass('tooltip-view')) {
                            isDisabled = true;
                        } else {
                            return;
                        }
                    } else if (attrs.onlyDisabled) {
                        return;
                    }
                    let tooltipData = scope.helpTooltip || scope.dynamicTooltip();
                    if(tooltipData instanceof Object && !(tooltipData instanceof String)) {
                        isWhite = tooltipData.isWhite;
                        isDisabled = false;
                    }
                    if (currentUser.skipHelpTooltips && !isDisabled && !isWhite) {
                        if (tip && tip.hasClass(activeClassName)) {
                            tip.removeClass(activeClassName);
                        }
                        return;
                    }
                    if (!isMounted || scope.watching) {
                        if (tip && tip[0]) {
                            tip[0].parentNode.removeChild(tip[0]);
                        }
                        initTip(isDisabled);
                        if (element.hasClass('tooltip-right')) {
                            tip.addClass('tooltip-right');
                        }
                        $document.find('body').append(tip);
                        isMounted = true;
                    }
                    tip.addClass(activeClassName);

                    let pos = element[0].getBoundingClientRect();
                    if (pos.top === 0 && pos.left === 0) {
                        tip.removeClass(activeClassName);
                        return;
                    }
                    let offset = tip.offset();
                    let tipHeight = tip.outerHeight();
                    let tipWidth = tip.outerWidth();
                    let elWidth = element[0].clientWidth;
                    let elHeight = element[0].clientHeight;
                    const tipOffset = 14;

                    let isRight = attrs.rightTooltip;
                    if (isRight) {
                        offset.top = Math.ceil(pos.top + elHeight + tipOffset);
                        tip[0].style.right = '4px';
                        tip[0].style.top = `${offset.top}px`;
                        tip[0].style.left = 'auto';
                    } else {
                        offset.top = Math.ceil(pos.top + elHeight + tipOffset);
                        offset.left = pos.left - (tipWidth / 2) + (elWidth / 2);
                    }
                    if (offset.left < 0) {
                        offset.left = 0;
                    }
                    if (pos.top + tipHeight + elHeight >= window.innerHeight) { //eslint-disable-line
                        offset.top = Math.ceil(pos.top - tipHeight - tipOffset);
                        tip.find('.arrow')[0].style.top = `${Math.ceil(offset.top + tipHeight)}px`;
                        if (isDisabled || isWhite) {
                            tip.find('.arrow')[0].style.transform = 'rotate(225deg) translate(4px, 7px)';
                        } else {
                            tip.find('.arrow')[0].style.transform = 'rotate(180deg)';
                        }
                    } else {
                        if (isDisabled || isWhite) {
                            tip.find('.arrow')[0].style.transform = 'rotate(45deg) translate(4px)';
                        } else {
                            tip.find('.arrow')[0].style.transform = 'rotate(0deg)';
                        }
                        tip.find('.arrow')[0].style.top = `${Math.ceil(offset.top - 10)}px`;
                    }
                    tip.find('.arrow')[0].style.left = `${pos.left - 11 + elWidth / 2}px`;
                    if (!isRight) {
                        tip.offset(offset);
                    }
                }, scope.tooltipDelay);
            };

            element.on('mouseenter', mouseOverListener);
            element.on('mouseleave', mouseOutListener);
            element.on('click', mouseOutListener);

            if (scope.watchingProps) {
                scope.$watch('watchedProp', val => {
                    if (val) {
                        mouseOverListener();
                    } else {
                        mouseOutListener();
                    }
                });
            }

            scope.$watch('tooltipDisabled', (newVal) => {
                if (!tip) {
                    return;
                }
                if (newVal) {
                    tip.addClass('hide-tooltip');
                } else {
                    tip.removeClass('hide-tooltip');
                }
            });

            scope.$on('$destroy', () => {
                element.off('mouseenter', mouseOverListener);
                element.off('mouseleave', mouseOutListener);
                element.off('click', mouseOutListener);
                if (tip && tip[0]) {
                    tip[0].parentNode.removeChild(tip[0]);
                }
                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }
            });
        }
    };
}
