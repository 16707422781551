export function multiSelectControl ($timeout, $document) {
    'ngInject'

    return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
            ngModel: '=',
            options: '=',
            except: '=?',
            isSmall: '@?',
            placeholder: '@?',
            noSearchResults: '@?',
            noResults: '@?',
            isDisabled: '=?',
            isInvalid: '=?'
        },
        template: `
            <div class="multi-select" ng-keydown="keypress($event)" ng-keyup="keyup($event)" tabindex="-1"
                 ng-click="openSelect($event)" ng-class="{'is-opened': isOpened, 'is-disabled': isDisabled, 'is-invalid': isInvalid}">
                <header class="handler">
                    <!-- for preventing autocompletion -->
                    <input type="text" style="display:none"/>
                    <ul>
                        <li ng-repeat="item in ngModel">
                            {{item.code || item.name}}
                            <i class="icon-common_close" ng-click="removeOption(item)" ng-if="!item.protected"></i>
                        </li>
                        <li class="input" ng-hide="isDisabled">
                            <input
                                type="text"
                                name="multi-select-input"
                                class="multi-select-input"
                                ng-model="searchText"
                                ng-style="{width: getInputLength() * 7 + 20}"
                                placeholder="{{ngModel.length ? '' : placeholder}}"
                                maxlength="50"
                                autocomplete="off"
                                tabindex="2"
                            />
                        </li>
                    </ul>
                    <span class="clear-all" ng-click="clearAll()" ng-if="clearAllIsActive()">
                        <i class="icon-common_close"></i>
                    </span>
                </header>

                <div class="multi-select-options-list" ng-style="{top: getHandlerHeight()}">
                    <ul ng-if="originOptions.length">
                        <li ng-repeat="option in originOptions">
                            <a ng-click="addOption(option, true)"
                               ng-mousemove="hoverAction(option)"
                               ng-class="{hovered: option.isHovered}">
                               <span ng-if="option.code" ng-bind-html="option.code | highlight:searchText"></span>
                               <span ng-if="option.code"> • </span>
                               <span ng-bind-html="option.name | highlight:searchText"></span>
                            </a>
                        </li>
                    </ul>
                    <p class="no-result" ng-if="searchText && !originOptions.length">{{noSearchResults | translate}}</p>
                    <p class="no-result" ng-if="!searchText && !originOptions.length">{{noResults | translate}}</p>
                </div>
            </div>
        `,
        link: (scope, element, attrs, ctrl) => {
            if (!scope.ngModel) {
                scope.ngModel = []
            }
            if (!scope.originOptions) {
                scope.originOptions = []
            }

            let input = element.find('.multi-select-input')[0]
            let rebuildOrigin = () => {
                scope.originOptions = scope.options.filter(option => {
                    const { id, name, code } = option
                    if (scope.ngModel.find(m => m.id === id) || scope.except && scope.except.id === id) {
                        return false
                    } else if (scope.searchText) {
                        return name.toLowerCase().includes(scope.searchText.toLowerCase())
                            || (code && code.toLowerCase().includes(scope.searchText.toLowerCase()))
                    }
                    return true
                })
                scope.clearHovered()
            }
            let docClickEvent = () => scope.closeSelect()

            let enterClicked
            scope.closeSelect = () => {
                ctrl.$setViewValue(scope.ngModel.slice())
                scope.searchText = ''
                scope.clearHovered()
                scope.$applyAsync()
                let parentEl = element.find('.multi-select-options-list')[0]
                if (parentEl) {
                    parentEl.scrollTop = 0
                }
                scope.isOpened = false
                input.blur()
                $document.off('click', docClickEvent)
            }
            scope.openSelect = (event) => {
                if (scope.isDisabled) {
                    return
                }
                if (event) {
                    event.stopPropagation()
                }
                $document.on('click', docClickEvent)
                input.focus()
                scope.isOpened = true
                rebuildOrigin()
            }
            scope.addOption = (option, isClick) => {
                if (scope.searchText) {
                    scope.searchText = ''
                }
                if (!scope.ngModel.find(m => m.id === option.id)) {
                    scope.ngModel.push(option)
                    ctrl.$setViewValue(scope.ngModel.slice())
                    rebuildOrigin()
                }
                if (isClick && input) {
                    input.focus()
                }
            }
            scope.removeOption = option => {
                scope.ngModel = scope.ngModel.filter(m => m.id !== option.id)
            }
            scope.clearAll = () => {
                scope.ngModel = scope.ngModel.filter(m => m.protected)
            }
            scope.clearAllIsActive = () => scope.ngModel.filter(m => !m.protected).length > 0

            scope.clearHovered = () => {
                scope.originOptions.forEach(o => o.isHovered = undefined)
            }
            scope.hoverAction = option => {
                scope.clearHovered()
                option.isHovered = true
            }
            scope.addToInput = () => {
                let option = scope.originOptions.find(o => o.isHovered)
                if (option) {
                    scope.addOption(option)
                    delete option.isHovered
                } else {
                    enterClicked = false
                }
            }
            scope.rmFromInput = () => {
                if (scope.searchText) {
                    return
                }
                if (scope.ngModel.filter(m => !m.protected).length) {
                    scope.ngModel.pop()
                }
            }
            scope.nextActive = () => {
                let el = element.find('.multi-select-options-list .hovered')[0]
                let parentEl = element.find('.multi-select-options-list')[0]
                if (el && parentEl && el.offsetTop - parentEl.scrollTop >= (scope.isSmall ? 102 : 150)) {
                    parentEl.scrollTop = el.offsetTop - (scope.isSmall ? 89 : 137)
                }

                let setNext
                if (scope.originOptions.find(o => o.isHovered)) {
                    scope.originOptions.forEach((o, i) => {
                        if (setNext) {
                            o.isHovered = true
                            setNext = false
                        } else if (o.isHovered && i !== scope.originOptions.length - 1) {
                            delete o.isHovered
                            setNext = true
                        }
                    })
                } else if (scope.originOptions.length) {
                    scope.originOptions[0].isHovered = true
                }
            }
            scope.prevActive = () => {
                let el = element.find('.multi-select-options-list .hovered')[0]
                let parentEl = element.find('.multi-select-options-list')[0]
                if (el && parentEl && el.offsetTop <= parentEl.scrollTop + 37) {
                    parentEl.scrollTop = el.offsetTop - 37
                }

                let index
                scope.originOptions.find((o, i) => {
                    if (o.isHovered) {
                        index = i
                        delete o.isHovered
                        return true
                    }
                })
                if (index) {
                    scope.originOptions[index - 1].isHovered = true
                }
            }

            scope.keypress = ($event) => {
                if (scope.isDisabled) {
                    return
                }
                switch ($event.keyCode) {
                    case 40: // arrow down
                        scope.nextActive()
                        break
                    case 38: // arrow up
                        scope.prevActive()
                        break
                    case 27: // escape
                        scope.closeSelect()
                        break
                    case 13:  // enter
                        $event.preventDefault()
                        enterClicked = true
                        scope.addToInput()
                        break
                    case 46:
                    case 8:
                        scope.rmFromInput()
                        break
                    case 9:
                        scope.closeSelect()
                        break
                    default:
                }
            }

            scope.keyup = event => {
                if (event && event.which === 13) {
                    event.preventDefault()
                    if (!enterClicked) {
                        scope.closeSelect()
                    }
                }
            }

            scope.getInputLength = () => {
                if (scope.searchText) {
                    return scope.searchText.length
                }
                return scope.placeholder && !scope.ngModel.length ? scope.placeholder.length : 0
            }

            scope.getHandlerHeight = () => {
                return element.find('.handler')[0].clientHeight
            }

            scope.$watch('searchText', (newValue, oldValue) => {
                if (!oldValue || !newValue) {
                    scope.clearHovered()
                }
                rebuildOrigin()
            })

            scope.$watch('isDisabled', (newValue, oldValue) => {
                if (newValue && !oldValue && scope.isOpened) {
                    scope.closeSelect()
                }
            })

            scope.$on('$destroy', () => {
                $document.off('click', docClickEvent)
            })
        }
    }
}
