// Generated from FormulaParser.g4 by ANTLR 4.9.2
// jshint ignore: start
import antlr4 from '../antlr4'
import FormulaParserListener from './FormulaParserListener.js'
import FormulaParserVisitor from './FormulaParserVisitor.js'

const serializedATN = ['\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786',
    '\u5964\u0003\u0010%\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0003\u0002',
    '\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0005\u0002',
    '\r\n\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002',
    '\u0003\u0002\u0005\u0002\u0015\n\u0002\u0003\u0002\u0003\u0002\u0003',
    '\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0007\u0002\u001d\n\u0002',
    '\f\u0002\u000e\u0002 \u000b\u0002\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0002\u0003\u0002\u0004\u0002\u0004\u0002\u0005\u0003\u0002',
    '\t\f\u0003\u0002\u0007\b\u0003\u0002\u0005\u0006\u0002(\u0002\u0014',
    '\u0003\u0002\u0002\u0002\u0004!\u0003\u0002\u0002\u0002\u0006\u0007',
    '\b\u0002\u0001\u0002\u0007\b\t\u0002\u0002\u0002\b\t\u0007\r\u0002\u0002',
    '\t\n\u0007\u000f\u0002\u0002\n\u0015\u0007\u000e\u0002\u0002\u000b\r',
    '\u0007\u0006\u0002\u0002\f\u000b\u0003\u0002\u0002\u0002\f\r\u0003\u0002',
    '\u0002\u0002\r\u000e\u0003\u0002\u0002\u0002\u000e\u0015\u0007\u0004',
    '\u0002\u0002\u000f\u0010\u0007\r\u0002\u0002\u0010\u0011\u0005\u0002',
    '\u0002\u0002\u0011\u0012\u0007\u000e\u0002\u0002\u0012\u0015\u0003\u0002',
    '\u0002\u0002\u0013\u0015\u0007\u000f\u0002\u0002\u0014\u0006\u0003\u0002',
    '\u0002\u0002\u0014\f\u0003\u0002\u0002\u0002\u0014\u000f\u0003\u0002',
    '\u0002\u0002\u0014\u0013\u0003\u0002\u0002\u0002\u0015\u001e\u0003\u0002',
    '\u0002\u0002\u0016\u0017\f\u0007\u0002\u0002\u0017\u0018\t\u0003\u0002',
    '\u0002\u0018\u001d\u0005\u0002\u0002\b\u0019\u001a\f\u0006\u0002\u0002',
    '\u001a\u001b\t\u0004\u0002\u0002\u001b\u001d\u0005\u0002\u0002\u0007',
    '\u001c\u0016\u0003\u0002\u0002\u0002\u001c\u0019\u0003\u0002\u0002\u0002',
    '\u001d \u0003\u0002\u0002\u0002\u001e\u001c\u0003\u0002\u0002\u0002',
    '\u001e\u001f\u0003\u0002\u0002\u0002\u001f\u0003\u0003\u0002\u0002\u0002',
    ' \u001e\u0003\u0002\u0002\u0002!"\u0005\u0002\u0002\u0002"#\u0007',
    '\u0010\u0002\u0002#\u0005\u0003\u0002\u0002\u0002\u0006\f\u0014\u001c',
    '\u001e'].join('')

const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN)

const decisionsToDFA = atn.decisionToState.map((ds, index) => new antlr4.dfa.DFA(ds, index))

const sharedContextCache = new antlr4.PredictionContextCache()

export default class FormulaParser extends antlr4.Parser {

    static grammarFileName = 'FormulaParser.g4'
    static literalNames = [null, null, null, '\'+\'', '\'-\'', '\'*\'', '\'/\'',
        '\'SUM\'', '\'AVG\'', '\'MIN\'', '\'MAX\'', '\'(\'', '\')\'']
    static symbolicNames = [null, 'WS', 'NUMBER', 'ADD', 'SUB', 'MUL',
        'DIV', 'SUM', 'AVG', 'MIN', 'MAX', 'OPEN_P',
        'CLOSE_P', 'FIELD', 'NEWLINE']
    static ruleNames = ['expression', 'resultExpression']

    constructor (input) {
        super(input)
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache)
        this.ruleNames = FormulaParser.ruleNames
        this.literalNames = FormulaParser.literalNames
        this.symbolicNames = FormulaParser.symbolicNames
    }

    get atn () {
        return atn
    }

    sempred (localctx, ruleIndex, predIndex) {
        switch (ruleIndex) {
            case 0:
                return this.expression_sempred(localctx, predIndex)
            default:
                throw 'No predicate with index:' + ruleIndex
        }
    }

    expression_sempred (localctx, predIndex) {
        switch (predIndex) {
            case 0:
                return this.precpred(this._ctx, 5)
            case 1:
                return this.precpred(this._ctx, 4)
            default:
                throw 'No predicate with index:' + predIndex
        }
    }

    expression (_p) {
        if (_p === undefined) {
            _p = 0
        }
        const _parentctx = this._ctx
        const _parentState = this.state
        let localctx = new ExpressionContext(this, this._ctx, _parentState)
        let _prevctx = localctx
        const _startState = 0
        this.enterRecursionRule(localctx, 0, FormulaParser.RULE_expression, _p)
        var _la = 0 // Token type
        try {
            this.enterOuterAlt(localctx, 1)
            this.state = 18
            this._errHandler.sync(this)
            switch (this._input.LA(1)) {
                case FormulaParser.SUM:
                case FormulaParser.AVG:
                case FormulaParser.MIN:
                case FormulaParser.MAX:
                    localctx = new TotalExpContext(this, localctx)
                    this._ctx = localctx
                    _prevctx = localctx

                    this.state = 5
                    _la = this._input.LA(1)
                    if (!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << FormulaParser.SUM) | (1 << FormulaParser.AVG) | (1 << FormulaParser.MIN) | (1 << FormulaParser.MAX))) !== 0))) {
                        this._errHandler.recoverInline(this)
                    } else {
                        this._errHandler.reportMatch(this)
                        this.consume()
                    }
                    this.state = 6
                    this.match(FormulaParser.OPEN_P)
                    this.state = 7
                    this.match(FormulaParser.FIELD)
                    this.state = 8
                    this.match(FormulaParser.CLOSE_P)
                    break
                case FormulaParser.NUMBER:
                case FormulaParser.SUB:
                    localctx = new NumericExpContext(this, localctx)
                    this._ctx = localctx
                    _prevctx = localctx
                    this.state = 10
                    this._errHandler.sync(this)
                    _la = this._input.LA(1)
                    if (_la === FormulaParser.SUB) {
                        this.state = 9
                        this.match(FormulaParser.SUB)
                    }

                    this.state = 12
                    this.match(FormulaParser.NUMBER)
                    break
                case FormulaParser.OPEN_P:
                    localctx = new ParenthesisExpContext(this, localctx)
                    this._ctx = localctx
                    _prevctx = localctx
                    this.state = 13
                    this.match(FormulaParser.OPEN_P)
                    this.state = 14
                    this.expression(0)
                    this.state = 15
                    this.match(FormulaParser.CLOSE_P)
                    break
                case FormulaParser.FIELD:
                    localctx = new FieldExpContext(this, localctx)
                    this._ctx = localctx
                    _prevctx = localctx
                    this.state = 17
                    this.match(FormulaParser.FIELD)
                    break
                default:
                    throw new antlr4.error.NoViableAltException(this)
            }
            this._ctx.stop = this._input.LT(-1)
            this.state = 28
            this._errHandler.sync(this)
            var _alt = this._interp.adaptivePredict(this._input, 3, this._ctx)
            while (_alt != 2 && _alt != antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if (_alt === 1) {
                    if (this._parseListeners !== null) {
                        this.triggerExitRuleEvent()
                    }
                    _prevctx = localctx
                    this.state = 26
                    this._errHandler.sync(this)
                    var la_ = this._interp.adaptivePredict(this._input, 2, this._ctx)
                    switch (la_) {
                        case 1:
                            localctx = new MulDivExpContext(this, new ExpressionContext(this, _parentctx, _parentState))
                            this.pushNewRecursionContext(localctx, _startState, FormulaParser.RULE_expression)
                            this.state = 20
                            if (!(this.precpred(this._ctx, 5))) {
                                throw new antlr4.error.FailedPredicateException(this, 'this.precpred(this._ctx, 5)')
                            }
                            this.state = 21
                            localctx.operator = this._input.LT(1)
                            _la = this._input.LA(1)
                            if (!(_la === FormulaParser.MUL || _la === FormulaParser.DIV)) {
                                localctx.operator = this._errHandler.recoverInline(this)
                            } else {
                                this._errHandler.reportMatch(this)
                                this.consume()
                            }
                            this.state = 22
                            this.expression(6)
                            break

                        case 2:
                            localctx = new AddSubExpContext(this, new ExpressionContext(this, _parentctx, _parentState))
                            this.pushNewRecursionContext(localctx, _startState, FormulaParser.RULE_expression)
                            this.state = 23
                            if (!(this.precpred(this._ctx, 4))) {
                                throw new antlr4.error.FailedPredicateException(this, 'this.precpred(this._ctx, 4)')
                            }
                            this.state = 24
                            localctx.operator = this._input.LT(1)
                            _la = this._input.LA(1)
                            if (!(_la === FormulaParser.ADD || _la === FormulaParser.SUB)) {
                                localctx.operator = this._errHandler.recoverInline(this)
                            } else {
                                this._errHandler.reportMatch(this)
                                this.consume()
                            }
                            this.state = 25
                            this.expression(5)
                            break

                    }
                }
                this.state = 30
                this._errHandler.sync(this)
                _alt = this._interp.adaptivePredict(this._input, 3, this._ctx)
            }

        } catch (error) {
            if (error instanceof antlr4.error.RecognitionException) {
                localctx.exception = error
                this._errHandler.reportError(this, error)
                this._errHandler.recover(this, error)
            } else {
                throw error
            }
        } finally {
            this.unrollRecursionContexts(_parentctx)
        }
        return localctx
    }

    resultExpression () {
        let localctx = new ResultExpressionContext(this, this._ctx, this.state)
        this.enterRule(localctx, 2, FormulaParser.RULE_resultExpression)
        try {
            this.enterOuterAlt(localctx, 1)
            this.state = 31
            this.expression(0)
            this.state = 32
            this.match(FormulaParser.NEWLINE)
        } catch (re) {
            if (re instanceof antlr4.error.RecognitionException) {
                localctx.exception = re
                this._errHandler.reportError(this, re)
                this._errHandler.recover(this, re)
            } else {
                throw re
            }
        } finally {
            this.exitRule()
        }
        return localctx
    }

}

FormulaParser.EOF = antlr4.Token.EOF
FormulaParser.WS = 1
FormulaParser.NUMBER = 2
FormulaParser.ADD = 3
FormulaParser.SUB = 4
FormulaParser.MUL = 5
FormulaParser.DIV = 6
FormulaParser.SUM = 7
FormulaParser.AVG = 8
FormulaParser.MIN = 9
FormulaParser.MAX = 10
FormulaParser.OPEN_P = 11
FormulaParser.CLOSE_P = 12
FormulaParser.FIELD = 13
FormulaParser.NEWLINE = 14

FormulaParser.RULE_expression = 0
FormulaParser.RULE_resultExpression = 1

class ExpressionContext extends antlr4.ParserRuleContext {

    constructor (parser, parent, invokingState) {
        if (parent === undefined) {
            parent = null
        }
        if (invokingState === undefined || invokingState === null) {
            invokingState = -1
        }
        super(parent, invokingState)
        this.parser = parser
        this.ruleIndex = FormulaParser.RULE_expression
    }

    copyFrom (ctx) {
        super.copyFrom(ctx)
    }

}

class FieldExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        super.copyFrom(ctx)
    }

    FIELD () {
        return this.getToken(FormulaParser.FIELD, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterFieldExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitFieldExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitFieldExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.FieldExpContext = FieldExpContext

class MulDivExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        this.operator = null // Token;
        super.copyFrom(ctx)
    }

    expression = function (i) {
        if (i === undefined) {
            i = null
        }
        if (i === null) {
            return this.getTypedRuleContexts(ExpressionContext)
        } else {
            return this.getTypedRuleContext(ExpressionContext, i)
        }
    }

    MUL () {
        return this.getToken(FormulaParser.MUL, 0)
    }

    DIV () {
        return this.getToken(FormulaParser.DIV, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterMulDivExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitMulDivExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitMulDivExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.MulDivExpContext = MulDivExpContext

class NumericExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        super.copyFrom(ctx)
    }

    NUMBER () {
        return this.getToken(FormulaParser.NUMBER, 0)
    }

    SUB () {
        return this.getToken(FormulaParser.SUB, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterNumericExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitNumericExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitNumericExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.NumericExpContext = NumericExpContext

class ParenthesisExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        super.copyFrom(ctx)
    }

    OPEN_P () {
        return this.getToken(FormulaParser.OPEN_P, 0)
    }

    expression () {
        return this.getTypedRuleContext(ExpressionContext, 0)
    }

    CLOSE_P () {
        return this.getToken(FormulaParser.CLOSE_P, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterParenthesisExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitParenthesisExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitParenthesisExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.ParenthesisExpContext = ParenthesisExpContext

class AddSubExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        this.operator = null // Token;
        super.copyFrom(ctx)
    }

    expression = function (i) {
        if (i === undefined) {
            i = null
        }
        if (i === null) {
            return this.getTypedRuleContexts(ExpressionContext)
        } else {
            return this.getTypedRuleContext(ExpressionContext, i)
        }
    }

    ADD () {
        return this.getToken(FormulaParser.ADD, 0)
    }

    SUB () {
        return this.getToken(FormulaParser.SUB, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterAddSubExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitAddSubExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitAddSubExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.AddSubExpContext = AddSubExpContext

class TotalExpContext extends ExpressionContext {

    constructor (parser, ctx) {
        super(parser)
        super.copyFrom(ctx)
    }

    OPEN_P () {
        return this.getToken(FormulaParser.OPEN_P, 0)
    }

    FIELD () {
        return this.getToken(FormulaParser.FIELD, 0)
    }

    CLOSE_P () {
        return this.getToken(FormulaParser.CLOSE_P, 0)
    }

    SUM () {
        return this.getToken(FormulaParser.SUM, 0)
    }

    AVG () {
        return this.getToken(FormulaParser.AVG, 0)
    }

    MIN () {
        return this.getToken(FormulaParser.MIN, 0)
    }

    MAX () {
        return this.getToken(FormulaParser.MAX, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterTotalExp(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitTotalExp(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitTotalExp(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.TotalExpContext = TotalExpContext

class ResultExpressionContext extends antlr4.ParserRuleContext {

    constructor (parser, parent, invokingState) {
        if (parent === undefined) {
            parent = null
        }
        if (invokingState === undefined || invokingState === null) {
            invokingState = -1
        }
        super(parent, invokingState)
        this.parser = parser
        this.ruleIndex = FormulaParser.RULE_resultExpression
    }

    expression () {
        return this.getTypedRuleContext(ExpressionContext, 0)
    }

    NEWLINE () {
        return this.getToken(FormulaParser.NEWLINE, 0)
    }

    enterRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.enterResultExpression(this)
        }
    }

    exitRule (listener) {
        if (listener instanceof FormulaParserListener) {
            listener.exitResultExpression(this)
        }
    }

    accept (visitor) {
        if (visitor instanceof FormulaParserVisitor) {
            return visitor.visitResultExpression(this)
        } else {
            return visitor.visitChildren(this)
        }
    }

}

FormulaParser.ExpressionContext = ExpressionContext
FormulaParser.ResultExpressionContext = ResultExpressionContext
