import moment from 'moment'
import isNil from 'lodash/isNil'
import config from '../../../config'
import {
    ALLOWED_FILE_TYPES_OPTIONS,
    DEFAULT_FIELD_SETTINGS,
    LIST_SOURCE_OPTIONS,
    PROVIDE_LIST_ITEMS,
    USE_EXISTING_TABLE
} from '../../../services/constants'
import {
    AGGREGATION,
    AGGREGATION_OPTIONS,
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import appUtils, { uuid } from '../../../utils'
import {
    calculateAggregation,
    calculateFormula,
    extractFieldsFromExpression,
    findFieldsUsedInExpression,
    formatFieldForExpression
} from '../../../components/expression'
import { fieldValueToString, isExponential, precisionRound } from '../../processes/utils'

export const parseNumericInput = amount => Number(String(amount).replace(/[^0-9\.-]/g, ''))

export const checkStringType = (field, multilineOnly = false) => {
    let fieldData = field.name || field
    return fieldData.dataType === STRING_MULTI_LINE || (fieldData.dataType === STRING_SINGLE_LINE && !multilineOnly)
}

export const checkEmailType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === EMAIL
}

export const checkMoneyType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === MONEY
}

export const checkDateType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === DATE_ONLY_VALUE || fieldData.dataType === DATE_AND_TIME
}

export const checkUserFieldType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === USER_FIELD
}

export const checkFormulaFieldType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === FORMULA
}

export const checkAggregationFieldType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === AGGREGATION
}

export const checkNumberType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === NUMERIC_VALUE
}

export const checkMultiselectType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === MULTI_SELECTOR
}

export const checkRadioType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === RADIO_SELECTOR
}

export const checkFilesType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === FILES_LIST
}

export const checkYesNoType = field => {
    let fieldData = field.name || field
    return fieldData.dataType === YES_NO
}

export const getNumberWidth = field => {
    let fieldData = field.name || field
    if (!checkNumberType(field)) {
        return null
    } else if (!fieldData.options) {
        fieldData.options = {
            precision: 2
        }
    }
    return fieldData.options.precision ? '90px' : '64px'
}

export const getFieldHints = (field, $translate, $filter) => {
    let fieldData = field.name || field
    if (fieldData.options && (!field.settings || field.settings.isEditable)) {
        let { minValue, maxValue } = fieldData.options
        if (checkNumberType(field)) {
            let res = []
            if (minValue != null) {
                res.push($translate.instant('label.minValue') + ' ' + String(minValue))
            }
            if (maxValue != null) {
                res.push($translate.instant('label.maxValue') + ' ' + String(maxValue))
            }
            if (res.length) {
                return res.join('; ')
            }
        } else if (checkMoneyType(field)) {
            let res = []
            if (minValue != null) {
                res.push($translate.instant('label.minValue') + ' ' + String(minValue))
            }
            if (maxValue != null) {
                res.push($translate.instant('label.maxValue') + ' ' + String(maxValue))
            }
            if (res.length) {
                return res.join('; ')
            }
        } else if (checkDateType(field)) {
            let withoutTZ = fieldData.dataType === DATE_ONLY_VALUE
            let format = withoutTZ ? 'DATE_INPUT' : 'DATE_TIME_INPUT'
            if (minValue && maxValue) {
                return $translate.instant('text.chooseBetween') + ' '
                    + $filter('momentDate')(minValue, format, withoutTZ) + ' '
                    + $translate.instant('label.and') + ' '
                    + $filter('momentDate')(maxValue, format, withoutTZ)
            } else if (minValue) {
                return $translate.instant('text.chooseDateNotEarlier') + ' '
                    + $filter('momentDate')(minValue, format, withoutTZ)
            } else if (maxValue) {
                return $translate.instant('text.chooseDateNotLater') + ' '
                    + $filter('momentDate')(maxValue, format, withoutTZ)
            }
        }
    }
    return null
}

export const checkInvalidEmail = (emailValue) => {
    return !!(emailValue && !config.pattern_emails.test(emailValue))
}

export const checkFieldError = (field, isProcessStartMode = false, isTableSectionRow = false) => {
    let fieldData = field.name || field
    let { value = {}, settings = {} } = field

    // errors for string
    if (checkStringType(field)) {
        if (!value.stringValue && settings.isRequiredToFill) {
            return 'required'
        }
        if (value.stringValue && fieldData.options && fieldData.options.minLength
            && value.stringValue.length < fieldData.options.minLength) {
            return 'minLength'
        }
    }
    // errors for email
    if (checkEmailType(field)) {
        if (!value.emailValue && settings.isRequiredToFill) {
            return 'required'
        }
        if (checkInvalidEmail(value.emailValue)) {
            return 'email'
        }
    }
    // errors for number
    if (checkNumberType(field)) {
        if (value.numericValue === '-') {
            return 'incorrectValue'
        }
        if (settings.isRequiredToFill) {
            if (value.numericValue === undefined || value.numericValue === null || value.numericValue === '') {
                return 'required'
            }
        }
        let resValue
        if (value.numericValue != null && angular.isString(value.numericValue)) {
            resValue = parseNumericInput(value.numericValue)
        } else {
            resValue = value.numericValue || null
        }
        if (resValue != null && fieldData.options) {
            if (fieldData.options.minValue != null && resValue < fieldData.options.minValue) {
                return 'minValue'
            }
            if (fieldData.options.maxValue != null && resValue > fieldData.options.maxValue) {
                return 'maxValue'
            }
        }
    }
    // errors for money
    if (checkMoneyType(field)) {
        if (settings.isRequiredToFill && (!value.moneyValue || (value.moneyValue.amount === undefined || value.moneyValue.amount === null || value.moneyValue.amount === ''))) {
            return 'required'
        }
        if (value.moneyValue && value.moneyValue.amount === '-') {
            return 'incorrectValue'
        }

        let resValue
        if (value.moneyValue && value.moneyValue.amount && angular.isString(value.moneyValue.amount)) {
            resValue = parseNumericInput(value.moneyValue.amount)
        } else {
            resValue = value.moneyValue ? value.moneyValue.amount : null
        }
        if (resValue != null && fieldData.options) {
            if (fieldData.options.minValue != null && resValue < fieldData.options.minValue) {
                return 'minValue'
            }
            if (fieldData.options.maxValue != null && resValue > fieldData.options.maxValue) {
                return 'maxValue'
            }
        }
    }
    // errors for date
    if (checkDateType(field)) {
        if (!value.dateValue && settings.isRequiredToFill) {
            return 'required'
        }
        if (value.dateValue && fieldData.options) {
            if (fieldData.options.minValue && value.dateValue < fieldData.options.minValue) {
                return 'minDate'
            }
            if (fieldData.options.maxValue && value.dateValue > fieldData.options.maxValue) {
                return 'maxDate'
            }
        }
    }
    // errors for multiselect
    if (checkMultiselectType(field)) {
        const { multiChoiceValue = [] } = value
        if (!multiChoiceValue.length && settings.isRequiredToFill) {
            return 'required'
        }
        if (isProcessStartMode && fieldData.options && fieldData.options.useTableAsSource && (!fieldData.options.source.tableId || fieldData.options.source.isInvalid)) {
            return 'source'
        }
        if (multiChoiceValue.length && multiChoiceValue.find(r => r.isInvalid) && (field.isChanged || isTableSectionRow)) {
            return 'deletedRecord'
        }
    }
    // errors for radio
    if (checkRadioType(field)) {
        if (!value.radioButtonValue && settings.isRequiredToFill) {
            return 'required'
        }
        if (isProcessStartMode && fieldData.options && fieldData.options.useTableAsSource && (!fieldData.options.source.tableId || fieldData.options.source.isInvalid)) {
            return 'source'
        }
        if (value.radioButtonValue && value.radioButtonValue.isInvalid && isTableSectionRow) {
            return 'deletedRecord'
        }
    }
    // errors for yes / no
    if (checkYesNoType(field)) {
        if (!value.yesNoValue && settings.isRequiredToFill) {
            return 'required'
        }
    }
    // errors for radio
    if (checkFilesType(field)) {
        if ((!field.uploadFiles || !field.uploadFiles.length) && (!value.files || !value.files.length) && settings.isRequiredToFill) {
            return 'required'
        }
    }

    if (checkUserFieldType(field)) {
        if (!value.userValue && settings.isRequiredToFill) {
            return 'required'
        }
        if (value.userValue && value.userValue.isDeleted && isTableSectionRow) {
            return 'deletedRecord'
        }
    }

    if (checkFormulaFieldType(field)) {
        return checkFormulaSettingsError(field)
    }
}

export const getFieldId = field => field.name.id || field.name.tempId

export const asNumber = value => {
    if (value === undefined || value === null || value === '') {
        return null
    }
    if (isExponential(value)) {
        return value
    }
    return Number(String(value).replace(/[^0-9\.-]/g, ''))
}

export const getFieldValue = (field, dataModelList, rowIndex, recalculate = false) => {
    if (!field) return undefined
    const { value = {} } = field

    if (value.calculationErrors) {
        return value
    }

    if (checkFormulaFieldType(field)) {
        return calculateFormula(field, dataModelList, rowIndex)
    }

    if (checkAggregationFieldType(field)) {
        if (!isNil(value.numericValue) && !recalculate) {
            return asNumber(value.numericValue)
        }
        if (!isNil(value.moneyValue?.amount) && !recalculate) {
            return asNumber(value.moneyValue.amount)
        }
        return calculateAggregation(field, dataModelList)
    }

    if (checkMoneyType(field) && !isNil(value.moneyValue?.amount)) {
        return asNumber(value.moneyValue.amount)
    }

    if (checkNumberType(field) && !isNil(value.numericValue)) {
        return asNumber(value.numericValue)
    }

    return undefined
}

export const getFieldValueForSaving = field => {
    let { dataType } = field.name || field
    let { value = {} } = field
    let putData = {}

    if (checkStringType(field) && value.stringValue) {
        putData.stringValue = value.stringValue.toString()
    } else if (dataType === EMAIL && value.emailValue) {
        putData.emailValue = value.emailValue.toString()
    } else if (dataType === YES_NO && value.yesNoValue) {
        putData.yesNoValue = value.yesNoValue.toString()
    } else if (dataType === MONEY && value.moneyValue) {
        putData.moneyValue = Object.assign({}, value.moneyValue, {
            amount: value.moneyValue.amount != null
                ? parseNumericInput(value.moneyValue.amount)
                : null
        })
    } else if (dataType === RADIO_SELECTOR && value.radioButtonValue) {
        putData.radioButtonValue = value.radioButtonValue
    } else if (dataType === NUMERIC_VALUE && value.numericValue && angular.isString(value.numericValue)) {
        putData.numericValue = parseNumericInput(value.numericValue)
    } else if (checkDateType(field) && value.dateValue) {
        putData.dateValue = value.dateValue
    } else if (dataType === MULTI_SELECTOR && value.multiChoiceValue) {
        putData.multiChoiceValue = value.multiChoiceValue
    } else if (dataType === USER_FIELD && value.userValue) {
        putData.userValue = value.userValue
    } else {
        putData.noValue = true
    }

    return putData
}

export const updateValue = (field, useDefaultValue, tz, processStartedDate) => {
    if (!field.value) {
        field.value = {}
    }

    const { defaultValue, options } = field.name

    if (checkStringType(field)) {
        let { stringValue = '' } = field.value
        if (useDefaultValue && !stringValue && defaultValue && defaultValue.stringValue) {
            stringValue = defaultValue.stringValue
        }
        field.value.stringValue = stringValue
    }

    if (checkEmailType(field)) {
        let { emailValue = '' } = field.value
        if (useDefaultValue && !emailValue && defaultValue && defaultValue.emailValue) {
            emailValue = defaultValue.emailValue
        }
        field.value.emailValue = emailValue
    }

    if (checkYesNoType(field)) {
        if (useDefaultValue && !field.value.yesNoValue && defaultValue && defaultValue.yesNoValue) {
            field.value.yesNoValue = defaultValue.yesNoValue
        }
    }

    if (checkRadioType(field)) {
        if (useDefaultValue && !field.value.radioButtonValue && defaultValue && defaultValue.radioButtonValue) {
            field.value.radioButtonValue = defaultValue.radioButtonValue.item
                ? defaultValue.radioButtonValue.item
                : defaultValue.radioButtonValue
        }
    }

    if (checkNumberType(field)) {
        if (useDefaultValue && !field.value.numericValue && defaultValue && defaultValue.numericValue) {
            field.value.numericValue = defaultValue.numericValue
        }
    }

    if (checkMoneyType(field)) {
        if (useDefaultValue && defaultValue && defaultValue.moneyValue && !field.value.moneyValue?.amount) {
            field.value.moneyValue = defaultValue.moneyValue
        } else if (!field.value.moneyValue) {
            field.value.moneyValue = {
                currency: field.name.options.currency.default.id,
                currencyInfo: field.name.options.currency.default
            }
        }
    }

    if (checkDateType(field)) {
        if (useDefaultValue && !field.value.dateValue && defaultValue && defaultValue.specialValue) {
            if (processStartedDate) {
                field.value.dateValue = processStartedDate
            } else {
                field.value.dateValue = field.name.dataType === 'DATE_AND_TIME'
                    ? moment.utc().tz(tz).unix()
                    : moment.utc().startOf('day').unix()
            }
        }
    }

    if (checkMultiselectType(field)) {
        field.value.multiChoiceValue = field.value.multiChoiceValue || []
    }

    if (checkUserFieldType(field) && useDefaultValue && !field.value.userValue && defaultValue) {
        const defaultValueUser = defaultValue.userValue && defaultValue.userValue.id
            ? options.allowedUsers.find(u => u.id === defaultValue.userValue.id)
            : undefined
        if (defaultValueUser) {
            field.value.userValue = defaultValueUser
        }
    }
}

export const checkDefaultFieldSettings = (field, settings, { companyCurrencies = undefined, source = {} }) => {
    if (checkFilesType(field)) {
        const defaultMaxFiles = settings.maxFiles === DEFAULT_FIELD_SETTINGS.maxFiles
        const defaultFileType = (!settings.accept && !settings.selectedFileTypesOption)
            || settings.selectedFileTypesOption === ALLOWED_FILE_TYPES_OPTIONS[0].label
            || settings.accept === ''
        return defaultMaxFiles && defaultFileType
    }

    if (checkYesNoType(field)) {
        return true
    }

    if (checkStringType(field, true)) {
        return settings.minLength === DEFAULT_FIELD_SETTINGS.minLength
            && settings.maxLength === DEFAULT_FIELD_SETTINGS.maxMultilineLength
    }

    if (checkStringType(field)) {
        return settings.minLength === DEFAULT_FIELD_SETTINGS.minLength
            && settings.maxLength === DEFAULT_FIELD_SETTINGS.maxLength
    }

    if (checkNumberType(field)) {
        const hasAggregation = settings.aggregation && (settings.aggregation.expression || settings.aggregation.totalField)
        return settings.precision === DEFAULT_FIELD_SETTINGS.precision
            && settings.maxValue == null
            && settings.minValue == null
            && !hasAggregation
    }

    if (checkMoneyType(field)) {
        const defaultCurrency = companyCurrencies ? {
            default: {
                id: companyCurrencies.defaultCurrency.id
            },
            available: []
        } : {}

        const savedCurrency = settings.currency ? {
            default: {
                id: settings.currency.default.id
            },
            available: [...settings.currency.available]
        } : {}

        const hasAggregation = settings.aggregation && (settings.aggregation.expression || settings.aggregation.totalField)

        return isNil(settings.maxValue)
            && isNil(settings.minValue)
            && angular.equals(savedCurrency, defaultCurrency)
            && !hasAggregation
    }

    if (checkRadioType(field) || checkMultiselectType(field)) {
        return (!settings.source.allowedValues || !settings.source.allowedValues.length)
            && (!source || source.type === LIST_SOURCE_OPTIONS.find(o => o.default).label)
    }

    if (checkDateType(field)) {
        return angular.isUndefined(settings.maxValue)
            && angular.isUndefined(settings.minValue)
    }

    if (checkEmailType(field)) {
        return true
    }

    if (checkUserFieldType(field)) {
        return settings.allUsers === true
    }

    if (checkFormulaFieldType(field)) {
        return settings.expression === undefined || settings.expression.replace(/\n/g, '').trim() === ''
    }
}

export const checkDefaultValueError = (field, defaultValue = {}, options = {}) => {
    const errorKeyBase = `validation.defaultValue`
    if (checkStringType(field) && defaultValue.stringValue) {
        if (options.minLength && defaultValue.stringValue.length < options.minLength) {
            return `${errorKeyBase}.stringValue.minLength`
        }
        if (options.maxLength && defaultValue.stringValue.length > options.maxLength) {
            return `${errorKeyBase}.stringValue.maxLength`
        }
    }

    if (checkNumberType(field) && defaultValue.numericValue) {
        if (!isNil(options.minValue) && defaultValue.numericValue < options.minValue) {
            return `${errorKeyBase}.numericValue.minValue`
        }
        if (!isNil(options.maxValue) && defaultValue.numericValue > options.maxValue) {
            return `${errorKeyBase}.numericValue.maxValue`
        }
    }

    if (checkMoneyType(field) && defaultValue.moneyValue && !isNil(defaultValue.moneyValue.amount)) {
        const amount = parseNumericInput(defaultValue.moneyValue.amount)
        if (!isNil(options.minValue) && amount < options.minValue) {
            return `${errorKeyBase}.moneyValue.minValue`
        }
        if (!isNil(options.maxValue) && amount > options.maxValue) {
            return `${errorKeyBase}.moneyValue.maxValue`
        }
    }

    if (checkUserFieldType(field) && defaultValue.userValue && defaultValue.userValue.isDeleted) {
        return 'error.userField.userRestricted'
    }
}

export const convertDefaultValue = (field, defaultValue, { sourceType, currency }) => {
    if (!defaultValue) {
        return undefined
    }
    if (checkYesNoType(field)) {
        return { yesNoValue: defaultValue.id }
    }
    if (checkRadioType(field) && sourceType === PROVIDE_LIST_ITEMS) {
        return { radioButtonValue: defaultValue.item }
    }
    if (checkRadioType(field) && sourceType === USE_EXISTING_TABLE) {
        return { radioButtonValue: { ...defaultValue } }
    }
    if (checkStringType(field)) {
        return { stringValue: defaultValue }
    }
    if (checkDateType(field)) {
        return { specialValue: defaultValue.id }
    }
    if (checkEmailType(field)) {
        return { emailValue: defaultValue }
    }
    if (checkNumberType(field)) {
        return { numericValue: defaultValue ? parseNumericInput(defaultValue) : undefined }
    }
    if (checkMoneyType(field) && defaultValue.amount) {
        const defaultMoneyValue = { moneyValue: defaultValue }
        if (!defaultMoneyValue.moneyValue.currency) {
            defaultMoneyValue.moneyValue.currency = currency.default.id
        }
        return defaultMoneyValue
    }
    if (checkUserFieldType(field)) {
        return defaultValue.id
            ? { userValue: defaultValue }
            : { specialValue: defaultValue.value }
    }
}

export function getAggregationExpressionForField (field, expression) {
    const { id, tempId } = field.name
    const fieldId = id ? id : `temp_${tempId}`
    const fieldExpressionId = `<@field_${fieldId}>`
    return `${expression.toUpperCase()}(${fieldExpressionId})\n`
}

export function createAggregationField (field, expression, aggregationFieldId) {
    const { label, dataType, options } = field.name
    const aggregationField = {
        dataType: AGGREGATION,
        label: `${expression.toUpperCase()} ${label}`,
        options: {
            sourceFieldId: getFieldId(field),
            expression: getAggregationExpressionForField(field, expression)
        }
    }

    if (dataType === FORMULA) {
        aggregationField.options.format = options.format
    } else if (dataType === NUMERIC_VALUE) {
        aggregationField.options.format = {
            dataType: NUMERIC_VALUE,
            options: {
                precision: options.precision
            }
        }
    } else if (dataType === MONEY) {
        aggregationField.options.format = {
            dataType: MONEY,
            options: {
                precision: options.currency.default.numberOfUnits
            }
        }
    }

    if (aggregationFieldId) {
        aggregationField.id = aggregationFieldId
    } else {
        aggregationField.tempId = uuid()
    }

    return { name: aggregationField }
}

export const excludeServiceFields = field => field.name && field.name.dataType && field.name.dataType !== AGGREGATION

export const parseAggregationOptions = totalField => {
    const option = AGGREGATION_OPTIONS.find(({ item }) => totalField.name.options.expression.slice(0, 3) === item)
    return option ? { expression: option.item } : {}
}

export const cloneField = (original, allSavedFieldsIds) => {
    const originalId = getFieldId(original)
    const clone = angular.copy(original)
    delete clone.name.id
    clone.name.tempId = appUtils.uid4(allSavedFieldsIds)
    clone.name.originalId = originalId

    if (clone.name.options && clone.name.options.totalFieldId) {
        const { aggregation } = clone.name.options
        const aggregationField = createAggregationField(clone, aggregation.expression)
        clone.name.options.totalFieldId = aggregationField.name.tempId

        return [clone, aggregationField]
    }

    return [clone]
}

export const cloneSection = (original, allSavedFieldsIds) => {
    const clone = angular.copy(original)
    delete clone.section.id
    const newAggregationFields = []
    clone.section.fieldsWithValues = clone.section.fieldsWithValues.filter(excludeServiceFields).map(field => {
        const originalField = original.section.fieldsWithValues.find(f => getFieldId(f) === getFieldId(field))
        const [clone, aggregationField] = cloneField(originalField, allSavedFieldsIds)

        if (aggregationField) {
            newAggregationFields.push(aggregationField)
        }

        return clone
    }).concat(newAggregationFields)

    clone.section.fieldsWithValues.filter(f => f.name.dataType === FORMULA).forEach(f => {
        const fields = extractFieldsFromExpression(f.name.options.expression)
        fields.forEach(({ id, value }) => {
            const clonedField = clone.section.fieldsWithValues.find(f => f.name.originalId === id)
            if (clonedField) {
                const replacement = formatFieldForExpression(clonedField).value
                f.name.options.expression = f.name.options.expression.replace(value, replacement)
            }
        })
    })

    return clone
}

export const prepareDataFormFieldsForFormula = (dataModelList, currentSection, currentField) => {
    const currentSectionId = currentSection.id || appUtils.uid4()
    currentSection.$tempId = currentSectionId
    const currentFieldId = getFieldId(currentField)
    return dataModelList.map((item, index) => {
        const group = item.section
        const groupId = group.id || appUtils.uid4()
        let allowedDataTypes = [NUMERIC_VALUE, MONEY, FORMULA]

        if (group.isTable && currentSectionId !== group.$tempId) {
            allowedDataTypes = [AGGREGATION]
        }

        return {
            id: groupId,
            name: group.name || 'Section ' + (index + 1),
            replacements: group.fieldsWithValues.map(field => {
                const fieldId = getFieldId(field)
                const fieldIsNotAllowed = !allowedDataTypes.includes(field.name.dataType)
                const fieldFromAnotherTable = currentSection.isTable && group.isTable && currentSectionId !== group.$tempId && field.name.dataType !== AGGREGATION

                let cycleDependency = currentFieldId === fieldId
                if (currentField.name.dataType === FORMULA && field.name.dataType === FORMULA && !cycleDependency && field.name.options && field.name.options.expression) {
                    const fieldsInUse = findFieldsUsedInExpression([formatFieldForExpression(currentField)], field.name.options.expression)
                    cycleDependency = fieldsInUse.length > 0
                }

                const isHidden = !field.name.label || fieldIsNotAllowed || cycleDependency || fieldFromAnotherTable
                return formatFieldForExpression(field, isHidden)
            })
        }
    })
}

export const getErrorSourceFieldName = (field, dataModel) => {
    const errorSourceFieldId = field.value?.calculationErrors?.[0].fieldId
    let errorSourceFieldName
    dataModel.list.map(({ section }) => section).forEach(section => {
        const errorSourceField = [...(section.columns || []), ...section.fieldsWithValues].find(f => getFieldId(f) === errorSourceFieldId)
        if (errorSourceField) {
            errorSourceFieldName = `${section.name}: ${errorSourceField.name.label}`
        }
    })

    return errorSourceFieldName
}

export const getDataFormField = (fieldId, dataModelList, currentRowIndex) => {
    if (!dataModelList || !fieldId) return undefined
    const fieldFilter = (f) => f.name.id === fieldId
    const section = dataModelList.map(s => s.section).find(section => section.fieldsWithValues.find(fieldFilter))
    const field = section?.fieldsWithValues?.find(fieldFilter)
    if (field && (!section.isTable || field.name.dataType === AGGREGATION)) {
        return field
    } else if (field && section.isTable && currentRowIndex) {
        const row = section.rows.find(r => r.rowIndex === currentRowIndex)
        const value = row && row.values.find(({ columnId }) => columnId === field.name.id)?.value
        return value ? { ...field, value } : undefined
    }
    return undefined
}

export const prepareFormulaFieldValue = (field, value) => {
    if (isNil(value)) return { noValue: true }
    if (value.calculationErrors) {
        return value
    }
    const { format } = field.name.options

    if (format.dataType === MONEY) {
        return {
            moneyValue: {
                amount: precisionRound(value, format.options.currency.default.numberOfUnits),
                currency: format.options.currency.default.id,
                currencyInfo: format.options.currency.default
            }
        }
    } else if (format.dataType === NUMERIC_VALUE) {
        return {
            numericValue: precisionRound(value, format.options.precision)
        }
    }
}

export const calculateDataForm = (dataModel, selectedFieldId) => {
    const deps = dataModel.formulasDependencies?.find(({ operandId }) => operandId === selectedFieldId)
    if (deps?.dependantFormulas?.length) {
        deps.dependantFormulas.forEach(dep => {
            calculateDataForm(dataModel, dep.dependantFieldId)
        })
    } else {
        dataModel.list.map(s => s.section).forEach(section => {
            section.fieldsWithValues.forEach(field => {
                const { id, dataType } = field.name
                if (dataType === FORMULA && selectedFieldId === id) {
                    if (section.isTable && section.rows?.length) {
                        section.rows.forEach(row => {
                            const rowValue = row.values.find(({ columnId }) => columnId === id)
                            const result = calculateFormula(field, dataModel.list, row.rowIndex)
                            rowValue.value = prepareFormulaFieldValue(field, result)
                        })
                    } else {
                        const result = calculateFormula(field, dataModel.list)
                        field.value = prepareFormulaFieldValue(field, result)
                    }
                } else if (dataType === AGGREGATION && selectedFieldId === id) {
                    const result = calculateAggregation(field, dataModel.list)
                    field.value = prepareFormulaFieldValue(field, result)
                }
            })
        })
    }
}

export const checkFormulaSettingsError = (field) => {
    if (field.name.dataType === FORMULA) {
        const { options } = field.name
        if (!options || options.expression === undefined || options.expression.replace(/\n/g, '').trim() === '') {
            return 'required'
        } else if (options.error) {
            return 'invalid'
        }
    }

    return false
}

export function updateFormulaFieldsWithServerData (dataModel, serverDataModel) {
    try {
        serverDataModel.list.forEach(({ section }, sectionIndex) => {
            if (section.isTable) {
                if (!section.rows?.length) {
                    delete dataModel.list[sectionIndex].section.rows
                } else {
                    section.rows.forEach((row, rowIndex) => {
                        row.values.forEach(({ columnId, value }, valueIndex) => {
                            const column = dataModel.list[sectionIndex].section.columns.find(c => getFieldId(c) === columnId)
                            if (column?.name.dataType === FORMULA) {
                                dataModel.list[sectionIndex].section.rows[rowIndex].values[valueIndex].value = value
                            }
                        })
                    })
                }
                section.fieldsWithValues.forEach((field) => {
                    if (field.name.dataType === AGGREGATION) {
                        dataModel.list[sectionIndex].section.fieldsWithValues.find(f => getFieldId(f) === getFieldId(field)).value = field.value
                    }
                })
            } else {
                section.fieldsWithValues.forEach((field) => {
                    if (field.name.dataType === FORMULA) {
                        const fieldIndex = dataModel.list[sectionIndex].section.fieldsWithValues.findIndex(f => getFieldId(f) === getFieldId(field))
                        const oldValueAsString = fieldValueToString(dataModel.list[sectionIndex].section.fieldsWithValues[fieldIndex])
                        const newValueAsString = fieldValueToString(field)
                        if (oldValueAsString !== newValueAsString) {
                            dataModel.list[sectionIndex].section.fieldsWithValues[fieldIndex].updating = true
                            dataModel.list[sectionIndex].section.fieldsWithValues[fieldIndex].value = field.value
                        }
                    }
                })
            }
        })
    } catch (e) {
        console.error(e)
    }
}

export default {
    checkStringType,
    checkEmailType,
    checkMoneyType,
    checkDateType,
    checkNumberType,
    checkMultiselectType,
    checkRadioType,
    checkFilesType,
    checkYesNoType,
    checkUserFieldType,
    checkFormulaFieldType,
    getNumberWidth,
    getFieldHints,
    checkFieldError,
    getFieldValueForSaving,
    updateValue
}
