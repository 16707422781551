export function escapeHtml() {
    const entityMap = {
        '<': `&lt;`,
        '>': `&gt;`
    };

    return (str) => {
        return str ? String(str).replace(/[<>]/g, (s) => entityMap[s]) : '';
    };
}
