import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Tooltip from '../../../modules/react/tooltip.jsx'
import BaseDropdown from './base.jsx'

import './actions-dropdown.less'

const autoCloseTimeout = 30000

class ActionsDropdown extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        disabled: PropTypes.bool,
        children: PropTypes.node.isRequired
    }

    static defaultProps = {
        icon: 'icon-button_more'
    }

    constructor (props) {
        super(props)

        this.state = { isOpen: false }
    }

    render () {
        const { isOpen } = this.state

        const children = React.cloneElement(this.props.children, { onClick: this.onClick })

        return (
            <BaseDropdown
                label={this.renderLabel()}
                isOpen={isOpen}
                onFocusLost={this.toggleDropdown}
                baseClassName={'actions-list-drop'}
                position={'bottom right'}
            >
                {children}
            </BaseDropdown>
        )
    }

    componentWillUnmount () {
        clearTimeout(this.closeTimeout)
    }

    renderLabel = () => {
        const { disabled, icon } = this.props
        const { isOpen } = this.state
        const labelClasses = cn('btn btn-icon muted action tooltip-view', { disabled })

        return (
            <Tooltip text={isOpen ? '' : 'tooltips.actionsDropdown'}>
                <button className={labelClasses} type="button" onClick={() => this.toggleDropdown(!isOpen)}>
                    <i className={cn('action-icn', icon)}/>
                </button>
            </Tooltip>
        )
    }

    onOpenCallback = () => {
        const { onOpen } = this.props
        if (onOpen) {
            onOpen()
        }
    }

    onCloseCallback = () => {
        const { onClose } = this.props
        if (onClose) {
            onClose()
        }
    }

    toggleDropdown = isOpen => {
        this.setState({ isOpen })
        if (isOpen) {
            this.onOpenCallback()
            this.closeTimeout = setTimeout(() => {
                this.toggleDropdown(false)
            }, autoCloseTimeout)
        } else {
            clearTimeout(this.closeTimeout)
            this.onCloseCallback()
        }
    }

    onClick = () => {
        this.toggleDropdown(false)
    }
}

export default ActionsDropdown
