import config from '../config';

const AUTOFOCUS_DELAY = 250;
const INVITE_RESTRICTED_ERROR_CODE = 519

function parseName(str) {
    let nameArr = str.split(' ');
    let firstName = nameArr.shift().substring(0, 100);
    let lastName = nameArr.length ? nameArr.join(' ').substring(0, 100) : '';

    return [firstName, lastName];
}

function convertRowToJSON(row) {
    let result = {
        email: row.email
    };

    if (row.name) {
        [result.firstName, result.lastName] = parseName(row.name);
    }

    if (row.actorsModel.groups.length) {
        result.groups = row.actorsModel.groups.map(g => ({id: g.id}));
    }

    return result;
}

export class InviteModalController {
    constructor($scope, $modalInstance, $translate, FormErrors, PageSettings, AdminApiCalls, $timeout, DeferredAction,
                groups, preloadedState) {
        'ngInject';
        let timeoutId, formDataMapping;

        $scope.actors = {users: [], groups: groups || [], specialRoles: []};
        if (DeferredAction.GroupDelete.getAllDeferred().length) {
            $scope.actors.groups = $scope.actors.groups.filter(g => !DeferredAction.GroupDelete.isDeferred(g));
        }

        $scope.minRowsLength = preloadedState ? preloadedState.length : 3;
        $scope.maxRowsLength = preloadedState ? preloadedState.length : 10;
        $scope.formRows = preloadedState || Array.apply(null, Array($scope.minRowsLength)).map(() => ({}));

        /*
         * We use https://github.com/hiebj/ng-focus-if to set focus to inputs.
         * It requires different value to be given as a expression result, simple true|false will not trigger $watcher.
         * We store each input's state as a integer wich will be incremented each time we need to return focus to input.
         * https://github.com/hiebj/ng-focus-if/issues/22#issuecomment-226221602
         */
        let inputFocusStates = [];

        const focusOnFirstEmptyRow = () => {
            let firstEmptyRowIndex = $scope.formRows.length > 1 ? $scope.formRows.findIndex(row => !row.email) : 0;
            inputFocusStates = $scope.formRows.map((row, index) => {
                if (index === firstEmptyRowIndex) {
                    return (inputFocusStates[index] || 0) + 1;
                }
                return 0;
            });
        };

        $scope.shouldBeFocused = (rowIndex) => {
            return inputFocusStates[rowIndex] || 0;
        };

        $scope.submitFormByPressingEnter = ({key}) => {
            if (key === 'Enter') {
                $scope.ok();
            }
        };

        $modalInstance.opened.then(() => {
            $timeout(() => focusOnFirstEmptyRow(), AUTOFOCUS_DELAY);
        });

        $scope.addFormRow = () => {
            if ($scope.formRows.length < $scope.maxRowsLength) {
                $scope.formRows.push({});
                focusOnFirstEmptyRow();
            }
        };

        $scope.removeFormRow = (index) => {
            if ($scope.formRows.length > $scope.minRowsLength) {
                $scope.formRows = $scope.formRows.filter((row, i) => i !== index);
                focusOnFirstEmptyRow();
            }
        };

        $scope.checkField = fieldName => $scope.inviteForm[fieldName].$invalid;
        $scope.fieldError = fieldName => FormErrors($scope.inviteForm, fieldName);
        $scope.emailPattern = () => config.pattern_emails;

        $scope.fixNameValue = (rowIndex, formField) => {
            let str = $scope.formRows[rowIndex].name || '';
            $scope.formRows[rowIndex].name = str.match(config.pattern_names).join('');
            formField.delSymbols = (str !== $scope.formRows[rowIndex].name);
        };

        $scope.validateEmails = () => {
            const cleanValue = (string) => string.trim().toLowerCase();
            const originalValues = {};

            $scope.formRows.forEach((row, index) => {
                let duplicate = false;
                if (row.email) {
                    let email = cleanValue(row.email);
                    if (originalValues[email] === undefined) {
                        originalValues[email] = index;
                    }
                    duplicate = originalValues[email] !== index;
                }
                $scope.inviteForm[`email${index}`].$setValidity('duplicateEmail', !duplicate);
            });
        };

        $scope.formIsValid = () => {
            return $scope.inviteForm.$valid && $scope.formRows.filter(row => row.email).length;
        };

        $scope.serverErrorHasField = (rowIndex, fieldName) => {
            return $scope.serverErrorData && $scope.serverErrorData.users
                && $scope.serverErrorData.users[rowIndex] && $scope.serverErrorData.users[rowIndex][fieldName];
        };
        $scope.getServerErrorFieldMessage = (rowIndex, fieldName) => {
            return $scope.serverErrorData.users[rowIndex][fieldName][0].fieldError;
        };
        $scope.cleanServerErrorFieldMessage = (rowIndex, fieldNames) => {
            fieldNames.forEach(fieldName => {
                if ($scope.serverErrorHasField(rowIndex, fieldName)) {
                    delete $scope.serverErrorData.users[rowIndex][fieldName];
                }
            });
        };

        $scope.onCreateGroupError = (errorMsg, errorResp) => {
            if(errorResp && errorResp.data && errorResp.data.name) {
                $scope.serverError = $translate.instant('invite.nameError');
            } else {
                $scope.serverError = errorMsg;
            }
        };

        const mapFormData = () => {
            let users = [];
            formDataMapping = [];
            $scope.formRows.forEach((row, index) => {
                if (row.email) {
                    users.push(convertRowToJSON(row));
                    formDataMapping.push(index);
                }
            });

            return {
                users: users
            };
        };

        const mapErrorData = (errorData) => {
            if (errorData.users) {
                let users = [];

                errorData.users.forEach((user, index) => {
                    users[formDataMapping[index]] = user;
                });

                return {
                    users: users
                };
            }

            return errorData;
        };

        $scope.ok = () => {
            if ($scope.formIsValid()) {
                let formData = mapFormData();

                AdminApiCalls.inviteMulti(formData).then(data => {
                    $modalInstance.close(data);
                }, errorResponse => {
                    if (angular.isObject(errorResponse.data)) {
                        if (errorResponse.status === 400) {
                            $scope.serverErrorData = mapErrorData(errorResponse.data);
                            $scope.serverError = (errorResponse.data.users && errorResponse.data.users[0]
                                && errorResponse.data.users[0].fieldError)
                                ? errorResponse.data.users[0].fieldError
                                : $translate.instant('invite.nameError');
                        } else if (errorResponse.status === INVITE_RESTRICTED_ERROR_CODE) {
                            $scope.serverError = $translate.instant('error.invite.restricted');
                        } else if (errorResponse.data.displayError) {
                            $scope.serverError = errorResponse.data.displayError;
                        } else {
                            $scope.serverError = $translate.instant('error.common');
                        }
                    } else {
                        $scope.serverError = $translate.instant('error.common');
                    }
                });
            }
        };

        $scope.getGroupsError = groupsT => {
            let group = groupsT.find(g => g.id);
            if (group) {
                return group.id[0] ? group.id[0].fieldError : $translate.instant('validation.incorrectValue');
            }
        };

        $scope.$watch('serverError', val => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
            if (val) {
                timeoutId = $timeout(() => $scope.serverError = null, config.errorDelay);
            }
        });

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId);
            }
        });
    }
}
