export function numericValue($filter) {
    'ngInject';
    return {
        require: '?ngModel',
        scope: {
            precision: '&?'
        },
        link: (scope, elem, attrs, ctrl) => {
            if (!ctrl) {
                return;
            }
            const clickListener = () => {
                if (elem[0].disabled || elem[0].readOnly) {
                    elem[0].selectionStart = 0;
                    elem[0].selectionEnd = 999;
                }
            };
            elem.on('touchstart', clickListener);

            let valueParser = viewValue => {
                if ((!viewValue || viewValue === '-') && viewValue !== 0) {
                    elem.val(viewValue);
                    return viewValue;
                }
                let start = '';
                let value = String(viewValue);
                if (value.length && value[0] === '-') {
                    start = '-';
                }

                let pattern = /[^0-9\.]/g;
                if (scope.precision && scope.precision() === 0) {
                    pattern = /[^0-9]/g;
                }
                let temp = value.replace(pattern, '');
                let decimalsFlag = temp.match(/\./g);
                temp = temp.split('.');
                let rest = temp.pop();

                if ((!temp || !temp.length) && !decimalsFlag) {
                    temp = $filter('number')(rest);
                } else {
                    temp = $filter('number')(temp.join(''));
                }

                let resNumber = start + temp.replace(/[\,]/g, ' ');
                if (decimalsFlag) {
                    resNumber = [resNumber, rest.slice(0, scope.precision ? scope.precision() : 2)].join('.');
                }
                elem.val(resNumber);
                ctrl.$setViewValue(resNumber);
                return resNumber;
            };

            ctrl.$formatters.push(valueParser);
            ctrl.$parsers.unshift(valueParser);

            scope.$on('$destroy', () => {
                elem.off('touchstart', clickListener);
            });
        }
    };
}
