/* global document */
import {ProcessesListBaseController} from './processes-list-base.controller';

export class AllProcessesController extends ProcessesListBaseController {
    constructor($scope, $state, $auth, $location, $timeout, ProcessesSettings, PageSettings, ServerConfig, ApiCalls,
                DeferredAction, $log, PusherHelper, currentUser) {
        'ngInject';

        super($scope, $state, $auth, $location, $timeout, ProcessesSettings, ServerConfig, PageSettings, ApiCalls,
                DeferredAction, $log, PusherHelper, currentUser);

        this.container = document.getElementsByClassName('processes__main__content')[0];

        this.api.active = ApiCalls.getAllProcesses;
        this.api.completed = ApiCalls.getAllCompletedProcesses;

        $scope.$on(DeferredAction.TaskDelete.CONST.CANCELED, () => {
            this.firstPage();
        });

        $scope.$on('grouping:changed', () => {
            this.firstPage();
        });

        $scope.$watch(() => this.ProcessesSettings.activeFilterMode, () => {
            this.firstPage(true);
        }, true);

        $scope.$on('searchProcesses:start', () => {
            this.firstPage(true);
        });

        this.ProcessesSettings.setDefaultAll();

        if ($auth.isAuthenticated()) {
            $scope.payload = $auth.getPayload();
        }

        this.firstPage(true);
    }
}
