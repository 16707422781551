export function widthLikePrev($timeout) {
    'ngInject';

    return {
        restrict: 'C',
        link: ($scope, element) => {
            $timeout(() => {
                let prevEl = element[0].previousElementSibling;
                if (prevEl) {
                    element.css('width', (prevEl.clientWidth + 1) + 'px');
                }
            }, 150);
        }
    };
}
