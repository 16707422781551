import validationErrorsLib from '../libs/default-validation';
import config from '../config';

export function FormErrors(ServerConfig, $translate) {
    'ngInject';
    let errorsAuthLib = {
        email: $translate.instant('validation.email') || validationErrorsLib.email,
        url: $translate.instant('validation.url') || validationErrorsLib.url,
        required: $translate.instant('validation.required') || validationErrorsLib.required,
        minlength: $translate.instant('validation.minLength', {value: ServerConfig.auth.minPasswordLength || 4}) || validationErrorsLib.minlength,
        compareTo: $translate.instant('validation.password.doNotMatch') || validationErrorsLib.compareTo,
        duplicateEmail: $translate.instant('inviteUsers.ui-errors.duplicate') || validationErrorsLib.duplicateEmail
    };
    let errorsBaseLib = {
        required: $translate.instant('validation.required') || validationErrorsLib.required,
        pattern: $translate.instant('validation.textNumSpecial') || validationErrorsLib.pattern,
        minlength: $translate.instant('validation.minLength', {value: 3}) || validationErrorsLib.minlength,
        maxlength: $translate.instant('validation.maxLength', {value: 100}) || validationErrorsLib.maxlength
    };

    return (form, field) => {
        if (!form[field] || !form[field].$error) {
            return;
        }
        let keys = Object.keys(form[field].$error);
        let key = keys.indexOf('pattern') !== -1 ? 'pattern' : keys[0];

        if (config.auth_forms.indexOf(form.$name) !== -1) {
            return errorsAuthLib[key];
        } else {
            return errorsBaseLib[key];
        }
    };
}
