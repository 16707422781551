export class DeleteWarningsController {
    constructor($scope, $modalInstance) {
        'ngInject';
        $scope.currentIndex = 0;

        let setWarning = () => $scope.activeWarning = $scope.warnings[$scope.currentIndex];
        setWarning();

        $scope.next = () => {
            if ($scope.currentIndex < $scope.warnings.length - 1) {
                ++$scope.currentIndex;
                setWarning();
            } else {
                $scope.currentIndex = 0;
                setWarning();
            }
        };

        $scope.prev = () => {
            if ($scope.currentIndex > 0) {
                --$scope.currentIndex;
                setWarning();
            } else {
                $scope.currentIndex = $scope.warnings.length - 1;
                setWarning();
            }
        };

        $scope.getCountText = count => {
            return angular.toJson({COUNT: count || 0});
        };

        $scope.ok = (params) => $modalInstance.close(params);
        $scope.cancel = () => $modalInstance.dismiss();
    }
}
