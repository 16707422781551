export function conditionFieldSelector (ConditionsConfig) {
    'ngInject'

    return {
        restrict: 'E',
        scope: true,
        bindToController: {
            condition: '=',
            formFields: '<',
            placeholder: '<?'
        },
        template: `
            <react-select-dropdown
                class="task-group-conditions-form-field-drop"
                ng-class="{'empty': !conditionFieldSelectorCtrl.condition.field.id}"
                value="conditionFieldSelectorCtrl.condition.field.asSelectItem"
                options="conditionFieldSelectorCtrl.formFields"
                placeholder="conditionFieldSelectorCtrl.placeholder"
                on-change="conditionFieldSelectorCtrl.setConditionField"
                is-searchable="true"
                min-width="150"
            ></react-select-dropdown>
        `,
        controllerAs: 'conditionFieldSelectorCtrl',
        controller: function () {
            this.setConditionField = (field) => {
                this.condition.field = { ...field }
                ConditionsConfig.updateCondition(this.condition, this.formFields)
            }
        }
    }
}
