export function trimmed() {
    'ngInject';

    return {
        restrict: 'A',
        link: ($scope, el, attrs) => {
            let input = $(el[0]); //eslint-disable-line
            input.on('input', (e) => {
                let target = e.target;
                let position = target.selectionStart;
                
                target.value = target.value.replace(/^\s+/, '');
                if (attrs.trimEnd) {
                    target.value = target.value.replace(/\s+$/, '');
                }
                if (attrs.trimAll) {
                    target.value = target.value.replace(/ +/g, ' ');
                }

                target.selectionEnd = position;
            });

            $scope.$on('$destroy', () => {
                input.off('input');
            });
        }
    };
}