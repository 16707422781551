import utils from '../utils';

export function notificationBar($window, PageSettings, currentUser, ServerConfig, ApiCalls, CurrentUserService) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div class="notification-bar" ng-if="checkToDisplay() || companyHasPaymentIssue()" ng-class="{billing: companyHasPaymentIssue()}">
                <div class="notification-bar__info" ng-if="checkToDisplay()">
                    <img src="/assets/icons/dialog_update_robot.svg" width="40" height="35" alt="Update"/>
                    <p>{{'notification.newVersion' | translate}}, <a ng-href="{{notification.detailsUrl || notification.buildInfoUrl || getBuildInfoUrl()}}" target="_blank">{{'notification.whatsNew' | translate}}</a>! {{'notification.saveChanges' | translate}}</p>
                </div>
                <div class="notification-bar__action" ng-if="checkToDisplay()">
                    <button class="btn btn-green" ng-click="reloadPage()">{{'label.refresh' | translate}}</button>
                </div>
                
                <div class="notification-bar__info" ng-if="companyHasPaymentIssue() && isAdmin()">
                    <img src="/assets/icons/dialog_update_robot.svg" width="40" height="35" alt="Update"/>
                    <p>{{'notification.paymentIssue.admin' | translate}} <a ui-sref="main.admin.billing">{{'notification.paymentIssue.billingPage' | translate}}</a></p>
                </div>
                <div class="notification-bar__info" ng-if="companyHasPaymentIssue() && !isAdmin()">
                    <img src="/assets/icons/dialog_update_robot.svg" width="40" height="35" alt="Update"/>
                    <p>{{'notification.paymentIssue.nonAdmin' | translate}}</p>
                </div>
            </div>
        `,
        link: (scope) => {
            scope.reloadPage = () => {
                ApiCalls.upgradeClear().then(user => {
                    Object.assign(currentUser, user);
                    $window.location.reload();
                });
            };

            scope.getBuildInfoUrl = () => {
                let storedBuildVersion = utils.localStorage.get('meta-build-version');
                if (storedBuildVersion && ServerConfig.buildInfoUrl) {
                    return ServerConfig.buildInfoUrl.replace('{version}', storedBuildVersion.replace(/\./g, '-'));
                }
            };

            scope.checkToDisplay = () => {
                return !PageSettings.smallScreensMode() && currentUser
                    && (currentUser.showUpgradeMessage || scope.notification.toDisplay && currentUser.id);
            };

            scope.companyHasPaymentIssue = () => {
                return !scope.checkToDisplay() && CurrentUserService.companyHasPaymentIssue();
            }

            scope.isAdmin = () => currentUser.isAdmin;

            if (scope.checkToDisplay()) {
                const key = 'showUpgradeMessage';
                if (utils.localStorage.get(key)) {
                    currentUser.showUpgradeMessage = false;
                    ApiCalls.upgradeClear().then(user => {
                        Object.assign(currentUser, user);
                        utils.localStorage.remove(key);
                    });
                } else {
                    utils.localStorage.set(key, 'true');
                }
            }
        }
    };
}
