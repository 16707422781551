import utils from '../modules/processes/utils';

export function tasksSorter() {
    'ngInject';
    return (input, tasksMode, isStrict) => {
        if (!input) {
            return input;
        }

        if (tasksMode !== 'Completed' && tasksMode !== 'DelegatedCompleted') {
            return input.sort(isStrict ? utils.customNotCompletedStrictSort : utils.customNotCompletedSort);
        } else {
            return input.sort(utils.customCompletedSort);
        }
    };
}
