import Drop from 'tether-drop';

const DropContext = Drop.createContext({classPrefix: 'behalf-drop'});

export function completeOnBehalf() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            callback: '&',
            disabled: '<'
        },
        template: `
            <div class="complete-on-behalf" ng-class="{disabled: disabled}" ng-click="$event.stopPropagation()">
                <div class="handler">
                    <i class="icon-common_arrow_down"></i>
                </div>
                
                <ul class="dropdown-menu--complete-on-behalf">
                    <li>
                        <a href="" ng-click="onClick()">
                            {{'label.completeOnBehalf' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        `,
        link: (scope, element) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--complete-on-behalf')[0],
                position: 'bottom left',
                openOn: 'click'
            });
            scope.close = () => {
                drop.close();
            };
            scope.onClick = () => {
                if (!scope.disabled && scope.callback) {
                    scope.callback();
                    scope.close();
                }
            };
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
