import moment from 'moment-timezone'
import utils, { cloneProcess, prepareTableSection, updateFieldsStatuses } from '../utils'
import globalUtils from '../../../utils'
import { updateFormulaFieldsWithServerData } from '../../templates/services/data-model-utils'

const prepareAssignee = task => {
    task.taskAssignee = task.completedBy || task.assignee
    return task
}

const EMPTY_WATCHERS = { specialRoles: [], groups: [], users: [] }

export class ProcessController {
    constructor (process, allActiveUsers, actors, Constants, $stateParams, $location, $translate, $scope, $rootScope, $timeout, $state,
        PageSettings, ProcessesSettings, ApiCalls, DeferredAction, TaskService, DateHelper, PusherHelper, currentUser, $modal, $window, $q) {
        'ngInject'
        this.process = process
        if (!this.process.watchers) {
            this.process.watchers = angular.copy(EMPTY_WATCHERS)
        }

        this.processSaved = angular.copy(process)
        this.showUpdate = { name: false, description: false, managers: false }
        this.processUpdatingCount = { name: 0, description: 0, managers: 0, watchers: 0, dueDate: 0 }
        this.tasksCompleting = {}
        this.tasksApproving = {}
        this.tasksAssigningToMe = {}
        this.managersActors = {
            groups: actors.groups,
            users: actors.users,
            specialRoles: [{ isProcessStarter: true, name: $translate.instant('label.processStarter') }]
        }
        this.watchersActors = {
            groups: actors.groups,
            users: actors.users,
            specialRoles: [{ isProcessStarter: true, name: $translate.instant('label.processStarter') }]
        }

        this.timezone = DateHelper.getTZ()

        this.items = []
        this.itemsSaved = []

        $scope.STATUS = Constants.TASK.STATUS
        $scope.GROUP_STATUS = Constants.GROUP.STATUS
        $scope.TYPE = Constants.TASK.TYPE
        this.PROCESS_EXECUTION = Constants.PROCESS.EXECUTION
        this.processNameMaxLength = Constants.PROCESS.NAME_MAX_LENGTH

        this.process.starters = { specialRoles: [], groups: [], users: [this.process.starter] }
        this.allActiveUsers = allActiveUsers
        this.actors = actors
        this.url = $stateParams.url || '/processes/all'
        if ($stateParams.url) {
            globalUtils.localStorage.set('processViewBackUrl', this.url)
        }

        this.toggleCommentsOpen = () => {
            this.isCommentsOpened = !this.isCommentsOpened
        }

        this.getCommentsCount = () => {
            return this.process.subTree
                .filter(item => item.task && this.checkPermission(item.task, 'viewComments') && !DeferredAction.TaskDelete.isDeferred(item.task))
                .reduce((prev, item) => prev + item.task.commentsCount, 0) || null
        }

        this.openedGroups = []
        const prepareHierarchyQuery = (isProcessView) => {
            let query = isProcessView ? { resultView: 'process' } : {}
            if (this.openedGroups.length) {
                const currentGroup = this.openedGroups[this.openedGroups.length - 1]
                query.hierarchy = utils.getHierarchyPath(currentGroup)
            }

            return Object.keys(query).length ? query : undefined
        }

        this.updateItems = () => {
            let subTreeItems = this.process.subTree || []
            if (this.openedGroups.length) {
                subTreeItems = subTreeItems.slice(1)
            }
            if (subTreeItems.length) {
                if (DeferredAction.TaskDelete.getAllDeferred().length) {
                    subTreeItems = subTreeItems.filter(item => !item.task || !DeferredAction.TaskDelete.isDeferred(item.task))
                }

                this.items = subTreeItems.map(item => {
                    let { task } = item
                    if (task) {
                        prepareAssignee(item.task)
                    }
                    return item
                })
                this.itemsSaved = angular.copy(this.items)
            }

            this.itemsCountText = angular.toJson({ COUNT: this.items.length })
            $scope.$applyAsync()
        }

        const initializeProcessDataModel = () => {
            if (this.process.dataModel) {
                this.conditionFormFields = this.process.dataModel.list
                    .map(item => item.section)
                    .filter(section => !section.isTable)
                    .map(section => section.fieldsWithValues)
                    .reduce((fields, section) => fields.concat(section), [])
                    .filter(field => field.name.label)
                    .map(field => ({
                        id: field.name.tempId || field.name.id,
                        item: field.name.label,
                        name: field.name
                    }))

                this.process.dataModel.list.forEach(s => {
                    if (s.section.isTable) {
                        s.section = prepareTableSection(s.section)
                    }
                })
            }
        }

        this.conditionFormFields = []

        const updateFieldStatusLabels = () => {
            updateFieldsStatuses(this.process.dataModel, $timeout)
        }

        const updateProcess = (processData, onlyFormula = false) => {
            if (onlyFormula) {
                updateFormulaFieldsWithServerData(this.process.dataModel, processData.dataModel)
            } else {
                if (!processData.watchers) {
                    processData.watchers = EMPTY_WATCHERS
                    this.process.watchers = undefined
                }

                this.process = cloneProcess(this.process, processData)
                this.process.starters = { specialRoles: [], groups: [], users: [this.process.starter] }
                this.process.dataModel.list.forEach(s => {
                    if (s.section.isTable) {
                        s.section = prepareTableSection(s.section)
                    }
                })
                this.updateItems()
            }
            updateFieldStatusLabels()
        }

        const updateDueDates = processData => {
            this.process.subTree.forEach((item, index) => {
                const newItem = processData.subTree[index]
                if (item.task && newItem && newItem.task && item.task.id === newItem.task.id) {
                    item.task.dueDate = newItem.task.dueDate
                }
            })
        }

        const recallProcess = (dueDateOnly = false, onlyFormula = false) => {
            let params = prepareHierarchyQuery()
            return ApiCalls.getProcess(this.process.id, params)
                .then(pr => {
                    if (dueDateOnly) {
                        return updateDueDates(pr)
                    } else {
                        return updateProcess(pr, onlyFormula)
                    }
                })
                .catch(ProcessesSettings.callAlertTaskPageModal)
        }

        const changeTaskErrorHandler = (errResponse) => {
            if (errResponse.status === 473) {
                recallProcess()
            } else {
                ProcessesSettings.callAlertTaskPageModal(errResponse)
            }
        }

        this.back = () => {
            $location.path(globalUtils.localStorage.get('processViewBackUrl') || this.url)
            $scope.$applyAsync()
        }

        this.getErrorsInString = (error) => {
            if (Array.isArray(error)) {
                return error.join('. ') + '. '
            }
            return error
        }

        this.runByEnter = (e, callback) => {
            if (e && e.which !== 13) {
                return
            }
            if (e) {
                e.preventDefault()
            }
            callback()
        }
        this.runByCtrlEnter = (e, callback) => {
            if (e && e.ctrlKey && (e.which === 10 || e.which === 13)) {
                e.preventDefault()
                callback()
            }
        }
        this.editDescription = (id) => {
            let el = $window.document.getElementById(id)
            if (el) {
                this.showUpdate.description = true
                el.focus()
            }
        }
        this.checkIsEmptyManagers = () => {
            return utils.checkActorsIsEmpty(this.process.managers)
        }

        this.updateName = () => {
            this.update('name', true)
        }
        this.updateDescription = () => {
            this.update('description')
        }
        this.updateManagers = () => {
            this.update('managers', true)
        }
        this.updateWatchers = () => {
            this.update('watchers')
        }
        this.updateDueDate = (date, customData) => {
            let dueDateInCompanyTimeZone = DateHelper.dateToCompanyEndOfDay(date)

            switch (customData.typeDueDate) {
                case 'task':
                    this.updateTaskDueDate(dueDateInCompanyTimeZone, date, customData.id)
                    break
                case 'process':
                    this.process['dueDate'] = dueDateInCompanyTimeZone
                    this.update('dueDate', false, date)
                    break
                default:
            }
        }
        this.updateTaskDueDate = (dueDateInCompanyTimeZone, dueDate, taskId) => {
            let taskSavedItem = this.itemsSaved.find(item => item.task && item.task.id === taskId)
            let taskCurrentItem = this.items.find(item => item.task && item.task.id === taskId)
            taskCurrentItem.task.dueDate = dueDateInCompanyTimeZone
            TaskService.updateDueDate(dueDate, taskCurrentItem.task, taskSavedItem.task)
        }
        this.onBlurDescription = (event) => {
            if (event && event.relatedTarget && event.relatedTarget.classList.contains('mdh-item')) {
                return
            }
            this.processDescrEdit = false
            this.updateDescription()
        }

        this.update = (field, required, paramsValue) => {
            const recoverLastSaved = () => {
                if (field === 'watchers') {
                    this.process[field] = angular.copy(this.processSaved[field] || EMPTY_WATCHERS)
                } else {
                    this.process[field] = angular.copy(this.processSaved[field])
                }
                this.process.version = this.processSaved.version
                if (field === 'managers' || field === 'watchers') {
                    this.showUpdate[field] = false
                    this.process.dataModel = angular.copy(this.processSaved.dataModel)
                    this.process.permissions = angular.copy(this.processSaved.permissions)
                    this.process.subTree = angular.copy(this.processSaved.subTree)
                    this.updateItems()
                }
            }

            if (field !== 'managers' && field !== 'watchers') {
                this.showUpdate[field] = false
            }

            if (this.processSaved[field] === this.process[field]) {
                return
            } else if (required && (!this.process[field] || (field === 'managers' && this.checkIsEmptyManagers()))) {
                this.process[field] = angular.copy(this.processSaved[field])
                if (field === 'managers') {
                    this.showUpdate[field] = false
                }
                return
            } else if (field === 'managers' && utils.compareActors(this.process.managers, this.processSaved.managers)) {
                this.showUpdate[field] = false
                return
            } else if (field === 'watchers' && utils.compareActors(this.process.watchers, this.processSaved.watchers)) {
                this.showUpdate[field] = false
                return
            }
            let params = {
                field: field
            }
            if (this.process[field]) {
                params[field] = paramsValue || this.process[field]
            }

            let query = prepareHierarchyQuery()

            this.processUpdatingCount[field]++
            ApiCalls.modifyProcess(this.process.id, params, query).then(response => {
                this.processUpdatingCount[field]--
                if (response.success) {
                    const processData = response.result
                    if (field === 'watchers' && !processData[field]) {
                        processData[field] = angular.copy(EMPTY_WATCHERS)
                    }
                    this.processSaved[field] = processData[field]
                    this.processSaved.version = processData.version
                    this.processSaved.dataModel = processData.dataModel
                    this.processSaved.permissions = processData.permissions
                    this.processSaved.subTree = processData.subTree

                    if (this.processUpdatingCount[field] <= 0) {
                        if (field === 'watchers') {
                            this.process[field] = angular.copy(processData[field] || EMPTY_WATCHERS)
                        } else {
                            this.process[field] = angular.copy(processData[field])
                        }
                        this.process.version = processData.version
                        if (field === 'managers' || field === 'watchers') {
                            this.showUpdate[field] = false
                            this.process.dataModel = angular.copy(processData.dataModel)
                            this.process.permissions = angular.copy(processData.permissions)
                            this.process.subTree = angular.copy(processData.subTree)
                            this.updateItems()
                        }
                    }
                } else if (this.processUpdatingCount[field] <= 0) {
                    recoverLastSaved()
                }

            }).catch(response => {
                this.processUpdatingCount[field]--
                if (this.processUpdatingCount[field] <= 0) {
                    recoverLastSaved()
                }
                if (response.status === 400) {
                    this.serverErrorData = response.data
                } else {
                    return ProcessesSettings.callAlertTaskPageModal(response)
                }
            })
        }

        this.showProcessUpdateIfPermitted = field => {
            if (this.checkPermission(this.process, 'edit')) {
                this.showUpdate[field] = true
                return true
            } else {
                return false
            }
        }
        this.showUpdateManagers = () => {
            if (this.showProcessUpdateIfPermitted('managers')) {
                $scope.$broadcast('managers:open')
            }
        }

        this.showUpdateWatchers = () => {
            if (this.showProcessUpdateIfPermitted('watchers')) {
                $scope.$broadcast('watchers:open')
            }
        }

        this.showCalendarTask = (targetElement, dueDate, dueDateInterval, task) => {
            let hasPermission = !task.template && this.checkPermission(task, 'editDueDate')
            this.showCalendar('task', targetElement, dueDate, dueDateInterval, task.id, hasPermission)
        }
        this.showCalendarProcess = (targetElement, dueDate, dueDateInterval) => {
            let hasPermission = this.checkPermission(this.process, 'edit')
            this.showCalendar('process', targetElement, dueDate, dueDateInterval, undefined, hasPermission, 'process-picker-content')

        }
        this.showCalendar = (typeDueDate, targetElement, dueDate, dueDateInterval, id, hasPermission, pickerContentClass) => {
            if (hasPermission) {
                let dueDateInUTC = DateHelper.companyEndOfDayToDateInUTC(dueDate)
                $scope.$broadcast('mt-date-picker:drop', {
                    targetElement: targetElement,
                    date: dueDateInUTC,
                    dateInterval: dueDateInterval,
                    customData: { typeDueDate: typeDueDate, id: id },
                    pickerContentClass: pickerContentClass
                })
            }
        }

        this.getStatus = (date, title) => {
            let utcDate = DateHelper.getUtcFromTimestamp(date)
            let tz = DateHelper.getTZ()

            let b = moment(utcDate).tz(tz)
            let a = moment().tz(tz)

            if (a.dayOfYear() === b.dayOfYear() && a.year() === b.year()) {
                return $translate.instant('process.' + title + 'At.status')
            }
            if (a.dayOfYear() - b.dayOfYear() < 2 && a.year() === b.year()) {
                return $translate.instant('process.' + title + '.status')
            }
            return $translate.instant('process.' + title + 'On.status')
        }

        this.getTaskActor = task => {
            if (task.completedBy) {
                return task.completedBy
            }
            return task.assignee
        }

        this.getActorsNames = task => utils.getActorsNames(task, $translate)
        this.checkDueDate = task => utils.checkPrDueDate(task, null, DateHelper)

        this.isTaskAssignee = task => {
            const isAssignedToCurrentUser = task.assignee && task.assignee.id === currentUser.id
            const canBeCompleted = task.type === Constants.TASK.TYPE.SIMPLE && this.checkPermission(task, 'complete')
            const canBeApproved = (task.type === Constants.TASK.TYPE.APPROVAL || task.type === Constants.TASK.TYPE.REVIEW) && this.checkPermission(task, 'approve')
            return isAssignedToCurrentUser && (canBeCompleted || canBeApproved)
        }

        this.completeTask = (task, event) => {
            event.stopPropagation()
            if (task.isCompleted || !this.checkPermission(task, 'complete') || task.hasEmptyRequiredFields || this.tasksCompleting[task.id]) {
                return
            }
            this.tasksCompleting[task.id] = true
            let query = prepareHierarchyQuery(true)
            ApiCalls.completeTask(task, query)
                .then((response) => {
                    updateProcess(response.result)
                })
                .catch(changeTaskErrorHandler)
                .finally(() => delete this.tasksCompleting[task.id])
        }
        this.approveTask = (task, event) => {
            event.stopPropagation()
            if (!this.checkPermission(task, 'approve') || task.hasEmptyRequiredFields || this.tasksApproving[task.id]) {
                return
            }
            this.tasksApproving[task.id] = true
            let query = prepareHierarchyQuery(true)
            ApiCalls.approveTask(task, query)
                .then((response) => {
                    updateProcess(response.result)
                })
                .catch(changeTaskErrorHandler)
                .finally(() => delete this.tasksApproving[task.id])
        }
        this.assignToMe = (task, event) => {
            event.stopPropagation()
            if (!this.checkPermission(task, 'selfAssign') || this.tasksAssigningToMe[task.id]) {
                return
            }

            let query = prepareHierarchyQuery(true)

            this.tasksAssigningToMe[task.id] = true
            ApiCalls.assignTask(task, query)
                .then((response) => {
                    updateProcess(response.result)
                })
                .catch(changeTaskErrorHandler)
                .finally(() => delete this.tasksAssigningToMe[task.id])
        }
        let isAssignClick = false
        this.assign = (task, event, isReassign) => {
            event.stopPropagation()
            if (isAssignClick || !this.checkPermission(task, isReassign ? 'reAssign' : 'assignToOther')) {
                return
            }
            let modalScope = $scope.$new()
            modalScope.isReassign = isReassign
            modalScope.taskId = task.id
            modalScope.additionalQuery = prepareHierarchyQuery(true)
            isAssignClick = true

            let modalInstance = $modal.open({
                animation: true,
                windowClass: 'assign-modal',
                backdrop: 'static',
                template: require('../../../templates/modals/assign.html'),
                controller: 'ModalAssignController',
                scope: modalScope,
                resolve: {
                    users: () => ApiCalls.getTaskActorsForAssign(task.id)
                }
            })

            modalInstance.result
                .then(response => {
                    if (response.success) {
                        updateProcess(response.result)
                    } else {
                        changeTaskErrorHandler(response)
                    }
                })
                .finally(() => {
                    isAssignClick = false
                    modalScope.$destroy()
                })
        }

        let isActivateClicked = false
        this.forceActivate = (task, event) => {
            event.stopPropagation()
            if (isActivateClicked || !this.checkPermission(task, 'forceActivation')) {
                return
            }

            isActivateClicked = true
            let query = prepareHierarchyQuery(true)

            ApiCalls.activateTask(task, query)
                .then(response => {
                    updateProcess(response.result)
                })
                .catch(changeTaskErrorHandler)
                .finally(() => {
                    isActivateClicked = false
                })
        }

        const deleteCompleteAction = () => {
            $state.go(ProcessesSettings.defaultAll ? 'main.processes.all' : 'main.processes.my')
        }

        let isProcessDeletion = false
        this.deleteProcess = () => {
            if (isProcessDeletion) {
                return
            }
            isProcessDeletion = true
            let modalInstance = PageSettings.deleteConfirmation('process')
            modalInstance.result.then(() => {
                this.process.subTree
                    .filter(item => item.task && DeferredAction.TaskDelete.isDeferred(item.task))
                    .forEach(item => DeferredAction.TaskDelete.cancelDeferred(item.task))

                ApiCalls.deleteProcess(this.process.id, this.process.version)
                    .then(deleteCompleteAction)
                    .catch((res) => PageSettings.errorHandlerModal(res, deleteCompleteAction))
                    .finally(() => isProcessDeletion = false)
            }).catch(() => isProcessDeletion = false)
        }

        let isProcessCancellation = false
        this.stopProcess = () => {
            if (isProcessCancellation) return
            isProcessCancellation = true
            const modalInstance = PageSettings.processCancelConfirmation()
            const params = prepareHierarchyQuery(true) || {}

            modalInstance.result.then((res) => {
                params.stoppedReason = res.userInput
                ApiCalls.stopProcess(this.process.id, params)
                    .then((response) => {
                        updateProcess(response.result)
                    })
                    .catch((res) => {
                        if (res.status === 400) {
                            this.serverErrorData = res.data
                        } else {
                            return ProcessesSettings.callAlertTaskPageModal(res)
                        }
                    })
                    .finally(() => isProcessCancellation = false)
            }).catch(() => isProcessCancellation = false)
        }

        let isProcessResumeInProgress = false
        this.resumeProcess = () => {
            if (isProcessResumeInProgress) return
            isProcessResumeInProgress = true
            const params = prepareHierarchyQuery(true)
            ApiCalls.resumeProcess(this.process.id, params)
                .then((response) => {
                    updateProcess(response.result)
                })
                .catch((res) => {
                    if (res.status === 400) {
                        this.serverErrorData = res.data
                    } else {
                        return ProcessesSettings.callAlertTaskPageModal(res)
                    }
                })
                .finally(() => isProcessResumeInProgress = false)
        }

        this.taskView = task => {
            if (!this.checkPermission(task, 'view')) {
                return
            }
            PageSettings.leftScroll = document.getElementById('process-tasks').scrollTop || null // eslint-disable-line
            $state.go('main.task', {
                id: task.id,
                url: $location.path(),
                processState: {
                    openedGroups: this.openedGroups
                }
            })
        }

        this.goToTmpl = () => {
            if (!this.process.template || !this.process.template.id || !this.checkPermission(this.process.template, 'view')) {
                return
            }
            $state.go('main.template', { id: this.process.template.id, url: $location.path() })
        }

        this.checkPermission = (obj, permission) => {
            if (this.processUpdatingCount.managers) {
                return false
            }
            return obj.permissions ? obj.permissions.indexOf(permission) !== -1 : null
        }

        this.onCreateGroupError = (errorMsg, errorResp) => {
            PageSettings.createGroupHandlerModal(errorMsg, errorResp)
        }

        this.fetchLevelItems = group => {
            this.items = []
            this.groupIsFetching = true
            let params = {}
            if (group) {
                params.hierarchy = utils.getHierarchyPath(group)
            }
            ApiCalls.getProcess(this.process.id, params).then(
                (data) => {
                    this.process.subTree = data.subTree
                    this.updateItems()
                    this.groupIsFetching = false
                }
            ).catch(ProcessesSettings.callAlertTaskPageModal)
        }

        this.openGroup = group => {
            if (this.groupIsFetching) {
                return
            }

            if (!this.openedGroups.length) {
                this.rootLevelItems = this.process.subTree.slice()
            }

            this.openedGroups.push(group)
            this.fetchLevelItems(group)
        }

        this.closeGroup = () => {
            if (this.groupIsFetching) {
                return
            }

            this.openedGroups.pop()
            if (this.openedGroups.length) {
                this.fetchLevelItems(this.openedGroups[this.openedGroups.length - 1])
            } else {
                this.process.subTree = this.rootLevelItems.slice()
                this.fetchLevelItems()
            }
        }

        this.itemIsCompleted = item => {
            return item.task
                && item.task.type === Constants.TASK.TYPE.SIMPLE
                && item.task.isCompleted
        }

        this.itemIsApproved = item => {
            const { APPROVAL, REVIEW } = Constants.TASK.TYPE
            return item.task
                && (item.task.type === APPROVAL || item.task.type === REVIEW)
                && item.task.status === Constants.TASK.STATUS.APPROVED
        }

        this.itemIsRejected = item => {
            const { APPROVAL, REVIEW } = Constants.TASK.TYPE
            return item.task
                && (item.task.type === APPROVAL || item.task.type === REVIEW)
                && item.task.status === Constants.TASK.STATUS.REJECTED
        }

        this.itemIsGreen = item => {
            if (item.task) {
                return item.task.isCompleted && !this.itemIsRejected(item)
            } else {
                return item.group.status === $scope.GROUP_STATUS.COMPLETED
            }
        }

        const { INACTIVE, SKIPPED, STOPPED_ON_REJECT, STOPPED_ON_CANCEL } = Constants.TASK.STATUS
        const inactiveTaskStatuses = [INACTIVE, SKIPPED, STOPPED_ON_REJECT, STOPPED_ON_CANCEL]
        this.itemIsRed = item => {
            if (item.task && inactiveTaskStatuses.find(s => s === item.task.status)) {
                return false
            }
            let dueDateIsInvalid = item.task && this.checkDueDate(item.task)
            return this.itemIsRejected(item) || dueDateIsInvalid
        }

        this.getGroupItemsCount = group => angular.toJson({ COUNT: group.subEntriesCount || 0 })

        let processUpdateTimeout
        const processUpdatedListener = ({
            processId,
            isDeleted,
            socketId,
            formChanged,
            dueDateChanged = false,
            formulaChanged = false
        }) => {
            const eventIsTriggeredByUser = PusherHelper.checkIfEventShouldBeSkipped(socketId)
            if ((eventIsTriggeredByUser && formChanged && !dueDateChanged && !formulaChanged) || processId !== this.process.id) {
                return
            }

            if (isDeleted) {
                let modalScope = $rootScope.$new()
                modalScope.title = $translate.instant('process.isDeleted.popup.title')
                modalScope.text = $translate.instant('process.isDeleted.popup.text')
                return PageSettings.openAlertModal(modalScope)
                    .then(deleteCompleteAction)
                    .finally(() => modalScope.$destroy())
            } else {
                $timeout.cancel(processUpdateTimeout)
                processUpdateTimeout = $timeout(() => {
                    const onlyFormula = eventIsTriggeredByUser && formChanged
                    // Get new process data
                    recallProcess(eventIsTriggeredByUser && dueDateChanged, onlyFormula)
                }, 1000)
            }
        }

        const taskUpdatedListener = ({ task }) => {
            const itemWithComment = this.items.find(item => item.task && item.task.id === task.id)
            if (itemWithComment) {
                ApiCalls.getTask(task.id)
                    .then(taskUpdated => {
                        itemWithComment.task.permissions = taskUpdated.permissions
                        itemWithComment.task.commentsCount = taskUpdated.commentsCount
                        itemWithComment.task.attachmentsCount = taskUpdated.attachmentsCount
                        itemWithComment.task.version = taskUpdated.version
                        $scope.$applyAsync()
                    })
                    .catch((e) => {
                        if (e.status === 473) {
                            itemWithComment.task.permissions = itemWithComment.task.permissions.filter(p => p !== 'view')
                            $scope.$applyAsync()
                        }
                    })
            }
        }

        this.checkSavingFields = () => {
            return this.process.dataModel && utils.findSavingFields(this.process.dataModel).length > 0
        }

        const groupCommentsByTask = (tasksStore, comment) => {
            let { task } = comment
            let comments = tasksStore[task.id] ? tasksStore[task.id].comments : []
            comments.push(comment)
            comments.sort((a, b) => a.creationDate - b.creationDate)
            tasksStore[task.id] = {
                task,
                comments
            }

            return tasksStore
        }

        let printingInProgress
        this.printProcess = () => {
            if (printingInProgress) {
                return
            }
            printingInProgress = true

            let processForPrint = {}
            const requests = []

            const processTreeRequest = ApiCalls.getProcessSubTree(this.process.id)
            processTreeRequest.then((data) => {
                processForPrint = { ...processForPrint, ...data }
            })
            requests.push(processTreeRequest)

            if (this.checkPermission(this.process, 'viewComments')) {
                const processCommentsRequest = ApiCalls.getProcessComments(this.process.id, { all: true })
                processCommentsRequest.then(data => {
                    const tasksWithCommentsById = data.list.reduce(groupCommentsByTask, {})
                    processForPrint.comments = Object.keys(tasksWithCommentsById).map(taskId => tasksWithCommentsById[taskId])
                    processForPrint.commentsCount = processForPrint.comments.reduce((acc, { task }) => acc + task.commentsCount, 0)
                    processForPrint.attachmentsCount = processForPrint.comments.reduce((acc, { task }) => acc + task.attachmentsCount, 0)
                })
                requests.push(processCommentsRequest)
            }

            if (this.checkPermission(this.process, 'viewHistory')) {
                const processHistoryRequest = ApiCalls.getProcessHistory(this.process.id)
                processHistoryRequest.then(data => {
                    processForPrint.history = data.list || []
                })
                requests.push(processHistoryRequest)
            }

            const originalTitle = $window.document.title
            const restoreTitle = () => {
                $window.document.title = originalTitle
                $window.window.removeEventListener('afterprint', restoreTitle)
            }

            $q.all(requests).then(() => {
                this.processForPrint = processForPrint
                $scope.$applyAsync()
                $timeout(() => {
                    $window.document.title = processForPrint.name
                    $window.window.addEventListener('afterprint', restoreTitle)
                    $window.window.print()
                    printingInProgress = false
                })
            })
        }

        const initialize = () => {
            this.itemsCountText = angular.toJson({ COUNT: 0 })

            if ($stateParams.openedGroups && $stateParams.openedGroups.length) {
                this.openedGroups = $stateParams.openedGroups.slice()
                $stateParams.openedGroups = []
            } else {
                this.openedGroups = []
            }

            initializeProcessDataModel()

            if (this.openedGroups.length) {
                let openedGroup = this.openedGroups[this.openedGroups.length - 1]
                this.fetchLevelItems(openedGroup)
            } else {
                this.updateItems()
            }

            this.activeMode = $state.current.data.activeMode

            $timeout(() => {
                if (PageSettings.leftScroll) {
                    document.getElementById('process-tasks').scrollTop = Number(PageSettings.leftScroll) // eslint-disable-line
                    PageSettings.leftScroll = null
                }
                this.showProcessTasks = true
            })

            $scope.$on(DeferredAction.TaskDelete.CONST.CANCELED, () => this.updateItems())

            this.rootLevelItems = this.process.subTree.slice()

            // PUSHER EVENT HANDLERS
            //bind to connection state_change to be able to determine when connection is lost
            $scope.$on('socket:connected', () => {
                if (PusherHelper.firstCall) {
                    PusherHelper.firstCall = false
                } else {
                    ApiCalls.getProcess($stateParams.id).then(data => {
                        this.process = data
                        initializeProcessDataModel()
                        this.updateItems()
                        $scope.$broadcast('process:reconnect')
                    }, errorResp => {
                        if (errorResp.status === 515) {
                            $state.go('main.notFound', { screen: 'process' })
                        }
                    })
                }
            })
            PusherHelper.subscribe('processUpdated', processUpdatedListener)
            PusherHelper.subscribe('commentAdded', taskUpdatedListener)
            PusherHelper.subscribe('commentDeleted', taskUpdatedListener)

            let customExit = (event, nextState, nextStateParams) => {
                if (this.checkSavingFields() && nextState.name !== 'login') {
                    event.preventDefault()
                    PageSettings.openSavingIndicator('process')
                    setTimeout(() => {
                        $state.go(nextState.name, nextStateParams)
                    }, 1000)
                } else {
                    PageSettings.closeSavingIndicator('process')
                }
            }

            let defaultExit = () => {
                if (this.checkSavingFields()) {
                    $window.event.returnValue = $translate.instant('taskView.text.unsavedChanges')
                }
            }

            let stopWatchStateChange = $scope.$on('$stateChangeStart', customExit)
            $window.addEventListener('beforeunload', defaultExit)

            const printHotKeyHandler = (event) => {
                if (event.key === 'p' && (event.metaKey || event.ctrlKey)) {
                    event.preventDefault()
                    this.printProcess()
                }
            }

            $window.document.addEventListener('keydown', printHotKeyHandler)

            $scope.$on('$destroy', () => {
                stopWatchStateChange()
                $window.removeEventListener('beforeunload', defaultExit)
                $window.document.removeEventListener('keydown', printHotKeyHandler)
                PusherHelper.unsubscribe('processUpdated', processUpdatedListener)
                PusherHelper.unsubscribe('commentAdded', taskUpdatedListener)
                PusherHelper.unsubscribe('commentDeleted', taskUpdatedListener)
            })

            $scope.$on('$stateChangeSuccess', (event, state) => {
                this.activeMode = state.data.activeMode
            })
        }

        initialize()
    }
}
