import utils from '../utils';

export function commentMention(currentUser, PageSettings, $window) {
    'ngInject';
    return {
        require: 'uiMention',
        link: function(scope, $element, $attrs, uiMention) {
            const recalcCommentForm = () => PageSettings.recalcElementTextIndent($element);
            recalcCommentForm();

            uiMention.label = choice => {
                if (!choice) {
                    return;
                }
                let name = choice.fullName.trim() ? choice.fullName : choice.email;
                return '@' + name.split(' ').join('');
            };

            uiMention.findChoices = (match) => {
                uiMention.searchQuery = match[1] ? match[1].toLowerCase() : null;
                let choices = utils.getMentionsList(
                    currentUser,
                    scope.task,
                    scope.comments,
                    scope.allUsers,
                    scope.tree,
                    !uiMention.searchQuery
                )
                    .filter(choice => {
                        if (uiMention.searchQuery) {
                            let parts = uiMention.searchQuery.split(' ');
                            let name = choice.fullName.trim() ? choice.fullName : choice.email;
                            let nameParts = name.toLowerCase().split(' ');
                            let rest = nameParts && nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
                            let queryRest = parts && parts.length > 1 ? parts.slice(1).join(' ') : '';

                            if (!parts[0]) {
                                return [];
                            }

                            if (parts.length) {
                                if (queryRest) {
                                    return (nameParts[0].indexOf(parts[0]) === 0
                                        && rest && rest.indexOf(queryRest.toLowerCase()) === 0)
                                        || (rest && rest.indexOf(uiMention.searchQuery) === 0);
                                } else {
                                    return nameParts[0].indexOf(parts[0]) === 0
                                        || (nameParts[1] && nameParts[1].indexOf(parts[0]) === 0)
                                        || (rest && rest.indexOf(uiMention.searchQuery) === 0);
                                }
                            } else {
                                return nameParts[0].indexOf(parts[0]) === 0
                                    || (nameParts[1] && nameParts[1].indexOf(parts[0]) === 0);
                            }
                        }
                        return true;
                    });
                uiMention.withCommentators = choices.find(user => user.isCommentator);
                return choices;
            };

            angular.element($window).on('resize', recalcCommentForm);

            scope.$on('$destroy', () => {
                angular.element($window).off('resize', recalcCommentForm);
            });
        }
    };
}
