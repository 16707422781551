import React from 'react';
import PropTypes from 'prop-types';

const ReportIcon = ({icon}) => (
    <div className="report-icon">
        <img src={`/assets/icons/${icon}.svg`} alt='' />
    </div>
);

ReportIcon.propTypes = {
    icon: PropTypes.string
};

export default ReportIcon;
