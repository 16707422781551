export function parseLinks($sce, $filter) {
    'ngInject';

    const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp~!$;:*_}{\)\(\'\/+#-]*[\w,@?^=%&amp~!$;:*_}{\)\(\'\/+#-])?/gi;
    const emailRegex = /[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{2,6})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?/gi;

    return (str) => {
        if (!str) {
            return str;
        }

        let escaped = $filter('escapeHtml')(str);
        let result = escaped.replace(urlRegex, url => {
            return `<a target="_blank" href="${url}">${url}</a>`;
        });
        result = result.replace(emailRegex, email => {
            return `<a target="_blank" href="mailto:${email}">${email}</a>`;
        });

        return $sce.trustAsHtml(result);
    };
}
