export function adminRoute ($stateProvider, $urlRouterProvider) {
    'ngInject'

    let modalStateInstance

    $stateProvider
        .state('main.admin', {
            template: require('./layout.html'),
            controller: 'AdminController',
            controllerAs: 'adminCtrl',
            abstract: true
        })
        .state('main.admin.users', {
            url: '/admin',
            template: require('./views/users.html'),
            controller: 'AdminUsersController',
            controllerAs: 'ctrl'
        })
        .state('main.admin.users.invite', {
            url: '/invite',
            onEnter: ($state, $stateParams, $rootScope, $modal, PageSettings) => {
                'ngInject'

                modalStateInstance = PageSettings.inviteUser()

                modalStateInstance.result
                    .then(
                        data => {
                            let invitedUsersInfo
                            if (data.success) {
                                invitedUsersInfo = data.result.length === 1 ? data.result[0] : { count: data.result.length }
                            }
                            $state.go('^', { invitedUsersInfo })
                            $rootScope.$broadcast('invite', data.result.map(user => Object.assign({}, user, { isInvited: true })))
                        },
                        () => $state.go('^')
                    )
            },
            onExit: () => {
                modalStateInstance.close(false)
            }
        })
        .state('main.admin.groups', {
            url: '/admin/groups',
            template: require('./views/groups.html'),
            controller: 'AdminGroupsController',
            controllerAs: 'ctrl'
        })
        .state('main.admin.company', {
            url: '/admin/company',
            template: require('./views/company.html'),
            controller: 'AdminCompanyController',
            controllerAs: 'ctrl',
            resolve: {
                timezones: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getTimezones()
                }
            }
        })
        .state('main.admin.calendar', {
            url: '/admin/calendar',
            template: require('./views/calendar.html'),
            controller: 'AdminCalendarController',
            controllerAs: 'ctrl'
        })
        .state('main.admin.billing', {
            url: '/billing?id&state',
            template: require('./views/billing.html'),
            controller: 'AdminBillingController',
            controllerAs: 'ctrl'
        })
        .state('main.admin.integrations', {
            url: '/admin/integrations',
            template: require('./views/integrations.html'),
            controller: 'AdminIntegrationsController',
            controllerAs: 'ctrl'
        })

    $urlRouterProvider.otherwise('/')
}
