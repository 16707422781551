import config from '../../../config'
import { asSelectOption } from '../../reports/store/utils'

export class ProfileController {
    constructor ($scope, profile, timezones, FormErrors, currentUser, $translate, $auth, $modal, $timeout, $window,
        ApiCalls, ServerConfig, PageSettings) {
        'ngInject'
        let timeoutId

        this.timezones = timezones
        this.languages = ServerConfig.languages.map(({ code, name }) => asSelectOption({ id: code, name }))
        this.setProfileLanguage = ({ value }) => {
            const selectedLanguage = ServerConfig.languages.find(l => l.code === value) || ServerConfig.languages.find(l => l.code === 'en')
            this.profileLanguage = asSelectOption({ id: selectedLanguage.code, ...selectedLanguage })
        }

        this.profile = profile
        this.setProfileLanguage({ value: this.profile.lang })

        this.checkField = fieldName => {
            if (!$scope.profileForm || !$scope.profileForm[fieldName]) {
                return
            }
            return $scope.profileForm[fieldName].$invalid && $scope.profileForm[fieldName].$dirty
        }
        this.fieldError = fieldName => {
            return $scope.profileForm && FormErrors($scope.profileForm, fieldName)
        }

        this.pattern = fieldName => {
            if (!this.profile) {
                return
            }
            let str = this.profile[fieldName] || ''
            this.profile[fieldName] = str.match(config.pattern_names).join('')
            $scope.profileForm[fieldName].delSymbols = (str !== this.profile[fieldName])
        }

        this.emailPattern = () => {
            return config.pattern_emails
        }

        this.handleOnChange = () => {
            if (this.formHint) {
                this.formHint = null
            }
            if (this.formHintError) {
                this.formHintError = null
            }
        }

        this.resendEmail = () => {
            ApiCalls.resendVerificationEmail()
                .then(data => {
                    if (data.success) {
                        $scope.$emit('email:verify', this.profile.email)
                    }
                })
                .catch(this.handleServerError)
        }

        this.formSubmit = () => {
            let form = $scope.profileForm

            if (form.$valid) {
                let postData = {
                    version: this.profile.version,
                    firstName: this.profile.firstName || '',
                    lastName: this.profile.lastName || '',
                    email: this.profile.email,
                    timeZoneId: this.profile.timeZoneId,
                    skipHelpTooltips: this.profile.skipHelpTooltips,
                }
                if (this.profileLanguage.value !== this.profile.lang) {
                    postData.lang = this.profileLanguage.value
                }
                ApiCalls.updateProfile(postData)
                    .then(resp => {
                        if (resp.success) {
                            this.formHint = $translate.instant('text.profileHasBeenUpdated')
                            this.profile = resp.result
                            angular.extend(this.profile, resp.result)
                            this.setProfileLanguage({ value: this.profile.lang })
                            currentUser.timeZoneId = resp.result.timeZoneId
                            currentUser.fullName = this.profile.firstName + ' ' + (this.profile.lastName || '')
                            currentUser.skipHelpTooltips = this.profile.skipHelpTooltips
                            currentUser.lang = this.profile.lang
                            if (resp.result.token) {
                                $auth.setToken(resp.result.token)
                            }
                            $translate.use(this.profile.lang)
                        }
                    })
                    .catch(errorResp => {
                        this.formHintError = errorResp.data
                            ? errorResp.data.displayError || errorResp.data.error
                            : $translate.instant('error.site')
                    })
                    .finally(() => {
                        if (timeoutId) {
                            $timeout.cancel(timeoutId)
                        }
                        timeoutId = $timeout(() => this.formHint = null, config.errorDelay)
                    })
            }
        }

        this.uploadFiles = (file, errFiles) => {
            if (this.formHint) {
                this.formHint = null
            }
            if (this.formHintError) {
                this.formHintError = null
            }
            if (errFiles && errFiles.length) {
                let modalScope = $scope.$new()
                modalScope.title = $translate.instant('text.thisTypeIsNotSupported')
                modalScope.text = $translate.instant('text.profilePhotoShouldBe')
                modalScope.additionalText = $translate.instant('text.pleaseSelectAnotherFile')
                PageSettings.openAlertModal(modalScope)
                    .finally(() => modalScope.$destroy())
            }
            if (file) {
                let modalScope = $scope.$new()
                modalScope.file = file
                modalScope.url = '/api/profile/avatar'
                modalScope.zoomEnabled = true
                let modalInstance = $modal.open({
                    animation: true,
                    windowClass: 'crop-image-modal',
                    template: require('../../../templates/modals/crop-image.html'),
                    controller: 'CropImageController',
                    scope: modalScope,
                    backdrop: 'static'
                })
                modalInstance.result.then(data => {
                    if (data.result) {
                        Object.assign(currentUser, data.result)
                        Object.assign(this.profile, data.result)
                    }
                }).finally(() => {
                    modalScope.$destroy()
                })
            }
        }

        this.handleServerError = (errResp) => {
            let modalScope = $scope.$new()
            modalScope.title = $translate.instant('error.commonTitle')
            modalScope.text = errResp.data && errResp.data.displayError
                ? errResp.data.displayError
                : $translate.instant('error.common')
            PageSettings.openAlertModal(modalScope)
                .then(() => $window.location.reload())
                .finally(() => modalScope.$destroy())
        }

        this.deleteAvatar = () => {
            if (this.formHint) {
                this.formHint = null
            }
            if (this.formHintError) {
                this.formHintError = null
            }
            ApiCalls.deleteProfileAvatar().then(data => {
                if (data.success) {
                    this.profile = data.result
                    Object.assign(currentUser, { avatarUrl: null })
                }
            })
        }

        this.getMaxSize = () => {
            return ServerConfig.avatarMaxSize
        }
        this.getContentTypes = () => {
            return ServerConfig.avatarContentTypes.join(',')
        }

        this.getUserGroups = () => {
            if (profile.groups && profile.groups.length) {
                return profile.groups.map(g => g.name).join(', ')
            } else {
                return $translate.instant('label.withoutGroups')
            }
        }

        $scope.$on('$destroy', () => {
            if (timeoutId) {
                $timeout.cancel(timeoutId)
            }
        })
    }
}
