import React from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import TimeInput from './time-input.jsx'
import { DEFAULT_TIME } from '../../../modules/reports/store/constants'
import { getService, translate } from '../../../modules/react/utils'

import './date-picker.less'

class DatePicker extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        modifiers: PropTypes.object,
        dayPickerProps: PropTypes.object,
        withTimeInput: PropTypes.bool
    }

    static defaultProps = {
        withTimeInput: false,
        modifiers: {},
        dayPickerProps: {}
    }

    constructor (props) {
        super(props)

        this.DateHelper = getService('DateHelper')

        this.onDateChange = this.onDateChange.bind(this)
        this.onTimeChange = this.onTimeChange.bind(this)

        this.state = {
            value: props.value,
            time: moment(DEFAULT_TIME, this.DateHelper.DATE_FORMATS().TIME_INPUT)
        }
    }

    componentDidUpdate (prevProps) {
        const { value } = this.props
        if (prevProps.value !== value) {
            this.setState({ value })
        }
    }

    render () {
        let { value } = this.state
        const { modifiers, dayPickerProps, withTimeInput } = this.props

        if (!value) {
            value = this.getDefaultDateTime()
        }
        const date = value.toDate()

        return (
            <div>
                <DayPicker
                    showOutsideDays
                    onDayClick={(date, modifiers) => {
                        if (!modifiers.disabled) {
                            this.onDateChange(date, true)
                        }
                    }}
                    selectedDays={[date]}
                    month={date}
                    modifiers={modifiers}
                    renderDay={this.renderDay}
                    localeUtils={MomentLocaleUtils}
                    locale={getService('currentUser')?.lang || 'en'}
                    {...dayPickerProps}
                />
                {withTimeInput && (
                    <div className={'form-group time'}>
                        <label>
                            {translate('label.time')}
                            <i className="icon-common_forms_required"/>
                        </label>
                        <TimeInput value={value.format(this.DateHelper.DATE_FORMATS().TIME_INPUT)}
                                   onChange={this.onTimeChange}/>
                    </div>
                )}
            </div>
        )
    }

    onDateChange (date, close = true) {
        const value = moment(date)
        const { withTimeInput, onChange } = this.props
        this.setState({
            value: withTimeInput ? this.withSelectedTime(value) : value
        }, () => onChange(this.state.value, close))
    }

    onTimeChange (time) {
        this.setState({
            time: moment(time, this.DateHelper.DATE_FORMATS().TIME_INPUT)
        }, () => {
            if (this.state.value) {
                this.onDateChange(this.state.value.toDate(), false)
            }
        })
    }

    renderDay (day) {
        return <span>{day.getDate()}</span>
    }

    getDefaultDateTime () {
        const { withTimeInput } = this.props
        const defaultDateTime = moment()

        return withTimeInput ? this.withSelectedTime(defaultDateTime) : defaultDateTime
    }

    withSelectedTime (moment) {
        const { time } = this.state
        return moment.hour(time.hour()).minute(time.minute())
    }
}

export default DatePicker
