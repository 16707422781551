import config from '../../../config';

export class CopyTemplateModalController {
  constructor($scope, $modalInstance, $translate, ApiCalls, FormErrors, $timeout) {
      'ngInject';
      let timeoutId;
      $scope.FormErrors = FormErrors;

      $scope.ok = ($event) => {
          $event.preventDefault();
          if ($scope.isClicked) {
              return;
          }
          $scope.isClicked = true;
          if (!$scope.tmplNameForm.$dirty) {
              $scope.tmplNameForm.$dirty = true;
              $scope.tmplNameForm.templateName.$dirty = true;
          }
          if ($scope.tmplNameForm.$invalid) {
              if (timeoutId) {
                  $timeout.cancel(timeoutId);
              }
              timeoutId = $timeout(() => {
                  $scope.serverError = null;
                  $scope.isClicked = false;
              }, config.errorDelay);
              return;
          }
          let templId = $scope.template.id;
          let params = {name: $scope.templateName};
          ApiCalls.copyTemplate(templId, params).then(data => {
              if (data.success) {
                  $modalInstance.close(data.result);
              }
          }, errorResponse => {
              $scope.serverError = null;
              $scope.serverErrorData = null;
              let data = errorResponse.data;
              switch(errorResponse.status) {
                  case 516:
                      if (data && data.summaryError) {
                          $scope.serverError = data.summaryError;
                      } else {
                          $scope.serverError = $translate.instant('error.common');
                      }
                      break;
                  case 515:
                      $scope.serverError = $translate.instant('copyTemplate.errors.515');
                      break;
                  case 472:
                      $scope.serverError = $translate.instant('copyTemplate.errors.472');
                      break;
                  case 400:
                      $scope.serverErrorData = data;
                      break;
                  default:
                      if (data && data.displayError) {
                          $scope.serverError = $translate.instant('error.commonWithError', {error: data.displayError});
                      } else {
                          $scope.serverError = $translate.instant('error.common');
                      }
              }
              if (timeoutId) {
                  $timeout.cancel(timeoutId);
              }
              timeoutId = $timeout(() => {
                  $scope.serverError = null;
                  $scope.isClicked = false;
              }, config.errorDelay);
          });
      };

      $scope.getUIError = (form) => {
          if (!form || !form.templateName) {
              return;
          }
          let mid = 'validation.text.fieldIsIncomplete';
          return $translate.instant('validation.text.copyTemplate') + ' '
              + 1 + ' ' + $translate.instant(mid) + '. '
              + $translate.instant('validation.text.fillItOut');
      };

      $scope.checkField = (form, fieldName) => {
          if (!form || !form[fieldName]) {
              return;
          }
          return form[fieldName].$invalid && form[fieldName].$dirty;
      };

      $scope.cancel = () => {
          $modalInstance.dismiss();
      };

      $scope.$on('$destroy', () => {
          if (timeoutId) {
              $timeout.cancel(timeoutId);
          }
      });
  }
}
