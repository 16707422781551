import utils from '../utils'
import isNil from 'lodash/isNil'
import { parseNumericInput } from '../modules/templates/services/data-model-utils'

export class DataModelTableRowController {
    constructor ($scope, $modalInstance, ProcessesSettings, $translate, $timeout, ApiCalls, $q) {
        'ngInject'
        let isLoading = false
        const fieldIsEditable = field => {
            return field.settings && field.settings.isEditable
        }
        const fieldIsVisible = field => {
            return field.settings && !field.settings.isHidden
        }
        const getValues = () => {
            return $scope.section.columns.map(c => {
                const { value = {} } = c
                let resValue
                if ($scope.checkStringType(c) && value.stringValue) {
                    resValue = { stringValue: value.stringValue }
                } else if ($scope.checkEmailType(c) && value.emailValue) {
                    resValue = { emailValue: value.emailValue }
                } else if ($scope.checkYesNoType(c) && value.yesNoValue) {
                    resValue = { yesNoValue: value.yesNoValue }
                } else if ($scope.checkNumberType(c) && value.numericValue) {
                    resValue = { numericValue: Number(value.numericValue.replace(/[^0-9\.-]/g, '')) }
                } else if ($scope.checkDateType(c) && value.dateValue) {
                    resValue = { dateValue: value.dateValue }
                } else if ($scope.checkUserFieldType(c) && value.userValue) {
                    resValue = { userValue: value.userValue }
                } else if ($scope.checkMultiselectType(c) && value.multiChoiceValue && value.multiChoiceValue.length) {
                    resValue = { multiChoiceValue: value.multiChoiceValue }
                } else if ($scope.checkMoneyType(c) && value.moneyValue && value.moneyValue.amount != null) {
                    resValue = {
                        moneyValue: Object.assign({}, value.moneyValue, {
                            amount: !isNil(value.moneyValue.amount)
                                ? parseNumericInput(value.moneyValue.amount)
                                : null
                        })
                    }
                } else if ($scope.checkRadioType(c) && value.radioButtonValue) {
                    resValue = { radioButtonValue: value.radioButtonValue }
                } else if ($scope.checkFilesType(c) && value.files && value.files.length) {
                    let files = value.files ? value.files.slice() : []
                    if (value.copy) {
                        files = files.concat(value.copy)
                    }
                    resValue = { files: files.filter(f => f) }
                } else if ($scope.checkFormulaFieldType(c) && value.numericValue !== undefined) {
                    resValue = { numericValue: value.numericValue }
                } else if ($scope.checkFormulaFieldType(c) && value.moneyValue) {
                    resValue = { moneyValue: value.moneyValue }
                } else if ($scope.checkFormulaFieldType(c) && value.calculationErrors) {
                    resValue = { calculationErrors: value.calculationErrors }
                } else {
                    resValue = { noValue: true }
                }

                return {
                    columnId: c.name.id,
                    value: fieldIsVisible(c) ? resValue : undefined,
                    readOnly: $scope.checkFormulaFieldType(c) || !fieldIsEditable(c)
                }
            })
        }

        let firstColumn = $scope.section.columns[0]
        if ($scope.checkStringType(firstColumn) || $scope.checkEmailType(firstColumn)
            || $scope.checkNumberType(firstColumn) || $scope.checkMoneyType(firstColumn)) {
            $timeout(() => {
                let el = document.getElementById(firstColumn.name.id) //eslint-disable-line
                if (el) {
                    el.focus()
                }
            }, 500)
        }

        if ($scope.isNew) {
            $scope.section.columns.forEach(column => {
                if ($scope.checkUserFieldType(column)) {
                    column.value = {}
                    if (column.name && column.name.options && column.name.options.allowedUsers) {
                        column.name.options.allowedUsers = column.name.options.allowedUsers.map(user => Object.assign(user, { selected: false }))
                    }
                } else if ($scope.checkRadioType(column)) {
                    if (column.name && column.name.defaultValue && column.name.defaultValue.radioButtonValue && column.name.defaultValue.radioButtonValue.isInvalid) {
                        column.name.defaultValue = undefined
                    }
                }
            })
        } else {
            $scope.section.columns.filter(column => $scope.checkUserFieldType(column)).forEach(column => {
                if (column.name && column.name.options && column.name.options.allowedUsers) {
                    column.name.options.allowedUsers = column.name.options.allowedUsers.map(user => Object.assign(user, { selected: false }))
                }
                const col = $scope.row.values.find(val => val.columnId === column.name.id)
                column.value = col ? col.value : {}
            })
        }

        $scope.checkSavingFiles = (column) => {
            return column.value && column.value.files && column.value.files.filter(f => !f.id).length > 0
        }

        $scope.checkSavingFilesInSection = () => {
            return $scope.section.columns.filter(c => $scope.checkSavingFiles(c)).length > 0
        }

        $scope.checkFieldsErrors = () => {
            return $scope.section.columns.find(c => $scope.checkFieldError(c, $scope.isProcessStartMode, true))
        }

        $scope.resetErrors = () => {
            $scope.rowErrorMessage = null
            $scope.rowErrorData = null
        }

        $scope.rowIsClicked = false
        $scope.ok = () => {
            if (isLoading || $scope.checkSavingFilesInSection() || $scope.checkFieldsErrors()) {
                return
            }
            isLoading = true
            $scope.rowIsClicked = true
            $scope.resetErrors()
            const postData = { values: getValues().filter(c => c.value !== undefined) }

            // Check if empty row
            if (!postData.values.find(v => v && (!v.value || !v.value.noValue)) && $scope.isNew) {
                $scope.rowErrorMessage = $translate.instant('tableSection.errors.emptyRow')
                isLoading = false
                return
            }
            // Check if row has incorrect field
            if ($scope.section.columns.find(c => $scope.checkFieldError(c, false, true) && $scope.checkFieldError(c, false, true) !== 'required')) {
                $scope.rowErrorMessage = $translate.instant('tableSection.errors.invalidRow')
                isLoading = false
                return
            }

            let promise
            $scope.onChangeSavingState(true)
            if ($scope.processId) {
                const data = { values: postData.values.filter(c => !c.readOnly)}
                if ($scope.isNew) {
                    promise = ApiCalls.addProcessDataModelTableRow($scope.processId, $scope.section.id, data)
                } else {
                    promise = ApiCalls.updateProcessDataModelTableRow($scope.processId, $scope.section.id, $scope.row.rowIndex, data)
                }
            } else if (!$scope.isIgnoreSaveRow) {
                const data = { values: postData.values.filter(c => !c.readOnly)}
                if ($scope.isNew) {
                    promise = ApiCalls.addDataModelTableRow($scope.taskId, $scope.section.id, data)
                } else {
                    promise = ApiCalls.updateDataModelTableRow($scope.taskId, $scope.section.id, $scope.row.rowIndex, data)
                }
            } else {
                const data = { result: angular.copy(postData) }
                const ids = ($scope.section.rows || []).map(row => row.rowIndex)
                data.result.rowIndex = utils.uid4(ids)
                promise = $q.resolve(data)
            }
            promise
                .then(data => $modalInstance.close(data.result))
                .catch(errResp => {
                    let data = errResp.data
                    if (data && data.displayError) {
                        $scope.rowErrorMessage = data.displayError
                    } else {
                        $scope.rowErrorMessage = $translate.instant('error.common')
                    }
                    if (errResp.status === 400 && data) {
                        $scope.rowErrorData = data
                    }
                    ProcessesSettings.callAlertTaskPageModal(errResp)
                })
                .finally(() => {
                    $scope.onChangeSavingState(false)
                    return isLoading = false
                })
        }

        $scope.delete = () => {
            $scope.deleteRow($scope.section, $scope.row.rowIndex)
            $modalInstance.dismiss()
        }

        $scope.cancel = () => $modalInstance.dismiss()
    }
}
