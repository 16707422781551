import utils from '../utils';

export class ProcessStartController {
    constructor($scope, $stateParams, process, actorsTree, FormErrors, PageSettings, ApiCalls, PrintErrors, $timeout,
                $translate, $state, $window, ProcessesSettings, $location) {
        'ngInject';
        let self = this;
        let backUrl = $stateParams.url || '/templates';
        // Initialization
        this.adHoc = $stateParams.adHoc || null;
        this.tasksActorsDirty = {};
        this.FormErrors = FormErrors;
        this.PrintErrors = PrintErrors;
        this.sortCallbacks = ProcessesSettings.processSortOptions;
        this.process = process;
        this.actors = {
            groups: actorsTree.groups,
            users: actorsTree.users,
            specialRoles: actorsTree.specialRoles
        };

        // Initialization for future comparison of two process-objects
        this.process.description = process.description || '';
        this.process.tasks.forEach(task => {
            task.description = task.description || '';
        });

        // Confirmations
        let checkChanges = () => {
            let obj1 = utils.processToCompare(self.process);
            let obj2 = utils.processToCompare(self.processOrigin);

            if (angular.equals(obj1, obj2)) {
                return false;
            }

            let filledArray = self.process.tasks.filter(task => {
                let groups = task.actors && task.actors.groups ? task.actors.groups : [];
                let users = task.actors && task.actors.users ? task.actors.users : [];
                let roles = task.actors && task.actors.specialRoles ? task.actors.specialRoles : [];
                return task.description || task.dueDate || groups.length || users.length || roles.length;
            });

            let checkValidationInputs = true;
            if ($scope.templateForm && $scope.templateForm.$error.required) {
                checkValidationInputs = $scope.templateForm.$error.required.length < self.process.tasks.length + 1;
            }
            return self.process.description || filledArray.length || checkValidationInputs;
        };

        let makeOrigin = true;
        $scope.$watch('processStartCtrl.process', () => {
            if (makeOrigin) {
                this.processOrigin = angular.copy(this.process);
                makeOrigin = false;
            }
        }, true);

        let customExit = (event, nextState) => {
            if (checkChanges() && !this.stateCalled && nextState.name !== 'login') {
                if (event) {
                    event.preventDefault();
                }
                let modalInstance = ProcessesSettings.callExitModal({
                    title: 'startProcess.text.leaveThisPage',
                    text: 'startProcess.text.unsavedChanges'
                });
                modalInstance.result.then(() => {
                    this.stateCalled = true;
                    $state.go(nextState.name, {id: this.process.id});
                });
            }
        };
        let destroyCallback = $scope.$on('$stateChangeStart', customExit);

        let defaultExit = () => {
            if (checkChanges()) {
                $window.event.returnValue = $translate.instant('createTemplate.text.haveUnsavedChanges');
            }
        };

        // Template tasks
        this.addNewTask = () => {
            let index = self.process.tasks.length;
            self.process.tasks.push({});
            $timeout(() => {
                document.getElementById('task-title-' + index).focus(); // eslint-disable-line
            });
        };
        this.removeTask = $index => {
            self.process.tasks.splice($index, 1);
        };

        this.getErrorsInString = (error) => {
            if (angular.isArray(error)) {
                return error.join('. ') + '. ';
            }
            return error;
        };

        // Form submitting and validation
        this.templateFormSubmit = ($event) => {
            $event.preventDefault();

            let form = $scope.templateForm;
            form.isClicked = true;
            if (utils.checkInvalidUsers(this.process) || this.checkTasksActorsIsEmpty()) {
                return;
            }

            if (form.$valid) {
                let promise;
                let tasks = angular.copy(self.process.tasks);
                let processData = {
                    name: self.process.name,
                    description: self.process.description || '',
                    tasks: utils.subTreeToServer(tasks)
                };
                if (self.process.dueDate) {
                    processData.dueDate = self.process.dueDate;
                }
                if (self.process.id) {
                    processData.id = self.process.id;
                    processData.version = self.process.version;
                    promise = ApiCalls.startProcess(self.process.id, processData);
                } else {
                    promise = ApiCalls.startProcessFromScratch(processData);
                }

                promise.then(data => {
                    if (data.success) {
                        destroyCallback();
                        $state.go('main.processView', {id: data.result.id, url: backUrl});
                    }
                }, errorResponse => {
                    if (self.serverErrorData) {
                        delete self.serverErrorData;
                    }
                    delete self.serverError;

                    if (errorResponse.data) {
                        if (errorResponse.status === 400) {
                            self.serverErrorData = errorResponse.data;
                            self.serverError = self.PrintErrors.getServerErrors(self.serverErrorData);
                        } else if (errorResponse.data.displayError) {
                            self.serverError = errorResponse.data.displayError;
                        } else {
                            self.serverError = $translate.instant('template.process.notStarted');
                        }
                    } else {
                        self.serverError = $translate.instant('template.process.notStarted');
                    }
                });
            }
        };


        this.checkTasksActorsIsEmpty = () => this.process.tasks.some((task) => utils.checkActorsIsEmpty(task.actors));
        this.checkTasksInvalidActors = () => this.process.tasks.some((task) => utils.checkInvalidActors(task.actors));

        this.checkField = (form, fieldName) => {
            if (form && form[fieldName]) {
                return (form[fieldName].$invalid && form[fieldName].$dirty)
                  || (form[fieldName].$invalid && $scope.templateForm.isClicked);
            }
        };

        this.mdCheck = event => {
            if (event && event.relatedTarget && event.relatedTarget.classList.contains('mdh-item')) {
                return;
            }
            this.processDescrEdit = false;
        };

        this.editDescr = (id) => {
            let el = document.getElementById(id); //eslint-disable-line
            if (el) {
                this.processDescrEdit = true;
                el.focus();
            }
        };

        this.cancel = () => {
            $location.path(backUrl);
            $scope.$applyAsync();
        };

        $timeout(() => {
            document.getElementById('template-title').focus(); // eslint-disable-line
        });

        $window.addEventListener('beforeunload', defaultExit);
        $scope.$on('$destroy', () => {
            destroyCallback();
            $window.removeEventListener('beforeunload', defaultExit);
        });
    }
}
