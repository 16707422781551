import { onboardingRoute } from './onboarding.config.js'
import { OnboardingController } from './onboarding.controller.js'

import './index.less'

let ngModule = angular.module('onboarding', [])
    .config(onboardingRoute)
    .controller('OnboardingController', OnboardingController)

export default ngModule
