import colors from '../../libs/colors';
import './user-field.less'

export const UserField = {
    bindings: {
        field: '=',
        ngChange: '&?',
        onOpen: '&?',
        readonly: '<?'
    },
    template: `<div>
    <single-select
            id="$ctrl.field.name.id"
            placeholder="label.selectUser"
            items="$ctrl.items"
            on-select="$ctrl.onSelect(item)"
            on-search="$ctrl.onSearch(query)"
            default-icon="$ctrl.defaultIcon"
            on-open="$ctrl.onOpen()"
            readonly="$ctrl.readonly"
            dropdown-class="user-field-dropdown"></single-select>
</div>`,
    controller: function($scope, $element, $filter, $sce) {
        'ngInject';

        let searchText = '';
        let sourceItems = [];

        this.defaultIcon = 'icon-company_users';
        this.items = [];
        this.readonly = false;

        const getColor = (index) => {
            return colors[index - 1];
        };

        const getIcon = (user) => {
            let icon;
            let background = 'transparent';
            let deleted = '';
            if (user.avatarUrl) {
                icon = `<img src="${user.avatarUrl + '_24'}" alt="${user.fullName.trim() ? user.fullName : user.email}"/>`;
            } else {
                let initials = $filter('initials')(user.fullName.trim() ? user.fullName : user.email);
                icon = `<span>${initials}</span>`;
                background = getColor(user.colorIndex);
            }

            return $sce.trustAsHtml(`
                <div class="thumbnail" style="background-color: ${background}">
                    ${icon}
                    ${deleted}
                </div>
            `);
        };

        const updateItems = () => {
            const filteredItems = searchText
                ? $filter('search')(sourceItems, searchText)
                : sourceItems.slice();

            const mappedItems = filteredItems.map(user => ({
                id: user.id,
                name: user.fullName.trim() ? user.fullName : user.email,
                selected: user.selected,
                icon: () => getIcon(user),
                isDisabled: user.isDisabled,
                isHidden: user.isHidden
            }));

            this.items = $filter('orderBy')(mappedItems, 'name');
        };

        const updateValue = (value) => {
            this.field.value = value ? {userValue: {id: value.id, fullName: value.name}} : {};
            if (this.ngChange) {
                this.ngChange({field: this.field});
            }
        };

        const updateSelectedItem = (value) => {
            if (value && !sourceItems.find(item => item.id === value.id)) {
                value.isHidden = this.allowedUsers !== undefined;
                sourceItems.push(value);
            }

            sourceItems.forEach(item => {
                item.selected = value && item.id === value.id;
            });
        };

        this.onSelect = (item) => {
            if (item && item.noResults) {
                return;
            }

            sourceItems.forEach(sourceItem => {
                sourceItem.selected = item && sourceItem.id === item.id;
            });

            updateValue(item);
            updateItems();
        };

        this.onSearch = (query) => {
            searchText = query;
            updateItems();
        };

        this.$onInit = () => {
            $element.addClass('user-field');
            $scope.$watch(() => this.field, () => {
                this.$onChanges();
            }, true);
        };

        this.$onChanges = () => {
            const {value} = this.field;
            const userValue = value ? value.userValue : null;
            const {options} = this.field.name;
            this.allowedUsers = options && options.allowedUsers;
            sourceItems = this.allowedUsers || [];

            if (userValue) {
                updateSelectedItem(Object.assign(userValue, {isDisabled: userValue.isDisabled || userValue.isDeleted}));
            } else {
                updateSelectedItem();
            }

            updateItems();
        };
    }
};
