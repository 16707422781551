import Cropper from 'cropperjs';
import toBlob from 'canvas-to-blob';

const ZOOM_STEP = 0.05;

export class CropImageController {
    constructor($scope, $modalInstance, Upload, $timeout) {
        'ngInject';
        let cropper;
        let isUploading = false;

        let initCropper = element => {
            cropper = new Cropper(element, {
                autoCrop: true,
                aspectRatio: $scope.aspectRatio || 1,
                preview: '.crop-image-modal .modal-footer .cropped-preview',
                scalable: false,
                rotatable: false,
                movable: $scope.zoomEnabled,
                zoomOnTouch: $scope.zoomEnabled,
                zoomOnWheel: $scope.zoomEnabled,
                wheelZoomRatio: ZOOM_STEP,
                dragMode: 'move',
                minContainerHeight: 300
            });
        };

        // Read data url from image file
        let FR = new FileReader();
        FR.onload = (e) => {
            $scope.$applyAsync(() => {
                $scope.imageSrc = e.target.result;
            });

            $timeout(() => {
                let el = document.getElementById('cropped-image'); // eslint-disable-line
                if (el) {
                    initCropper(el);
                }
            }, 50);
        };
        FR.readAsDataURL($scope.file);

        let sendFile = blob => {
            if (isUploading) {
                return;
            }

            let file = blob;
            file.name = $scope.file.name;
            isUploading = true;
            file.upload = Upload.upload({
                url: $scope.url,
                method: 'POST',
                data: {
                    file: file
                }
            });
            file.upload.then(response => {
                isUploading = false;
                $scope.progress = null;
                cropper.enable();
                if (response.data) {
                    $modalInstance.close(response.data);
                }
            }, (response) => {
                isUploading = false;
                $scope.progress = null;
                cropper.enable();
                if (response.status > 0 && response.data) {
                    if (response.data.displayError) {
                        this.serverError = response.data.displayError;
                    }
                }
            }, (evt) => {
                cropper.disable();
                $scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };

        $scope.zoomIn = () => {
            cropper.zoom(ZOOM_STEP);
        };

        $scope.zoomOut = () => {
            cropper.zoom(-ZOOM_STEP);
        };

        $scope.resetZoom = () => {
            let cropBox = cropper.getCropBoxData();
            cropper.reset();
            cropper.setCropBoxData(cropBox);
        };

        $scope.ok = () => {
            if (toBlob.supported) {
                let canvas = cropper.getCroppedCanvas();
                if (canvas) {
                    canvas.toBlob(sendFile, $scope.file.type);
                }
            } else {
                sendFile($scope.file);
            }
        };

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        $scope.$on('$destroy', () => {
            cropper.destroy();
        });
    }
}
