export class IntegrationsController {
    constructor($location, $scope, $state, $stateParams, $timeout, $window, ApiCalls, PageSettings, ProfileDataService) {
        'ngInject';

        let updateChannelTimeoutId;

        const updateLocationWithId = id => {
            $state.go('main.profile.integrations.channel', { id })
        }

        this.addingToSlack = false;
        this.addToSlack = () => {
            if (this.addingToSlack) {
                return;
            }

            this.addingToSlack = true;

            ApiCalls.addToSlack()
                .then(json => {
                    const {addIntegrationUrl} = json.result;
                    if (addIntegrationUrl) {
                        $window.location.href = addIntegrationUrl;
                    }
                })
                .catch(() => this.addingToSlack = false);
        };

        this.getChannelName = channel => {
            const {slackChannelName, slackTeamName} = channel.details;
            return `${slackChannelName} at ${slackTeamName}`;
        };

        this.getChannelIcon = channel => {
            const {slackChannelName} = channel.details;
            return slackChannelName[0] === '@' ? 'icon-et' : 'icon-hashtag';
        };

        this.isSelected = channel => {
            return this.selectedChannel && channel.id === this.selectedChannel.id;
        };

        this.openChannelSettings = channel => {
            updateLocationWithId(channel.id);
        };

        this.closeChannelSettings = () => {
            $state.go('main.profile.integrations')
        };

        this.updateNotification = notification => {
            notification.toSend = !notification.toSend;
            $timeout.cancel(updateChannelTimeoutId);
            updateChannelTimeoutId = $timeout(() => {
                this.updateChannel(this.selectedChannel);
            }, 500);
        };

        this.updateChannel = channel => {
            this.channelIsSaving = true;
            this.channelIsSaved = false;
            ProfileDataService.updateSlackChannel(channel).finally(() => {
                $timeout(() => {
                    this.channelIsSaving = false;
                    this.channelIsSaved = true;
                });
                $timeout(() => {
                    this.channelIsSaved = false;
                }, 2000);
            });
        };

        this.manageInSlack = channel => {
            window.open(channel.details.slackConfigUrl, '_blank');
        };

        this.removingChannel = false;
        this.removeChannel = channel => {
            if (this.removingChannel) {
                return;
            }

            this.removingChannel = true;

            PageSettings.deleteConfirmation('slack').result
                .then(() => {
                    ProfileDataService.removeSlackChannel(channel.id)
                        .then(() => {
                            this.removingChannel = false
                            this.channels = this.channels.filter(({id}) => id !== channel.id)
                            $state.go('main.profile.integrations')
                        });
                })
                .catch(() => this.removingChannel = false);
        };

        $scope.$on('$stateChangeStart', (event, toState) => {
            if (toState.name !== 'main.profile.integrations' || toState.name !== 'main.profile.integrations.channel') {
                ProfileDataService.invalidateSlackChannels();
            }
        });

        $scope.$on('$stateChangeSuccess', (event, state , params) => {
            if (this.isReady && params.id) {
                this.selectedChannel =  this.channels.find(c => c.id === params.id)
            }
            if (!params.id) {
                this.selectedChannel = null
            }
            ProfileDataService.getSlackChannels().then(channels => {
                this.channels = channels
                this.isReady = true
                if (params.id) {
                    this.selectedChannel =  this.channels.find(c => c.id === params.id)
                }
                $scope.$apply()
            })
        })
    }
}
