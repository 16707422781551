import config from '../../config';

const utils = {
    usersSort: (a, b) => {
        if (!a.fullName && !b.fullName) {
            return 0;
        }
        if (a.fullName.indexOf('@') !== -1 && b.fullName.indexOf('@') === -1) {
            return -1;
        } else if (a.fullName.indexOf('@') === -1 && b.fullName.indexOf('@') !== -1) {
            return 1;
        }
        if (a.fullName > b.fullName) {
            return 1;
        } else if (a.fullName < b.fullName) {
            return -1;
        }
        return 0;
    },
    groupNameTrimRestrictedChar: (groupName) => {
        groupName = groupName || '';
        groupName = groupName.replace(config.pattern_invisible_control_char, '');
        groupName = groupName.replace(config.pattern_groups_names, '');
        groupName = groupName.replace(config.pattern_whitespace_separator, ' ');
        groupName = groupName.replace(config.pattern_multiple_whitespaces, ' ');
        return groupName.trim();
    }
};

export default utils;