import './expression.less'
import { findFieldsUsedInExpression, flattenReplacement, getStaticPart, getStaticPartLength, } from './index'

export const Expression = {
    bindings: {
        model: '=',
        isDisabled: '<',
        replacementGroups: '<',
        fieldId: '<',
        placeholder: '@',
        hideHint: '<',
        isRequired: '<',
        isTaskTitle: '<',
        minLength: '<',
        maxLength: '<',
        error: '<',
        validator: '&?',
        allowedSymbols: '<',
        noFieldsTooltip: '<?'
    },
    template: `
        <div class="expression-component">
            <expression-input
                model="$ctrl.model"
                selection="$ctrl.selection"
                is-disabled="$ctrl.isDisabled"
                replacements="$ctrl.replacements"
                on-update="$ctrl.updateModel(value)"
                on-trigger="$ctrl.openDropdown()"
                error="$ctrl.error"
                field-id="$ctrl.fieldId"
                placeholder="$ctrl.placeholder"
                allowed-symbols="$ctrl.allowedSymbols"
                no-fields-tooltip="$ctrl.noFieldsTooltip"
            ></expression-input>
            <expression-options
                is-opened="$ctrl.isOpened"
                groups="$ctrl.availableGroups"
                on-select="$ctrl.selectReplacement(id)"
                on-focus-lost="$ctrl.closeDropdown()"
            ></expression-options>
            <div class="help-text" ng-if="!$ctrl.hideHint && !$ctrl.isDisabled && !$ctrl.error">{{ 'tooltips.templateEdit.processStartFields.triggerSymbol' | translate }}</div>
            <div class="error" ng-if="!$ctrl.isDisabled && $ctrl.error">{{$ctrl.error}}</div>
        </div>
    `,
    controller: function ($translate, $scope, $document, $element, $timeout) {
        'ngInject'

        this.isOpened = false
        this.availableGroups = []
        this.selection = { start: 0, end: 0 }

        this.updateReplacements = () => {
            this.replacements = flattenReplacement(this.replacementGroups)
            this.updateAvailableReplacements()
        }

        this.updateAvailableReplacements = () => {
            this.availableGroups = this.replacementGroups
                .map(group => {
                    return Object.assign({}, group, {
                        replacements: group.replacements.filter(replacement => {
                            return !replacement.hidden
                        })
                    })
                })
                .filter(group => group.replacements.length > 0)
        }

        this.updateModel = (value) => {
            this.model = value
            this.updateAvailableReplacements()
            $timeout(() => this._validate(true))
        }

        this._validate = (inputChanged) => {
            let fieldsInUse = findFieldsUsedInExpression(this.replacements, this.model)

            if (this.validator) {
                this.validator({
                    obj: {
                        expression: this.model,
                        staticPart: getStaticPart(this.model, this.replacements),
                        fieldsInUse,
                        inputChanged
                    }
                })
            } else {
                this.error = undefined
                let staticPartLength = getStaticPartLength(this.model, fieldsInUse)
                if ((fieldsInUse.length || this.isRequired) && !staticPartLength) {
                    this.error = $translate.instant('validation.textRequired')
                } else if (staticPartLength > 0 && staticPartLength < this.minLength) {
                    this.error = $translate.instant('validation.minLength', { value: this.minLength })
                } else if (staticPartLength > this.maxLength) {
                    this.error = $translate.instant('validation.maxLength', { value: this.maxLength })
                }
            }
        }

        this.openDropdown = () => {
            this.isOpened = this.availableGroups.length > 0
            this.toggleOutsideClickBinding(true)
        }
        this.ignoreCloseCallback = false
        this.closeDropdown = () => {
            if (!this.ignoreCloseCallback) {
                this.isOpened = false
                this.toggleOutsideClickBinding(false)
            }
        }
        this.applyCloseDropdown = () => $scope.$apply(this.closeDropdown)

        this.selectReplacement = (replacementId) => {
            if (replacementId) {
                let replacement = this.replacements.find(r => r.id === replacementId)
                if (replacement) {
                    let { start, end } = this.selection
                    let str = this.model
                    if (str[start - 1] === '#') {
                        start = start - 1
                    }
                    this.updateModel(str.slice(0, start) + replacement.value + str.slice(end))
                }
            }
            this.closeDropdown()
        }

        this.toggleOutsideClickBinding = (state) => {
            if (state) {
                $document.on('click', this.applyCloseDropdown)
            } else {
                $document.off('click', this.applyCloseDropdown)
            }
        }

        this.$onChanges = () => {
            if (this.isDisabled) {
                this.isOpened = false
            } else {
                this.updateReplacements()
            }
        }

        this.$onDestroy = () => {
            this.toggleOutsideClickBinding(false)
        }

        this.$postLink = () => {
            this._validate()
            let templateString = this.model || ''
            this.selection.start = this.selection.end = templateString.length
            // Do not close dropdown if click was inside component
            $element.on('click', () => {
                if (this.isOpened) {
                    this.ignoreCloseCallback = true
                    $timeout(() => {
                        this.ignoreCloseCallback = false
                    }, 500)
                    this.openDropdown()
                }
            })
        }
    }
}
