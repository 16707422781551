import utils from '../utils'

export class TemplatesStartController {
    constructor ($scope, $translate, $state, $location, $timeout, $stateParams, ApiCalls, PageSettings, DateHelper) {
        'ngInject'

        this.ApiCalls = ApiCalls
        this.PageSettings = PageSettings
        this.$scope = $scope
        this.$timeout = $timeout
        this.showTotal = false

        this.back = () => {
            $location.path(this.url)
            $scope.$applyAsync()
        }

        this.startProcess = (template, event) => {
            if (this.preventStart) {
                return
            }
            if (event) {
                this.preventStartProcess()
            }
            if (template) {
                if (!this.checkPermission(template, 'start')) {
                    return
                }
                let modalInstance = PageSettings.startProcess(template)
                modalInstance.result
                    .then(
                        (res) => {
                            if (res) {
                                $state.go('main.processes.my')
                            }
                        }
                    )
            } else {
                $state.go('main.processStartAdHoc', { url: $location.path() })
            }
        }

        this.copyTemplate = (template) => {
            const position = this.templates.findIndex(t => t.id === template.id)
            PageSettings.copyTemplate(template).result.then(data => {
                if (data) {
                    this.templates.splice(position + 1, 0, this.prepareTemplate(data))
                    PageSettings.toasterData = {
                        iconClass: 'icon-template_duplicate_roundless',
                        text: $translate.instant('copyTemplate.toaster.text'),
                        linkText: $translate.instant('copyTemplate.toaster.linkText'),
                        route: {
                            name: 'main.template',
                            data: { id: data.id }
                        }
                    }
                }
            })
        }

        this.deleteTemplate = (template) => {
            let modalInstance = PageSettings.deleteConfirmation('template')
            modalInstance.result.then(() => {
                const deleteCompleteAction = () => {
                    this.templates = this.templates.filter(t => t.id !== template.id)
                    this.triggerListChange()
                }

                ApiCalls.deleteTemplate(template.id, template.version)
                    .then(deleteCompleteAction)
                    .catch(res => PageSettings.errorHandlerModal(res, deleteCompleteAction))
            })
        }

        this.goToTemplate = template => {
            if (!this.checkPermission(template, 'view')) {
                return
            }
            return this.checkScreenSize(() => {
                return $state.go('main.template', { id: template.id })
            })
        }

        this.lastRunFailed = template => {
            const { schedule } = template
            return (schedule?.id && schedule?.lastRunDate && schedule?.lastRunStatus === 'FAILURE')
        }

        this.scheduleHasIssue = template => {
            const { schedule } = template
            return (schedule?.id && template.isScheduleRestricted)
        }

        this.openTemplateSchedule = template => {
            if (template.isScheduleRestricted) {
                PageSettings.openScheduleRestrictedModal().result.then(() => this.goToEdit(template, undefined, { tab: 'FIELDS' }))
            } else {
                this.templateForScheduleDialog = template
            }
        }

        this.closeTemplateSchedule = () => {
            this.templateForScheduleDialog = undefined
        }

        this.updateTemplateSchedule = (template, close = false) => {
            if (close) {
                this.closeTemplateSchedule()
            }
            const tpl = this.templates.find(t => t.id === template.id)
            tpl.schedule = template.schedule
        }

        this.formatScheduleRunDate = template => {
            return template.schedule?.nextRunDate
                ? DateHelper.timestampToDateTime(template.schedule.nextRunDate, DateHelper.DATE_FORMATS().SCHEDULE_RUN_DATE_TIME)
                : ''
        }

        this.preventStartProcess = () => {
            this.preventStart = true
            $timeout(() => this.preventStart = false, 1000)
        }

        this.goToEdit = (template, event, stateParams = {}) => {
            if (event) {
                this.preventStartProcess()
            }

            return this.checkScreenSize(() => {
                return $state.go('main.templateEdit', { id: template.id, url: $location.path(), ...stateParams })
            })
        }

        this.checkScreenSize = (callback) => {
            if (PageSettings.smallScreensMode()) {
                let modalScope = $scope.$new()
                modalScope.title = $translate.instant('desktopIsNeeded.title')
                modalScope.text = $translate.instant('desktopIsNeeded.text')
                return PageSettings.openAlertModal(modalScope).finally(() => modalScope.$destroy())
            } else {
                return callback()
            }
        }

        this.checkPermission = (tmpl, permission) => {
            return tmpl.permissions ? tmpl.permissions.indexOf(permission) !== -1 : null
        }

        $scope.$on('templates:refresh', () => {
            this.firstPage()
        })

        $scope.$on('content:search', (event, searchString) => {
            this.searchString = searchString
            this.firstPage()
        })

        this.url = $stateParams.url || '/processes'
        this.firstPage()

        this.activeTemplateId = null
        this.setActiveTemplateId = (id, e) => {
            if (e) {
                e.stopPropagation()
            }
            this.activeTemplateId = id
        }
    }

    getTemplates () {
        return this.templates
    }

    prepareTemplate (tpl) {
        tpl.tasksCountText = angular.toJson({ COUNT: tpl.tasksCount || 0 })
        return tpl
    }

    prepareTemplates (list) {
        return list.map(this.prepareTemplate)
    }

    getApiParams () {
        const params = {
            count: this.PageSettings.itemsPerPage * 2
        }

        if (this.searchString) {
            params.searchString = this.searchString
        }

        if (this.nextOffsetId && this.nextOffsetId !== -1) {
            params.nextOffset = this.nextOffsetId
        }

        return params
    }

    triggerListChange () {
        this.$timeout(() => this.$scope.$emit(`list:changed`), 100)
    }

    toggleDropdownFlag (template, state = false) {
        this.$timeout(() => template.dropdownIsOpened = state)
    }

    nextPage () {
        if (this.nextOffsetId === -1) {
            return
        }

        const params = this.getApiParams()

        this.busyLoading = true
        this.showTotal = false
        this.ApiCalls.getTemplates(params)
            .then(data => {
                this.nextOffsetId = data.nextOffset || -1
                if (data.list && data.list.length) {
                    utils.mergeToArray(this.templates, this.prepareTemplates(data.list), true)
                    this.triggerListChange()
                    this.serverError = false
                }
            }, () => this.serverError = true)
            .finally(() => {
                this.$timeout(() => this.busyLoading = false)
                this.loaded = true
                this.showTotal = this.templates.length > 20
            })
    }

    firstPage () {
        this.loaded = false
        this.templates = []
        this.nextOffsetId = 0
        this.nextPage()
    }

    emptyStateVisible () {
        return !this.serverError && !this.getTemplates().length && this.loaded && !this.searchString
    }

    notFoundStateVisible () {
        return !this.serverError && !this.getTemplates().length && this.loaded && this.searchString
    }

    getTemplatesTotalParams = () => {
        return { TOTAL: this.templates.length }
    }
}
