export function tmplTaskDueDate($translate) {
    'ngInject';
    const calculationsT = [
        {id: null, item: $translate.instant('label.notSet')},
        {id: 'AFTER_PROCESS_START', item: $translate.instant('text.AFTER_PROCESS_START')},
        {id: 'AFTER_PREVIOUS_TASK_COMPLETE', item: $translate.instant('text.AFTER_PREVIOUS_TASK_COMPLETE')},
        {id: 'BEFORE_PROCESS_DEADLINE', item: $translate.instant('text.BEFORE_PROCESS_DEADLINE')}
    ];
    const unitsT = [
        {id: 'BUSINESS_DAY', item: $translate.instant('label.businessDays')},
        {id: 'DAY', item: $translate.instant('label.calendarDays')},
        {id: 'WEEK', item: $translate.instant('label.weeks')},
        {id: 'MONTH', item: $translate.instant('label.months')}
    ];
    const additionalDateFieldsPresets = [
        {id: 'FIELD_DATE_BEFORE', item: $translate.instant('text.FIELD_DATE_BEFORE')},
        {id: 'FIELD_DATE_AFTER', item: $translate.instant('text.FIELD_DATE_AFTER')}
    ]

    return {
        restrict: 'E',
        replace: true,
        scope: {
            interval: '=?',
            task: '=?',
            isClicked: '=?',
            dateFields: '=?'
        },
        template: `
            <div class="tmpl-task-due-date">
                <tether-drop class="tmpl-calculations-drop" ng-model="interval.calculation" values="calculations"
                    ng-change="updateCalculation(interval.calculation)"></tether-drop>
                <div class="form-group" ng-class="{'has-error': checkError()}">
                    <input type="tel" pattern="[0-9]{1,8}" ng-model="interval.interval" id="task-interval-input"
                           ng-disabled="interval.calculation == null" ng-change="isEdited = true"/>
                    <tether-drop class="tmpl-units-drop" ng-model="interval.units" values="units"
                        is-disabled="interval.calculation == null"
                        ng-change="updateUnits(interval.units)"></tether-drop>
                </div>
                <div class="error" ng-if="checkError()">
                    {{task ? 'validation.task-fieldDueDate' : 'validation.processDeadline-fieldDueDate' | translate}}
                </div>
            </div>
        `,

        link: (scope) => {
            scope.units = unitsT
            const initCalculations = () => {
                scope.calculations = scope.task
                    ? calculationsT.slice()
                    : calculationsT.slice(0, 2)

                if (scope.dateFields && scope.dateFields.length) {
                    const [before, after] = additionalDateFieldsPresets
                    scope.dateFields.forEach(field => {
                        const linkedField = {
                            id: field.id,
                            name: field.item
                        }
                        const beforeDateField = {
                            id: field.id + '_' + before.id,
                            item: `${before.item} "${field.item}"`,
                            label: `${before.item.toLowerCase()} "${field.item}"`,
                            linkedField
                        }
                        const afterDateField = {
                            id: field.id + '_' + after.id,
                            item: `${after.item} "${field.item}"`,
                            label: `${after.item.toLowerCase()} "${field.item}"`,
                            linkedField
                        }

                        if (!scope.calculations.find(c => c.id === beforeDateField.id)) {
                            scope.calculations.push(beforeDateField)
                        }

                        if (!scope.calculations.find(c => c.id === afterDateField.id)) {
                            scope.calculations.push(afterDateField)
                        }
                    })
                }
            }
            initCalculations()

            scope.updateCalculation = calc => {
                scope.interval.calculation = calc
                scope.interval.linkedField = undefined
                scope.interval.label = undefined

                if (calc) {
                    if (scope.interval && !scope.interval.units) {
                        scope.interval.units = 'BUSINESS_DAY'
                    }
                    const dueDateCalc = scope.calculations.find(({id, linkedField}) => id === calc && linkedField)
                    if (dueDateCalc) {
                        scope.interval.linkedField = dueDateCalc.linkedField
                        scope.interval.label = dueDateCalc.label
                    }
                } else if (scope.interval) {
                    scope.interval = null
                    scope.isEdited = false
                }
            }

            scope.updateUnits = unitV => {
                scope.interval.units = unitV
            }

            scope.checkError = () => {
                return scope.interval
                    && scope.interval.calculation
                    && scope.interval.interval == null
                    && (scope.isClicked || scope.isEdited)
            }

            scope.$watch(() => scope.interval, (newVal, oldVal) => {
                if (newVal == null) {
                    return;
                } else if (newVal.interval === '') {
                    return newVal.interval = null;
                }
                if (angular.isUndefined(newVal.interval) && oldVal != null) {
                    scope.interval.interval = oldVal.interval;
                    return;
                }
                scope.interval.interval = newVal.interval != null ? Number(newVal.interval.toString()) : null;
            }, true)

            scope.$watch('task', () => {
                scope.isEdited = false
                initCalculations()
            })

            scope.$watch('dateFields', () => {
                initCalculations()
            })
        }
    };
}
