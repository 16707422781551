import config from '../config'
import utils from '../utils'
import moment from 'moment-timezone'

export function formatForUserflowIdentify (user) {
    const unixTimeToSting = (ts) => new Date(ts * 1000).toISOString().split('.')[0] + 'Z'

    return {
        name: user.fullName,
        email: user.email,
        signedUpAt: unixTimeToSting(user.creationDate),
        traits: {
            user_role: user.isAdmin ? 'admin' : 'user',
            company_creation_date: unixTimeToSting(user.companyCreationDate),
            company_billing_plan: user.companyBillingPlan,
            company_status: user.companyStatus
        }
    }
}

export function CurrentUserService ($http, $window, $auth, currentUser, PusherHelper, TemplateCacheHelper, $translate) {
    'ngInject'
    const updateUser = (user, isToken, initPusher = true, disabledMode = false) => {
        TemplateCacheHelper.clearAll()
        user.fullName = (user.firstName || 'Loading...') + ' ' + (user.lastName || '')
        angular.copy(user, currentUser)
        if (!isToken && currentUser.token) {
            $auth.setToken(currentUser.token)
        }
        $translate.use(currentUser.lang)
        if ($window.analytics) {
            $window.analytics.identify(user.id, {
                name: user.fullName,
                email: user.email,
                companyName: user.companyName,
                userRole: user.isAdmin ? 'Admin' : 'User',
                domainUrl: $window.location.protocol + '//' + $window.location.host,
                company: {
                    id: user.companyId
                }
            }, {
                Intercom: {
                    user_hash: user.userHash
                }
            })
        }

        if (!disabledMode) {
            const authTokenHeaders = { headers: { 'X-Auth-Token': user.token } }
            $http.get('/api/templates/check_list', authTokenHeaders).then(response => {
                $window.templatesNotAvailable = response.data.result.exists === false
            }).catch(() => {
                $window.templatesNotAvailable = false
            })

            $http.get('/api/datatables/check_list', authTokenHeaders).then(response => {
                $window.datatablesNotAvailable = response.data.result.exists === false
            }).catch(() => {
                $window.datatablesNotAvailable = false
            })
        }

        if ($window.userflow) {
            $window.userflow.identify(user.id, formatForUserflowIdentify(user))
        }

        if (PusherHelper.getSocket()) {
            PusherHelper.deleteSocket()
        }
        if (initPusher) {
            PusherHelper.initSocket(currentUser)
        }
        return user
    }

    const companyHasPaymentIssue = () => {
        if (currentUser.companyPaymentIssuesStartDate !== undefined) {
            return currentUser.isAdmin || moment.utc().diff(moment.unix(currentUser.companyPaymentIssuesStartDate).utc(), 'days', true) > 7
        }
        return false
    }

    return {
        reload: (token, disabledMode = false) => {
            const apiPath = disabledMode ? 'disabled/current' : 'current'
            const withPusher = !disabledMode

            return $http({
                method: 'GET',
                url: `${config.api}/${apiPath}`,
                data: {},
                headers: {
                    'X-Auth-Token': token || utils.localStorage.get('satellizer_token')
                }
            }).then(resp => {
                if (resp.data) {
                    updateUser(resp.data.result, true, withPusher, disabledMode)
                }
                return resp
            })
        },
        updateUser,
        companyHasPaymentIssue
    }
}
