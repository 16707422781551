import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import { Actors } from '../../models/actors'
import { createColumnForm, createRecordForm } from '../../models/record'

import Header from './header.jsx'
import RecordsGrid from '../records/grid.jsx'
import RecordForm from '../records/form.jsx'
import ColumnForm from '../columns/form.jsx'
import PermissionsDialog from './permissions-dialog.jsx'
import TypedConfirmation from '../../../../components/react/typed-confirmation.jsx'
import Confirmation from '../../../../components/react/confirmation.jsx'
import SearchField from '../../../../components/react/search-field.jsx'

import '../../less/datatable.less'
import { getService } from '../../../react/utils'
import { translate } from '../../../react/utils'

class TableView extends React.Component {
    static propTypes = {
        table: PropTypes.object,
        action: PropTypes.object,
        onDelete: PropTypes.func.isRequired,
        onTableLoadError: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            displayPermissions: false,
            displayDeleteConfirmation: false,
            displayDeleteColumnConfirmation: false,
            displayDeleteRecordConfirmation: false,
            deletingColumnId: undefined,
            selectedRecord: undefined,
            selectedColumn: undefined,
            searchString: ''
        }

        props.table.getInfo(this.props.onTableLoadError).then(({ error }) => {
            if (!error) {
                props.table.getRecords().then(() => {
                    this.runAction(this.props.action)
                })
            }
        })
        this.actors = Actors.create()
    }

    setSearchString = (searchString) => {
        this.setState({ searchString })
    }

    render () {
        const {
            displayPermissions,
            displayDeleteConfirmation,
            displayDeleteColumnConfirmation,
            displayDeleteRecordConfirmation,
            selectedRecord,
            selectedColumn
        } = this.state
        const { table } = this.props

        return (
            <div className="datatable">
                <Header
                    table={table}
                    addRecordAction={this.onSelectRecord}
                    openPermissions={this.openPermissions}
                    deleteTable={this.openDeleteConfirmation}
                />

                <div className="datatable-actions">
                    <SearchField value={table.searchString} onChange={table.setSearchString}/>
                </div>

                <div className="datatable-grid">
                    {(table.columnsOrdered && !table.noSearchResults && !table.dataIsLoading) && (
                        <RecordsGrid
                            model={table}
                            onSelectRecord={this.onSelectRecord}
                            onEditColumn={this.editColumn}
                            onCreateColumn={table.isEditable ? this.createColumn : undefined}
                            onDeleteColumn={this.openDeleteColumnConfirmation}
                        />
                    )}
                    {table.dataIsLoading && (
                        <div className="data-states">
                            <div className="data-state">
                                <i className="icon-task_field_save_ring data-state__icon"/>
                                <h3 className="data-state__header">{translate('label.loading')}</h3>
                            </div>
                        </div>
                    )}
                    {table.noSearchResults && (
                        <div className="data-states">
                            <div className="data-state">
                                <i className="icon-empty_search data-state__icon"/>
                                <h3 className="data-state__header">{translate('text.database.noRecords.header')}</h3>
                                <p className="data-state__summary">{translate('text.database.noRecords.summary')}</p>
                            </div>
                        </div>
                    )}
                </div>

                <PermissionsDialog
                    table={table}
                    actors={this.actors}
                    isOpen={displayPermissions}
                    onClose={this.closePermissions}
                />

                <RecordForm
                    record={selectedRecord}
                    table={table}
                    onCancel={this.closeRecordForm}
                    onSelectRecord={this.editRecord}
                    onDeleteRecord={this.openDeleteRecordConfirmation}
                />

                <ColumnForm
                    column={selectedColumn}
                    onCancel={this.closeColumnForm}
                />

                <TypedConfirmation
                    type={'table'}
                    isOpen={displayDeleteConfirmation}
                    onConfirm={this.deleteTable}
                    onCancel={this.closeDeleteConfirmation}
                />

                <Confirmation
                    title={'text.database.deleteColumn.title'}
                    text={'text.database.deleteColumn.text'}
                    confirmButtonText={'label.delete'}
                    isOpen={displayDeleteColumnConfirmation}
                    onConfirm={this.deleteColumn}
                    onCancel={this.closeDeleteColumnConfirmation}
                />

                <Confirmation
                    title={'text.database.deleteRecord.title'}
                    text={'text.database.deleteRecord.text'}
                    confirmButtonText={'label.delete'}
                    isOpen={displayDeleteRecordConfirmation}
                    onConfirm={this.deleteRecord}
                    onCancel={this.closeDeleteRecordConfirmation}
                />
            </div>
        )
    }

    componentDidUpdate (prevProps) {
        const { action } = this.props
        if (action?.id !== prevProps.action?.id) {
            this.runAction(action)
        }
    }

    runAction = (action) => {
        if (action.id === 'NEW_RECORD') {
            this.addRecord()
        }
        if (action.id === 'EDIT_RECORD') {
            const record = this.props.table.records.get(action.params.recordId)
            this.editRecord(record)
        }
    }

    openPermissions = () => {
        this.setState({ displayPermissions: true })
    }

    closePermissions = () => {
        this.setState({ displayPermissions: false })
    }

    openDeleteConfirmation = () => {
        this.setState({ displayDeleteConfirmation: true })
    }

    closeDeleteConfirmation = () => {
        this.setState({ displayDeleteConfirmation: false })
    }

    deleteTable = () => {
        this.closeDeleteConfirmation()
        this.props.onDelete(this.props.table)
    }

    editColumn = (index) => {
        const { table } = this.props
        const selectedColumn = createColumnForm(table.columnsOrdered[index], table)
        this.setState({ selectedColumn })
    }

    createColumn = () => {
        const selectedColumn = createColumnForm(undefined, this.props.table)
        this.setState({ selectedColumn })
    }

    closeColumnForm = () => {
        this.setState({ selectedColumn: undefined })
    }

    openDeleteColumnConfirmation = (columnId) => {
        this.setState({
            displayDeleteColumnConfirmation: true,
            deletingColumnId: columnId
        })
    }

    closeDeleteColumnConfirmation = () => {
        this.setState({
            displayDeleteColumnConfirmation: false,
            deletingColumnId: undefined
        })
    }

    deleteColumn = () => {
        const { deletingColumnId } = this.state
        this.props.table.deleteColumn(deletingColumnId)
        this.closeDeleteColumnConfirmation()
    }

    onSelectRecord = (record) => {
        if (record) {
            getService('$state').go('main.database.table.editRecord', { recordId: record.id })
        } else {
            getService('$state').go('main.database.table.newRecord')
        }
    }

    addRecord = () => {
        this.setState({ selectedRecord: createRecordForm(undefined, this.props.table) })
    }

    editRecord = (record) => {
        this.setState({ selectedRecord: createRecordForm(record.toJSON(), this.props.table) })
    }

    closeRecordForm = () => {
        this.setState({ selectedRecord: undefined })
        getService('$state').go('main.database.table', { tableId: this.props.table.id })
    }

    openDeleteRecordConfirmation = () => {
        this.setState({ displayDeleteRecordConfirmation: true })
    }

    closeDeleteRecordConfirmation = () => {
        this.setState({ displayDeleteRecordConfirmation: false })
    }

    deleteRecord = () => {
        const { selectedRecord } = this.state
        this.closeDeleteRecordConfirmation()
        this.closeRecordForm()
        this.props.table.deleteRecord(selectedRecord.id)
    }
}

export default observer(TableView)
