import config from '../config';

export function allowedValues($sce) {
    'ngInject';

    return (text, perLineSize) => {
        if (!text) {
            return text;
        }
        let strTrim = (str) => {
            return str.replace(/\&nbsp;/g, ' ').trim().toLowerCase();
        };
        text = text.replace(new RegExp('<', 'g'), '&lt;')
                   .replace(new RegExp('>', 'g'), '&gt;')
                   .replace(/&/g, '&amp;')
                   .replace(/ /g, '&nbsp;');
        let lines = text.split('\n');
        let maxLength = perLineSize || 30;

        let updateLines = (linesT, str) => {
            linesT.forEach((l, index) => {
                if (strTrim(l) === strTrim(str)) {
                    linesT[index] = `<span class="invalid">${l}</span>`;
                }
            });
        };

        lines.forEach(l => {
            if (lines.filter(i => strTrim(l) === strTrim(i)).length > 1) {
                updateLines(lines, l);
            }
            if (!l) {
                updateLines(lines, l);
            } else if (l.replace(new RegExp('&lt;', 'g'), '<')
                        .replace(new RegExp('&gt;', 'g'), '>')
                        .replace(new RegExp('&nbsp;', 'g'), ' ')
                        .replace(new RegExp('&amp;', 'g'), '&').length > maxLength
                    || !l.match(config.pattern)) {
                updateLines(lines, l);
            }
        });

        return $sce.trustAsHtml(lines.join('\n'));
    };
}
