export class NotFoundController {
    constructor($stateParams, $state) {
        'ngInject';
        this.screen = $stateParams.screen;
        this.from = $stateParams.from;
        this.goToList = () => {
            switch(this.screen) {
                case 'process':
                    $state.go('main.processes.my');
                    break;
                case 'template':
                    // $state.go('main.templates.index');
                    $state.go('main.templates');
                    break;
                case 'task':
                default:
                    $state.go(this.from === 'process' ? 'main.processes.my' : 'main.home');
            }
        };
    }
}
