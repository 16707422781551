import { connect } from '../store/connect'
import ReportView from '../components/report-view.jsx'
import {
    exportReport,
    fetchReportData,
    fetchReportDataSize,
    receiveReportData, selectRow,
    updateColumns
} from '../store/actions'
import { getCurrentReport } from '../store/selectors'
import { prepareColumns } from '../store/utils'

const mapStateToProps = (state) => {
    const report = getCurrentReport(state)
    // Add getters to columns. Getters cannot be placed to store.
    const columns = report ? prepareColumns(report) : {}
    const { selectedRowIndex } = state

    return {
        report: { ...report, columns },
        selectedRowIndex
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onColumnsChange: (reportId, columns) => {
            const state = {}
            Object.keys(columns).forEach(key => {
                state[key] = {
                    width: columns[key].width,
                    flexGrow: columns[key].flexGrow
                }
            })
            dispatch(updateColumns({ reportId, columns: state }))
        },
        requestReportDataSizeAction: () => {
            dispatch(fetchReportDataSize())
        },
        requestReportDataAction: (nextOffset, callback) => {
            dispatch(fetchReportData(nextOffset, callback))
        },
        receiveReportDataAction: (data) => {
            dispatch(receiveReportData(data))
        },
        exportReportAction: (format) => {
            dispatch(exportReport(format))
        },
        selectRowAction: (rowIndex) => {
            dispatch(selectRow(rowIndex))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportView)
