import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import TemplateScheduleForm from './template-schedule-form'
import ModalDialog from '../dialog/modal'

import { translate } from '../../../modules/react/utils'

class TemplateScheduleDialog extends React.Component {
    static propTypes = {
        template: PropTypes.object,
        onChange: PropTypes.func,
        onClose: PropTypes.func
    }

    render () {
        const { template, onClose, onChange, ...props } = this.props

        return (
            <ModalDialog
                title={this.renderTitle()}
                className="template-schedule-modal"
                isOpen={!!template}
                onCancel={onClose}
            >
                {
                    template
                        ? <TemplateScheduleForm template={template} onCancel={onClose} onChange={onChange} />
                        : null
                }
            </ModalDialog>
        )
    }

    renderTitle = () => {
        const { template } = this.props

        if (!template) {
            return ''
        }

        const avatar = template.icon
            ? (<span className="avatar" style={{ background: template.icon.color }}><i
                className={cn('icon', template.icon.id)}/></span>)
            : (<span className="avatar default"><i className="icon-File-Settings"/></span>)

        const label = template.schedule?.id
            ? translate('schedule.label.updateScheduleFor')
            : translate('schedule.label.createScheduleFor')

        return <div className="template-schedule-header">{label} {avatar} {template.name}</div>
    }
}

export default TemplateScheduleDialog
