import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { observer } from 'mobx-react'

import ModalDialog from '../../../../components/react/dialog/modal.jsx'
import ActionsDropdown from '../../../../components/react/dropdown/actions-dropdown.jsx'
import FormField from '../../../../components/react/data-form/form-field.jsx'
import { translate } from '../../../react/utils'

import '../../less/record-form.less'

class RecordForm extends React.Component {
    static propTypes = {
        record: PropTypes.object,
        table: PropTypes.object,
        onCancel: PropTypes.func.isRequired,
        onDeleteRecord: PropTypes.func.isRequired
    }

    componentDidUpdate (prevProps) {
        const oldRecordId = prevProps.record ? prevProps.record.id : undefined
        const newRecordId = this.props.record ? this.props.record.id : undefined
        if (newRecordId && newRecordId !== oldRecordId) {
            this.props.record.getRecordData()
        }
    }

    render () {
        const { table, record } = this.props
        const isOpen = record !== undefined || this.actionIsProcessing()

        return (
            <ModalDialog
                isOpen={isOpen}
                title={this.renderTitle()}
                onCancel={this.onCancel}
                className={'record-form'}
            >
                <div>
                    {isOpen && record.columns.map((column, index) => (
                        <FormField
                            key={column.id}
                            label={column.name}
                            value={column.value}
                            dataType={column.dataType}
                            settings={column.settings}
                            error={column.error}
                            required={column.isTitle}
                            autoFocus={column.isTitle}
                            onChange={(value) => this.onColumnChange(value, index)}
                            displayValue={column.value.displayValue}
                            disabled={!table.isRecordEditable}
                        />
                    ))}
                </div>
            </ModalDialog>
        )
    }

    renderTitle () {
        const { table, record } = this.props

        return (
            <div className="record-form-header">
                <div className="title">
                    <small>{table.name}</small>
                    <h3>{this.getRecordTitle()}</h3>
                </div>
                {record && record.id !== undefined && table.isRecordEditable && (
                    <div className="actions">
                        <ActionsDropdown
                            // disabled={!utils.checkPermission(task, 'delete')}
                            // onOpen={() => this.setState({inFocus: true})}
                            // onClose={() => this.setState({inFocus: false})}
                        >
                            <ul className="actions-list">
                                <li className="actions-list-item" onClick={this.deleteAction}>
                                    <i className="icon-delete_roundless"/> <span>{translate('label.delete')}</span>
                                </li>
                            </ul>
                        </ActionsDropdown>
                    </div>
                )}
            </div>
        )
    }

    getRecordTitle = () => {
        const { record } = this.props
        const title = record && record.id ? record.title : translate('label.addRecord')
        return (
            <span className={cn({ 'no-value': record && record.id && record.titleIsEmpty })}>{title}</span>
        )
    }

    getValue = (columnIndex) => {
        const { record } = this.props
        return record.columnsOrdered[columnIndex].value
    }

    onColumnChange = (value, columnIndex) => {
        const { record } = this.props
        record.updateColumnValue(columnIndex, value)
    }

    actionIsProcessing = () => {
        const { record } = this.props
        return record && record.columns.find(column => column.value.isSaving || column.value.isUploading) !== undefined
    }

    onCancel = () => {
        if (!this.actionIsProcessing()) {
            clearTimeout(this.closeInterval)
            this.props.onCancel()
        } else {
            this.closeInterval = setTimeout(() => {
                this.onCancel()
            }, 100)
        }
    }

    deleteAction = () => {
        this.props.onDeleteRecord()
    }
}

export default observer(RecordForm)
