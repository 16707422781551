import { ProcessesController } from './processes.controller'

import { MyProcessesController } from './controllers/my-processes.controller'
import { AllProcessesController } from './controllers/all-processes.controller'
import { TemplatesStartController } from './controllers/templates-start.controller'
import { TemplateController } from './controllers/template.controller'
import { TemplateEditController } from './controllers/template-edit.controller'
import { ProcessStartController } from './controllers/process-start.controller'
import { ProcessController } from './controllers/process.controller'
import { NotFoundController } from './controllers/not-found.controller'
import { TaskController } from './controllers/task.controller'
import { StartProcessModalController } from './controllers/start-process.modal.controller'
import { CopyTemplateModalController } from './controllers/copy-template.modal.controller'

import { actorsField } from './directives/actors-field'
import { iconsSelect } from './directives/icons-select'
import { selectActivityMode } from './directives/select-activity-mode'
import { selectFieldType } from './directives/select-field-type'
import { selectFieldEdition } from './directives/select-field-edition'
import { selectPrFilterMode } from './directives/select-filter-mode'
import { completeOnBehalf } from './directives/complete-on-behalf'
import { taskReviewActions } from './directives/task-review-actions'
import { selectProcessesGroup } from './directives/select-processes-group'
import { reactProcessesList } from './directives/react-processes-list'
import { processComments } from './directives/process-comments'
import { processCommentMention } from './directives/process-comment-mention'
import { processDatePicker } from './directives/process-date-picker'
import { processTaskListDueDate } from './directives/process-task-list-due-date'
import { taskFormAssignee } from './directives/task-form-assignee'
import { titleEditable } from './directives/title-editable'

import { ProcessesSettings } from './services/processes-settings'

import './less/index.less'

let ngModule = angular.module('processes', [])
    .controller('ProcessesController', ProcessesController)
    .controller('TemplatesStartController', TemplatesStartController)
    .controller('TemplateController', TemplateController)
    .controller('TemplateEditController', TemplateEditController)
    .controller('ProcessStartController', ProcessStartController)
    .controller('ProcessController', ProcessController)
    .controller('NotFoundController', NotFoundController)
    .controller('TaskController', TaskController)
    .controller('MyProcessesController', MyProcessesController)
    .controller('AllProcessesController', AllProcessesController)
    .controller('StartProcessModalController', StartProcessModalController)
    .controller('CopyTemplateModalController', CopyTemplateModalController)

    .directive('actorsField', actorsField)
    .directive('iconsSelect', iconsSelect)
    .directive('selectActivityMode', selectActivityMode)
    .directive('selectFieldType', selectFieldType)
    .directive('selectFieldEdition', selectFieldEdition)
    .directive('selectPrFilterMode', selectPrFilterMode)
    .directive('completeOnBehalf', completeOnBehalf)
    .directive('taskReviewActions', taskReviewActions)
    .directive('selectProcessesGroup', selectProcessesGroup)
    .directive('reactProcessesList', reactProcessesList)
    .directive('processComments', processComments)
    .directive('processCommentMention', processCommentMention)
    .directive('processDatePicker', processDatePicker)
    .directive('processTaskListDueDate', processTaskListDueDate)
    .directive('taskFormAssignee', taskFormAssignee)
    .directive('titleEditable', titleEditable)

    .factory('ProcessesSettings', ProcessesSettings)

export default ngModule
