import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import colors, { tmplColors } from '../../libs/colors.js'
import TimerFromNow from './timer-from-now.jsx'
import ProcessAssigneesList from './process-assignees-list.jsx'
import ProcessTaskBar from './process-task-bar.jsx'
import devicesDetector from './device-detector.js'
import utils, { translate } from './utils.js'

class ReactProcessesList extends React.Component {
    static propTypes = {
        tree: PropTypes.arrayOf(Object),
        goTo: PropTypes.object,
        config: PropTypes.object,
        payload: PropTypes.object
    }

    render () {
        const { tree, goTo, config, payload } = this.props
        if (!tree) {
            return null
        }
        let nodes = tree.map((node, index) => {
            const goToTemplate = () => {  //currently is not used for processes view
                if (!utils.checkPermission(node.template, 'view')) {
                    return
                }
                if (!devicesDetector.isMobile() || window.innerWidth > 1023) {
                    goTo.template(node.template)
                }
            }
            let header = null
            if (node.template) {
                if (node.template.id) {
                    let avatar = null
                    let hStyle = null
                    if (node.template.icon) {
                        const iconClasses = cn({ icon: true }, node.template.icon.id)
                        const avatarStyle = { background: node.template.icon.color }
                        hStyle = { backgroundColor: tmplColors[node.template.icon.color] }
                        avatar = (
                            <div className="avatar-small" style={avatarStyle}>
                                <i className={iconClasses}/>
                            </div>
                        )
                    } else {
                        avatar = (
                            <div className="avatar-small default">
                                <i className="icon-File-Settings"/>
                            </div>
                        )
                    }
                    header = (
                        <header className="processes-header bg-gray" style={hStyle}>
                            {avatar}
                            <span>{node.template.name}</span>
                            {!!node.template.processesRunningCount && (
                                <span className="processes-count">
                                    {translate('plural.processRunning', { COUNT: node.template.processesRunningCount }, true)}
                                </span>
                            )}
                        </header>
                    )
                } else if (node.processes && node.processes.length) {
                    header = (
                        <header className="processes-header bg-gray">
                            <span>{translate('tasks-group.noTemplate')}</span>
                        </header>
                    )
                }
            }
            let content = node.processes.map((process, processIndex) => {
                const goToProcess = () => goTo.process(process)
                if (process.name) {
                    let style = { background: colors[process.starter.colorIndex - 1] }
                    let assigneesClassNames = cn({
                        processes__main__content__process__assignees: true,
                        completed: process.completedDate
                    })
                    let completedSpanClassNames = cn({
                        red: process.isRejected,
                        yellow: process.isStopped,
                        green: process.completedDate && !process.isRejected && !process.isStopped
                    })
                    const completedStatusText = translate(process.isRejected ? 'process.status.rejected' : process.isStopped ? 'process.status.stopped' : 'process.status.completed')
                    let detailsContent = null
                    if (process.completedDate) {
                        detailsContent = (
                            <p>
                                <span className={completedSpanClassNames}>{completedStatusText}</span>
                                {' '}
                                <TimerFromNow start={process.completedDate} />
                            </p>
                        )
                    } else if (process.tasks && process.tasks.length) {
                        detailsContent = (
                            <div>
                                <ul className="tasks">
                                    {process.tasks.map((task, tIndex) => {
                                        const goToTask = (event) => {
                                            if (utils.checkPermission(task, 'view')) {
                                                goTo.taskView(task, event)
                                            }
                                        }
                                        return (
                                            <ProcessTaskBar
                                                task={task}
                                                process={process}
                                                key={tIndex}
                                                config={config}
                                                payload={payload}
                                                link={goToTask}
                                            />
                                        )
                                    })}
                                </ul>
                                <span>{translate('plural.tasks', { COUNT: process.tasksCount }, true)}</span>
                            </div>
                        )
                    }
                    return (
                        <div className="processes__main__content__process" key={process.id} onClick={goToProcess}>
                            <div className="processes__main__content__process__details">
                                <div className="processes__main__content__process__details__process_avatar">
                                    {process.starter.avatarUrl && (
                                        <img className="avatar" src={process.starter.avatarUrl + '_40'} height="40"
                                             width="40"/>
                                    )}
                                    {!process.starter.avatarUrl &&
                                    <div className="avatar"
                                         style={style}>{utils.getInitials(process.starter.fullName)}</div>
                                    }
                                    {(process.completedDate && !process.isRejected && !process.isStopped) &&
                                    <div className="avatar-completed">
                                        <i className="icon-menu_checkmark"></i>
                                    </div>
                                    }
                                    {process.isRejected &&
                                    <div className="avatar-reject">
                                        <i className="icon-common_close"></i>
                                    </div>
                                    }
                                    {process.isStopped &&
                                    <div className="avatar-stopped">
                                        <i className="icon-stop"></i>
                                    </div>
                                    }
                                </div>
                                <div className="details-wrapper">
                                    <h4>
                                        <a>{process.name}</a>
                                    </h4>
                                    {detailsContent}
                                </div>
                            </div>
                            <div className={assigneesClassNames}>
                                <div className="status">
                                    {!process.completedDate && (
                                        <div>
                                            <TimerFromNow start={process.startedDate} isStarted={true}/>
                                            {process.dueDate && (
                                                <span className="dueDate">
                                                    {process.isPastDue && <span
                                                        className="red">{translate('label.pastDue')} {process.formattedDueDate}</span>}
                                                    {!process.isPastDue && <span
                                                        className={process.isWarnDue ? 'red' : ''}>{process.warnFormattedDueDate}</span>}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {process.assignees && process.assignees.length && (
                                    <ProcessAssigneesList assignees={process.assignees}/>
                                )}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="processes__main__content__process" key={processIndex}>
                            <div className="processes__main__content__process__loading">
                                <div className="main">
                                    <div className="thumbnail"/>
                                    <div className="text"/>
                                </div>
                                <div className="assignees">
                                    <div className="assignee"/>
                                    <div className="assignee"/>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
            const nodeClassName = node.template && utils.checkPermission(node.template, 'view') ? 'animated' : null
            return (
                <li key={index} className={nodeClassName}>
                    {header}
                    {content}
                </li>
            )
        })
        return <ul>{nodes}</ul>
    }
}

export default ReactProcessesList
