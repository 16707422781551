import config from '../config';
import processesUtils from '../modules/processes/utils';
import {REPORT_TYPE} from '../modules/reports/store/constants';

export function ApiCalls($http, PusherHelper) {
    'ngInject';
    let withSocketId = (url) => {
        const operator = url.includes('?') ? '&' : '?'
        return `${url}${operator}socketId=${PusherHelper.getSocketId()}`
    };

    let getCall = (url, params) => {
        return $http({
            method: 'GET',
            url: config.api + url,
            params: params
        }).then(res => res.data);
    };
    let deleteCall = (url, headers) => {
        return $http({
            method: 'DELETE',
            url: withSocketId(config.api + url),
            data: {},
            headers: headers
        }).then(res => res.data);
    };
    let postCall = (url, params, headers) => {
        return $http({
            method: 'POST',
            url: withSocketId(config.api + url),
            data: params,
            headers: headers
        }).then(res => res.data);
    };
    let putCall = (url, params, query, headers) => {
        return $http({
            method: 'PUT',
            url: withSocketId(config.api + url),
            data: params,
            params: query,
            headers: headers
        }).then(res => res.data);
    };

    return {
        // Tasks requests
        getAvailableTasks: (params) => {
            return getCall('/tasks/my_available', params);
        },
        getMyTasks: (params) => {
            return getCall('/tasks/my_assigned', params);
        },
        getCompletedTasks: (params) => {
            return getCall('/tasks/my_completed', params);
        },
        getDelegatedTasks: (params) => {
            return getCall('/tasks/delegated_active', params);
        },
        getDelegatedCompletedTasks: (params) => {
            return getCall('/tasks/delegated_completed', params);
        },
        assignTask: (task, query) => {
            let url = `/tasks/${task.id}/assign`;
            return putCall(url, {}, query);
        },
        reAssignTask: (tid, user, query) => {
            let url = `/tasks/${tid}/reassign`;
            return putCall(url, user, query);
        },
        assignToOtherTask: (tid, user, query) => {
            let url = `/tasks/${tid}/assign_to_other`;
            return putCall(url, user, query);
        },
        unassignTask: task => {
            let url = `/tasks/${task.id}/return`;
            return putCall(url, {});
        },
        completeTask: (task, query) => {
            let url = `/tasks/${task.id}/complete`;
            return putCall(url, {}, query);
        },
        completeOnBehalfTask: task => {
            let url = `/tasks/${task.id}/complete_on_behalf`;
            return putCall(url, {});
        },
        approveTask: (task, query) => {
            let url = `/tasks/${task.id}/approve`;
            // TODO Workaround for MBPM-2307
            return putCall(url, {}, query);
        },
        rejectTask: (task, isResultProcess) => {
            let {id, rejectReason} = task
            let query = isResultProcess ? '?resultView=process' : '';
            let url = `/tasks/${id}/reject${query}`;
            return putCall(url, {rejectReason});
        },
        uncompleteTask: task => {
            let url = `/tasks/${task.id}/uncomplete`;
            return putCall(url, {});
        },
        modifyTask: (tid, obj) => {
            let url = `/tasks/${tid}/modify`;
            return putCall(url, obj);
        },
        activateTask: (task, query) => {
            // TODO Workaround for MBPM-2307
            return putCall(`/tasks/${task.id}/force_activation`, {}, query);
        },
        reviewTask: (task) => {
            let {id, reviewNote} = task
            let url = `/tasks/${id}/review`;
            return putCall(url, {reviewNote});
        },
        forceApproveTask: (task) => {
            let url = `/tasks/${task.id}/force_approve`;
            return putCall(url);
        },
        forceRejectTask: (task) => {
            let {id, rejectReason} = task
            let url = `/tasks/${id}/force_reject`;
            return putCall(url, {rejectReason});
        },

        // Processes/templates requests
        getAllProcesses: params => getCall('/processes/all_running', params),
        getMyProcesses: params => getCall('/processes/my_running', params),
        getAllCompletedProcesses: params => getCall('/processes/all_completed', params),
        getMyCompletedProcesses: params => getCall('/processes/my_completed', params),

        // Templates
        getTemplates: params => getCall('/templates', params),
        getTemplatesToStart: params => getCall('/templates/to_start', params),
        getTemplate: params => getCall(`/templates/${params.id}`, params),
        createTemplate: template => {
            processesUtils.cleanTemplateTasks(template.subTree, template.execution)
            return postCall('/templates', template)
        },
        updateTemplate: template => {
            processesUtils.cleanTemplateTasks(template.subTree, template.execution)
            let url = `/templates/${template.id}`
            return putCall(url, template)
        },
        copyTemplate: (tmplId, template) => {
            let url = `/templates/${tmplId}/duplicate`;
            return putCall(url, template);
        },
        deleteTemplate: (tmpId, version) => deleteCall(`/templates/${tmpId}`, {'Optimistic-locking-version': version}),
        startProcess: (id, params) => {
            let url = `/templates/${id}/start`;
            return putCall(url, params);
        },
        quickStartProcess: (templateId, params, skipWarnings) => {
            const end = skipWarnings ? '?skipWarnings=1' : '';
            const url = `/templates/${templateId}/quick_start${end}`;
            return putCall(url, params);
        },
        getInfoForStartProcess: (templateId) => {
            const url = `/templates/${templateId}/for_start`;
            return getCall(url);
        },
        getListOfProcessStarters: templateId => {
            const url = `/templates/${templateId}/process_starters`
            return getCall(url)
        },
        getSchedule: templateId => {
            const url = `/schedules/${templateId}`
            return getCall(url)
        },
        createSchedule: (templateId, params) => {
            const url = `/schedules/${templateId}`
            return postCall(url, params)
        },
        updateSchedule: (templateId, scheduleId, params) => {
            const url = `/schedules/${templateId}/${scheduleId}`
            return putCall(url, params)
        },
        deleteSchedule: (templateId, scheduleId) => {
            const url = `/schedules/${templateId}/${scheduleId}`
            return deleteCall(url)
        },
        startProcessFromScratch: (params) => postCall('/processes', params),
        editProcess: (id, params) => {
            let url = `/processes/${id}`;
            return putCall(url, params);
        },
        modifyProcess: (id, params, query) => {
            const url = `/processes/${id}/modify`;
            return putCall(url, params, query);
        },
        getProcess: (id, params) => getCall(`/processes/${id}`, params),
        getProcessHistory: id => {
            return getCall(`/processes/${id}/logs`);
        },
        getProcessAttachments: id => {
            return getCall(`/processes/${id}/attachments`);
        },
        getProcessComments: (id, params) => {
            return getCall(`/processes/${id}/comments`, params);
        },
        getProcessActiveTasks: id => {
            return getCall(`/processes/${id}/tasks_statuses`);
        },
        getProcessSubTree: id => getCall(`/processes/${id}/tree`),
        deleteProcess: (pId, version) => deleteCall(`/processes/${pId}`, {'Optimistic-locking-version': version}),
        updateProcessField: (processId, fieldId, params) => {
            return putCall(`/processes/${processId}/fields/${fieldId}`, params);
        },
        addProcessDataModelFile: (processId, fieldId, data) => {
            return postCall(`/processes/${processId}/fields/${fieldId}/files`, data);
        },
        deleteProcessDataModelFile: (processId, fieldId, fileId) => {
            return deleteCall(`/processes/${processId}/fields/${fieldId}/files/${fileId}`);
        },
        addProcessDataModelTableRow: (processId, sectionId, data) => {
            return postCall(`/processes/${processId}/tables/${sectionId}`, data);
        },
        updateProcessDataModelTableRow: (processId, sectionId, rowId, data) => {
            return putCall(`/processes/${processId}/tables/${sectionId}/rows/${rowId}`, data);
        },
        deleteProcessDataModelTableRow: (processId, sectionId, rowId) => {
            return deleteCall(`/processes/${processId}/tables/${sectionId}/rows/${rowId}`);
        },
        stopProcess: (id, { stoppedReason, ...query }) => {
            const url = `/processes/${id}/stop`;
            return putCall(url, { stoppedReason }, query)
        },
        resumeProcess: (id, query) => {
            const url = `/processes/${id}/resume`;
            return putCall(url, {}, query)
        },

        // Task
        addTask: (task) => postCall('/tasks', task),
        getTask: id => getCall(`/tasks/${id}`),
        getTaskActors: id => getCall(`/tasks/${id}/actors`),
        getTaskActorsForAssign: id => getCall(`/tasks/${id}/actors_for_assign`),
        getTemplateTask: id => getCall(`/template_tasks/${id}`),
        deleteTask: (id, version) => deleteCall(`/tasks/${id}`, {'Optimistic-locking-version': version}),

        // Data model fields
        updateSingleTaskField: (tid, fid, obj) => {
            let url = `/tasks/${tid}/fields/${fid}`;
            return putCall(url, obj);
        },
        addDataModelFile: (tid, fid, obj) => {
            return postCall(`/tasks/${tid}/fields/${fid}/files`, obj);
        },
        deleteDataModelFile: (tid, fid, aid) => deleteCall(`/tasks/${tid}/fields/${fid}/files/${aid}`),

        // Data Model table columns
        addDataModelTableRow: (tid, sid, obj) => {
            return postCall(`/tasks/${tid}/tables/${sid}`, obj);
        },
        deleteDataModelTableRow: (tid, sid, rid) => {
            return deleteCall(`/tasks/${tid}/tables/${sid}/rows/${rid}`);
        },
        updateDataModelTableRow: (tid, sid, rid, obj) => {
            let url = `/tasks/${tid}/tables/${sid}/rows/${rid}`;
            return putCall(url, obj);
        },

        // Task comments
        getTaskHistory: id => getCall(`/tasks/${id}/logs`),
        getTaskAttachments: id => getCall(`/tasks/${id}/attachments`),
        getTaskComments: id => getCall(`/tasks/${id}/comments`),
        getTaskComment: (id, cid) => getCall(`/tasks/${id}/comments/${cid}`),
        addComment: (id, params) => postCall(`/tasks/${id}/comments`, params),
        deleteComment: (id, commentId) => deleteCall(`/tasks/${id}/comments/${commentId}`),
        getFile: fileId => getCall(`/tempfiles/${fileId}`),
        deleteFile: fileId => deleteCall(`/tempfiles/${fileId}`),
        generateFilesUploadPolicy: filesArray => putCall('/tempfiles/policy', filesArray),

        // Actors tree request
        getActors: () => getCall('/actors/tree'),
        getActorsGroups: () => getCall('/actors/groups'),
        getActorsUsers: params => getCall('/actors/users', params),
        getInvitedUser: token => getCall('/invite/' + token),
        getResetData: token => getCall('/reset/' + token),
        getResetDisabledData: token => getCall('/disabled/reset/' + token),

        // User profile changes
        changePassword: params => postCall('/password', params),
        getProfile: () => getCall('/profile'),
        getTimezones: () => getCall('/profile/timezones'),
        getNotifications: () => getCall('/profile/notifications'),
        getDirectManager: () => getCall('/profile/direct_manager'),
        updateProfile: params => {
            let url = '/profile';
            return putCall(url, params);
        },
        deleteProfileAvatar: () => deleteCall('/profile/avatar'),
        updateNotifications: params => putCall('/profile/notifications', params),
        upgradeClear: () => putCall('/profile/upgrade/clear', {}),
        resendVerificationEmail: () => putCall('/confirm/resend', {}),

        // Slack integration
        addToSlack: () => postCall('/profile/integrations/slack'),
        getSlackChannels: () => getCall('/profile/integrations/slack'),
        updateSlackChannel: (id, data) => putCall(`/profile/integrations/slack/${id}`, data),
        removeSlackChannel: id => deleteCall(`/profile/integrations/${id}`),

        // Tmpl gallery
        getGalleryTemplates: () => getCall('/gallery_templates'),
        getGalleryTemplateWarnings: (tid) => getCall(`/gallery_templates/${tid}/warnings`),
        copyMultipleGalleryTemplates: (tmplIds) => postCall('/gallery_templates/copy_multiple', {ids: tmplIds}),
        copyGalleryTemplate: (tmplId) => postCall(`/gallery_templates/${tmplId}/copy`, {}),
        copyGalleryTemplateSkipGroups: (tmplId) => postCall(`/gallery_templates/${tmplId}/copy_skip_groups`, {}),

        // Site helpers
        getConfirm: mailToken => getCall('/confirm/' + mailToken),
        getCurrencies: () => getCall('/currency/all'),

        // Reports
        getTemplatesForReports: () => getCall('/reports/templates_for_selection'),
        getReportDataSize: params => getCall('/reports/total_count', params),
        getReportData: params => {
            const url = params.reportType === REPORT_TYPE.TASK_PERFORMANCE ? 'tasks' : 'processes';
            return getCall(`/reports/${url}`, params);
        },
        exportReport: params => postCall('/exports', params),
        exportReportStatus: id => getCall(`/exports/${id}`),

        // Datatables
        getTables: params => getCall('/datatables', params),

        getTableInfo: tableId => getCall(`/datatables/${tableId}`),
        getTableRecords: (tableId, params) => getCall(`/datatables/${tableId}/records`, params),
        getTableUniqueName: () => getCall(`/datatables/unique_name`),
        createTable: params => postCall('/datatables', params),
        modifyTable: (tableId, params) => putCall(`/datatables/${tableId}/modify`, params),
        deleteTable: tableId => deleteCall(`/datatables/${tableId}`),
        checkTableLockStatus: tableId => getCall(`/datatables/${tableId}/lock`),
        reorderTableColumns: (tableId, params) => putCall(`/datatables/${tableId}/reorder`, params),

        createTableColumn: (tableId, params, version) => postCall(
            `/datatables/${tableId}/columns`,
            params,
            {
                'Optimistic-locking-version': version
            }
        ),
        modifyTableColumn: (tableId, columnId, params, version) => putCall(
            `/datatables/${tableId}/columns/${columnId}`,
            params,
            undefined,
            {
                'Optimistic-locking-version': version
            }
        ),
        deleteTableColumn: (tableId, columnId, version) => deleteCall(
            `/datatables/${tableId}/columns/${columnId}`,
            {
                'Optimistic-locking-version': version
            }
        ),

        getTableRecord: (tableId, recordId) => getCall(`/datatables/${tableId}/records/${recordId}`),
        createTableRecord: (tableId, params) => postCall(`/datatables/${tableId}/record`, params),
        createTableRecords: (tableId, params) => postCall(`/datatables/${tableId}/title_records`, params),
        modifyTableRecord: (tableId, columnId, recordId, params) => putCall(
            `/datatables/${tableId}/columns/${columnId}/records/${recordId}`,
            params
        ),
        deleteTableRecord: (tableId, recordId) => deleteCall(`/datatables/${tableId}/records/${recordId}`),

        addDatabaseRecordFiles: (tableId, columnId, recordId, params) => postCall(`/datatables/${tableId}/columns/${columnId}/records/${recordId}/files`, params),
        deleteDatabaseRecordFile: (tableId, columnId, recordId, fileId) => deleteCall(`/datatables/${tableId}/columns/${columnId}/records/${recordId}/files/${fileId}`),

        getSourceTables: params => getCall('/datatables_in_app', params),
        getSourceTableColumns: (tableId, params) => getCall(`/datatables_in_app/${tableId}/display_fields`, params),
        getSourceTableRecords: (tableId, params) => getCall(`/datatables_in_app/${tableId}/records`, params),
        getSourceTableRecordsForTemplate: (tableId, templateId, fieldId, params) => getCall(`/datatables_in_app/${tableId}/records_for_template/${templateId}/${fieldId}`, params),
        getSourceTableRecordsForProcess: (tableId, processId, fieldId, params) => getCall(`/datatables_in_app/${tableId}/records_for_process/${processId}/${fieldId}`, params),
        getSourceTableRecordsForTask: (tableId, taskId, fieldId, params) => getCall(`/datatables_in_app/${tableId}/records_for_task/${taskId}/${fieldId}`, params)
    };
}
