import React from 'react'
import PropTypes from 'prop-types'
import { BUTTONS } from '../../../services/constants'
import moment from 'moment'
import { DEFAULT_TIME } from '../../../modules/reports/store/constants'
import { getService } from '../../../modules/react/utils'

const TIME_FORMATS = ['hh:mm', 'h:mm', 'hmm', 'hh:mma', 'h:mma', 'hmma', 'hh:mm a', 'h:mm a', 'hmm a', 'HH:mm', 'H:mm', 'Hmm']

class TimeInput extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired
    }

    static defaultProps = {
        value: DEFAULT_TIME
    }

    constructor (props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.onBlur = this.onBlur.bind(this)

        this.DateHelper = getService('DateHelper')

        this.state = {
            value: props.value
        }
    }

    componentDidUpdate (prevProps) {
        const { value } = this.props
        if (prevProps.value !== value) {
            this.setState({ value })
        }
    }

    render () {
        const { value } = this.state
        return (
            <div className="time-input">
                <input
                    autoFocus={true}
                    type="text"
                    value={value}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    onBlur={this.onBlur}
                    placeholder={DEFAULT_TIME}
                />
            </div>
        )
    }

    onChange (e) {
        this.setState({
            value: e.target.value.slice(0, 7)
        })
    }

    onKeyPress (e) {
        if (e.key === BUTTONS.ENTER || e.key === BUTTONS.ESCAPE) {
            e.preventDefault()
            e.target.blur()
        }
    }

    onBlur (e) {
        const { value } = this.state
        const time = moment(value, TIME_FORMATS)
        const newValue = time.isValid() ? time.format(this.DateHelper.DATE_FORMATS().TIME_INPUT) : DEFAULT_TIME

        this.setState({
            value: newValue
        })

        this.props.onChange(newValue)
    }
}

export default TimeInput
