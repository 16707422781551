/*global $*/
export function integerInput() {
    return {
        restrict: 'A',
        scope: {},
        link: ($scope, el) => {
            let input = $(el); // eslint-disable-line
            let listener = e => {
                // Allow: backspace, delete, tab, escape, enter and .
                if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1
                    // Allow: Ctrl+A
                    || (e.keyCode === 65 && e.ctrlKey === true)
                    // Allow: Ctrl+C
                    || (e.keyCode === 67 && e.ctrlKey === true)
                    // Allow: Ctrl+X
                    || (e.keyCode === 88 && e.ctrlKey === true)
                    // Allow: home, end, left, right
                    || (e.keyCode >= 35 && e.keyCode <= 40)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            };

            input.on('keydown', listener);
            $scope.$on('$destroy', () => {
                input.off('keydown', listener);
            });
        }
    };
}
