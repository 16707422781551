import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Dropdown from '../dropdown.jsx'
import { DATA_TYPE_OPTIONS } from '../../../services/data-types'

import './data-type-selector.less'
import { translate } from '../../../modules/react/utils'

function DataTypeSelector ({ value, onChange, ...props }) {
    const [isOpen, setIsOpen] = useState(false)
    const clickHandler = (option) => {
        onChange(option)
        setIsOpen(false)
    }

    const label = (
        <>
            <i className={cn('icn', value.icon)}/>
            {' '}
            {translate(value.label)}
        </>
    )

    return (
        <Dropdown
            label={label}
            labelClasses="data-type-selector__header"
            position={'bottom left'}
            open={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            {...props}
        >
            <ul className="data-type-selector__options">
                {DATA_TYPE_OPTIONS.map((option, i) => (
                    <li key={option.value}>
                        <a onClick={() => clickHandler(option)}>
                            <i className={cn('icn', option.icon)}/>
                            {' '}
                            {translate(option.label)}
                        </a>
                    </li>
                ))}
            </ul>
        </Dropdown>
    )
}

DataTypeSelector.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
}

export default DataTypeSelector
