export function undoDeletionGroup(AdminApiCalls, PageSettings, $timeout, $state) {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div class="undo-deletion-popup">
                <i class="icon-common_close" ng-click="close()"></i>
                <i class="icon-template_delete"></i>
                <div>
                    <header>
                        {{PageSettings.deletedGroup.name}}&nbsp;{{'undoDeletion.text' | translate}}
                    </header>
                    <a href="" ng-click="undo()">{{'undoDeletion.link' | translate}}</a>
                </div>
            </div>
        `,

        link: (scope) => {
            scope.PageSettings = PageSettings;

            scope.close = () => {
                $timeout.cancel(scope.timeoutId);
                if (scope.PageSettings.deletedGroup) {
                    AdminApiCalls.deleteGroup(scope.PageSettings.deletedGroup.id, scope.PageSettings.deletedGroup.version)
                        .then(() => {
                            if ($state.current.name.indexOf('main.admin') !== -1) {
                                scope.PageSettings.reloadGroups = true;
                            } else {
                                scope.PageSettings.deletedGroup = null;
                            }
                        })
                        .catch(() => scope.PageSettings.deleteGroup = null);
                }
            };

            scope.undo = () => {
                scope.PageSettings.deletedGroup = null;
            };

            scope.timeoutId = $timeout(scope.close, 5 * 1000);
            scope.$on('$destroy', scope.close);
        }
    };
}
