import colors from '../../../libs/colors';

export function actorsField() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            actors: '=',
            task: '=?'
        },
        template: `
            <div class="actors-field">
                <div class="group" ng-if="noUsers">
                    <span class="avatar-small">
                        <i class="icon-assignee_group"></i>
                    </span>
                    <span class="text">{{'label.noUsers' | translate}}</span>
                </div>

                <div class="group" ng-repeat="role in actors.specialRoles" ng-if="!role.isDeleted">
                    <span class="avatar-small">
                        <i ng-class="{'icon-assignee_process_starter': role.isProcessStarter || role.isStarterDirectManager,
                                      'icon-assignee_process_manager': role.isProcessManager,
                                      'icon-assignee_group': role.allUsers,
                                      'icon-company_users': role.formField && role.formField.dataType === 'USER_FIELD'}"></i>
                    </span>
                    <span class="text">{{::role.name}}</span>
                </div>

                <div class="group" ng-repeat="group in actors.groups" ng-if="!group.isDeleted">
                    <span class="avatar-small">
                        <i class="icon-assignee_group"></i>
                    </span>
                    <span class="text">{{::group.name}}</span>
                </div>

                <div class="user" ng-repeat="user in actors.users" ng-if="!user.isDeleted">
                    <div class="avatar-small" ng-style="{background: getColor(user.colorIndex)}"
                         ng-if="!user.avatarUrl">
                        {{user.name || user.fullName | initials}}
                    </div>
                    <img class="avatar-small" ng-src="{{user.avatarUrl + '_24'}}" alt="" ng-if="user.avatarUrl"/>
                    <span class="text">{{user.name || user.fullName}}</span>
                </div>
            </div>
        `,
        link: (scope) => {
            scope.getColor = index => colors[index - 1];

            const updateNoUsers = () => {
                scope.noUsers = scope.actors.specialRoles.filter(r => !r.isDeleted).length
                    + scope.actors.groups.filter(g => !g.isDeleted).length +
                    scope.actors.users.filter(u => !u.isDeleted).length === 0;
            }

            scope.$watch('task', (val) => {
                scope.noUsers = null

                if (!(val && !val.hasActors) && scope.actors) {
                    updateNoUsers()
                }
            })

            scope.$watch('actors', () => {
                scope.noUsers = null

                if (scope.actors) {
                    updateNoUsers()
                }
            })
        }
    };
}
