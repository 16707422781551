/*global angular*/
export function ServerDateConverter() {
    'ngInject';
    let iso8601RegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

    let _objIsDate = checkObj => {
        if (checkObj) {
            if (angular.isString(checkObj)) {
                return iso8601RegEx.test(checkObj);
            } else if (angular.isObject(checkObj)) {
                return angular.isDate(Object.prototype.toString.call(checkObj));
            }
        }
        return false;
    };

    let _dateToUTCTimestamp = dateValue => {
        return Math.floor(dateValue.getTime() / 1000);
    };

    //convert http post data - date objects
    let _convertDates = postData => {
        for (var key in postData) {
            //iterate over all object properties
            if (Object.prototype.hasOwnProperty.call(postData, key) && key.indexOf('$') !== 0) {
                var value = postData[key];

                if (_objIsDate(value)) {
                    //convert date string to UTC timestamp
                    postData[key] = _dateToUTCTimestamp(new Date(value));
                } else if (angular.isObject(value)) {
                    //Recursively evaluate nested objects
                    _convertDates(value);
                }
            }
        }
    };

    return {
        //do dates conversion on HTTP post
        convertDatesOnRemotePost: postData => {
            if (postData && angular.isObject(postData)) {
                _convertDates(postData);
            }
        },
        objectIsDate: object => {
            return _objIsDate(object);
        }
    };
}
