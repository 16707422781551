import utils from './utils';

export class ErrorController {
    constructor($stateParams, $translate, $window, $location, ServerConfig) {
        'ngInject';
        let storedMsg, storedReason;
        if (utils.isSessionStorageSupported()) {
            storedMsg = $window.sessionStorage.getItem('errorPageMessage');
            storedReason = $window.sessionStorage.getItem('errorPageReason');
        }

        if ($stateParams.message) {
            if (utils.isSessionStorageSupported()) {
                $window.sessionStorage.setItem('errorPageMessage', $stateParams.message);
            }
            this.message = $stateParams.message;
        } else if ($stateParams.blocked) {
            this.currentDomain = $location.host().split('.').slice(-2).join('.');
            this.isBlockedAccount = true;
            this.canBeActivated = ServerConfig.canBeActivated;
        } else {
            this.message = storedMsg || $translate.instant('error.site');
        }

        if ($stateParams.reason) {
            if (utils.isSessionStorageSupported()) {
                $window.sessionStorage.setItem('errorPageReason', $stateParams.reason);
            }
            this.reason = $stateParams.reason;
        } else if ($stateParams.message) {
            if (utils.isSessionStorageSupported()) {
                $window.sessionStorage.removeItem('errorPageReason');
            }
            this.reason = null;
        } else {
            this.reason = storedReason || null;
        }
        
        this.goToOrigin = () => {
            let parts = $window.location.host.split('.');
            if (parts.length > 2) {
                $window.location.href = `${$window.location.port}//${parts.slice(-2).join('.')}`;
            } else {
                $window.location.href = $window.location.origin;
            }
        };
    }
}
