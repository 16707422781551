import config from '../config';

export function LocationSettings($location, $timeout) {
    'ngInject';

    let service = {
        go: stateName => {
            $location.path(stateName);
        },
        goMain: () => {
            //TODO: add timeout when needed. Right now timeout is Zero
            $timeout(() => {
                $location.path(config.state_main);
            }, config.redirect_timeout);
        },
        goLogin: () => {
            $location.path(config.state_login);
        },
        goHome: () => {
            $location.path(config.state_home);
        },
        goPath: (path) => {
            $location.path(path);
        }
    };
    return service;
}
