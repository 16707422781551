import adminUtils from '../../modules/admin/utils';
import config from '../../config';
import './user-field-settings.less'

export const UserFieldSettings = {
    bindings: {
        settings: '='
    },
    template: `
        <single-select
            items="$ctrl.items"
            on-select="$ctrl.onSelect(item)"
            on-search="$ctrl.onSearch(query)"
            placeholder="{{$ctrl.placeholder}}"
            default-icon="$ctrl.defaultIcon"
            no-results="$ctrl.noResults"></single-select>
    `,
    controller: function($translate, $scope, $filter, $element, ApiCalls, AdminApiCalls, $translateSanitization, PageSettings, $rootScope, currentUser) {
        'ngInject';

        this.source = [];
        this.items = [];
        this.selected = null;
        this.placeholder = $translate.instant('label.userFieldSettings.defaultValue');
        this.defaultIcon = 'icon-assignee_group';
        this.noResults = '';

        const noResultsStaticLabel = $translate.instant('text.notFoundGroupName');
        const groupCreatorNameLabel = $translate.instant('label.createGroup');

        const prepareGroupName = (groupName) => {
            return adminUtils.groupNameTrimRestrictedChar(groupName).substr(0, 45);
        };

        const getGroupCreatorItem = (name) => {
            return {
                newGroupName: name,
                name: `${groupCreatorNameLabel} "${prepareGroupName(name)}"`,
                icon: 'icon-template_create'
            };
        };

        const getModelValue = () => {
            this.source.forEach(sourceItem => {
                sourceItem.selected = this.settings.group && sourceItem.id === this.settings.group.id;
            });
        };

        const setModelValue = () => {
            const selected = this.source.find(item => item.selected);

            if (selected) {
                delete this.settings.allUsers;
                this.settings.group = {id: selected.id, name: selected.name};
            } else {
                delete this.settings.group;
                this.settings.allUsers = true;
            }
        };

        const updateItems = (searchText) => {
            const filteredItems = searchText
                ? $filter('search')(this.source, searchText)
                : this.source.slice();

            this.items = $filter('orderBy')(filteredItems, 'name');

            if (searchText && !this.items.length) {
                let noResultsParts = [`<span>${noResultsStaticLabel}</span>`];
                if (currentUser.isAdmin) {
                    noResultsParts.push(`<a>${groupCreatorNameLabel} "${prepareGroupName(searchText)}"</a>`);
                }
                this.noResults = noResultsParts.join('<br>');
            }

            if (currentUser.isAdmin && searchText && this.items.length
                    && !this.items.find(item => item.name.toLowerCase() === searchText.toLowerCase())) {
                this.items.push(getGroupCreatorItem(searchText));
            }
        };

        const addCreatedGroup = (group) => {
            $rootScope.$broadcast('group-creation', Object.assign({}, group));
            this.source.push(Object.assign({}, group, {selected: true, icon: this.defaultIcon}));
            updateItems();
            setModelValue();
        };

        const createNewGroup = (rawName) => {
            const groupName = prepareGroupName(rawName);
            if (!groupName) {
                return;
            }

            this.serverErrorMsg = null;

            AdminApiCalls.addGroup({name: groupName}, true, true).then(data => {
                if (data.success) {
                    addCreatedGroup(data.result);

                    $translateSanitization.useStrategy(null);
                    let text = $translate.instant('label.groupCreated', {groupName: data.result.name});
                    $translateSanitization.useStrategy(config.sanitize_strategy);
                    PageSettings.toasterData = {
                        iconClass: ' icon-company_groups',
                        text: text,
                        timeout: 3000
                    };
                }
            }, errorResp => {
                if(errorResp.status === 517) {
                    addCreatedGroup(errorResp.data.existing);
                }

                if (errorResp.data) {
                    this.serverErrorMsg = errorResp.data.displayError || errorResp.data.error || errorResp.data.name[0].fieldError;
                }

                this.serverErrorMsg = this.serverErrorMsg || $translate.instant('error.common');
            });
        };

        this.onSelect = (item) => {
            if (item && (item.newGroupName || item.noResults)) {
                createNewGroup(item.newGroupName || item.searchText);
            } else {
                this.source.forEach(sourceItem => {
                    sourceItem.selected = item && sourceItem.id === item.id;
                });
                updateItems();
                setModelValue();
            }
        };

        this.onSearch = (query) => {
            updateItems(query);
        };

        this.fetchData = () => {
            let initialValue;
            if (this.settings.group) {
                initialValue = Object.assign({}, this.settings.group, {selected: true, icon: this.defaultIcon});
                this.source = [initialValue];
            }

            ApiCalls.getActorsGroups().then((data) => {
                this.source = data.map(group => {
                    return Object.assign({}, group, {icon: this.defaultIcon});
                });

                if (initialValue && !data.find(group => group.id === initialValue.id)) {
                    initialValue.isDeleted = true;
                    this.source.push(initialValue);
                }

                getModelValue();
                updateItems();
            });
        };

        this.$onInit = () => {
            $element.addClass('user-field-settings');
            this.fetchData();
        };

        $scope.$watch(() => this.settings.group, () => {
            getModelValue();
            updateItems();
        }, true);
    }
};
