import Drop from 'tether-drop';
import { DEFAULT_PROCESS_GROUP_TYPE_ID } from '../services/processes-settings'
const DropContext = Drop.createContext({classPrefix: 'processes-group'});

export function selectProcessesGroup($translate) {
    'ngInject';
    const types = [
        {id: 0, name: $translate.instant('tasks-group.noGroups')},
        {id: 1, name: $translate.instant('tasks-group.byTemplate')}
    ];

    return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        scope: {
            ngModel: '=?'
        },
        template: require('./select-processes-group.html'),
        link: (scope, element, attrs, ctrl) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--processes-group')[0],
                position: 'bottom right',
                openOn: 'click'
            });

            scope.types = types.slice();
            scope.activeType = scope.types.find(t => t.id === scope.ngModel) || types.find(t => t.id === DEFAULT_PROCESS_GROUP_TYPE_ID);

            scope.setType = type => {
                if (type.id !== scope.ngModel) {
                    ctrl.$setViewValue(type.id);
                    drop.close();
                }
            };

            scope.getIcon = index => {
                return ['icon-process_tasks', 'icon-tasks_group_process'][index];
            };

            scope.$watch('ngModel', val => {
                scope.activeType = scope.types.find(t => t.id === val) || types[0];
            });

            scope.$on('tether:close', () => {
                drop.close();
            });
            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
