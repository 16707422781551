import {createSelector} from 'redux-starter-kit';
import {asSelectOption, userAsSelectOption} from './utils';

export const getTemplates = createSelector(
    ['templates'],
    templates => templates.map(asSelectOption)
);

export const getUsers = createSelector(
    ['users'],
    users => users.map(userAsSelectOption)
);

export const getCurrentReport = createSelector(
    ['currentReportId', 'reports'],
    (currentReportId, reports) => {
        return reports.find(report => report.id === currentReportId);
    }
);
