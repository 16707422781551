import { conditionIsInvalid } from '../../processes/utils'
import { NUMERIC_DATA_TYPES, STRING_DATA_TYPES } from '../services/conditions-config'

export function taskGroupConditions ($translate, $timeout, ConditionsConfig) {
    'ngInject'

    return {
        restrict: 'E',
        scope: true,
        bindToController: {
            item: '=',
            formFields: '<',
            isGroup: '@?',
            helpTextAction: '&?',
            displayErrors: '<?'
        },
        template: `
            <div class="task-group-conditions">
                <div class="header" ng-if="conditionsCtrl.formFields.length">
                    <span>
                        {{conditionsCtrl.getHeaderTitle() | translate}}
                        <i class="icon-common_hint tooltip-white" help-tooltip="{{conditionsCtrl.getHeaderHint()}}"></i>
                    </span>
                    <tether-drop class="task-group-conditions-operator-drop"
                        ng-model="conditionsCtrl.item.conditions.rule"
                        values="conditionsCtrl.operatorOptions"
                        ng-change="conditionsCtrl.updateConditions()"></tether-drop>
                </div>
                <div class="conditions" ng-if="conditionsCtrl.conditionsAreVisible()">
                    <div class="condition" ng-repeat="condition in conditionsCtrl.item.conditions.list">
                        <div class="rule" ng-hide="$index === 0">{{conditionsCtrl.item.conditions.rule}}</div>
                        <div class="form-field">
                            <condition-field-selector
                                condition="condition"
                                form-fields="conditionsCtrl.formFields"
                                placeholder="conditionsCtrl.getFormFieldPlaceholder()"
                            ></condition-field-selector>
                        </div>
                        <div class="operation">
                            <tether-drop class="task-group-conditions-operation-drop"
                                ng-class="{'empty': !condition.operation.id, 'error': conditionsCtrl.invalidOperation(condition)}"
                                placeholder="{{ conditionsCtrl.getOperationPlaceholder() }}"
                                ng-model="condition.operation.id"
                                values="condition.field.availableOperations"
                                ng-change="conditionsCtrl.updateOperation(condition)"></tether-drop>
                        </div>
                        <div class="expression form-group" ng-if="condition.operation.id && condition.operation.hasExpression"
                            ng-class="{'has-error error': conditionsCtrl.invalidExpression(condition)}">
                            <div class="expression__date" ng-if="condition.field.dataType == 'DATE_ONLY_VALUE'">
                                <date-alt-picker date="condition.expression.date"></date-alt-picker>
                            </div>

                            <div class="expression__date" ng-if="condition.field.dataType == 'DATE_AND_TIME'">
                                <date-time-picker date="condition.expression.date"></date-time-picker>
                            </div>

                            <div class="expression__dropdown" ng-if="condition.expression.allowedValues && (condition.field.dataType == 'RADIO_SELECTOR' || condition.field.dataType == 'MULTI_SELECTOR')">
                                <dropdown-tether
                                    ng-model="condition.expression.string"
                                    not-empty="true"
                                    values="condition.expression.allowedValues"
                                ></dropdown-tether>
                            </div>

                            <div class="expression__dropdown" ng-if="condition.expression.tableId && (condition.field.dataType == 'RADIO_SELECTOR' || condition.field.dataType == 'MULTI_SELECTOR')">
                                <react-table-source-field
                                    table-id="condition.expression.tableId"
                                    value="condition.expression.record"
                                    on-change="condition.expression.selectRecord"
                                    with-empty-value="false"
                                    display-fields="condition.expression.displayFields"
                                ></react-table-source-field>
                            </div>

                            <div class="expression__number" ng-if="conditionsCtrl.expressionIsNumeric(condition)">
                                <input class="number" type="text" ng-model="condition.expression.number"
                                    numeric-value precision="condition.field.options.precision" />
                            </div>

                            <div class="expression__string" ng-if="conditionsCtrl.expressionIsString(condition)">
                                <input type="text" ng-model="condition.expression.string"
                                    maxlength="{{condition.field.options.maxLength || 255}}" />
                            </div>

                            <div class="expression__yesno" ng-if="condition.field.dataType == 'YES_NO'">
                                <yesno-data-field ng-model="condition.expression.string"
                                    ng-change="conditionsCtrl.updateExpressionSupportData(condition)"
                                    field="condition.field"></yesno-data-field>
                            </div>

                            <div class="errors" ng-if="conditionsCtrl.invalidExpression(condition)">
                                {{'validation.required' | translate}}
                            </div>
                        </div>
                        <div class="remove-action">
                            <i tabindex="-1" class="icon-group_delete"
                               ng-hide="conditionsCtrl.item.conditions.list.length <= 1"
                               ng-click="conditionsCtrl.removeCondition($index)"
                               help-tooltip="taskConditions.label.deleteCondition"></i>
                        </div>
                    </div>
                </div>
                <div class="add-condition" ng-if="conditionsCtrl.newConditionCanBeAdded()">
                    <button type="button"
                        class="btn-switcher btn-primary-switcher with-angle"
                        ng-click="conditionsCtrl.addCondition()">
                        <i class="icon-company_groups_add"></i>
                        <span>{{"taskConditions.label.addCondition" | translate}}</span>
                    </button>
                </div>
                <div class="help-text"
                     ng-if="!conditionsCtrl.formFields.length && conditionsCtrl.helpTextAction"
                     ng-bind-html="conditionsCtrl.helpTextHtml()"></div>
            </div>
        `,
        controllerAs: 'conditionsCtrl',
        controller: function () {
            'ngInject'

            this.operatorOptions = ConditionsConfig.OPERATOR_OPTIONS
            this.formFields = []
            this.isGroup = false
            this.displayErrors = false
            this.updateOperation = ConditionsConfig.updateOperation
            this.updateExpressionSupportData = ConditionsConfig.updateExpressionSupportData

            this.updateConditions = () => {
                ConditionsConfig.updateConditions(this.item, this.formFields)

                if (this.item.conditions.rule === ConditionsConfig.OPERATORS.ALWAYS || !this.item.conditions.list.length) {
                    this.addDefaultConditions()
                }
            }

            this.addCondition = () => {
                const condition = {
                    field: {},
                    operation: {},
                    expression: {}
                }
                ConditionsConfig.updateAvailableOperations(condition)
                ConditionsConfig.updateOperation(condition)
                this.item.conditions.list.push(condition)
            }

            this.removeCondition = (index) => {
                this.item.conditions.list.splice(index, 1)
            }

            this.addDefaultConditions = () => {
                if (!this.item.conditions.list.length) {
                    this.addCondition()
                }
            }

            this.getHeaderTitle = () => {
                return this.isGroup ? 'taskConditions.label.groupCreationTitle' : 'taskConditions.label.taskCreationTitle'
            }

            this.getHeaderHint = () => {
                return this.isGroup ? 'taskConditions.tooltips.groupHint' : 'taskConditions.tooltips.taskHint'
            }

            this.getFormFieldPlaceholder = () => {
                if (!this.formFields.length) {
                    return $translate.instant('taskConditions.label.formFieldDropdown.emptyFormFields')
                }

                return $translate.instant('taskConditions.label.formFieldDropdown.emptyState')
            }

            this.getOperationPlaceholder = () => {
                return $translate.instant('taskConditions.label.operationDropdown.emptyState')
            }

            this.expressionIsString = (condition) => {
                return STRING_DATA_TYPES.includes(condition.field.dataType)
            }

            this.expressionIsNumeric = (condition) => {
                return NUMERIC_DATA_TYPES.includes(condition.field.dataType)
            }

            this.conditionsAreVisible = () => {
                return this.formFields.length
                    && this.item.conditions
                    && this.item.conditions.rule
                    && this.item.conditions.rule !== ConditionsConfig.OPERATORS.ALWAYS
            }

            this.newConditionCanBeAdded = () => {
                return this.conditionsAreVisible() && this.item.conditions.list.length < ConditionsConfig.MAX_CONDITIONS_AMOUNT
            }

            this.invalidOperation = condition => {
                return this.displayErrors && conditionIsInvalid(condition) === 'invalid-operation'
            }

            this.invalidExpression = condition => {
                return this.displayErrors && conditionIsInvalid(condition) === 'invalid-expression'
            }

            this.helpTextHtml = () => {
                let rawText = $translate.instant('taskConditions.text.emptyFormHint')
                return rawText.replace('[', '<a href="" class="empty-state-action-link">').replace(']', '</a>')
            }
        },
        link: ($scope, element, attrs, $ctrl) => {
            $scope.$watch($ctrl.item, () => {
                $ctrl.updateConditions()
            }, true)

            element.on('click', '.empty-state-action-link', (event) => {
                event.preventDefault()
                $timeout($ctrl.helpTextAction)
            })
        }
    }
}
