import isFunction from 'lodash/isFunction'
import colors from '../libs/colors'

export class MainController {
    constructor ($auth, $scope, $filter, $translate, $modal, currentUser, AuthApiCalls, LocationSettings, PusherHelper, CurrentUserService,
        MomentHelper, $state, PageSettings, ProcessesSettings, $window, ApiCalls, $location, $timeout, $rootScope) {
        'ngInject'
        this.MomentHelper = MomentHelper
        this.smallScreensMode = PageSettings.smallScreensMode
        this.searchString = ''
        this.PageSettings = PageSettings

        this.currentUserIsAdmin = currentUser.isAdmin
        this.templatesNotAvailable = () => !currentUser.isAdmin && $window.templatesNotAvailable
        this.datatablesNotAvailable = () => !currentUser.isAdmin && $window.datatablesNotAvailable

        this.startProcess = () => {
            $state.go('main.templates', { url: $location.path() })
        }

        let logout = () => {
            if ($auth.isAuthenticated()) {
                AuthApiCalls.logout().then(() => {
                    $auth.logout()
                    PusherHelper.deleteSocket()
                    LocationSettings.goLogin()
                    if ($window.analytics) {
                        $window.analytics.reset()
                    }
                })
            }
        }

        this.logout = () => {
            if (isFunction(PageSettings.checkUnsavedChangesAsync)) {
                PageSettings.checkUnsavedChangesAsync().then((result) => result && logout())
            } else {
                logout()
            }
        }

        this.truncate = (input) => {
            return $filter('truncate')(input)
        }

        this.checkState = stateName => {
            return $state.current.name.indexOf(stateName) !== -1
        }

        this.getColorByIndex = index => {
            return colors[index - 1]
        }

        this.getCompanyLogoUrl = () => {
            if (currentUser.companyLogoUrl) {
                return `${currentUser.companyLogoUrl}_120`
            }
            return ''
        }

        this.goToProcessesList = () => {
            $state.go(ProcessesSettings.defaultAll ? 'main.processes.all' : 'main.processes.my')
        }

        this.getGroupsStr = user => {
            if (user.groups && user.groups.length > 3) {
                let count = user.groups.length - 3
                let begin = user.groups.slice(0, 3).map(group => group.name).join(', ')
                let end = $translate.instant('text.andMoreGroups', { count }, 'messageformat')
                return begin + ' ' + end
            }
            return user.groups.map(group => group.name).join(', ')
        }

        this.tmplGallery = () => {
            $state.go('main.templates.gallery')
        }

        this.createTemplate = () => {
            if (PageSettings.smallScreensMode()) {
                let modalScope = $scope.$new()
                modalScope.title = $translate.instant('desktopIsNeeded.title')
                modalScope.text = $translate.instant('desktopIsNeeded.text')
                return PageSettings.openAlertModal(modalScope)
                    .finally(() => modalScope.$destroy())
            }
            return $state.go('main.create-template', { url: $location.path() })
        }

        let searchTimeout
        this.applySearchString = () => {
            $timeout.cancel(searchTimeout)
            searchTimeout = $timeout(() => $scope.$broadcast('content:search', this.searchString), 500)
        }

        this.resetSearchString = () => {
            this.searchString = ''
            this.searchState = false
            this.applySearchString()
        }

        $rootScope.$on('$stateChangeSuccess', () => {
            this.searchString = ''
            this.searchState = false
        })

        this.toggleSearchingState = ($event) => {
            const isFocused = $event.target === document.activeElement
            if (isFocused || this.searchString) {
                this.searchState = true
            } else {
                $timeout(() => this.searchState = false, 200)
            }
        }

        this.notificationBannerIsVisible = () => {
            return currentUser.showUpgradeMessage || $rootScope.notification.toDisplay || CurrentUserService.companyHasPaymentIssue()
        }

        this.companyStatusChangeHandler = ({ hasPaymentIssues, paymentIssuesStartDate }) => {
            currentUser.companyPaymentIssuesStartDate = hasPaymentIssues ? paymentIssuesStartDate : undefined
        }

        PusherHelper.subscribe('companyStatusChanged', this.companyStatusChangeHandler)

        $scope.$on('$destroy', () => {
            PusherHelper.unsubscribe('companyStatusChanged', this.companyStatusChangeHandler)
        })
    }
}
