export const MultiselectField = {
    bindings: {
        field: '=?',
        taskId: '=',
        processId: '=',
        templateId: '=',
        readonly: '<?',
        onChange: '&?',
        isFull: '@?',
        checkFieldError: '&?'
    },
    template: `
 <div class="multiselect-field" ng-if="!$ctrl.useTableAsSource">
    <label class="checkbox"
        ng-click="$ctrl.toggle(opt)"
        ng-repeat="opt in $ctrl.allowedValues"
        ng-class="{error: $ctrl.checkRequired(), checked: $ctrl.isChecked(opt)}"
        title="{{ opt.item }}"
        ng-attr-id="{{$index == 0 ? $ctrl.field.name.id : ''}}">
        <input type="checkbox" data-ng-disabled="$ctrl.readonly"/>
        <span ng-class="{checked: $ctrl.isChecked(opt)}" class="checkbox-control">
            <i class="icon-menu_checkmark"></i>
        </span>
        <span>{{opt.item}}</span>
    </label>
    <div class="error-hint" ng-if="$ctrl.checkRequired()">{{'text.multiselectRequire' | translate}}</div>
</div>
<div class="multiselect-control" ng-if="$ctrl.useTableAsSource">
    <react-table-source-field
        value="$ctrl.field.value.multiChoiceValue"
        table-id="$ctrl.field.name.options.source.tableId"
        display-fields="$ctrl.field.name.options.source.displayFields"
        field-id="$ctrl.field.name.id"
        task-id="$ctrl.taskId"
        process-id="$ctrl.processId"
        template-id="$ctrl.templateId"
        placeholder="$ctrl.placeholder"
        on-change="$ctrl.onSelectItems"
        is-disabled="$ctrl.readonly"
        is-multi="true">
    </react-table-source-field>
    <div class="error-hint" ng-if="$ctrl.checkRequired()">{{'validation.required' | translate}}</div>
</div>`,
    controller: function ($window, $element, $scope, $translate) {
        'ngInject'
        this.placeholder = $translate.instant('label.dataForm.sourceTableName.placeholder')

        if (!this.isFull) {
            this.maxWidth = $window.innerWidth * 0.6 - $element.offset().left - 140 //eslint-disable-line
        }
        this.isChecked = opt => {
            return this.field.value && this.field.value.multiChoiceValue && this.field.value.multiChoiceValue.find(i => i === opt.item) !== undefined
        }
        this.checkRequired = () => {
            if (this.checkFieldError) {
                return this.checkFieldError() === 'required'
            }
            return this.field.settings && this.field.settings.isRequiredToFill && (!this.field.value.multiChoiceValue || !this.field.value.multiChoiceValue.length)
        }
        this.toggle = opt => {
            if (this.readonly) {
                return
            }
            if (this.isChecked(opt)) {
                this.field.value.multiChoiceValue = this.field.value.multiChoiceValue.filter(i => i !== opt.item)
            } else {
                this.field.value.multiChoiceValue.push(opt.item)
            }
        }

        this.onSelectItems = items => {
            this.field.value.multiChoiceValue = items.map(({ ...props }) => ({ ...props }))
        }

        const getSelectedValuesAsString = () => {
            return this.field.value.multiChoiceValue.map(item => this.useTableAsSource ? item.recordId : item).join(',')
        }

        this.$onInit = () => {
            this.useTableAsSource = this.field.name.options.useTableAsSource
            this.allowedValues = !this.useTableAsSource
                ? this.field.name.options.source.allowedValues
                : []

            if (!this.field.value) {
                this.field.value = {}
            }

            if (!this.field.value.multiChoiceValue) {
                this.field.value.multiChoiceValue = []
            }

            $scope.$watch(getSelectedValuesAsString, (nextValue, prevValue) => {
                if (nextValue !== prevValue && this.onChange) {
                    this.onChange({ field: this.field })
                }
            }, true)
        }
    }
}
