import moment from 'moment-timezone';
import Drop from 'tether-drop';

const DateAltPickerContext = Drop.createContext({
    classPrefix: 'date-alt-picker-drop'
});

export function dateTimePicker($timeout, DateHelper, $document) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            dateTime: '=?date',
            onOpen: '&?',
            onClose: '&?',
            field: '=?',
            index: '@?',
            isDisabled: '=?'
        },
        template: `
            <span class="prc-date-picker date-time-picker">
                <span class="handler" ng-class="{readOnly: isDisabled}" ng-attr-id="{{field.name.id}}" tabindex="-1">
                    <i class="icon-task_due_date_add_small"></i>
                    <div ng-if="dateTime">
                        {{dateTime | momentDate:'DATE_TIME_INPUT'}}
                    </div>
                    <i ng-if="dateTime && !isDisabled" class="icon-common_remove" touch-start="clearDate()"
                       tabindex="-1" ng-click="clearDate($event)"></i>
                </span>
                <div class="prc-picker-content date-time-picker-content" ng-class="{empty: !dateTime}"
                     ng-click="$event.stopPropagation()" ng-show="!isDisabled">
                    <div ng-click="pickerClick($event)" date-picker="pickerValue"
                         watch-direct-changes="true" min-view="date" max-view="date" with-tz="true"
                         after="(field.name || field).options.minValue" before="(field.name || field).options.maxValue"></div>
                     <div class="form-group time">
                        <label>
                            <span>{{'label.time' | translate}}</span>
                            <i class="icon-common_forms_required"></i>
                        </label>
                        <time-input ng-model="timeValue"
                                    on-enter="timeSave()"
                                    on-update="recalc"
                                    is-active="isTimeActive"
                        ></time-input>
                    </div>
                </div>
            </span>
        `,

        link: (scope, element) => {
            const TIME_FORMAT = DateHelper.DATE_FORMATS().TIME_INPUT
            const DATE_FORMAT = DateHelper.DATE_FORMATS().DATE_INPUT
            const DATETIME_FORMAT = DateHelper.DATE_FORMATS().DATE_TIME_INPUT

            const tz = DateHelper.getTZ();
            let drop = new DateAltPickerContext({
                target: element.find('.handler')[0],
                content: element.find('.prc-picker-content')[0],
                position: 'bottom center',
                remove: false,
                openOn: 'click',
                tetherOptions: {
                    constraints: [
                        {
                            to: 'window',
                            attachment: 'together',
                            pin: true
                        }
                    ]
                }
            });
            let dump;

            scope.fieldId = scope.field ? (scope.field.name || scope.field).id : (scope.index || 'alt');
            scope.showTimeText = true;

            const setViewValue = () => {
                if (scope.dateTime) {
                    let date = moment.unix(scope.dateTime).utc().tz(tz)
                    scope.pickerValue = date
                    scope.timeValue = date.format(TIME_FORMAT)
                } else {
                    scope.pickerValue = undefined
                    scope.timeValue = undefined
                }
            }

            scope.$watch('dateTime', () => {
                setViewValue()
            });

            scope.$watch('pickerValue', newValue => {
                if (!newValue) {
                    return
                }

                let chosen = moment(newValue)
                let start = chosen.format(DATE_FORMAT)
                let end = ', 12:00'

                if (scope.timeValue) {
                    end = scope.timeValue
                }

                scope.dateTime = moment.tz(start + end, DATETIME_FORMAT, tz).unix()
            })

            scope.recalc = (time) => {
                if (scope.dateTime) {
                    let date = moment.unix(scope.dateTime).utc().tz(tz).format(DATE_FORMAT);
                    scope.dateTime = moment.tz(date + ', ' + time, DATETIME_FORMAT, tz).unix();
                }
            };

            scope.timeSave = () => {
                if (scope.dateTime && scope.timeValue) {
                    drop.close();
                }
            };

            scope.timeSaveOnKey = event => {
                if (event.which === 13) {
                    scope.timeSave();
                }
            };

            scope.pickerClick = (event) => {
                scope.isTimeActive = true;
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                if (event.target.nodeName === 'SPAN' && scope.timeValue) {
                    drop.close();
                }
            };

            scope.clearDate = (event) => {
                if (event) {
                    event.stopPropagation();
                }
                if (scope.timeValue) {
                    delete scope.timeValue;
                }
                if (scope.pickerValue) {
                    delete scope.pickerValue;
                }
                if (scope.dateTime) {
                    delete scope.dateTime;
                }
                if (scope.field) {
                    scope.field.value = scope.field.value || {};
                    scope.field.value.dateValue = null;
                }
                if (scope.onClose && !scope.dateTime) {
                    dump = scope.dateTime;
                    scope.onClose();
                }
                drop.close();
                scope.$applyAsync();
            };

            drop.on('open', () => {
                dump = scope.dateTime;
                if (scope.onOpen) {
                    scope.onOpen();
                }
                $document.on('keypress', scope.timeSaveOnKey);
                $timeout(() => {
                    scope.isTimeActive = true;
                });
            });

            drop.on('close', () => {
                scope.$broadcast('reset:datepicker');
                $document.off('keypress', scope.timeSaveOnKey);
                $timeout(() => scope.isTimeActive = false, 0);
                if (!scope.dateTime && scope.timeValue) {
                    let start = moment.utc().tz(tz).format(DATE_FORMAT);
                    let end = ', ' + scope.timeValue;
                    let tempDate = moment.tz(start + end, DATETIME_FORMAT, tz).unix();
                    scope.dateTime = tempDate > 0 ? tempDate : 0;
                }
                $timeout(() => {
                    if (scope.onClose && dump !== scope.dateTime) {
                        scope.onClose();
                    }
                });
            });

            scope.$on('selectize:open', () => {
                drop.close();
            });

            scope.$on('$destroy', () => {
                $document.off('keypress', scope.timeSaveOnKey);
                drop.destroy();
            });
        }
    };
}
