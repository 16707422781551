export function routerConfig ($stateProvider, $urlRouterProvider) {
    'ngInject'

    let modalStateInstance

    const galleryState = {
        scope: undefined,
        modal: undefined
    }

    $urlRouterProvider.rule(function ($injector, $location) {
        const path = $location.path()
        const hasTrailingSlash = path[path.length - 1] === '/'
        if (hasTrailingSlash) {
            return path.substr(0, path.length - 1)
        }
    })

    $stateProvider
        .state('main', {
            template: require('./templates/layout.html'),
            controller: 'MainController',
            controllerAs: 'mainCtrl'
        })

        // Home routes
        .state('main.home', {
            url: '/?authToken&redir',
            template: require('./modules/home/index.html'),
            controller: 'HomeController',
            controllerAs: 'homeCtrl',
            params: {
                authToken: null,
                redir: null
            }
        })

        // Processes routes
        .state('main.processes', {
            template: require('./modules/processes/layout.html'),
            controller: 'ProcessesController',
            controllerAs: 'processesCtrl'
        })
        .state('main.processes.my', {
            url: '/processes',
            template: require('./modules/processes/views/my-processes.html'),
            controller: 'MyProcessesController',
            controllerAs: 'ctrl'
        })
        .state('main.processes.all', {
            url: '/processes/all',
            template: require('./modules/processes/views/all-processes.html'),
            controller: 'AllProcessesController',
            controllerAs: 'ctrl'
        })
        .state('main.templates', {
            url: '/templates',
            template: require('./modules/processes/views/templates-start.html'),
            controller: 'TemplatesStartController',
            controllerAs: 'templatesCtrl',
            params: {
                url: '/processes'
            }
        })

        .state('main.templates.gallery', {
            url: '/gallery',
            onEnter: ($rootScope, $state, categories, PageSettings) => {
                'ngInject'
                galleryState.scope = $rootScope.$new()
                galleryState.scope.source = categories.list
                galleryState.scope.isLoaded = true
                galleryState.modal = PageSettings.templatesGallery(galleryState.scope)
                galleryState.modal.result
                    .then(result => {
                        if (result) {
                            $rootScope.$broadcast('templates:refresh')
                        }
                    })
                    .finally(() => {
                        $state.go('main.templates')
                        galleryState.scope.$destroy()
                    })
            },
            onExit: () => {
                galleryState.modal.close(false)
            },
            resolve: {
                categories: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getGalleryTemplates()
                }
            }
        })
        .state('main.templates.gallery.category', {
            url: '/:category',
            onEnter: ($stateParams) => {
                'ngInject'
                galleryState.scope.activeCategory = $stateParams.category
                galleryState.scope.selected = []
            },
            onExit: () => {
            }
        })
        .state('main.templates.gallery.category.templates', {
            url: '/:selected',
            onEnter: ($stateParams) => {
                'ngInject'
                galleryState.scope.selected = $stateParams.selected ? $stateParams.selected.split(',') : []
            },
            onExit: () => {
            }
        })

        .state('main.templates-list', {
            url: '/templates/start',
            // template: require('./modules/processes/views/templates-start.html'),
            // controller: 'TemplatesStartController',
            // controllerAs: 'templatesCtrl',
            redirectTo: 'main.templates'
        })

        // Separate states for Template creation/edit/view
        .state('main.create-template', {
            url: '/templates/create',
            template: require('./modules/processes/views/template-edit.html'),
            controller: 'TemplateEditController',
            controllerAs: 'ctrl',
            resolve: {
                template: () => ({}),
                actorsTree: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getActors()
                },
                currencies: CompanyCurrenciesService => {
                    'ngInject'
                    return CompanyCurrenciesService.reload()
                }
            },
            params: {
                url: '/templates'
            }
        })
        .state('main.template', {
            url: '/templates/:id',
            template: require('./modules/processes/views/template.html'),
            controller: 'TemplateController',
            controllerAs: 'ctrl',
            resolve: {
                template: (ApiCalls, $stateParams, $state) => {
                    'ngInject'
                    if ($stateParams.template) {
                        return $stateParams.template
                    }
                    return ApiCalls.getTemplate({ id: $stateParams.id }).then(data => {
                        return data
                    }, errorResp => {
                        if (errorResp.status === 515) {
                            $state.go('main.notFound', { screen: 'template' })
                        } else if (errorResp.status === 473) {
                            $state.go('main.noPermission', { screen: 'template' })
                        }
                    })
                },
                actorsTree: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getActors()
                }
            },
            params: {
                template: null,
                url: null
            }
        })
        .state('main.templateEdit', {
            url: '/templates/:id/edit',
            template: require('./modules/processes/views/template-edit.html'),
            controller: 'TemplateEditController',
            controllerAs: 'ctrl',
            resolve: {
                template: (ApiCalls, $stateParams, $state) => {
                    'ngInject'
                    return ApiCalls.getTemplate($stateParams).then(data => {
                        return data
                    }, (errorResp) => {
                        if (errorResp.status === 515) {
                            $state.go('main.notFound', { screen: 'template' })
                        } else if (errorResp.status === 473) {
                            $state.go('main.noPermission', { screen: 'template' })
                        }
                    })
                },
                actorsTree: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getActors()
                },
                currencies: CompanyCurrenciesService => {
                    'ngInject'
                    return CompanyCurrenciesService.reload()
                }
            },
            params: {
                url: '/templates',
                mode: null,
                tab: null,
                openedGroupIds: [],
                selectedItemIndex: null
            }
        })

        .state('main.template.start', {
            url: '/start',
            onEnter: ($state, $stateParams, $rootScope, $modal, template, PageSettings) => {
                'ngInject'
                modalStateInstance = PageSettings.startProcess(template)
                modalStateInstance.result
                    .then(
                        (res) => {
                            if (res) {
                                $state.go('main.processes.my')
                            }
                        },
                        () => $state.go('main.template', { id: $stateParams.id })
                    )
            },
            onExit: () => {
                modalStateInstance.close(false)
            }
        })

        .state('main.template.copy', {
            url: '/copy',
            onEnter: ($state, $stateParams, $rootScope, $modal, $translate, template, PageSettings) => {
                'ngInject'
                modalStateInstance = PageSettings.copyTemplate(template)
                modalStateInstance.result
                    .then(
                        res => {
                            if (res) {
                                $state.go('main.templates')
                                PageSettings.toasterData = {
                                    iconClass: 'icon-template_duplicate_roundless',
                                    text: $translate.instant('copyTemplate.toaster.text'),
                                    linkText: $translate.instant('copyTemplate.toaster.linkText'),
                                    route: {
                                        name: 'main.template',
                                        data: { id: res.id }
                                    }
                                }
                            }
                        },
                        () => $state.go('main.template', { id: $stateParams.id })
                    )
            },
            onExit: () => {
                modalStateInstance.close(false)
            }
        })

        .state('main.template.schedule', {
            url: '/schedule',
            views: {
                schedule: {
                    template: '<template-schedule-dialog template="template" on-close="onClose" on-change="onChange"></template-schedule-dialog>',
                    controller: ($scope, $state, template) => {
                        'ngInject'
                        $scope.template = template
                        $scope.onClose = () => $state.go('main.template', { id: template.id })
                        $scope.onChange = (updated, close = false) => {
                            $scope.$broadcast('template.schedule.updated', updated)
                            if (close) {
                                $scope.onClose()
                            }
                        }
                    }
                }
            }
        })

        // Separate state for Process start/edit/view
        // .state('main.processStart', {
        //     url: '/templates/:id/start',
        //     template: require('./modules/processes/views/process-start.html'),
        //     controller: 'ProcessStartController',
        //     controllerAs: 'ctrl',
        //     resolve: {
        //         process: (ApiCalls, $stateParams) => {
        //             'ngInject'
        //             if ($stateParams.id) {
        //                 return ApiCalls.getTemplate($stateParams)
        //             }
        //             return {
        //                 tasks: [{}]
        //             }
        //         },
        //         actorsTree: ApiCalls => {
        //             'ngInject'
        //             return ApiCalls.getActors()
        //         }
        //     },
        //     params: {
        //         url: '/templates'
        //     }
        // })
        .state('main.processStartAdHoc', {
            url: '/templates/start/adhoc',
            template: require('./modules/processes/views/process-start.html'),
            controller: 'ProcessStartController',
            controllerAs: 'ctrl',
            resolve: {
                process: () => {
                    return {
                        tasks: [{}]
                    }
                },
                actorsTree: ApiCalls => {
                    'ngInject'
                    return ApiCalls.getActors()
                }
            },
            params: {
                url: '/templates',
                adHoc: true
            }
        })
        .state('main.processView', {
            url: '/process/:id',
            template: require('./modules/processes/views/process.html'),
            controller: 'ProcessController',
            controllerAs: 'ctrl',
            resolve: {
                process: (ApiCalls, $stateParams, $state) => {
                    'ngInject'
                    return ApiCalls.getProcess($stateParams.id)
                        .catch(errorResp => {
                            if (errorResp.status === 515) {
                                $state.go('main.notFound', { screen: 'process' })
                            } else if (errorResp.status === 473) {
                                $state.go('main.noPermission', { screen: 'process' })
                            }
                        })
                },
                allActiveUsers: (ApiCalls) => {
                    'ngInject'
                    return ApiCalls.getActorsUsers()
                },
                actors: (ApiCalls) => {
                    'ngInject'
                    return ApiCalls.getActors()
                }
            },
            params: {
                url: null,
                openedGroups: []
            },
            data: {
                activeMode: 'TASKS',
                scenario: 'DEFAULT'
            }
        })
        .state('main.processView.dataForm', {
            url: '/dataform',
            data: {
                activeMode: 'FORM'
            }
        })
        .state('main.processView.dataForm.tableView', {
            url: '/:sectionId/expand',
            data: {
                scenario: 'TABLE_VIEW'
            }
        })
        .state('main.processView.dataForm.newRow', {
            url: '/:sectionId/new-row',
            data: {
                scenario: 'NEW_ROW'
            }
        })
        .state('main.processView.dataForm.editRow', {
            url: '/:sectionId/edit-row/:rowIndex',
            data: {
                scenario: 'EDIT_ROW'
            }
        })
        .state('main.processView.properties', {
            url: '/properties',
            data: {
                activeMode: 'PROPS'
            }
        })
        // Separate state for Task view
        .state('main.task', {
            url: '/processes/task/:id',
            template: require('./modules/processes/views/task.html'),
            controller: 'TaskController',
            controllerAs: 'ctrl',
            resolve: {
                task: (ApiCalls, $stateParams, $state) => {
                    'ngInject'
                    return ApiCalls.getTask($stateParams.id)
                        .catch((errorResp) => {
                            if (errorResp.status === 515) {
                                $state.go('main.notFound', { screen: 'task' })
                            } else if (errorResp.status === 473) {
                                $state.go('main.noPermission', { screen: 'task' })
                            }
                        })
                },
                actors: (ApiCalls, $stateParams) => {
                    'ngInject'
                    return ApiCalls.getTaskActors($stateParams.id)
                },
                allActiveUsers: (ApiCalls) => {
                    'ngInject'
                    return ApiCalls.getActorsUsers()
                }
            },
            params: {
                tasks: null,
                fromList: null,
                tasksGroup: null,
                url: '/',
                processState: null
            },
            data: {
                scenario: 'DEFAULT'
            }
        })
        .state('main.task.tableView', {
            url: '/dataform/:sectionId/expand',
            data: {
                scenario: 'TABLE_VIEW'
            }
        })
        .state('main.task.newRow', {
            url: '/dataform/:sectionId/new-row',
            data: {
                scenario: 'NEW_ROW'
            }
        })
        .state('main.task.editRow', {
            url: '/dataform/:sectionId/edit-row/:rowIndex',
            data: {
                scenario: 'EDIT_ROW'
            }
        })
        .state('main.notFound', {
            url: '/not-found',
            template: require('./modules/processes/views/not-found.html'),
            controller: 'NotFoundController',
            controllerAs: 'ctrl',
            params: {
                screen: 'page'
            }
        })
        .state('main.noPermission', {
            url: '/no-permission',
            template: require('./modules/processes/views/no-permission.html'),
            controller: 'NotFoundController',
            controllerAs: 'ctrl',
            params: {
                screen: 'page',
                from: null
            }
        })

        // Reports
        // TODO Move to modules/reports/config.js
        .state('main.report', {
            url: '/reports/:slug',
            template: '<report-view></report-view>'
        })
        .state('main.reports', {
            url: '/reports',
            template: '<reports-list-view></reports-list-view>'
        })

        // DataStore
        .state('main.database', {
            template: '<database-view></database-view>'
        })
        .state('main.database.index', {
            url: '/database'
        })
        .state('main.database.table', {
            url: '/database/:tableId',
            data: {
                action: 'DEFAULT'
            }
        })
        .state('main.database.table.newRecord', {
            url: '/records/new',
            data: {
                action: 'NEW_RECORD'
            }
        })
        .state('main.database.table.editRecord', {
            url: '/records/:recordId',
            data: {
                action: 'EDIT_RECORD'
            }
        })

    $urlRouterProvider.otherwise('/')
}
