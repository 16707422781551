import moment from 'moment-timezone';
import Drop from 'tether-drop';
import uid from 'uid';
import { DATE_SYSTEM_FORMAT } from '../services/constants'

export function mtDatePicker(DateHelper, $rootScope, $timeout, currentUser) {
    'ngInject';

    moment.locale(currentUser?.lang || 'en')

    return {
        restrict: 'E',
        replace: true,
        scope: {
            date: '=?',
            dateInterval: '=?',
            onClose: '&?',
            targetElement: '=?',
            isDisabled: '=?',
            pickerContentClass: '=?',
            dropEvent: '@?'
        },
        template: `
            <span class="prc-date-picker" ng-hide="true">
                <div class="prc-picker-content task-date-picker-content" ng-class="{empty: !date && !dateInterval}"
                     ng-click="$event.stopPropagation()">
                    <div ng-click="pickerClick($event)"
                         date-picker="pickerValue"
                         is-due="true"
                         watch-direct-changes="true"
                         min-view="date"
                         max-view="date"></div>
                    <div class="arrow"></div>
                    <ul class="buttons">
                        <li ng-click="setDueDate(0)">{{'date.today' | translate}}</li>
                        <li ng-click="setDueDate(1)">{{'date.tomorrow' | translate}}</li>
                        <li ng-click="setDueDate(2)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(2):'messageformat'}}</li>
                        <li ng-click="setDueDate(7)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(7):'messageformat'}}</li>
                        <li ng-click="setDueDate(14)">{{'label.in' | translate}} {{'plural.days' | translate:getIntervalStr(14):'messageformat'}}</li>
                        <li ng-click="setDueDate(1, true)">{{'date.inAMonth' | translate}}</li>
                    </ul>
                    <div ng-if="dateValue" class="clear-date">
                        <button class="btn-switcher btn-primary-switcher" ng-click="clearDate($event)">
                            <i class="icon-template_delete"></i>
                            {{'label.removeDueDate' | translate}}
                        </button>
                    </div>
                </div>
            </span>
        `,

        link: (scope, element) => {
            let tz = DateHelper.getTZ();
            let drop;
            let ID = uid(6);
            function destroyDrop() {
                if(drop) {
                    drop.destroy();
                    drop = undefined;
                }
            }
            function createDrop(targetElement) {
                if(!scope.pickerContent) {
                    scope.pickerContent = element.find('.prc-picker-content')[0];
                }
                destroyDrop();
                drop = new Drop({
                    target: targetElement,
                    content: scope.pickerContent,
                    position: 'bottom left',
                    openOn: 'click'
                });
                drop.on('close', () => {
                    scope.$broadcast('reset:datepicker');
                    scope.onClose({date: scope.date, customData: scope.customData});
                });

                drop.on('open', () => {
                    $rootScope.$broadcast('mt-date-picker:open', ID);
                });
                scope.$on('mt-date-picker:open', (event, openingId) => {
                    if (openingId && openingId !== ID && drop && drop.isOpened()) {
                        drop.close();
                    }
                });
                if(scope.isOpeningCalendar) {
                    $timeout(() => {
                        if(drop) {
                            if(!drop.isOpened()) {
                                drop.open();
                            }
                            scope.isOpeningCalendar = false;
                        }
                    });
                }
            }

            scope.$watch('targetElement', function(val) {
                if(val) {
                    createDrop(val);
                }
            });

            scope.$watch('isDisabled', val => {
                if(val) {
                    destroyDrop();
                } else if(scope.targetElement && !drop) {
                    createDrop(scope.targetElement);
                }
            });

            scope.$watch('pickerContentClass', (newVal, oldVal) => {
                if(newVal) {
                    angular.element(scope.pickerContent).addClass(newVal);
                } else if (oldVal) {
                    angular.element(scope.pickerContent).removeClass(oldVal);
                }
            });

            const init = () => {
                if (scope.date) {
                    scope.dateValue = true;
                    scope.pickerValue = moment.unix(scope.date).utc();
                }
                if (scope.dateInterval) {
                    scope.dateValue = true;
                    scope.pickerValue = moment.utc(moment().format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT)
                        .add(scope.dateInterval, 'days');
                }
                if(!scope.date && !scope.dateInterval) {
                    scope.dateValue = false;
                    if(scope.pickerValue) {
                        delete scope.pickerValue;
                    }
                }
            };

            init();

            scope.$watchGroup(['date', 'dateInterval'], () => init());

            scope.getIntervalStr = (count) => {
                return angular.toJson({COUNT: count || 0});
            };

            scope.$watch('pickerValue', newValue => {
                if (!newValue) {
                    return;
                }

                let chosen = moment(newValue);
                let dueTempDate = moment.utc(chosen.format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).unix();
                scope.date = dueTempDate > 0 ? dueTempDate : 0;
            });

            scope.pickerClick = (event) => {
                if (event.target.nodeName === 'SPAN') {
                    drop.close();
                }
            };

            scope.checkPastDue = () => {
                let utcDate = moment.unix(scope.date).utc()
                let tzDate = moment.utc().tz(tz)
                if (utcDate.year() !== tzDate.year()) {
                    return utcDate.year() < tzDate.year();
                }
                return utcDate.dayOfYear() < tzDate.dayOfYear();
            };

            scope.setDueDate = (count, isMonth) => {
                scope.pickerValue = moment.utc().tz(tz).add(count, isMonth ? 'months' : 'days');
                let chosen = moment(scope.pickerValue);
                let dueTempDate = moment.utc(chosen.format(DATE_SYSTEM_FORMAT), DATE_SYSTEM_FORMAT).unix();
                scope.date = dueTempDate > 0 ? dueTempDate : 0;
                drop.close();
            };

            scope.clearDate = () => {
                scope.date = null;
                if (scope.pickerValue) {
                    delete scope.pickerValue;
                }
                drop.close();
                scope.$applyAsync();
            };


            scope.$on('selectize:open', () => {
                if(drop) {
                    drop.close();
                }
            });

            scope.$on('$destroy', () => {
                destroyDrop();
            });

            if(scope.dropEvent) {
                scope.$on(scope.dropEvent, (event, options) => {
                    scope.date = options.date;
                    scope.dateInterval = options.dateInterval;
                    scope.customData = options.customData;
                    scope.pickerContentClass = options.pickerContentClass;
                    scope.targetElement = options.targetElement;
                    scope.isOpeningCalendar = true;
                });
            }
        }
    };
}
