export function typeName() {
    'ngInject';

    return function(input) {
        if (!input) {
            return input;
        }
        let str = input.toString().replace(/_/g, '-');
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
}