import config from '../config';
import messages from '../messages';

export function TranslateConfiguration($translateProvider) {
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
    $translateProvider.preferredLanguage('en');

    angular.forEach(messages, (value, key) => {
        $translateProvider.translations(key, value);
        // $translateProvider.translations(key, '');
    });

    //set for security purposes
    //see here: https://angular-translate.github.io/docs/#/guide/19_security
    $translateProvider.useSanitizeValueStrategy(config.sanitize_strategy);
}
