import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import ActionsDropdown from '../../../../components/react/dropdown/actions-dropdown.jsx'
import { translate } from '../../../react/utils'


function HeaderCell ({ column, columnState, isEditable, editAction, deleteAction, changeTitleAction, toggleFixedStateAction, enableReordering }) {
    return (
        <>
            {column.isTitle && (
                <div className="column-title-icon">
                    <i className="icon-title_badge"/>
                </div>
            )}
            <div className="label">{column.name}</div>
            {isEditable && (
                <ActionsDropdown key="actions" icon="icon-task_properties">
                    <ul className="actions-list">
                        <li className="actions-list-item" onClick={editAction}>
                            <i className="icon-template_create_field_editable"/> <span>{translate('label.edit')}</span>
                        </li>
                        {column.canBeTitle && (
                            <li className="actions-list-item" onClick={changeTitleAction}>
                                <i className="icon-title_badge"/> <span>{translate('label.useAsTitle')}</span>
                            </li>
                        )}
                        <li className="actions-list-item" onClick={toggleFixedStateAction}>
                            {columnState.isFixed ? (
                                <>
                                    <i className="icon-unlock"/> <span>{translate('label.unfreeze')}</span>
                                </>
                            ) : (
                                <>
                                    <i className="icon-lock"/> <span>{translate('label.freeze')}</span>
                                </>
                            )}
                        </li>
                        {!column.isTitle && (
                            <li className="actions-list-item" onClick={deleteAction}>
                                <i className="icon-delete_roundless"/> <span>{translate('label.delete')}</span>
                            </li>
                        )}
                        {(enableReordering && !columnState.isFixed && !column.isTitle) && (
                            <li className="actions-list-item" onClick={enableReordering}>
                                <i className="icon-template_create_dragable"/> <span>{translate('label.reorder')}</span>
                            </li>
                        )}
                    </ul>
                </ActionsDropdown>
            )}
        </>
    )
}

HeaderCell.propTypes = {
    style: PropTypes.object,
    isEditable: PropTypes.bool,
    column: PropTypes.object.isRequired,
    columnState: PropTypes.object.isRequired,
    editAction: PropTypes.func.isRequired,
    deleteAction: PropTypes.func,
    changeTitleAction: PropTypes.func,
    toggleFixedStateAction: PropTypes.func,
    enableReordering: PropTypes.func
}

export default observer(HeaderCell)
