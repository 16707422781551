import utils from '../modules/processes/utils';
import { checkFormulaSettingsError } from '../modules/templates/services/data-model-utils'
export function invalidDataModel() {
    'ngInject';

    return input => {
        if (!input || !input.list) {
            return null;
        }

        let fields = input.list.reduce((list, item) => list.concat(item.section.fieldsWithValues), []);
        return fields.filter(f => utils.checkInvalidNameValue(f.name.label, true) || utils.invalidSelectorField(f) || utils.invalidUserFieldSettingsField(f) || checkFormulaSettingsError(f));
    };
}
