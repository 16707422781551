import colors from '../../../libs/colors';

export function taskFormAssignee() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            actors: '=?',
            isProcess: '@?',
            isApproval: '=?'
        },
        template: `
            <div class="task-form-assignee" ng-class="{process: isProcess}">
                <div class="thumbnail" ng-style="{background: getColor(assignee.colorIndex)}" ng-if="assignee && !assignee.avatarUrl">
                    {{getUserName(assignee) | initials}}
                </div>
                <div class="avatar group" ng-if="!assignee && !isProcess" ng-class="{'approval': isApproval}">
                    <i class="icon-menu_checkmark"></i>
                    <div class="rectangle"></div>
                </div>
                <div class="avatar unassigned" ng-if="!assignee && isProcess">
                    <i class="icon-assignee_unassigned"></i>
                </div>
                <img class="avatar" ng-src="{{assignee.avatarUrl + '_40'}}" alt="" ng-if="assignee.avatarUrl"/>
            </div>
        `,
        link: (scope) => {
            scope.getColor = index => colors[index - 1];

            scope.getUserName = ({name, fullName, email}) => {
                return name || (fullName.trim() ? fullName : email);
            };

            scope.$watch('actors', (val) => {
                if (val && !val.specialRoles.length && !val.groups.length && val.users.length === 1 && !val.users[0].isDeleted) {
                    scope.assignee = val.users[0];
                } else {
                    scope.assignee = null;
                }
            }, true);
        }
    };
}
