/*global angular*/

export const FILE_ERRORS = {
    fileNameMinLength: 'fileNameMinLength',
    fileNameMaxLength: 'fileNameMaxLength'
};

export function PrintErrors($translate, ServerConfig) {
    'ngInject';
    let endMany = $translate.instant('validation.text.fillThemOut');
    let endOne = $translate.instant('validation.text.fillItOut');

    let getErrParams = (err, errFiles) => {
        let names = errFiles.filter(f => f.$error === err).map(file => {
            return file.name;
        }).join(', ');

        let data = {fileNames: names};
        if (err === 'maxSize') {
            data.size = Math.round(ServerConfig.fileMaxSize / 1024 / 1024);
        }

        return data;
    };

    return {
        getSumErrors: (form, title) => {
            let countOfTypeError = (formError, type) => {
                let count = 0;
                if(formError[type]) {
                    formError[type].forEach((innerFromError) => {
                        if (innerFromError.$error && innerFromError.$error[type] && innerFromError.$error[type].length) {
                            count = count + countOfTypeError(innerFromError.$error, type);
                        } else if (innerFromError.$error && innerFromError.$error[type] === true) {
                            count++;
                        }
                    });
                }
                return count;
            };
            if (!form || form.$valid) {
                return null;
            }
            let start = $translate.instant('validation.text.' + (title ? title : 'createTemplate')) + ' ';
            let array = [];
            let errorCount = 0;
            Object.keys(form.$error).forEach(error => {
                let count = countOfTypeError(form.$error, error);
                errorCount = errorCount + count;
                switch (error) {
                    case 'required':
                        array.push(
                            count === 1
                                ? 1 + ' ' + $translate.instant('validation.text.fieldIsRequired')
                                : count + ' ' + $translate.instant('validation.text.fieldsAreRequired')
                        );
                        break;
                    case 'minlength':
                        array.push(
                            count === 1
                                ? 1 + ' ' + $translate.instant('validation.text.fieldIsIncomplete')
                                : count + ' ' + $translate.instant('validation.text.fieldsAreIncomplete')
                        );
                        break;
                    case 'maxlength':
                        array.push(
                            count === 1
                                ? 1 + ' ' + $translate.instant('validation.text.fieldIsOutRange')
                                : count + ' ' + $translate.instant('validation.text.fieldsAreOutRange')
                        );
                        break;
                    case 'pattern':
                        array.push(
                            count === 1
                                ? 1 + ' ' + $translate.instant('validation.text.fieldWithIncorrectPattern')
                                : count + ' ' + $translate.instant('validation.text.fieldsWithIncorrectPattern')
                        );
                        break;
                    default:
                        if (angular.isArray(form.$error[error])) {
                            array = array.concat(form.$error[error]);
                        }
                }
            });
            if (!form.$error.required) {
                let count = 0;
                if (count > 0) {
                    errorCount = errorCount + count;
                    array.unshift(
                        count === 1
                            ? 1 + ' ' + $translate.instant('validation.text.fieldIsRequired')
                            : count + ' ' + $translate.instant('validation.text.fieldsAreRequired')
                    );
                }
            }
            return start + array.join(', ') + '. ' + (errorCount === 1 ? endOne : endMany);
        },
        getServerErrors: (serverErrors, title) => {
            let count = 0;
            let start = ' ';
            if (title) {
                start = $translate.instant('validation.text.' + title) + start;
            } else {
                start = $translate.instant('validation.text.createTemplate') + start;
            }

            let errorCb = errs => {
                if (angular.isObject(errs)) {
                    count = count + Object.keys(errs).length;
                } else {
                    count++;
                }
            };
            for (let error in serverErrors) {
                if (serverErrors.hasOwnProperty(error)) {
                    if (angular.isArray(serverErrors[error])) {  // Check if the field is an Array
                        serverErrors[error].forEach(errorCb);
                    } else if (error !== 'success') {
                        count++;
                    }
                }
            }
            return start + ' ' + count + ' ' + $translate.instant('validation.text.invalidFields')
                + ' ' + (count === 1 ? endOne : endMany);
        },
        printFilesErrors: (errFiles) => {
            let countPattern = 0;
            let countSize = 0;
            let countFileNameMinLength = 0;
            let countFileNameMaxLength = 0;
            let countOther = 0;

            errFiles.forEach(f => {
                if (f.$error === 'pattern') {
                    countPattern++;
                } else if (f.$error === 'maxSize') {
                    countSize++;
                } else if (f.$error === FILE_ERRORS.fileNameMinLength) {
                    countFileNameMinLength++;
                } else if (f.$error === FILE_ERRORS.fileNameMaxLength) {
                    countFileNameMaxLength++;
                } else {
                    countOther++;
                }
            });

            let strParts = [];
            if (countSize) {
                let messageId = countSize === 1 ? 'validation.text.fileMaxSize' : 'validation.text.filesMaxSize';
                strParts.push($translate.instant(messageId, getErrParams('maxSize', errFiles)));
            }
            if (countPattern) {
                let messageId = countSize === 1 ? 'validation.text.filePattern' : 'validation.text.filesPattern';
                strParts.push($translate.instant(messageId, getErrParams('pattern', errFiles)));
            }
            if (countFileNameMinLength) {
                let messageId = countSize === 1 ? 'validation.text.fileNameMinLength' : 'validation.text.filesNamesMinLength';
                strParts.push($translate.instant(messageId, getErrParams(FILE_ERRORS.fileNameMinLength, errFiles)));
            }
            if (countFileNameMaxLength) {
                let messageId = countSize === 1 ? 'validation.text.fileNameMaxLength' : 'validation.text.filesNamesMaxLength';
                strParts.push($translate.instant(messageId, getErrParams(FILE_ERRORS.fileNameMaxLength, errFiles)));
            }
            if (countOther) {
                let messageId = countSize === 1 ? 'validation.text.fileNotUpload' : 'validation.text.filesNotUpload';
                strParts.push($translate.instant(messageId, getErrParams('upload', errFiles)));
            }
            return strParts.join(' ');
        }
    };
}
