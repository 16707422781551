export default {
    getInitials: input => {
        if (!input) {
            return input
        }
        let words = input.split(' ')
        if (words.length) {
            if (words.length > 1) {
                return words[0].charAt(0) + words[words.length - 1].charAt(0)
            }
            return words[0].charAt(0)
        }
    },
    checkPermission: (obj, permission) => {
        return obj && obj.permissions && obj.permissions.indexOf(permission) !== -1
    }
}

export function getService (serviceName) {
    const injector = angular.element(document.body).injector()
    return injector.get(serviceName)
}

export function translate (string, params, useMessageFormat) {
    const text = getService('$translate').instant(string, params, useMessageFormat ? 'messageformat' : undefined)
    return getService('$filter')('parseSymbols')(text)
}
