// import { templatesRoute } from './templates.config.js'
import { ConditionsConfig } from './services/conditions-config'
import { TemplatesController } from './controllers/templates.controller.js'
import { taskGroupConditions } from './directives/task-group-conditions.js'
import { taskGroupConditionsViewer } from './directives/task-group-conditions-viewer.js'
import { taskGroupConditionsHint } from './directives/task-group-conditions-hint.js'
import { conditionFieldSelector } from './directives/condition-field-selector.js'

import './less/templates.less'

let ngModule = angular.module('templates', [])
    // .config(templatesRoute)
    .controller('TemplatesController', TemplatesController)
    .directive('taskGroupConditions', taskGroupConditions)
    .directive('conditionFieldSelector', conditionFieldSelector)
    .directive('taskGroupConditionsViewer', taskGroupConditionsViewer)
    .directive('taskGroupConditionsHint', taskGroupConditionsHint)
    .factory('ConditionsConfig', ConditionsConfig)

export default ngModule
