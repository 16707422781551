import { profileRoute } from './profile.config.js'

import { selectTimezone } from './directives/select-timezone.js'
import { profileInfoPopup } from './directives/profile-info-popup.js'

import { ProfileController } from './controllers/profile.controller.js'
import { ProfileMainController } from './controllers/profileMain.controller.js'
import { NotificationsController } from './controllers/notifications.controller.js'
import { ProfilePasswordController } from './controllers/profilePassword.controller.js'
import { IntegrationsController } from './controllers/integrations.controller'
import { ProfileDataService } from './services/profile-data-service'

import './less/index.less'

let ngModule = angular.module('profile', [])
    .config(profileRoute)

    .controller('ProfileController', ProfileController)
    .controller('ProfileMainController', ProfileMainController)
    .controller('NotificationsController', NotificationsController)
    .controller('ProfilePasswordController', ProfilePasswordController)
    .controller('IntegrationsController', IntegrationsController)

    .factory('ProfileDataService', ProfileDataService)

    .directive('profileInfoPopup', profileInfoPopup)
    .directive('selectTimezone', selectTimezone)

export default ngModule
