import Drop from 'tether-drop';
import { getFormattedRecordValues } from '../../database/models/database-utils'
import { DATE_AND_TIME } from '../../../services/data-types'

const baseClass = 'task-group-conditions-hint';
const SelectDropdown = Drop.createContext({classPrefix: `${baseClass}-drop-tether`});

export function taskGroupConditionsHint($translate, ConditionsConfig, DateHelper) {
    'ngInject';

    return {
        restrict: 'E',
        scope: true,
        bindToController: {
            item: '<',
            formFields: '<',
            isGroup: '@?'
        },
        template: `
<div class="${baseClass}">
    <span class="${baseClass}__trigger conditions-marker__icon" ng-mouseenter="conditionsHintCtrl.open()" ng-mouseleave="conditionsHintCtrl.close()">
        <i class="icon-common_hint"></i>
    </span>
    <div class="${baseClass}__content">
        <div class="summary">{{::conditionsHintCtrl.getSummary() | translate}}:</div>
        <ul class="conditions">
            <li ng-repeat="condition in conditionsHintCtrl.item.conditions.list">
                <span class="match-status">
                    <i class="icon-menu_checkmark match-found" ng-if="condition.matches == true"></i>
                    <i class="icon-common_close match-not-found" ng-if="condition.matches == false"></i>
                    <span class="bullet" ng-if="condition.matches === undefined"></span>
                </span>
                <span class="field-label">{{::condition.fieldLabel}}</span>
                <span class="operation">{{::conditionsHintCtrl.getOperationLabel(condition)}}</span>
                <span class="expression" ng-hide="!condition.expression">{{::conditionsHintCtrl.getExpressionValue(condition)}}</span>
            </li>
        </ul>
        <span class="arrow-top"></span>
        <span class="arrow-bottom"></span>
    </div>
</div>
        `,
        controllerAs: 'conditionsHintCtrl',
        controller: function() {
            this.formFields = [];
            this.isGroup = false;

            this.getSummary = () => {
                if (this.item.conditions.rule === ConditionsConfig.OPERATORS.AND) {
                    return this.isGroup
                        ? 'taskConditions.processView.groupSummary.and'
                        : 'taskConditions.processView.taskSummary.and';

                }

                if (this.item.conditions.rule === ConditionsConfig.OPERATORS.OR) {
                    return this.isGroup
                        ? 'taskConditions.processView.groupSummary.or'
                        : 'taskConditions.processView.taskSummary.or';
                }
            };

            this.getOperationLabel = (condition) => {
                const operation = ConditionsConfig.AVAILABLE_OPERATIONS.find(item => item.id === condition.operation);
                return operation ? $translate.instant(operation.label) : '';
            };

            this.getExpressionValue = (condition) => {
                const {expression} = condition;
                const formField = this.formFields.find(field => field.id === condition.fieldId);
                const dataType = formField && formField.name.dataType ? formField.name.dataType : condition.dataType;

                if (expression && expression.number !== undefined) {
                    return expression.number;
                }
                if (expression && expression.string !== undefined) {
                    return expression.string;
                }
                if (expression && expression.record !== undefined && expression.record.recordId !== undefined) {
                    return getFormattedRecordValues(expression.record).name
                }
                if (expression && expression.date !== undefined && dataType === DATE_AND_TIME) {
                    return DateHelper.timestampToDateTime(expression.date);
                }
                if (expression && expression.date !== undefined) {
                    return DateHelper.timestampToDate(expression.date);
                }

                return '';
            };

            this.itemHasConditions = () => {
                return this.item.conditions && this.item.conditions.list.length;
            };

            this.createDropdown = (element) => {
                if (this.itemHasConditions()) {
                    this.dropdownTarget = element.find(`.${baseClass}__trigger`)[0];
                    this.dropdownContent = element.find(`.${baseClass}__content`)[0];
                    this.dropdown = new SelectDropdown({
                        target: this.dropdownTarget,
                        content: this.dropdownContent,
                        position: 'bottom center',
                        openOn: null,
                        constrainToScrollParent: false
                    });
                }
            };

            this.destroyDropdown = () => {
                if (this.dropdown) {
                    this.dropdown.destroy();
                    this.dropdown = null;
                }
            };

            this.open = () => {
                this.isOpened = true;
                if (this.dropdown && !this.dropdown.isOpened()) {
                    this.dropdown.open();
                }
            };

            this.close = () => {
                this.isOpened = false;
                if (this.dropdown) {
                    this.dropdown.remove();
                }
            };
        },
        link: (scope, element, attrs, ctrl) => {
            ctrl.createDropdown(element);
            scope.$on('$destroy', () => ctrl.destroyDropdown());
        }
    };
}
