import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import cn from 'classnames'
import { BUTTONS } from '../../services/constants'
import { translate } from '../../modules/react/utils'

const PRESETS = {
    'table': {
        title: 'table.deleteConfirmation.title',
        text: 'table.deleteConfirmation.text',
        passphrase: 'table'
    }
}

class TypedConfirmation extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        isOpen: PropTypes.bool,
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func
    }

    constructor (props) {
        super(props)

        this.state = {
            value: ''
        }

        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.updateValue = this.updateValue.bind(this)
        this.onKeypress = this.onKeypress.bind(this)
    }

    render () {
        const { type, isOpen } = this.props
        const { value } = this.state
        const { title, text, passphrase } = PRESETS[type]
        const isConfirmed = this.isConfirmed()
        const hasError = value.length > passphrase.length || (value.length && !passphrase.includes(value))

        return (
            <ReactModal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={this.onCancel}
                overlayClassName="modal fade in"
                className={cn('confirm-alt-modal modal-dialog')}
                style={{ overlay: { zIndex: 1040 } }}
            >
                <div className="modal-content">
                    <div className="dialog-img">
                        <img src="/assets/icons/dialogs_warning.svg"/>
                    </div>
                    <div className="dialog-content">
                        <div className="modal-header">
                            <i className="icon-common_close" onClick={this.onCancel}/>
                            <h3>{translate(title)}</h3>
                        </div>
                        <div className="modal-body">
                            <p>{translate(text)}</p>
                            <div className={cn('typed-confirmation', { 'has-error': hasError })}>
                                <input
                                    type="text"
                                    value={value}
                                    onChange={this.updateValue}
                                    onKeyDown={this.onKeypress}
                                    placeholder={passphrase}
                                    autoFocus
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className={cn('btn btn-red', { disabled: !isConfirmed })}
                                disabled={!isConfirmed}
                                onClick={this.onConfirm}
                            >
                                {translate('label.confirm')}
                            </button>
                            <button
                                type="button"
                                className="btn-switcher btn-primary-switcher"
                                onClick={this.onCancel}
                            >
                                {translate('label.cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        )
    }

    isConfirmed () {
        return this.state.value === PRESETS[this.props.type].passphrase
    }

    updateValue (event) {
        this.setState({ value: event.target.value })
    }

    onConfirm () {
        if (this.isConfirmed()) {
            this.props.onConfirm()
        }
    }

    onCancel () {
        this.setState({ value: '' })
        this.props.onCancel()
    }

    onKeypress (event) {
        const { key } = event

        if (key === BUTTONS.ENTER) {
            event.preventDefault()
            return this.onConfirm()
        }

        if (key === BUTTONS.ESCAPE) {
            event.preventDefault()
            return this.props.onCancel()
        }
    }
}

export default TypedConfirmation
