export class ModalRejectReasonController {
    constructor ($scope, $modalInstance, ApiCalls) {
        'ngInject'
        $scope.isRejecting = false
        $scope.isDirtyRejectReasonEmpty = false

        $scope.reject = () => {
            if ($scope.isRejecting) {
                return
            }
            $scope.isRejecting = true
            let apiAction = $scope.isForceReject ? ApiCalls.forceRejectTask : ApiCalls.rejectTask
            if ($scope.isReviewAction) {
                $scope.task.reviewNote = $scope.task.rejectReason
                apiAction = ApiCalls.reviewTask
            }
            apiAction($scope.task).then(resp => {
                $modalInstance.close(resp)
            })
                .catch(errorResp => {
                    if (errorResp.status === 400) {
                        $scope.serverErrorData = errorResp.data
                    } else {
                        $modalInstance.close(errorResp)
                    }
                })
                .finally(() => {
                    $scope.isRejecting = false
                    if ($scope.isReviewAction) {
                        delete $scope.task.rejectReason
                    }
                })
        }
        $scope.$watch('form["reject-reason"].$dirty && !task.rejectReason && !task.rejectReason.trim()', val => {
            $scope.isDirtyRejectReasonEmpty = val
        })

        $scope.cancel = () => {
            $modalInstance.dismiss()
        }
    }
}
