export function DeferredAction(PageSettings, ApiCalls, AdminApiCalls, $q, $timeout, $rootScope, $translate) {
    'ngInject';

    let undoDeletionText = $translate.instant('undoDeletion.text');
    let undoDeletionLinkText = $translate.instant('undoDeletion.link');

    const TIMEOUT_DELAY = 2 * 1000;

    function DeferredActionConstructor(actionHandler, objType,  canceledEvent, completedEvent) {

        let infoForDeferredAction = [];
        let actionPromise = $q.resolve();

        let skipDeferred = {};
        let actionsWaitingInChain = {};

        let addDeferredAction = obj => {
            if(obj) {
                PageSettings.deferredActionsInfo.push({
                    obj: obj,
                    type: objType,
                    text: `${obj.name} ${undoDeletionText}`,
                    linkText: undoDeletionLinkText
                });
            }
        };
        let removeDeferredAction = id => {
            if(id) {
                let index = PageSettings.deferredActionsInfo.findIndex(dai => dai.obj.id === id);
                if(index !== -1) {
                    PageSettings.deferredActionsInfo.splice(index, 1);
                }
            }
        };

        let delayedActionHandler = (obj) => {
            if(skipDeferred[obj.id]) {
                delete skipDeferred[obj.id];
                delete actionsWaitingInChain[obj.id];
                return $q.resolve();
            }
            if (obj.id && obj.version) {
                let failed;
                return actionHandler(obj)
                    .then((resp) => $rootScope.$broadcast(this.CONST.COMPLETED, obj, resp))
                    .catch((resp) => {
                        failed = true;
                        PageSettings.errorHandlerModal(resp);
                    })
                    .finally(() => {
                        if (failed) {
                            this.cancelDeferred(obj);
                            delete skipDeferred[obj.id];
                            delete actionsWaitingInChain[obj.id];
                        } else {
                            let info = infoForDeferredAction.find(iDel => iDel.obj.id === obj.id);
                            if (info) {
                                removeDeferredAction(info.obj.id);
                            }
                        }
                    });
            } else {
                return $q.reject();
            }
        };

        let delayedActionHandlerChain = (obj) => {
            if(actionsWaitingInChain[obj.id]) {
                return $q.resolve();
            }
            actionsWaitingInChain[obj.id] = true;
            actionPromise = actionPromise.finally(() => delayedActionHandler(obj));
            return actionPromise;
        };

        this.CONST = {
            CANCELED: canceledEvent,
            COMPLETED: completedEvent
        };
        Object.freeze(this.CONST);

        this.addToDeferred = function(obj) {
            if(infoForDeferredAction.every(iDel => iDel.obj.id  !== obj.id)) {
                let tPromise = $timeout(delayedActionHandlerChain, TIMEOUT_DELAY, true, obj);
                infoForDeferredAction.push({
                    obj: obj,
                    type: objType,
                    timeoutPromise: tPromise
                });
                addDeferredAction(obj);
                return tPromise;
            } else {
                $q.reject(obj);
            }
        };
        this.cancelDeferred = (obj) => {
            let index = infoForDeferredAction.findIndex(iDel => iDel.obj.id === obj.id);
            if(index !== -1) {
                skipDeferred[obj.id] = true;
                let cancelledObj = infoForDeferredAction.splice(index, 1)[0].obj;
                removeDeferredAction(cancelledObj.id);
                $rootScope.$broadcast(this.CONST.CANCELED);
            }
        };
        this.getAllDeferred = function() {
            return infoForDeferredAction.map(iDel => iDel.obj);
        };
        let immediatelyDeleting = {};
        this.immediately = function(obj) {
            if(immediatelyDeleting[obj.id]) {
                return;
            }
            immediatelyDeleting[obj.id] = true;
            let index = infoForDeferredAction.findIndex(iDel => iDel.obj.id === obj.id);
            if(index !== -1) {
                let defObj = infoForDeferredAction[index].obj;
                let imPromise = delayedActionHandlerChain(defObj);
                removeDeferredAction(infoForDeferredAction[index].obj);
                return imPromise;
            } else {
                return delayedActionHandlerChain(obj);
            }
        };
        this.isDeferred = function(obj) {
            return infoForDeferredAction.some(iDel => iDel.obj.id === obj.id);
        };
    }
    const GROUP_DELETE = 'GroupDelete';
    const TASK_DELETE = 'TaskDelete';

    let deferredActionService = {
        CONST: {
            UNDO_EVENT: 'deferred-action:undo-event',
            CLOSE_POPUP_EVENT: 'deferred-action:close-popup-event'
        },
        TaskDelete: new DeferredActionConstructor(task => {
            return ApiCalls.deleteTask(task.id, task.version);
        }, TASK_DELETE, 'deferred-action:task-delete:cancel', 'deferred-action:task-delete:complete'),
        GroupDelete: new DeferredActionConstructor(group => {
            return AdminApiCalls.deleteGroup(group.id, group.version);
        }, GROUP_DELETE, 'deferred-action:group-delete:cancel', 'deferred-action:group-delete:complete')
    };
    Object.freeze(deferredActionService.CONST);

    $rootScope.$on(deferredActionService.CONST.UNDO_EVENT, (event, actionInfo) => {
        deferredActionService[actionInfo.type].cancelDeferred(actionInfo.obj);
    });
    $rootScope.$on(deferredActionService.CONST.CLOSE_POPUP_EVENT, (event, actionInfo) => {
        deferredActionService[actionInfo.type].immediately(actionInfo.obj);
    });

    return deferredActionService;
}
