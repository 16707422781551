import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import request from 'superagent'
import cn from 'classnames'

import config from '../../../config'
import { getService, translate } from '../../../modules/react/utils'
import { validateAttachedFiles } from '../../../utils'
import FilePreview from '../file-preview.jsx'
import { FileValue } from '../../../modules/database/models/datatypes'

import './files-list.less'

class FilesList extends React.Component {
    static propTypes = {
        value: PropTypes.array,
        maxFiles: PropTypes.number,
        accept: PropTypes.string,
        onChange: PropTypes.func,
        onStartUploading: PropTypes.func,
        onCompleteUploading: PropTypes.func,
        disabled: PropTypes.bool
    }

    constructor (props) {
        super(props)

        this.ServerConfig = getService('ServerConfig')
        this.ApiCalls = getService('ApiCalls')

        this.state = {
            tempFiles: [],
            progress: {}
        }
    }

    render () {
        const { accept, maxFiles, disabled } = this.props
        const isLimited = this.getAvailableMaxFiles() < 1

        return (
            <div>
                <Dropzone
                    onDrop={this.onDrop}
                    maxSize={this.ServerConfig.fileMaxSize}
                    accept={accept}
                    multiple
                    disabled={disabled}
                    noClick={isLimited}
                >
                    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                        <div {...getRootProps({
                            className: cn('dropzone', {
                                'active': isDragActive,
                                'accept': isDragAccept,
                                'reject': isDragReject,
                                'limited': isLimited || disabled
                            })
                        })}>
                            <input {...getInputProps()} />
                            <i className="icon-task_attachment"/>
                            {this.renderFiles()}
                        </div>
                    )}
                </Dropzone>
                <div className="dropzone-hint">
                    {translate('plural.text.filesLimit', {COUNT: maxFiles}, true)}
                    {' '}
                    {isLimited ? translate('validation.text.filesLimitReached') : ''}
                </div>
            </div>
        )
    }

    renderFiles = () => {
        const files = this.getFiles()
        return files.length ? (
            <ul>
                {this.getFiles().map(file => (
                    <li
                        key={file.id || file.fileName}
                        onClick={e => e.stopPropagation()}
                        className={cn({ 'uploading': !file.url })}
                    >
                        {this.renderFileItem(file)}
                    </li>
                ))}
            </ul>
        ) : (
            <span className="placeholder">{translate('text.clickToAttachFile')}</span>
        )
    }

    renderFileItem = file => {
        const { disabled } = this.props
        return (
            <React.Fragment>
                <i className="icon-attachment_document"/>
                {file.url ? (
                    <FilePreview file={file} gallery={this.props.value}/>
                ) : (
                    <span>{file.name}</span>
                )}
                {!disabled && (
                    <i className="icon-common_close" onClick={(e) => this.removeFile(e, file.fileName)}/>
                )}
            </React.Fragment>
        )
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const [files, errFiles] = validateAttachedFiles(acceptedFiles, rejectedFiles, config)
        const availableMaxFiles = this.getAvailableMaxFiles()

        if (availableMaxFiles > 0) {
            const newFiles = files
                .filter(this.fileIsUnique)
                .slice(0, availableMaxFiles)
            const newFilesModels = newFiles.map(f => FileValue.create({ fileName: f.name }))
            const { tempFiles } = this.state

            this.setState({ tempFiles: [...tempFiles, ...newFilesModels] })
            this.generateUploadPolicy(newFiles)
        }
    }

    getFiles = () => {
        const { value = [] } = this.props
        const { tempFiles } = this.state

        return [...value, ...tempFiles]
    }

    fileIsUnique = ({ name }) => {
        return !this.getFiles().find(file => file.fileName === name)
    }

    generateUploadPolicy = files => {
        const policyData = files.map(file => ({
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type
        }))

        if (policyData.length) {
            this.ApiCalls.generateFilesUploadPolicy(policyData).then(response => {
                if (response.success) {
                    this.uploadFiles(files, response.result)
                } else {
                    this.catchPolicyRequestError()
                }
            }, this.catchPolicyRequestError)
        }
    }

    catchPolicyRequestError = () => {
        console.log('policy error')
        // this.serverError = $translate.instant('validation.text.getPolicy')
    }

    getAvailableMaxFiles = () => {
        const { maxFiles } = this.props
        return Math.max(maxFiles - this.getFiles().length, 0)
    }

    uploadFiles = (files, policies) => {
        const { onStartUploading } = this.props

        if (onStartUploading) {
            onStartUploading()
        }

        files.forEach((file, fileIndex) => {
            const filePolicy = policies[fileIndex]
            file.uploading = new Promise((resolve, reject) => {
                request
                    .post(`https://${filePolicy.bucket}.s3.amazonaws.com/`)
                    .field('key', filePolicy.key)
                    .field('AWSAccessKeyId', filePolicy.accessKey)
                    .field('acl', filePolicy.acl)
                    .field('policy', filePolicy.policy)
                    .field('signature', filePolicy.signature)
                    .field('x-amz-server-side-encryption', filePolicy.xAmzServerSideEncryption)
                    .field('x-amz-credential', filePolicy.xAmzCredential)
                    .field('x-amz-algorithm', filePolicy.xAmzAlgorithm)
                    .field('x-amz-date', filePolicy.xAmzDate)
                    .field('Content-Type', filePolicy.contentType)
                    .field('Content-Disposition', filePolicy.contentDisposition)
                    .field('filename', filePolicy.fileName)
                    .attach('file', file)
                    .on('progress', event => {
                        const { progress } = this.state
                        /* the event is:
                        {
                          direction: "upload" or "download"
                          percent: 0 to 100 // may be missing if file size is unknown
                          total: // total file size, may be missing
                          loaded: // bytes downloaded or uploaded so far
                        } */
                        progress[filePolicy.fileName] = Math.min(100, parseInt(100.0 * event.loaded / event.total))
                        this.setState({ progress })
                    })
                    .then(res => {
                            const { value } = this.props
                            const { id, fileName } = filePolicy

                            if (res.status >= 200 && res.status <= 204) {
                                if (!this.getFiles().find(f => f.fileName === fileName)) {
                                    // File was deleted during uploading
                                    this.ApiCalls.deleteFile(id)
                                    return resolve()
                                }

                                const fileValue = FileValue.create({
                                    id,
                                    fileName,
                                    hashToken: res.headers.etag.replace(/"/g, ''),
                                    creationDate: getService('MomentHelper').getUTCTimestampFromString(res.headers.date)
                                })

                                fileValue.loadInfo(() => {
                                    const changedTempFiles = this.state.tempFiles.map(f => {
                                        if (f.fileName === fileName) {
                                            return fileValue
                                        }
                                        return f
                                    })

                                    this.setState({
                                        tempFiles: changedTempFiles
                                    }, () => {
                                        this.applyChanges([...value, ...changedTempFiles.filter(f => f.url)])
                                        resolve()
                                    })
                                })
                            } else {
                                console.log('Error while uploading (2)')
                                // let copy = angular.copy(file)
                                // copy.$error = 'upload'
                                // this.errFiles.push(copy)
                                resolve()
                            }
                        },
                        response => {
                            console.log('Error while uploading')
                            // if (response.data && response.data.displayError) {
                            //     this.serverError = response.data.displayError
                            // } else {
                            //     this.serverError = $translate.instant('validation.text.commonUpload')
                            // }
                            // this.field.uploading = false
                            reject()
                        }
                    )
            })
        })
    }

    removeFile = (e, fileName) => {
        e.stopPropagation()
        const file = this.getFiles().find(f => f.fileName === fileName)
        if (!file) {
            return
        }

        const { value, onCompleteUploading } = this.props
        const { tempFiles } = this.state
        const fileIsAlreadySaved = value.find(f => f.fileName === fileName)

        if (fileIsAlreadySaved) {
            const updatedValue = value.filter(f => f.fileName !== fileName)
            this.applyChanges(updatedValue, true)
            return
        }

        if (file.id) {
            this.ApiCalls.deleteFile(file.id)
        }

        const updatedTempFiles = tempFiles.filter(f => f.fileName !== fileName)
        const isComplete = updatedTempFiles.filter(f => !f.url).length === 0
        this.setState({ tempFiles: updatedTempFiles })

        if (isComplete) {
            onCompleteUploading && onCompleteUploading()
        }
    }

    applyChanges = (value, forceApply) => {
        const { onChange, onCompleteUploading } = this.props
        const { tempFiles } = this.state
        const isComplete = tempFiles.filter(f => !f.url).length === 0

        if (forceApply) {
            onChange(value)
            return
        }

        if (isComplete) {
            onChange(value)
            onCompleteUploading && onCompleteUploading()
            this.setState({ tempFiles: [] })
        }
    }
}

export default FilesList
