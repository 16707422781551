export function helpersRoute($stateProvider, $urlRouterProvider) {
    'ngInject';

    $stateProvider
        .state('confirm', {
            url: '/confirm/:mailToken',
            template: require('./index.html'),
            controller: 'HelpersController',
            controllerAs: 'ctrl',
            params: {
                mailToken: null
            },
            resolve: {
                info: (ApiCalls, $stateParams, $state) => {
                    'ngInject'
                    if ($stateParams.mailToken) {
                        return ApiCalls.getConfirm($stateParams.mailToken)
                            .catch(errorResp => {
                                $state.go('error', {message: errorResp.data.displayError || errorResp.data.error});
                            });
                    }
                    return {success: false};
                }
            }
        });

    $urlRouterProvider.otherwise('/');
}
