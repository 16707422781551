export function multilineEllipsis($timeout, $window, $sce) {
    'ngInject';

    const AsyncDigest = function(delay) {
        let timeout = null;
        let queue = [];

        this.remove = function(fn) {
            if (queue.indexOf(fn) !== -1) {
                queue.splice(queue.indexOf(fn), 1);
                if (queue.length === 0) {
                    $timeout.cancel(timeout);
                    timeout = null;
                }
            }
        };
        this.add = function(fn) {
            if (queue.indexOf(fn) === -1) {
                queue.push(fn);
            }
            if (!timeout) {
                timeout = $timeout(function() {
                    let copy = queue.slice();
                    timeout = null;
                    // reset scheduled array first in case one of the functions throws an error
                    queue.length = 0;
                    copy.forEach(fnCb => fnCb());
                }, delay);
            }
        };
    };

    let asyncDigestImmediate = new AsyncDigest(150);
    let asyncDigestDebounced = new AsyncDigest(300);

    return {
        restrict: 'A',
        scope: {
            ngBind: '=?',
            ngBindHtml: '=?'
        },
        compile: function() {
            return (scope, element, attrs) => {
                /* Window Resize Variables */
                attrs.lastWindowResizeTime = 0;
                attrs.lastWindowResizeWidth = 0;
                attrs.lastWindowResizeHeight = 0;
                /* State Variables */
                attrs.isTruncated = false;

                function getParentHeight(el) {
                    let heightOfChildren = 0;
                    el.parent().children().forEach(child => {
                        if (child !== el[0]) {
                            heightOfChildren = heightOfChildren + child.clientHeight;
                        }
                    });
                    return el.parent()[0].clientHeight - heightOfChildren;
                }

                function isOverflowed(thisElement, useParent) {
                    thisElement = useParent ? thisElement.parent() : thisElement;
                    return thisElement[0].scrollHeight > thisElement[0].clientHeight;
                }

                function buildEllipsis() {
                    let binding = scope.ngBind || scope.ngBindHtml;
                    let isTrustedHTML = false;

                    if ($sce.isEnabled() && angular.isObject(binding) && $sce.getTrustedHtml(binding)) {
                        isTrustedHTML = true;
                        binding = $sce.getTrustedHtml(binding);
                    }
                    if (binding) {
                        let isHtml = (!(scope.ngBind) && !!(scope.ngBindHtml));
                        let i = 0;
                        let ellipsisSymbol = (typeof(attrs.ellipsisSymbol) !== 'undefined') ? attrs.ellipsisSymbol : '&hellip;';
                        let ellipsisSeparator = (typeof(scope.ellipsisSeparator) !== 'undefined') ? attrs.ellipsisSeparator : ' ';
                        let ellipsisSeparatorReg = (typeof(scope.ellipsisSeparatorReg) !== 'undefined') ? scope.ellipsisSeparatorReg : false;
                        let appendString = (typeof(scope.ellipsisAppend) !== 'undefined' && scope.ellipsisAppend !== '')
                            ? ellipsisSymbol + '<span class="angular-ellipsis-append">' + scope.ellipsisAppend + '</span>'
                            : ellipsisSymbol;
                        let bindArray = ellipsisSeparatorReg ? binding.match(ellipsisSeparatorReg) : binding.split(ellipsisSeparator);

                        attrs.isTruncated = false;
                        if (isHtml) {
                            element.html(binding);
                        } else {
                            element.text(binding);
                        }

                        // If text has overflow
                        if (isOverflowed(element, scope.useParent)) {
                            let bindArrayStartingLength = bindArray.length;
                            let initialMaxHeight = scope.useParent ? getParentHeight(element) : element[0].clientHeight;

                            if (isHtml) {
                                element.html(binding + appendString);
                            } else {
                                element.text(binding).html(element.html() + appendString);
                            }
                            //Set data-overflow on element for targeting
                            element.attr('data-overflowed', 'true');

                            // Set complete text and remove one word at a time, until there is no overflow
                            for (; i < bindArrayStartingLength; i++) {
                                let current = bindArray.pop();

                                //if the last string still overflowed, then truncate the last string
                                if (bindArray.length === 0) {
                                    bindArray[0] = current.substring(0, Math.min(current.length, 5));
                                }

                                if (isHtml) {
                                    element.html(bindArray.join(ellipsisSeparator) + appendString);
                                } else {
                                    element.text(bindArray.join(ellipsisSeparator)).html(element.html() + appendString);
                                }

                                if ((scope.useParent ? element.parent()[0] : element[0]).scrollHeight < initialMaxHeight || isOverflowed(element, scope.useParent) === false) {
                                    attrs.isTruncated = true;
                                    break;
                                }
                            }

                            if (!isTrustedHTML && $sce.isEnabled()) {
                                $sce.trustAsHtml(binding);
                            }
                        } else {
                            element.attr('data-overflowed', 'false');
                        }
                    }
                }

                /**
                 *	Watchers
                 */

                /**
                 *	Execute ellipsis truncate on ngShow update
                 */
                scope.$watch('ngShow', function() {
                    asyncDigestImmediate.add(buildEllipsis);
                });

                /**
                 *	Execute ellipsis truncate on ngBind update
                 */
                scope.$watch('ngBind', function() {
                    asyncDigestImmediate.add(buildEllipsis);
                });

                /**
                 *	Execute ellipsis truncate on ngBindHtml update
                 */
                scope.$watch('ngBindHtml', function() {
                    asyncDigestImmediate.add(buildEllipsis);
                });

                /**
                 *	Execute ellipsis truncate on ngBind update
                 */
                scope.$watch('ellipsisAppend', function() {
                    buildEllipsis();
                });

                /**
                 *	Execute ellipsis truncate when element becomes visible
                 */
                scope.$watch(() => element[0].offsetWidth !== 0 && element[0].offsetHeight !== 0, () => {
                    asyncDigestDebounced.add(buildEllipsis);
                });

                function checkWindowForRebuild() {
                    if (attrs.lastWindowResizeWidth !== window.innerWidth || attrs.lastWindowResizeHeight !== window.innerHeight) {
                        buildEllipsis();
                    }

                    attrs.lastWindowResizeWidth = window.innerWidth;
                    attrs.lastWindowResizeHeight = window.innerHeight;
                }

                /**
                 *	When window width or height changes - re-init truncation
                 */

                function onResize() {
                    asyncDigestDebounced.add(checkWindowForRebuild);
                }

                let $win = angular.element($window);
                $win.bind('resize', onResize);

                asyncDigestDebounced.add(buildEllipsis);

                /**
                 * Clean up after ourselves
                 */
                scope.$on('$destroy', function() {
                    $win.unbind('resize', onResize);
                    asyncDigestImmediate.remove(buildEllipsis);
                    asyncDigestDebounced.remove(checkWindowForRebuild);
                });
            };
        }
    };
}
