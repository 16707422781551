// Generated from FormulaParser.g4 by ANTLR 4.9.2
// jshint ignore: start
import antlr4 from '../antlr4'

// This class defines a complete generic visitor for a parse tree produced by FormulaParser.

export default class FormulaParserVisitor extends antlr4.tree.ParseTreeVisitor {

    // Visit a parse tree produced by FormulaParser#fieldExp.
    visitFieldExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#mulDivExp.
    visitMulDivExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#numericExp.
    visitNumericExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#parenthesisExp.
    visitParenthesisExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#addSubExp.
    visitAddSubExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#totalExp.
    visitTotalExp (ctx) {
        return this.visitChildren(ctx)
    }

    // Visit a parse tree produced by FormulaParser#resultExpression.
    visitResultExpression (ctx) {
        return this.visitChildren(ctx)
    }

}
