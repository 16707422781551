import {BUTTONS} from '../services/constants';

const EVENT_TYPE = 'keydown';

export class ModalInstanceController {
    constructor($scope, $modalInstance, $document, $timeout) {
        'ngInject';
        $scope.ok = () => {
            if ($scope.confirmed()) {
                $modalInstance.close();
            }
        };

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        const handleKeypress = (event) => {
            const {key} = event;

            if (key === BUTTONS.ENTER && $scope.typedConfirmation) {
                event.preventDefault();
                return $scope.ok();
            }

            if (key === BUTTONS.ESCAPE) {
                event.preventDefault();
                return $scope.cancel();
            }
        };

        $document.on(EVENT_TYPE, handleKeypress);

        $modalInstance.rendered.then(() => {
            $timeout(() => $document.find('#typedConfirmationInput').focus(), 100);
        });

        $scope.$on('$destroy', () => {
            $document.off(EVENT_TYPE, handleKeypress);
        });

        $scope.confirmed = () => {
            return $scope.typedConfirmation
                ? $scope.typedConfirmation.requested === $scope.typedConfirmation.entered
                : true;
        };

        $scope.hasError = () => {
            const {requested, entered} = $scope.typedConfirmation || {};
            return entered.length > requested.length || (entered.length && !requested.includes(entered));
        };
    }
}
