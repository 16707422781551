import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import cn from 'classnames'
import { BUTTONS } from '../../services/constants'
import { translate } from '../../modules/react/utils'

class Confirmation extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.string,
        confirmButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        isOpen: PropTypes.bool,
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func
    }

    constructor (props) {
        super(props)

        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.onKeypress = this.onKeypress.bind(this)
    }

    render () {
        const {
            title,
            text,
            isOpen,
            confirmButtonText = 'label.confirm',
            cancelButtonText = 'label.cancel'
        } = this.props

        return (
            <ReactModal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={this.onCancel}
                overlayClassName="modal fade in"
                className={cn('confirm-alt-modal modal-dialog')}
                style={{ overlay: { zIndex: 1040 } }}
            >
                <div className="modal-content">
                    <div className="dialog-img">
                        <img src="/assets/icons/dialogs_warning.svg"/>
                    </div>
                    <div className="dialog-content">
                        <div className="modal-header">
                            <i className="icon-common_close" onClick={this.onCancel}/>
                            <h3>{translate(title)}</h3>
                        </div>
                        <div className="modal-body">
                            <p>{translate(text)}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className={cn('btn btn-red')}
                                onClick={this.onConfirm}
                            >
                                {translate(confirmButtonText)}
                            </button>
                            <button
                                type="button"
                                className="btn-switcher btn-primary-switcher"
                                onClick={this.onCancel}
                            >
                                {translate(cancelButtonText)}
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        )
    }

    onConfirm () {
        this.props.onConfirm()
    }

    onCancel () {
        this.props.onCancel()
    }

    onKeypress (event) {
        const { key } = event

        if (key === BUTTONS.ENTER) {
            event.preventDefault()
            return this.onConfirm()
        }

        if (key === BUTTONS.ESCAPE) {
            event.preventDefault()
            return this.props.onCancel()
        }
    }
}

export default Confirmation
