export function undoDeferredAction(PageSettings, DeferredAction, $rootScope, $timeout) {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        template: `
            <div class="undo-deletion-popup">
                <i class="icon-common_close" ng-click="close()"></i>
                <i class="icon-template_delete"></i>
                <div>
                    <header>
                        {{PageSettings.deferredActionsInfo[PageSettings.deferredActionsInfo.length - 1].text}}
                    </header>
                    <a href="" ng-click="undo()">
                        {{PageSettings.deferredActionsInfo[PageSettings.deferredActionsInfo.length - 1].linkText}}
                    </a>
                </div>
            </div>
        `,

        link: (scope) => {
            scope.close = () => {
                if(PageSettings.deferredActionsInfo.length) {
                    let lastAction = PageSettings.deferredActionsInfo.pop();
                    $timeout(() => {
                        $rootScope.$broadcast(DeferredAction.CONST.CLOSE_POPUP_EVENT, lastAction);
                    });
                }
            };

            scope.undo = () => {
                if(PageSettings.deferredActionsInfo.length) {
                    let lastAction = PageSettings.deferredActionsInfo.pop();
                    $timeout(() => {
                        $rootScope.$broadcast(DeferredAction.CONST.UNDO_EVENT, lastAction);
                    });
                }
            };

            scope.$on('$destroy', scope.close);
        }
    };
}
