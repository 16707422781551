export function profileRoute($stateProvider, $urlRouterProvider) {
    'ngInject';

    $stateProvider
        .state('main.profile', {
            url: '/profile',
            template: require('./layout.html'),
            controller: 'ProfileMainController',
            controllerAs: 'prCtrl',
            abstract: true
        })
        .state('main.profile.main', {
            url: '',
            template: require('./views/profile.html'),
            controller: 'ProfileController',
            controllerAs: 'ctrl',
            resolve: {
                profile: ApiCalls => {
                    'ngInject';
                    return ApiCalls.getProfile();
                },
                timezones: ApiCalls => {
                    'ngInject';
                    return ApiCalls.getTimezones();
                }
            }
        })
        .state('main.profile.notifications', {
            url: '/notifications',
            template: require('./views/notifications.html'),
            controller: 'NotificationsController',
            controllerAs: 'ctrl',
            resolve: {
                profile: ApiCalls => {
                    'ngInject';
                    return ApiCalls.getProfile();
                },
                notifications: ApiCalls => {
                    'ngInject';
                    return ApiCalls.getNotifications();
                }
            }
        })
        .state('main.profile.password', {
            url: '/password',
            template: require('./views/password.html'),
            controller: 'ProfilePasswordController',
            controllerAs: 'ctrl',
            resolve: {
                profile: ApiCalls => {
                    'ngInject';
                    return ApiCalls.getProfile();
                }
            }
        })
        .state('main.profile.integrations', {
            url: '/integrations',
            template: require('./views/integrations.html'),
            controller: 'IntegrationsController',
            controllerAs: 'ctrl',
            resolve: {
                channels: ProfileDataService => {
                    'ngInject';
                    return ProfileDataService.getSlackChannels();
                }
            }
        })
        .state('main.profile.integrations.channel', {
            url: '/:id'
        });

    $urlRouterProvider.otherwise('/');
}
