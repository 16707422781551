import React from 'react'
import cn from 'classnames'
import moment from 'moment-timezone'
import { PropTypes } from 'prop-types'
import later from '@breejs/later'

import UserSelect from '../selectize/user-select'
import DateInput from '../date-input'
import { getService, translate } from '../../../modules/react/utils'
import { User } from '../../../modules/database/models/user'
import SelectDropdown from '../select-dropdown'
import {
    DAY,
    DAYS_OF_WEEK,
    DAYS_OF_WEEK_NUMBERS,
    DAYS_OF_WEEK_NUMBERS_OPTIONS,
    DAYS_OF_WEEK_OPTIONS,
    MONTH,
    MONTH_TYPES,
    ONE_TIME,
    REPEAT_OPTIONS,
    REPEAT_PERIODS,
    WEEK,
    YEAR
} from '../../../services/constants'
import NumberInput from '../form/number-input'
import Checkbox from '../checkbox'
import './template-schedule-form.less'
import Button from '../button'
import FormGroup from '../form/form-group'
import Confirmation from '../confirmation'
import Tooltip from '../../../modules/react/tooltip'

later.date.localTime()

const parseInputDate = (string, format) => {
    const m = moment(string, format)
    if (m.isValid()) {
        return m.toDate()
    }
    return undefined
}

const inRange = (min, number, max) => {
    if (!number) {
        return undefined
    }
    return Math.min(Math.max(Number(number), min), max)
}

const parseCronExpression = expression => {
    const [second, minute, hour, day, month, daysOfWeek, year] = expression.trim().split(' ')
    return {
        second,
        minute,
        hour,
        day,
        month,
        daysOfWeek,
        year
    }
}

class TemplateScheduleForm extends React.Component {
    static propTypes = {
        template: PropTypes.object,
        onCancel: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.DateHelper = getService('DateHelper')
        this.MomentHelper = getService('MomentHelper')

        this.state = {
            starter: undefined,
            availableStarters: [],
            startDate: undefined,
            repeat: {
                period: undefined
            },
            schedule: undefined,
            expression: '',
            repeatError: undefined,
            loading: true,
            displayDeleteConfirmation: false,
            serverError: undefined
        }
    }

    componentDidMount () {
        this.tz = this.DateHelper.getTZ()
        this.companyTz = this.DateHelper.getCompanyTZ()

        this.minStartDate = this.dateTimeToUnix(moment().tz(this.tz).add(10, 'minute'))
        this.setState({ startDate: this.minStartDate })

        const { template, onChange } = this.props

        getService('ApiCalls').getSchedule(template.id).then(response => {
            if (response.success && response.result?.id) {
                const schedule = response.result
                const { cronExpression, period, startDate, processStarter } = schedule
                const starter = processStarter ? User.create(processStarter) : undefined

                const settings = this.parseCronExpression(cronExpression, period)
                const repeat = settings
                    ? { period, settings }
                    : { period: ONE_TIME }

                this.setState({
                    schedule,
                    starter,
                    repeat,
                    startDate,
                    expression: cronExpression
                })

                onChange({ ...template, schedule })
            }
        }, ({ status, data, ...error }) => {
            if (status === 515) {
                onChange({ ...template, schedule: undefined })
            } else {
                this.setState({ serverError: data?.displayError || translate('error.common') })
            }
        })

        getService('ApiCalls').getListOfProcessStarters(template.id).then(data => {
            try {
                const availableStarters = data.map(u => User.create(u))
                const currentUserAsStarter = availableStarters.find(u => u.id === getService('currentUser').id)
                const starter = this.state.starter || currentUserAsStarter
                this.setState({
                    availableStarters,
                    starter,
                    loading: false
                })
            } catch (e) {
                console.error(e)
                this.setState({ loading: false })
            }
        })
    }

    render () {
        const { onCancel } = this.props
        const {
            starter,
            availableStarters,
            startDate,
            repeat,
            displayDeleteConfirmation,
            serverError,
            schedule
        } = this.state
        const starterError = this.validateStarter()
        const startDateError = this.validateStartDate()
        const periodError = this.validatePeriod()
        const cronExpressionError = this.validateExpression()
        const scheduleIsSaved = schedule?.id !== undefined

        return (
            <div className={'template-schedule-form'}>
                {serverError && (
                    <span className={'form-hint error'}>{serverError}</span>
                )}
                <FormGroup
                    className={cn('starter')}
                    label={translate('report.filters.field.starter.label')}
                    required={true}
                    error={starterError}
                >
                    <UserSelect
                        value={starter?.asSelectOption}
                        options={availableStarters.map(u => u.asSelectOption)}
                        onChange={users => this.selectStarter(users[0])}
                    />
                </FormGroup>
                <FormGroup
                    className={cn('start-date')}
                    label={translate('label.process.startDate')}
                    required={true}
                    error={startDateError}
                >
                    <DateInput
                        formatDate={this.unixToDateTime}
                        parseDate={parseInputDate}
                        value={startDate}
                        onDateChange={(dateTime) => this.selectStartDate(dateTime)}
                        minValue={moment().add(-1, 'day').endOf('day').unix()}
                        withIcon
                        withTimeInput
                        isClearable
                    />
                </FormGroup>
                <FormGroup
                    className={cn('repeat-settings', { 'repeat-error': periodError })}
                    label={translate('schedule.label.repeats')}
                    error={periodError || cronExpressionError}
                >
                    <>
                        <SelectDropdown
                            value={REPEAT_OPTIONS.find(o => o.value === repeat.period)}
                            options={REPEAT_OPTIONS}
                            onChange={this.setRepeatPeriod}
                        />
                        {this.renderRepeatControls()}
                    </>
                </FormGroup>
                <FormGroup className={cn('summary')} label={translate('schedule.label.summary')}>
                    <span className="read-only">
                        {this.renderSummary()}
                    </span>
                </FormGroup>
                <FormGroup className="next-run" label={translate('schedule.text.nextRunOn')}>
                    <span className="static">
                        {this.renderNextRun()}
                        {' '}
                        {(this.lastRunFailed()) && (
                            <span className="failed-run">
                                <i className={'icon-error_hint'}/>
                                <span>{translate('schedule.text.lastRunFailedOn')} {this.formatScheduleRunDate(schedule?.lastRunDate)}</span>
                            </span>
                        )}
                    </span>
                </FormGroup>


                <div className="form-actions">
                    <div className="main-actions">
                        <Button style="green" onClick={this.saveSchedule} disabled={this.saveButtonIsDisabled()}>
                            {scheduleIsSaved
                                ? translate('schedule.label.updateSchedule')
                                : translate('schedule.label.createSchedule')
                            }
                        </Button>
                        <Button onClick={onCancel}>
                            {translate('label.cancel')}
                        </Button>
                    </div>
                    <div className="additional-actions">
                        {scheduleIsSaved && (
                            <Tooltip text={'schedule.tooltips.deleteAction'} forceDisplay>
                                <Button
                                    style="icon"
                                    onClick={this.openDeleteConfirmation}>
                                    <i className="icon-delete_roundless"/>
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <Confirmation
                    title={translate('schedule.text.deleteConfirmation.title')}
                    text={translate('schedule.text.deleteConfirmation.text')}
                    confirmButtonText={translate('label.delete')}
                    isOpen={displayDeleteConfirmation}
                    onConfirm={this.deleteSchedule}
                    onCancel={this.closeDeleteConfirmation}
                />
            </div>
        )
    }

    renderRepeatControls = () => {
        const { repeat } = this.state
        const { interval } = repeat.settings || {}

        if (repeat.period === DAY) {
            return (
                <span className={cn('repeat-control', { 'has-error': !interval })}>
                    <span>{translate('plural.every', {COUNT: interval}, true)}</span>
                    <NumberInput
                        value={interval}
                        precision={0}
                        min={1}
                        max={31}
                        onChange={(interval) => this.updateIntervalSettings({ interval: inRange(1, interval, 31) })}
                    />
                    <span>{translate('schedule.interval.days', { interval }, true)} {interval > 1 ? '(' + translate('schedule.text.startingFromFirstDay') + ')' : ''}</span>
                </span>
            )
        }

        if (repeat.period === WEEK) {
            const daysOfWeek = Object.keys(DAYS_OF_WEEK).map(key => {
                const D = DAYS_OF_WEEK[key]
                return {
                    id: D.id,
                    name: moment().day(translate(`daysOfWeek.${D.id}`)).format('ddd'),
                    isChecked: repeat.settings.daysOfWeek.includes(D.id)
                }
            })

            return (
                <span className={'repeat-control repeat-control-block'}>
                    <span>{translate('label.on')}</span>
                    {daysOfWeek.map(day => (
                        <Checkbox
                            key={day.id}
                            label={day.name}
                            className="field-value-checkbox"
                            checked={day.isChecked}
                            onChange={(state) => this.toggleDayOfWeek(day.id, state)}
                        />
                    ))}
                </span>
            )
        }

        if (repeat.period === MONTH) {
            return [
                <span className={'repeat-control'} key={0}>
                    <span>{translate('plural.every', {COUNT: repeat.settings.interval}, true)}</span>
                    <SelectDropdown
                        value={{ value: repeat.settings.interval, label: repeat.settings.interval }}
                        options={[1, 2, 3, 4, 6, 12].map(n => ({ value: n, label: n }))}
                        onChange={(selected) => this.updateIntervalSettings({ interval: inRange(1, selected.value, 12) })}
                    />
                    <span>{translate('schedule.interval.months', { interval }, true)}</span>
                </span>,
                <span
                    className={cn('repeat-control repeat-control-block', {
                        disabled: repeat.settings.type !== MONTH_TYPES.DAY_OF_MONTH,
                        'has-error': !repeat.settings?.dayOfMonth
                    })}
                    key={1}>
                    <input
                        type="radio"
                        checked={repeat.settings.type === MONTH_TYPES.DAY_OF_MONTH}
                        onChange={() => this.setMonthType(MONTH_TYPES.DAY_OF_MONTH)}
                    />
                    <span>{translate('label.onDay')}</span>
                    <NumberInput
                        value={repeat.settings.dayOfMonth}
                        precision={0}
                        min={1}
                        max={31}
                        onChange={(dayOfMonth) => this.updateIntervalSettings({ dayOfMonth: inRange(1, dayOfMonth, 31) })}
                        disabled={repeat.settings.type !== MONTH_TYPES.DAY_OF_MONTH}
                    />
                </span>,
                <span
                    className={cn('repeat-control repeat-control-block', { disabled: repeat.settings.type !== MONTH_TYPES.DAY_OF_WEEK })}
                    key={2}>
                    <input
                        type="radio"
                        checked={repeat.settings.type === MONTH_TYPES.DAY_OF_WEEK}
                        onChange={() => this.setMonthType(MONTH_TYPES.DAY_OF_WEEK)}
                    />
                    <SelectDropdown
                        value={DAYS_OF_WEEK_NUMBERS_OPTIONS.find(o => o.value === repeat.settings.dayOfWeekNumber)}
                        options={DAYS_OF_WEEK_NUMBERS_OPTIONS}
                        onChange={this.setDayOfWeekNumber}
                        disabled={repeat.settings.type !== MONTH_TYPES.DAY_OF_WEEK}
                    />
                    <SelectDropdown
                        value={DAYS_OF_WEEK_OPTIONS.find(o => o.value === repeat.settings.dayOfWeek)}
                        options={DAYS_OF_WEEK_OPTIONS}
                        onChange={this.setDayOfWeek}
                        disabled={repeat.settings.type !== MONTH_TYPES.DAY_OF_WEEK}
                    />
                </span>,
                <span
                    className={cn('repeat-control repeat-control-block', { disabled: repeat.settings.type !== MONTH_TYPES.LAST_DAY })}
                    key={3}>
                    <input
                        type="radio"
                        checked={repeat.settings.type === MONTH_TYPES.LAST_DAY}
                        onChange={() => this.setMonthType(MONTH_TYPES.LAST_DAY)}
                    />
                    <span>{translate('label.lastDayOfMonth')}</span>
                </span>
            ]
        }

        if (repeat.period === YEAR) {
            return (
                <span className={cn('repeat-control', { 'has-error': !interval })}>
                    <span>{translate('plural.every', {COUNT: interval}, true)}</span>
                    <NumberInput
                        value={interval}
                        precision={0}
                        min={1}
                        onChange={(interval) => this.updateIntervalSettings({ interval })}
                    />
                    <span>{translate('schedule.interval.years', { interval }, true)}</span>
                </span>
            )
        }
    }

    renderSummary = () => {
        const { repeat, startDate, expression } = this.state

        if (this.validateStartDate() || this.validatePeriod() || this.validateExpression()) {
            return ''
        }

        if (repeat.period === ONE_TIME) {
            return translate('schedule.summary.onceOn', { DATETIME: this.formatScheduleRunDate(startDate) }, true)
        }

        if (!expression) {
            return ''
        }

        const { minute, hour, day, month } = parseCronExpression(expression)
        const dateTimeFromExpression = moment().tz(this.companyTz).set({ hour, minute }).tz(this.tz)
        const time = dateTimeFromExpression.format(this.DateHelper.DATE_FORMATS().SCHEDULE_TIME)
        const { interval, daysOfWeek } = repeat.settings

        if (repeat.period === DAY && interval) {
            return translate('schedule.summary.daily', { time, interval }, true)
        } else if (repeat.period === WEEK) {
            const days = daysOfWeek.map((id, index) => {
                const separator = index === 0 ? '' : index === daysOfWeek.length - 1 ? ` ${translate('label.and')} ` : ', '
                return separator + translate(`daysOfWeek.${id}`)
            })
            if (days.length === 7) {
                return translate('schedule.summary.weekly.everyDay', { time }, true)
            }
            return translate('schedule.summary.weekly.onlyOnDays', { time, days: days.join('') }, true)
        } else if (repeat.period === MONTH && repeat.settings.type === MONTH_TYPES.DAY_OF_MONTH && repeat.settings.dayOfMonth) {
            const day = repeat.settings.dayOfMonth
            return translate('schedule.summary.monthly.dayOfMonth', { time, interval, day }, true)
        } else if (repeat.period === MONTH && repeat.settings.type === MONTH_TYPES.DAY_OF_WEEK) {
            const dayNumber = translate(DAYS_OF_WEEK_NUMBERS_OPTIONS.find(o => o.value === repeat.settings.dayOfWeekNumber).label).toLowerCase()
            const day = translate(DAYS_OF_WEEK_OPTIONS.find(o => o.value === repeat.settings.dayOfWeek).label)
            return translate('schedule.summary.monthly.dayOfWeek', { time, interval, dayNumber, day }, true)
        } else if (repeat.period === MONTH && repeat.settings.type === MONTH_TYPES.LAST_DAY) {
            return translate('schedule.summary.monthly.lastDay', { time, interval }, true)
        } else if (repeat.period === YEAR && interval) {
            const date = dateTimeFromExpression.set({ month: month - 1, date: day }).format(this.DateHelper.DATE_FORMATS().SCHEDULE_DATE)
            return translate('schedule.summary.yearly', { time, interval, date }, true)
        }
        return ''
    }

    selectStarter = starter => {
        this.setState({ starter })
    }

    selectStartDate = startDate => {
        this.setState({ startDate: this.dateTimeToUnix(startDate) }, () => this.generateCronExpression())
    }

    setRepeatPeriod = period => {
        const { settings } = REPEAT_PERIODS[period.value]
        this.setState({ repeat: { period: period.value, settings } }, () => this.generateCronExpression())
    }

    setMonthType = type => {
        this.updateIntervalSettings({ type })
    }

    unixToDateTime = (date, format, tz) => {
        return date
            ? this.MomentHelper.formatInputDateTime(date, format, tz)
            : ''
    }

    dateTimeToUnix = (datetime, tz) => {
        if (datetime) {
            return this.MomentHelper.dateTimeToUnix(datetime, tz)
        }
        return undefined
    }

    updateIntervalSettings = settings => {
        const { repeat } = this.state
        this.setState({
            repeat: {
                ...repeat,
                settings: { ...repeat.settings, ...settings }
            }
        }, this.generateCronExpression)
    }

    toggleDayOfWeek = (day, state) => {
        const { repeat } = this.state
        const daysOfWeek = [...repeat.settings.daysOfWeek.filter(d => d !== day)]
        if (state) {
            daysOfWeek.push(day)
        }
        this.updateIntervalSettings({ daysOfWeek })
    }

    setDayOfWeek = option => {
        this.updateIntervalSettings({ dayOfWeek: option.value })
    }

    setDayOfWeekNumber = option => {
        this.updateIntervalSettings({ dayOfWeekNumber: option.value })
    }

    generateCronExpression = () => {
        const { startDate, repeat } = this.state
        const { period, settings } = repeat

        if (this.validateStartDate()) {
            return this.setState({ expression: undefined })
        }

        const time = this.unixToDateTime(startDate, '[0] m H', this.companyTz)
        const date = this.unixToDateTime(startDate, 'D M', this.companyTz)
        let expression

        if (period === DAY) {
            const interval = inRange(1, settings.interval, 31)
            if (interval) {
                const dayExpression = interval > 1 ? `1/${interval}` : '*'
                expression = `${time} ${dayExpression} * ? *`
            }
        }

        if (period === WEEK) {
            expression = `${time} ? * ${settings.daysOfWeek?.join(',') || '_'} *`
        }

        if (period === MONTH) {
            const generateMonthlyExpression = (month) => {
                if (settings.type === MONTH_TYPES.LAST_DAY) {
                    return `${time} L ${month} ? *`
                }

                if (settings.type === MONTH_TYPES.DAY_OF_MONTH) {
                    const dayOfMonth = inRange(1, settings.dayOfMonth || 0, 31)
                    return `${time} ${dayOfMonth} ${month} ? *`
                }

                if (settings.type === MONTH_TYPES.DAY_OF_WEEK) {
                    const dayOfWeek = DAYS_OF_WEEK[settings.dayOfWeek].expression
                    const dayOfWeekNumber = DAYS_OF_WEEK_NUMBERS[settings.dayOfWeekNumber].expression
                    return `${time} ? ${month} ${dayOfWeek}${dayOfWeekNumber} *`
                }
            }

            // New logic for month
            let month
            if (settings.interval > 1) {
                let nextRunMonth = Number(date.split(' ')[1])
                const nextRunYear = this.unixToDateTime(startDate, 'YYYY', this.companyTz)
                let rest = nextRunMonth % settings.interval
                let startMonth = rest > 0 ? rest : settings.interval
                month = `${startMonth}/${settings.interval}`

                const tempExpression = generateMonthlyExpression(month)
                const nextRunUnix = this.calculateNextRun(tempExpression, startDate)
                const [newNextRunMonth, newNextRunYear] = this.unixToDateTime(nextRunUnix, 'M YYYY').split(' ')
                if (Number(newNextRunMonth) > nextRunMonth || Number(newNextRunYear) > Number(nextRunYear)) {
                    nextRunMonth += 1
                }
                rest = nextRunMonth % settings.interval
                startMonth = rest > 0 ? rest : settings.interval
                month = `${startMonth}/${settings.interval}`
            } else {
                month = '*'
            }

            expression = generateMonthlyExpression(month)
        }

        if (period === YEAR) {
            const interval = settings.interval || 1
            const year = this.unixToDateTime(startDate, 'YYYY')
            expression = `${time} ${date} ? ${year}/${interval}`
        }

        // Log expression and next next runs
        // if (expression) {
        //     console.log('New cron expression: ', expression)
        //     console.group('New Schedule')
        //     const cron = later.parse.cron(expression, true)
        //     const startDateForLater = this.unixToDateTime(startDate, 'YYYY-MM-DD[T]HH:mm:[00]')
        //     console.log('startDateForLater', startDateForLater)
        //     const nextRuns = later.schedule(cron).next(10, new Date(startDateForLater))
        //     console.table(nextRuns.map(nextRunTs => this.formatScheduleRunDate(this.dateTimeToUnix(nextRunTs))))
        //     console.groupEnd()
        // }

        this.setState({ expression })
    }

    parseCronExpression = (expression, period) => {
        const { day, month, daysOfWeek, year } = parseCronExpression(expression)
        const defaultSettings = REPEAT_PERIODS[period].settings

        if (period === DAY && /(\d\/\d|\*)/.test(day)) {
            return {
                ...defaultSettings,
                interval: day === '*' ? 1 : day.split('/')[1]
            }
        }

        if (period === WEEK) {
            return {
                ...defaultSettings,
                daysOfWeek: daysOfWeek.split(',')
            }
        }

        if (period === MONTH && /(\d\/\d|\*)/.test(month)) {
            const settings = {
                ...defaultSettings,
                interval: month === '*' ? 1 : month.split('/')[1]
            }
            if (day === 'L') {
                settings.type = MONTH_TYPES.LAST_DAY
            } else if (/\d/.test(day)) {
                settings.type = MONTH_TYPES.DAY_OF_MONTH
                settings.dayOfMonth = day
            } else if (day === '?' && /(\w+)(#\d|L)/.test(daysOfWeek)) {
                const matches = /(\w+)(#\d|L)/.exec(daysOfWeek)
                settings.type = MONTH_TYPES.DAY_OF_WEEK
                settings.dayOfWeek = Object.keys(DAYS_OF_WEEK).find(id => DAYS_OF_WEEK[id].expression === matches[1])
                settings.dayOfWeekNumber = Object.keys(DAYS_OF_WEEK_NUMBERS).find(id => DAYS_OF_WEEK_NUMBERS[id].expression === matches[2])
            }
            return settings
        }

        if (period === YEAR) {
            return {
                ...defaultSettings,
                interval: year.split('/')[1]
            }
        }

        return undefined
    }

    validatePeriod = () => {
        const { repeat, loading } = this.state

        if (!repeat.period && !loading) {
            return translate('schedule.error.period.required')
        }

        return undefined
    }

    validateExpression = () => {
        const { repeat, expression } = this.state

        if (repeat.period !== ONE_TIME && expression) {
            const { daysOfWeek } = parseCronExpression(expression)
            if (daysOfWeek === '_') {
                return translate('schedule.error.daysOfWeek.required')
            }
        }

        return undefined
    }

    validateStarter = () => {
        const { starter, loading } = this.state
        if (!starter && !loading) {
            return translate('schedule.error.starter.required')
        }
        if (starter && starter.isDeleted) {
            return translate('schedule.error.starter.invalid')
        }
        return undefined
    }

    validateStartDate = () => {
        const { startDate, repeat } = this.state
        if (repeat.period === ONE_TIME && startDate < this.minStartDate && this.formIsChanged()) {
            return translate('schedule.error.startDate.outdated')
        }
        if (!startDate) {
            return translate('schedule.error.startDate.required')
        }
        return undefined
    }

    validateInterval = () => {
        const { repeat } = this.state
        if ([DAY, YEAR].includes(repeat.period)) {
            return !repeat.settings?.interval
        }
        if (repeat.period === MONTH) {
            return !repeat.settings?.dayOfMonth
        }
        return false
    }

    formIsChanged = () => {
        const { schedule, starter, startDate, expression } = this.state
        if (!schedule) {
            return true
        }

        let starterIsChanged = starter && schedule.processStarter.id !== starter.id
        let startDateIsChanged = schedule.startDate !== startDate
        let expressionIsChanged = (schedule.cronExpression && schedule.cronExpression !== expression) || (!schedule.cronExpression && expression)

        return starterIsChanged || startDateIsChanged || expressionIsChanged
    }

    openDeleteConfirmation = () => {
        this.setState({ displayDeleteConfirmation: true })
    }

    closeDeleteConfirmation = () => {
        this.setState({ displayDeleteConfirmation: false })
    }

    deleteSchedule = () => {
        const { template, onChange } = this.props
        const { schedule, loading } = this.state
        if (loading) {
            return
        }

        this.setState({ serverError: undefined, loading: true })
        if (schedule?.id) {
            getService('ApiCalls')
                .deleteSchedule(template.id, schedule.id)
                .then(response => {
                    if (response.success) {
                        this.closeDeleteConfirmation()
                        onChange({ ...template, schedule: undefined }, true)
                        getService('PageSettings').toasterData = {
                            iconClass: ' icon-small_clock',
                            text: translate('schedule.text.isDeleted'),
                            timeout: 3000
                        }
                    }
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    saveSchedule = () => {
        const { template, onChange } = this.props
        const { schedule, starter, startDate, expression, repeat, loading } = this.state

        if (loading) {
            return
        }

        this.setState({ serverError: undefined, loading: true })

        const data = {
            startDate,
            processStarter: {
                id: starter.id
            },
            period: repeat.period,
            cronExpression: expression
        }

        const updateScenario = !!schedule?.id

        const ApiCalls = getService('ApiCalls')
        const request = updateScenario
            ? ApiCalls.updateSchedule(template.id, schedule?.id, data)
            : ApiCalls.createSchedule(template.id, data)

        request
            .then(response => {
                if (response.success) {
                    getService('PageSettings').toasterData = {
                        iconClass: ' icon-small_clock',
                        text: updateScenario
                            ? translate('schedule.text.isUpdated')
                            : translate('schedule.text.isCreated'),
                        timeout: 3000
                    }
                    onChange({ ...template, schedule: response.result }, true)
                } else {
                    this.setState({ serverError: translate('error.common') })
                }
            }, error => {
                this.setState({ serverError: this.parseServerError(error) || translate('error.common') })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    parseServerError = response => {
        const { data = {} } = response
        if (data.displayError) {
            return data.displayError
        }
        let error
        ['startDate', 'processStarter', 'cronExpression', 'period'].forEach(key => {
            if (data[key] && data[key][0]?.fieldError) {
                error = data[key][0]?.fieldError
            }
        })
        return error
    }

    saveButtonIsDisabled = () => {
        const { loading } = this.state
        const hasErrors = this.validateStarter()
            || this.validateStartDate()
            || this.validatePeriod()
            || this.validateExpression()
            || this.validateInterval()

        return !(this.formIsChanged() && !hasErrors && !loading)
    }

    calculateNextRun = (expression, startDate) => {
        if (!startDate) {
            return undefined
        }

        const cron = later.parse.cron(expression, true)
        const startDateForLater = this.unixToDateTime(startDate, 'YYYY-MM-DD[T]HH:mm:[00]', this.companyTz)
        const nextRun = later.schedule(cron).next(1, new Date(startDateForLater))
        return this.dateTimeToUnix(nextRun, this.companyTz)
    }

    renderNextRun = () => {
        const { schedule, expression, startDate, repeat } = this.state

        if (repeat.period === ONE_TIME && schedule?.startDate !== startDate && startDate >= this.minStartDate) {
            return this.formatScheduleRunDate(startDate)
        }

        if (repeat.period !== ONE_TIME && expression && schedule?.cronExpression !== expression) {
            return this.formatScheduleRunDate(this.calculateNextRun(expression, startDate))
        }

        return this.formatScheduleRunDate(schedule?.nextRunDate)
    }

    formatScheduleRunDate = runDate => {
        return runDate
            ? this.unixToDateTime(runDate, this.DateHelper.DATE_FORMATS().SCHEDULE_RUN_DATE_TIME)
            : ''
    }

    lastRunFailed = () => {
        const { schedule } = this.state
        return (schedule?.id && schedule?.lastRunDate && schedule?.lastRunStatus === 'FAILURE')
    }
}

export default TemplateScheduleForm
