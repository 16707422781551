export function short() {
    'ngInject';
    return (input, size, noDots) => {
        if (!input || input.length <= size) {
            return input;
        }
      
        return input.toString().substring(0, size) + (noDots ? '' : '...');
    };
}
