
export function highlight($sce) {
    'ngInject';

    let escape = (text) => {
        let element = angular.element('<div></div>');
        element.text(text); // not chain able https://github.com/angular-translate/angular-translate/issues/1044
        return element.html();
    };

    let replaceSearch = (text, search, withoutSpaces) => {
        let result = '';
        let regexp, regExpResult;
        if(withoutSpaces) {
            regexp = new RegExp('^' + search, 'gi');
        } else {
            regexp = new RegExp(search, 'gi');
        }
        let prevLastIndex = 0;
        regExpResult = regexp.exec(text);
        while(regExpResult) {
            let searchedStr = escape(text.substring(regExpResult.index, regexp.lastIndex));
            if(regExpResult.index > prevLastIndex) {
                result = result + escape(text.substring(prevLastIndex, regExpResult.index)) + `<span class="highlighted">${searchedStr}</span>`;
            } else {
                result = result + `<span class="highlighted">${searchedStr}</span>`;
            }
            prevLastIndex = regexp.lastIndex;
            regExpResult = regexp.exec(text);
        }
        result = result + escape(text.substr(prevLastIndex));
        return result;
    };


    return (text, search, withoutSpaces) => {
        if (!text) {
            return text;
        }
        if (!search) {
            return $sce.trustAsHtml(escape(text));
        }

        search = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        let result = '';
        if (withoutSpaces) {
            let query = search.split(' ');
            let queryRest = query && query.length > 1 ? query.slice(1).join(' ') : '';

            let str = text.split(' ');
            let strRest = str && str.length > 1 ? str.slice(1).join(' ') : '';

            result = [replaceSearch(str[0], query[0], true)];
            if (queryRest) {
                if (strRest) {
                    result.push(replaceSearch(strRest, queryRest, true));
                }
                if (text === result.join(' ')) {
                    result = [escape(str[0]), replaceSearch(strRest, search, true)];
                }
            } else if (strRest) {
                result.push(replaceSearch(strRest, query[0], true));
            }
            result = result.join(' ');
        } else {
            result = replaceSearch(text, search);
        }

        return $sce.trustAsHtml(result);
    };
}
