import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import NumberInput from '../form/number-input.jsx'
import BaseDropdown from '../dropdown/base.jsx'

const MONEY_PRECISION = 2

class MoneyField extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        settings: PropTypes.object,
        disabled: PropTypes.bool,
        onChange: PropTypes.func
    }

    constructor (props) {
        super(props)

        this.state = {
            isDropdownOpen: false,
            selectedCurrencyId: undefined,
            focusState: false
        }
    }

    render () {
        const { value, settings, disabled } = this.props
        const hasDropdown = this.hasDropdown()

        return (
            <div className={clsx('price-data-field money-field', { 'has-focus': this.state.focusState })}>
                <NumberInput
                    value={value.amount}
                    precision={MONEY_PRECISION}
                    min={settings.min}
                    max={settings.max}
                    onChange={this.changeAmount}
                    onFocusChange={this.onFocusChange}
                    disabled={disabled}
                />
                {hasDropdown
                    ? this.renderCurrencySelector()
                    : this.renderUnit()
                }
            </div>
        )
    }

    renderUnit = (hasDropdown = false) => {
        const currency = this.getActiveCurrency()
        const { disabled } = this.props

        return (
            <div
                className={clsx('unit', { single: !hasDropdown })}
                onClick={hasDropdown && !disabled ? this.openDropdown : undefined}
            >
                {!hasDropdown ? (
                    currency.symbol ? currency.symbol : currency.code
                ) : (
                    <span className="handler">
                        {currency.code}
                        {!disabled && (
                            <i className="icon-common_arrow_down"/>
                        )}
                    </span>
                )}
            </div>
        )
    }

    renderCurrencySelector = () => {
        const { settings, disabled } = this.props
        const { isDropdownOpen } = this.state
        const hasDropdown = this.hasDropdown()
        const label = this.renderUnit(hasDropdown)
        const currency = this.getActiveCurrency()

        if (disabled) {
            return this.renderUnit(true)
        }

        return (
            <BaseDropdown
                label={label}
                isOpen={isDropdownOpen}
                onFocusLost={this.closeDropdown}
                position={'bottom right'}
            >
                <ul className="select-dropdown__menu-list">
                    {settings.currencies.map((c) => (
                        <li
                            key={c.id}
                            onClick={() => this.selectCurrency(c.id)}
                            className={clsx('select-dropdown__option', { 'select-dropdown__option--is-selected': c.id === currency.id })}
                        >
                            <i className="icon-menu_checkmark"/>
                            {c.label}
                        </li>
                    ))}
                </ul>
            </BaseDropdown>
        )
    }

    getActiveCurrency = () => {
        const { value, settings } = this.props
        const { selectedCurrencyId } = this.state

        if (selectedCurrencyId && this.hasDropdown()) {
            return settings.currencies.find(c => c.id === selectedCurrencyId)
        }

        return value.currency || settings.currency
    }

    hasDropdown = () => {
        return this.props.settings.currencies.length > 1
    }

    openDropdown = () => {
        this.setState({
            isDropdownOpen: true
        })
    }

    closeDropdown = () => {
        this.setState({
            isDropdownOpen: false
        })
    }

    selectCurrency = currencyId => {
        const { value } = this.props
        if (value.amount !== '') {
            this.applyChanges({ currencyId })
        } else {
            this.setState({ selectedCurrencyId: currencyId })
        }
        this.closeDropdown()
    }

    changeAmount = amount => {
        this.applyChanges({ amount })
    }

    applyChanges = (attrs) => {
        const { value, settings, onChange } = this.props
        const selectedCurrencyId = this.state.selectedCurrencyId || attrs.currencyId
        const currency = value.currency || settings.currency

        const amount = attrs.amount !== undefined ? attrs.amount : value.amount
        const selectedCurrency = selectedCurrencyId !== undefined
            ? settings.currencies.find(c => c.id === selectedCurrencyId)
            : currency

        if (selectedCurrency) {
            const result = amount === null ? undefined : {
                amount: Number(amount),
                currency: selectedCurrency.toJSON()
            }
            onChange(result)
            this.setState({ selectedCurrencyId: undefined })
        }
    }

    onFocusChange = focusState => {
        this.setState({ focusState })
    }
}

export default observer(MoneyField)
