import React from 'react'
import classNames from 'classnames'
import { uuid } from '../../utils'
import { translate } from '../../modules/react/utils'

const Checkbox = ({ checked, label, disabled, onChange, ...props }) => {
    const id = uuid()
    const style = {
        marginLeft: 0
    }

    return (
        <div {...props}>
            <span className="checkbox" style={style}>
                <input type="checkbox" onChange={(e) => onChange(!checked, e)} id={id} value={checked}
                       disabled={disabled}/>
                <span className={classNames('checkbox-control', { checked })}>
                    <i className="icon-menu_checkmark"/>
                </span>
            </span>
            {label && (
                <label className="description" htmlFor={id}>{translate(label)}</label>
            )}
        </div>
    )
}

export default Checkbox
