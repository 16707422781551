import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Parser } from 'html-to-react'
import isNil from 'lodash/isNil'

import FilePreview from '../../../components/react/file-preview.jsx'
import Button from '../../../components/react/button.jsx'
import Tooltip from '../../react/tooltip.jsx'
import { DURATION } from '../store/constants'
import { calculateDuration, formatDuration, formatNumber } from '../store/utils'
import { localStorageHelper } from '../../../utils'
import { getFormattedMultiChoiceValue, getFormattedRadioButtonValue } from '../../database/models/database-utils'
import {
    DATE_AND_TIME,
    DATE_ONLY_VALUE,
    DATE_WITHOUT_TIME,
    EMAIL,
    FILES_LIST,
    FORMULA,
    MONEY,
    MULTI_SELECTOR,
    NUMERIC_VALUE,
    RADIO_SELECTOR,
    STATUS,
    STRING_MULTI_LINE,
    STRING_SINGLE_LINE,
    USER_FIELD,
    YES_NO
} from '../../../services/data-types'
import DateTimeFormatter from './date-time-formatter'
import { translate } from '../../react/utils'
import StatusFormatter from './status-formatter'

const htmlToReactParser = new Parser()

export function renderField (value, dataType, textParser, previewIsEnabled) {
    if (isNil(value)) {
        return null
    }

    switch (dataType) {
        case STRING_SINGLE_LINE:
        case STRING_MULTI_LINE:
            const parsedText = textParser(value.stringValue || value)
            return htmlToReactParser.parse(parsedText)
        case DATE_WITHOUT_TIME:
        case DATE_ONLY_VALUE:
        case DATE_AND_TIME:
            const timestamp = Number(value.dateValue || value)
            return [
                <i key={'icon'} className="icon-task_due_date_add_small"/>,
                <DateTimeFormatter key={'value'} timestamp={timestamp} withTime={dataType === DATE_AND_TIME}/>
            ]
        case USER_FIELD:
            return value.userValue.fullName
        case YES_NO:
            return (
                <div className="yesno-data-field">
                    <ul>
                        <li className={cn('yes', { active: value.yesNoValue === 'yes' })}>
                            <span className="helper"/>
                            {translate('label.yes')}
                        </li>
                        <li className={cn('no', { active: value.yesNoValue === 'no' })}>
                            <span className="helper"/>
                            {translate('label.no')}
                        </li>
                    </ul>
                </div>
            )
        case STATUS:
            return <StatusFormatter {...value} withColor={false}/>
        case EMAIL:
            return [
                <i key={'icon'} className="icon-toaster_mail"/>,
                <a key={'value'} href={`mailto:${value.emailValue}`} target="_blank" rel="noreferrer">{value.emailValue}</a>
            ]
        case RADIO_SELECTOR:
            return [
                <span key={'value'}>{getFormattedRadioButtonValue(value.radioButtonValue)}</span>,
                <i key={'icon'} className="icon-common_arrow_down"/>
            ]
        case MULTI_SELECTOR:
            return [
                ...getFormattedMultiChoiceValue(value.multiChoiceValue).map(string => (
                    <label key={string}>
                        <span className="checkbox">
                            <span className="checked checkbox-control">
                                <i className="icon-menu_checkmark"/>
                            </span>
                        </span>
                        {string}
                    </label>
                ))
            ]
        case FILES_LIST:
            const gallery = value.files.map(f => ({ ...f }))
            return [
                <i key={'icon'} className="icon-task_attachment"/>,
                ...gallery.map((file, index) => (
                    <div key={file.id} className="file">
                        <i className="icon-attachment_document"/>
                        <FilePreview className="file" file={file} gallery={gallery}
                                     isopen={previewIsEnabled && index === 0}/>
                    </div>
                ))
            ]
        case NUMERIC_VALUE:
            return formatNumber(value.numericValue)
        case MONEY:
            const { amount, currencyInfo } = value.moneyValue
            return amount ? [
                <span key={'value'} className="amount">{formatNumber(amount)}</span>,
                <span key={'icon'} className="unit single">{currencyInfo.symbol || currencyInfo.code}</span>
            ] : null
        case DURATION:
            return formatDuration(calculateDuration(value))
        case FORMULA:
            const { numericValue, moneyValue } = value
            if (numericValue) {
                return formatNumber(numericValue)
            }
            if (moneyValue && moneyValue.amount !== undefined) {
                return [
                    <span key={'value'} className="amount">{formatNumber(moneyValue.amount)}</span>,
                    <span key={'icon'}
                          className="unit single">{moneyValue.currencyInfo.symbol || moneyValue.currencyInfo.code}</span>
                ]
            }
            return null
        default:
            return value
    }
}

function setProcessViewBackUrl (url) {
    localStorageHelper.set('processViewBackUrl', url)
}

class ReportRecordView extends React.Component {
    static propTypes = {
        record: PropTypes.object.isRequired,
        columns: PropTypes.object.isRequired,
        textParser: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.state = { fieldIdWithPreview: null }
    }

    getColumnValue (columnKey) {
        const { record, columns } = this.props
        const { getter } = columns[columnKey]
        return (record && getter) ? getter(record) : null
    }

    renderField (columnKey) {
        const { record, columns, textParser } = this.props
        const column = columns[columnKey]
        const previewIsEnabled = this.state.fieldIdWithPreview && column.id && this.state.fieldIdWithPreview === column.id
        let { dataType = STRING_SINGLE_LINE, dataTypeGetter } = column
        if (dataTypeGetter) {
            dataType = dataTypeGetter(record)
        }
        const value = this.getColumnValue(columnKey)

        if (columnKey === 'PROCESS_TITLE') {
            const { process } = record
            const processId = process ? process.id : record.id
            const url = window.location.pathname
            return (
                <a href={`/process/${processId}`} onClick={() => setProcessViewBackUrl(url)}>{value}</a>
            )
        }

        return renderField(value, dataType, textParser, previewIsEnabled)
    }

    columnShouldBeDisplayed (columnKey) {
        return !(columnKey === 'TASK_REJECTION_REASON' && !this.getColumnValue(columnKey))
    }

    openPreviewForField (columnKey) {
        const { columns } = this.props
        const column = columns[columnKey]
        this.setState({
            fieldIdWithPreview: column.id
        })
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.fieldIdWithPreview !== null) {
            this.setState({
                fieldIdWithPreview: null
            })
        }
    }

    render () {
        const { columns } = this.props
        const columnKeys = Object.keys(columns)
        const visibleColumnKeys = columnKeys.filter(this.columnShouldBeDisplayed.bind(this))

        if (!this.props.record) {
            return null
        }

        return (
            <div>
                {visibleColumnKeys.map(columnKey => (
                    <div key={columnKey} className="form-group">
                        <label>{translate(columns[columnKey].label)}</label>
                        <div
                            className={cn('field-value', (columns[columnKey].dataType || '').toLowerCase(), columnKey.toLowerCase())}>
                            {this.renderField(columnKey)}
                        </div>
                        {columns[columnKey].dataType === FILES_LIST && (
                            <div className="actions">
                                <Tooltip text="tooltips.filePreview.previewField" isDisabled={false}>
                                    <Button style={'icon'} onClick={() => this.openPreviewForField(columnKey)}>
                                        <i className="icon-header_search"/>
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }
}

export default ReportRecordView
