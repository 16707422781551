export const DropdownField = {
    bindings: {
        field: '=',
        taskId: '=',
        processId: '=',
        templateId: '=',
        readonly: '<?',
        onChange: '&?',
        onOpen: '&?'
    },
    template:
`<div>
    <react-table-source-field
        ng-if="$ctrl.useTableAsSource"
        value="$ctrl.field.value.radioButtonValue"
        table-id="$ctrl.field.name.options.source.tableId"
        display-fields="$ctrl.field.name.options.source.displayFields"
        field-id="$ctrl.field.name.id"
        task-id="$ctrl.taskId"
        process-id="$ctrl.processId"
        template-id="$ctrl.templateId"
        placeholder="$ctrl.placeholder"
        on-change="$ctrl.onSelectItem"
        is-disabled="$ctrl.readonly"
    ></react-table-source-field>
    <dropdown-tether
        field-id="$ctrl.field.name.id"
        ng-if="!$ctrl.useTableAsSource"
        values="$ctrl.field.name.options.source.allowedValues"
        ng-model="$ctrl.field.value.radioButtonValue"
        on-change="$ctrl.onSelectSimpleItem(value)"
        is-disabled="$ctrl.readonly"
    ></dropdown-tether>
</div>`,
    controller: function (ApiCalls, $translate) {
        'ngInject'

        this.placeholder = $translate.instant('label.dataForm.sourceTableName.placeholder')

        this.onSelectItem = (item) => {
            if (!this.field.value) {
                this.field.value = {}
            }

            this.field.value.radioButtonValue = item ? { recordId: item.id, values: item.values } : undefined
            this.applyChanges()
        }

        this.onSelectSimpleItem = value => {
            this.field.value = {
                radioButtonValue: value.item || null
            }
            this.applyChanges()
        }

        this.applyChanges = () => {
            if (this.onChange) {
                this.onChange({ field: this.field })
            }
        }

        this.$onInit = () => {
            this.useTableAsSource = this.field.name.options.useTableAsSource
        }
    }
}
