import React from 'react'
import PropTypes from 'prop-types'
import { getService } from './utils'

class TimerFromNow extends React.Component {
    static propTypes = {
        start: PropTypes.number,
        isStarted: PropTypes.bool
    }

    constructor (props) {
        super(props)
        this.state = {
            time: this.getTime(props)
        }
        this.timerId = setTimeout(() => this.tick(), 60 * 1000)
    }

    componentWillUnmount () {
        if (this.timerId) {
            clearTimeout(this.timerId)
        }
    }

    componentDidMount () {
        this.tick()
    }

    componentDidUpdate (prevProps) {
        const { start } = this.props
        if (prevProps.start !== start) {
            this.tick()
        }
    }

    tick () {
        this.setState({
            time: this.getTime(this.props)
        })
        if (this.timerId) {
            clearTimeout(this.timerId)
        }
        this.timerId = setTimeout(() => this.tick(), 60 * 1000)
    }

    render () {
        const { time = null } = this.state
        return <span>{time}</span>
    }

    getTime = ({ start, isStarted }) => {
        const MomentHelper = getService('MomentHelper')
        return isStarted
            ? MomentHelper.fromNowStarted(start)
            : MomentHelper.fromNow(start)
    }
}

export default TimerFromNow
