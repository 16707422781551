import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ProcessLink from './process-link.jsx'
import deviceDetector from './device-detector'
import utils, { translate } from './utils.js'
import { tmplColors } from '../../libs/colors.js'

class AvailableTasksList extends React.Component {
    static propTypes = {
        tree: PropTypes.arrayOf(Object),
        currentUser: PropTypes.object,
        tasksGroup: PropTypes.number,
        functions: PropTypes.object,
        config: PropTypes.object,
        payload: PropTypes.object
    }

    render () {
        const { tree, functions, tasksGroup, currentUser, config, payload } = this.props
        if (!tree) {
            return null
        }
        let isMobile = deviceDetector.isMobile()
        let nodes = tree.map((node, index) => {
            let header = null
            if (node.tasks.length) {
                if (node.dueDate) {
                    const classNames = cn('task-duedate-section-header', 'bg-gray', {
                        'bg-red': node.dueDate.title === translate('label.overdue')
                    })
                    header = (
                        <header className={classNames}>
                            {node.dueDate.title}&nbsp;
                            {node.dueDate.date && <span>&nbsp;• &nbsp;{node.dueDate.date}</span>}
                        </header>
                    )
                } else if (node.process) {
                    if (node.process.id) {
                        let style = null
                        let hStyle = null
                        let className = null
                        if (node.process.icon) {
                            style = { background: node.process.icon.color }
                            hStyle = { backgroundColor: tmplColors[node.process.icon.color] }
                            className = cn({ icon: true }, node.process.icon.id)
                        }
                        const processView = (event) => functions.goToProcess(node, event)
                        header = (
                            <header className="task-process-section-header bg-gray" style={hStyle}>
                                <div className="first-row">
                                    {!node.process.icon && (
                                        <div className="avatar-small default">
                                            <i className="icon-File-Settings"/>
                                        </div>
                                    )}
                                    {node.process.icon && (
                                        <div className="avatar-small" style={style}>
                                            <i className={className}/>
                                        </div>
                                    )}
                                    <ProcessLink
                                        task={node}
                                        process={node.process}
                                        config={config}
                                        payload={payload}
                                        link={processView}
                                    />
                                </div>
                            </header>
                        )
                    } else {
                        header = (
                            <header className="task-process-section-header--empty bg-gray">
                                {translate('tasks-group.delegated')}
                            </header>
                        )
                    }
                }
            }
            let content = node.tasks.map((task, taskIndex) => {
                const className = cn({
                    tasks__list__content__item: true,
                    small: node.process,
                    isMobile: isMobile,
                    animated: utils.checkPermission(task, 'view')
                })
                const taskView = () => functions.goToTask(task, 'available', tasksGroup)
                const assign = (event) => functions.assign(task, event)
                const processView = (event) => functions.goToProcess(task, event)
                let isShowProcessLink = task.process && (!node.process || !node.process.id)
                let isShowCreatedBy = !task.process && task.creator && currentUser.id !== task.creator.id
                return (
                    <div className={className} onClick={taskView} key={taskIndex}>
                        <div>
                            <div className="wrap-table task-name">
                                <h4><span>{task.name}</span>
                                    {task.name && !isShowProcessLink && !isShowCreatedBy && (
                                        <span className="action">
                                            <span>&nbsp;•&nbsp;</span>
                                            <span className="assign-to-me" onClick={assign}>
                                                {translate('label.assignToMe')}
                                            </span>
                                        </span>
                                    )}
                                </h4>
                            </div>
                            {isShowProcessLink && (
                                <span className="descr wrap-table process-link">
                                    <ProcessLink
                                        task={task}
                                        process={task.process}
                                        config={config}
                                        payload={payload}
                                        link={processView}
                                        assign={assign}
                                    />
                                </span>
                            )}
                            {isShowCreatedBy && (
                                <span className="descr wrap-table created-by">
                                    <span>{`${translate('label.createdBy')} ${task.creator.fullName}`}</span>
                                    {task.name && (
                                        <span className="action">
                                            <span>&nbsp;•&nbsp;</span>
                                            <span className="assign-to-me" onClick={assign}>
                                                {`${translate('label.assignToMe')}`}
                                            </span>
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                )
            })
            return (
                <li key={index}>
                    {header}
                    {content}
                </li>
            )
        })
        return <ul>{nodes}</ul>
    }
}

export default AvailableTasksList
