export function ProfileDataService(ApiCalls) {
    'ngInject';

    const data = {
        channels: undefined
    };

    const getSlackChannels = () => {
        return new Promise((resolve) => {
            if (data.channels) {
                resolve(data.channels);
            } else {
                ApiCalls.getSlackChannels().then(json => {
                    data.channels = json.list || [];
                    resolve(data.channels);
                });
            }
        });
    };

    const removeSlackChannel = id => {
        return new Promise(resolve => {
            ApiCalls.removeSlackChannel(id)
                .then(() => {
                    if (data.channels) {
                        data.channels = data.channels.filter(ch => ch.id !== id);
                    }
                    resolve();
                });
        });
    };

    const updateSlackChannel = ({id, notifications}) => {
        return new Promise((resolve, reject) => {
            ApiCalls.updateSlackChannel(id, {notifications})
                .then(json => {
                    if (data.channels) {
                        data.channels = data.channels.map(channel => channel.id === id ? json.result : channel);
                    }
                    resolve(json);
                })
                .catch(error => reject(error));
        });
    };

    const invalidateSlackChannels = () => {
        data.channels = undefined;
    };

    return {
        getSlackChannels,
        removeSlackChannel,
        updateSlackChannel,
        invalidateSlackChannels
    };
}
