export function profileInfoPopup() {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '='
        },
        template: `
            <div class="profile-info-popup">
                <i class="icon-common_close" ng-click="close()"></i>
                <i class="icon-toaster_mail"></i>
                <div>
                    <header>
                        {{'text.verificationEmailIsSent' | translate}}
                    </header>
                    {{ngModel}}
                </div>
            </div>
        `,

        link: (scope) => {
            scope.close = () => {
                scope.ngModel = null;
            };
        }
    };
}
