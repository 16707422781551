export class OnboardingController {
    constructor($timeout, $stateParams, $state) {
        'ngInject';
        $timeout(() => this.canContinue = true, 3000);

        this.continue = () => {
            $state.go('main.home');
        };
    }
}
