import React from 'react'
import PropTypes from 'prop-types'
import FieldValuePrintView from './field-value-print-view'
import { translate } from '../../../modules/react/utils'

function TableSectionPrintView ({ section }) {
    const getColumnDataType = columnId => section.columns.find(c => c.name.id === columnId).name.dataType
    const getColumnValue = (row, columnId) => (row.values || []).find(rowValue => rowValue.columnId === columnId)?.value
    const fakeRow = { rowIndex: 0, values: section.columns.map(c => ({ columnId: c.name.id })) }
    return (
        <div className={'data-form-section'}>
            <h3>{section.name}</h3>
            {(section.rows || [fakeRow]).map(row => (
                <div key={row.rowIndex || 0} className={'table-row'}>
                    {row.rowIndex > 0 && (
                        <h4>{translate('label.rowWithIndex', { INDEX: row.rowIndex })}</h4>
                    )}
                    <div className={'fields'}>
                        {section.columns.map(column => (
                            <div key={column.name.id} className={'field'}>
                                <label>{column.name.label}</label>
                                <FieldValuePrintView
                                    value={getColumnValue(row, column.name.id)}
                                    dataType={getColumnDataType(column.name.id)}
                                    displayEmptyValue={false}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

TableSectionPrintView.propTypes = {
    section: PropTypes.object
}

export default TableSectionPrintView
