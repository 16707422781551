import {getCurrentReport, getTemplates, getUsers} from '../store/selectors';
import {connect} from '../store/connect';
import ReportFilters from '../components/report-filters.jsx';
import {updateFiltersForSelectedReport} from '../store/actions';
import {createDateRanges, templatesWithSpecialValues} from '../store/utils';
import {REPORT_TYPE} from '../store/constants';
import {getService} from '../../react/utils';

const mapStateToProps = (state) => {
    const report = getCurrentReport(state);
    const templates = getTemplates(state);
    const users = getUsers(state);
    const dateRangePresets = createDateRanges();
    const filters = {...report.filters};
    const dateRange = {...filters.dateRange};

    const dataIsLoading = report.data.isLoading;
    const dataIsReceived = report.data.isReceived;

    if (dateRange.start === undefined || dateRange.end === undefined) {
        const preset = dateRangePresets.find(opt => opt.value === dateRange.preset);
        dateRange.start = preset.start;
        dateRange.end = preset.end;
    }

    return {
        withTaskStatus: report.type === REPORT_TYPE.TASK_PERFORMANCE,
        filters: {...filters, dateRange, applied: dataIsLoading || dataIsReceived},
        options: {
            templates: report.type === REPORT_TYPE.COLLECTED_DATA ? templates : templatesWithSpecialValues(templates),
            dateRange: {
                presets: dateRangePresets,
                firstDayOfWeek: getService('DateHelper').getFirstDayOfWeek()
            },
            users
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilters: (filters) => {
            dispatch(updateFiltersForSelectedReport(filters));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportFilters);
