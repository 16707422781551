import config from '../config'
import utils from '../modules/processes/utils'
import mainUtils, { getTextWidth } from '../utils'
import {
    checkAggregationFieldType,
    checkDefaultFieldSettings,
    checkFormulaFieldType,
    checkFormulaSettingsError,
    cloneField,
    cloneSection,
    createAggregationField,
    excludeServiceFields,
    getFieldId,
    prepareDataFormFieldsForFormula
} from '../modules/templates/services/data-model-utils'
import { AGGREGATION, FORMULA, MONEY, NUMERIC_VALUE } from '../services/data-types'
import { extractFieldsFromExpression, flattenReplacement, formatExpression } from '../components/expression'

export function templateDataModel ($modal, $timeout, $window, $translate, FormErrors, companyCurrencies) {
    'ngInject'
    return {
        restrict: 'E',
        replace: true,
        scope: {
            template: '=',
            dataModel: '=',
            templateForm: '=',
            errorData: '=',
            onFieldTypeChange: '&'
        },
        template: require('../templates/template-data-model.html'),
        link: ($scope) => {
            const getAllSavedFieldsId = () => {
                let allFields = []
                if ($scope.dataModel) {
                    $scope.dataModel.list.forEach(s => {
                        allFields = allFields.concat(s.section.fieldsWithValues.filter(excludeServiceFields).filter(field => field.name.id).map(field => field.name.id))
                    })
                }
                return allFields
            }
            const fakeField = () => {
                return {
                    name: {
                        tempId: mainUtils.uid4(getAllSavedFieldsId()),
                        label: '',
                        dataType: 'STRING_SINGLE_LINE'
                    }
                }
            }
            const fakeSection = (index) => {
                let sectionIndex = index ? index + 1 : 1
                return {
                    section: {
                        name: `Section ${sectionIndex}`,
                        isTable: false,
                        fieldsWithValues: [
                            fakeField()
                        ]
                    }
                }
            }

            const updateFormulaFieldsAfterDataFormIsChanged = () => {
                $scope.dataModel.list.forEach(({ section }) => {
                    const withoutRedundantAggregationFieldsFilter = (field) => {
                        return !(field.name.dataType === AGGREGATION && !section.fieldsWithValues.find(f => f.name.options?.totalFieldId === getFieldId(field)))
                    }
                    section.fieldsWithValues = section.fieldsWithValues.filter(withoutRedundantAggregationFieldsFilter)
                    section.fieldsWithValues.forEach(field1 => {
                        const fieldGroupsForFormula = prepareDataFormFieldsForFormula($scope.dataModel.list, section, field1)
                        const availableFields = fieldGroupsForFormula.reduce((fields, group) => fields.concat(group.replacements), [])
                        field1.availableFields = availableFields.filter(f => f.id !== getFieldId(field1) && !f.hidden)
                        if (field1.name.dataType === FORMULA && field1.name.options?.expression) {
                            const missingFields = extractFieldsFromExpression(field1.name.options.expression).filter(mField => !availableFields.find(f => f.value === mField.value))
                            const invalidFields = availableFields.filter(field2 => {
                                return field2.hidden && field1.name.options.expression.indexOf(field2.value) > -1
                            })
                            if (invalidFields.length || missingFields.length) {
                                field1.name.options.expression = undefined
                                field1.name.$formulaHint = undefined
                            } else {
                                field1.name.$formulaHint = formatExpression(field1.name.options.expression, flattenReplacement(fieldGroupsForFormula))
                            }
                        }
                    })
                })
            }

            $scope.FormErrors = FormErrors
            $scope.namePattern = config.pattern
            $scope.getFieldId = getFieldId

            $scope.createForm = () => {
                $scope.dataModel = {
                    list: [fakeSection()]
                }
                $timeout(() => {
                    let el = document.getElementById('s-0') //eslint-disable-line
                    if (el) {
                        el.focus()
                    }
                })
            }

            $scope.checkField = (form, fieldName) => {
                if (!form || !form[fieldName]) {
                    return
                }
                return form[fieldName].$invalid
            }

            $scope.fieldTypeChanged = (field, type) => {
                field.name.$originalDataType = field.name.dataType
                field.name.dataType = type.id
                delete field.name.options
                delete field.name.defaultValue
                $scope.onFieldTypeChange({ field })
                $timeout(updateFormulaFieldsAfterDataFormIsChanged)
            }

            $scope.fieldNameChanged = (field, section) => {
                if (field.name.options && field.name.options.totalFieldId) {
                    const { totalFieldId, aggregation } = field.name.options
                    const savedAggregationField = section.fieldsWithValues.find(f => f.name.id === totalFieldId)
                    const aggregationField = createAggregationField(field, aggregation.expression, savedAggregationField?.name.id)
                    section.fieldsWithValues = section.fieldsWithValues.filter(f => !totalFieldId || getFieldId(f) !== totalFieldId).concat([aggregationField])
                    field.name.options.totalFieldId = getFieldId(aggregationField)
                }
            }

            $scope.settingsButtonIsActive = (field) => {
                const settingsAreDefault = !field.name.description
                    && !field.name.defaultValue
                    && (!field.name.options || checkDefaultFieldSettings(field, field.name.options, { companyCurrencies }))
                return !settingsAreDefault
            }

            $scope.settingsInvalid = (field) => {
                return $scope.checkRadioError(field) || $scope.checkMoneyError(field) || $scope.checkUserFieldSettingsError(field) || $scope.checkFormulaSettingsError(field)
            }

            $scope.checkRadioError = field => utils.invalidSelectorField(field)

            $scope.checkMoneyError = (field) => {
                if ($scope.errorData && $scope.errorData.dataModel && field.name.dataType === MONEY) {
                    const sectionIndex = $scope.dataModel.list.findIndex(s => s.section.fieldsWithValues.find(f => getFieldId(f) === getFieldId(field)))
                    if (!$scope.errorData.dataModel.list[sectionIndex]) {
                        return false
                    }
                    const { section } = $scope.errorData.dataModel.list[sectionIndex]
                    const errorSection = $scope.dataModel.list[sectionIndex]?.section
                    if (errorSection && section) {
                        const fieldsWithErrors = section.fieldsWithValues || section.columns || []
                        const originalFieldIndex = errorSection.fieldsWithValues.findIndex(f => getFieldId(f) === getFieldId(field))

                        if (fieldsWithErrors.length && fieldsWithErrors[originalFieldIndex]) {
                            return fieldsWithErrors[originalFieldIndex].name
                                && fieldsWithErrors[originalFieldIndex].name.options
                        }
                    }
                }
            }

            $scope.checkUserFieldSettingsError = utils.invalidUserFieldSettingsField

            $scope.checkFormulaSettingsError = checkFormulaSettingsError

            $scope.fieldSettings = (field) => {
                const modalScope = $scope.$new()
                modalScope.field = field
                const sectionIndex = $scope.dataModel.list.findIndex(s => s.section.fieldsWithValues.find(f => getFieldId(f) === getFieldId(field)))
                const { section } = $scope.dataModel.list[sectionIndex]
                modalScope.fieldGroupsForFormula = prepareDataFormFieldsForFormula($scope.dataModel.list, section, field)
                const availableFields = modalScope.fieldGroupsForFormula.reduce((fields, group) => fields.concat(group.replacements), [])
                modalScope.availableFields = availableFields.filter(f => f.id !== getFieldId(field) && !f.hidden)
                modalScope.isTable = section.isTable

                if (field.name.dataType === MONEY) {
                    modalScope.optionsError = $scope.checkMoneyError(field, sectionIndex) || null
                    if ($scope.errorData && $scope.errorData.dataModel) {
                        modalScope.resetOptionsError = () => {
                            const item = $scope.errorData.dataModel.list[sectionIndex]
                            if (item) {
                                const fieldsWithErrors = item.section.fieldsWithValues || item.section.columns || []
                                const originalFieldIndex = section.fieldsWithValues.findIndex(f => {
                                    return getFieldId(f) === getFieldId(field)
                                })
                                if (fieldsWithErrors.length
                                    && fieldsWithErrors[originalFieldIndex]
                                    && fieldsWithErrors[originalFieldIndex].name
                                    && fieldsWithErrors[originalFieldIndex].name.options) {
                                    fieldsWithErrors[originalFieldIndex].name.options = null
                                }
                            }
                        }
                    }
                }

                let modalInstance = $modal.open({
                    animation: true,
                    backdrop: 'static',
                    template: require('../templates/modals/data-model-field-settings.html'),
                    controller: 'DataModelFieldSettingsController',
                    windowClass: 'data-model-field-settings',
                    keyboard: false,
                    scope: modalScope
                })
                modalInstance.result.then(({ options, defaultValue, description }) => {
                    const { dataType } = field.name
                    if (angular.isObject(options)) {
                        field.name.options = angular.copy(options)
                        delete field.name.options.selectedFileTypesOption
                    }
                    if (angular.isObject(defaultValue)) {
                        field.name.defaultValue = angular.copy(defaultValue)
                        if (dataType === MONEY && defaultValue && defaultValue.moneyValue && defaultValue.moneyValue.amount) {
                            field.name.defaultValue.moneyValue.amount = Number(defaultValue.moneyValue.amount.replace(/[^0-9\.-]/g, ''))
                        }
                    } else if (field.name.defaultValue) {
                        delete field.name.defaultValue
                    }
                    field.name.description = description

                    if ([MONEY, NUMERIC_VALUE, FORMULA].includes(dataType) && section.isTable) {
                        const { totalFieldId } = field.name.options
                        const savedAggregationField = section.fieldsWithValues.find(f => f.name.id === totalFieldId)
                        section.fieldsWithValues = section.fieldsWithValues.filter(f => !totalFieldId || getFieldId(f) !== totalFieldId)

                        if (options.aggregation && options.aggregation.expression) {
                            const aggregationField = createAggregationField(field, options.aggregation.expression, savedAggregationField?.name.id)
                            section.fieldsWithValues.push(aggregationField)
                            field.name.options.totalFieldId = getFieldId(aggregationField)
                        } else {
                            field.name.options.aggregation = undefined
                            field.name.options.totalFieldId = undefined
                        }
                    }

                    updateFormulaFieldsAfterDataFormIsChanged()
                }).finally(() => modalScope.$destroy())
            }

            $scope.deleteField = (section, field) => {
                resetServerErrors()
                const deletedFieldId = getFieldId(field)
                let totalFieldId
                if (field.name.options) {
                    totalFieldId = field.name.options.totalFieldId
                }
                section.fieldsWithValues = section.fieldsWithValues.filter(f => {
                    const fieldId = getFieldId(f)
                    return fieldId !== deletedFieldId && (!totalFieldId || fieldId !== totalFieldId)
                })
                updateFormulaFieldsAfterDataFormIsChanged()
            }

            $scope.cloneField = (section, field) => {
                resetServerErrors()
                const originalIndex = section.fieldsWithValues.findIndex(f => getFieldId(f) === getFieldId(field))
                const [clone, aggregationField] = cloneField(field, getAllSavedFieldsId())
                section.fieldsWithValues.splice(originalIndex + 1, 0, clone)
                if (aggregationField) {
                    section.fieldsWithValues.push(aggregationField)
                }
            }

            $scope.newField = ($index, section) => {
                resetServerErrors()
                let field = fakeField()
                section.fieldsWithValues.push(field)
                $timeout(() => {
                    document.getElementById(getFieldId(field)).focus() //eslint-disable-line
                })
            }

            $scope.newSection = () => {
                $scope.dataModel.list.push(fakeSection($scope.dataModel.list.length))
                $timeout(() => {
                    document.getElementById('s-' + ($scope.dataModel.list.length - 1)).focus() //eslint-disable-line
                })
            }

            $scope.removeSection = item => {
                $scope.dataModel.list = $scope.dataModel.list.filter(i => i !== item)
                updateFormulaFieldsAfterDataFormIsChanged()
            }

            $scope.cloneSection = index => {
                const original = $scope.dataModel.list[index]
                const clone = cloneSection(original, getAllSavedFieldsId())
                $scope.dataModel.list.splice(index + 1, 0, clone)
            }

            $scope.sectionsOpts = {
                accept: function (sourceItemHandleScope, destSortableScope) {
                    return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id
                },
                dragMove: (itemPosition, containment, eventObj) => {
                    if (eventObj) {
                        let container = document.getElementsByClassName('template__section')[0] //eslint-disable-line
                        let targetY = eventObj.pageY - $window.pageYOffset
                        if (targetY < $(container).offset().top + 30) { //eslint-disable-line
                            container.scrollTop = container.scrollTop - 50
                        } else if (targetY - 100 > container.clientHeight) {
                            container.scrollTop = container.scrollTop + 50
                        }
                    }
                }
            }

            $scope.fieldsOpts = {
                accept: function (sourceItemHandleScope) {
                    return !sourceItemHandleScope.itemScope.element.hasClass('template-data-model__content__section')
                },
                dragMove: (itemPosition, containment, eventObj) => {
                    resetServerErrors()
                    if (eventObj) {
                        let container = document.getElementsByClassName('template__section')[0] //eslint-disable-line
                        let targetY = eventObj.pageY - $window.pageYOffset
                        if (targetY < $(container).offset().top + 35) { //eslint-disable-line
                            container.scrollTop = container.scrollTop - 30
                        } else if (targetY - 100 > container.clientHeight) {
                            container.scrollTop = container.scrollTop + 30
                        }
                    }
                },
                itemMoved: ({ source, dest }) => {
                    resetServerErrors()
                    const field = source.itemScope.modelValue
                    const sourceSection = source.sortableScope.$parent.modelValue.section
                    const destSection = dest.sortableScope.$parent.modelValue.section

                    if (field.name.options?.totalFieldId) {
                        const { totalFieldId, aggregation } = field.name.options
                        sourceSection.fieldsWithValues = sourceSection.fieldsWithValues.filter(f => getFieldId(f) !== totalFieldId)

                        if (destSection.isTable) {
                            const aggregationField = createAggregationField(field, aggregation.expression)
                            destSection.fieldsWithValues = destSection.fieldsWithValues.concat([aggregationField])
                            field.name.options.totalFieldId = getFieldId(aggregationField)
                        } else {
                            delete field.name.options.aggregation
                            delete field.name.options.totalFieldId
                        }
                    }

                    updateFormulaFieldsAfterDataFormIsChanged()
                }
            }

            $scope.dataFormEmptyStateHelpText = $translate.instant('text.dataFormEmptyStateHelpText').replace('&lt;br&gt;', `<br>`)

            $scope.getErrorField = (field) => {
                if (!$scope.errorData) {
                    return undefined
                }
                const sectionIndex = $scope.dataModel.list.findIndex(s => s.section.fieldsWithValues.find(f => getFieldId(f) === getFieldId(field)))
                if (!$scope.errorData.dataModel?.list[sectionIndex]?.section) {
                    return undefined
                }
                const { section } = $scope.errorData.dataModel.list[sectionIndex]
                const fieldIndex = $scope.dataModel.list[sectionIndex].section.fieldsWithValues.findIndex(f => getFieldId(f) === getFieldId(field))
                return (section.fieldsWithValues || section.columns)[fieldIndex]?.name
            }
            $scope.getErrorForOptions = (options) => utils.getServerErrorForOptions(options, $translate, true)
            $scope.getErrorForDefaultValue = (options) => utils.getServerErrorForDefaultValue(options, $translate, true)

            const resetServerErrors = () => {
                if ($scope.errorData?.dataModel) {
                    $scope.errorData.dataModel = undefined
                }
            }

            $scope.$on('openTemplateDataSettings', (event, field) => {
                $scope.fieldSettings(field)
            })

            $scope.fieldIsFormula = field => checkFormulaFieldType(field)
            $scope.getFormulaHint = field => {
                if (checkFormulaFieldType(field) && field.name.$formulaHint && getTextWidth(field.name.label) < 250) {
                    return `${field.name.label || 'Field name'} = ${field.name.$formulaHint}`
                }
                return ''
            }

            $scope.$watch(() => $scope.dataModel.list.map(s => s.section.isTable).join(','), () => {
                $scope.dataModel.list.forEach(s => {
                    if (!s.section.isTable) {
                        delete s.section.columns
                        s.section.fieldsWithValues = s.section.fieldsWithValues.filter(f => !checkAggregationFieldType(f))
                        s.section.fieldsWithValues.forEach(f => {
                            if (f.name.options) {
                                delete f.name.options.aggregation
                                delete f.name.options.totalFieldId
                            }
                        })
                    }
                })
                updateFormulaFieldsAfterDataFormIsChanged()
            })
        }
    }
}
