export function filesContent() {
    'ngInject';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            files: '='
        },
        template: `
            <div class="files">
                <react-file-preview 
                    class="file"
                    file="files[0]" 
                    gallery="files" 
                    ng-click="$event.stopPropagation();"></react-file-preview>
                <react-file-preview
                    class="file" 
                    ng-repeat="file in files"
                    ng-if="fullMode && $index" 
                    file="file" 
                    gallery="files" 
                    ng-click="$event.stopPropagation();"></react-file-preview>
                <a class="more-files" href="" ng-if="!fullMode && files.length > 1"
                   ng-click="setFullMode(true, $event)">+{{files.length - 1}}</a>
                <a class="more-files" href="" ng-if="fullMode"
                   ng-click="setFullMode(false, $event)">{{'label.less' | translate}}</a>
            </div>
        `,
        link: scope => {
            scope.setFullMode = (isOpen, event) => {
                event.stopPropagation();
                scope.fullMode = isOpen;
            };
        }
    };
}
