import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import colors from '../../libs/colors.js';
import TimerFromNow from './timer-from-now.jsx';
import deviceDetector from './device-detector.js';
import utils from './utils.js';

import { translate } from './utils.js'

class ProcessLink extends React.Component {
    static propTypes = {
        task: PropTypes.object,
        process: PropTypes.object,
        link: PropTypes.func,
        config: PropTypes.object,
        payload: PropTypes.object,
        assign: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
        };
    }
    changeState() {
        this.setState({showTooltip: true});
    }
    render() {
        const {link, task, process, config, payload, assign} = this.props;
        const changeState = () => this.changeState();

        let container = null;
        if (this.state.showTooltip && task && !task.isCompleted && process) {
            let style = null;
            let tasksContent = null;
            if (process.starter) {
                style = {background: colors[process.starter.colorIndex - 1]};
            }
            if (process.tasks && process.tasks.length) {
                tasksContent = (
                    <ul>
                        {process.tasks.map((subtask, index) => {
                            const tClassName = cn({
                                // 'to-do': true,
                                // 'in-process': subtask.isAssigned,
                                // completed: subtask.isCompleted,
                                // upcoming: subtask.isUpcoming,
                                // inactive: subtask.status === 64 || subtask.status === 1024
                                'not-assigned': true,
                                'in-process': subtask.status === 4,
                                completed: subtask.status === 16,
                                upcoming: subtask.isUpcoming,
                                inactive: subtask.status === 64 || subtask.status === 1024,
                                approved: subtask.status === 128,
                                rejected: subtask.status === 256,
                                'stopped-on-reject': subtask.status === 512
                            });
                            const tStyle = {width: 'calc(' + 100/process.tasks.length + '% - 1px)'};
                            return <li className={tClassName} style={tStyle} key={index}/>;
                        })}
                    </ul>
                );
            }
            container = deviceDetector.isMobile() ? null : (
                <div className="link-hint-template">
                    {process.starter && process.starter.avatarUrl && (
                        <img className="avatar-small" src={process.starter.avatarUrl + '_24'} alt="" height="24" width="24"/>
                    )}
                    {process.starter && !process.starter.avatarUrl && (
                        <div className="avatar-small" style={style}>
                            {utils.getInitials(process.starter.fullName)}
                        </div>
                    )}
                    <div className="full-size-div">
                        <div className="link-hint-template__header">
                            {process.starter && process.starter.fullName}&nbsp;
                            <span>
                                {translate('text.startedThisProcess')}&nbsp;
                                <TimerFromNow start={process.startedDate} />
                            </span>
                        </div>
                        <div className="link-hint-template__content">
                            {tasksContent}
                            <span>{translate('plural.tasks', {COUNT: process.tasks?.length || 0}, true)}</span>
                            <div className="square"/>
                            <div className="top-line"/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <a>
                <span className="process-label">
                    <span className="process-name" onClick={link} onMouseEnter={changeState}>
                        {container}
                        <span>{process.name}</span>
                    </span>
                    {task.name && assign && (
                        <span className="action">
                                <span>&nbsp;•&nbsp;</span>
                                <span className="assign-to-me" onClick={assign}>
                                    {translate('label.assignToMe')}
                                </span>
                        </span>
                    )}
                </span>
            </a>
        );
    }
}

export default ProcessLink;
