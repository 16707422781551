import config from '../../../config';

export function AdminApiCalls($rootScope, PageSettings, $translate, $http, $window) {
    'ngInject';

    let openModal = (errResp) => {
        if (errResp.status === 403) {
            let modalScope = $rootScope.$new();
            modalScope.title = $translate.instant('manageRole.error.title');
            modalScope.text = $translate.instant('manageRole.error.text');
            PageSettings.openAlertModal(modalScope)
                .then(() => $window.location.reload())
                .finally(() => modalScope.$destroy());
        }
    };

    let getCall = (url, params) => {
        let clone = $http({
            method: 'GET',
            url: config.api + url,
            params: params
        }).then(res => res.data);
        clone.catch(openModal);
        return clone;
    };

    let putCall = (url, params, settings) => {
        let clone = $http({
            method: 'PUT',
            url: config.api + url,
            data: params,
            timeout: settings && settings.timeout
        }).then(res => res.data);
        //ignoreCatch: true should be added after code is merged with branch 2307
        if(!settings || !settings.isIgnoreCatch) {
            clone.catch(openModal);
        }
        return clone;
    };

    let postCall = (url, params, settings) => {
        let clone = $http({
            method: 'POST',
            url: config.api + url,
            data: params
        }).then(res => res.data);
        //ignoreCatch: true should be added after code is merged with branch 2307
        if(!settings || !settings.isIgnoreCatch) {
            clone.catch(openModal);
        }
        return clone;
    };
    let deleteCall = (url, headers) => {
        let clone = $http({
            method: 'DELETE',
            url: config.api + url,
            data: {},
            headers: headers
        }).then(res => res.data);
        clone.catch(openModal);
        return clone;
    };

    return {
        getUserInfo: id => {
            return getCall('/users/' + id);
        },
        changeUserPassword: (id, params) => {
            let url = `/users/${id}/password`;
            return putCall(url, params);
        },
        changeUserGroups: (id, params) => {
            let url = `/users/${id}/groups`;
            return putCall(url, params, {timeout: 60000});
        },
        changeUserManager: (id, params) => {
            let url = `/users/${id}/manager`;
            return putCall(url, params, {timeout: 60000});
        },
        changeUserSubordinates: (id, params) => {
            return putCall(`/users/${id}/subordinates`, params, {timeout: 60000})
        },
        disableUser: (id, params) => {
            let url = `/users/${id}/disable`;
            return putCall(url, params, {timeout: 60000});
        },
        enableUser: (id, params) => {
            let url = `/users/${id}/enable`;
            return putCall(url, params, {timeout: 60000});
        },
        resendInvite: (id, params) => {
            let url = `/users/${id}/reinvite`;
            return putCall(url, params);
        },
        cancelInvite: (id, params) => {
            let url = `/users/${id}/cancelinvite`;
            return putCall(url, params);
        },

        renameGroup: (id, params) => {
            let url = `/groups/${id}/rename`;
            return putCall(url, params);
        },
        changeGroupUsers: (id, params) => {
            let url = `/groups/${id}/users`;
            return putCall(url, params, {timeout: 60000});
        },
        deleteGroup: (id, version) => {
            let clone = $http({
                method: 'DELETE',
                url: config.api + '/groups/' + id,
                data: {},
                timeout: 60000,
                headers: {
                    'Optimistic-locking-version': version
                }
            });
            clone.catch(openModal);
            return clone;
        },

        getActiveUsers: (params) => getCall('/users/active', params),
        getDisabledUsers: (params) => getCall('/users/disabled', params),
        getGroups: (params) => getCall('/groups', params),
        invite: params => postCall('/invite', params),
        inviteMulti: params => postCall('/invite_multi', params),
        addGroup: (params, isIgnoreCatch, isIgnoreHandler403) => {
            return postCall('/groups', params, {
                isIgnoreCatch: isIgnoreCatch,
                isIgnoreHandler403: isIgnoreHandler403 // used in merge
            });
        },

        setAdmin: (id, params) => {
            let url = `/users/${id}/setadmin`;
            return putCall(url, params, {timeout: 60000});
        },
        rmAdmin: (id, params) => {
            let url = `/users/${id}/removeadmin`;
            return putCall(url, params, {timeout: 60000});
        },

        getUserWarnings: id => getCall(`/users/${id}/disable/warnings`),
        getGroupWarnings: id => getCall(`/groups/${id}/delete/warnings`),

        getCompanyInfo: () => getCall('/company'),
        updateCompanyInfo: (params, currencyOption) => {
            let url = '/company';
            if (currencyOption) {
                url = `${url}?currencyOption=${currencyOption}`;
            }
            return putCall(url, params);
        },
        deleteCompanyLogo: () => deleteCall('/company/logo'),

        getCompanyCalendar: () => getCall('/company/calendar'),
        updateFirstDayOfWeek: (day) => {
            return putCall('/company/calendar/first_day_of_week', day);
        },
        updateWeekDay: (day) => {
            return putCall('/company/calendar/week_day', day);
        },
        addHoliday: (date) => {
            return postCall('/company/calendar/holiday', date);
        },
        removeHoliday: (date) => {
            return deleteCall(`/company/calendar/holiday/${date}`);  //{ignoreCatch: true} should be added after code is merged with branch 2307
        },
        removeAllYearHolidays: (year) => {
            return deleteCall(`/company/calendar/holiday/all/${year}`); //{ignoreCatch: true} should be added after code is merged with branch 2307
        },

        // Billing
        getCompanyBillingInfo: () => getCall('/company/billing_info'),
        getCompanyInvoices: () => getCall('/company/invoices'),
        getCompanyInvoiceLink: (id) => getCall('/company/invoices/' + id),
        getCompanyCardUpdateUrlEmbed: () => getCall('/company/card_update_url', {embed: true}),
        getCompanyCardUpdateUrlRedirect: () => getCall('/company/card_update_url', {embed: false}),
        getCompanyCardUpdateStatus: (pageId) => getCall('/company/card_update_state/' + pageId),
        getDisabledCompanyCardUpdateUrlEmbed: () => getCall('/disabled/company/card_update_url', {embed: true}),
        getDisabledCompanyCardUpdateUrlRedirect: () => getCall('/disabled/company/card_update_url', {embed: false}),
        getDisabledCompanyCardUpdateStatus: (pageId) => getCall('/disabled/company/card_update_state/' + pageId),
        reactivateDisabledCompany: () => putCall('/disabled/company/reactivate'),
        cancelCompany: (data) => putCall('/company/cancel', data),
        removeCancellation: (data) => putCall('/company/remove_cancellation', data),

        // Integrations
        getApiKeys: () => getCall('/company/api_keys'),
        generateApiKeys: () => postCall('/company/api_keys'),
        deleteApiKeys: () => deleteCall('/company/api_keys/all')
    };
}
