import React from 'react';
import PropTypes from 'prop-types';
import ReportView from '../containers/report-view-container';

class ReportLoader extends React.Component {
    static propTypes = {
        report: PropTypes.object,
        templates: PropTypes.array,
        loadReport: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.loadReport();
    }

    render() {
        const {DevicesDetector, textParser, report, templates} = this.props;
        if (report && templates) {
            return <ReportView
                DevicesDetector={DevicesDetector}
                textParser={textParser}
            />
        }

        return null;
    }
}

export default ReportLoader;
