import { isExponential } from '../modules/processes/utils'

export function numericFormat($filter) {
    'ngInject';

    return (input, precision) => {
        if (!input) {
            return input;
        }

        if (isExponential(input)) {
            return input.toString()
        }

        let start = '';
        let value = String(input);
        if (value.length && value[0] === '-') {
            start = '-';
        }
        let temp = value.replace(/[^0-9\.]/g, '');
        let decimalsFlag = temp.match(/\./g);
        temp = temp.split('.');
        let rest = temp.pop();

        if ((!temp || !temp.length) && !decimalsFlag) {
            temp = $filter('number')(rest);
        } else {
            temp = $filter('number')(temp.join(''));
        }

        let resNumber = start + temp.replace(/[\,]/g, ' ');
        if (decimalsFlag) {
            resNumber = [resNumber, rest.slice(0, precision)].join('.');
        }
        return resNumber;
    };
}
