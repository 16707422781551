import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ProcessLink from './process-link.jsx';
import TimerFromNow from './timer-from-now.jsx';
import Tooltip from './tooltip.jsx';
import devicesDetector from './device-detector.js';
import utils from './utils.js';
import {constants} from '../../services/constants';
import ActionsDropdown from '../../components/react/dropdown/actions-dropdown.jsx';
import { translate } from './utils.js'

const STATUS_APPROVED = 128;
const STATUS_REJECTED = 256;

class TasksListTile extends React.Component {
    static propTypes = {
        task: PropTypes.object,
        node: PropTypes.object,
        currentUser: PropTypes.object,
        tasksGroup: PropTypes.number,
        activeMode: PropTypes.string,
        functions: PropTypes.object,
        config: PropTypes.object,
        payload: PropTypes.object,
        index: PropTypes.number,
        isLast: PropTypes.bool
    }

    constructor(props) {
        super(props)
        this.state = {
            animated: false,
            inFocus: false,
            calendarIsOpened: false
        }
    }

    changeState () {
        this.setState({ animated: true })
    }

    componentDidUpdate () {
        if (this.state.animated) {
            this.setState({ animated: false })
        }
    }

    render() {
        const {task, node, currentUser, tasksGroup, activeMode, functions, config, payload, index, isLast} = this.props;
        if (!task) {
            return null;
        }
        const {TYPE} = constants.TASK;
        const taskClassName = cn({
            tasks__main__content__task: true,
            animation: this.state.animated,
            animated: utils.checkPermission(task, 'view'),
            first: index === 0,
            completed: task.isCompleted,
            adHoc: !task.process && tasksGroup !== 1,
            small: node.process && activeMode === 'My',
            initial: !task.name,
            'calendar-opened': this.state.calendarIsOpened || this.state.inFocus
        });
        const taskDueDateClass = cn({
            dueDate: true,
            warn: task.isWarnDue
        });
        const delegatedAvatarClassName = cn({
            avatar: true,
            relative: task.isCompleted
        });
        const delegatedAvatarStyle = {background: task.assigneeColor};
        let completedByLabel = null;
        if (task.completedBy) {
            completedByLabel = task.completedBy.id === currentUser.id
                ? translate('task.status.completedByMe')
                : translate('task.status.completedByName', {NAME: task.completedBy.fullName});
        }

        const taskView = () => functions.goToTask(task, activeMode, tasksGroup);
        const processView = (event) => functions.goToProcess(task, event);
        const toggleState = (event) => {
            if (!task.isCompleted && (task.hasEmptyRequiredFields || !utils.checkPermission(task, 'complete'))) {
                event.stopPropagation();
                return;
            }
            this.changeState();
            functions.toggleState(task, event);
        };
        const toggleApprovalState = (event) => {
            if (task.hasEmptyRequiredFields || !(utils.checkPermission(task, 'approve') || utils.checkPermission(task, 'reopen'))) {
                event.stopPropagation();
                return;
            }
            this.changeState();
            functions.toggleApprovalState(task, event);
        };
        const deleteTask = (event) => functions.delete(task, event);

        const showDueDateCalendar = (event) => {
            if(event) {
                event.stopPropagation();
            }
            let result = functions.showDueDateCalendar(task, this.dueDateCalendarIcon, (taskId) => {
                if(taskId === task.id) {
                    this.setState({
                        calendarIsOpened: false
                    });
                }
            });
            if(result) {
                this.setState({
                    calendarIsOpened: true
                });
            }
        };
        const isDisabled = (task.isCompleted || task.hasEmptyRequiredFields) && !this.state.animated;
        const checkboxClass = cn({
            'tooltip-view': isDisabled,
            disabled: isDisabled,
            'no-hover': devicesDetector.isMobile()
        });
        const approvalClassName = cn({
            'approval-default': true,
            approved : (task.type === TYPE.APPROVAL || task.type === TYPE.REVIEW) && task.status === STATUS_APPROVED,
            rejected : (task.type === TYPE.APPROVAL || task.type === TYPE.REVIEW) && task.status === STATUS_REJECTED,
            disabled: task.hasEmptyRequiredFields
        });

        let approveTaskTooltip = 'tooltips.approveTask';
        switch (task.status) {
            case STATUS_APPROVED:
                approveTaskTooltip = utils.checkPermission(task, 'reopen') ? 'tooltips.reopenApproval' : 'tooltips.reopenRestrictedForApproved';
                break;
            case STATUS_REJECTED:
                approveTaskTooltip = utils.checkPermission(task, 'reopen') ? 'tooltips.reopenApproval' : 'tooltips.reopenRestrictedForRejected';
                break;
            default:
                break;
        }

        return (
            <div className={taskClassName} onClick={taskView}>
                <div>
                    {activeMode !== 'Delegated' && task.type === 'SIMPLE' && (
                        <div className="checkbox" onClick={toggleState}>
                            {!task.completedBy && task.name && (
                                <Tooltip text="tooltips.completeTask" isDisabled={isDisabled}>
                                    <input type="checkbox" className={checkboxClass}/>
                                </Tooltip>
                            )}
                            {task.completedBy && (
                                <Tooltip text="tooltips.reopenTask">
                                    <input type="checkbox" disabled={!task.isCompleted}/>
                                </Tooltip>
                            )}
                            <span className={cn('checkbox-control', {checked: task.isCompleted})}>
                                <i className="icon-menu_checkmark"/>
                            </span>
                        </div>
                    )}
                    {activeMode !== 'Delegated' && (task.type === TYPE.APPROVAL || task.type === TYPE.REVIEW) && (
                        <div className={approvalClassName} onClick={toggleApprovalState}>
                            <Tooltip text={approveTaskTooltip} isDisabled={task.hasEmptyRequiredFields}>
                                <div className="task-list-approval-tooltip" />
                            </Tooltip>
                            <i className="icon-menu_checkmark"/>
                            <i className="icon-common_close"/>
                        </div>
                    )}
                    {task.name && !task.isAssigned && activeMode === 'Delegated' && (
                        <div className="avatar group">
                            <i className="icon-assignee_unassigned"/>
                        </div>
                    )}
                    {task.assignee && !task.assignee.avatarUrl && activeMode === 'Delegated' && (
                        <div className={delegatedAvatarClassName} style={delegatedAvatarStyle}>
                            {utils.getInitials(task.assignee.fullName)}
                        </div>
                    )}
                    {task.assignee && task.assignee.avatarUrl && activeMode === 'Delegated' && (
                        <img className={delegatedAvatarClassName} src={task.assignee.avatarUrl + '_40'} height="40" width="40" alt=""/>
                    )}
                    {task.name && (
                        <div className="tasks__main__content__task__info">
                            <h4>{task.name}</h4>
                            {task.process && (!node.process || !node.process.id) && (
                                <ProcessLink task={task} process={task.process} config={config} payload={payload} link={processView}/>
                            )}
                            {!task.process && task.completedBy && (
                                <a href="" className="delegated">
                                    {completedByLabel}
                                </a>
                            )}
                            {!task.isCompleted && !task.completedBy && task.isAssigned && activeMode === 'Delegated' && (
                                <a href="" className="delegated">
                                    <span>{translate('label.assigned')}</span>&nbsp;
                                    <TimerFromNow start={task.assignedDate} />&nbsp;
                                    {translate('label.to')}&nbsp;
                                    <span className="name">{task.assignee.fullName}</span>
                                </a>
                            )}
                            {!task.isCompleted && !task.isAssigned && activeMode === 'Delegated' && (
                                <a href="" className="delegetad">
                                    {translate('label.notAssigned.candidates')}&nbsp;
                                    <span className="name">{task.actorsNames}</span>
                                </a>
                            )}
                            {!task.process && !task.isCompleted && activeMode === 'My' && task.creator && currentUser.id !== task.creator.id && (
                                <span className="descr wrap-table">
                                    {`${translate('label.delegatedToYouBy')} ${task.creator.fullName}`}
                                </span>
                            )}
                        </div>
                    )}
                </div>
                <div className="rest">
                    <div className="tasks__main__content__task__hint">
                        {!!task.commentsCount && (
                            <span className="task-comments">
                                <i className="icon-task_comment"/>{task.commentsCount}
                            </span>
                        )}
                        {!!task.attachmentsCount && (
                            <span className="task-attachments">
                                <i className="icon-task_attachment"/>{task.attachmentsCount}
                            </span>
                        )}
                        {task.dueDate && !task.completedDate && (
                            <span className={taskDueDateClass}>
                                {task.formattedDueDate}
                            </span>
                        )}
                    </div>
                    {task.name && !devicesDetector.isMobile() && (
                        <div className="tasks__main__content__task__hover_actions" onClick={(e) => e.stopPropagation()}>
                            {(activeMode === 'My' && utils.checkPermission(task, 'editDueDate')) && (
                                <Tooltip text="tooltips.taskView.dueDate">
                                    <span className="action"
                                          onClick={showDueDateCalendar}
                                          ref={(input) => { this.dueDateCalendarIcon = input; }}>
                                        <i className="icon-task_due_date_add"/>
                                    </span>
                                </Tooltip>
                            )}
                            {!isLast && utils.checkPermission(task, 'delete') && (
                                <ActionsDropdown
                                    disabled={!utils.checkPermission(task, 'delete')}
                                    onOpen={() => this.setState({inFocus: true})}
                                    onClose={() => this.setState({inFocus: false})}
                                >
                                    <ul className="actions-list">
                                        <li onClick={deleteTask} className="actions-list-item">
                                            <i className="icon-delete_roundless" /> <span>{translate('label.delete')}</span>
                                        </li>
                                    </ul>
                                </ActionsDropdown>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TasksListTile;
