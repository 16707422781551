import config from '../config';
import utils from '../utils';

export function CompanyCurrenciesService($http, companyCurrencies) {
    'ngInject';

    return {
        reload: (token) => {
            return $http({
                method: 'GET',
                url: config.api + '/company/currencies',
                headers: {
                    'X-Auth-Token': token || utils.localStorage.get('satellizer_token')
                }
            }).then(resp => {
                if (resp.data) {
                    angular.copy(resp.data.result, companyCurrencies);
                }
                return resp;
            });
        }
    };
}
