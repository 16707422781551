import { flow, getParent, types } from 'mobx-state-tree'
import { DataTypedValue } from './datatypes'
import { DataTypeOptions } from './datatype-options'
import { getService, translate } from '../../react/utils'
import * as dataTypes from '../../../services/data-types'
import { FILES_LIST } from '../../../services/data-types'
import { getDataTypeSettings, getPreparedDefaultValue } from '../../../services/data-types-service'
import { orderColumns, prepareColumnSnapshot } from './database-utils'
import { DEFAULT_COLUMN_WIDTH, DEFAULT_COLUMN_WIDTHS } from '../../../components/react/data-grid'
import { callAlertTaskPageModal, callDeletedRecordErrorModal } from './index'

const { string, number, maybe } = types

export const Record = types.model('Record', {
    id: types.identifier,
    values: types.optional(types.array(DataTypedValue), [])
}).actions(self => ({
    delete: flow(function * () {
        try {
            const table = getParent(getParent(self))
            yield getService('ApiCalls').deleteTableRecord(table.id, self.id)
            table.getRecords()
        } catch (e) {
            console.log(`Error while deleting record:`, e)
            e.status === 515 ? callDeletedRecordErrorModal(e) : callAlertTaskPageModal(e)
        }
    })
}))

const COLUMN_NAME_MIN_LENGTH = 3
const COLUMN_NAME_MAX_LENGTH = 100

export const Column = types.model('Column', {
    id: maybe(string),
    name: string,
    originalName: maybe(string),
    dataType: string,
    isTitle: types.optional(types.boolean, false),
    order: types.optional(types.number, 0),
    settings: maybe(DataTypeOptions),
    width: maybe(number),
    isFixed: types.optional(types.boolean, false)
}).views(self => ({
    get canBeTitle () {
        return !self.isTitle && self.dataType === dataTypes.STRING_SINGLE_LINE
    },
    get nameIsChanged () {
        return (self.originalName && self.originalName !== self.name)
    },
    get isChanged () {
        return !self.id || self.nameIsChanged || (self.settings && self.settings.isChanged)
    },
    get defaultWidth () {
        return DEFAULT_COLUMN_WIDTHS[self.dataType] || DEFAULT_COLUMN_WIDTH
    },
    get nameError () {
        if (!self.name) {
            return translate('validation.required')
        } else if (self.name.length < COLUMN_NAME_MIN_LENGTH) {
            return translate('validation.minLength', {value: COLUMN_NAME_MIN_LENGTH})
        } else if (self.name.length > COLUMN_NAME_MAX_LENGTH) {
            return translate('validation.maxLength', {value: COLUMN_NAME_MAX_LENGTH})
        }
        return undefined
    }
}))

export function createColumnForm (column, table) {
    const ColumnFormActions = types.model({}).actions(self => {
        const ApiCalls = getService('ApiCalls')
        return {
            changeName: value => {
                if (!self.originalName) {
                    self.originalName = self.name
                }
                self.name = value
            },
            changeDataType: value => {
                if (self.dataType !== value) {
                    self.dataType = value
                    self.settings = getDataTypeSettings(self.dataType)
                    self.settings.initialize()
                }
            },
            toServerJSON: () => {
                const { settings, ...column } = self.toJSON()
                const { defaultValue, ...options } = self.settings.toServerJSON()
                return { ...column, options, defaultValue }
            },
            save: flow(function * () {
                try {
                    yield table.getInfo()
                    const { result } = yield ApiCalls.createTableColumn(table.id, self.toServerJSON(), table.version)
                    table.changeVersion(result.tableVersion)
                    table.getRecords()
                } catch (e) {
                    console.log(`Error while creating new column:`, e)
                    callAlertTaskPageModal(e)
                }
            }),
            update: flow(function * () {
                try {
                    if (self.nameIsChanged) {
                        const response = yield ApiCalls.modifyTableColumn(table.id, self.id, {
                            field: 'name',
                            name: self.name
                        }, table.version)
                        self.originalName = undefined
                        table.changeVersion(response.result.tableVersion)
                        table.updateColumn(response.result.column)
                    }
                    if (self.settings.isChanged) {
                        const { defaultValue, ...options } = self.settings.toServerJSON()
                        const params = {
                            field: 'options',
                            options,
                            defaultValue
                        }
                        self.settings.save()
                        yield ApiCalls.modifyTableColumn(table.id, self.id, params, table.version)
                        table.getInfo().then(() => {
                            table.getRecords()
                        })
                    }
                } catch (e) {
                    console.log(`Error while updating column:`, e)
                    callAlertTaskPageModal(e)
                }
            })
        }
    })

    const ColumnForm = types.compose('ColumnForm', Column, ColumnFormActions)

    const columnProps = column
        ? { // Existed column
            id: column.id,
            name: column.name,
            dataType: column.dataType,
            settings: { ...column.settings.toJSON(), dataType: column.dataType }
        } : { // New column
            name: translate('text.database.columnName', {INDEX: table.columns.length + 1}),
            dataType: dataTypes.DEFAULT_DATA_TYPE,
            settings: getDataTypeSettings(dataTypes.DEFAULT_DATA_TYPE)
        }

    return ColumnForm.create(columnProps)
}

const RecordFormColumn = types.compose(
    'RecordFormColumn',
    Column,
    types.model({
        value: DataTypedValue,
        _error: maybe(string)
    }).actions(self => ({
        validate () {
            self._error = ''
            self.value.validate(self.settings)
            if (self.isTitle && self.value.isEmpty) {
                self._error = translate('validation.required')
            }
        },
        update (value, { tableId, recordId } = {}) {
            self.value.update(value, { tableId, columnId: self.id, recordId })
            self.validate()
        }
    })).views(self => ({
        get error () {
            return self._error || self.value.error || (self.value.isInvalid ? translate('validation.userField.invalidOption') : undefined)
        },
        get isValid () {
            return self.value.isValid
        }
    }))
)

export function createRecordForm (record, table) {
    const RecordForm = types
        .model(
            'RecordForm',
            {
                id: maybe(string),
                columns: types.array(RecordFormColumn)
            })
        .views(self => {
            return {
                get titleColumn () {
                    return self.columns.find(c => c.isTitle)
                },
                get title () {
                    if (!self.titleColumn.value.isEmpty) {
                        return self.titleColumn.value.displayValue
                    }
                    return `[${self.titleColumn.name}]`
                },
                get titleIsEmpty () {
                    return self.titleColumn.value.isEmpty
                }
            }
        })
        .actions(self => {
            const promises = {}
            const ApiCalls = getService('ApiCalls')
            return {
                applyColumnSettings: function (record, columns) {
                    self.columns = []
                    columns.forEach(column => {
                        const { settings, ...columnProps } = column
                        let value = record && record.values
                            ? record.values.find(v => v.columnId === column.id)
                            : undefined

                        if (!record && settings.defaultValue) { // Apply default value for new record
                            value = getPreparedDefaultValue(settings)
                        }

                        const recordColumn = RecordFormColumn.create({
                            ...columnProps,
                            settings: { ...settings, dataType: column.dataType },
                            value: { ...value, dataType: column.dataType, columnId: column.id }
                        })
                        recordColumn.validate()
                        self.columns.push(recordColumn)
                    })
                },
                getRecordData: flow(function * () {
                    try {
                        const response = yield ApiCalls.getTableRecord(table.id, self.id)

                        if (table.version !== response.tableVersion) {
                            table.getInfo().then(() => {
                                table.getRecords()
                            })
                        }

                        const record = response.list[0]

                        if (record) {
                            record.values.forEach((v, i) => {
                                const { id, isInvalid, value } = v
                                record.values[i] = { id, isInvalid, ...value, columnId: response.columns[i].id }
                            })
                            self.applyColumnSettings(record, orderColumns(response.columns).map(c => prepareColumnSnapshot(c)))
                            table.updateRecord({
                                id: record.id,
                                values: self.columns.map(c => c.value)
                            })
                        }
                    } catch (e) {
                        e.status === 515 ? callDeletedRecordErrorModal(e) : callAlertTaskPageModal(e)
                    }
                }),
                updateColumnValue (columnIndex, value) {
                    const column = self.columns[columnIndex]
                    column.update(value, { tableId: table.id, recordId: self.id })
                    if (column.isValid && (column.dataType !== FILES_LIST || !self.id)) {
                        self.saveColumnValue(columnIndex)
                    }

                    if (column.dataType === FILES_LIST && self.id) {
                        table.updateRecord({
                            id: self.id,
                            values: self.columns.map(column => column.value)
                        })
                    }
                },
                saveColumnValue: function (columnIndex) {
                    clearTimeout(promises[columnIndex])
                    promises[columnIndex] = setTimeout(() => {
                        self.saveColumnValueAction(columnIndex)
                    }, 1000)
                },
                saveColumnValueAction: flow(function * (columnIndex) {
                    const column = self.columns[columnIndex]

                    if (!column.isValid) {
                        column.value.saved(false)
                        return
                    }
                    try {
                        column.value.setError()
                        column.value.saving()

                        if (self.id) {
                            const { result } = yield ApiCalls.modifyTableRecord(table.id, column.id, self.id, {
                                value: !column.value.isEmpty ? column.value.toServerJSON() : { noValue: true }
                            })
                            column.value.saved()
                            column.value.setValidity(!result.isInvalid)
                            table.updateRecord({
                                id: self.id,
                                values: self.columns.map(c => c.value)
                            })
                            table.getRecords()
                            if (table.version !== result.tableVersion) {
                                self.getRecordData()
                            }
                        } else {
                            const { result } = yield ApiCalls.createTableRecord(table.id, {
                                values: self.columns.map(c => {
                                    return {
                                        columnId: c.id,
                                        value: !c.value.isEmpty ? c.value.toServerJSON() : { noValue: true }
                                    }
                                })
                            })

                            self.id = result.id
                            column.value.saved()
                            if (result.values && result.values.length) {
                                self.columns.forEach((c, i) => {
                                    c.value.setValidity(!result.values[i].isInvalid)
                                })
                            }
                            table.addRecord({
                                id: self.id,
                                values: self.columns.map(column => column.value)
                            })
                            table.getRecords()
                            if (table.version !== result.tableVersion) {
                                self.getRecordData()
                            }
                        }
                    } catch (e) {
                        column.value.saved(false)
                        if (e.data && e.data.value) {
                            column.value.setError(e.data.value)
                        } else {
                            callAlertTaskPageModal(e)
                        }
                    }
                })
            }
        })

    const recordFormEntity = RecordForm.create({ id: record ? record.id : undefined, columns: [] })
    recordFormEntity.applyColumnSettings(record, table.columnsOrdered.map(c => c.toJSON()))

    return recordFormEntity
}
