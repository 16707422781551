import { getFormattedRecordValues } from '../../database/models/database-utils'
import { DATE_AND_TIME, DATE_ONLY_VALUE } from '../../../services/data-types'

export function taskGroupConditionsViewer($translate, ConditionsConfig, DateHelper) {
    'ngInject';

    return {
        restrict: 'E',
        scope: true,
        bindToController: {
            item: '<',
            formFields: '<',
            isGroup: '@?'
        },
        template: `
<div class="task-group-conditions-viewer">
    <div class="summary">{{::conditionsViewCtrl.getSummary() | translate}}:</div>
    <ul class="conditions">
        <li ng-repeat="condition in conditionsViewCtrl.item.conditions.list">
            <span class="field-label">{{::condition.field.label}}</span>
            <span class="operation">{{::conditionsViewCtrl.getOperationLabel(condition)}}</span>
            <span class="expression" ng-hide="!condition.expression">{{::conditionsViewCtrl.getExpressionValue(condition)}}</span>
        </li>
    </ul>
</div>
        `,
        controllerAs: 'conditionsViewCtrl',
        controller: function() {
            this.formFields = [];
            this.isGroup = false;

            this.getSummary = () => {
                if (this.item.conditions.rule === ConditionsConfig.OPERATORS.AND) {
                    return this.isGroup
                        ? 'taskConditions.templateView.groupSummary.and'
                        : 'taskConditions.templateView.taskSummary.and';
                }

                if (this.item.conditions.rule === ConditionsConfig.OPERATORS.OR) {
                    return this.isGroup
                        ? 'taskConditions.templateView.groupSummary.or'
                        : 'taskConditions.templateView.taskSummary.or';
                }
            };

            this.getOperationLabel = (condition) => {
                const operation = ConditionsConfig.AVAILABLE_OPERATIONS.find(item => item.id === condition.operation.id);
                return operation ? $translate.instant(operation.label) : '';
            };

            this.getExpressionValue = (condition) => {
                const {expression} = condition;
                const formField = this.formFields.find(field => field.id === condition.field.id);

                if (!formField || !expression) {
                    return '';
                }
                if (expression.number !== undefined) {
                    return expression.number;
                }
                if (expression.string !== undefined) {
                    return expression.string;
                }
                if (expression.record !== undefined && expression.record.recordId !== undefined) {
                    return getFormattedRecordValues(expression.record).name
                }
                if (expression.date !== undefined && formField.name.dataType === DATE_ONLY_VALUE) {
                    return DateHelper.timestampToDate(expression.date);
                }
                if (expression.date !== undefined && formField.name.dataType === DATE_AND_TIME) {
                    return DateHelper.timestampToDateTime(expression.date);
                }
            };
        }
    };
}
