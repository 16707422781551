export function touchEnd() {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            touchEnd: '&?'
        },
        link: (scope, elem, attrs) => {
            const listener = (event) => {
                if (!attrs.skipPreventing) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                if (scope.touchEnd) {
                    scope.touchEnd();
                }
            };
            elem.bind('touchend', listener);

            scope.$on('$destroy', () => {
                elem.unbind('touchend', listener);
            });
        }
    };
}
