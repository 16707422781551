import XRegExp from 'xregexp';

const AuthNeededStates = [
    'login', 'signup', 'signup-start', 'reset', 'reset-start', 'invite', 'error', 'confirm', 'team', 'onboarding', 'accountDisabled'
];

export const SSO_SIGN_IN_TARGET_KEY = `ssoSignInTarget`

let config = {
    moduleName: 'metatask',
    api: '/api',
    // capterra
    capterra_vkey: '9844828a65627dc7ef01ef9da7169233',
    capterra_vid: '2108543',
    // redirect
    redirect_timeout: 0,
    errorDelay: 3000,
    // translate
    sanitize_strategy: 'escape',
    // states paths
    state_main: 'main',
    state_home: 'home',
    state_login: 'login',
    // cache
    templates_cache_dir: ['/pages/', '/secured/pages/'],
    // states without login
    states_without_login: AuthNeededStates,
    auth_forms: ['loginForm', 'resetForm', 'signupForm', 'inviteForm', 'teamForm', 'profileForm'],

    isAuthNeededState: state => {
        let base = state.name.split('.')[0];
        return AuthNeededStates.indexOf(base) === -1;
    },

    allowedFileFormats: ['numbers', 'pages', 'key'],
    fileNameMinLength: 1,
    fileNameMaxLength: 100,
    maxFilesCount: 10,

    //pattern: XRegExp("^[0-9!@#$%\^\[\]\\\/|&* )(+=.,:;'\x22`~<>{}?_-]*[\\pL+]*[\\pN+]*$")
    pattern: XRegExp('^[\\pL\\pM\\pN\x00-\x7F\u0000-\u007F!@#№$%\^&\\`]*$'),
    pattern_adhoc: XRegExp('[\\pL\\pM\\pN\x00-\x7F\u0000-\u007F!@#№$%\^&\\`]*', 'g'),
    pattern_names: XRegExp('[\\pL\\pM\\pN \'-]*', 'g'),
    pattern_groups_names: XRegExp('[^\\p{L}\\p{N}\\p{P}\\p{S}\\p{Z}]', 'g'),
    pattern_invisible_control_char: XRegExp('\\p{C}', 'g'),
    pattern_whitespace_separator: XRegExp('\\p{Z}', 'g'),
    pattern_multiple_whitespaces: XRegExp('\\s{2,}', 'g'),
    pattern_passwords: XRegExp('^[A-Za-z\\pN+\x00-\x7F]*$'),
    pattern_extension: /(?:\.([^.]+))?$/,
    pattern_emails: XRegExp('^[\\pL\\pM\\pN\.!#$%&\'*+/=?^_`{|}~-]+@[\\pL\\pM\\pN](?:[\\pL\\pM\\pN-]{0,61}[\\pL\\pM\\pN])?(?:\\.[\\pL\\pM\\pN](?:[\\pL\\pM\\pN-]{0,61}[\\pL\\pM\\pN])?)*$'),
    pattern_number: /^([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/g,
    getDecimals: num => {
        let match = String(num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? Number(match[2]) : 0));
    },
    minimal_entropy: 50
};
export default config;
