export function perLineText($timeout) {
    'ngInject';
    return {
        require: '?ngModel',
        scope: {
            ngModel: '='
        },
        link: (scope, elem, attrs, ctrl) => {
            if (!ctrl) {
                return;
            }

            let resetValidity = () => {
                ctrl.$setValidity('notUnique', true);
                ctrl.$setValidity('empty', true);
                ctrl.$setValidity('itemMaxlength', true);
                let scrollContainer = document.querySelector('.textarea-wrapper'); //eslint-disable-line
                if (scrollContainer) {
                    scrollContainer.scrollLeft = 0;
                    elem[0].style.width = '100%';
                    elem[0].style.height = '100px';
                }
            };

            let updateSize = () => {
                elem[0].style.overflow = 'hidden';
                elem[0].style.width = 'auto';
                elem[0].style.height = 'auto';
                elem[0].style.width = elem[0].scrollWidth + 'px';
                elem[0].style.height = elem[0].scrollHeight + 'px';
            };
            $timeout(() => {
                updateSize();
            });

            elem[0].addEventListener('input', () => {
                elem[0].value = elem[0].value.replace(/^\s+/, '').replace(/[\u0009]/g, ' ');
                ctrl.$setViewValue(elem[0].value);
                updateSize();
            });

            ctrl.$formatters.push(viewValue => {
                if (!viewValue) {
                    resetValidity();
                }
                return viewValue;
            });

            ctrl.$parsers.unshift(viewValue => {
                if (!viewValue) {
                    resetValidity();
                    return viewValue;
                }
                let lines = elem[0].value.split('\n');
                let maxLength = attrs.perLineText || 30;
                let notUnique, notEmpty, itemMaxlength;

                lines.forEach((l, i) => {
                    if (lines.filter(lu => l.trim().toLowerCase() === lu.trim().toLowerCase()).length > 1) {
                        ctrl.$setValidity('notUnique', false);
                        notUnique = true;
                    }
                    if ((!l.trim() && (lines.length - 1) !== i) || (l && !l.trim() && (lines.length - 1) === i)) {
                        ctrl.$setValidity('empty', false);
                        notEmpty = true;
                    }
                    if (l && l.length > maxLength) {
                        ctrl.$setValidity('itemMaxlength', false);
                        itemMaxlength = true;
                    }
                });
                if (!notUnique) {
                    ctrl.$setValidity('notUnique', true);
                }
                if (!notEmpty) {
                    ctrl.$setValidity('empty', true);
                }
                if (!itemMaxlength) {
                    ctrl.$setValidity('itemMaxlength', true);
                }

                viewValue = lines.join('\n');
                ctrl.$setViewValue(viewValue);
                return viewValue;
            });
        }
    };
}
