import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './image-viewer.less'

export default class ImageViewer extends React.Component {
    static propTypes = {
        src: PropTypes.string,
        fit: PropTypes.bool,
        angle: PropTypes.number,
        onImageClick: PropTypes.func
    }

    static defaultProps = {
        fit: true,
        angle: 0
    }

    constructor (props) {
        super(props)

        this.imageLoader = null

        this.state = {
            image: null,
            container: null
        }

        this.onImageClick = this.onImageClick.bind(this)

        this.viewport = React.createRef()
    }

    componentDidMount () {
        this.container = ReactDOM.findDOMNode(this).parentNode
        const { height, width } = this.container.getBoundingClientRect()
        this.setState({
            container: { height, width }
        })
    }

    componentDidUpdate (prevProps, prevState) {
        if (!this.imageLoader || this.imageLoader.src !== this.props.src) {
            this.setState({ image: null })
            this.imageLoader = new Image()
            this.imageLoader.src = this.props.src
            this.imageLoader.onload = () => {
                this.setState({
                    image: {
                        width: this.imageLoader.width,
                        height: this.imageLoader.height
                    }
                })
            }
        }

        if (prevProps.fit !== this.props.fit) {
            this.shouldBeScrolled = true
        }

        if (this.viewport.current && this.shouldBeScrolled) {
            const { container } = this.state
            const { width, height } = this.viewport.current.children[0].getBoundingClientRect()

            if (width > container.width) {
                this.viewport.current.scrollLeft = (width - container.width) / 2
            }
            if (height > container.height) {
                this.viewport.current.scrollTop = (height - container.height) / 2
            }
            this.shouldBeScrolled = false
        }
    }

    onImageClick (e) {
        e.stopPropagation()
        if (this.props.onImageClick) {
            this.props.onImageClick()
        }
    }

    getStyles () {
        const { image, container } = this.state
        const { fit, angle } = this.props
        if (image) {
            const imageWidth = (angle === 0 || angle === 180) ? image.width : image.height
            const imageHeight = (angle === 0 || angle === 180) ? image.height : image.width

            const W = container.width / imageWidth
            const H = container.height / imageHeight
            const scaleFactor = Math.min(W, H)
            const scale = fit ? Math.min(1, scaleFactor) : Math.max(1, scaleFactor)

            const scaledWidth = imageWidth * scale
            const scaledHeight = imageHeight * scale

            const imageStyles = {}

            if (angle === 90 || angle === 270) {
                imageStyles.width = scaledHeight
            }

            return {
                containerStyles: {
                    width: scaledWidth,
                    height: scaledHeight,
                    marginLeft: scaledWidth <= container.width ? (container.width - scaledWidth) / 2 : 0,
                    marginTop: scaledHeight <= container.height ? (container.height - scaledHeight) / 2 : 0,
                    cursor: fit ? 'zoom-in' : 'zoom-out'
                },
                imageStyles
            }
        }
        return {}
    }

    render () {
        const { image } = this.state
        const { src, angle } = this.props
        const { containerStyles = {}, imageStyles = {} } = this.getStyles()

        if (image) {
            return (
                <div className={cn('image-viewer', `rotate${angle}`)} ref={this.viewport}>
                    <div style={containerStyles} onClick={this.onImageClick}>
                        <img src={src} alt="" style={imageStyles}/>
                    </div>
                </div>
            )
        }

        return (
            <span className="new-loading-spinner"/>
        )
    }
}
