import Drop from 'tether-drop'

export function selectFieldEdition() {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            types: '<',
            field: '<',
            action: '&',
            isProcessStart: '<'
        },
        template: require('./select-field-edition.html'),
        link: (scope, element) => {
            let drop = new Drop({
                target: element.find('.handler')[0],
                content: element.find('.dropdown-menu--field-edition')[0],
                position: 'bottom left',
                openOn: 'click'
            });

            scope.isActive = (type) => {
                return scope.field && type.name === scope.field.settings.visibility;
            };

            scope.isVisible = (type) => {
                const hiddenOptionForUsedField = scope.field && scope.field.isUsed && type.name === 'HIDDEN'
                const notAvailableForFormula = scope.field && scope.field.$isFormula && (type.name === 'EDITABLE' || type.name === 'REQUIRED')
                return !hiddenOptionForUsedField && !notAvailableForFormula
            }

            scope.getIcon = () => {
                let type = scope.types.find(t => scope.field && t.name === scope.field.settings.visibility) || scope.types[0];
                return type.icon;
            };

            scope.getTooltip = () => {
                return scope.isProcessStart
                    ? 'tooltips.templateEdit.processStartFields.fieldEdition'
                    : 'tooltips.templateTaskDataModel.fieldEdition';
            };

            scope.setType = type => {
                scope.action({value: type.name, fieldId: scope.field.fieldId});
                drop.close();
            };

            scope.getTypeLabel = type => {
                const item = scope.isProcessStart ? 'process' : 'task'
                return type.label[item]
            }

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
