export default {
    isSessionStorageSupported: () => {
        let testKey = 'test';
        let storage = window.sessionStorage; //eslint-disable-line
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch(e) {
            return false;
        }
    }
};