import utils from '../../../utils';

export function processCommentMention(currentUser, PageSettings, $window) {
    'ngInject';
    return {
        require: 'uiMention',
        link: function(scope, $element, $attrs, uiMention) {
            const recalcCommentForm = () => PageSettings.recalcElementTextIndent($element);
            recalcCommentForm();

            uiMention.label = choice => {
                if (!choice || !choice.fullName) {
                    return;
                }
                return '@' + choice.fullName.split(' ').join('');
            };

            uiMention.findChoices = (match) => {
                uiMention.searchQuery = match[1] ? match[1].toLowerCase() : null;
                let choices = utils.getMentionsList(
                  currentUser,
                  scope.section.task,
                  scope.commentsTree.find(c => c.task.id === scope.section.task.id).comments,
                  scope.allUsers,
                  scope.tree,
                  !uiMention.searchQuery,
                  scope.process
                )
                    .filter(choice => {
                        if (uiMention.searchQuery) {
                            let parts = uiMention.searchQuery.split(' ');
                            let queryRest = parts && parts.length > 1 ? parts.slice(1).join(' ') : '';

                            if (!parts[0]) {
                                return [];
                            }

                            let nameParts = choice.fullName.toLowerCase().split(' ');
                            let rest = nameParts && nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

                            if (parts.length) {
                                if (queryRest) {
                                    return (nameParts[0].indexOf(parts[0]) === 0
                                        && rest && rest.indexOf(queryRest) === 0)
                                        || (rest && rest.indexOf(uiMention.searchQuery) === 0);
                                } else {
                                    return nameParts[0].indexOf(parts[0]) === 0
                                        || (nameParts[1] && nameParts[1].indexOf(parts[0]) === 0)
                                        || (rest && rest.indexOf(uiMention.searchQuery) === 0);
                                }
                            } else {
                                return nameParts[0].indexOf(parts[0]) === 0
                                    || (nameParts[1] && nameParts[1].indexOf(parts[0]) === 0);
                            }
                        }
                        return true;
                    });
                uiMention.withCommentators = choices.find(user => user.isCommentator);
                return choices;
            };

            angular.element($window).on('resize', recalcCommentForm);

            scope.$on('$destroy', () => {
                angular.element($window).off('resize', recalcCommentForm);
            });
        }
    };
}
