export function without(PageSettings) {
    'ngInject';
    return (input, field) => {
        if (!input) {
            return input;
        }

        return input.filter(item => {
            if (PageSettings[field]) {
                return item.id !== PageSettings[field].id;
            }
            return item;
        });
    };
}
