import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../libs/colors.js'
import deviceDetector from './device-detector.js'
import utils, { translate } from './utils.js'

class UserTooltip extends React.Component {
    static propTypes = {
        actor: PropTypes.object
    }

    getGroupsStr (user) {
        if (user.groups && user.groups.length > 3) {
            let count = user.groups.length - 3
            let begin = user.groups.slice(0, 3).map(g => g.name).join(', ') + ' '
            let end = translate('text.andMoreGroups', { count }, true)
            return begin + end
        }
        return user.groups ? user.groups.map(group => group.name).join(', ') : null
    }

    render () {
        if (deviceDetector.isMobile()) {
            return null
        }
        const { actor } = this.props
        const style = { background: colors[actor.colorIndex - 1] }
        return (
            <div className="user-tooltip">
                <div className="user-tooltip__container">
                    {actor.avatarUrl && <img className="avatar" src={actor.avatarUrl + '_40'} height="40" width="40"/>}
                    {!actor.avatarUrl && (
                        <div className="avatar" style={style}>
                            {utils.getInitials(actor.fullName)}
                        </div>
                    )}
                    <div>
                        <span>{actor.fullName}</span>
                        {actor.groups && <span>{this.getGroupsStr(actor)}</span>}
                    </div>
                    <i className="arrow"/>
                </div>
            </div>
        )
    }
}

export default UserTooltip
