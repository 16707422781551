export function attachment($timeout) {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element) => {
            const PADDING = 68;

            const calculation = () => {
                let contentInfo = element.find('.content .content__info')[0]; // eslint-disable-line
                let contentHeader = element.find('.content .content__header')[0]; // eslint-disable-line
                let date = $(element[0]).children('.date'); // eslint-disable-line
                let ava = $(element[0]).children('.avatar-small'); // eslint-disable-line

                if (contentInfo.clientWidth + PADDING < date.position().left && contentHeader.clientWidth + PADDING < date.position().left) {
                    date.css('visibility', 'visible');
                }
                if (contentInfo.clientWidth + PADDING < ava.position().left && contentHeader.clientWidth + PADDING < ava.position().left) {
                    ava.css('visibility', 'visible');
                }
            };

            $timeout(() => calculation(), 0);
            scope.$watch(() => scope.attachment.creator, () => calculation(), true);
        }
    };
}
