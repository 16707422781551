export function touchStart() {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            touchStart: '&?'
        },
        link: (scope, elem, attrs) => {
            const listener = (event) => {
                if (!attrs.skipPreventing) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                if (scope.touchStart) {
                    scope.touchStart();
                }
            };
            elem.bind('touchstart', listener);

            scope.$on('$destroy', () => {
                elem.unbind('touchstart', listener);
            });
        }
    };
}
