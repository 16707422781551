export function DevicesDetector($log) {
    'ngInject';
    let isMobile, isDesktop;
    const detector = () => {
        if((typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)) { // eslint-disable-line
            isMobile = true;
            isDesktop = false;
        } else {
            isMobile = false;
            isDesktop = true;
        }
        $log.info('Mobile device: ', isMobile, window.orientation);
    };
    detector();
    return {
        isMobile: () => isMobile,
        isDesktop: () => isDesktop
    };
}