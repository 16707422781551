import config from '../../config';
import statuses from '../../libs/status2mode';
import utils from '../processes/utils';
import globalUtils from '../../utils';
import homeUtils from './home-utils';

const SECTIONS = {
    AVAILABLE: 'available'
};

const STORAGE_KEYS = {
    ACTIVE: ['My', 'Delegated'],
    COMPLETED: ['Completed', 'DelegatedCompleted']
};

export class HomeController {
    constructor($scope, $state, $location, Constants, HomeSettings, PageSettings, ApiCalls, DeferredAction, TaskService,
                PusherHelper, $translate, $timeout, $filter, DateHelper, ServerConfig, $auth) {
        'ngInject';

        this.DateHelper = DateHelper;
        this.DeferredAction = DeferredAction;
        this.HomeSettings = HomeSettings;
        this.PageSettings = PageSettings;
        this.$filter = $filter;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;

        this.nextOffsetIds = {};
        this.busyLoading = {};
        this.loadingFirstPages = []
        this.api = {
            [SECTIONS.AVAILABLE]: ApiCalls.getAvailableTasks,
            [HomeSettings.filterModes[0]]: ApiCalls.getMyTasks,
            [HomeSettings.filterModes[1]]: ApiCalls.getDelegatedTasks,
            [HomeSettings.filterModes[2]]: ApiCalls.getCompletedTasks,
            [HomeSettings.filterModes[3]]: ApiCalls.getDelegatedCompletedTasks
        };
        this.listStub = Array.from({length: PageSettings.homeItemsPerPage}, (v, k) => ({id: k + 1}));

        let timeoutTreeId, onCloseCalendar;

        ApiCalls.getActors().then(data => {
            $scope.actors = {users: data.users || [], groups: data.groups || [], specialRoles: data.specialRoles || []};
        });

        $scope.pattern = config.pattern;
        $scope.HomeSettings = HomeSettings;
        $scope.ServerConfig = ServerConfig;
        $scope.storage = {};

        if ($auth.isAuthenticated()) {
            $scope.payload = $auth.getPayload();
        }

        let findTask = (id) => {
            let foundedTask;
            $scope.tasksTree.forEach((group) => group.tasks.forEach((task) => {
                if (task.id === id) {
                    foundedTask = task;
                }
            }));
            return foundedTask;
        };

        // Task addition from RTU
        let addRTUTask = (task, key) => {
            let list = $scope.storage[key].slice();
            let sortRule;
            switch (key) {
                case 'My':
                    sortRule = HomeSettings.myTasksGroup === 1 ? utils.groupTasksByProcessSort : utils.customNotCompletedSort;
                    break;
                case 'available':
                    sortRule = HomeSettings.availableTasksGroup === 1 ? utils.groupTasksByProcessSort : utils.customNotCompletedSort;
                    break;
                case 'Delegated':
                    sortRule = utils.customNotCompletedSort;
                    break;
                default:
                    sortRule = utils.customCompletedSort;
            }
            list.sort(sortRule);
            let res = [list.pop(), task];
            res.sort(sortRule);
            if (res[0].id !== task.id && this.nextOffsetIds[key] !== -1) {
                return;
            }
            utils.mergeToArray($scope.storage[key], task);
        };

        // ASSIGN to Self task
        let assign = (task, event) => {
            if (event) {
                event.stopPropagation();
            }
            if (task.actionClick || !$scope.checkPermission(task, 'selfAssign')) {
                return;
            }
            task.actionClick = true;
            ApiCalls.assignTask(task).then(response => {
                if (response && response.success) {
                    $scope.storage.available = $scope.storage.available.filter(t => t.id !== task.id);
                    this.updateAvailableTasksTree();
                }
            }).finally(() => delete task.actionClick);
        };

        // UNASSIGN task
        let unassign = (task, $event) => {
            if ($event) {
                $event.stopPropagation();
            }
            if (task.actionClick || !$scope.checkPermission(task, 'unAssign')) {
                return;
            }
            task.actionClick = true;
            ApiCalls.unassignTask(task)
                .then(response => {
                    if (response.success) {
                        if ($scope.checkPermission(response.result, 'selfAssign')) {
                            addRTUTask(response.result, 'available');
                            this.updateAvailableTasksTree();
                        }
                        $scope.storage.My = $scope.storage.My.filter(t => t.id !== task.id);
                        this.updateTasksTree();
                    }
                })
                .finally(() => delete task.actionClick);
        };

        // DELETE task
        let deleteTask = (task, $event) => {
            if ($event) {
                $event.stopPropagation();
            }
            if (!$scope.checkPermission(task, 'delete')) {
                return;
            }

            if (!DeferredAction.TaskDelete.isDeferred(task)) {
                DeferredAction.TaskDelete.addToDeferred(task).catch(PageSettings.errorHandlerModal);
                $scope.$applyAsync();
                this.updateTasksTree();
            }
        };

        $scope.$on(DeferredAction.TaskDelete.CONST.COMPLETED, (event, deletedTask) => {
            if (deletedTask) {
                const filterFn = t => t.id !== deletedTask.id;
                for (let section in $scope.storage) {
                    if ($scope.storage.hasOwnProperty(section)) {
                        $scope.storage[section] = $scope.storage[section].filter(filterFn);
                    }
                }
            }
            $scope.$applyAsync();
            this.updateTasksTree();
        });

        const showDueDateCalendar = (task, targetElement, onClose) => {
            if(onCloseCalendar !== undefined) {
                return false;
            }
            onCloseCalendar = onClose;
            $scope.$broadcast('mt-date-picker:drop', {
                targetElement: targetElement,
                date: DateHelper.companyEndOfDayToDateInUTC(task.dueDate),
                dateInterval: task.dueDateInterval,
                customData: {id: task.id}
            });
            $scope.$digest();
            return true;
        };

        const tasksSaved = {};
        $scope.updateTaskDueDate = (date, customData) => {
            onCloseCalendar(customData.id);
            const taskCurrent = findTask(customData.id);
            if(tasksSaved[customData.id] === undefined) {
                tasksSaved[customData.id] = angular.copy(taskCurrent);
            }
            taskCurrent.dueDate = DateHelper.dateToCompanyEndOfDay(date);
            TaskService.updateDueDate(date, taskCurrent, tasksSaved[customData.id]);
            $timeout(() => {
                onCloseCalendar = undefined;
            });
        };

        const withoutFilterFn = (id) => (t) => t.id !== id;
        const removeTaskFromStorage = (task, storage_keys) => {
            storage_keys.forEach(key => {
                if ($scope.storage[key]) {
                    $scope.storage[key] = $scope.storage[key].filter(withoutFilterFn(task.id));
                }
            });
        };

        // COMPLETE / REOPEN task
        let toggleState = (task, $event) => {
            if ($event) {
                $event.stopPropagation();
                $event.preventDefault();
            }
            if (task.actionClick) {
                return;
            }

            task.actionClick = true;
            if (task.isCompleted) {
                if (!$scope.checkPermission(task, 'reopen')) {
                    delete task.actionClick;
                    return;
                }
                removeTaskFromStorage(task, STORAGE_KEYS.COMPLETED);
                ApiCalls.uncompleteTask(task).finally(() => delete task.actionClick);
            } else {
                if (!$scope.checkPermission(task, 'complete')) {
                    delete task.actionClick;
                    return;
                }
                removeTaskFromStorage(task, STORAGE_KEYS.ACTIVE);
                ApiCalls.completeTask(task).finally(() => delete task.actionClick);
            }
            task.isCompleted = !task.isCompleted;
            $timeout(() => this.updateTasksTree(), 300);
        };

        let toggleApprovalState = (task, $event) => {
            if ($event) {
                $event.stopPropagation();
                $event.preventDefault();
            }
            if (task.actionClick) {
                return;
            }
            task.actionClick = true;

            if (task.status === Constants.TASK.STATUS.APPROVED || task.status === Constants.TASK.STATUS.REJECTED) {
                if (!$scope.checkPermission(task, 'reopen')) {
                    delete task.actionClick;
                    return;
                }
                removeTaskFromStorage(task, STORAGE_KEYS.COMPLETED);
                ApiCalls.uncompleteTask(task).finally(() => delete task.actionClick);
            } else {
                if (!$scope.checkPermission(task, 'approve')) {
                    delete task.actionClick;
                    return;
                }
                removeTaskFromStorage(task, STORAGE_KEYS.ACTIVE);
                ApiCalls.approveTask(task).finally(() => delete task.actionClick);
            }

            $timeout(() => this.updateTasksTree(), 300);
        };

        $scope.isMainListToDisplay = () => {
            return $scope.tasksTree.length && $scope.tasksTree[0].tasks.length && !$scope.serverError;
        };

        $scope.isAvailableTasksListNotEmpty = () => {
            return $scope.availableTree.length && $scope.availableTree[0].tasks.length && !$scope.serverError;
        };

        // React props definitions
        $scope.functions = {
            goToProcess: (task, $event) => {
                if ($event) {
                    $event.stopPropagation();
                }
                $state.go('main.processView', {id: task.process.id, url: $location.path()});
            },
            goToTask: (task, fromList, tasksGroup) => {
                if (!task.name) {
                    return;
                }
                this.saveScrollPositions(this.getActiveSectionId())
                this.saveScrollPositions(SECTIONS.AVAILABLE)
                $state.go('main.task', {
                    id: task.id,
                    tasks: fromList ? $scope.storage[fromList] : null,
                    url: $location.path(),
                    fromList: fromList === 'available' ? 'Available' : fromList || null,
                    tasksGroup: tasksGroup || null
                });
            },
            toggleState: toggleState,
            toggleApprovalState: toggleApprovalState,
            delete: deleteTask,
            unassign: unassign,
            assign: assign,
            showDueDateCalendar: showDueDateCalendar
        };

        $scope.checkPermission = (obj, permission) => {
            return obj.permissions ? obj.permissions.indexOf(permission) !== -1 : null;
        };

        /**
         * WATCHERS
         */
        $scope.$watch(() => HomeSettings.activeFilterId, (section) => {
            this.firstPage(section);
        });

        $scope.$watch(() => HomeSettings.myTasksGroup, (newVal)  => {
            globalUtils.localStorage.set('MyTasksGroup', newVal);
            this.firstPage();
        });

        $scope.$watch(() => HomeSettings.availableTasksGroup, (newVal)  => {
            globalUtils.localStorage.set('AvailableTasksGroup', newVal);
            this.firstPage(SECTIONS.AVAILABLE);
        });

        $scope.$on(DeferredAction.TaskDelete.CONST.CANCELED, () => this.updateTasksTree());

        /**
         * PUSHER EVENT HANDLERS
         */
        const socketConnectedListener = () => {
            if (PusherHelper.firstCall) {
                PusherHelper.firstCall = false;
                return;
            }
            this.firstPage();
            this.firstPage(SECTIONS.AVAILABLE);
        };

        let rmTasks = (changedObj, key) => {
            if ($scope.storage[key]) {
                changedObj.tasks.forEach(task => {
                    $scope.storage[key] = $scope.storage[key].filter(withoutFilterFn(task.id));
                });
            }
        };

        let addTasks = (changedObj, key) => {
            changedObj.tasks.forEach(task => {
                if ($scope.storage[key] && !$scope.storage[key].find(sT => sT.id === task.id)) {
                    if (changedObj.missingData) {
                        ApiCalls.getTask(task.id).then(data => addRTUTask(data, key, true));
                    } else {
                        addRTUTask(task, key, true);
                    }
                }
            });
        };

        let getStorageKey = status => {
            return statuses[status];
        };

        let updateRTUTasks = (changedObj) => {
            const {newStatus} = changedObj;
            if (!statuses[newStatus]) {
                return;
            }

            let updater = key => {
                if (!$scope.storage[key]) {
                    return;
                }

                changedObj.tasks.forEach(task => {
                    const index = $scope.storage[key].findIndex((sT) => sT.id === task.id);
                    if (index > -1) {
                        let tasks, permissions;
                        const changedObject = $scope.storage[key][index];
                        if (changedObject.process && changedObject.process.tasks && !task.process.tasks) {
                            tasks = changedObject.process.tasks.slice();
                        }
                        if (changedObject.permissions && !task.permissions) {
                            permissions = changedObject.permissions;
                        }

                        $scope.storage[key][index] = task;
                        if (tasks) {
                            $scope.storage[key][index].process.tasks = tasks;
                        }
                        if (permissions) {
                            $scope.storage[key][index].permissions = permissions;
                        }
                    }
                });
            };
            if (statuses[newStatus]) {
                updater(getStorageKey(newStatus));
            }
        };

        let updateRTUProcesses = (changedObj) => {
            changedObj.tasks.forEach(rtuTask => {
                if (!rtuTask.process) {
                    return;
                }
                const updateFn = (item) => {
                    if (item.process && item.process.tasks && item.process.id === rtuTask.process.id) {
                        let index;
                        let taskToUpdate = item.process.tasks.find((t, i) => {
                            if (t.id === rtuTask.id) {
                                index = i;
                                return true;
                            }
                            return false;
                        });
                        if (taskToUpdate) {
                            if (changedObj.newStatus === 'none') {
                                item.process.tasks.splice(index, 1);
                            } else {
                                Object.assign(taskToUpdate, {
                                    isAssigned: rtuTask.isAssigned,
                                    isCompleted: rtuTask.isCompleted,
                                    status: rtuTask.status,
                                    dueDate: rtuTask.dueDate || null
                                });
                            }
                        }
                    }
                };

                for (let section in $scope.storage) {
                    if ($scope.storage.hasOwnProperty(section)) {
                        $scope.storage[section].forEach(updateFn);
                    }
                }
            });
        };

        let updateStatusTasks = (data) => {
            if (data && data.changes) {
                data.changes.forEach(changedObj => {
                    if (changedObj.oldStatus !== 'none') {
                        updateRTUProcesses(changedObj);
                    }
                    if (changedObj.oldStatus === changedObj.newStatus) {
                        return updateRTUTasks(changedObj);
                    }
                    if (statuses[changedObj.oldStatus]) {
                        rmTasks(changedObj, getStorageKey(changedObj.oldStatus));
                    }
                    if (statuses[changedObj.newStatus]) {
                        addTasks(changedObj, getStorageKey(changedObj.newStatus));
                    }
                });
                if (timeoutTreeId) {
                    $timeout.cancel(timeoutTreeId);
                }
                timeoutTreeId = $timeout(() => {
                    this.updateTasksTree();
                    this.updateAvailableTasksTree();
                }, 300);
            }
        };

        let updateTaskCounter = (data) => {
            let task = data.task;
            let currentStore = $scope.storage[HomeSettings.activeFilterId];
            currentStore.forEach(t => {
                if (t.id === task.id) {
                    Object.assign(t, task);
                }
            });
            this.updateTasksTree();
        };

        let updateUserOrGroup = (data) => {
            if (data.user) {
                for (let section in $scope.storage) {
                    if ($scope.storage.hasOwnProperty(section)) {
                        $scope.storage[section].forEach(homeUtils.updateUserOrGroupCb(data.user)[section]);
                    }
                }

                if (timeoutTreeId) {
                    $timeout.cancel(timeoutTreeId);
                }

                timeoutTreeId = $timeout(() => {
                    this.updateTasksTree();
                    this.updateAvailableTasksTree();
                }, 300);
            }
            if (data.group && $scope.storage['Delegated']) {
                $scope.storage['Delegated'].forEach(homeUtils.updateUserOrGroupCb(data.group, true)['Delegated']);
                if (HomeSettings.activeFilterId === 'Delegated') {
                    this.updateTasksTree();
                }
            }
        };

        PusherHelper.subscribe('commentAdded', updateTaskCounter);
        PusherHelper.subscribe('commentDeleted', updateTaskCounter);
        PusherHelper.subscribe('taskStatusChanged', updateStatusTasks);
        PusherHelper.subscribe('userOrGroupUpdated', updateUserOrGroup);

        $scope.$on('socket:connected', socketConnectedListener);
        $scope.$on('$destroy', () => {
            PusherHelper.unsubscribe('commentAdded', updateTaskCounter);
            PusherHelper.unsubscribe('commentDeleted', updateTaskCounter);
            PusherHelper.unsubscribe('taskStatusChanged', updateStatusTasks);
            PusherHelper.unsubscribe('userOrGroupUpdated', updateUserOrGroup);
            if (timeoutTreeId) {
                $timeout.cancel(timeoutTreeId);
            }
        });

        $scope.countAvailableTasks = () => {
            const actualAmount = $scope.storage[SECTIONS.AVAILABLE].length;
            const moreThanOnePage = this.nextOffsetIds[SECTIONS.AVAILABLE] > 0 || actualAmount > PageSettings.homeItemsPerPage;
            const amount = Math.min(PageSettings.homeItemsPerPage, actualAmount);
            return `${amount}${moreThanOnePage ? '+' : ''}`;
        };
    }

    getSectionContainer(section) {
        const selector = section === SECTIONS.AVAILABLE ? 'tasks__list__content' : 'tasks__main__content';
        return document.getElementsByClassName(selector)[0];
    }

    restoreScrollPosition(section, initial = false) {
        const container = this.getSectionContainer(section)
        const savedScrollTop = this.HomeSettings.scrollTopValues[section]

        if (container) {
            if (!savedScrollTop && initial) {
                container.scrollTop = 0
                return
            } else if (!savedScrollTop) {
                return
            }

            if (savedScrollTop < container.children[0].clientHeight) {
                container.scrollTop = savedScrollTop
            }
            this.HomeSettings.scrollTopValues[section] = undefined
        }
    }

    saveScrollPositions(section) {
        const container = this.getSectionContainer(section)
        this.HomeSettings.scrollTopValues[section] = container ? container.scrollTop : 0
    }

    getActiveSectionId() {
        return this.HomeSettings.activeFilterId;
    }

    isSectionLoading(section) {
        return this.busyLoading[section || this.getActiveSectionId()];
    }

    buildTree(list, isAvailable) {
        const {DeferredAction, DateHelper, $translate, $filter, HomeSettings} = this;
        let tasksList = homeUtils.prepareList(list, DeferredAction.TaskDelete.getAllDeferred(), DateHelper, $translate, $filter);
        if (isAvailable || HomeSettings.activeFilterId === 'My') {
            let groupFilter = HomeSettings[isAvailable ? 'availableTasksGroup' : 'myTasksGroup'];
            if (groupFilter === 1) {
                return utils.groupTasksByProcess(tasksList);
            } else if (groupFilter === 2) {
                return utils.groupTasksByDueDate(tasksList, DateHelper, $translate);
            }
        }
        return [{tasks: tasksList.sort(isAvailable || HomeSettings.subMode ? utils.customNotCompletedSort : utils.customCompletedSort)}];
    }

    updateTasksTree(listStub) {
        if (listStub) {
            this.$scope.tasksTree = [{tasks: listStub.slice()}];
        } else {
            this.$scope.tasksTree = this.buildTree(this.$scope.storage[this.getActiveSectionId()]);
        }
    }

    updateAvailableTasksTree(listStub) {
        if (listStub) {
            this.$scope.availableTree = [{tasks: listStub.slice()}];
        } else {
            this.$scope.availableTree = this.buildTree(this.$scope.storage.available, true);
        }
    }

    getApiParams(section) {
        const params = {
            count: this.PageSettings.homeItemsPerPage,
            groupBy: section === SECTIONS.AVAILABLE
                ? this.HomeSettings.availableTasksGroup
                : this.HomeSettings.myTasksGroup
        };

        if (this.nextOffsetIds[section] && this.nextOffsetIds[section] !== -1) {
            params.nextOffset = this.nextOffsetIds[section];
        }

        return params;
    }

    nextPage(section, force) {
        section = section || this.getActiveSectionId();
        if (this.nextOffsetIds[section] === -1 || this.isSectionLoading(section)) {
            return;
        }

        if (section === SECTIONS.AVAILABLE && !this.HomeSettings.showList && !force) {
            return;
        }

        const params = this.getApiParams(section);
        this.busyLoading[section] = true;

        this.api[section](params).then(data => {
            this.nextOffsetIds[section] = data.nextOffset || -1;
            this.serverError = false;

            if (data.list) {
                utils.mergeToArray(this.$scope.storage[section], data.list, true);
                if (section === SECTIONS.AVAILABLE) {
                    this.updateAvailableTasksTree();
                } else {
                    this.updateTasksTree();
                }
                this.triggerListChange(section);
            }

        }, () => {
            this.serverError = true;
        }).finally(() => {
            this.$timeout(() => this.busyLoading[section] = false);
        });
    }

    firstPage(section) {
        section = section || this.getActiveSectionId();

        if (this.loadingFirstPages.includes(section)) {
            return
        }
        this.loadingFirstPages = [...this.loadingFirstPages, section]
        setTimeout(() => {
            this.loadingFirstPages = this.loadingFirstPages.filter(s => s !== section)
        }, 1000)

        this.nextOffsetIds[section] = 0;

        if (section === SECTIONS.AVAILABLE) {
            this.updateAvailableTasksTree(this.listStub);
        } else {
            this.updateTasksTree(this.listStub);
        }

        if (!this.$scope.storage[section]) {
            this.$scope.storage[section] = [];
        }

        this.nextPage(section, true);

        setTimeout(() => {
            this.restoreScrollPosition(section, true)
        }, 500)
    }

    triggerListChange(section) {
        const listId = section === SECTIONS.AVAILABLE ? 'available' : 'main';
        this.$timeout(() => this.$scope.$emit(`list:${listId}:changed`), 100);
    }
}
