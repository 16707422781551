import stringEntropy from 'fast-password-entropy'
import config from '../config'

export class PasswordModalController {
    constructor($scope, $modalInstance, ServerConfig, FormErrors, AdminApiCalls) {
        'ngInject';
        $scope.checkField = fieldName => {
            return $scope.resetForm[fieldName].$invalid && $scope.resetForm[fieldName].$dirty;
        };
        $scope.fieldError = fieldName => {
            return FormErrors($scope.resetForm, fieldName);
        };

        $scope.getMinLength = () => {
            if (ServerConfig.auth && ServerConfig.auth.minPasswordLength) {
                return ServerConfig.auth.minPasswordLength;
            } else {
                return 4;
            }
        };

        $scope.passwordIsWeak = undefined
        $scope.handleOnChange = () => {
            $scope.passwordIsWeak = $scope.newPassword && stringEntropy($scope.newPassword) < config.minimal_entropy
        }

        $scope.ok = () => {
            if (!$scope.isClicked) {
                $scope.isClicked = true;
                let postData = {
                    password: $scope.newPassword,
                    version: $scope.user.version
                };

                AdminApiCalls.changeUserPassword($scope.user.id, postData).then(user => {
                    $modalInstance.close(user);
                }, errResponse => {
                    if (errResponse.data) {
                        $scope.serverError = errResponse.data.displayError || errResponse.data.error
                            || errResponse.data.password[0].fieldError;
                    }
                }).finally(() => {
                    $scope.isClicked = false;
                });
            }
        };

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };
    }
}
