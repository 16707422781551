import uid from 'uid';
import Drop from 'tether-drop';
const DropContext = Drop.createContext({classPrefix: 'default-currency'});

export function selectDefaultCurrency() {
    'ngInject';

    return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
            ngModel: '=',
            options: '='
        },
        template: `
            <div class="select-default-currency">
                <header class="handler">
                    {{ngModel.code + ' • ' + ngModel.name}}
                    <i class="icon-common_arrow_down"></i>
                </header>
            
                <ul class="currencies-list" ng-attr-id="{{uid}}">
                    <li ng-repeat="option in options">
                        <a ng-click="setCurrency(option)" ng-class="{active: option.id == ngModel.id}">
                            <i class="icon-menu_checkmark"></i> {{option.code + ' • ' + option.name}}
                        </a>
                    </li>
                </ul>
            </div>
        `,
        link: (scope, element, attrs, ctrl) => {
            let drop = new DropContext({
                target: element.find('.handler')[0],
                content: element.find('.currencies-list')[0],
                position: 'bottom left',
                openOn: 'click',
                constrainToWindow: false,
                constrainToScrollParent: false
            });

            scope.uid = uid();
            scope.setCurrency = option => {
                if (scope.ngModel.id === option.id) {
                    drop.close();
                    return;
                }
                ctrl.$setViewValue(option);
                drop.close();
            };

            drop.on('open', () => {
                let el = document.getElementById(scope.uid); //eslint-disable-line
                if (el) {
                    el.scrollTop = 0;
                }
            });

            scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
