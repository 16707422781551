import Drop from 'tether-drop';

const HELP_TEXT_LINK = 'http://help.workblocks.me/en/articles/4854513-markdown'

export function markdownHint() {
    return {
        restrict: 'E',
        scope: {
            callback: '&?',
            taskDescr: '@?'
        },
        template: `
            <div class="markdown-hint mdh-item" ng-click="call()" tabindex="-1">
                <div class="toolbar">
                    <a tabindex="-1" href="${HELP_TEXT_LINK}"
                       class="m-logo mdh-item" target="_blank">
                        <i class="icon-logo_markdown"></i>
                    </a>
                    <span tabindex="-1" class="mdh-item">{{'markdown.bold' | translate}}</span>
                    <span tabindex="-1" class="mdh-item"># {{'markdown.header1' | translate}}</span>
                    <span ng-if="!taskDescr" tabindex="1" class="mdh-item">{{'markdown.listItem' | translate}}</span>
                    <span tabindex="-1" class="mdh-item link-hint">{{'markdown.link' | translate}}</span>
                    <a href="" tabindex="-1" class="all-options mdh-item">{{'markdown.allOptions' | translate}}</a>
                </div>

                <div class="dropdown-menu--markdown-hint mdh-item" tabindex="-1">
                    <p class="announcement">
                        {{'markdown.announcement1' | translate}}
                        <a href="${HELP_TEXT_LINK}" class="mdh-item" target="_blank" tabindex="-1">
                            {{'label.markdown' | translate}}
                        </a> {{'markdown.announcement2' | translate}}
                    </p>
                    <p># {{'markdown.header1' | translate}}</p>
                    <p>## {{'markdown.header2' | translate}}</p>
                    <p>{{'markdown.bold' | translate}}</p>
                    <p>{{'markdown.italic' | translate}}</p>
                    <p>{{'markdown.strikethrough' | translate}}</p>
                    <p>{{'markdown.link' | translate}}</p>
                    <p>{{'markdown.image' | translate}}</p>
                    <p>{{'markdown.listItem' | translate}}</p>
                    <p>{{'markdown.horizontalLine' | translate}}</p>
                    <p>> {{'markdown.quote' | translate}}</p>
                    <p>@[youtube](http://www.youtube.com/watch?v=dQw4w9WgXcQ)</p>
                </div>
            </div>
        `,
        link: ($scope, element, attrs) => {
            let drop = new Drop({
                target: element.find('.all-options')[0],
                content: element.find('.dropdown-menu--markdown-hint')[0],
                position: 'bottom right',
                openOn: 'click'
            });

            if (element[0].parentNode.clientWidth - (attrs.offset || 0) < 500) {
                element.find('.link-hint')[0].style.display = 'none';
            }

            $scope.call = () => {
                if ($scope.callback) {
                    $scope.callback();
                }
            }

            drop.on('close', $scope.call);

            $scope.$on('$destroy', () => {
                drop.destroy();
            });
        }
    };
}
