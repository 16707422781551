import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../react/utils'

import ReportsListItem from './reports-list-item.jsx'

import '../less/reports.less'

class ReportsList extends React.Component {
    static propTypes = {
        reports: PropTypes.array,
        onComponentMount: PropTypes.func
    }

    constructor (props) {
        super(props)
    }

    componentDidMount () {
        this.props.onComponentMount()
    }

    render () {
        const { reports } = this.props

        return (
            <div className="reports">
                <header className="reports__header">
                    <h3>{translate('label.reports')}</h3>
                </header>

                <div className="reports-list">
                    {reports.map(report => (
                        <ReportsListItem key={report.id} report={report}/>
                    ))}
                </div>
            </div>
        )
    }
}

export default ReportsList
