import moment from 'moment-timezone';

export function dueDate($translate, DateHelper) {
    'ngInject';
    const DUE_DATE_SHORT = DateHelper.DATE_FORMATS().DUE_DATE_SHORT

    return (input, taskView, prList) => {
        if (!input) {
            return input;
        }

        let workingHoursStart = DateHelper.getWorkingTimeStart();
        let tz = DateHelper.getTZ();
        let nowInTimeZone = moment().tz(tz);
        let endOfToday = nowInTimeZone.clone().set({hour: 23, minute: 59, second: 59, millisecond: 0}).add(workingHoursStart, 'hours');
        let endOfTomorrow = endOfToday.clone().add(1, 'day');

        let tsNow = nowInTimeZone.unix();
        let inputDate = moment.unix(input).tz(tz);
        let tsInputDate = inputDate.clone().unix();

        let checkCloseOrPastDate = () => {
            if (tsInputDate > tsNow && tsInputDate <= endOfToday.unix()) {
                return $translate.instant('label.today');
            }
            if (tsInputDate > tsNow && tsInputDate <= endOfTomorrow.unix()) {
                return $translate.instant('label.tomorrow');
            }
            return inputDate.format(DUE_DATE_SHORT);
        };

        let checkThisWeek = () => {
            let tsEndOfThisWeek = nowInTimeZone.clone().endOf('week').add(workingHoursStart, 'hours').unix();
            return tsInputDate <= tsEndOfThisWeek;
        };

        let checkNextWeek = () => {
            let tsEndOfNextWeek = nowInTimeZone.clone().add(1, 'week').endOf('week').add(workingHoursStart, 'hours').unix();
            return tsInputDate <= tsEndOfNextWeek;
        };

        let checkThisMonth = () => {
            let tsEndOfThisMonth = nowInTimeZone.clone().endOf('month').add(workingHoursStart, 'hours').unix();
            return tsInputDate <= tsEndOfThisMonth;
        };

        let checkNextMonth = () => {
            let tsEndOfNextMonth = nowInTimeZone.clone().add(1, 'month').endOf('month').add(workingHoursStart, 'hours').unix();
            return tsInputDate <= tsEndOfNextMonth;
        };

        if (!taskView) {
            return checkCloseOrPastDate();
        }

        if (tsInputDate <= endOfTomorrow.unix()) {  //Date Timestamp before End Of Tomorrow
            return checkCloseOrPastDate();
        }

        if (!prList) {
            if (checkThisWeek()) {
                return $translate.instant('label.dueDate.thisWeek');
            } else if (checkNextWeek()) {
                return $translate.instant('label.dueDate.nextWeek');
            } else if (checkThisMonth()) {
                return $translate.instant('label.dueDate.thisMonth');
            } else if (checkNextMonth()) {
                return $translate.instant('label.dueDate.nextMonth');
            } else {
                return $translate.instant('label.dueDate');
            }
        } else {
            let textPrefix;
            if (checkThisWeek()) {
                textPrefix = $translate.instant('label.dueDate.thisWeek');
            } else if (checkNextWeek()) {
                textPrefix = $translate.instant('label.dueDate.nextWeek');
            } else {
                textPrefix = $translate.instant('label.dueDate.date.by');
            }
            return textPrefix + ' ' + inputDate.format(DUE_DATE_SHORT);
        }
    };
}
