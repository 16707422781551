import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { observer } from 'mobx-react'

import './options-list-editor.less'

class OptionsListEditor extends React.Component {
    static propTypes = {
        // Model is an instance of DataTypeListOfLinesOption (datatypes.js)
        model: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.state = { active: false }

        this.activate = this.activate.bind(this)
        this.deactivate = this.deactivate.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    render () {
        const { active } = this.state
        const { model } = this.props

        return (
            <div className={cn('options-list-editor', { active })}>
                {model.isEmpty && (
                    <div className="placeholder">
                        Value 1<br/>
                        Value 2<br/>
                        Value 3<br/>
                        ...
                    </div>
                )}
                <textarea
                    className="content-editor"
                    value={model.asListOfValues.join('\n')}
                    onFocus={this.activate}
                    onBlur={this.deactivate}
                    onChange={this.onChange}
                    required
                    ref={this._refContent}
                />
                <div className="content-bg">
                    {model.value.map((option, index) => (
                        <React.Fragment key={`${option.item}_${index}`}>
                            <span className={cn({ invalid: !option.isValid })}>{option.item}</span>
                            <br/>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        )
    }

    onChange (e) {
        const { onChange } = this.props

        onChange(e.target.value.split('\n'))
        this.updateSize()
    }

    _refContent = (ref) => {
        this.textarea = ref
    }

    updateSize () {
        this.textarea.style.width = 'auto'
        this.textarea.style.height = 'auto'
        this.textarea.style.width = this.textarea.scrollWidth + 'px'
        this.textarea.style.height = this.textarea.scrollHeight + 'px'
    }

    activate () {
        this.setState({ active: true })
    }

    deactivate () {
        this.setState({ active: false })
    }

    componentWillUnmount () {
        this.deactivate()
    }

    componentDidMount () {
        this.updateSize()
    }
}

export default observer(OptionsListEditor)
