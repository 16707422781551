const colors = [
    '#b8e17d', // Soft green
    '#80c0e5', // Soft blue
    '#ff72b1', // Soft pink
    '#f3c376', // Soft orange
    '#79dfbc', // Soft mint
    '#f3a0a0', // Soft red
    '#c0a4ee', // Soft purple
    '#e5dc4e',  // Soft yellow
    '#ccc'  // Soft gray
];
export default colors;

export const tmplColors = {
    '#b8e17d': '#fafff3', // Soft green
    '#80c0e5': '#f7fcff', // Soft blue
    '#ff72b1': '#fff7fb', // Soft pink
    '#f3c376': '#fffaf2', // Soft orange
    '#79dfbc': '#f7fffc', // Soft mint
    '#f3a0a0': '#fff7f7', // Soft red
    '#c0a4ee': '#faf7ff', // Soft purple
    '#e5dc4e': '#fffef2',  // Soft yellow
    '#ccc': '#fafafa'  // Soft gray
};
