/*global ChargeBee, $*/

export const FILTER_MODES = {
    ACTIVE: 'active',
    DISABLED: 'disabled'
};

export function AdminSettings($translate) {
    'ngInject';
    let filterModes = [{
        id: FILTER_MODES.ACTIVE,
        title: $translate.instant('users.filter.active')
    }, {
        id: FILTER_MODES.DISABLED,
        title: $translate.instant('users.filter.disabled')
    }];

    let settings = {
        showList: false,
        filterModes: filterModes,
        activeFilterMode: filterModes[0],

        toggleList: () => {
            settings.showList = !settings.showList;
        },
        toggleMode: mode => {
            if (mode.id !== settings.activeFilterMode.id) {
                settings.activeFilterMode = mode;
            }
        },
        callChargeBee: (data, onSuccess, onCancel) => {
            const container = $('#chargbee-container');
            ChargeBee.embed(data.url, data.siteName).load({
                addIframe: (iframe) => {
                    container.append(iframe);
                },
                onLoad: (iframe, width, height) => {
                    iframe.setAttribute('style', 'height:' + height + 'px;');
                    $(iframe).show();
                },
                onResize: (iframe, width, height) => {
                    iframe.setAttribute('style', 'height:' + height + 'px;');
                },
                onSuccess: (iframe) => {
                    settings.hideChargeBee(iframe);
                    onSuccess(data.pageId);
                },
                onCancel: (iframe) => {
                    settings.hideChargeBee(iframe);
                    onCancel();
                }
            });
        },
        hideChargeBee: (iframe) => {
            $(iframe).hide();
            $('#chargbee-container').empty();
        }
    };
    return settings;
}
