export function activationRoute ($stateProvider) {
    'ngInject'

    $stateProvider
        .state('login-disabled', {
            url: '/disabled/login?error',
            template: require('../auth/views/login.html'),
            controller: 'LoginController',
            controllerAs: 'loginCtrl',
            params: {
                message: null,
                path: '/',
                redir: null,
                error: null,
                target: null,
                disabledMode: true
            }
        })
        .state('reset-start-disabled', {
            url: '/disabled/reset',
            template: require('../auth/views/reset-start.html'),
            controller: 'ResetController',
            controllerAs: 'resetCtrl',
            resolve: {
                data: () => {
                    return {}
                }
            },
            params: {
                disabledMode: true
            }
        })
        .state('reset-disabled', {
            url: '/disabled/reset/:mailToken',
            template: require('../auth/views/reset.html'),
            controller: 'ResetController',
            controllerAs: 'resetCtrl',
            resolve: {
                data: (ApiCalls, $stateParams, $translate, $state) => {
                    'ngInject'
                    return ApiCalls.getResetDisabledData($stateParams.mailToken).then(data => data, errorResp => {
                        let error
                        if (errorResp && errorResp.data) {
                            error = errorResp.data.displayError
                        }
                        if (errorResp.status === 518) { //check if user is already reset password with this link
                            $state.go('billing-disabled')
                        } else {
                            $state.go('error', {
                                message: error || $translate.instant('error.reset'),
                                reason: 'reset'
                            })
                        }
                    })
                }
            },
            params: {
                mailToken: null,
                disabledMode: true
            }
        })
        .state('billing-disabled', {
            url: '/disabled/billing',
            template: require('./views/billing.html'),
            controller: 'BillingController',
            controllerAs: 'billingCtrl'
        })
}
